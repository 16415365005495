export default (state = {
	user: {},
	loadingVisible: true,
	alertVisible: false,
	alertMessage: "",
	alertSeverity: "",
    redirectAdmin: 0,
}, action) => {
    switch (action.type){
        case "SETUSER": 
            return state = {
                ...state,
                user: action.payload.user,
            }
        case "REMOVEUSERS":
            return state = {
                user: {},
                loadingVisible: false,
                alertVisible: false,
                alertMessage: "",
                alertSeverity: ""
			}
		case "DISPLAYLOADING":
            return state = {
				...state,
                loadingVisible: action.payload.value,
			}
		case "DISPLAYALERT":
            return state = {
				...state,
				alertVisible: action.payload.visible,
				alertMessage: action.payload.message,
				alertSeverity: action.payload.severity
			}
        case "REDIRECTITEMS":
            return state = {
				...state,
				redirectAdmin: action.payload.value
			}
        default:
            return state;
    }
};
