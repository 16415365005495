import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import * as Material from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";

import {
	mapStateToProps,
	mapDispatchToProps,
} from "../container/container.reducer";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "./navigation.styles";
import * as Service from "../ppmp/ppmp.service";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { COMM } from "../../helpers/common";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class Navigation extends React.Component {
	constructor() {
		super();
		this.state = {
			profileMenuVisible: null,
			helpFileLink: "",
			openDialog: false,
			notifications: [],
			notificationIsLoading: true,
			viewNotificationDialog: false,
			message: {},
			anchorEl: null,
			menuItems: [],
			anchorElPopover: null,
			pendingPPMP: [],
		};
		// this.onChangeHandler = this.onChangeHandler.bind(this);
		this.navigationHandler = this.navigationHandler.bind(this);
		this.handleProfileOpen = this.handleProfileOpen.bind(this);
		this.handleProfileClose = this.handleProfileClose.bind(this);
		this.onClickLinks = this.onClickLinks.bind(this);
		this.generateNavData = this.generateNavData.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.handleClickOpen = this.handleClickOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.formatDate = this.formatDate.bind(this);
		this.handleViewNotification = this.handleViewNotification.bind(this);
		this.getCurrentDateTime = this.getCurrentDateTime.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleCloseMenu = this.handleCloseMenu.bind(this);
		this.handleClickOpenNotification =
			this.handleClickOpenNotification.bind(this);
		this.handleCloseNotification = this.handleCloseNotification.bind(this);
		this.fetchPendingPPMP = this.fetchPendingPPMP.bind(this);
	}
	componentDidMount() {
		// console.log("$$Data.User", this.props.data.user);
		const { data } = this.props;
		if (data?.user?.office_id) {
			this.fetchPendingPPMP(data.user.office_id);
		}
	}

	componentDidUpdate(prevProps) {
		const { data } = this.props;

		if (
			prevProps.data.user?.office_id !== data.user?.office_id &&
			data.user?.office_id
		) {
			this.fetchPendingPPMP(data.user.office_id);
		}
	}

	fetchPendingPPMP(office_id) {
		Service.getPendingPPMP({ office_id })
			.then((response) => {
				if (response.error) {
					console.log("Error getting pending PPMP");
				} else {
					this.setState({
						...this.state,
						pendingPPMP: response.data,
					});
				}
			})
			.catch((err) => {
				console.error("Error occurred while fetching pending PPMP!", err);
			});
	}
	// onChangeHandler (e, index) {
	//     this.setState({...this.state, tabIndex: index})
	// }
	handleClickOpen = () => {
		this.setState({
			openDialog: true,
		});
		this.getOfficeNotification();
	};
	handleClose = () => {
		this.setState({
			openDialog: false,
		});
	};
	navigationHandler(e) {
		this.props.history.push(e.currentTarget.name);
	}
	handleProfileOpen(e) {
		this.setState({ ...this.state, profileMenuVisible: e.currentTarget });
	}
	handleProfileClose(e) {
		this.setState({ ...this.state, profileMenuVisible: false });
	}
	onClickLinks(e) {
		this.handleProfileClose();
		this.props.history.push(e.target.title);
	}
	// generateNavData() {
	// 	const { data } = this.props;
	// 	const {
	// 		can_manage_ppmp,
	// 		can_manage_admin,
	// 		has_pow,
	// 		has_catering,
	// 		is_approver,
	// 		can_approve_manage_unforseen_emergency,
	// 	} = data.user;
	// 	return [
	// 		{
	// 			name: "Procurement Plan",
	// 			path: "",
	// 			key: "ppmp",
	// 			active: can_manage_ppmp,
	// 		},
	// 		{
	// 			name: "Office Activities",
	// 			path: "activities",
	// 			key: "activities",
	// 			active: has_catering > 0,
	// 		},
	// 		{
	// 			name: "Program of Works",
	// 			path: "pow",
	// 			key: "pow",
	// 			active: has_pow > 0,
	// 		},
	// 		{
	// 			name: "PPMP Items Approval",
	// 			path: "itemsforapproval",
	// 			key: "itemsforapproval",
	// 			active: is_approver > 0,
	// 		},
	// 		{
	// 			name: "Unforeseen/Emergency PPMP",
	// 			path: "unforeseenPPMP",
	// 			key: "unforeseenPPMP",
	// 			active: can_approve_manage_unforseen_emergency !== false,
	// 		},
	// 		{
	// 			name: "Payments",
	// 			path: "payments",
	// 			key: "payments",
	// 			active: can_manage_ppmp,
	// 		},
	// 		{
	// 			name: "Balances of Appropriations",
	// 			path: "consumption",
	// 			key: "Consumption",
	// 			active: can_manage_ppmp,
	// 		},
	// 		{
	// 			name: "Deliverables",
	// 			path: "deliverables",
	// 			key: "Deliverables",
	// 			active: can_manage_ppmp,
	// 		},
	// 		// {
	// 		//     name: "Annual Procurement",
	// 		//     path: "annualPPMP",
	// 		//     key: "annualPPMP",
	// 		//     active: can_manage_ppmp
	// 		// },
	// 		// {
	// 		//     name: "Purchase Request",
	// 		//     path: "pr",
	// 		//     key: "pr",
	// 		//     active: can_manage_pr
	// 		// },
	// 		// {
	// 		//     name: "Purchase Order",
	// 		//     path: "po",
	// 		//     key: "po",
	// 		//     active: can_manage_pr
	// 		// },
	// 		// {
	// 		//   name: "Administration",
	// 		//   path: "admin",
	// 		//   key: "admin",
	// 		//   active: can_manage_admin,
	// 		// },
	// 	];
	// }
	generateNavData() {
		const { data } = this.props;
		const user = data?.user || {};

		const {
			can_manage_ppmp = false,
			has_pow = 0,
			has_catering = 0,
			is_approver = 0,
			can_approve_manage_unforseen_emergency = false,
		} = user;

		return [
			{
				label: "Procurement Plan",
				items: [
					{
						name: "PPMP",
						path: "",
						key: "ppmp",
					},
					{
						name: "Office Activities",
						path: "activities",
						key: "activities",
					},
					{
						name: "Program of Works",
						path: "pow",
						key: "pow",
					},
				],
				active: can_manage_ppmp,
			},
			{
				label: "Approvals & Reviews",
				items: [
					...(is_approver > 0
						? [
								{
									name: "PPMP Items Approval",
									path: "itemsforapproval",
									key: "itemsforapproval",
								},
						  ]
						: []),
					...(can_approve_manage_unforseen_emergency === true
						? [
								{
									name: "Unforeseen/Emergency PPMP",
									path: "unforeseenPPMP",
									key: "unforeseenPPMP",
								},
						  ]
						: []),
				],
				active:
					is_approver > 0 || can_approve_manage_unforseen_emergency === true,
			},
			{
				label: "Financial Management",
				items: [
					{
						name: "Payments",
						path: "payments",
						key: "payments",
					},
					{
						name: "Balances of Appropriations",
						path: "consumption",
						key: "consumption",
					},
					{
						name: "Deliverables",
						path: "deliverables",
						key: "deliverables",
					},
				],
				active: can_manage_ppmp,
			},
		];
	}
	handleClick = (event, item) => {
		this.setState({
			anchorEl: event.currentTarget,
			menuItems: item.items,
		});
	};
	handleCloseMenu = () => {
		this.setState({
			anchorEl: null
		});

		setTimeout(() => {
			this.setState({ menuItems: [] });
		  }, 200);
	};
	handleNavigateMenuItem = (menuItem) => {
		this.props.history.push(menuItem.path);
		this.handleCloseMenu();
	};
	downloadFile() {
		let link = process.env.REACT_APP_ROOTURL + this.state.helpFileLink;
		window.open(link, "_blank", "noreferrer");
	}

	getOfficeNotification() {
		const { displayAlert, data } = this.props;
		let office_id = data.user.office_id;

		// this.setState({ notificationIsLoading: true });
		Service.getOfficeNotification({ office_id })
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: response.error,
						message: response.msg,
						severity: "warning",
					});
					this.setState({ notificationIsLoading: false });
				} else {
					this.setState({
						...this.state,
						notifications: response.data,
						notificationIsLoading: false,
					});
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
				this.setState({ notificationIsLoading: false });
			});
	}

	setStatus(messageId) {
		const { displayAlert, data } = this.props;
		let user_value = {
			user_id: parseInt(data.user.username),
			message_id: parseInt(messageId),
			currentDateTime: this.getCurrentDateTime(),
		};

		this.setState({ notificationIsLoading: true });
		Service.officeNotificationSetRecieved({ user_value })
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: response.error,
						message: response.msg,
						severity: "warning",
					});
					this.setState({ notificationIsLoading: false });
				} else {
					this.setState({
						...this.state,
						notifications: response.data,
						viewNotificationDialog: false,
						notificationIsLoading: false,
					});
					this.getOfficeNotification();
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
				this.setState({
					notificationIsLoading: false,
					viewNotificationDialog: false,
				});
			});
	}

	formatDate = (dateString) => {
		const options = { month: "short", day: "2-digit", year: "numeric" };
		return new Date(dateString).toLocaleDateString("en-US", options);
	};

	getCurrentDateTime() {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
		const day = String(now.getDate()).padStart(2, "0");
		const hours = String(now.getHours()).padStart(2, "0");
		const minutes = String(now.getMinutes()).padStart(2, "0");
		const seconds = String(now.getSeconds()).padStart(2, "0");

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}

	handleViewNotification = (notification) => () => {
		this.setState({
			...this.state,
			viewNotificationDialog: true,
			message: notification,
		});
	};

	handleCloseNotificationDialog = () => () => {
		this.setState({
			...this.state,
			viewNotificationDialog: false,
			message: {},
		});
	};
	handleClickOpenNotification = (event) => {
		this.setState({ anchorElPopover: event.currentTarget });
	};
	handleCloseNotification = () => {
		this.setState({ anchorElPopover: null });
	};

	render() {
		const {
			profileMenuVisible,
			openDialog,
			notifications,
			notificationIsLoading,
			viewNotificationDialog,
			anchorEl,
			menuItems,
			anchorElPopover,
			pendingPPMP,
		} = this.state;
		const { classes, history } = this.props;
		const path = history.location.pathname;
		const open = Boolean(anchorEl);
		const openPopover = Boolean(anchorElPopover);
		const id = open ? "simple-popover" : undefined;
		const activeItem = this.generateNavData().filter((fil) => fil.active);
		let currentIndex = activeItem.findIndex(
			(item) => path.indexOf(item.key) !== -1
		);
		let profile =
			path.indexOf("useraccount") > -1 || path.indexOf("logout") > -1;
		currentIndex = currentIndex === -1 ? false : currentIndex;
		currentIndex = path === "/" ? 0 : currentIndex;
		return (
			<div className={classes.root}>
				<AppBar position="fixed" className={classes.navAppBar} color="default">
					<Material.Toolbar className={classes.toolbar}>
						{/* <Tabs value={currentIndex} className={classes.tabs}>
							{activeItem.map((item, index) => {
								return (
									<Material.Tab
										key={`tab-nav-${index}`}
										className={classes.tabIcon}
										label={item.name}
										name={item.path}
										onClick={this.navigationHandler}
									/>
								);
							})}
						</Tabs> */}
						<Tabs value={currentIndex} className={classes.tabs}>
							{activeItem.map((item, index) => {
								return (
									<Material.Tab
										key={`tab-nav-${index}`}
										label={item.label}
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										onClick={(event) => this.handleClick(event, item)}
										className={classes.tabIcon}
									/>
								);
							})}
						</Tabs>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={this.handleCloseMenu}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
						>
							{menuItems.map((menuItem, index) => (
								<MenuItem
									key={index}
									onClick={() => this.handleNavigateMenuItem(menuItem)}
								>
									{menuItem.name}
								</MenuItem>
							))}
						</Menu>
						<div className="classes.icon">
							<Material.IconButton
								aria-label="messages"
								color="inherit"
								onClick={this.handleClickOpen}
							>
								<MailIcon color={profile ? "primary" : "inherit"} />
							</Material.IconButton>
							<Material.IconButton
								aria-label="notification"
								aria-describedby={id}
								color="inherit"
								className={classes.iconButtonHover}
								onClick={this.handleClickOpenNotification}
							>
								<Badge badgeContent={pendingPPMP.length} color="error">
									<NotificationsIcon color={profile ? "primary" : "inherit"} />
								</Badge>
							</Material.IconButton>
							<Material.Popover
								id={id}
								open={openPopover}
								anchorEl={anchorElPopover}
								onClose={this.handleCloseNotification}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								PaperProps={{
									sx: {
										width: "420px", // Adjust width as needed
										maxHeight: "400px",
										overflow: "auto",
										borderRadius: 2,
										boxShadow: 3,
										padding: 1,
									},
								}}
							>
								<Material.Box>
									{/* Header with Tabs */}
									<Material.Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											borderBottom: "1px solid #ddd",
											paddingBottom: 1,
											paddingX: 2,
										}}
									>
										<Material.Typography
											variant="h6"
											sx={{ fontWeight: "bold" }}
										>
											Pending PPMP
										</Material.Typography>
										<Material.Box sx={{ display: "flex", gap: 1 }}>
											<Material.Typography
												sx={{
													color: "primary.main",
													fontWeight: "medium",
												}}
											>
												All
											</Material.Typography>
										</Material.Box>
									</Material.Box>

									{/* Notification List */}
									{pendingPPMP.length > 0 ? (
										pendingPPMP.map((ppmp, index) => (
											<Material.Box
												key={index}
												sx={{
													display: "flex",
													alignItems: "center",
													gap: 2,
													padding: 2,
													borderBottom: "1px solid #eee",
												}}
											>
												{/* Notification Icon */}
												<Material.Chip
													sx={{
														bgcolor: "#e89e16eb",
														color: "white",
														width: 100,
														height: 40,
													}}
													label={ppmp.status}
												/>

												{/* Notification Text */}
												<Material.Box sx={{ flex: 1 }}>
													<Material.Typography
														sx={{ fontSize: "0.9rem", fontWeight: "bold" }}
													>
														{`${ppmp.ppmp_title} (${ppmp.ppmp_no})`}
													</Material.Typography>
													<Material.Typography
														sx={{ fontSize: "0.8rem", color: "text.secondary" }}
													>
														{ppmp.is_indicative > 0
															? `Indicative PPMP - ${ppmp.year}`
															: `Final PPMP - ${ppmp.year}`}
													</Material.Typography>
													<Material.Typography
														sx={{
															fontSize: "0.75rem",
															color: "text.secondary",
															marginTop: "4px",
														}}
													>
														{`Created ${COMM.timeSince(ppmp.transdate)}`}
													</Material.Typography>
												</Material.Box>

												{/* Read Indicator */}
												<Material.Icon
													sx={{
														fontSize: "small",
														color: ppmp.read ? "text.disabled" : "primary.main",
													}}
												>
													{ppmp.read ? "done" : "fiber_manual_record"}
												</Material.Icon>
											</Material.Box>
										))
									) : (
										<Material.Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												height: "50px", // Adjust height if necessary
												textAlign: "center",
												color: "text.secondary",
											}}
										>
											<Material.Typography variant="body1">
												No pending PPMP
											</Material.Typography>
										</Material.Box>
									)}
								</Material.Box>
							</Material.Popover>
							<Material.IconButton
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={this.handleProfileOpen}
								color="inherit"
							>
								<AccountCircle color={profile ? "primary" : "inherit"} />
							</Material.IconButton>
							<Material.Typography
								variant="caption"
								display="inline"
								gutterBottom
								color={profile ? "primary" : "initial"}
							>
								{this.props.data.user.firstname}
							</Material.Typography>
							<Material.Menu
								id="menu-appbar"
								anchorEl={profileMenuVisible}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(profileMenuVisible)}
								onClose={this.handleProfileClose}
							>
								<Material.MenuItem
									title="useraccount"
									onClick={this.onClickLinks}
								>
									My account
								</Material.MenuItem>
								<Material.MenuItem title="logout" onClick={this.onClickLinks}>
									Logout
								</Material.MenuItem>
							</Material.Menu>
							{/* <Tooltip title="Help" placement="bottom">
                                <IconButton aria-label="help?"
                                    onClick={this.downloadFile}
                                    color="inherit" >
                                    <ContactSupportIcon color={profile? "primary" : "inherit"}/>
                                </IconButton>
                            </Tooltip> */}
						</div>
					</Material.Toolbar>
				</AppBar>
				<Material.Dialog
					fullScreen
					open={openDialog}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar sx={{ position: "relative" }} onClick={this.handleClose}>
						<Material.Toolbar>
							<Material.IconButton
								edge="start"
								color="inherit"
								onClick={this.handleClose}
								aria-label="close"
							>
								<CloseIcon />
							</Material.IconButton>
							<Material.Typography
								sx={{ ml: 2, flex: 1 }}
								variant="h6"
								component="div"
							>
								Office Notifications
							</Material.Typography>
						</Material.Toolbar>
					</AppBar>
					<Material.TableContainer component={Paper} sx={{ mt: 12 }}>
						<Material.Table sx={{ minWidth: 650 }} aria-label="simple table">
							<Material.TableHead>
								<Material.TableRow>
									<Material.TableCell align="center" size="small">
										Status
									</Material.TableCell>
									<Material.TableCell align="left" size="small">
										Recieved By
									</Material.TableCell>
									<Material.TableCell align="left">
										Notification
									</Material.TableCell>
									<Material.TableCell align="right">
										Transdate
									</Material.TableCell>
								</Material.TableRow>
							</Material.TableHead>
							<Material.TableBody>
								<>
									{notificationIsLoading ? (
										// Render Skeletons while loading
										[1, 2, 3].map((_, index) => (
											<Material.TableRow key={index}>
												<Material.TableCell>
													<Skeleton variant="text" />
												</Material.TableCell>
												<Material.TableCell>
													<Skeleton variant="text" />
												</Material.TableCell>
												<Material.TableCell>
													<Skeleton variant="text" />
												</Material.TableCell>
												<Material.TableCell>
													<Skeleton variant="text" />
												</Material.TableCell>
											</Material.TableRow>
										))
									) : notifications && notifications.length > 0 ? (
										notifications.map((notification, index) => (
											<Material.TableRow
												sx={{
													cursor: "pointer",
													border: 1,
													backgroundColor:
														notification.received_by === null
															? "grey.300"
															: "inherit",
												}}
												key={index}
												size="small"
												onClick={this.handleViewNotification(notification)}
											>
												<Material.TableCell align="center">
													{notification.received_by &&
													notification.received_on ? (
														<Material.Chip
															label="Received"
															color="success"
															sx={{ width: 140 }}
														/>
													) : (
														<Material.Chip
															label="Not Yet Received"
															sx={{
																backgroundColor: "#f44336",
																color: "white",
																width: 140,
															}}
														/>
													)}
												</Material.TableCell>
												<Material.TableCell align="left">
													{notification.received_by && notification.received_on
														? `${notification.received_by} - ${this.formatDate(
																notification.received_on
														  )}`
														: ""}
												</Material.TableCell>
												<Material.TableCell component="th" align="left">
													{notification.notification}
												</Material.TableCell>
												<Material.TableCell align="right">
													{this.formatDate(notification.transdate)}
												</Material.TableCell>
											</Material.TableRow>
										))
									) : (
										<Material.TableRow>
											<Material.TableCell
												colSpan={4}
												align="center"
												className="emptyCont"
											>
												<div className="empty">
													<div className="emptyBorder">
														No notifications available.
													</div>
												</div>
											</Material.TableCell>
										</Material.TableRow>
									)}
								</>
							</Material.TableBody>
						</Material.Table>
					</Material.TableContainer>
				</Material.Dialog>
				<Material.Dialog
					open={viewNotificationDialog}
					onClose={this.handleCloseNotificationDialog()}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth="sm"
					fullWidth
				>
					<Material.DialogTitle id="alert-dialog-title">
						Message
					</Material.DialogTitle>
					<Material.DialogContent>
						<Material.DialogContentText id="alert-dialog-description">
							{this.state.message.notification}
						</Material.DialogContentText>
					</Material.DialogContent>
					<Material.DialogActions>
						<Material.Button
							startIcon={this.state.message.received_by ? <TaskAltIcon /> : ""}
							onClick={() => this.setStatus(this.state.message.id)}
							disabled={this.state.message.received_by}
							autoFocus
						>
							{this.state.message.received_by ? "Received" : "Mark as Recieved"}
						</Material.Button>
					</Material.DialogActions>
				</Material.Dialog>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(useStyles)(Navigation)));
