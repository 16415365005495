export const mapStateToProps = (state) => {
    return {
        data: state.data,
        annualPPMP: state.annualPPMP
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setAnnualPPMPDataSource: (res) => {
            dispatch({
                type: "SETANNUALPPMPDATASOURCE",
                payload: {
                    annualPPMPDatasource: res.annualPPMPDatasource,
                },
                // callback: () => { callback(); }
            })
        },
        displayLoading: (res) => {
            dispatch({
                type: "DISPLAYLOADING",
                payload: {
                    value: res,
                }
            })
        },
        displayAlert: (res) => {
            dispatch({
                type: "DISPLAYALERT",
                payload: {
                    visible: res.visible,
                    message: res.message,
                    severity: res.severity,
                }
            })
        },

    };
};