export default (state = {
    priceDatasource: [],
    priceTotal: 0,
    supplierCreate: {}
}, action) => {
    switch (action.type) {
        case "SETPRICEDATASOURCE":
            return state = {
                ...state,
                priceDatasource: action.payload.priceDatasource,
                priceTotal: action.payload.priceTotal,
            }
        case "DROPPRICESTATE":
            return state = {
                priceDatasource: [],
                priceTotal: 0,
                supplierCreate: {}
            }
        default:
            return state;
    }
};
