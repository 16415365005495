import { API } from './axios.instance';

export const remoteSessionValidation = session => {
    let data = new FormData();
    data.append('session', session.userToken);
    return API().post('/authentication/auth',data).then(res => {
        return { isSessionValid: res.data.sessionValidate }
    }).catch(err => {
        return { error: true , message: `${err}` };
    });
};