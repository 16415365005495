import React from 'react';
import { connect } from 'react-redux';

import * as Material from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './container.styles';
import { mapStateToProps } from './container.reducer';

class SessionLoading extends React.Component {
    render() {
        const { session, classes } = this.props;
        return (
			<Material.Modal aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal} open={!session.checked}
                closeAfterTransition disableAutoFocus={true}
                BackdropComponent={Material.Backdrop} BackdropProps={{ timeout: 500 }} >
                <div className={classes.paper}>
                    <Material.Fade in={!session.checked}>
                        <Material.FormControlLabel control={ <Material.CircularProgress /> } 
                            checked={false}
                            label="Loading..." />
                    </Material.Fade>
                </div>
            </Material.Modal>
        );
    }
}

export default connect(mapStateToProps)(withStyles(useStyles)(SessionLoading));