import React from 'react';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';

import * as Material from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';

import { useStyles } from './signatories.modal.style';
import * as PPMPservice from '../ppmp.service';
import { mapStateToProps, mapDispatchToProps } from '../ppmp.reducer';

// import EditIcon from '@material-ui/icons/Edit';
// import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class SignatoriesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            admin_fullname: "",
            admin_designation: "",
            head_fullname: "",
            head_designation: "",
            budget_fullname: "",
            budget_designation: "",
            pa_name: "",
            pa_pos: "",
            id: 0,
            ppmp_id: 0,
            signatoriesFound: false,
            useAutocompleteA: true,
            useAutocompleteB: true,
            useAutocompleteC: true,

            plus_10per_inflation: false,
            plus_10per_contingency: false
        };
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onCancelSetSignatories = this.onCancelSetSignatories.bind(this);
        this.setSignatoriesData = this.setSignatoriesData.bind(this);
        this.handle_admin_fullname = this.handle_admin_fullname.bind(this);
        this.handle_head_fullname = this.handle_head_fullname.bind(this);
        this.handle_budget_fullname = this.handle_budget_fullname.bind(this);
        this.requestSetSignatories = this.requestSetSignatories.bind(this); //called when necessary update of signatories happen
        this.setDefaultSignatories = this.setDefaultSignatories.bind(this); //onRendered on opening modal - setting signatories value from redux
        this.switchHandlerA = this.switchHandlerA.bind(this);
        this.switchHandlerB = this.switchHandlerB.bind(this);
        this.switchHandlerC = this.switchHandlerC.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
    }
    componentDidMount() {
        const { history, displayAlert } = this.props; //ppmp
        sessionService.loadSession().then((currentSession) => {
            if (currentSession.selectedTransaction === null) {
                history.push('/');
            } else {
                const data = currentSession.selectedTransaction;
                this.setState({
                    ...this.state,
                    ppmp_id: data.id,
                });
            }
        }).catch((err) => {
            displayAlert({
                visible: true,
                message: err,
                severity: "warning"
            });
        });
    }
    onCheckChange(event) {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    }
    switchHandlerA(event) {
        this.setState({ ...this.state, useAutocompleteA: event.target.checked });
    }
    switchHandlerB(event) {
        this.setState({ ...this.state, useAutocompleteB: event.target.checked });
    }
    switchHandlerC(event) {
        this.setState({ ...this.state, useAutocompleteC: event.target.checked });
    }
    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    setDefaultSignatories() {
        const { ppmp } = this.props;
        this.setState({
            ...this.state,
            admin_designation: ppmp.signatories.adminDesignation ? ppmp.signatories.adminDesignation : "",
            admin_fullname: ppmp.signatories.adminFullname ? ppmp.signatories.adminFullname : "",
            budget_designation: ppmp.signatories.budgetDesignation ? ppmp.signatories.budgetDesignation : "",
            budget_fullname: ppmp.signatories.budgetFullname ? ppmp.signatories.budgetFullname : "",
            head_designation: ppmp.signatories.headDesignation ? ppmp.signatories.headDesignation : "",
            head_fullname: ppmp.signatories.headFullname ? ppmp.signatories.headFullname : "",
            plus_10per_inflation: ppmp.genPPMPDataSource[0].funds[0].ppa_list[0].items[0].plus_10per_inflation > 0,
            plus_10per_contingency: ppmp.genPPMPDataSource[0].funds[0].ppa_list[0].items[0].plus_10per_contingency > 0,
        }, () => {
            // console.log('$$plus_10per_inflation', this.state.plus_10per_inflation);
            // console.log('$$plus_10per_contingency', this.state.plus_10per_contingency);
        });
    }
    requestSetSignatories() {
        const { displayLoading, displayAlert } = this.props;
        PPMPservice.updateSignatories({ ...this.state }).then((res) => {
            displayAlert({
                visible: true,
                message: res.data.msg,
                severity: "success"
            });
            displayLoading(false);
            this.props.onCloseModal();
        }).catch((err) => {
            console.error(err);
            displayLoading(false);
        });
    }
    handle_admin_fullname(event, value) {
        if (value) {
            this.setState({
                ...this.state,
                admin_fullname: value.fullname,
                admin_designation: value.designation,
                id: value.id,
            });
        }
    }
    handle_head_fullname(event, value) {
        if (value) {
            this.setState({
                ...this.state,
                head_fullname: value.fullname,
                head_designation: value.designation,
                id: value.id,
            });
        }
    }
    handle_budget_fullname(event, value) {
        if (value) {
            this.setState({
                ...this.state,
                budget_fullname: value.fullname,
                budget_designation: value.designation,
                id: value.id,
            });
        }
    }
    setSignatoriesData() {
        const { setCurrentSignatories, displayAlert, displayLoading } = this.props;
        const { admin_fullname, admin_designation, head_fullname, head_designation, budget_fullname, budget_designation } = this.state;
        const errorHander = {};

        errorHander.admin_name_Error = !admin_fullname && true;
        errorHander.admin_d_Error = !admin_designation && true;
        errorHander.head_name_Error = !head_fullname && true;
        errorHander.head_d_Error = !head_designation && true;
        errorHander.budget_name_Error = !budget_fullname && true;
        errorHander.budget_d_Error = !budget_designation && true;

        this.setState({ ...this.state, ...errorHander }, () => {
            const { admin_name_Error, admin_d_Error, head_name_Error, head_d_Error, budget_name_Error, budget_d_Error } = errorHander;
            if (!admin_name_Error && !admin_d_Error && !head_name_Error && !head_d_Error && !budget_name_Error && !budget_d_Error) {
                displayLoading(true);
                setCurrentSignatories({
                    adminFullname: this.state.admin_fullname,
                    adminDesignation: this.state.admin_designation,
                    headFullname: this.state.head_fullname,
                    headDesignation: this.state.head_designation,
                    budgetFullname: this.state.budget_fullname,
                    budgetDesignation: this.state.budget_designation,
                    authFullname: this.state.pa_name,
                    authDesignation: this.state.pa_pos,
                })
                this.requestSetSignatories();
                this.props.onCloseModal();
                displayAlert({
                    visible: true,
                    message: "Signatories set successfuly.",
                    severity: "success"
                });

            } else {
                displayAlert({ visible: true, message: "Please fill up all fields.", severity: "warning" });
            }
        });
    }
    onCancelSetSignatories() {
        this.props.onCloseModal();
        this.setState({
            ...this.state,
            admin_name_Error: false,
            admin_d_Error: false,
            head_name_Error: false,
            head_d_Error: false,
            budget_name_Error: false,
            budget_d_Error: false,
        });
    }
    render() {
        const { modalVisible, classes } = this.props; //userRole
        return (
            <Modal
                onRendered={this.setDefaultSignatories}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalVisible}
                onClose={this.onCancelSetSignatories}
                closeAfterTransition
                disableAutoFocus={true}
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }} >
                <div className={classes.root}>
                    <Fade in={modalVisible}>
                        <div className={classes.paper}>
                            <div className={classes.padTop}><Material.Typography color="primary" variant="h5">PPMP Settings</Material.Typography></div>
                            {/* <Material.Grid container spacing={2}>
                                <Material.Grid item xs={12}>
                                    <Material.Paper>
                                        <Material.FormGroup>
                                            <Material.FormControlLabel control={
                                                <Material.Checkbox
                                                    name='plus_10per_inflation'
                                                    checked={this.state.plus_10per_inflation}
                                                    onChange={this.onCheckChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } label="+ 10% Provision for Inflation" />
                                        </Material.FormGroup>
                                        <Material.FormGroup>
                                            <Material.FormControlLabel control={
                                                <Material.Checkbox
                                                    name='plus_10per_contingency'
                                                    checked={this.state.plus_10per_contingency}
                                                    onChange={this.onCheckChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } label="+ 10% Contingency" />
                                        </Material.FormGroup>
                                    </Material.Paper>
                                </Material.Grid>
                            </Material.Grid> */}

                            <div className={classes.padTop}><Material.Typography color="primary" variant="h5">Signatories</Material.Typography></div>
                            <Material.Grid container spacing={2}>
                                <Material.Grid item xs={4}>
                                    <Material.Paper>
                                        <div align="right">
                                            <Material.Tooltip title="Uncheck to input custom signatory.">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={this.state.useAutocompleteA}
                                                        onChange={this.switchHandlerA}
                                                        name="useAutocompleteA" />}
                                                    label="" /></Material.Tooltip>
                                        </div>
                                        {this.state.useAutocompleteA ?
                                            <React.Fragment>
                                                <Autocomplete onChange={this.handle_admin_fullname}
                                                    id="signatories_admin"
                                                    options={this.props.ppmp.signatoriesDatasource}
                                                    // getOptionLabel={(option) => `${option.fullname}`}
                                                    // getOptionSelected={(option, value)=> option === value}  
                                                    getOptionLabel={(option) => {
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        return option.fullname;
                                                    }}
                                                    value={this.state.admin_fullname}
                                                    // renderOption={(option) => option.fullname}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    freeSolo
                                                    renderInput={(params) => (<TextField {...params} label="Prepared by:" margin="normal" error={this.state.admin_name_Error} />)} />
                                                <TextField id="admin_d" helperText="" label="Designate" value={this.state.admin_designation} disabled={true} error={this.state.admin_d_Error} />
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div className={classes.FormContainer}>
                                                    <form className={classes} noValidate autoComplete="off">
                                                        <TextField id="admin_fullname"
                                                            onChange={this.onChangeHandler}
                                                            name="admin_fullname"
                                                            label="Prepared by:"
                                                            value={this.state.admin_fullname}
                                                            error={this.state.admin_name_Error}
                                                        />
                                                    </form>
                                                </div>
                                                <div className={classes.FormContainer}>
                                                    <form className={classes} noValidate autoComplete="off">
                                                        <TextField id="admin_designation"
                                                            onChange={this.onChangeHandler}
                                                            name="admin_designation"
                                                            label="Designate:"
                                                            value={this.state.admin_designation}
                                                            error={this.state.admin_d_Error}
                                                        />
                                                    </form>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </Material.Paper>
                                </Material.Grid>

                                <Material.Grid item xs={4}>
                                    <Material.Paper>
                                        <div align="right">
                                            <Material.Tooltip title="Uncheck to input custom signatory.">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={this.state.useAutocompleteB}
                                                        onChange={this.switchHandlerB}
                                                        name="useAutocompleteB" />}
                                                    label="" /></Material.Tooltip>
                                        </div>
                                        {
                                            this.state.useAutocompleteB ?
                                                <React.Fragment>
                                                    <Autocomplete onChange={this.handle_head_fullname}
                                                        id="signatories_head"
                                                        options={this.props.ppmp.signatoriesDatasource}
                                                        // getOptionLabel={(option) => `${option.fullname}`}
                                                        // getOptionSelected={(option, value)=> option.fullname === value.fullname}  
                                                        getOptionLabel={(option) => {
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            return option.fullname;
                                                        }}
                                                        value={this.state.head_fullname}
                                                        // renderOption={(option) => option.fullname}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        freeSolo
                                                        renderInput={(params) => (<TextField {...params} label="Submitted by:" margin="normal" error={this.state.head_name_Error} />)} />

                                                    <TextField id="head_d" helperText="" label="Designate" value={this.state.head_designation} disabled={true} error={this.state.head_d_Error} />
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className={classes.FormContainer}>
                                                        <form className={classes} noValidate autoComplete="off">
                                                            <TextField id="head_fullname"
                                                                onChange={this.onChangeHandler}
                                                                name="head_fullname"
                                                                label="Submitted by:"
                                                                value={this.state.head_fullname}
                                                                error={this.state.head_name_Error}
                                                            />
                                                        </form>
                                                    </div>
                                                    <div className={classes.FormContainer}>
                                                        <form className={classes} noValidate autoComplete="off">
                                                            <TextField id="head_designation"
                                                                onChange={this.onChangeHandler}
                                                                name="head_designation"
                                                                label="Designate:"
                                                                value={this.state.head_designation}
                                                                error={this.state.head_d_Error}
                                                            />
                                                        </form>
                                                    </div>
                                                </React.Fragment>
                                        }
                                    </Material.Paper>
                                </Material.Grid>

                                <Material.Grid item xs={4}>
                                    <Material.Paper>
                                        <div align="right">
                                            <Material.Tooltip title="Uncheck to input custom signatory.">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={this.state.useAutocompleteC}
                                                        onChange={this.switchHandlerC}
                                                        name="useAutocompleteC" />}
                                                    label="" /></Material.Tooltip>
                                        </div>
                                        {
                                            this.state.useAutocompleteC ?
                                                <React.Fragment>
                                                    <Autocomplete onChange={this.handle_budget_fullname}
                                                        id="signatories_budget"
                                                        options={this.props.ppmp.signatoriesDatasource}
                                                        // getOptionLabel={(option) => `${option.fullname}`}
                                                        // getOptionSelected={(option, value)=> option.fullname === value.fullname}  
                                                        getOptionLabel={(option) => {
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            return option.fullname;
                                                        }}
                                                        value={this.state.budget_fullname}
                                                        // renderOption={(option) => option.fullname}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        freeSolo
                                                        renderInput={(params) => (<TextField {...params} label="Reviewed by:" margin="normal" error={this.state.budget_name_Error} />)} />

                                                    <TextField id="budget_d" helperText="" label="Designate" value={this.state.budget_designation} disabled={true} error={this.state.budget_d_Error} />
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className={classes.FormContainer}>
                                                        <form className={classes} noValidate autoComplete="off">
                                                            <TextField id="budget_fullname"
                                                                onChange={this.onChangeHandler}
                                                                name="budget_fullname"
                                                                label="Reviewed by:"
                                                                value={this.state.budget_fullname}
                                                                error={this.state.budget_name_Error}
                                                            />
                                                        </form>
                                                    </div>
                                                    <div className={classes.FormContainer}>
                                                        <form className={classes} noValidate autoComplete="off">
                                                            <TextField id="budget_designation"
                                                                onChange={this.onChangeHandler}
                                                                name="budget_designation"
                                                                label="Designate:"
                                                                value={this.state.budget_designation}
                                                                error={this.state.budget_d_Error}
                                                            />
                                                        </form>
                                                    </div>
                                                </React.Fragment>
                                        }
                                    </Material.Paper>
                                </Material.Grid>
                            </Material.Grid>

                            <br></br>
                            <form className={classes} noValidate autoComplete="off">
                                {
                                    this.props.signatoriesFound ?
                                        <Button variant="outlined" color="primary" onClick={this.setSignatoriesData}>Update</Button> :
                                        <Button variant="outlined" color="primary" onClick={this.setSignatoriesData}>Set</Button>
                                }
                                &nbsp;
                                <Button variant="outlined" color="secondary"
                                    onClick={() => {
                                        this.onCancelSetSignatories()
                                    }}>Close
                                </Button>
                            </form>
                        </div>
                    </Fade>
                </div>
            </Modal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(SignatoriesModal));