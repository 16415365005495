import React, { Component } from "react";
import { connect } from "react-redux";
import { useStyles } from "../list/edit.modal.styles";
import { mapStateToProps, mapDispatchToProps } from "../ppmp.reducer";
// import { VariableSizeList } from 'react-window';
import matchSorter from "match-sorter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Material from "@material-ui/core";
import * as PPMPService from "../ppmp.service";
import * as ModesService from "../../admin/modes/modes.service";
import * as ActivitiesService from "../../activities/activities.service";
import { sessionService } from "redux-react-session";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

class EditModal extends Component {
  constructor() {
    super();
    this.state = {
      addItemModalVisible: false,
      dialogVisible: false,
      proceedAnyway: false,
      advanceSearchVisible: false,
      rows: [{ fundSource: null, fundSourceAllocatedAmount: 0 }],
      fundSourceOpen: false,
      respoCodeValue: null,
      sourceFundList: [],
      RA_year_choices: [],
      respoAccount_choices: [],
      fundTypeChoices: [],
      legalBasisChoices: [],
      checkedRCA: false,
      invalidState: false,
      editDataQty: {},
      editMode: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      confirmationDialogVisible: false,
      confirmationDialogMessage: "",

      Qty: 0,

      //default values
      IsNonOffice: false,
      RA_year: "",
      RA_desc: "",
      fundType: "",
      PPA_code: "",
      respo_code: "",
      activeStep: 0,
      activeStepForEdit: 1,
      UnitCost: 0,
      totalCost: 0,
      qtyJan: 0,
      qtyFeb: 0,
      qtyMar: 0,
      qtyApr: 0,
      qtyMay: 0,
      qtyJun: 0,
      qtyJul: 0,
      qtyAug: 0,
      qtySep: 0,
      qtyOct: 0,
      qtyNov: 0,
      qtyDec: 0,
      id: 0,
      ppmpItemId: 0,
      // jan_qty: {},
      // feb_qty: {},
      // mar_qty: {},
      // apr_qty: {},
      // may_qty: {},
      // jun_qty: {},
      // jul_qty: {},
      // aug_qty: {},
      // sep_qty: {},
      // oct_qty: {},
      // nov_qty: {},
      // dec_qty: {},
      year: "",

      stepper: ["Item Details", "Target Months", "Selecting Funds"],

      // modes
      modes: [],
      // parent
      parent_modes: [],
      mode_parent_id: 0,
      procurement_mode: "",
      // category
      category_modes: [],
      mode_category_id: 0,
      procurement_subMode: "",
      // sub-category
      sub_category_modes: [],
      mode_sub_category_id: 0,
      procurement_subMode_child: "",

      mode_proc: "",
      ppmp_item_id: 0,
      ppmp_id: 0,
      item_id: 0,
      unit: "",

      // catering data
      activities: [],
      activity_type_id: 0,
      activity_year: 0,
      year_range: [],
      selected_activity_id: 0,
      office_id: 0,

      quarter_1_disable: false,
      quarter_2_disable: false,
      quarter_3_disable: false,
      quarter_4_disable: false,

      // activity details
      selected_activity: {},
      first_load: true,

      prefered_description: "",
      //year trigger and data for monthly control
      monthly_closing: [],
      monthly_closing_year: "",
      mode: "",
      mode_id: 0,

      monthFields: {
        qtyJan: { qty: "qty_jan", price: "jan_price", forEdit: "jan_for_edit" },
        qtyFeb: { qty: "qty_feb", price: "feb_price", forEdit: "feb_for_edit" },
        qtyMar: { qty: "qty_mar", price: "mar_price", forEdit: "mar_for_edit" },
        qtyApr: { qty: "qty_apr", price: "apr_price", forEdit: "apr_for_edit" },
        qtyMay: { qty: "qty_may", price: "may_price", forEdit: "may_for_edit" },
        qtyJun: { qty: "qty_jun", price: "jun_price", forEdit: "jun_for_edit" },
        qtyJul: { qty: "qty_jul", price: "jul_price", forEdit: "jul_for_edit" },
        qtyAug: { qty: "qty_aug", price: "aug_price", forEdit: "aug_for_edit" },
        qtySep: { qty: "qty_sep", price: "sep_price", forEdit: "sep_for_edit" },
        qtyOct: { qty: "qty_oct", price: "oct_price", forEdit: "oct_for_edit" },
        qtyNov: { qty: "qty_nov", price: "nov_price", forEdit: "nov_for_edit" },
        qtyDec: { qty: "qty_dec", price: "dec_price", forEdit: "dec_for_edit" },
      },
      isTotalCostExceeded: true,
      jan_for_edit: 0,
      feb_for_edit: 0,
      mar_for_edit: 0,
      apr_for_edit: 0,
      may_for_edit: 0,
      jun_for_edit: 0,
      jul_for_edit: 0,
      aug_for_edit: 0,
      sep_for_edit: 0,
      oct_for_edit: 0,
      nov_for_edit: 0,
      dec_for_edit: 0,
      jan_price: 0,
      feb_price: 0,
      mar_price: 0,
      apr_price: 0,
      may_price: 0,
      jun_price: 0,
      jul_price: 0,
      aug_price: 0,
      sep_price: 0,
      oct_price: 0,
      nov_price: 0,
      dec_price: 0,
      for_edit: 0,
      for_update_price: 0,

      newTotalCost: 0,
      monthlyCosts: {},
      newOverAllCost: 0,

      errorFields: {
        qtyJan: false,
        qtyFeb: false,
        qtyMar: false,
        qtyApr: false,
        qtyMay: false,
        qtyJun: false,
        qtyJul: false,
        qtyAug: false,
        qtySep: false,
        qtyOct: false,
        qtyNov: false,
        qtyDec: false,
      },
    };
    this.initRespoCenter = this.initRespoCenter.bind(this);
    this.initDefaultRespoValues = this.initDefaultRespoValues.bind(this);
    this.requestRespoCenterData = this.requestRespoCenterData.bind(this);
    this.requestSourceOfFund = this.requestSourceOfFund.bind(this);
    this.onHandleRespoCodeAutocomplete =
      this.onHandleRespoCodeAutocomplete.bind(this);
    this.onHandleSourceFundAutocomplete =
      this.onHandleSourceFundAutocomplete.bind(this);
    this.onHandleCheckboxChange = this.onHandleCheckboxChange.bind(this);
    this.onHandleSelectChange = this.onHandleSelectChange.bind(this);
    this.onClickAdvanceSearch = this.onClickAdvanceSearch.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
    this.requestFundList = this.requestFundList.bind(this);
    this.requestRAyear = this.requestRAyear.bind(this);
    this.accountCodeRenderOptions = this.accountCodeRenderOptions.bind(this);
    this.respoCodeRenderOptions = this.respoCodeRenderOptions.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.onClickNextStep = this.onClickNextStep.bind(this);
    this.onHandleTargetChange = this.onHandleTargetChange.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.updateItem_final = this.updateItem_final.bind(this);
    this.onHandleTextChange = this.onHandleTextChange.bind(this);
    this.requestGetProcModes = this.requestGetProcModes.bind(this);
    this.requestGetProcModesParent = this.requestGetProcModesParent.bind(this);
    this.requestGetProcModesCategory =
      this.requestGetProcModesCategory.bind(this);
    this.requestGetProcModesSubCategory =
      this.requestGetProcModesSubCategory.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onChangeHandlerForEdit = this.onChangeHandlerForEdit.bind(this);
    this.onHandleEditData = this.onHandleEditData.bind(this);
    this.onHandleEdit = this.onHandleEdit.bind(this);
    this.resetState = this.resetState.bind(this);
    this.getYear = this.getYear.bind(this);
    this.onChangeCateringYear = this.onChangeCateringYear.bind(this);
    this.showActivityDetails = this.showActivityDetails.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.handleFundSourceAllocatedAmountChange =
      this.handleFundSourceAllocatedAmountChange.bind(this);
    this.updateItemFinalMultipleRespo =
      this.updateItemFinalMultipleRespo.bind(this);
    this.isSupplimentalByPass = this.isSupplimentalByPass.bind(this);
    this.updateState = this.updateState.bind(this);
    this.logOut = this.logOut.bind(this);
  }
  componentDidMount() {
    // console.log('$$respoCenterCodeDatasource', this.props.ppmp.respoCenterCodeDatasource);
    (this.props.ppmp.respoCenterCodeDatasource.length === 0 ||
      this.state.respoCodeValue === null) &&
      this.requestRespoCenterData();
  }

  logOut() {
    sessionService.deleteSession().then(() => {
      sessionService.deleteUser().then(() => { });
    });
  }

  // isSupplimentalByPass = (month) => {
  //   if (this.props.ppmpParentId > 0) {
  //     // console.log('$$-----------------------');
  //     // change this to quarter with value:
  //     // get month with value:
  //     let monthWithValue =
  //       parseInt(this.state.qtyJan) > 0
  //         ? 1
  //         : parseInt(this.state.qtyFeb) > 0
  //         ? 2
  //         : parseInt(this.state.qtyMar) > 0
  //         ? 3
  //         : parseInt(this.state.qtyApr) > 0
  //         ? 4
  //         : parseInt(this.state.qtyMay) > 0
  //         ? 5
  //         : parseInt(this.state.qtyJun) > 0
  //         ? 6
  //         : parseInt(this.state.qtyJul) > 0
  //         ? 7
  //         : parseInt(this.state.qtyAug) > 0
  //         ? 8
  //         : parseInt(this.state.qtySep) > 0
  //         ? 9
  //         : parseInt(this.state.qtyOct) > 0
  //         ? 10
  //         : parseInt(this.state.qtyNov) > 0
  //         ? 11
  //         : parseInt(this.state.qtyDec) > 0
  //         ? 12
  //         : 0; // Default value if none of the quantities are present
  //     if (monthWithValue > 0) {
  //       const quarterWithValue = Math.floor((monthWithValue - 1) / 3) + 1;
  //       const monthQuarter = Math.floor((month - 1) / 3) + 1;
  //       console.log("$$month", month);
  //       console.log("$$monthQuarter", monthQuarter);
  //       console.log("$$monthWithValue", monthWithValue);
  //       console.log("$$currentQuarter", quarterWithValue);

  //       if (quarterWithValue === monthQuarter) {
  //         // console.log('$$bypass', month, monthQuarter);
  //         return true;
  //       }
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // };
  isSupplimentalByPass = (month) => {
    if (this.props.ppmp.transactionData.parent_id > 0) {
      // console.log('$$-----------------------');
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // getMonth() returns month from 0 (January) to 11 (December)
      // Quarter calculation
      const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
      const monthQuarter = Math.floor((month - 1) / 3) + 1;
      // console.log('$$month', month);
      // console.log("$$monthQuarter", monthQuarter);
      // console.log("$$currentMonth", currentMonth);
      // console.log('$$currentQuarter', currentQuarter);

      if (currentQuarter === monthQuarter) {
        // console.log("$$bypass", month, monthQuarter);
        return true;
      }
    } else {
      return false;
    }
  };

  addRow = () => {
    const { rows } = this.state;
    this.setState({
      rows: [...rows, { fundSource: "" }],
    });
  };

  removeRow = (index) => {
    const { rows } = this.state;
    if (rows.length > 1) {
      const updatedRows = [...rows.slice(0, index), ...rows.slice(index + 1)];
      this.setState({ rows: updatedRows });
    }
  };

  handleFundSourceAllocatedAmountChange = (event, index) => {
    const { rows } = this.state;
    let value = event.target.value.replace(/,/g, ""); // Remove existing commas

    // Check if the input is empty and set the value to 0
    if (value === "") {
      value = "0";
    }

    // Allow only numbers and up to 2 decimal places
    if (!isNaN(value) && /^(\d+(\.\d{0,2})?)?$/.test(value)) {
      if (value.includes(".")) {
        // Format only the integer part for commas
        let parts = value.split(".");
        parts[0] = parseFloat(parts[0]).toLocaleString("en-US");
        value = parts.join(".");
      } else {
        value = parseFloat(value).toLocaleString("en-US");
      }

      const updatedRows = [...rows];
      updatedRows[index] = {
        ...updatedRows[index],
        fundSourceAllocatedAmount: value,
      };
      this.setState({ rows: updatedRows });
    }
  };

  resetState() {
    this.setState({
      ...this.state,
      addItemModalVisible: false,
      dialogVisible: false,
      proceedAnyway: false,
      advanceSearchVisible: false,

      // fundSource: null,
      // fundSourceOpen: false,
      // respoCodeValue: null,

      // sourceFundList: [],
      // RA_year_choices: [],
      // respoAccount_choices: [],
      // fundTypeChoices: [],
      // legalBasisChoices: [],

      checkedRCA: false,
      invalidState: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      confirmationDialogVisible: false,
      confirmationDialogMessage: "",

      //default values
      IsNonOffice: false,
      RA_year: "",
      RA_desc: "",
      fundType: "",
      PPA_code: "",
      respo_code: "",
      activeStep: 0,
      jan_qty: 0,
      feb_qty: 0,
      mar_qty: 0,
      apr_qty: 0,
      may_qty: 0,
      jun_qty: 0,
      jul_qty: 0,
      aug_qty: 0,
      sep_qty: 0,
      oct_qty: 0,
      nov_qty: 0,
      dec_qty: 0,

      stepper: ["Item Details", "Target Months", "Selecting Funds"],

      // parent
      mode_parent_id: 0,
      procurement_mode: "",
      // category
      category_modes: [],
      mode_category_id: 0,
      procurement_subMode: "",
      // sub-category
      sub_category_modes: [],
      mode_sub_category_id: 0,
      procurement_subMode_child: "",
      prefered_description: "",
      is_supplimental: false,
    });
  }
  formatNumber(num) {
    if (num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  updateItem_final() {
    if (this.state.rows?.length === 0) {
      this.setState({
        errorDialogVisible: false,
        confirmationDialogVisible: false,
        activeStep: 2,
      });
    } else {
      const {
        ppmp_item_id,
        ppmp_id,
        item_id,
        unit,
        procurement_mode,
        mode_parent_id,
        procurement_subMode,
        mode_category_id,
        procurement_subMode_child,
        mode_sub_category_id,
        IsNonOffice,
        fundType,
        RA_year,
        RA_desc,
        fundSource,
        qtyJan,
        qtyFeb,
        qtyMar,
        qtyApr,
        qtyMay,
        qtyJun,
        qtyJul,
        qtyAug,
        qtySep,
        qtyOct,
        qtyNov,
        qtyDec,
        prefered_description,
        rows,
        newTotalCost
      } = this.state;
      const { ppmp, displayAlert, displayLoading } = this.props;
      const { monthFields } = this.state;
      const forToolTip = ppmp.suppliesData;

      let newOverAllCost = Object.keys(monthFields).reduce((total, month) => {
        const { qty, price, forEdit } = monthFields[month];
        const monthQty = forToolTip[qty];
        const monthPrice = forToolTip[forEdit] > 0 ? forToolTip.for_update_price : forToolTip[price];
        return total + (monthQty * monthPrice);
      }, 0).toFixed(2);
      const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];
      const prices = {};
      months.forEach((month) => {
        const editKey = `${month}_for_edit`;
        prices[`${month}Price`] = ppmp.suppliesData[editKey] === "1"
          ? parseFloat(ppmp.suppliesData.for_update_price.replace(",", "")).toFixed(2)
          : parseFloat(ppmp.suppliesData._latest_price.replace(",", "")).toFixed(2);
      });

      let latest_price = ppmp.suppliesData.for_edit === "1" ? parseFloat(
        ppmp.suppliesData.for_update_price.replace(",", "")
      ).toFixed(2) : parseFloat(
        ppmp.suppliesData._latest_price.replace(",", "")
      ).toFixed(2);

      let qty = [
        qtyJan,
        qtyFeb,
        qtyMar,
        qtyApr,
        qtyMay,
        qtyJun,
        qtyJul,
        qtyAug,
        qtySep,
        qtyOct,
        qtyNov,
        qtyDec,
      ]
        .map((elt) => (elt ? parseFloat(elt) : 0))
        .reduce((a, b) => parseFloat(a) + parseFloat(b))
        .toFixed(2);
      let total_cost = ppmp.suppliesData.for_edit === "1" && this.state.newTotalCost === 0
        ? newOverAllCost
        : ppmp.suppliesData.for_edit === "1" && this.state.newTotalCost !== 0
          ? this.state.newTotalCost
          : (qty * latest_price).toFixed(2);
      this.setState({
        ...this.state,
        errorDialogVisible: false,
        proceedAnyway: false,
      });
      displayLoading(true);
      let mode_id =
        mode_sub_category_id > 0
          ? mode_sub_category_id
          : mode_category_id > 0
            ? mode_category_id
            : mode_parent_id > 0
              ? mode_parent_id
              : 0;
      // console.log('$$mode_id', mode_id);
      let mode =
        mode_sub_category_id > 0
          ? procurement_subMode_child
          : mode_category_id > 0
            ? procurement_subMode
            : mode_parent_id > 0
              ? procurement_mode
              : "";
      // console.log('$$mode', mode);
      // console.log('$$ppmp', ppmp);
      // console.log('$$PPMPTRANS', ppmp.transactionData.is_indicative);

      let to_save = false;
      // console.log('$$SavingStateData', this.state);
      // check if total amount from ppmp list + total cost < budget (per budget account)
      // filter ppmp list to get the total amount for this account
      // console.log('$$genPPMPDataSource', ppmp.genPPMPDataSource);
      // console.log('$$fundSource', fundSource);
      // filter allotment_class

      const fundSourceData = {
        allot_type: [],
        account_code: [],
        account_particulars: [],
        respo_code: [],
        respo_desc: [],
        ppa_code: [],
        ppa_desc: [],
        respo_desc2: [],
        respo_type: [],
        RA_year: [],
        RA_desc: [],
        fundSourceAllocatedAmount: [],
      };

      // Use reduce to concatenate fundSourceData from rows array
      rows.reduce((acc, row) => {
        const {
          allot_type,
          account_code,
          account_particulars,
          respo_code,
          respo_desc,
          ppa_code,
          ppa_desc,
          respo_desc2,
          respo_type,
          ra_year,
          ra_desc,
        } = Object.values(row)[0];
        const fundSourceAllocatedAmount = row.fundSourceAllocatedAmount;

        acc.allot_type.push(allot_type);
        acc.account_code.push(account_code);
        acc.account_particulars.push(account_particulars);
        acc.respo_code.push(respo_code);
        acc.respo_desc.push(respo_desc);
        acc.ppa_code.push(ppa_code);
        acc.ppa_desc.push(ppa_desc);
        acc.respo_desc2.push(respo_desc2);
        acc.respo_type.push(respo_type);
        acc.RA_year.push(ra_year);
        acc.RA_desc.push(ra_desc);
        acc.fundSourceAllocatedAmount.push(fundSourceAllocatedAmount);
        return acc;
      }, fundSourceData);

      // console.log('$$fundSourceData: ', fundSourceData);

      let allotment_class_filtered = ppmp.genPPMPDataSource.filter(function (
        value
      ) {
        return value.allotment_class === fundSourceData.allot_type;
      });

      // console.log('$$allotment_class_filtered', allotment_class_filtered);
      if (allotment_class_filtered.length > 0) {
        //filter fund_source
        let fund_source_filtered = allotment_class_filtered[0].funds.filter(
          function (value) {
            return (
              value.fund_source ===
              `${fundSourceData.ra_desc.join(
                ", "
              )} (${fundSourceData.account_particulars.join(", ")})`
            );
          }
        );
        // console.log('$$fund_source_filtered', fund_source_filtered);
        if (fund_source_filtered.length > 0) {
          //filter ppa_list
          let ppa_list_filtered = fund_source_filtered[0].ppa_list.filter(
            function (value) {
              return (
                value.ppa_respo ===
                `${fundSourceData.ppa_code.join(
                  ", "
                )}-${fundSourceData.ppa_desc.join(", ")}`
              );
            }
          );
          // console.log('$$ppa_list_filtered', ppa_list_filtered);
          if (ppa_list_filtered.length > 0) {
            // get ppmp_list total amount:
            let ppmp_account_items = ppa_list_filtered[0].items;
            // console.log('$$ppmp_account_items', ppmp_account_items);
            let ppmp_account_total = 0;
            // console.log('$$item_id', parseInt(item_id));
            for (let i = 0; i < ppmp_account_items.length; i++) {
              if (
                fundSourceData.respo_code.join(", ") ==
                ppmp_account_items[i].respo_code
              ) {
                // console.log('$$loop_item_id', parseInt(ppmp_account_items[i].item_id));
                if (
                  parseInt(ppmp_account_items[i].item_id) !== parseInt(item_id)
                ) {
                  ppmp_account_total += parseFloat(
                    ppmp_account_items[i].total_cost
                  );
                }
              }
            }
            // console.log('$$total_purchase', parseFloat(total_cost) + parseFloat(ppmp_account_total));
            // console.log('$$allot_balance', parseFloat(fundSource.allot_balance));
            let total_purchase = parseFloat(
              parseFloat(total_cost) + parseFloat(ppmp_account_total)
            );
            // console.log('$$total_purchase', total_purchase);
            if (
              total_purchase <=
              parseFloat(fundSourceData.allot_balance.join(", "))
            ) {
              to_save = true;
            } else {
              to_save = false;
            }
          } else {
            to_save = true;
          }
        } else {
          to_save = true;
        }
      } else {
        to_save = true;
      }
      if (to_save) {
        PPMPService.updateItem({
          ...this.state,
          ppmp_id,
          allotment_class: fundSourceData.allot_type.join(", "),
          account_code: fundSourceData.account_code.join(", "),
          account_code_description:
            fundSourceData.account_particulars.join(", "),
          respo_code: fundSourceData.respo_code.join(", "),
          respo_code_description: fundSourceData.respo_desc.join(", "),
          ppa_code: fundSourceData.ppa_code.join(", "),
          ppa_desc: fundSourceData.ppa_desc.join(", "),
          respo_desc2: fundSourceData.respo_desc2.join(", "),
          respo_type: fundSourceData.respo_type.join(", "),
          allocated_amount: fundSourceData.fundSourceAllocatedAmount.join(", "),
          procurement_mode: procurement_mode,
          year: fundSourceData.RA_year.join(", "),
          legal_basis: fundSourceData.RA_desc.join(", "),
          fundType: fundType,
          IsNonOffice: IsNonOffice,
          item_id,
          unit,
          _latest_price: latest_price,
          total_cost,
          qty,
          //   added
          jan_qty: qtyJan,
          feb_qty: qtyFeb,
          mar_qty: qtyMar,
          apr_qty: qtyApr,
          may_qty: qtyMay,
          jun_qty: qtyJun,
          jul_qty: qtyJul,
          aug_qty: qtyAug,
          sep_qty: qtySep,
          oct_qty: qtyOct,
          nov_qty: qtyNov,
          dec_qty: qtyDec,
          mode,
          mode_id,
          id: ppmp_item_id,
          prefered_description,
          user_id: this.props.data.user.id,
          user_name: this.props.data.user.username,
          jan_price: prices.janPrice,
          feb_price: prices.febPrice,
          mar_price: prices.marPrice,
          apr_price: prices.aprPrice,
          may_price: prices.mayPrice,
          jun_price: prices.junPrice,
          jul_price: prices.julPrice,
          aug_price: prices.augPrice,
          sep_price: prices.sepPrice,
          oct_price: prices.octPrice,
          nov_price: prices.novPrice,
          dec_price: prices.decPrice,
        })
          .then((res) => {
            // console.log("$$res", res);
            if (res.error) {
              if (res.fundStatus[0]) {
                this.setState({
                  ...this.state,
                  errorDialogVisible: true,
                  errorDialogMessage: `Cannot proceed! Total amount for this funds exceeded from 
                      Respo Code/s: 
                      ${res.fundStatus
                      .map((status) =>
                        status.type === "error"
                          ? ` ${status.account_code_description} with the alloted budget of ${status.alloted_amount} - ${status.status}`
                          : ""
                      )
                      .join(" | ")}`,
                  activeStep: 2,
                  proceedAnyway: ppmp.transactionData.is_indicative > 0,
                });
              } else {
                displayAlert({
                  visible: true,
                  message: `Error saving item! ${res?.errmsg}`,
                  severity: "error",
                });
              }
            } else {
              // console.log('$$_ret', res.data[0]._ret);
              let item_tagged_accounts = res.data[0]?._tagged_accounts;
              if (res.data[0]._ret === "unauthorized_action") {
                displayAlert({
                  visible: true,
                  message: "Unauthorized Access!",
                  severity: "error",
                });
                this.closeModal();
                this.logOut();
              } else if (res.data[0]._ret === "invalid_account_code") {
                // displayAlert({
                //   visible: true,
                //   message: "Invalid account code for the selected item, Please Select another account code!",
                //   severity: "warning",
                // });
                // displayLoading(false);
                // this.state.activeStep = 2;

                displayLoading(false);
                this.setState({
                  ...this.state,
                  errorDialogVisible: true,
                  errorDialogMessage: `Invalid account code for the selected item, please select the correct account code for this item! 
                    ${item_tagged_accounts
                      ? `| Account(suggested) code tagged for this item: ${item_tagged_accounts}`
                      : `
                      | Possible cause:
                      1. This item not yet tagged by PBMO (Contact PBMO). 
                      2. Incorrect account code for this specific item (Select the correct account code).`
                    }`,
                  activeStep: 2,
                  proceedAnyway: 0,
                });
              } else {
                displayAlert({
                  visible: true,
                  message: "Item saved successfully!",
                  severity: "success",
                });
                this.closeModal();
                this.resetState();
              }
            }
            // displayAlert({
            // 	visible: true,
            // 	message: "Success!",
            // 	severity: "success",
            // });
            this.props.requestGeneratedPPMP();
            displayLoading(false);
          })
          .catch((err) => {
            displayAlert({
              visible: true,
              message: "Oops! Error occured, please try again",
              severity: "warning",
            });
            displayLoading(false);
          });
      } else {
        // console.log("$$not save!");
        displayLoading(false);
        this.setState({
          ...this.state,
          errorDialogVisible: true,
          errorDialogMessage:
            "Cannot proceed! Total amount for this funds exceeded!",
          activeStep: 2,
          proceedAnyway: ppmp.transactionData.is_indicative > 0,
        });
      }
    }
  }
  updateItemFinalMultipleRespo() { }
  onChangeHandler(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value === "" ? 0 : e.target.value,
      });
    }
  }
  onChangeHandlerForEdit(e) {
    const { ppmp } = this.props;
    const regex = /^[0-9\b]+$/;
    const forToolTip = ppmp.suppliesData;
    const updatedQty = e.target.value === "" ? 0 : parseInt(e.target.value);
    const { monthFields } = this.state;
    let newTotalCost = 0;
    let updatedState = { ...this.state };
    let monthlyCosts = {};

    Object.keys(monthFields).forEach((month) => {
      const { qty, price, forEdit } = monthFields[month];
      const monthQty = e.target.name === month ? updatedQty : this.state[month] || forToolTip[qty];

      // Format month cost to 2 decimal places
      const monthCost = parseFloat((monthQty * (forToolTip[forEdit] > 0 ? forToolTip.for_update_price : forToolTip[price])).toFixed(2));

      monthlyCosts[month] = monthCost;
      newTotalCost += monthCost;

      if (e.target.name === month) {
        updatedState[month] = updatedQty === 0 ? 0 : updatedQty;
      }
    });
    newTotalCost = parseFloat(newTotalCost.toFixed(2));

    const isTotalCostExceeded = newTotalCost > forToolTip.total_cost;

    if (regex.test(e.target.value) || e.target.value === "") {
      this.setState({
        ...updatedState,
        newTotalCost,
        isTotalCostExceeded,
        updatedQty,
        monthlyCosts,
      });
    }
  }

  onHandleEditData(row) {
    this.setState({ dialogVisible: true, editDataQty: row });
    this.onHandleEdit(row);
  }
  onHandleEdit(row) {
    this.setState({
      ItemName: this.state.editDataQty.name,
      ItemDescription: this.state.editDataQty.description,
      UnitCost: Number(this.state.editDataQty.unit_cost),
      qtyJan: this.state.editDataQty.qty_jan,
      qtyFeb: this.state.editDataQty.qty_feb,
      qtyMar: this.state.editDataQty.qty_mar,
      qtyApr: this.state.editDataQty.qty_apr,
      qtyMay: this.state.editDataQty.qty_may,
      qtyJun: this.state.editDataQty.qty_jun,
      qtyJul: this.state.editDataQty.qty_jul,
      qtyAug: this.state.editDataQty.qty_aug,
      qtySep: this.state.editDataQty.qty_sep,
      qtyOct: this.state.editDataQty.qty_oct,
      qtyNov: this.state.editDataQty.qty_nov,
      qtyDec: this.state.editDataQty.qty_dec,
      itemCost: this.state.editDataQty.unit_cost,
      id: this.state.editDataQty.ppmp_item_id,
    });
    this.props.displaySupplies({ modalVisible: false, suppliesData: {} });
  }

  initRespoCenter() {
    const { ppmp, data } = this.props;
    const { modes } = this.state;
    const passed_data = ppmp.suppliesData;
    // console.log('$$data', passed_data);
    // console.log('$$parent_id', this.props.ppmpParentId);

    // pass app quarter id
    let quarter_1_app_id = passed_data.app_id_1st_quarter;
    let quarter_2_app_id = passed_data.app_id_2nd_quarter;
    let quarter_3_app_id = passed_data.app_id_3rd_quarter;
    let quarter_4_app_id = passed_data.app_id_4th_quarter;

    // check if it has app_quarter id  to validate
    let quarter_1_disable =
      quarter_4_app_id > 0
        ? true
        : quarter_3_app_id > 0
          ? true
          : quarter_2_app_id > 0
            ? true
            : quarter_1_app_id > 0
              ? true
              : false;
    let quarter_2_disable =
      quarter_4_app_id > 0
        ? true
        : quarter_3_app_id > 0
          ? true
          : quarter_2_app_id > 0
            ? true
            : false;
    let quarter_3_disable =
      quarter_4_app_id > 0 ? true : quarter_3_app_id > 0 ? true : false;
    let quarter_4_disable = quarter_4_app_id > 0 ? true : false;

    this.setState(
      {
        ...this.state,
        ppmp_item_id: passed_data.ppmp_item_id,
        ppmp_id: passed_data.ppmp_id,
        item_id: passed_data.item_id,
        unit: passed_data.unit,
        is_supplimental: parseInt(this.props.ppmpParentId) > 0,

        activeStep: 0,

        qtyJan: passed_data.qty_jan,
        qtyFeb: passed_data.qty_feb,
        qtyMar: passed_data.qty_mar,
        qtyApr: passed_data.qty_apr,
        qtyMay: passed_data.qty_may,
        qtyJun: passed_data.qty_jun,
        qtyJul: passed_data.qty_jul,
        qtyAug: passed_data.qty_aug,
        qtySep: passed_data.qty_sep,
        qtyOct: passed_data.qty_oct,
        qtyNov: passed_data.qty_nov,
        qtyDec: passed_data.qty_dec,

        quarter_1_disable,
        quarter_2_disable,
        quarter_3_disable,
        quarter_4_disable,
      },
      () => {
        // console.log('$$state', this.state);
        // console.log("$$modes", modes);

        let mode_of_procurement_id = passed_data.mode_of_procurement_id;
        if (mode_of_procurement_id > 0) {
          // get the level - filter
          let mode_level = modes.filter(
            (element) =>
              parseInt(element.id) === parseInt(mode_of_procurement_id)
          )[0].mode_level;
          let sub_category_id = 0,
            category_id = 0,
            parent_id = 0;
          if (mode_level === 3) {
            // have parent, category & sub-categpory
            sub_category_id = mode_of_procurement_id;
            category_id = modes.filter(
              (element) => parseInt(element.id) === parseInt(sub_category_id)
            )[0].parent_id;
            parent_id = modes.filter(
              (element) => parseInt(element.id) === parseInt(category_id)
            )[0].parent_id;
          } else if (mode_level === 2) {
            category_id = mode_of_procurement_id;
            parent_id = modes.filter(
              (element) => parseInt(element.id) === parseInt(category_id)
            )[0].parent_id;
          } else {
            parent_id = mode_of_procurement_id;
          }
          if (sub_category_id > 0 || category_id > 0 || parent_id > 0) {
            // console.log('$$parent_id:', parent_id);
            // console.log('$$category_id:', category_id);
            // console.log('$$sub_category_id:', sub_category_id);
            this.setState(
              {
                ...this.state,
                mode_parent_id: parent_id,
                procurement_mode: modes.filter(
                  (element) => parseInt(element.id) === parseInt(parent_id)
                )[0].mode_name,
              },
              () => {
                if (category_id > 0) {
                  let category_modes = modes.filter(
                    (element) =>
                      parseInt(element.parent_id) === parseInt(parent_id)
                  );
                  let procurement_subMode = modes.filter(
                    (element) => parseInt(element.id) === parseInt(category_id)
                  )[0].mode_name;

                  this.setState(
                    {
                      ...this.state,
                      mode_category_id: category_id,
                      category_modes,
                      procurement_subMode,
                    },
                    () => {
                      if (sub_category_id > 0) {
                        let sub_category_modes = modes.filter(
                          (element) =>
                            parseInt(element.parent_id) ===
                            parseInt(category_id)
                        );
                        let procurement_subMode_child = modes.filter(
                          (element) =>
                            parseInt(element.id) === parseInt(sub_category_id)
                        )[0].mode_name;

                        this.setState({
                          mode_sub_category_id: sub_category_id,
                          sub_category_modes,
                          procurement_subMode_child,
                        });
                      }
                    }
                  );
                }
              }
            );
          }
        }
      }
    );

    if (this.state.respoCodeValue === null) {
      this.initDefaultRespoValues();
    } else {
      this.requestRAyear();
    }
    // catering data:
    if (passed_data.category_name.indexOf("Catering") !== -1) {
      // setup year:
      let stepper_array = [
        "Item Details",
        "Selecting Funds",
        "Target Months",
        "Catering Details",
      ];

      //get ppmp year
      let currDate = new Date();
      let ppmpYear = parseInt(currDate.getFullYear());
      sessionService
        .loadSession()
        .then((currentSession) => {
          const data2 = currentSession.selectedTransaction;
          ppmpYear = data2.year;
          this.setState(
            {
              stepper: stepper_array,
              activity_year: ppmpYear,
              activity_type_id:
                ppmpYear == passed_data.catering_office_activities_year
                  ? passed_data.catering_office_activity_id
                  : 0,
              selected_activity_id:
                ppmpYear == passed_data.catering_office_activities_year
                  ? passed_data.catering_office_activity_id
                  : 0,
              // office_id: (ppmpYear == passed_data.catering_office_activities_year) ? parseInt(data.user.office_id) : 0,
              office_id: parseInt(data.user.office_id),
            },
            () => {
              this.getYear();
            }
          );
        })
        .catch((err) => {
          this.props.displayAlert({
            visible: true,
            message: err,
            severity: "warning",
          });
        });
    }
  }
  getYear() {
    const { data } = this.props;
    let currDate = new Date();
    let currYear = parseInt(currDate.getFullYear());

    //get year +1 & -2
    let yearRange = [currYear - 2, currYear - 1, currYear, currYear + 1];
    // this.setState(
    // 	{
    // 		year_range: yearRange,
    // 	},
    // 	() => {
    // 		this.onChangeCateringYear(this.state.activity_year);
    // 	}
    // );
    sessionService
      .loadSession()
      .then((currentSession) => {
        const data2 = currentSession.selectedTransaction;
        this.setState(
          {
            ...this.state,
            year: data2.year,
            year_range: yearRange,
            office_id: parseInt(data.user.office_id),
          },
          () => {
            this.onChangeCateringYear(this.state.activity_year);
          }
        );
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "warning",
        });
      });
  }
  onChangeCateringYear(year) {
    // console.log('$$year', year);
    const { displayLoading, displayAlert, ppmp } = this.props;
    const passed_data = ppmp.suppliesData;
    displayLoading(true);
    ActivitiesService.getOfficeActivitiesEditModal({ ...this.state })
      .then((response) => {
        // console.log("$$response", response);
        if (!response.error) {
          this.setState(
            {
              ...this.state,
              activities: response.data,
            },
            () => {
              // console.log('$$activities', response.data);
              if (this.state.first_load) {
                this.setState(
                  {
                    ...this.state,
                    first_load: false,
                  },
                  () => {
                    this.showActivityDetails(
                      passed_data.catering_office_activity_id
                    );
                  }
                );
              }
            }
          );
        } else {
          this.setState({
            ...this.state,
            activities: [],
            selected_activity_id: 0,
          });
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }
  requestRespoCenterData() {
    const {
      data,
      displayAlert,
      displayLoading,
      setRespoCenterDataSource,
      setRespoDefaultValues,
      ppmpId,
    } = this.props;
    // console.log('$$props', this.props);
    // console.log('$$ppmp_id', ppmpId);
    if (ppmpId) {
      PPMPService.getRespoCenterData2(ppmpId)
        .then((res) => {
          const respoCenterData = res.data;
          if (respoCenterData.length > 0) {
            setRespoCenterDataSource({ respoCenterData });
            const defval = data.user;
            const respoCodeValue = respoCenterData.filter((item) => {
              return (
                item.ppa_code === defval.fpp &&
                item.respo_code === defval.respo_center
              );
            });
            setRespoDefaultValues({ respoCodeValue: respoCodeValue[0] });
            this.setState({ ...this.state, respoCodeValue: respoCodeValue[0] });
          }
        })
        .catch((err) => {
          displayAlert({
            alertVisible: true,
            alertMessage: err,
            alertSeverity: "warning",
          });
          displayLoading(false);
        });
    }
    //get modes
    this.requestGetProcModes();
    this.getYear();
  }
  requestGetProcModes() {
    const { displayLoading, displayAlert } = this.props;
    displayLoading(true);
    let arr = {
      keyword: "",
      total: 0,
      page_no: 0,
      limit: 100,
      sort_column: "",
      sort_type: "",
      filter_business_personality: "",
      filter_nationality: "",
      filter_nature_of_operation: "",
    };
    // console.log(arr);
    ModesService.getPRModes(arr)
      .then((response) => {
        // console.log("$$response", response);
        let data = response.data.result0;
        let parent_modes = data.filter((element) => 0 == element.parent_id);
        this.setState(
          {
            ...this.state,
            modes: data,
            parent_modes,
            monthly_closing: response.data.result4,
            monthly_closing_year: this.state.year,
          },
          () => {
            // console.log("$$monthly_closing", this.state.monthly_closing);
            // console.log('$$monthly_closing_year', this.state.monthly_closing_year);
          }
        );
        // console.log('$$modes1', data);
        // console.log('$$parent_modes', parent_modes);
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }
  requestGetProcModesParent(parent_id) {
    // console.log('$$parent_id', parent_id);
    const { modes } = this.state;
    let data = modes;
    let category_modes = data.filter(
      (element) => element.parent_id == parent_id
    );
    let parent_name = data.filter((element) => element.id == parent_id)[0]
      .mode_name;
    // console.log('$$parent_name', parent_name);
    // console.log('$$category_modes', category_modes);
    if (category_modes) {
      this.setState({
        ...this.state,
        category_modes,
        mode_parent_id: parent_id,
        procurement_mode: parent_name,

        mode_category_id: 0,
        procurement_subMode: "",

        sub_category_modes: [],
        mode_sub_category_id: 0,
        procurement_subMode_child: "",
      });
    }
  }
  requestGetProcModesCategory(category_id) {
    // console.log('$$category_id', category_id);
    const { modes } = this.state;
    let data = modes;
    let sub_category_modes = data.filter(
      (element) => element.parent_id == category_id
    );
    let category_name = data.filter((element) => element.id == category_id)[0]
      .mode_name;
    // console.log('$$category_name', category_name);
    // console.log('$$sub_category_modes', sub_category_modes);
    this.setState({
      ...this.state,
      sub_category_modes,
      mode_category_id: category_id,
      procurement_subMode: category_name,

      mode_sub_category_id: 0,
      procurement_subMode_child: "",
    });
  }
  requestGetProcModesSubCategory(sub_category_id) {
    // console.log('$$sub_category_id', sub_category_id);
    const { modes } = this.state;
    let data = modes;
    let sub_category_name = data.filter(
      (element) => element.id == sub_category_id
    )[0].mode_name;
    // console.log('$$sub_category_name', sub_category_name);
    this.setState({
      ...this.state,

      mode_sub_category_id: sub_category_id,
      procurement_subMode_child: sub_category_name,
    });
  }
  requestRAyear() {
    const { displayAlert, displayLoading, setRespoDefaultValues, ppmp } =
      this.props;
    const { respoCodeValue } = this.state;
    PPMPService.getRAyear({
      respo_code: respoCodeValue.respo_code,
      ppmp_id: ppmp.transactionData.id,
    })
      .then((res) => {
        var data = res.data;
        if (typeof data === "string") {
          this.setState(
            { ...this.state, RA_year: "", RA_year_choices: [{ ra_year: "" }] },
            () => this.requestFundList()
          );
          displayLoading(false);
        } else {
          if (data.length > 0) {
            const RA_year = data[0].ra_year;
            // console.log('$$RA_year', RA_year);
            setRespoDefaultValues({ RA_year, IsNonOffice: false });
            this.setState(
              {
                ...this.state,
                RA_year,
                RA_year_choices: data,
                monthly_closing_year: RA_year,
              },
              () => this.requestFundList()
            );
            // temp
            let y =
              parseInt(
                this.state.monthly_closing.filter(
                  (value) =>
                    parseInt(value.month) === 1 &&
                    parseInt(value.year) ===
                    parseInt(this.state.monthly_closing_year)
                )[0]?.is_open
              ) === 1;
            // console.log('$$monthly_closing_year', this.state.monthly_closing_year);
            // console.log('$$monthly_closing', this.state.monthly_closing);
            // console.log('$$y', y);
          }
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }
  requestFundList() {
    const { displayAlert, setRespoDefaultValues, displayLoading, ppmp } =
      this.props;
    const { RA_year, respoCodeValue } = this.state;
    PPMPService.getAccountCodePerRespo({
      respo_code: respoCodeValue.respo_code,
      year: RA_year,
      ppmp_id: ppmp.transactionData.id,
    })
      .then((res) => {
        var data = res.data;
        if (typeof data === "string") {
          this.setState({
            ...this.state,
            RA_desc: "",
            fundType: "",
            PPA_code: "",
          });
          displayLoading(false);
        } else {
          if (data.length > 0) {
            let dataFiltered = data;
            // let dataFiltered = data.filter((item) => {
            // 	if (IsNonOffice) {
            // 		return (
            // 			item.ppa_code !==
            // 			ppmp?.respoDefaultParams?.respoCodeValue?.ppa_code
            // 		);
            // 	} else {
            // 		return (
            // 			item.ppa_code ===
            // 			ppmp?.respoDefaultParams?.respoCodeValue?.ppa_code
            // 		);
            // 	}
            // });
            var _entryData = dataFiltered[0];
            setRespoDefaultValues({
              RA_desc: _entryData.ra_desc,
              fundType: _entryData.fund_type,
              PPA_code: _entryData.ppa_code,
            });
            this.setState(
              {
                ...this.state,
                RA_desc: _entryData.ra_desc,
                fundType: _entryData.fund_type,
                PPA_code: _entryData.ppa_code,
                respoAccount_choices: dataFiltered,
                legalBasisChoices: [
                  ...new Set(dataFiltered.map((e, i) => e.ra_desc)),
                ],
                fundTypeChoices: [
                  ...new Set(dataFiltered.map((e, i) => e.fund_type)),
                ],
              },
              () => {
                this.state.sourceFundList.length === 0
                  ? this.requestSourceOfFund()
                  : displayLoading(false);
              }
            );
          }
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }
  requestSourceOfFund() {
    const { displayAlert, displayLoading, ppmp } = this.props;
    const { respoCodeValue } = this.state;
    let sp_used = this.state.IsNonOffice
      ? "sp_account_get_fundsource_nonoffice3"
      : "sp_account_get_fundsource3";
    displayLoading(true);
    if (respoCodeValue) {
      PPMPService.getSourceOfFundData({
        ...this.state,
        respo_code: respoCodeValue.respo_code,
        sp_used,
        ppmp_id: ppmp.transactionData.id,
      })
        .then((res) => {
          if (typeof res.data === "string") {
            this.setState({
              ...this.state,
              sourceFundList: [],
              fundSource: null,
            });
          } else {
            this.setState(
              {
                ...this.state,
                sourceFundList: res.data,
                fundSource: null,
              },
              () => {
                let sourceFundList = res.data;
                const data = ppmp.suppliesData;
                if (data.ppmp_item_id > 0) {
                  // continue code_here	account_code_description == account_particulars
                  let fundSource = sourceFundList.filter(
                    (element) =>
                      element.account_particulars ==
                      data.account_code_description
                  )[0];
                  // console.log('$$fundSource', fundSource);
                  this.setState({
                    ...this.state,
                    // funds
                    fundSource,
                  });
                  // console.log('$$state', this.state);
                }
              }
            );
          }
        })
        .catch((err) => {
          displayAlert({
            alertVisible: true,
            alertMessage: err,
            alertSeverity: "warning",
          });
        });
    } else {
      // do nothing
    }
    displayLoading(false);
  }
  onHandleRespoCodeAutocomplete(event, value) {
    this.setState({ ...this.state, respoCodeValue: value }, () => {
      if (value) {
        this.props.displayLoading(true);
        this.requestRAyear();
      }
    });
  }
  // onHandleSourceFundAutocomplete(event, value) {
  // 	this.setState({ ...this.state, fundSource: value });
  // }
  onHandleSourceFundAutocomplete = (event, value, index) => {
    const { rows } = this.state;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], fundSource: value };
    this.setState({ rows: updatedRows });
  };

  onHandleTargetChange(event) {
    var value = event.target.defaultValue;
    if (!isNaN(value)) {
      this.setState({ ...this.state, [event.target.name]: value });
    }
  }
  onHandleTextChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }
  onHandleSelectChange(event) {
    // console.log('$$event', event);
    this.props.displayLoading(true);
    this.setState(
      { ...this.state, [event.target.name]: event.target.value },
      () => {
        if (event.target.name === "RA_year") {
          this.requestFundList();
        } else if (event.target.name === "procurement_mode") {
          let parent_id = event.target.value;
          this.requestGetProcModesParent(parent_id);
        } else if (event.target.name === "procurement_category") {
          let category_id = event.target.value;
          this.requestGetProcModesCategory(category_id);
        } else if (event.target.name === "sub_category") {
          let sub_category_id = event.target.value;
          this.requestGetProcModesSubCategory(sub_category_id);
        } else if (event.target.name === "activity_year") {
          let year_selected = event.target.value;
          // console.log('$$year_selected', year_selected);
          this.setState(
            {
              selected_activity_id: 0,
              activities: [],
            },
            () => {
              this.onChangeCateringYear(year_selected);
            }
          );
        } else if (event.target.name === "selected_activity_id") {
          let selected_activity_id = event.target.value;
          this.showActivityDetails(selected_activity_id);
        } else {
          this.requestSourceOfFund();
        }
      }
    );
    this.props.displayLoading(false);
  }
  showActivityDetails(activity_id) {
    // console.log('$$activity_id', activity_id);
    const { activities } = this.state;
    // console.log('$$activities', activities);
    if (activity_id > 0 && activities) {
      let activity_details = activities.filter(
        (element) => element.id == activity_id
      )[0];
      // console.log('$$activity_details', activity_details);
      if (activity_details) {
        this.setState({
          ...this.state,
          selected_activity: activity_details,
        });
      }
    }
  }
  onHandleCheckboxChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
    if (event.target.name === "checkedRCA") {
      if (!event.target.checked) {
        this.initDefaultRespoValues();
      }
    }
  }
  initDefaultRespoValues() {
    const { ppmp } = this.props;
    const value = ppmp.respoDefaultParams;
    this.setState(
      {
        IsNonOffice: false,
        respoCodeValue: value.respoCodeValue,
      },
      () => {
        // this.initRespoCenter();
      }
    );
  }
  onClickAdvanceSearch(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      advanceSearchVisible: !this.state.advanceSearchVisible,
    });
  }
  closeModal() {
    this.setState({
      ...this.state,
      activeStep: 0,
      advanceSearchVisible: false,
      qtyJan: "",
      qtyFeb: "",
      qtyMar: "",
      qtyApr: "",
      qtyMay: "",
      qtyJun: "",
      qtyJul: "",
      qtyAug: "",
      qtySep: "",
      qtyOct: "",
      qtyNov: "",
      qtyDec: "",
      // jan_qty: "",
      // feb_qty: "",
      // mar_qty: "",
      // apr_qty: "",
      // may_qty: "",
      // jun_qty: "",
      // jul_qty: "",
      // aug_qty: "",
      // sep_qty: "",
      // oct_qty: "",
      // nov_qty: "",
      // dec_qty: "",
      IsNonOffice: false,
      RA_year: "",
      RA_desc: "",
      fundType: "",
      PPA_code: "",
      respo_code: "",
      procurement_mode: "",
      procurement_subMode: "",
      procurement_subMode_child: "",

      stepper: ["Item Details", "Selecting Funds", "Target Months"],
    });
    this.props.displaySupplies({ modalVisible: false, suppliesData: {} });
    this.resetState();
  }
  accountCodeRenderOptions(option) {
    return (
      <div className={this.props.classes.OptionContainer}>
        <Material.Typography variant="subtitle1">
          {option.account_particulars}
        </Material.Typography>
        <div>
          <div className="inline padded">
            <Material.Typography color="primary" variant="caption">
              FPP:{" "}
            </Material.Typography>
            <Material.Typography variant="caption">
              {option.ppa_code} {option.ppa_desc}
            </Material.Typography>
          </div>
          <div className="inline padded">
            <Material.Typography color="primary" variant="caption">
              Legal Basis:{" "}
            </Material.Typography>
            <Material.Typography variant="caption">
              {option.ra_desc}
            </Material.Typography>
          </div>
        </div>
      </div>
    );
  }
  respoCodeRenderOptions(option) {
    return (
      <div className={this.props.classes.OptionContainer}>
        <Material.Typography variant="subtitle1">
          {option.respo_code}. {option.office_name}
        </Material.Typography>
        <div>
          <div className="inline padded">
            <Material.Typography color="primary" variant="caption">
              Respo Type:{" "}
            </Material.Typography>
            <Material.Typography variant="caption">
              {option.respo_type}
            </Material.Typography>
          </div>
          <div className="inline padded">
            <Material.Typography color="primary" variant="caption">
              FPP:{" "}
            </Material.Typography>
            <Material.Typography variant="caption">
              {option.ppa_code}: {option.ppa_desc}
            </Material.Typography>
          </div>
        </div>
      </div>
    );
  }

  getStepContent(stepIndex) {
    const { ppmp, classes } = this.props;
    const forToolTip = ppmp.suppliesData;
    const { isTotalCostExceeded, newTotalCost, monthlyCosts, monthFields } = this.state;
    let newOverAllCost = Object.keys(monthFields).reduce((total, month) => {
      const { qty, price, forEdit } = monthFields[month];
      const monthQty = forToolTip[qty];
      const monthPrice = forToolTip[forEdit] > 0 ? forToolTip.for_update_price : forToolTip[price];
      return total + (monthQty * monthPrice);
    }, 0);

    // Ensure the final result has two decimal points
    newOverAllCost = parseFloat(newOverAllCost).toFixed(2);

    switch (stepIndex) {
      case 0:
        const data = ppmp.suppliesData;
        const { parent_modes, category_modes, sub_category_modes } = this.state;
        this.state.fundSource = null;

        return (
          <div>
            <div className="title">
              <Material.Typography variant="subtitle1">
                Item Details
              </Material.Typography>
            </div>
            <Material.Divider></Material.Divider>
            <Material.Grid container spacing={3}>
              <Material.Grid item xs={7}>
                <div className="inline">
                  <Material.Typography color="primary" variant="subtitle1">
                    Category:
                  </Material.Typography>
                  <Material.Typography variant="subtitle1">
                    {data.category_name}
                  </Material.Typography>
                </div>
                <div className="inline">
                  <Material.Typography color="primary" variant="subtitle1">
                    Serial No:
                  </Material.Typography>
                  <Material.Typography variant="subtitle1">
                    {data.serial_no}
                  </Material.Typography>
                </div>
              </Material.Grid>
              <Material.Grid item xs={5}>
                <div className="inline">
                  <Material.Typography color="primary" variant="subtitle1">
                    Unit:
                  </Material.Typography>
                  <Material.Typography variant="subtitle1">
                    {data.unit}
                  </Material.Typography>
                </div>
                <div className="inline">
                  <Material.Typography color="primary" variant="subtitle1">
                    Price:
                  </Material.Typography>
                  <Material.Typography variant="subtitle1">
                    Php {data.for_edit === "1" ? data.for_update_price : data._latest_price}
                  </Material.Typography>
                </div>
              </Material.Grid>
            </Material.Grid>
            {/* <div className="inline">
							<Material.Typography color="primary" variant="subtitle1">
								Saved Procurement Mode:
							</Material.Typography>
							<Material.Typography variant="subtitle1">
								{data.mode_of_procurement}
							</Material.Typography>
						</div> */}
            <Material.Divider></Material.Divider>
            <div className="full-width-choices">
              {
                <Material.FormControl className={classes.formControlMax}>
                  <Material.InputLabel id="procurement-mode-label">
                    Reselect / Select New Mode of Procurement *
                  </Material.InputLabel>
                  <Material.Select
                    // defaultValue={this.props.ppmp.suppliesData.mode_of_procurement}
                    inputProps={{
                      labelId: "procurement-mode-select",
                      id: "procurement-mode-select",
                      name: "procurement_mode",
                    }}
                    value={this.state.mode_parent_id}
                    onChange={this.onHandleSelectChange}
                  >
                    {/* <Material.Typography variant="h6">
                                            Public Bidding
                                        </Material.Typography> */}
                    {parent_modes.map((item, index) => {
                      return (
                        <Material.MenuItem
                          key={`procurement-mode-item-${index}`}
                          value={item.id}
                        >
                          {item.mode_name}
                        </Material.MenuItem>
                      );
                    })}
                  </Material.Select>
                </Material.FormControl>
              }

              {this.state.category_modes.length > 0 && (
                <Material.FormControl className={classes.formControlMax}>
                  <Material.InputLabel id="procurement-subMode-label">
                    Sub category *
                  </Material.InputLabel>
                  <Material.Select
                    labelId="procurement-subMode-select"
                    id="procurement-subMode-select"
                    name="procurement_category"
                    value={this.state.mode_category_id}
                    onChange={this.onHandleSelectChange}
                  >
                    {category_modes.map((item, index) => {
                      return (
                        <Material.MenuItem
                          key={`procurement-category-item-${index}`}
                          value={item.id}
                        >
                          {item.mode_name}
                        </Material.MenuItem>
                      );
                    })}
                  </Material.Select>
                </Material.FormControl>
              )}

              {this.state.sub_category_modes.length > 0 &&
                this.state.category_modes.length > 0 && (
                  <Material.FormControl className={classes.formControlMax}>
                    <Material.InputLabel id="procurement-subMode-child-label">
                      Sub category *
                    </Material.InputLabel>
                    <Material.Select
                      labelId="procurement-subMode-child-select"
                      id="procurement-subMode-child-select"
                      name="sub_category"
                      value={this.state.mode_sub_category_id}
                      onChange={this.onHandleSelectChange}
                    >
                      {sub_category_modes.map((item, index) => {
                        return (
                          <Material.MenuItem
                            key={`procurement-subMode-item-${index}`}
                            value={item.id}
                          >
                            {item.mode_name}
                          </Material.MenuItem>
                        );
                      })}
                    </Material.Select>
                  </Material.FormControl>
                )}

              {this.state.category_modes.length > 0 && (
                <Material.FormControl className={classes.formControlMax}>
                  <Material.TextField
                    id="prefered_description"
                    name="prefered_description"
                    onChange={this.onHandleTextChange}
                    label="Prefered Item Description/Brand/Specification/Remarks *"
                    multiline
                    value={this.state.prefered_description}
                  />
                </Material.FormControl>
              )}
            </div>
          </div>
        );
      case 1:
        if (forToolTip.for_edit !== "1") {
          return (
            <div>
              <Material.Grid
                // aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                // editdata={editDataQty}
                onRendered={this.onHandleEdit}
              >
                <div className="title">
                  <Material.Typography variant="subtitle1">
                    Target of Procurement
                  </Material.Typography>
                </div>
                <Material.Grid container spacing={3} alignItems="center">
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 1 &&
                            value.year === this.state.year
                        );
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true; // Disable TextField
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(1)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false // Exclude unforeseen and emergency
                              : false;
                      })()}
                      id="qtyJan"
                      onChange={this.onChangeHandler}
                      name="qtyJan"
                      label="January"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyJan}
                      helperText={this.props.ppmp.suppliesData.unit}

                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 2 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(2)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyFeb"
                      onChange={this.onChangeHandler}
                      name="qtyFeb"
                      label="February"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyFeb}
                      helperText={this.props.ppmp.suppliesData.unit}

                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 3 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true; // Disable TextField
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(3)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyMar"
                      onChange={this.onChangeHandler}
                      name="qtyMar"
                      label="March"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyMar}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 4 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(4)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyApr"
                      onChange={this.onChangeHandler}
                      name="qtyApr"
                      label="April"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyApr}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 5 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(5)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyMay"
                      onChange={this.onChangeHandler}
                      name="qtyMay"
                      label="May"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyMay}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 6 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(6)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyJun"
                      onChange={this.onChangeHandler}
                      name="qtyJun"
                      label="June"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyJun}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 7 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(7)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyJul"
                      onChange={this.onChangeHandler}
                      name="qtyJul"
                      label="July"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyJul}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 8 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(8)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyAug"
                      onChange={this.onChangeHandler}
                      name="qtyAug"
                      label="August"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyAug}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 9 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(9)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtySep"
                      onChange={this.onChangeHandler}
                      name="qtySep"
                      label="September"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtySep}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 10 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(10)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyOct"
                      onChange={this.onChangeHandler}
                      name="qtyOct"
                      label="October"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyOct}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 11 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(11)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyNov"
                      onChange={this.onChangeHandler}
                      name="qtyNov"
                      label="November"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyNov}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 12 &&
                            value.year === this.state.year
                        );

                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(12)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyDec"
                      onChange={this.onChangeHandler}
                      name="qtyDec"
                      label="December"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyDec}
                      helperText={this.props.ppmp.suppliesData.unit}
                    />
                  </Material.Grid>
                </Material.Grid>
              </Material.Grid>
            </div>
          );
        } else {
          return (
            <div>
              <Material.Grid
                // aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                // editdata={editDataQty}
                onRendered={this.onHandleEdit}
              >
                <div className="title">
                  <Material.Typography variant="subtitle1">
                    Target of Procurement
                  </Material.Typography>
                </div>

                <Material.Grid container spacing={3} alignItems="center">
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.jan_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 1 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.jan_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true; // Disable TextField
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(1)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false // Exclude unforeseen and emergency
                              : false;
                      })()}
                      id="qtyJan"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyJan"
                      label="January"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyJan}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.jan_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of January: ${forToolTip.jan_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.jan_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_jan === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_jan
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.jan_for_edit > 0
                                      ? "red" // Red if either condition is met
                                      : "grey", // Grey otherwise
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.feb_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 2 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.feb_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(2)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyFeb"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyFeb"
                      label="February"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyFeb}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.feb_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of February: ${forToolTip.feb_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.feb_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_feb === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_feb
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.feb_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.mar_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 3 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.mar_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true; // Disable TextField
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(3)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyMar"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyMar"
                      label="March"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyMar}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.mar_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of March: ${forToolTip.mar_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.mar_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_mar === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_mar
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.mar_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.apr_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 4 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.apr_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(4)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyApr"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyApr"
                      label="April"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyApr}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.apr_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of April: ${forToolTip.apr_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.apr_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_apr === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_apr
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.apr_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.may_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 5 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.may_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(5)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyMay"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyMay"
                      label="May"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyMay}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.may_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of May: ${forToolTip.may_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.may_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_may === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_may
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.may_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.jun_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 6 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.jun_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(6)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyJun"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyJun"
                      label="June"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyJun}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.jun_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of June: ${forToolTip.jun_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.jun_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_jun === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_jun
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.jun_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.jul_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 7 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.jul_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(7)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyJul"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyJul"
                      label="July"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyJul}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.jul_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of July: ${forToolTip.jul_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.jul_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_jul === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_jul
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.jul_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.aug_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 8 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.aug_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(8)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyAug"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyAug"
                      label="August"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyAug}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.aug_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of August: ${forToolTip.aug_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.aug_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_aug === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_aug
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.aug_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.sep_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 9 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.sep_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(9)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtySep"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtySep"
                      label="September"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtySep}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.sep_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of September: ${forToolTip.sep_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.sep_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_sep === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_sep
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.sep_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.oct_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 10 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.oct_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(10)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyOct"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyOct"
                      label="October"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyOct}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.oct_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of October: ${forToolTip.oct_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.oct_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_oct === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_oct
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.oct_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.nov_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 11 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.nov_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(11)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyNov"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyNov"
                      label="November"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyNov}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.nov_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of November: ${forToolTip.nov_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.nov_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_nov === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_nov
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.nov_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      error={forToolTip.dec_for_edit === "1" ? isTotalCostExceeded : ""}
                      className={classes.textField}
                      disabled={(() => {
                        const foundItem = this.state.monthly_closing.filter(
                          (value) =>
                            parseInt(value.month) === 12 &&
                            value.year === this.state.year
                        );
                        if (forToolTip.dec_for_edit === "0") {
                          return true; // Disable TextField
                        }
                        if (
                          foundItem.length === 0 ||
                          foundItem[0]?.is_open === "0"
                        ) {
                          return true;
                        } else {
                          if (parseInt(foundItem[0].is_open) === 0) {
                            return true;
                          }
                        }

                        return parseInt(ppmp.transactionData?.is_indicative) > 0
                          ? false
                          : this.state.is_supplimental
                            ? !this.isSupplimentalByPass(12)
                            : parseInt(this.props.isUnforeseen) === 1
                              ? false
                              : false;
                      })()}
                      id="qtyDec"
                      onChange={this.onChangeHandlerForEdit}
                      name="qtyDec"
                      label="December"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.qtyDec}
                      // helperText={this.props.ppmp.suppliesData.unit}
                      helperText={
                        isTotalCostExceeded && forToolTip.dec_for_edit === "1"
                          ? "Updated overall total cost must not exceeds recent overall total cost."
                          : this.props.ppmp.suppliesData.unit
                      }
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <Material.Tooltip
                              title={
                                <>
                                  <Material.Typography variant="body2">
                                    {`Price as of December: ${forToolTip.dec_for_edit > 0
                                        ? forToolTip.for_update_price
                                        : forToolTip.dec_price
                                      }`}
                                  </Material.Typography>

                                  <Material.Typography
                                    variant="body2"
                                    style={{ marginTop: 4 }}
                                  >
                                    {`Mode of Procurement: ${forToolTip._mop_dec === "Not Yet Generated"
                                        ? forToolTip.mode_of_procurement
                                        : forToolTip._mop_dec
                                      }`}
                                  </Material.Typography>
                                </>
                              }
                              arrow
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color:
                                    isTotalCostExceeded && forToolTip.dec_for_edit > 0 ? "red" : "grey",
                                  cursor: "pointer",
                                }}
                              />
                            </Material.Tooltip>
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Grid>
                </Material.Grid>
                <Material.Grid container spacing={3} alignItems="center">
                  <Material.Grid item xs={12} sx={{ paddingBottom: '10px' }} >
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Monthly Detailed Information
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of January
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.jan_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.jan_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total :  {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyJan !== undefined
                          ? parseFloat(monthlyCosts.qtyJan).toFixed(2)
                          : parseFloat(
                            (forToolTip.jan_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.jan_price) * forToolTip.qty_jan
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of February
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.feb_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.feb_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyFeb !== undefined
                          ? parseFloat(monthlyCosts.qtyFeb).toFixed(2)
                          : parseFloat(
                            (forToolTip.feb_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.feb_price) * forToolTip.qty_feb
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of March
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.mar_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.mar_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyMar !== undefined
                          ? parseFloat(monthlyCosts.qtyMar).toFixed(2)
                          : parseFloat(
                            (forToolTip.mar_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.mar_price) * forToolTip.qty_mar
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of April
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.apr_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.apr_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyApr !== undefined
                          ? parseFloat(monthlyCosts.qtyApr).toFixed(2)
                          : parseFloat(
                            (forToolTip.apr_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.apr_price) * forToolTip.qty_apr
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>

                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of May
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.may_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.may_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyMay !== undefined
                          ? parseFloat(monthlyCosts.qtyMay).toFixed(2)
                          : parseFloat(
                            (forToolTip.may_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.may_price) * forToolTip.qty_may
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>

                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of June
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.jun_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.jun_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyJun !== undefined
                          ? parseFloat(monthlyCosts.qtyJun).toFixed(2)
                          : parseFloat(
                            (forToolTip.jun_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.jun_price) * forToolTip.qty_jun
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>

                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of July
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.jul_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.jul_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyJul !== undefined
                          ? parseFloat(monthlyCosts.qtyJul).toFixed(2)
                          : parseFloat(
                            (forToolTip.jul_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.jul_price) * forToolTip.qty_jul
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>

                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of August
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.aug_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.aug_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyAug !== undefined
                          ? parseFloat(monthlyCosts.qtyAug).toFixed(2)
                          : parseFloat(
                            (forToolTip.aug_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.aug_price) * forToolTip.qty_aug
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>

                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of September
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.sep_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.sep_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtySep !== undefined
                          ? parseFloat(monthlyCosts.qtySep).toFixed(2)
                          : parseFloat(
                            (forToolTip.sep_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.sep_price) * forToolTip.qty_sep
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>

                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of October
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.oct_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.oct_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyOct !== undefined
                          ? parseFloat(monthlyCosts.qtyOct).toFixed(2)
                          : parseFloat(
                            (forToolTip.oct_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.oct_price) * forToolTip.qty_oct
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>

                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of November
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.nov_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.nov_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyNov !== undefined
                          ? parseFloat(monthlyCosts.qtyNov).toFixed(2)
                          : parseFloat(
                            (forToolTip.nov_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.nov_price) * forToolTip.qty_nov
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>

                  <Material.Grid item xs={4}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Month of December
                    </Material.Typography>
                    <Material.Typography >
                      Price :  <span style={{ color: 'blue' }}>{forToolTip.dec_for_edit !== "0" ? forToolTip.for_update_price : forToolTip.dec_price}</span>
                    </Material.Typography>
                    <Material.Typography>
                      Total : {" "}
                      <span style={{ color: 'blue' }}>
                        {monthlyCosts.qtyDec !== undefined
                          ? parseFloat(monthlyCosts.qtyDec).toFixed(2)
                          : parseFloat(
                            (forToolTip.dec_for_edit !== "0"
                              ? forToolTip.for_update_price
                              : forToolTip.dec_price) * forToolTip.qty_dec
                          ).toFixed(2)
                        }
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={12}></Material.Grid>
                  <Material.Grid item xs={6}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Recent Overall Total Cost : {" "}
                      <span style={{ color: 'blue' }}>{forToolTip.total_cost}</span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={6}>
                    <Material.Typography style={{ fontWeight: 'bold' }}>
                      Updated Overall Total Cost :  <span style={{ color: 'blue' }}>
                        {newTotalCost === 0 ? newOverAllCost : newTotalCost}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                </Material.Grid>
              </Material.Grid>
            </div>
          );
        }

      case 2:
        // const { classes, ppmp} = this.props;
        const { editDataQty } = this.state;
        // const targetList = [
        // 	{
        // 		label: "January",
        // 		name: "jan_qty",
        // 		value: ppmp.suppliesData.qty_jan,
        // 		id: "formatted-jan_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "February",
        // 		name: "feb_qty",
        // 		value: ppmp.suppliesData.qty_feb,
        // 		id: "formatted-feb_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "March",
        // 		name: "mar_qty",
        // 		value: ppmp.suppliesData.qty_mar,
        // 		id: "formatted-mar_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "April",
        // 		name: "apr_qty",
        // 		value: ppmp.suppliesData.qty_apr,
        // 		id: "formatted-apr_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "May",
        // 		name: "may_qty",
        // 		value: ppmp.suppliesData.qty_may,
        // 		id: "formatted-may_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "June",
        // 		name: "jun_qty",
        // 		value: ppmp.suppliesData.qty_jun,
        // 		id: "formatted-jun_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "July",
        // 		name: "jul_qty",
        // 		value: ppmp.suppliesData.qty_jul,
        // 		id: "formatted-jul_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "August",
        // 		name: "aug_qty",
        // 		value: ppmp.suppliesData.qty_aug,
        // 		id: "formatted-aug_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "September",
        // 		name: "sep_qty",
        // 		value: ppmp.suppliesData.qty_sep,
        // 		id: "formatted-sep_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "October",
        // 		name: "oct_qty",
        // 		value: ppmp.suppliesData.qty_oct,
        // 		id: "formatted-oct_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "November",
        // 		name: "nov_qty",
        // 		value: ppmp.suppliesData.qty_nov,
        // 		id: "formatted-nov_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // 	{
        // 		label: "December",
        // 		name: "dec_qty",
        // 		value: ppmp.suppliesData.qty_dec,
        // 		id: "formatted-dec_qty-input",
        // 		unit: this.props.ppmp.suppliesData.unit,
        // 	},
        // ];

        const {
          advanceSearchVisible,
          sourceFundList,
          fundSource,
          checkedRCA,
          legalBasisChoices,
          fundTypeChoices,
          rows,

          qtyJan,
          qtyFeb,
          qtyMar,
          qtyApr,
          qtyMay,
          qtyJun,
          qtyJul,
          qtyAug,
          qtySep,
          qtyOct,
          qtyNov,
          qtyDec,
        } = this.state;
        const priceData = ppmp.suppliesData;
        let totalQty =
          parseInt(qtyJan) +
          parseInt(qtyFeb) +
          parseInt(qtyMar) +
          parseInt(qtyApr) +
          parseInt(qtyMay) +
          parseInt(qtyJun) +
          parseInt(qtyJul) +
          parseInt(qtyAug) +
          parseInt(qtySep) +
          parseInt(qtyOct) +
          parseInt(qtyNov) +
          parseInt(qtyDec);
        // let totalAmt =
        //   Number((totalQty * priceData._latest_price).toFixed(2))
        //     .toLocaleString()
        //     .split(/\s/)
        //     .join(",") +
        //   "." +
        //   Number(
        //     (totalQty * priceData._latest_price)
        //       .toFixed(2)
        //       .toString()
        //       .slice(
        //         (totalQty * priceData._latest_price)
        //           .toFixed(2)
        //           .toString()
        //           .indexOf(".") + 1
        //       )
        //   ).toLocaleString();
        let totalAmt =
          priceData.for_edit === "1" && newTotalCost === 0
            ? newOverAllCost
            : priceData.for_edit === "1" && newTotalCost !== 0
              ? newTotalCost
              : (totalQty * priceData._latest_price).toFixed(2);
        totalAmt = Number(totalAmt).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return (
          <div>
            <div className="title">
              <Material.Grid container spacing={3}>
                <Material.Grid item xs={6}>
                  <Material.Typography variant="subtitle1">
                    Funds (WFP)
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Total Amount: ₱{totalAmt}
                  </Material.Typography>
                </Material.Grid>
              </Material.Grid>
            </div>
            <Material.Divider></Material.Divider>
            <Material.Grid container spacing={3}>
              <Material.Grid item xs={12}>
                <Material.Link
                  href=""
                  style={{ textAlign: "right" }}
                  onClick={this.onClickAdvanceSearch}
                >
                  {" "}
                  {advanceSearchVisible ? "Hide" : "Show"} Advance Search{" "}
                </Material.Link>
                <Material.Collapse
                  className={classes.collapseExpand}
                  in={advanceSearchVisible}
                >
                  <Material.Paper
                    elevation={1}
                    className={classes.paperAdvance}
                  >
                    <div className="full-width-choices">
                      <Material.Tooltip
                        title="Get Fund source from other Respo Center"
                        arrow
                      >
                        <Material.FormControlLabel
                          control={
                            <Material.Checkbox
                              checked={checkedRCA}
                              onChange={this.onHandleCheckboxChange}
                              name="checkedRCA"
                              color="primary"
                            />
                          }
                          label="Respo Center"
                        />
                      </Material.Tooltip>
                      <Material.Tooltip
                        title="Respo Center needs approval when using other funds"
                        arrow
                      >
                        <Autocomplete
                          onChange={this.onHandleRespoCodeAutocomplete}
                          id="respo-code-select"
                          autoHighlight
                          disabled={!checkedRCA}
                          options={ppmp.respoCenterCodeDatasource}
                          getOptionLabel={(option) =>
                            `${option.respo_code}. ${option.office_name}`
                          }
                          filterOptions={this.filterOptions}
                          getOptionSelected={(option, value) =>
                            option.ppa_code === value.ppa_code
                          }
                          value={this.state.respoCodeValue}
                          renderOption={this.respoCodeRenderOptions}
                          renderInput={(params) => (
                            <Material.TextField
                              {...params}
                              color="primary"
                              label="Fund Source *"
                              margin="normal"
                            />
                          )}
                          disableListWrap
                        />
                      </Material.Tooltip>

                      <div>
                        <Material.FormControl className={classes.formControl}>
                          <Material.InputLabel id="RA-year-select-label">
                            Year *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="RA-year-select"
                            id="RA-year-select"
                            name="RA_year"
                            value={this.state.RA_year}
                            onChange={this.onHandleSelectChange}
                          >
                            {this.state.RA_year_choices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`ra-year-item-${index}`}
                                  value={item.ra_year}
                                >
                                  {item.ra_year}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                        <Material.FormControl className={classes.formControl}>
                          <Material.InputLabel id="legal-basis-select-label">
                            Legal Basis *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="legal-basis-select"
                            id="legal-basis-select"
                            name="RA_desc"
                            value={this.state.RA_desc}
                            onChange={this.onHandleSelectChange}
                          >
                            {legalBasisChoices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`legal_basis-item-${index}`}
                                  value={item}
                                >
                                  {item}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                      </div>
                      <div style={{ display: "inline" }}>
                        <Material.FormControl className={classes.formControl}>
                          <Material.InputLabel id="fund-type-select-label">
                            Fund Type *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="fund-type-select"
                            id="fund-type-select"
                            name="fundType"
                            value={this.state.fundType}
                            onChange={this.onHandleSelectChange}
                          >
                            {fundTypeChoices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`fund-type-item-${index}`}
                                  value={item}
                                >
                                  {item}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                        <Material.FormControl className={classes.formControl}>
                          {/* <Material.InputLabel id="fund-type-select-label">Non-Office</Material.InputLabel> */}
                          <Material.FormControlLabel
                            control={
                              <Material.Checkbox
                                checked={this.state.IsNonOffice}
                                onChange={this.onHandleCheckboxChange}
                                name="IsNonOffice"
                                color="primary"
                              />
                            }
                            label="Non-Office"
                          />
                        </Material.FormControl>
                      </div>
                    </div>
                    <Material.Button
                      className={classes.action}
                      variant="outlined"
                      color="primary"
                      onClick={this.requestSourceOfFund}
                    >
                      {" "}
                      Search{" "}
                    </Material.Button>
                  </Material.Paper>
                </Material.Collapse>
                {/* <div className="inline">
									<Material.Typography color="primary" variant="subtitle1">
										Saved Account Code:
									</Material.Typography>
									<Material.Typography variant="subtitle1">
										{this.props.ppmp.suppliesData.account_code}
									</Material.Typography>
								</div> */}
                <Material.Divider></Material.Divider>
                {/* <div className="full-width-choices">
                  <Autocomplete
                    onChange={this.onHandleSourceFundAutocomplete}
                    id="fund-source-select"
                    autoHighlight
                    className={classes.autocompleteRoot}
                    options={sourceFundList}
                    getOptionLabel={(option) => option.account_particulars}
                    renderOption={this.accountCodeRenderOptions}
                    // getOptionSelected={(option, value) =>
                    //   option.unique_code === value.unique_code
                    // }
                    value={this.state.fundSource}
                    filterOptions={this.filterOptions}
                    renderInput={(params) => (
                      <Material.TextField
                        {...params}
                        name="fundSource"
                        color="primary"
                        label="Reselect/ Select New Account Code *"
                        margin="normal"
                      />
                    )}
                  />
                  <Material.Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ color: "rgba(0, 0, 0, 0.54)", float: "right" }}
                  >
                    <i>
                      {sourceFundList.length} Account{" "}
                      {sourceFundList.length > 1 ? "codes" : "code"} found
                    </i>
                  </Material.Typography>
                </div> */}
                {/* {rows.map((row, index) => (
                  <div className="full-width-choices" key={index}>
                    <Autocomplete
                      onChange={(e, value) =>
                        this.onHandleSourceFundAutocomplete(e, value, index)
                      }
                      id={`fund-source-select-${index}`}
                      autoHighlight
                      className={classes.autocompleteRoot}
                      options={sourceFundList}
                      getOptionLabel={(option) => option.account_particulars}
                      renderOption={this.accountCodeRenderOptions}
                      value={row.fundSource}
                      filterOptions={this.filterOptions}
                      renderInput={(params) => (
                        <Material.TextField
                          {...params}
                          name="fundSource"
                          color="primary"
                          label="Reselect/ Select New Account Code *"
                          margin="normal"
                        />
                      )}
                    />
                    <Material.Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{ color: "rgba(0, 0, 0, 0.54)", float: "right" }}
                    >
                      <i>
                        {sourceFundList.length} Account{" "}
                        {sourceFundList.length > 1 ? "codes" : "code"} found
                      </i>
                    </Material.Typography>
                    <Material.FormControl
                      fullWidth
                      sx={{ m: 1 }}
                      variant="standard"
                    >
                      <Material.InputLabel
                        htmlFor={`standard-adornment-amount-${index}`}
                      >
                        Allocated Amount
                      </Material.InputLabel>
                      <Material.Input
                        id={`standard-adornment-amount-${index}`}
                        value={rows.fundSourceAllocatedAmount}
                        onChange={(e) =>
                          this.handleFundSourceAllocatedAmountChange(e, index)
                        }
                        startAdornment={
                          <Material.InputAdornment position="start">
                            ₱
                          </Material.InputAdornment>
                        }
                      />
                    </Material.FormControl>
                    {rows.length > 1 ? (
                      <Material.Button
                        variant="text"
                        color="secondary"
                        onClick={() => this.removeRow(index)}
                        style={{ float: "right" }}
                      >
                        - Remove Row
                      </Material.Button>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </Material.Grid>
              <Material.Grid item xs={6}>
                <Material.Collapse in={Boolean(fundSource)}>
                  <div className="inline">
                    <Material.Typography color="primary" variant="subtitle1">
                      F.P.P.:
                    </Material.Typography>
                    {fundSource ? (
                      <Material.Typography className="wrap" variant="subtitle1">
                        {fundSource.ppa_code}: {fundSource.ppa_desc}
                      </Material.Typography>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inline">
                    <Material.Typography color="primary" variant="subtitle1">
                      RESPO:
                    </Material.Typography>
                    {fundSource ? (
                      <Material.Typography className="wrap" variant="subtitle1">
                        {fundSource.respo_desc}
                      </Material.Typography>
                    ) : (
                      ""
                    )}
                  </div>
                </Material.Collapse>
              </Material.Grid>
              <Material.Grid item xs={6}>
                <Material.Collapse in={Boolean(fundSource)}>
                  <div className="inline">
                    <Material.Typography color="primary" variant="subtitle1">
                      Balance:
                    </Material.Typography>
                    {fundSource ? (
                      <Material.Typography className="wrap" variant="subtitle1">
                        Php {fundSource.allot_balance}
                      </Material.Typography>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inline">
                    <Material.Typography color="primary" variant="subtitle1">
                      Legal Basis:
                    </Material.Typography>
                    {fundSource ? (
                      <Material.Typography className="wrap" variant="subtitle1">
                        {fundSource.ra_desc}
                      </Material.Typography>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inline">
                    <Material.Typography color="primary" variant="subtitle1">
                      Year:
                    </Material.Typography>
                    {fundSource ? (
                      <Material.Typography className="wrap" variant="subtitle1">
                        {fundSource.ra_year}
                      </Material.Typography>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inline">
                    <Material.Typography color="primary" variant="subtitle1">
                      Allotted Class:
                    </Material.Typography>
                    {fundSource ? (
                      <Material.Typography className="wrap" variant="subtitle1">
                        {fundSource.allot_type}
                      </Material.Typography>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inline">
                    <Material.Typography color="primary" variant="subtitle1">
                      Fund Type:
                    </Material.Typography>
                    {fundSource ? (
                      <Material.Typography className="wrap" variant="subtitle1">
                        {fundSource.fund_type}
                      </Material.Typography>
                    ) : (
                      ""
                    )}
                  </div>
                </Material.Collapse>
              </Material.Grid>
              <Material.Button
                variant="text"
                color="secondary"
                onClick={this.addRow}
              >
                + Add Row
              </Material.Button>
            </Material.Grid> */}
                {rows.map((row, index) => {
                  return (
                    <div className="full-width-choices" key={index}>
                      <Autocomplete
                        onChange={(e, value) =>
                          this.onHandleSourceFundAutocomplete(e, value, index)
                        }
                        id={`fund-source-select-${index}`}
                        autoHighlight
                        className={classes.autocompleteRoot}
                        options={sourceFundList}
                        getOptionLabel={(option) => option.account_particulars}
                        renderOption={this.accountCodeRenderOptions}
                        value={row.fundSource}
                        filterOptions={this.filterOptions}
                        renderInput={(params) => (
                          <Material.TextField
                            {...params}
                            name="fundSource"
                            color="primary"
                            label="Reselect/ Select New Account Code *"
                            margin="normal"
                          />
                        )}
                      />
                      <Material.Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ color: "rgba(0, 0, 0, 0.54)", float: "right" }}
                      >
                        <i>
                          {sourceFundList.length} Account{" "}
                          {sourceFundList.length > 1 ? "codes" : "code"} found
                        </i>
                      </Material.Typography>
                      <Material.FormControl
                        fullWidth
                        sx={{ m: 1 }}
                        variant="standard"
                      >
                        <Material.InputLabel
                          htmlFor={`standard-adornment-amount-${index}`}
                        >
                          Allocated Amount
                        </Material.InputLabel>
                        <Material.Input
                          id={`standard-adornment-amount-${index}`}
                          value={row.fundSourceAllocatedAmount}
                          onChange={(e) =>
                            this.handleFundSourceAllocatedAmountChange(e, index)
                          }
                          startAdornment={
                            <Material.InputAdornment position="start">
                              ₱
                            </Material.InputAdornment>
                          }
                        />
                      </Material.FormControl>

                      {rows.length > 1 ? (
                        <Material.Button
                          variant="text"
                          color="secondary"
                          onClick={() => this.removeRow(index)}
                          style={{ float: "right" }}
                        >
                          - Remove Account Code
                        </Material.Button>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </Material.Grid>
              <Material.Grid>
                <Material.Button
                  variant="text"
                  color="primary"
                  onClick={this.addRow}
                >
                  + Add Account Code
                </Material.Button>
              </Material.Grid>
              <Material.Grid
                style={{ paddingTop: 15, paddingLeft: 7 }}
                container
                spacing={2}
              >
                {rows.map((row, index) => (
                  <React.Fragment key={index}>
                    <Material.Grid style={{ paddingLeft: 9 }} item xs={6}>
                      <Material.Collapse in={Boolean(row.fundSource)}>
                        <div>
                          <Material.Typography
                            style={{ paddingLeft: 5 }}
                            color="secondary"
                          >
                            Account Code {index + 1} Details
                          </Material.Typography>
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            F.P.P.:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.ppa_code}:{" "}
                              {row.fundSource.ppa_desc}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            RESPO:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.respo_desc}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </Material.Collapse>
                    </Material.Grid>
                    <Material.Grid style={{ paddingTop: 33 }} item xs={6}>
                      <Material.Collapse in={Boolean(row.fundSource)}>
                        <div paddingTop={5} className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Balance:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              Php {row.fundSource.allot_balance}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Legal Basis:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.ra_desc}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Year:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.ra_year}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Allotted Class:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.allot_type}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Fund Type:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.fund_type}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </Material.Collapse>
                    </Material.Grid>
                  </React.Fragment>
                ))}
              </Material.Grid>
            </Material.Grid>
          </div>
        );
      case 3:
        const {
          stepper,
          activity_year,
          year_range,
          activities,
          selected_activity_id,
          selected_activity,
        } = this.state;
        if (stepper[3]) {
          return (
            <div>
              <div className="title">
                <Material.Typography variant="subtitle1">
                  Additional Catering Details
                </Material.Typography>
              </div>
              <Material.Divider></Material.Divider>
              <Material.Grid container spacing={3}>
                <Material.Grid item xs={3}>
                  <Material.FormControl variant="standard">
                    <Material.InputLabel>Year</Material.InputLabel>
                    <Material.Select
                      name="activity_year"
                      value={activity_year}
                      onChange={this.onHandleSelectChange}
                      disabled
                    >
                      {year_range.map((val) => (
                        <Material.MenuItem value={val}>
                          {" "}
                          {val}
                        </Material.MenuItem>
                      ))}
                    </Material.Select>
                  </Material.FormControl>
                </Material.Grid>
                <Material.Grid item xs={9}>
                  <Material.FormControl variant="standard">
                    <Material.InputLabel htmlFor="dater_remarks">
                      Office Catering Activity
                    </Material.InputLabel>
                    <Material.Select
                      name="selected_activity_id"
                      value={selected_activity_id}
                      onChange={this.onHandleSelectChange}
                    >
                      {activities.map((val) => (
                        <Material.MenuItem value={val.id}>
                          {" "}
                          {val.title}
                        </Material.MenuItem>
                      ))}
                    </Material.Select>
                  </Material.FormControl>
                </Material.Grid>
              </Material.Grid>
              <div>
                <Material.Grid container spacing={3}>
                  <Material.Grid item xs={5}>
                    <Material.Collapse in={Boolean(selected_activity_id)}>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Activity Type:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.activity_type}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Year:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.year}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Batch:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_batches}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Rooms:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_rooms}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Pax:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_pax}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Meals:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_meals}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Snacks:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_snacks}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Venue:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.venue}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    </Material.Collapse>
                  </Material.Grid>
                  <Material.Grid item xs={7}>
                    <Material.Collapse in={Boolean(selected_activity_id)}>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Date Span:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.date_of_activity_from} -{" "}
                            {selected_activity.date_of_activity_to}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Participants:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.participants}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Equipments:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.facilities_equipment_needed}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Type of catering service:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.type_of_catering_service}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Accommodation:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.accommodation}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    </Material.Collapse>
                  </Material.Grid>
                </Material.Grid>
              </div>
            </div>
          );
        } else {
          return (
            <div className={classes.loading}>
              <Material.FormControlLabel
                control={<Material.CircularProgress />}
                checked={false}
                label="Loading..."
              />
            </div>
          );
        }
      default:
        return (
          <div className={classes.loading}>
            <Material.FormControlLabel
              control={<Material.CircularProgress />}
              checked={false}
              label="Loading..."
            />
          </div>
        );
    }
  }


  updateState(step) {
    const { ppmp } = this.props;
    const { data } = ppmp.suppliesData;
    const { rows } = this.state;
    // Create a new array of rows with the updated fundSourceAllocatedAmount
    const updatedRows = rows.map((row) => {
      // Remove commas, parse as float, and format to 2 decimal places
      let allocated_amount = parseFloat(
        row.fundSourceAllocatedAmount.toString().replace(/,/g, "")
      ).toFixed(2);
      // console.log('$$allocatedAmount:', allocated_amount);
      return { ...row, fundSourceAllocatedAmount: allocated_amount };
    });
    // Update the state with the new array of rows
    this.setState({ rows: updatedRows }, () => {
      this.onClickNextStep(step);
    });
  }
  onClickNextStep(step) {
    this.setState(
      { ...this.state, activeStep: this.state.activeStep + step },
      () => {
        // this.setState({
        //   ...this.state,
        //   fundSource: null
        // })
        const {
          activeStep,
          fundSource = null,
          qtyJan,
          qtyFeb,
          qtyMar,
          qtyApr,
          qtyMay,
          qtyJun,
          qtyJul,
          qtyAug,
          qtySep,
          qtyOct,
          qtyNov,
          qtyDec,
          stepper,

          sub_category_modes,
          mode_sub_category_id,
          mode_category_id,
          category_modes,
          mode_parent_id,
          parent_modes,
          procurement_subMode_child,
          procurement_subMode,
          procurement_mode,
          rows,
          newTotalCost
        } = this.state;

        //reyno 02072024
        let mode_id =
          mode_sub_category_id > 0
            ? mode_sub_category_id
            : mode_category_id > 0
              ? mode_category_id
              : mode_parent_id > 0
                ? mode_parent_id
                : 0;
        let mode =
          mode_sub_category_id > 0
            ? procurement_subMode_child
            : mode_category_id > 0
              ? procurement_subMode
              : mode_parent_id > 0
                ? procurement_mode
                : "";
        this.setState(
          {
            ...this.state,
            mode,
            mode_id,
          },
          () => {
            // console.log("$$mode_id", this.state.mode_id);
          }
        );
        const { ppmp, displayLoading } = this.props;
        const { monthFields } = this.state;
        const forToolTip = ppmp.suppliesData;
        let newOverAllCost = Object.keys(monthFields).reduce((total, month) => {
          const { qty, price, forEdit } = monthFields[month];
          const monthQty = forToolTip[qty];
          const monthPrice = forToolTip[forEdit] > 0 ? forToolTip.for_update_price : forToolTip[price];
          return total + (monthQty * monthPrice);
        }, 0).toFixed(2);

        if (activeStep === stepper.length) {
          let target = [
            qtyJan,
            qtyFeb,
            qtyMar,
            qtyApr,
            qtyMay,
            qtyJun,
            qtyJul,
            qtyAug,
            qtySep,
            qtyOct,
            qtyNov,
            qtyDec,
          ];
          let qty = target
            .map((elt) => (elt ? parseFloat(elt) : 0))
            .reduce((a, b) => parseFloat(a) + parseFloat(b));

          let total_cost =
            ppmp.suppliesData.for_edit === "1" && this.state.newTotalCost === 0
              ? newOverAllCost
              : ppmp.suppliesData.for_edit === "1" && this.state.newTotalCost !== 0
                ? this.state.newTotalCost
                : parseFloat((qty * ppmp.suppliesData._latest_price).toFixed(2));

          let unit_price = ppmp.suppliesData.for_edit === "1" ? parseFloat(ppmp.suppliesData.for_update_price) : parseFloat(ppmp.suppliesData._latest_price);

          let activeStep = 0;
          let errorMessage = "";
          if (parent_modes.length > 0 && mode_parent_id > 0) {
            if (
              (category_modes.length > 0 && mode_category_id > 0) ||
              category_modes.length == 0
            ) {
              if (
                (sub_category_modes.length > 0 && mode_sub_category_id > 0) ||
                sub_category_modes.length == 0
              ) {
                // console.log('$$transactionData', ppmp.transactionData);
                if (ppmp.transactionData === null) {
                  errorMessage =
                    "Ohh uh! look like there are no PPMP transaction selected";
                  activeStep = 0;
                } else {
                  if (qty > 0) {
                    if (
                      ppmp.suppliesData.category_name.indexOf("Catering") !==
                      -1 &&
                      this.state.selected_activity_id === 0
                    ) {
                      errorMessage = "Please add Catering Particulars";
                      activeStep = 3;
                    } else {
                      // if (fundSource != null) {
                      // 	if (fundSource.allot_balance < total_cost) {
                      // 		var fs = fundSource.allot_balance;
                      // 		errorMessage =
                      // 			"Your current target total cost: " +
                      // 			this.formatNumber(total_cost) +
                      // 			" exceeded from the remaining fund allotment balance: " +
                      // 			this.formatNumber(fs);
                      // 		activeStep = 2;
                      // 	} else {
                      // 		//save here
                      // 		this.updateItem_final();
                      // 	}
                      // } else {
                      // 	errorMessage = "Please select a source of fund!";
                      // 	activeStep = 1;
                      // }

                      if (rows[0]?.fundSource != null) {
                        if (parseInt(ppmp.transactionData?.is_indicative) > 0) {
                          if (
                            //   unit_price > parseFloat(50000.0) &&
                            //   rows[0]?.fundSource.allot_type !== "Capital Outlay"
                            // ) {
                            //   this.setState({
                            //     ...this.state,
                            //     errorDialogVisible: true,
                            //   });
                            //   errorMessage =
                            //     "Suggestion: Please select Capital Outlay as fund source for amounts exceeding 50,000!";
                            //   activeStep = 2;
                            // } else if (
                            unit_price >= parseFloat(50000.0) &&
                            rows[0]?.fundSource.allot_type === "Capital Outlay"
                          ) {
                            this.updateItem_final();
                          } else if (
                            total_cost >
                            parseFloat(rows[0]?.fundSource.allot_balance)
                          ) {
                            // var fs = rows[0]?.fundSource.allot_balance;
                            // errorMessage =
                            //   "Current total cost: " +
                            //   this.formatNumber(total_cost) +
                            //   " exceeds the remaining fund allotment balance: " +
                            //   this.formatNumber(fs);
                            // activeStep = 2;
                            // console.log("total", total_cost);
                            this.updateItem_final();
                          } else if (
                            total_cost !==
                            parseFloat(rows[0]?.fundSourceAllocatedAmount)
                          ) {
                            errorMessage =
                              "Total cost does not match the allocated amount. Please verify the allocated amount.";
                            activeStep = 2;
                            // console.log(
                            //   "$$fundSourceAllocatedAmount",
                            //   rows[0]?.fundSourceAllocatedAmount
                            // );
                          } else if (
                            rows[0]?.fundSource.allot_type ===
                            "Capital Outlay" &&
                            unit_price < parseFloat(50000.0)
                          ) {
                            this.setState({
                              ...this.state,
                              errorDialogVisible: true,
                              proceedAnyway: false,
                            });
                            errorMessage =
                              "Unit price must be 50k above for Capital Outlay";
                            activeStep = 2;
                          } else {
                            this.updateItem_final();
                          }
                        } else {
                          if (
                            rows[0]?.fundSource.allot_type ===
                            "Capital Outlay" &&
                            unit_price < parseFloat(50000.0) &&
                            ppmp.suppliesData.category_id !== "63"
                          ) {
                            this.setState({
                              ...this.state,
                              errorDialogVisible: true,
                              proceedAnyway: false,
                            });
                            errorMessage =
                              "Unit price must be 50k above! For Capital Outlay";
                            activeStep = 2;
                          } else if (
                            ppmp.suppliesData.for_edit === "1" && (parseFloat(total_cost) > ppmp.suppliesData.total_cost)
                          ) {
                            errorMessage =
                              "Updated total cost must not exceeds the recent total cost. Please verify the items quantity.";
                            activeStep = 2;
                          } else if (
                            parseFloat(total_cost) !==
                            rows.reduce(
                              (acc, row) =>
                                acc +
                                parseFloat(row?.fundSourceAllocatedAmount || 0),
                              0
                            )
                          ) {
                            errorMessage =
                              "Allocated amount does not match the total cost. Please verify the allocated amount.";
                            activeStep = 2;
                          } else if (
                            rows.some(
                              (row) =>
                                parseFloat(row.fundSourceAllocatedAmount || 0) >
                                parseFloat(row.fundSource?.allot_balance || 0)
                            )
                          ) {
                            const rowWithExcessAllocation = rows.find(
                              (row) =>
                                parseFloat(row.fundSourceAllocatedAmount || 0) >
                                parseFloat(row.fundSource?.allot_balance || 0)
                            );

                            if (rowWithExcessAllocation) {
                              errorMessage =
                                `The allocated amount for fund source ${rowWithExcessAllocation.fundSource.account_particulars} exceeds its allotment balance. ` +
                                `Allotted amount: ${this.formatNumber(
                                  parseFloat(
                                    rowWithExcessAllocation.fundSourceAllocatedAmount ||
                                    0
                                  )
                                )}, ` +
                                `Allotment balance: ${this.formatNumber(
                                  parseFloat(
                                    rowWithExcessAllocation.fundSource
                                      .allot_balance || 0
                                  )
                                )}. Please verify.`;
                              activeStep = 2;
                            }
                          } else if (
                            rows.some(
                              (row) =>
                                row?.fundSource?.allot_type ===
                                "Capital Outlay" &&
                                unit_price < parseFloat(50000.0)
                            )
                          ) {
                            const rowWithIssue = rows.find(
                              (row) =>
                                row?.fundSource?.allot_type ===
                                "Capital Outlay" &&
                                unit_price < parseFloat(50000.0)
                            );

                            if (rowWithIssue) {
                              errorMessage = `The fund allotment type is 'Capital Outlay' but the unit price is below 50k. For fund source ${rowWithIssue.fundSource?.account_particulars}. Please verify.`;
                              activeStep = 2;
                            }
                          } else {
                            if (ppmp.suppliesData.for_edit === "1") {
                              this.setState({
                                confirmationDialogVisible: true,
                                confirmationDialogMessage: "Are you sure you want to update this item?",
                                activeStep: 2,
                                onConfirmSave: () => {
                                  this.updateItem_final();
                                },
                              });
                            } else {
                              this.updateItem_final();
                            }
                          }
                        }
                      } else {
                        errorMessage = "Please select a source of fund!";
                        activeStep = 2;
                      }
                    }
                  }
                  //  else if (qty === 0) {
                  //   errorMessage =
                  //     "Warning: You have not inputted quantity for any months. Proceeding anyway.";
                  //   activeStep = 1;
                  // }
                  else {
                    errorMessage = "Please add a target month!";
                    activeStep = 1;
                  }
                }
              } else {
                errorMessage = "Please add a procurement sub-category mode!";
                activeStep = 0;
              }
            } else {
              errorMessage = "Please add a procurement category mode!";
              activeStep = 0;
            }
          } else {
            errorMessage = "Please add a procurement mode!";
            activeStep = 0;
          }
          if (errorMessage) {
            this.setState({
              ...this.state,
              errorDialogVisible: true,
              errorDialogMessage: errorMessage,
              activeStep,
              proceedAnyway: 0,
              // || qty === 0,
            });
          }
        }
      }
    );
  }
  filterOptions(options, { inputValue }) {
    return matchSorter(options, inputValue, {
      keys: [
        "office_name",
        "ppa_code",
        "ppa_desc",
        "respo_code",
        "respo_type",
        "ra_desc",
        "account_particulars",
      ],
    });
  }
  render() {
    const { ppmp, classes } = this.props;
    const { activeStep, activeStepForEdit, stepper } = this.state;
    const data = ppmp.suppliesData;
    return (
      <Material.Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        onRendered={this.initRespoCenter}
        className={classes.modal}
        open={ppmp.suppliesModalVisible}
        // onClose={this.closeModal}
        closeAfterTransition
        disableAutoFocus={true}
        BackdropComponent={Material.Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.root}>
          <Material.Fade in={ppmp.suppliesModalVisible}>
            <div className={classes.paper}>
              <Material.Grid container spacing={0}>
                <Material.Grid item xs={10}>
                  <div className="inline">
                    <Material.Typography
                      className="flex"
                      color="primary"
                      variant="h5"
                    >
                      {data.name}
                    </Material.Typography>
                  </div>
                  <div className={classes.description}>
                    <Material.Tooltip title={data.description}>
                      <Material.Typography
                        className="title"
                        variant="subtitle1"
                      >
                        {data.description}
                      </Material.Typography>
                    </Material.Tooltip>
                  </div>
                </Material.Grid>
                <Material.Grid item xs={2}>
                  <Material.Typography variant="h6" className="pull-right">
                    {data.for_edit === "1" ? data.for_update_price : data._latest_price}
                  </Material.Typography>
                  <span className="pull-right padded">Php</span>
                </Material.Grid>
              </Material.Grid>
              <Material.Divider></Material.Divider>
              <div className={classes.FormContainer}>
                <Material.Paper elevation={4} className={classes.paperFunds}>
                  <Material.Stepper
                    className={classes.stepperCont}
                    activeStep={activeStep}
                    alternativeLabel
                  >
                    {stepper.map((label) => (
                      <Material.Step key={label}>
                        <Material.StepLabel>{label}</Material.StepLabel>
                      </Material.Step>
                    ))}
                  </Material.Stepper>
                </Material.Paper>
                {/* {data.for_edit === "1" 
              ? this.getStepContent(activeStepForEdit)
              : this.getStepContent(activeStep)} */}

                {this.getStepContent(activeStep)}
              </div>
              <form noValidate autoComplete="off">
                <Material.Grid container spacing={0}>
                  <Material.Grid item xs={6}>
                    <Material.Button
                      className={classes.action}
                      variant="outlined"
                      color="secondary"
                      onClick={this.closeModal}
                    >
                      {" "}
                      Cancel{" "}
                    </Material.Button>
                  </Material.Grid>
                  {activeStep !== stepper.length ? (
                    <Material.Grid item xs={6} style={{ textAlign: "right" }}>
                      <Material.Button
                        className={classes.action}
                        disabled={activeStep === 0}
                        onClick={() => this.updateState(-1)}
                      >
                        Back{" "}
                      </Material.Button>
                      <Material.Button
                        className={classes.action}
                        variant="contained"
                        color="primary"
                        onClick={() => this.updateState(1)}
                        disabled={this.state.invalidState}
                      >
                        {(activeStep === stepper.length - 1)
                          ? "Update Item"
                          : "Next"}
                      </Material.Button>
                    </Material.Grid>
                  ) : (
                    ""
                  )}
                </Material.Grid>
              </form>
            </div>
          </Material.Fade>
          <Material.Dialog
            open={this.state.errorDialogVisible}
            onClose={() =>
              this.setState({ ...this.state, errorDialogVisible: false })
            }
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Material.DialogTitle className={classes.yellow}>
              Warning!
            </Material.DialogTitle>

            <Material.DialogContent>
              <Material.DialogContentText id="alert-dialog-description">
                {this.state.errorDialogMessage}
              </Material.DialogContentText>
            </Material.DialogContent>
            {!this.state.proceedAnyway ? (
              <Material.DialogActions>
                <Material.Button
                  onClick={() =>
                    this.setState({ ...this.state, errorDialogVisible: false })
                  }
                  color="primary"
                >
                  Close
                </Material.Button>
              </Material.DialogActions>
            ) : (
              <Material.DialogActions>
                <Material.Button
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      errorDialogVisible: false,
                      proceedAnyway: false,
                    })
                  }
                  color="secondary"
                >
                  Cancel
                </Material.Button>
                <Material.Button
                  onClick={() => this.updateItem_final()}
                  color="primary"
                >
                  Proceed Anyway
                </Material.Button>
              </Material.DialogActions>
            )}
          </Material.Dialog>

          {/* confirmation dialog  */}
          <Material.Dialog
            open={this.state.confirmationDialogVisible}
            onClose={() => this.setState({ confirmationDialogVisible: false })}
          >
            <Material.DialogTitle>Confirmation</Material.DialogTitle>
            <Material.DialogContent>
              <Material.DialogContentText>{this.state.confirmationDialogMessage}</Material.DialogContentText>
            </Material.DialogContent>
            <Material.DialogActions>
              <Material.Button
                onClick={() => this.setState({ confirmationDialogVisible: false, activeStep: 2 })}
                color="secondary"
              >
                Cancel
              </Material.Button>
              <Material.Button
                onClick={() => {
                  this.state.onConfirmSave();
                  this.setState({ confirmationDialogVisible: false });
                }}
                color="primary"
              >
                Confirm
              </Material.Button>
            </Material.DialogActions>
          </Material.Dialog>
        </div>
      </Material.Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Material.withStyles(useStyles)(
    React.forwardRef((props, ref) => <EditModal innerRef={ref} {...props} />)
  )
);
