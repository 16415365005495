import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";

import * as Material from "@material-ui/core";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";

import { useStyles } from "./unforeseen.ppmp.list.per_office.styles";
import { mapStateToProps, mapDispatchToProps } from "../ppmp/ppmp.reducer";
import { COMM } from "../../helpers/common";
import * as UnforeseenItemsForApproval from "../unforeseen.ppmp/unforeseen.ppmp.service";

export class UnforeseenItemListForApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ppmp_no: 0,
      remarks: "",
      for_approval_items: [],
      itemDescription: "",
      itemName: "",
      res_no_data: 0,
      approve: false,
      remarks: "",
      confirmationDialogVisible: false, // Ensure the confirmation dialog visibility state
      item_to_approve_id: 0, // Initialize the item to approve ID
    };
    this.getForApprovalItemsPerPPMP =
      this.getForApprovalItemsPerPPMP.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.handleRemarksChange = this.handleRemarksChange.bind(this);
    this.onHandleConfirm = this.onHandleConfirm.bind(this);
    this.setPPMPItemStatus = this.setPPMPItemStatus.bind(this);
  }

  componentDidMount() {
    const { data, history, displayLoading, displayAlert } = this.props;
    sessionService
      .loadSession()
      .then((currentSession) => {
        if (!currentSession.selectedTransaction) {
          history.push("/");
        } else {
          const selectedTransaction = currentSession.selectedTransaction;
          this.setState(
            {
              ppmp_no: selectedTransaction.ppmp_no,
              transactionID: selectedTransaction.id,
              transactionNo: selectedTransaction.ppmp_no,
              transactionStatus: selectedTransaction.status,
              transactionDate: selectedTransaction.transdate,
              transactionYear: selectedTransaction.year,
              transactionDivision: selectedTransaction.division_name,
              transactionTitle: selectedTransaction.ppmp_title,
              ppmp_title: selectedTransaction.ppmp_title,
              itemDescription: selectedTransaction.description,
              itemName: selectedTransaction.name,
              division_name: selectedTransaction.division_name,
            },
            () => {
              this.getForApprovalItemsPerPPMP();
            }
          );
        }
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
      });
  }

  handleRemarksChange = (e) => {
    this.setState({
      ...this.state,
      remarks: e.target.value,
    });
  };

  onHandleConfirm(visible, itemId = 0) {
    this.setState({
      ...this.state,
      confirmationDialogVisible: visible,
      // item_to_approve_id: itemId,
    });
  }

  formatNumber(num) {
    return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";
  }
  setPPMPItemStatus() {
    const { displayLoading, displayAlert } = this.props;
    const { item_to_approve_id, approve, remarks } = this.state;
    displayLoading(true);

    // Determine the status code based on the approval
    const status_code = approve ? 1 : 2; // Approve: 1, Reject: 2

    // Call the API to set the item status
    UnforeseenItemsForApproval.setItemStatus({
      ppmp_item_id: item_to_approve_id,
      status_code: status_code,
      remarks: remarks,
    })
      .then((res) => {
        // Check if the status code is 2 (rejected) and remarks are empty
        if (status_code === 2 && remarks.trim() === "") {
          displayAlert({
            visible: true,
            message: "Remarks cannot be empty for rejection.",
            severity: "warning",
          });
          displayLoading(false);
          return;
        }
        if (res.error) {
          displayAlert({
            visible: true,
            message: res.error.message,
            severity: "warning",
          });
        } else {
          if (status_code === 2) {
            // Rejected
            displayAlert({
              visible: true,
              message: "Item rejected!",
              severity: "success",
            });
          } else {
            // Approved
            displayAlert({
              visible: true,
              message: "Item approved!",
              severity: "success",
            });
          }
          this.setState({
            ...this.state,
            confirmationDialogVisible: false,
          });
          this.getForApprovalItemsPerPPMP();
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          visible: true,
          message: err.message, // Render the message property of the error object
          severity: "error",
        });
      });
  }

  getForApprovalItemsPerPPMP() {
    const { displayLoading, displayAlert } = this.props;
    displayLoading(true);

    UnforeseenItemsForApproval.getUnforeseenItemsForApprovalPerPPMP({
      ...this.state,
      ppmp_no: this.state.ppmp_no,
      status: this.props.location.state.filtered_status,
    })
      .then((res) => {
        displayLoading(false);
        if (res.error || res === "No data found") {
          displayAlert({
            alertVisible: true,
            alertMessage: res.error ? res.error : "No PPMP found!",
            alertSeverity: "warning",
          });
          this.setState({
            ...this.state,
            res_no_data: 1,
            for_approval_items: [],
          });
        } else {
          this.setState({
            ...this.state,
            res_no_data: 0,
            for_approval_items: res,
          });
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { filtered_status, for_approval } = this.props.location.state;
    const {
      for_approval_items,
      res_no_data,
      transactionNo,
      confirmationDialogVisible,
      transactionStatus,
      transactionTitle,
      transactionDate,
      transactionYear,
      transactionDivision,
      itemDescription,
      itemName,
      isPreApproval,
      isIndicative,
      ppmp_title,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className="inline">
          <Material.Breadcrumbs aria-label="breadcrumb">
            <Material.Link
              underline="hover"
              color="inherit"
              href="/unforeseenPPMP"
            >
              UNFORESEEN PPMP
            </Material.Link>
            <Material.Typography color="text.primary">
              ITEM LIST
            </Material.Typography>
          </Material.Breadcrumbs>
        </div>
        <Material.Paper className={classes.transactionHeader}>
          <Material.Grid container spacing={3}>
            <Material.Grid item xs={9}>
              <div className="inline">
                <div className={classes.statusColor}>
                  <Material.Chip
                    className={transactionStatus}
                    label={COMM.capitalizeFirstLetter(transactionStatus)}
                  />
                </div>
                <Material.Typography
                  className="flex"
                  color="primary"
                  variant="h5"
                >
                  {transactionNo}
                </Material.Typography>
                <div>
                  {this.state.pow_id > 0 ? (
                    <Material.Chip
                      label="INFRA"
                      color="secondary"
                      // variant="outlined"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <Material.Typography variant="h6">
                  ({transactionYear}) {transactionTitle}
                </Material.Typography>
                {/* <Material.Typography variant="h6" color="secondary" sx={{fontWeight: "bold"}}>{this.state.is_reimbursement > 0 ? "Reimbursement" : ""}</Material.Typography> */}
                <Material.Typography
                  variant="h6"
                  color="secondary"
                  sx={{ fontWeight: "bold" }}
                ></Material.Typography>
              </div>
            </Material.Grid>
            <Material.Grid item xs={3}>
              <Material.Typography variant="subtitle2" className="pull-right">
                {isIndicative > 0 ? (
                  <Material.Chip
                    label="INDICATIVE"
                    variant="outlined"
                    size="small"
                    style={{ borderColor: "#ff9800", color: "#ff9800" }}
                  />
                ) : (
                  ""
                )}{" "}
                {COMM.DdMmmYyyyHhMm(transactionDate)}
              </Material.Typography>
            </Material.Grid>
          </Material.Grid>
          <Material.Divider className="div-margin" />
          <Material.Grid container spacing={3}>
            <Material.Grid item xs={9}>
              <Material.Typography variant="caption">
                PPMP Transaction No
              </Material.Typography>
              <Material.Typography variant="subtitle2">
                {" "}
                Division:
                {transactionDivision}
              </Material.Typography>
            </Material.Grid>
          </Material.Grid>
        </Material.Paper>
        <Material.Paper className={classes.drawer}>
          <Material.TableContainer>
            <Material.Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className={classes.table}
            >
              <Material.TableHead>
                <Material.TableRow>
                  <Material.TableCell></Material.TableCell>
                  <Material.TableCell align="center">
                    <b> Item Name</b>
                  </Material.TableCell>
                  <Material.TableCell>
                    <b>Quantity</b>
                  </Material.TableCell>
                  <Material.TableCell>
                    <b>Unit</b>
                  </Material.TableCell>
                  <Material.TableCell>
                    <b>Unit Cost</b>
                  </Material.TableCell>
                  <Material.TableCell>
                    <b>Total Cost</b>
                  </Material.TableCell>
                  <Material.TableCell>
                    <b>Mode of Procurement</b>
                  </Material.TableCell>
                  <Material.TableCell align="center">
                    <b>Action</b>
                  </Material.TableCell>
                  {filtered_status === 2 ? (
                    <Material.TableCell align="center">
                      <b>Remarks</b>
                    </Material.TableCell>
                  ) : (
                    ""
                  )}
                </Material.TableRow>
              </Material.TableHead>
              <Material.TableBody>
                {this.state.res_no_data === 1 ? (
                  <Material.TableRow>
                    <Material.TableCell colSpan={20} className="emptyCont">
                      <div className="empty">
                        <div className="emptyBorder">No PPMP data found.</div>
                      </div>
                    </Material.TableCell>
                  </Material.TableRow>
                ) : (
                  this.state.for_approval_items.map((item) => {
                    // const isApprovedStatusToShow = filtered_status;
                    const isApprovedStatusToShow =
                      parseInt(item.is_unforeseen_is_approved) ===
                      filtered_status;
                    if (isApprovedStatusToShow) {
                      return (
                        <React.Fragment key={item.id}>
                          <Material.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <Material.TableCell></Material.TableCell>
                            <Material.TableCell>
                              <Material.Tooltip
                                title={
                                  item.isRejected ? item.reject_remarks : ""
                                }
                                arrow
                                placement="top"
                              >
                                <b>
                                  <Material.Chip
                                    className={
                                      item.isApproved
                                        ? classes.approved
                                        : item.isPending
                                        ? classes.pending
                                        : classes.rejected
                                    }
                                    size="small"
                                    avatar={
                                      item.isApproved ? (
                                        <CheckCircleIcon />
                                      ) : item.isPending ? (
                                        <HelpIcon />
                                      ) : (
                                        <ThumbDownIcon />
                                      )
                                    }
                                    label={item.name}
                                  />
                                </b>
                              </Material.Tooltip>{" "}
                              {" - "}
                              {item.description}
                            </Material.TableCell>
                            <Material.TableCell align="center">
                              {item.qty}
                            </Material.TableCell>
                            <Material.TableCell>{item.unit}</Material.TableCell>
                            <Material.TableCell>
                              {this.formatNumber(item.unit_cost)}
                            </Material.TableCell>
                            <Material.TableCell>
                              {this.formatNumber(item.total_cost)}
                            </Material.TableCell>
                            <Material.TableCell>
                              {item.mode_of_procurement}
                            </Material.TableCell>
                            <Material.TableCell align="center">
                              {filtered_status !== 0 ? (
                                <div className="inline">
                                  <Material.IconButton
                                    className="animate"
                                    color="gray"
                                  >
                                    <ThumbUpIcon />
                                  </Material.IconButton>
                                  <Material.IconButton
                                    className="animate"
                                    color="gray"
                                  >
                                    <ThumbDownIcon />
                                  </Material.IconButton>
                                </div>
                              ) : (
                                <div className="inline">
                                  <Material.IconButton
                                    className="animate"
                                    color="primary"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          approve: true,
                                          item_to_approve_id: item.id,
                                        },
                                        () => {
                                          this.onHandleConfirm(true, item.id);
                                        }
                                      );
                                    }}
                                  >
                                    <ThumbUpIcon />
                                  </Material.IconButton>
                                  <Material.IconButton
                                    className="animate"
                                    color="primary"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          approve: false,
                                          item_to_approve_id: item.id,
                                        },
                                        () => {
                                          this.onHandleConfirm(true, item.id);
                                        }
                                      );
                                    }}
                                  >
                                    <ThumbDownIcon />
                                  </Material.IconButton>
                                </div>
                              )}
                            </Material.TableCell>
                            {filtered_status === 2 ? (
                              <Material.TableCell>
                                {item.is_unforeseen_reject_remarks}
                              </Material.TableCell>
                            ) : (
                              ""
                            )}
                          </Material.TableRow>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })
                )}
              </Material.TableBody>
            </Material.Table>
          </Material.TableContainer>
        </Material.Paper>
        <Material.Dialog
          open={confirmationDialogVisible}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogTitle id="alert-dialog-title" color="secondary">
            Confirmation
          </Material.DialogTitle>
          <Material.DialogContent>
            <Material.DialogContentText id="alert-dialog-description">
              Do you want to confirm this action?
            </Material.DialogContentText>
            {!this.state.approve ? (
              <Material.TextField
                id="remarks"
                label="Remarks"
                multiline
                type="text"
                onChange={this.handleRemarksChange}
                value={this.state.remarks}
                className={classes.textField_input}
                margin="dense"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            ) : (
              ""
            )}
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Button
              onClick={() => {
                this.setState({
                  ...this.state,
                  approve: false,
                  ppmp_item_to_approve_id: 0,
                  remarks: "",
                });
                this.onHandleConfirm(false, {});
              }}
              color="secondary"
            >
              Cancel
            </Material.Button>
            <Material.Button
              onClick={() =>
                this.setPPMPItemStatus(this.state.item_to_approve_id)
              }
              color="primary"
              autoFocus
            >
              Continue
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Material.withStyles(useStyles)(UnforeseenItemListForApproval));
