export const useStyles = (theme) => ({
    root: {
        '& .emptyCont': {
			padding: theme.spacing(4),
			'& .emptyBorder': {
				padding: theme.spacing(8, 4),
				borderRadius: '10px',
				borderStyle: 'dashed',
				borderColor: 'rgba(29, 29, 29, 0.10)',
			},
			'& .empty': {
				padding: theme.spacing(4),
				backgroundColor: 'rgba(29, 29, 29, 0.10)',
				borderRadius: '10px',
				textAlign: 'center',
				color: 'gray'
			},
		},
    },
    tablePaper: {
		overflow: 'auto',
        height: 'calc(100vh - 274px)',
	},
    table: {
        minWidth: 650,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    btngroup: {
        "& button": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            // textOverflow: "ellipsis",
        },
    },
    chipContainer: {
        padding: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    toolRight: {
        flexGrow: 1,
    },
    iconButton: {
        padding: '10px',
    },
    tableFooter: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    actionItem: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0),
        },
    },
    drawer: {
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        // width: 280,
        height: "97%",
		'& .MuiFormControl-root': {
			display: 'flex'
		},
		'& .MuiFormControlLabel-root': {
			paddingLeft: theme.spacing(1),
		},
		'& .radio-choices:hover':{
			'& .MuiButtonBase-root':{
				backgroundColor: 'rgba(8, 0, 74, 0.15)',
				transform: 'scale(1.1)'
			},
			'& .MuiFormControlLabel-label':{
				transform: 'scale(1.1)'
			}
		}
    },
    tableRow: {
        '&:hover': {
            backgroundColor: 'rgba(29, 29, 29, 0.10)',
            transform: 'scaleY(1.01)'
        }
    },
    button: {
        margin: theme.spacing(1),
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    tabContainer: {
		// padding: theme.spacing(2),
        color: '#b9b9b9',
        zIndex: 1,
        borderStyle: "solid",
        borderWidth: '1px',
        borderRadius: '0px 10px 0px 0px',
    },
    paperB: {
        padding: theme.spacing(2),
        backgroundColor: '#e9e7e7'
    },
    tabs: {
        paddingLeft: '12px',
        indicator: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& > div': {
                maxWidth: 40,
                width: '100%',
                backgroundColor: '#635ee7',
            },
            '& .MuiTabs-indicator': {
                backgroundColor: '#b9b9b9',
                transform: 'scale(1.1)',
			},
		},
		'& .Mui-selected': {
			backgroundColor: "#0000ff54"
		}
    },
    tab: {
        textTransform: 'none',
        color: '#2b2b2b',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        border: '1px',
        borderRadius: '10px 10px 0px 0px',
		backgroundColor: '#b9b9b9',
        '&:hover': {
			backgroundColor: 'rgba(8, 0, 74, 0.15)',
			color: '#1f1e1e',
            transform: 'scale(1.03)',
            // opacity: 1,
            // border: '1px',
            // borderRadius: '10px 10px 0px 0px',
            // backgroundColor: '#b9b9b9'
		},
		'& .Mui-selected': {
			backgroundColor: "#0000ff54"
		}
    },
    transactionHeader: {
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
        boxShadow: theme.shadows[3],
		'& .div-margin': {
			margin: theme.spacing(1, 0, 0, 0),
		},
		'& .pull-right': {
			float: 'right'
		}
	},
});
