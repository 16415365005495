export const mapStateToProps = (state) => {
    return {
        data: state.data
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
		displayLoading: (res) => {
            dispatch({
                type: "DISPLAYLOADING",
                payload: {
                    value: res,
                }
            })
		},
		displayAlert: (res) => {
            dispatch({
                type: "DISPLAYALERT",
                payload: {
					visible: res.visible,
					message: res.message,
					severity: res.severity,
                }
            })
		},
		
    };
};