// CO-AUTHOR - MP-DEV, Class Based Components
import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import * as Material from "@material-ui/core";
import { useStyles } from "./pow.modal.style";
import * as POWService from "./pow.service";

import {
  mapStateToProps,
  mapDispatchToProps,
} from "../activities/activities.reducer";

class POWModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_active: true,

      // new state
      id: 0,
      title: "",
      description: "",
      location: "",
      dimension: "",
      area: "",
      classification: "",
      project_cost: "",
      fund_source: "",
      project_duration: "",
      implemented_by: "",
      date_prepared: "",
      added_by: 0,
      is_active: false,

      year: "",
      office_id: 0,
      year_range: [],

      errorHandler: {},
    };
    this.onToggleSwitch = this.onToggleSwitch.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onChangeHandlerr = this.onChangeHandlerr.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.initStates = this.initStates.bind(this);
    this.requestSavePOW = this.requestSavePOW.bind(this);
    this.selectOnChangeHandler = this.selectOnChangeHandler.bind(this);
  }
  componentDidMount() {}
  onChangeHandlerr(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onToggleSwitch() {
    this.setState({
      ...this.state,
      is_active: !this.state.is_active,
    });
  }
  // This two function serves displays the SnackBar using the redux suppliers.reducer.js
  requestSavePOW() {
    const { requestGetPOW, displayAlert, displayLoading } = this.props;
    const {
      title,
      description,
      year,
      location,
      dimension,
      area,
      classification,
      project_cost,
      fund_source,
      project_duration,
      implemented_by,
    } = this.state;
    const errorHandler = {
      title_error: !title && true,
      description_error: !description && true,
      year_error: !year && true,
      location_error: !location && true,
      dimension_error: !dimension && true,
      area_error: !area && true,
      classification_error: !classification && true,
      project_cost_error: !project_cost && true,
      fund_source_error: !fund_source && true,
      project_duration_error: !project_duration && true,
      implemented_by_error: !implemented_by && true,
      //   date_prepared_error:
      //     date_prepared <= date_prepared ? !date_prepared && true : true,
    };

    this.setState(
      {
        ...this.state,
        errorHandler,
      },
      () => {
        const {
          title_error,
          description_error,
          year_error,
          location_error,
          dimension_error,
          area_error,
          classification_error,
          project_cost_error,
          fund_source_error,
          project_duration_error,
          implemented_by_error,
          //   date_prepared_error,
        } = errorHandler;
        if (
          !title_error &&
          !description_error &&
          !year_error &&
          !location_error &&
          !dimension_error &&
          !area_error &&
          !classification_error &&
          !project_cost_error &&
          !fund_source_error &&
          !project_duration_error &&
          !implemented_by_error
          //   !date_prepared_error
        ) {
          displayLoading(true);
          POWService.savePOW({ ...this.state })
            .then((res) => {
              requestGetPOW();
              displayLoading(false);
              this.closeModal();
              displayAlert({
                alertVisible: true,
                alertMessage: res.data.msg, // Data from The DAL Response
                alertSeverity: "success",
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    );
  }
  closeModal() {
    this.props.onCloseModal();
    this.setState({
      ...this.state,
      title: "",
      description: "",
      location: "",
      dimension: "",
      area: "",
      classification: "",
      project_cost: "",
      fund_source: "",
      project_duration: "",
      implemented_by: "",
      date_prepared: "",
      added_by: 0,
      year: "",
      office_id: 0,

      title_error: "",
      description_error: "",
      year_error: "",
      office_id_error: 0,
      location_error: "",
      dimension_error: "",
      area_error: "",
      classification_error: "",
      project_cost_error: "",
      fund_source_error: "",
      project_duration_error: "",
      implemented_by_error: "",
      date_prepared_error: "",
      added_by_error: 0,
    });
  }
  //for data display for edit (state)
  initStates() {
    const { editMode, editPOWData, data } = this.props;
    let id = editMode ? editPOWData.id : 0;

    // load year
    let currDate = new Date();
    let currYear = parseInt(currDate.getFullYear());
    //get year +1 & -2
    let yearRange = [currYear - 2, currYear - 1, currYear, currYear + 1];

    this.setState(
      {
        ...this.state,
        id,
        year_range: yearRange,
        is_active: editMode
          ? editPOWData.is_active == 1
            ? true
            : false
          : true,
        title: editMode ? editPOWData.title : "",
        description: editMode ? editPOWData.description : "",
        location: editMode ? editPOWData.location : "",
        dimension: editMode ? editPOWData.dimension : "",
        area: editMode ? editPOWData.area : "",
        classification: editMode ? editPOWData.classification : "",
        project_cost: editMode ? editPOWData.project_cost : "",
        fund_source: editMode ? editPOWData.fund_source : "",
        project_duration: editMode ? editPOWData.project_duration : "",
        implemented_by: editMode ? editPOWData.implemented_by : "",
        date_prepared: editMode ? editPOWData.date_prepared : "",
        added_by: editMode ? editPOWData.added_by : data.user.id,
        year: editMode ? editPOWData.year : currYear,
        office_id: editMode
          ? editPOWData.office_id
          : parseInt(data.user.office_id),
      },
      () => {
        // console.log('$$state', this.state);
        // load activity types
        // POWService.getActivityTypes().then((response) => {
        // 	console.log("$$response", response);
        // 	if (!response.error) {
        // 		this.setState({
        // 			...this.state,
        // 			activity_types: response.data,
        // 		}, () => {
        // 			console.log("$$activity_types", response.data);
        // 		});
        // 	} else {
        // 		this.setState({
        // 			...this.state,
        // 			activity_types: [],
        // 		});
        // 	}
        // }).catch((err) => {
        // 	console.log('$$err', err);
        // });
      }
    );
  }
  // Function to set state event name target from value target
  selectOnChangeHandler(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  // Render function:
  render() {
    // Declaration of props and state
    const { modalVisible, classes } = this.props;
    const { id, year_range, errorHandler } = this.state;

    return (
      <Material.Modal
        aria-labelledby="transition-modal-title"
        onRendered={this.initStates}
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalVisible}
        onClose={this.closeModal}
        closeAfterTransition
        disableAutoFocus={true}
        BackdropComponent={Material.Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div>
          <Material.Fade in={modalVisible}>
            <div className={classes.paper} spacing={2}>
              <Material.Typography color="primary" variant="h5">
                {id ? "Edit" : "Add"} Program of Works
              </Material.Typography>
              <Material.Divider></Material.Divider>
              <div>
                <Material.Typography
                  color="secondary"
                  className={classes.typography}
                >
                  Project Details
                </Material.Typography>
                <Material.Grid container spacing={2}>
                  <Material.Grid item xs={8}>
                    <Material.TextField
                      fullWidth
                      id="title-input"
                      onChange={this.onChangeHandler}
                      name="title"
                      label="Title *"
                      error={errorHandler.title_error}
                      value={this.state.title || ""}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    {/* <Material.TextField
                      fullWidth
                      id="description-input"
                      onChange={this.onChangeHandler}
                      name="description"
                      label="Description"
                      error={errorHandler.description_error}
                      value={this.state.description || ""}
                    /> */}
                    <Material.FormControl fullWidth>
                      <Material.InputLabel
                        margin="dense"
                        error={errorHandler.description_error}
                      >
                        Description
                      </Material.InputLabel>
                      <Material.Select
                        onChange={this.onChangeHandler}
                        name="description"
                        value={this.state.description || ""}
                        // error={errorHandler.implemented_by_error}
                      >
                        <Material.MenuItem value={"Vertical"}>
                          Vertical
                        </Material.MenuItem>
                        <Material.MenuItem value={"Horizontal"}>
                          Horizontal
                        </Material.MenuItem>
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                </Material.Grid>
                <Material.Grid container spacing={2}>
                  <Material.Grid item xs={4}>
                    <Material.FormControl fullWidth>
                      <Material.InputLabel
                        id="year-input"
                        error={this.state.yearError}
                      >
                        Year
                      </Material.InputLabel>
                      <Material.Select
                        id="year-select"
                        onChange={this.selectOnChangeHandler}
                        name="year"
                        error={errorHandler.year_error}
                        value={this.state.year || ""}
                        disabled={this.props.editMode}
                      >
                        {year_range.map((year) => (
                          <Material.MenuItem value={year}>
                            {year}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid item xs={8}>
                    <Material.TextField
                      fullWidth
                      id="location-input"
                      onChange={this.onChangeHandler}
                      name="location"
                      label="Location*"
                      error={errorHandler.location_error}
                      value={this.state.location || ""}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      fullWidth
                      id="dimension-input"
                      onChange={this.onChangeHandler}
                      name="dimension"
                      label="Dimension"
                      error={errorHandler.dimension_error}
                      value={this.state.dimension || ""}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      fullWidth
                      id="area-input"
                      onChange={this.onChangeHandler}
                      name="area"
                      label="Area"
                      error={errorHandler.area_error}
                      value={this.state.area || ""}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      fullWidth
                      id="classification-input"
                      onChange={this.onChangeHandler}
                      name="classification"
                      label="Classification"
                      error={errorHandler.classification_error}
                      value={this.state.classification || ""}
                    />
                  </Material.Grid>
                </Material.Grid>
                <Material.Typography
                  color="secondary"
                  margin="normal"
                  className={classes.typography}
                >
                  Other Details
                </Material.Typography>
                <Material.Grid container spacing={2}>
                  <Material.Grid item xs={6}>
                    <Material.TextField
                      fullWidth
                      type="number"
                      id="project_cost-input"
                      onChange={this.onChangeHandler}
                      name="project_cost"
                      label="Project Cost"
                      error={errorHandler.project_cost_error}
                      value={this.state.project_cost || ""}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={6}>
                    <Material.TextField
                      fullWidth
                      id="fund_source-input"
                      onChange={this.onChangeHandler}
                      name="fund_source"
                      label="Fund Source"
                      error={errorHandler.fund_source_error}
                      value={this.state.fund_source || ""}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      fullWidth
                      id="project_duration-input"
                      onChange={this.onChangeHandler}
                      name="project_duration"
                      label="Project Duration"
                      error={errorHandler.project_duration_error}
                      value={this.state.project_duration || ""}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    {/* <Material.TextField
											fullWidth
											id="implemented_by-input"
											onChange={this.onChangeHandler}
											name="implemented_by"
											label="To be Implemented by"
											error={errorHandler.implemented_by_error}
											value={this.state.implemented_by || ""}
										/> */}
                    <Material.FormControl fullWidth>
                      <Material.InputLabel
                        margin="dense"
                        error={errorHandler.type_of_catering_service_error}
                      >
                        To be Implemented by
                      </Material.InputLabel>
                      <Material.Select
                        onChange={this.onChangeHandler}
                        name="implemented_by"
                        value={this.state.implemented_by || ""}
                        error={errorHandler.implemented_by_error}
                      >
                        <Material.MenuItem value={"Administration"}>
                          Administration
                        </Material.MenuItem>
                        <Material.MenuItem value={"Contract"}>
                          Contract
                        </Material.MenuItem>
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid item xs={4}>
                    <Material.TextField
                      fullWidth
                      id="date_prepared-input"
                      type="date"
                      onChange={this.onChangeHandler}
                      name="date_prepared"
                      label="     "
                      error={errorHandler.date_prepared_error}
                      value={this.state.date_prepared || ""}
                      helperText="Date Prepared"
                    />
                  </Material.Grid>
                </Material.Grid>
                <form>
                  <Material.FormControlLabel
                    control={
                      <Material.Switch
                        checked={this.state.is_active}
                        onChange={this.onToggleSwitch}
                        name="is_active"
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                </form>
                <form
                  align="right"
                  className={classes}
                  noValidate
                  autoComplete="off"
                >
                  <Material.Button
                    className={classes.action}
                    variant="outlined"
                    color="primary"
                    onClick={this.requestSavePOW}
                    // onClick={this.onCreateActivity}
                  >
                    {id > 0 ? "Save" : "Add"}
                  </Material.Button>
                  <Material.Button
                    className={classes.action}
                    variant="outlined"
                    color="secondary"
                    onClick={this.closeModal}
                  >
                    Cancel
                  </Material.Button>
                </form>
              </div>
            </div>
          </Material.Fade>
        </div>
      </Material.Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(POWModal));
