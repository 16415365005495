export const useStyles = (theme) => ({
    root: {
        outline: 'none',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    padTop: {
        paddingBottom: '10px',
    },
    foroneline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '98%',
        },
    },
    fortwoline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '52ch',
        },
    },
    forthreeline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '34ch',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px',
        padding: theme.spacing(4, 4, 3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    FormContainer: {
        // // display: 'flex',
        // flexWrap: 'wrap',
    },
    action: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
})