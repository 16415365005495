export const mapDispatchToProps = (dispatch) => {
    return {
		setCredential: (res) => {
            dispatch({
                type: "SETCREDENTIAL",
                payload: {
                    userToken: res.userToken,
                    tokenHash: "ENCRYPTION TYPE",
                }
            })
		},
		setUser: (res) => {
            dispatch({
                type: "SETUSER",
                payload: {
                    user: res.user,
                }
            })
		},
		displayLoading: (res) => {
            dispatch({
                type: "DISPLAYLOADING",
                payload: {
                    value: res,
                }
            })
		},
		displayAlert: (res) => {
            dispatch({
                type: "DISPLAYALERT",
                payload: {
					visible: res.visible,
					message: res.message,
					severity: res.severity,
                }
            })
		},
		
    };
};