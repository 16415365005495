export default (state = {
    itemsDatasource: [],
    itemsTotal: 0,
    itemsFilter: [],
    categoryDatasource: [],
    supplierCreate: {}
}, action) => {
    switch (action.type) {
        case "SETITEMSDATASOURCE":
            return state = {
                ...state,
                itemsDatasource: action.payload.itemsDatasource,
                itemsTotal: action.payload.itemsTotal,
                itemsFilter: action.payload.itemsFilter,
                categoryDatasource: action.payload.categoryDatasource
            }
        case "DROPITEMSTATE":
            return state = {
                itemsDatasource: [],
                itemsTotal: 0,
                itemsFilter: [],
                categoryDatasource: [],
                supplierCreate: {}
            }
        default:
            return state;
    }
};
