import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";
import { setAPIauth } from "../../helpers/axios.instance";
import jwt_decode from "jwt-decode";

import * as Material from "@material-ui/core";

//imported icons
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ListIcon from "@material-ui/icons/List";
import CancelIcon from "@material-ui/icons/Cancel";
import DescriptionIcon from "@material-ui/icons/Description";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { mapStateToProps, mapDispatchToProps } from "./ppmp.reducer";
import { useStyles } from "./ppmp.styles";
import * as PPMPService from "./ppmp.service";
import { COMM } from "../../helpers/common";
// import VerifyPasswordModal from '../common/verify.login';

class PPMP extends Component {
	constructor() {
		super();
		this.state = {
			searchValue: "",
			dialogVisible: false,
			dialogSupplementalVisible: false,
			dialogDuplicateVisible: false,
			dialogVerifyVisible: false,
			ppmpTotal: 0,
			keyword: "",
			page_no: 0,
			limit: 10,
			sort_column: "",
			sort_type: "DESC",
			filter_year: 0,
			filter_office_id: 0,
			filter_status: "",
			year: "",
			division_name: "",
			ppmp_title: "",
			modalVisible: false,
			drawerVisible: false,
			showModalInfo: false,
			openExpanded: true,
			supplementalPlanTarget: null,
			duplicatePPMPTarget: null,
			chipData: [],
			ppmpToCancel: {},
			dialogOpen: false,
			ppmpCancelDialogVisible: false,
			//pow update
			is_pow: false,
			pow_id: 0,
			pows: [], // active pows only
			errorHandler: {},
			duplicate_year: 0,
			//reimbursement
			// is_reimbursement: 0,
			// is_reimbursement_checked: false,

			//indicative
			is_indicative: 0,
			is_indicative_checked: false,

			//unforseen/emergency
			is_unforeseen: 0,
			is_unforeseen_checked: false,
			selected_unforeseen_grounds: "",
			can_create_unforeseen_ppmp: false,

			pow_title: "",
			pow_year: "",
			pow_dimension: "",
			pow_area: "",
			pow_classification: "",
			pow_location: "",
			pow_project_cost: "",
			pow_fund_source: "",
			pow_project_duration: "",

			downloadFileLink: "",
			pending_remarks: "",

			// supplemental
			cancelSuppDialogVisible: false,
			suppPpmpToCancel: {},
		};
		this.onClickAddTransactionButton =
			this.onClickAddTransactionButton.bind(this);
		this.onSuppliesDisplay = this.onSuppliesDisplay.bind(this);
		this.onDisplayDialog = this.onDisplayDialog.bind(this);
		this.onDisplaySupplDialog = this.onDisplaySupplDialog.bind(this);
		this.onDisplayDuplicateDialog = this.onDisplayDuplicateDialog.bind(this);
		this.onDisplayVerifyDialog = this.onDisplayVerifyDialog.bind(this);
		this.requestGetPPMP = this.requestGetPPMP.bind(this);
		this.tableFilterContainer = this.tableFilterContainer.bind(this);
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.onTextChangeHandler = this.onTextChangeHandler.bind(this);
		this.onSearchHandler = this.onSearchHandler.bind(this);
		this.generateChip = this.generateChip.bind(this);
		this.removeChipHandler = this.removeChipHandler.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.TablePaginationActions = this.TablePaginationActions.bind(this);
		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.onDisplayPR = this.onDisplayPR.bind(this);
		this.onPPMPCartDisplay = this.onPPMPCartDisplay.bind(this);
		this.onVIEWListDisplay = this.onVIEWListDisplay.bind(this);
		this.onPPMPFormDisplay = this.onPPMPFormDisplay.bind(this);
		this.onPRDisplay = this.onPRDisplay.bind(this);
		this.rowRenderDisplay = this.rowRenderDisplay.bind(this);
		this.onClickAddSupplemental = this.onClickAddSupplemental.bind(this);
		this.onCancelPPMP = this.onCancelPPMP.bind(this);
		this.onRequestCancelPPMP = this.onRequestCancelPPMP.bind(this);
		this.onVerifyPassword = this.onVerifyPassword.bind(this);
		this.onCheckChangeHandler = this.onCheckChangeHandler.bind(this);
		this.requestPOW = this.requestPOW.bind(this);
		this.onCancelPPMPManual = this.onCancelPPMPManual.bind(this);
		this.onDownloadFile = this.onDownloadFile.bind(this);
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.onCheckIndicativeHandlerDuplicate =
			this.onCheckIndicativeHandlerDuplicate.bind(this);
		this.onCheckIndicativeHandlerCreate =
			this.onCheckIndicativeHandlerCreate.bind(this);
		this.onCheckUnforseenHandlerCreate =
			this.onCheckUnforseenHandlerCreate.bind(this);
		this.onHandleUnforseenGroundsChange =
			this.onHandleUnforseenGroundsChange.bind(this);
		this.onCancelSupplementalPPMP = this.onCancelSupplementalPPMP.bind(this);
		this.onDisplayCancelSuppDialog = this.onDisplayCancelSuppDialog.bind(this);
	}
	componentDidMount() {
		const { data } = this.props;
		this.setState(
			{
				...this.state,
				year: new Date().getFullYear(),
				filter_office_id: data.user.office_id,
				duplicate_year: "",
			},
			() => {
				this.requestGetPPMP();
				this.requestPOW();
			}
		);
	}
	requestPOW() {
		const { displayLoading, displayAlert, data } = this.props;

		displayLoading(true);
		let toPass = {
			office_id: parseInt(data.user.office_id),
			year: this.state.year,
		};
		PPMPService.getActivePOW(toPass)
			.then((response) => {
				// console.log("$$response", response);
				this.setState({
					...this.state,
					pows: response.error ? [] : response.data?.result0,
					can_create_unforeseen_ppmp:
						response.data?.result1[0]?.can_create_unforeseen_ppmp === "1",
					pow_id: 0,
				});
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
			});
	}
	requestGetPPMP() {
		const { displayLoading, displayAlert } = this.props;
		displayLoading(true);
		PPMPService.getPPMP({ ...this.state })
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: response.error,
						message: response.msg,
						severity: "warning",
					});
				} else {
					if (response[0]?.length > 0 && response.data.result0?.length > 0) {
						response[0].forEach((item, index) => {
							const result0Item = response.data.result0[index];
							if (result0Item?.is_received !== undefined) {
								item.is_recieved = result0Item.is_received;
							}
						});
					}

					this.props.setPPMPDataSource({
						ppmpDataSource:
							response[0]?.length > 0 ? response[0] : response.data.result0,
						// ppmpDataSource: response?.data.result0,
						ppmpRawSource: response.data.result0,
						ppmpTotal: parseInt(response.data.result1[0].row_count),
						ppmpFilter: response.data.result3,
					});
				}
				displayLoading(false);
				// this.generateChip();
			})
			.catch((err) => {
				displayLoading(false);
				// this.generateChip()
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
			});
	}
	tableFilterContainer() {
		const { classes, ppmp } = this.props;
		const options = [
			//{key: 1, label: "Office", value: this.state[`filter_office_id`], target: "office_id", handleChange: this.onFilterChange },
			{
				key: 1,
				label: "Status",
				value: this.state[`filter_status`],
				target: "status",
				handleChange: this.onFilterChange,
			},
			{
				key: 2,
				label: "Year",
				value: this.state[`filter_year`],
				target: "year",
				handleChange: this.onFilterChange,
			},
		];
		return (
			<Material.Paper className={classes.drawer}>
				{options.map((option) => {
					return (
						<Material.FormControl
							component="fieldset"
							key={option.key}
							margin="normal"
							size="small"
							variant="outlined"
						>
							<Material.FormLabel filled>{option.label}</Material.FormLabel>
							<Material.Divider></Material.Divider>
							<Material.RadioGroup
								aria-label={option.target}
								name={`filter_${option.target}`}
								value={option.value}
								onChange={option.handleChange}
							>
								{ppmp.ppmpFilter
									.filter((obj) => obj._particular === option.target)
									.map((filter, index) => {
										return (
											<Material.FormControlLabel
												key={`${filter._value}-${index}`}
												value={filter._value}
												className="radio-choices"
												control={<Material.Radio size="small" />}
												label={filter._value}
											/>
										);
									})}
							</Material.RadioGroup>
						</Material.FormControl>
					);
				})}
				<Material.Button
					variant="contained"
					color="primary"
					size="small"
					className={classes.button}
					startIcon={<FilterListIcon />}
					onClick={() => this.requestGetPPMP()}
				>
					Apply
				</Material.Button>
			</Material.Paper>
		);
	}
	onCheckChangeHandler(e) {
		this.setState({
			...this.state,
			is_pow: !this.state.is_pow,
			pow_id: 0,
		});
		// console.log("$$isPow", this.state.is_pow);
	}

	onCheckIndicativeHandlerDuplicate(e) {
		const isChecked = e.target.checked;
		this.setState((prevState) => ({
			...prevState,
			is_indicative_checked: isChecked,
			is_indicative: isChecked ? 1 : 0,
		}));
		// console.log("$$indicative", this.state.is_indicative);
	}

	onCheckIndicativeHandlerCreate(e) {
		const isChecked = e.target.checked;
		this.setState((prevState) => ({
			...prevState,
			is_indicative_checked: isChecked,
			is_indicative: isChecked ? 1 : 0,
		}));
		// console.log("$$indicative", this.state.is_indicative);
	}

	onCheckUnforseenHandlerCreate(e) {
		const isChecked = e.target.checked;
		this.setState((prevState) => ({
			...prevState,
			is_unforeseen_checked: isChecked,
			is_unforeseen: isChecked ? 1 : 0,
		}));
		// console.log("$$unforseen", this.state.is_unforseen);
	}

	// handle Change for Unforseen grounds
	onHandleUnforseenGroundsChange = (event) => {
		this.setState((prevState) => ({
			...prevState,
			selected_unforeseen_grounds: event.target.value,
		}));
	};

	// onCheckReimbursementHandler(e) {
	// 	this.setState({
	// 		...this.state,
	// 		is_reimbursement_checked: !this.state.is_reimbursement_checked,
	// 		is_reimbursement: 1,
	// 	});
	// }
	toggleDrawer(value) {
		this.setState({ ...this.state, drawerVisible: value });
	}
	onTextChangeHandler(e) {
		const node = e.target;
		this.setState({ ...this.state, [node.name]: node.value }, () => {
			if (node.name == "pow_id") {
				let filteredPOW = this.state.pows.filter(
					(element) => element.id == this.state.pow_id
				)[0];
				this.setState(
					{
						...this.state,
						pow_id: node.value,
						pow_title: filteredPOW.title,
						pow_year: filteredPOW.year,
						pow_dimension: filteredPOW.dimension,
						pow_area: filteredPOW.area,
						pow_classification: filteredPOW.classification,
						pow_location: filteredPOW.location,
						pow_project_cost: filteredPOW._project_cost,
						pow_fund_source: filteredPOW.fund_source,
						pow_project_duration: filteredPOW.project_duration,
					},
					() => {
						// console.log("$$state", this.state);
					}
				);
			}
		});
	}
	onSearchHandler(e) {
		e.preventDefault();
		this.setState(
			{ ...this.state, keyword: this.state.searchValue, page_no: 0 },
			() => {
				this.requestGetPPMP();
			}
		);
	}
	generateChip() {
		const {
			keyword,
			sort_column,
			sort_type,
			filter_year,
			filter_office_id,
			filter_status,
		} = this.state;
		let data = [];
		keyword &&
			data.push({
				key: 1,
				label: keyword,
				icon: <SearchIcon />,
				stateVar: "keyword",
				stateVara: "searchValue",
			});
		if (sort_column) {
			var icon =
				sort_type === "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
			data.push({
				key: 2,
				label: sort_column,
				icon,
				stateVar: "sort_column",
				stateVara: "sort_type",
			});
		}
		filter_year &&
			data.push({
				key: 3,
				label: filter_year,
				icon: <FilterListIcon />,
				stateVar: "filter_year",
				stateVara: "filter_year",
			});
		filter_office_id &&
			data.push({
				key: 3,
				label: filter_office_id,
				icon: <FilterListIcon />,
				stateVar: "filter_office_id",
				stateVara: "filter_office_id",
			});
		filter_status &&
			data.push({
				key: 3,
				label: filter_status,
				icon: <FilterListIcon />,
				stateVar: "filter_status",
				stateVara: "filter_status",
			});
		this.setState({ ...this.state, chipData: data });
	}
	removeChipHandler(value) {
		this.setState(
			() => {
				return {
					...this.state,
					chipData: this.state.chipData.filter(
						(chip) => chip.key !== value.key
					),
					[value.stateVar]: "",
					[value.stateVara]: "",
				};
			},
			() => this.requestGetPPMP()
		);
	}
	onFilterChange(e) {
		this.setState({ ...this.state, [e.target.name]: e.target.value });
	}
	handleChangePage(event, value) {
		this.setState({ ...this.state, page_no: value }, () => {
			this.requestGetPPMP();
		});
	}
	handleChangeRowsPerPage(e) {
		this.setState(
			{ ...this.state, page_no: 0, limit: parseInt(e.target.value, 10) },
			() => {
				this.requestGetPPMP();
			}
		);
	}
	TablePaginationActions(props) {
		const { classes } = this.props;
		const { count, page, rowsPerPage, onChangePage } = props;
		return (
			<div className={classes.tableFooter}>
				<Material.IconButton
					className="buttons"
					onClick={(event) => {
						onChangePage(event, 0);
					}}
					disabled={page === 0}
					aria-label="first page"
				>
					<FirstPageIcon />
				</Material.IconButton>
				<Material.IconButton
					className="buttons"
					onClick={(event) => {
						onChangePage(event, page - 1);
					}}
					disabled={page === 0}
					aria-label="previous page"
				>
					<KeyboardArrowLeft />
				</Material.IconButton>
				<Material.IconButton
					className="buttons"
					onClick={(event) => {
						onChangePage(event, page + 1);
					}}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
				>
					<KeyboardArrowRight />
				</Material.IconButton>
				<Material.IconButton
					className="buttons"
					onClick={(event) => {
						onChangePage(
							event,
							Math.max(0, Math.ceil(count / rowsPerPage) - 1)
						);
					}}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
				>
					<LastPageIcon />
				</Material.IconButton>
			</div>
		);
	}
	handleRequestSort(event, property) {
		const { sort_column, sort_type } = this.state;
		const isAsc = sort_column === property && sort_type === "asc";
		this.setState(
			{
				...this.state,
				sort_column: property,
				sort_type: isAsc ? "desc" : "asc",
			},
			() => this.requestGetPPMP()
		);
	}
	onClickAddTransactionButton() {
		const { displayLoading, displayAlert, data } = this.props;
		// validate first
		let errorHandler = {
			ppmp_title_error: !this.state.ppmp_title && true,
			year_error: !this.state.year && true,
			division_name_error: !this.state.division_name && true,
			pow_error: !this.state.is_pow
				? false
				: this.state.pow_id > 0
				? false
				: true,
			indicative_error: !this.state.is_indicative_checked
				? false
				: this.state.is_indicative > 0
				? false
				: true,
		};
		this.setState(
			{
				...this.state,
				errorHandler,
			},
			() => {
				if (
					!errorHandler.ppmp_title_error &&
					!errorHandler.year_error &&
					!errorHandler.division_name_error &&
					!errorHandler.pow_error &&
					!errorHandler.indicative_error
				) {
					displayLoading(true);
					PPMPService.createPPMPTransaction({
						user_id: data.user.id,
						office_id: data.user.office_id,
						division_name: this.state.division_name,
						ppmp_title: this.state.ppmp_title,
						year: this.state.year,
						pow_id: this.state.pow_id,
						// is_reimbursement: this.state.is_reimbursement,
						is_indicative: this.state.is_indicative,
						is_unforeseen: this.state.is_unforeseen,
						is_unforeseen_grounds: this.state.selected_unforeseen_grounds,
					})
						.then((res) => {
							// console.log("$$ppmp", this.state.is_indicative);
							if (res.error) {
								displayAlert({
									visible: true,
									message: res.msg,
									severity: "error",
								});
							} else {
								// this.requestGetPPMP();
								displayAlert({
									visible: true,
									message: res.msg,
									severity: "success",
								});
								this.onDisplayDialog(false);
								this.onSuppliesDisplay(res.data[0]);
							}
							displayLoading(false);
							this.resetIndicativeState();
						})
						.catch((err) => {
							displayLoading(false);
							this.onDisplayDialog(false);
							displayAlert({
								visible: true,
								message: err,
								severity: "warning",
							});
						});
				}
			}
		);
		// console.log("$$StateAfterSave", this.state);
	}
	onRequestCancelPPMP() {
		const { displayLoading, displayAlert } = this.props;
		displayLoading(true);
		PPMPService.updatePpmpStatus({
			status: "Cancelled",
			ppmp_id: this.state.ppmpToCancel.id,
		})
			.then((response) => {
				var res = response.data;
				if (res.error) {
					displayAlert({ visible: true, message: res.msg, severity: "error" });
				} else {
					this.requestGetPPMP();
					this.onCancelPPMP({}, false);
					displayAlert({
						visible: true,
						message: "PPMP was successfully cancelled.",
						severity: "success",
					});
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				this.onCancelPPMP({}, false);
				displayAlert({ visible: true, message: err, severity: "warning" });
			});
	}
	onClickAddSupplemental(value) {
		const { displayLoading, displayAlert, data } = this.props;
		displayLoading(true);
		PPMPService.createSupplementalTransaction({
			user_id: data.user.id,
			office_id: data.user.office_id,
			year: value.ppmp_year,
			parent: value.parent_id,
		})
			.then((res) => {
				if (res.error) {
					// console.log(res.error);
					displayAlert({ visible: true, message: res.msg, severity: "error" });
				} else {
					this.requestGetPPMP();
					this.onSuppliesDisplay(res.data[0]);
					this.onDisplayDialog(false);
					displayAlert({
						visible: true,
						message: res.msg,
						severity: "success",
					});
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				this.onDisplayDialog(false);
				displayAlert({ visible: true, message: err, severity: "warning" });
			});
	}

	onClickDuplicatePPMP(value) {
		const { displayLoading, displayAlert, data } = this.props;
		const { duplicate_year, is_indicative } = this.state;
		displayLoading(true);
		if (duplicate_year && duplicate_year > 2000) {
			PPMPService.duplicatePPMP({
				ppmp_id: value.parent_id,
				user_id: data.user.id,
				year: duplicate_year,
				is_indicative: is_indicative,
			})
				.then((res) => {
					// console.log("$$dataDupl", is_indicative);
					if (res.error) {
						displayAlert({
							visible: true,
							message: res.msg,
							severity: "error",
						});
					} else {
						// window.location.reload(true);
						this.requestGetPPMP();
						this.onDisplayDuplicateDialog(false);
						displayAlert({
							visible: true,
							message: res.msg,
							severity: "success",
						});
						//   this.setState({
						//     ...this.state,
						//     ppmpTotal: 0,
						//     keyword: "",
						//     page_no: 0,
						//     limit: 10,
						//     sort_column: "",
						//     sort_type: "DESC",
						//     filter_year: 0,
						//     filter_office_id: 0,
						//     filter_status: "",
						//     year: "",
						//     division_name: "",
						//     ppmp_title: "",
						//   });
					}
					this.resetIndicativeState();
					displayLoading(false);
				})
				.catch((err) => {
					displayLoading(false);
					this.onDisplayDialog(false);
					displayAlert({ visible: true, message: err, severity: "warning" });
				});
		} else {
			displayLoading(false);
			displayAlert({
				visible: true,
				message: "Please enter a valid year!",
				severity: "warning",
			});
		}
	}
	resetIndicativeState() {
		this.setState({
			is_indicative: 0,
			is_indicative_checked: false,
		});
	}
	onCancelPPMPManual() {
		this.setState({
			...this.state,
			ppmp_title: "",
			division_name: "",
			is_pow: false,
			is_indicative_checked: false,
			is_indicative: 0,
			pow_id: 0,
			dialogVisible: false,
		});
	}
	onDisplayDialog(value) {
		this.setState({
			...this.state,
			dialogVisible: value,
		});
	}
	onDisplayCancelSuppDialog(value) {
		this.setState({
			...this.state,
			cancelSuppDialogVisible: value,
		});
	}
	onDisplaySupplDialog(value, target = null) {
		this.setState({
			...this.state,
			dialogSupplementalVisible: value,
			supplementalPlanTarget: target,
		});
	}
	onDisplayDuplicateDialog(value, target = null) {
		this.setState({
			...this.state,
			dialogDuplicateVisible: value,
			supplementalPlanTarget: target,
			// duplicate_year: this.state.supplementalPlanTarget.year
		});
	}
	handleCloseDialog() {
		this.setState({ dialogOpen: false });
	}
	onDownloadFile(row) {
		try {
			let downloadFileLink = row.pow_filename;

			if (!downloadFileLink) {
				throw new Error("File not found.");

				// this.props.displayAlert({
				// 	visible: true,
				// 	message: "File not found.",
				// 	severity: "warning",
				// });
			} else {
				let path = "uploads/infra/";
				let link = process.env.REACT_APP_ROOTURL + `${path}${downloadFileLink}`;
				window.open(link, "_blank", "noreferrer");
			}
		} catch (err) {
			// console.error("$$Error downloading file:", err.message);
			this.props.displayAlert({
				visible: true,
				message: "Error downloading file",
				severity: "warning",
			});
			this.setState({ dialogOpen: true });
		}
	}

	onDisplayVerifyDialog(value, target = null) {
		this.setState({
			...this.state,
			dialogDuplicateVisible: value,
			dialogVerifyVisible: value,
			supplementalPlanTarget: target,
		});
	}
	onDisplayPR() {
		this.props.history.push("pr");
	}
	onSuppliesDisplay(data) {
		// console.log('$$data', data);
		sessionService
			.saveSession({ ...this.props.auth, selectedTransaction: data })
			.then(() => {
				// console.log('$$pow_id', this.state.pow_id);
				if (this.state.pow_id > 0) {
					// console.log('$$ppmplist');
					// this.props.history.push("/ppmplist");
					this.props.history.push({
						pathname: "/ppmplist",
						state: {
							ppmpId: data.id,
						},
					});
				} else {
					// console.log('$$to: ppmpsupplies ppmp_id:', data.id);
					// this.props.history.push("/ppmpsupplies");
					this.props.history.push({
						pathname: "/ppmpsupplies",
						state: {
							ppmpId: data.id,
						},
					});
				}
			})
			.catch((err) => {
				this.props.displayAlert({
					visible: true,
					message: err,
					severity: "warning",
				});
			});
	}
	onPPMPCartDisplay(data) {
		sessionService
			.saveSession({ ...this.props.auth, selectedTransaction: data })
			.then(() => {
				// this.props.history.push("/ppmplist");

				this.props.history.push({
					pathname: "/ppmplist",
					state: {
						ppmpId: data.id,
					},
				});
			})
			.catch((err) => {
				this.props.displayAlert({
					visible: true,
					message: err,
					severity: "warning",
				});
			});
	}

	onVIEWListDisplay(data) {
		sessionService
			.saveSession({ ...this.props.auth, selectedTransaction: data })
			.then(() => {
				// console.log("$$view", data);
				this.props.history.push("/viewlist");
			})
			.catch((err) => {
				this.props.displayAlert({
					visible: true,
					message: err,
					severity: "warning",
				});
			});
	}
	onPPMPFormDisplay(data) {
		sessionService
			.saveSession({ ...this.props.auth, selectedTransaction: data })
			.then(() => {
				this.props.history.push("/ppmpform");
			})
			.catch((err) => {
				this.props.displayAlert({
					visible: true,
					message: err,
					severity: "warning",
				});
			});
	}
	onPRDisplay(data) {
		sessionService
			.saveSession({ ...this.props.auth, selectedTransaction: data })
			.then(() => {
				this.props.history.push("/pr");
			})
			.catch((err) => {
				this.props.displayAlert({
					visible: true,
					message: err,
					severity: "warning",
				});
			});
	}
	onCancelPPMP(row, visible) {
		// console.log('$$Row', row);
		this.setState({
			...this.state,
			ppmpToCancel: row,
			ppmpCancelDialogVisible: visible,
		});
	}
	onCancelSupplementalPPMP(suppItem, visible) {
		const { displayLoading, displayAlert } = this.props;
		// console.log('$$SuppItem', suppItem);
		// console.log('$$SuppItemVisible', visible);
		this.setState({
			...this.state,
			// suppPpmpToCancel: suppItem,
			// cancelSuppDialogVisible: visible,
			ppmpToCancel: suppItem.id,
			ppmpCancelDialogVisible: visible,
		});
		if (
			window.confirm(
				`Are you sure you want to cancel PPMP No. ${suppItem.ppmp_no}?`
			)
		) {
			PPMPService.updatePpmpStatus({
				status: "Cancelled",
				ppmp_id: suppItem.id,
			})
				.then((response) => {
					var res = response.data;
					if (res.error) {
						displayAlert({
							visible: true,
							message: res.msg,
							severity: "error",
						});
					} else {
						this.requestGetPPMP();
						this.onCancelPPMP({}, false);
						displayAlert({
							visible: true,
							message: res.msg,
							severity: "success",
						});
					}
				})
				.catch((err) => {
					this.onCancelPPMP({}, false);
					displayAlert({ visible: true, message: err, severity: "warning" });
				});
		} else {
			// Code to handle cancellation (optional)
		}
	}
	onVerifyPassword() {
		// e.preventDefault();
		const user = {
			username: this.state.username,
			password: this.state.password,
		};
		let validate = { usernameError: false, passwordError: false };
		user.password === "" && (validate.passwordError = true);
		this.setState({ ...this.state, ...validate }, () =>
			this.requestCredential()
		);
	}
	requestCredential() {
		const { displayLoading, displayAlert } = this.props;
		const { usernameError, passwordError, type } = this.state;
		if (!usernameError && !passwordError) {
			displayLoading(true);
			PPMPService.login({ ...this.state, proc: type ? "Y" : "N" })
				.then((res) => {
					if (res.error) {
						displayAlert({
							visible: true,
							message: res.message,
							severity: "error",
						});
						displayLoading(false);
					} else {
						this.redirectLogin(res);
					}
				})
				.catch((err) => {
					displayAlert({ visible: true, message: err, severity: "warning" });
					displayLoading(false);
				});
		}
	}
	redirectLogin(token) {
		const { displayLoading, displayAlert, getCredential } = this.props;
		displayLoading(true);
		const data = token;
		setAPIauth(data);
		sessionService
			.saveSession({
				userToken: data,
				selectedTransaction: null,
				selectedPRTransaction: null,
			})
			.then(() => {
				const user = jwt_decode(data);
				const access = {
					...user,
					can_manage_ppmp: user.can_manage_ppmp === "Y" ? true : false,
					can_manage_admin: user.can_manage_admin === "Y" ? true : false,
					isactive: user.isactive === "Y" ? true : false,
				};
				getCredential({ userToken: data });
				this.props.setUser({ user: access });
				this.onClickDuplicatePPMP({
					parent_id: this.state.supplementalPlanTarget.id,
				});
				displayLoading(false);
			})
			.catch((err) => {
				displayAlert({
					visible: true,
					message: err.message,
					severity: "warning",
				});
				displayLoading(false);
			});
	}
	rowRenderDisplay() {
		const { ppmp, classes } = this.props;

		return ppmp.ppmpDataSource.map((row) => (
			<React.Fragment key={`ppmpd-${row.id}${row.ppmp_no}`}>
				<Material.TableRow
					className={row.parent_id ? classes.expandableRow : classes.tableRow}
				>
					<Material.TableCell>
						{row.supplemental ? (
							<Material.Badge
								badgeContent={row.supplemental.length}
								invisible={
									this.state[`openExpanded-col${row.id}${row.ppmp_no}`]
								}
								color="secondary"
							>
								<Material.IconButton
									aria-label="expand row"
									size="small"
									onClick={() =>
										this.setState({
											...this.state,
											[`openExpanded-col${row.id}${row.ppmp_no}`]:
												!this.state[`openExpanded-col${row.id}${row.ppmp_no}`],
										})
									}
								>
									{this.state[`openExpanded-col${row.id}${row.ppmp_no}`] ? (
										<KeyboardArrowUpIcon />
									) : (
										<KeyboardArrowDownIcon />
									)}
								</Material.IconButton>
							</Material.Badge>
						) : (
							""
						)}
					</Material.TableCell>
					<Material.TableCell>
						<Material.IconButton
							className="animate row"
							onClick={(event) => {
								this.setState({
									...this.state,
									[`anchorEl-${row.id}${row.ppmp_no}`]: event.currentTarget,
								});
							}}
						>
							<DoubleArrowIcon />
						</Material.IconButton>
						<Material.Popover
							id="mouse-over-popover"
							open={Boolean(this.state[`anchorEl-${row.id}${row.ppmp_no}`])}
							anchorEl={this.state[`anchorEl-${row.id}${row.ppmp_no}`]}
							onClick={() => {
								this.setState({
									[`anchorEl-${row.id}${row.ppmp_no}`]: null,
								});
							}}
							classes={{ paper: classes.paperless_ref }}
							anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
							transformOrigin={{ vertical: "bottom", horizontal: "left" }}
						>
							{row.status === "Open" ? (
								<React.Fragment>
									<Material.Tooltip title="PPMP List" aria-label="PPMP List">
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onPPMPCartDisplay(row)}
										>
											<ListIcon />
										</Material.Fab>
									</Material.Tooltip>
									<Material.Tooltip title="Cancel PPMP" aria-label="PPMP List">
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onCancelPPMP(row, true)}
										>
											<CancelIcon />
										</Material.Fab>
									</Material.Tooltip>
								</React.Fragment>
							) : (
								""
							)}
							{row.status === "Pre-Approval" || row.status === "Returned" ? (
								<Material.Tooltip title="PPMP List" aria-label="PPMP List">
									<Material.Fab
										className={classes.actionList}
										size="small"
										onClick={() => this.onPPMPCartDisplay(row)}
									>
										<ListIcon />
									</Material.Fab>
								</Material.Tooltip>
							) : (
								""
							)}
							{row.status === "Received(PBMO)" ? (
								//console.log("$$row", row),
								<React.Fragment>
									{row.pow_id > 0 ? (
										<Material.Tooltip
											title="Download PDF File"
											aria-label="Download PDF File"
										>
											<Material.Fab className={classes.actionList} size="small">
												<FileDownloadIcon
													// fileLink={this.state.uploadFileLink}
													onClick={() =>
														!row.pow_filename
															? this.setState({ dialogOpen: true })
															: this.onDownloadFile(row)
													}
												/>
											</Material.Fab>
										</Material.Tooltip>
									) : (
										""
									)}
									<Material.Tooltip
										title="Generated Form"
										aria-label="Generated Form"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onPPMPFormDisplay(row)}
										>
											<DescriptionIcon />
										</Material.Fab>
									</Material.Tooltip>
									<Material.Tooltip
										title="Duplicate PPMP"
										aria-label="Duplicate PPMP"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() =>
												this.onDisplayDuplicateDialog(
													true,
													row,
													this.state.is_indicative
												)
											}
										>
											<FileCopyIcon />
										</Material.Fab>
									</Material.Tooltip>
								</React.Fragment>
							) : (
								""
							)}
							{row.status === "Generated" ? (
								// console.log("$$row", row),
								<React.Fragment>
									{row.pow_id > 0 ? (
										<Material.Tooltip
											title="Download PDF File"
											aria-label="Download PDF File"
										>
											<Material.Fab className={classes.actionList} size="small">
												<FileDownloadIcon
													// fileLink={this.state.uploadFileLink}
													onClick={() =>
														!row.pow_filename
															? this.setState({ dialogOpen: true })
															: this.onDownloadFile(row)
													}
												/>
											</Material.Fab>
										</Material.Tooltip>
									) : (
										""
									)}
									<Material.Tooltip
										title="Generated Form"
										aria-label="Generated Form"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onPPMPFormDisplay(row)}
										>
											<DescriptionIcon />
										</Material.Fab>
									</Material.Tooltip>
									<Material.Tooltip
										title="Duplicate PPMP"
										aria-label="Duplicate PPMP"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() =>
												this.onDisplayDuplicateDialog(
													true,
													row,
													this.state.is_indicative
												)
											}
										>
											<FileCopyIcon />
										</Material.Fab>
									</Material.Tooltip>
								</React.Fragment>
							) : (
								""
							)}
							{row.status === "Approved" ? (
								<React.Fragment>
									{row.pow_id > 0 ? (
										<Material.Tooltip
											title="Download PDF File"
											aria-label="Download PDF File"
										>
											<Material.Fab className={classes.actionList} size="small">
												<FileDownloadIcon
													onClick={() =>
														!row.pow_filename
															? this.setState({ dialogOpen: true })
															: this.onDownloadFile(row)
													}
												/>
											</Material.Fab>
										</Material.Tooltip>
									) : (
										""
									)}
									<Material.Tooltip
										title="Generated Form"
										aria-label="Generated Form"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onPPMPFormDisplay(row)}
										>
											<DescriptionIcon />
										</Material.Fab>
									</Material.Tooltip>
									<Material.Tooltip
										title="Duplicate PPMP"
										aria-label="Duplicate PPMP"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() =>
												this.onDisplayDuplicateDialog(
													true,
													row,
													this.state.is_indicative
												)
											}
										>
											<FileCopyIcon />
										</Material.Fab>
									</Material.Tooltip>

									<Material.Tooltip
										title="View List of Items"
										aria-label="View List of Items"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onVIEWListDisplay(row)}
										>
											<VisibilityIcon />
										</Material.Fab>
									</Material.Tooltip>
									<Material.Tooltip
										title="Add Supplemental PPMP"
										aria-label="Add Supplemental PPMP"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onDisplaySupplDialog(true, row)}
										>
											<AddIcon />
										</Material.Fab>
									</Material.Tooltip>
								</React.Fragment>
							) : (
								""
							)}
							{row.status === "Pending" ? (
								<Material.Tooltip
									title="Generated Form"
									aria-label="Generated Form"
								>
									<Material.Fab
										size="small"
										onClick={() => this.onPPMPFormDisplay(row)}
									>
										<DescriptionIcon />
									</Material.Fab>
								</Material.Tooltip>
							) : (
								""
							)}
							{row.status === "For Revision" ? (
								<React.Fragment>
									<Material.Tooltip title="PPMP List" aria-label="PPMP List">
										<Material.Fab
											size="small"
											onClick={() => this.onPPMPCartDisplay(row)}
										>
											<ListIcon />
										</Material.Fab>
									</Material.Tooltip>
								</React.Fragment>
							) : (
								""
							)}
							{row.status === "Approved-Revision" ? (
								// <React.Fragment>
								//   <Material.Tooltip
								//     title="Generated Form"
								//     aria-label="Generated Form"
								//   >
								//     <Material.Fab
								//       className={classes.actionList}
								//       size="small"
								//       onClick={() => this.onPPMPFormDisplay(row)}
								//     >
								//       <DescriptionIcon />
								//     </Material.Fab>
								//   </Material.Tooltip>
								// </React.Fragment>
								<React.Fragment>
									{row.pow_id > 0 ? (
										<Material.Tooltip
											title="Download PDF File"
											aria-label="Download PDF File"
										>
											<Material.Fab className={classes.actionList} size="small">
												<FileDownloadIcon
													onClick={() =>
														!row.pow_filename
															? this.setState({ dialogOpen: true })
															: this.onDownloadFile(row)
													}
												/>
											</Material.Fab>
										</Material.Tooltip>
									) : (
										""
									)}
									<Material.Tooltip
										title="Generated Form"
										aria-label="Generated Form"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onPPMPFormDisplay(row)}
										>
											<DescriptionIcon />
										</Material.Fab>
									</Material.Tooltip>
									<Material.Tooltip
										title="Duplicate PPMP"
										aria-label="Duplicate PPMP"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() =>
												this.onDisplayDuplicateDialog(
													true,
													row,
													this.state.is_indicative
												)
											}
										>
											<FileCopyIcon />
										</Material.Fab>
									</Material.Tooltip>

									<Material.Tooltip
										title="View List of Items"
										aria-label="View List of Items"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onVIEWListDisplay(row)}
										>
											<VisibilityIcon />
										</Material.Fab>
									</Material.Tooltip>
									<Material.Tooltip
										title="Add Supplemental PPMP"
										aria-label="Add Supplemental PPMP"
									>
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onDisplaySupplDialog(true, row)}
										>
											<AddIcon />
										</Material.Fab>
									</Material.Tooltip>
								</React.Fragment>
							) : (
								""
							)}
							{row.status === "For Edit" ? (
								<React.Fragment>
									<Material.Tooltip title="PPMP List" aria-label="PPMP List">
										<Material.Fab
											className={classes.actionList}
											size="small"
											onClick={() => this.onPPMPCartDisplay(row)}
										>
											<ListIcon />
										</Material.Fab>
									</Material.Tooltip>
								</React.Fragment>
							) : (
								""
							)}
						</Material.Popover>
					</Material.TableCell>
					<Material.TableCell className={classes.statusColor}>
						{row.status === "Pending" ? (
							<Material.Tooltip title={row.pending_remarks}>
								<Material.Chip
									className={row.status}
									label={COMM.capitalizeFirstLetter(row.status)}
								/>
							</Material.Tooltip>
						) : (
							<Material.Chip
								className={row.status === "Received(PBMO)" ? "RecievedPBMO" : row.status === "For Edit" ? "ForEdit" : row.status}
								label={COMM.capitalizeFirstLetter(row.status)}
							/>
						)}
						{/* {row.is_indicative > 0 ? (
								// <Material.Chip
								//   label="INDICATIVE"
								//   color="primary"
								//   variant="outlined"
								//   size="small"
								// ></Material.Chip>
								<Material.Typography color="primary">
									{"(INDICATIVE)"}{" "}
								</Material.Typography>
								) : (
								""
								)}{" "} */}
					</Material.TableCell>
					<Material.TableCell component="th" scope="row">
						<React.Fragment>
							{" "}
							{row.ppmp_no}{" "}
							<div style={{ marginTop: "4px" }}>
								{row.is_indicative > 0 ? (
									<Material.Chip
										label="INDICATIVE"
										variant="outlined"
										size="small"
										style={{ borderColor: "#ff9800", color: "#ff9800" }}
									/>
								) : (
									""
								)}
							</div>
							<div style={{ marginTop: "4px" }}>
								{row.pow_id > 0 ? (
									<Material.Chip
										label="infra"
										color="primary"
										variant="outlined"
										size="small"
									/>
								) : (
									""
								)}
							</div>
							<div style={{ marginTop: "4px" }}>
								{row.is_unforeseen > 0 ? (
									<Material.Chip
										label="UNFORESEEN"
										variant="outlined"
										size="small"
										style={{ borderColor: "#ef5350", color: "#ef5350" }}
									/>
								) : (
									""
								)}
							</div>
						</React.Fragment>
					</Material.TableCell>
					<Material.TableCell align="left">
						{row.year}
						{/* {console.log("$$row", row)} */}
					</Material.TableCell>
					<Material.TableCell align="center">
						{" "}
						{row.ppmp_title}
					</Material.TableCell>
					<Material.TableCell align="left">
						<Material.Typography variant="subtitle2">
							{row.division_name}
						</Material.Typography>
					</Material.TableCell>
					<Material.TableCell align="center ">
						{" "}
						{row.employee}
					</Material.TableCell>
					<Material.TableCell align="center">
						{" "}
						{COMM.DdMmmYyyyHhMm(row.transdate)}{" "}
					</Material.TableCell>
					<Material.TableCell align="center">
						{row.revision_count === "3" ? (
							<Material.Chip
								className={classes.noRevisionLeft}
								label={row.revision_count}
							/>
						) : (
							""
						)}
						{row.revision_count === "2" ? (
							<Material.Chip
								className={classes.revision_two}
								label={row.revision_count}
							/>
						) : (
							""
						)}
						{row.revision_count === "1" ? (
							<Material.Chip
								className={classes.revision_one}
								label={row.revision_count}
							/>
						) : (
							""
						)}
						{row.revision_count === "0" ? (
							<Material.Chip
								className={classes.noRevisionYet}
								label={row.revision_count}
							/>
						) : (
							""
						)}
					</Material.TableCell>
				</Material.TableRow>
				{row.supplemental ? (
					<Material.TableRow className={classes.expandableContent}>
						<Material.TableCell
							style={{ paddingBottom: 0, paddingTop: 0 }}
							colSpan={10}
						>
							<Material.Collapse
								in={this.state[`openExpanded-col${row.id}${row.ppmp_no}`]}
								timeout="auto"
								unmountOnExit
							>
								<Material.Box margin={1}>
									<Material.Typography
										variant="subtitle2"
										gutterBottom
										component="div"
									>
										Supplemental PPMP
									</Material.Typography>
									<Material.Divider />
									<Material.Table size="small" aria-label="purchases">
										<Material.TableBody>
											{row.supplemental.map((suppItem) => {
												{/* console.log('$$suppItem', suppItem); */}
												return (
													<Material.TableRow
														key={`suppl-${suppItem.id}${suppItem.ppmp_no}`}
														className={classes.tableRow}
													>
														<Material.TableCell colSpan={3} />
														<Material.TableCell>
															<Material.IconButton
																className="animate row"
																onClick={(event) => {
																	this.setState({
																		...this.state,
																		[`anchorEl-${suppItem.id}${suppItem.ppmp_no}`]:
																			event.currentTarget,
																	});
																}}
															>
																<DoubleArrowIcon />
															</Material.IconButton>
															<Material.Popover
																id="mouse-over-popover"
																open={Boolean(
																	this.state[
																		`anchorEl-${suppItem.id}${suppItem.ppmp_no}`
																	]
																)}
																anchorEl={
																	this.state[
																		`anchorEl-${suppItem.id}${suppItem.ppmp_no}`
																	]
																}
																onClick={() => {
																	this.setState({
																		...this.state,
																		[`anchorEl-${suppItem.id}${suppItem.ppmp_no}`]:
																			null,
																	});
																}}
																classes={{ paper: classes.paperless_ref }}
																anchorOrigin={{
																	vertical: "bottom",
																	horizontal: "left",
																}}
																transformOrigin={{
																	vertical: "bottom",
																	horizontal: "left",
																}}
															>
																{suppItem.status === "Open" ? (
																	<React.Fragment>
																		<Material.Tooltip
																			title="Supplemental PPMP List"
																			aria-label="Supplemental PPMP List"
																		>
																			<Material.Fab
																				className={classes.actionList}
																				size="small"
																				onClick={() =>
																					this.onPPMPCartDisplay(suppItem)
																				}
																			>
																				<ListIcon />
																			</Material.Fab>
																		</Material.Tooltip>
																		<Material.Tooltip
																			title="Cancel Supplemental PPMP"
																			aria-label="Supplemental PPMP List"
																		>
																			<Material.Fab
																				className={classes.actionList}
																				size="small"
																				onClick={() =>
																					this.onCancelSupplementalPPMP(
																						suppItem,
																						true
																					)
																				}
																			>
																				<CancelIcon />
																			</Material.Fab>
																		</Material.Tooltip>
																	</React.Fragment>
																) : (
																	""
																)}
																{suppItem.status === "Pre-Approval" ||
																suppItem.status === "Returned" ? (
																	<Material.Tooltip
																		title="Supplemental PPMP List"
																		aria-label="Supplemental PPMP List"
																	>
																		<Material.Fab
																			className={classes.actionList}
																			size="small"
																			onClick={() =>
																				this.onPPMPCartDisplay(suppItem)
																			}
																		>
																			<ListIcon />
																		</Material.Fab>
																	</Material.Tooltip>
																) : (
																	""
																)}
																{suppItem.status === "Generated" ? (
																	<Material.Tooltip
																		title="Supplemental Generated Form"
																		aria-label="Generated Form"
																	>
																		<Material.Fab
																			className={classes.acti2000onList}
																			size="small"
																			onClick={() =>
																				this.onPPMPFormDisplay(suppItem)
																			}
																		>
																			<DescriptionIcon />
																		</Material.Fab>
																	</Material.Tooltip>
																) : (
																	""
																)}
																{suppItem.status === "Approved" ? (
																	<React.Fragment>
																		<Material.Tooltip
																			title="Supplemental Generated Form"
																			aria-label="Generated Form"
																		>
																			<Material.Fab
																				className={classes.actionList}
																				size="small"
																				onClick={() =>
																					this.onPPMPFormDisplay(suppItem)
																				}
																			>
																				<DescriptionIcon />
																			</Material.Fab>
																		</Material.Tooltip>

																		<Material.Tooltip
																			title="Duplicate PPMP"
																			aria-label="Duplicate PPMP"
																		>
																			<Material.Fab
																				className={classes.actionList}
																				size="small"
																				onClick={() =>
																					this.onDisplayDuplicateDialog(
																						true,
																						row,
																						this.state.is_indicative
																					)
																				}
																			>
																				<FileCopyIcon />
																			</Material.Fab>
																		</Material.Tooltip>

																		<Material.Tooltip
																			title="View List of Items"
																			aria-label="View List of Items"
																		>
																			<Material.Fab
																				className={classes.actionList}
																				size="small"
																				onClick={() =>
																					this.onVIEWListDisplay(suppItem)
																				}
																			>
																				<VisibilityIcon />
																			</Material.Fab>
																		</Material.Tooltip>
																	</React.Fragment>
																) : (
																	""
																)}
																{suppItem.status === "Pending" ? (
																	<Material.Tooltip
																		title="Supplemental Generated Form"
																		aria-label="Generated Form"
																	>
																		<Material.Fab
																			size="small"
																			onClick={() =>
																				this.onPPMPFormDisplay(suppItem)
																			}
																		>
																			<DescriptionIcon />
																		</Material.Fab>
																	</Material.Tooltip>
																) : (
																	""
																)}
																{suppItem.status === "For Revision" ? (
																	<Material.Tooltip
																		title="Supplemental PPMP List"
																		aria-label="PPMP List"
																	>
																		<Material.Fab
																			size="small"
																			onClick={() =>
																				this.onPPMPCartDisplay(suppItem)
																			}
																		>
																			<ListIcon />
																		</Material.Fab>
																	</Material.Tooltip>
																) : (
																	""
																)}
																{suppItem.status === "Approved-Revision" ? (
																	<React.Fragment>
																		<Material.Tooltip
																			title="Supplemental Generated Form"
																			aria-label="Generated Form"
																		>
																			<Material.Fab
																				className={classes.actionList}
																				size="small"
																				onClick={() =>
																					this.onPPMPFormDisplay(suppItem)
																				}
																			>
																				<DescriptionIcon />
																			</Material.Fab>
																		</Material.Tooltip>

																		<Material.Tooltip
																			title="View List of Items"
																			aria-label="View List of Items"
																		>
																			<Material.Fab
																				className={classes.actionList}
																				size="small"
																				onClick={() =>
																					this.onVIEWListDisplay(suppItem)
																				}
																			>
																				<VisibilityIcon />
																			</Material.Fab>
																		</Material.Tooltip>
																	</React.Fragment>
																) : (
																	""
																)}
															</Material.Popover>
														</Material.TableCell>
														<Material.TableCell className={classes.statusColor}>
															<Material.Chip
																className={suppItem.status}
																label={COMM.capitalizeFirstLetter(
																	suppItem.status
																)}
															/>
														</Material.TableCell>
														<Material.TableCell component="th" scope="row">
															{" "}
															{suppItem.ppmp_no}{" "}
														</Material.TableCell>
														<Material.TableCell align="right">
															{" "}
															{suppItem.year}
														</Material.TableCell>
														<Material.TableCell align="right">
															{" "}
															{suppItem.ppmp_title}
														</Material.TableCell>
														<Material.TableCell align="right">
															{" "}
															{suppItem.division_name}
														</Material.TableCell>
														<Material.TableCell align="right">
															{" "}
															{suppItem.employee}
														</Material.TableCell>
														<Material.TableCell align="right">
															{" "}
															{COMM.DdMmmYyyyHhMm(suppItem.transdate)}{" "}
														</Material.TableCell>
													</Material.TableRow>
												);
											})}
										</Material.TableBody>
									</Material.Table>
								</Material.Box>
							</Material.Collapse>
						</Material.TableCell>
					</Material.TableRow>
				) : (
					<Material.TableRow />
				)}
			</React.Fragment>
		));
	}
	render() {
		const {
			searchValue,
			limit,
			page_no,
			sort_column,
			sort_type,
			ppmpToCancel,
			showPassword,
			usernameError,
			passwordError,
			suppPpmpToCancel,
		} = this.state;
		const { classes, ppmp } = this.props;
		const TableHeaderOp = [
			{
				id: "Status",
				numeric: false,
				disablePadding: false,
				label: "Status",
				align: "left",
				field: "status",
			},
			{
				id: "PPMPNo",
				numeric: false,
				disablePadding: false,
				label: "PPMP No.",
				field: "ppmp_no",
			},
			{
				id: "Year",
				numeric: false,
				disablePadding: false,
				label: "Year",
				align: "center",
				field: "year",
			},
			{
				id: "Title",
				numeric: false,
				disablePadding: false,
				label: "Title",
				align: "center",
				field: "title",
			},
			{
				id: "Division",
				numeric: false,
				disablePadding: false,
				label: "Division",
				align: "left",
				field: "division_name",
			},
			{
				id: "Created by",
				numeric: false,
				disablePadding: false,
				label: "Created by",
				align: "left",
				field: "employee",
			},
			{
				id: "Date",
				numeric: false,
				disablePadding: false,
				label: "Date",
				align: "center",
				field: "transdate",
			},
			{
				id: "Revisions",
				numeric: false,
				disablePadding: false,
				label: "No. of Revisions",
				align: "center",
				field: "revision_count",
			},
		];
		return (
			<React.Fragment>
				<div className={classes.root}>
					{/* <Material.Paper elevation={3} className="container"> */}
					<Material.Paper className={classes.paper} elevation={1}>
						<Material.Toolbar className={classes.toolbar}>
							<div className={classes.toolRight}>
								<Material.Button
									variant="contained"
									color="primary"
									startIcon={<AddIcon />}
									onClick={() => {
										this.onDisplayDialog(true, this.props.data.user);
									}}
								>
									Create PPMP
								</Material.Button>
							</div>
							<form noValidate autoComplete="off">
								<Material.Tooltip title="Search">
									<Material.TextField
										id="outlined-secondary"
										name="searchValue"
										value={searchValue}
										label="Search"
										onChange={this.onTextChangeHandler}
										variant="outlined"
										color="primary"
									/>
								</Material.Tooltip>
								<Material.Tooltip title="Search">
									<Material.IconButton
										type="submit"
										className="animate"
										aria-label="search"
										onClick={this.onSearchHandler}
									>
										<SearchIcon />
									</Material.IconButton>
								</Material.Tooltip>
							</form>
						</Material.Toolbar>
						{/* </Material.Paper>
                        <Material.Paper elevation={24}> */}
						<div className={classes.chipContainer} />
						<div className={classes.tablePaper}>
							<Material.TableContainer>
								<Material.Table aria-label="ppmp-datalist">
									<Material.TableHead>
										<Material.TableRow>
											<Material.TableCell style={{ width: "30px" }} />
											<Material.TableCell>Action</Material.TableCell>
											{TableHeaderOp.map((option) => (
												<Material.TableCell
													key={option.id}
													align={option.align}
													padding={option.disablePadding ? "none" : "default"}
													sortDirection={
														sort_column === option.id ? sort_type : false
													}
												>
													<Material.TableSortLabel
														active={sort_column === option.id}
														direction={
															sort_column === option.id ? sort_type : "asc"
														}
														onClick={(event) => {
															this.handleRequestSort(event, option.field);
														}}
													>
														{option.label}
														{sort_column === option.id ? (
															<span className={classes.visuallyHidden}>
																{sort_type === "desc"
																	? "sorted descending"
																	: "sorted ascending"}
															</span>
														) : null}
													</Material.TableSortLabel>
												</Material.TableCell>
											))}
										</Material.TableRow>
									</Material.TableHead>
									<Material.TableBody>
										{this.rowRenderDisplay()}
										{ppmp.ppmpDataSource.length === 0 ? (
											<Material.TableRow>
												<Material.TableCell colSpan={20} className="emptyCont">
													<div className="empty">
														<div className="emptyBorder">
															No Procurement Plan.
															<Material.Link
																href="#"
																onClick={(e) => {
																	e.preventDefault();
																	this.onDisplayDialog(true);
																}}
															>
																{" "}
																Add now
															</Material.Link>
														</div>
													</div>
												</Material.TableCell>
											</Material.TableRow>
										) : (
											<Material.TableRow />
										)}
									</Material.TableBody>
								</Material.Table>
							</Material.TableContainer>
						</div>
						<Material.Table>
							<Material.TableFooter>
								<Material.TableRow>
									<Material.TablePagination
										rowsPerPageOptions={[
											10,
											50,
											100,
											{ label: "All", value: 10000000 },
										]}
										count={ppmp.ppmpTotal}
										rowsPerPage={limit}
										page={page_no}
										SelectProps={{
											inputProps: { "aria-label": "rows per page" },
											native: true,
										}}
										onChangePage={this.handleChangePage}
										onChangeRowsPerPage={this.handleChangeRowsPerPage}
										ActionsComponent={this.TablePaginationActions}
									/>
								</Material.TableRow>
							</Material.TableFooter>
						</Material.Table>
					</Material.Paper>
					{/* </Material.Paper> */}
					{/* Supplemental PPMP Dialog */}
					<Material.Dialog
						open={this.state.dialogSupplementalVisible}
						onClose={() => {
							this.onDisplaySupplDialog(false);
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						{this.state.supplementalPlanTarget ? (
							<React.Fragment>
								<Material.DialogTitle id="alert-dialog-title">
									Create a supplementary PPMP for{" "}
									{this.state.supplementalPlanTarget.ppmp_no}?
								</Material.DialogTitle>
								<Material.DialogContent>
									<Material.DialogContentText id="alert-dialog-description">
										This will generate a new supplemental transaction number for
										PPMP {this.state.supplementalPlanTarget.ppmp_no}. Do you
										wish to continue?
									</Material.DialogContentText>
								</Material.DialogContent>
								<div>
									<Material.TextField
										id="sup-target-year"
										label="Year"
										type="year"
										disabled
										defaultValue={this.state.supplementalPlanTarget.year}
										className={classes.textField}
									/>
									<Material.TextField
										id="sup-target-status"
										label="Status"
										type="text"
										disabled
										defaultValue={this.state.supplementalPlanTarget.status}
										className={classes.textField}
									/>
									<Material.TextField
										id="sup-target-year"
										label="Date Created"
										type="text"
										disabled
										defaultValue={this.state.supplementalPlanTarget.transdate}
										className={classes.textField}
									/>
								</div>
								<Material.DialogActions>
									<div>
										<Material.Button
											onClick={() => {
												this.onDisplaySupplDialog(false);
											}}
											color="secondary"
										>
											Cancel
										</Material.Button>
										<Material.Button
											onClick={() =>
												this.onClickAddSupplemental({
													ppmp_year: this.state.supplementalPlanTarget.year,
													parent_id: this.state.supplementalPlanTarget.id,
												})
											}
											color="primary"
											autoFocus
										>
											Continue
										</Material.Button>
									</div>
								</Material.DialogActions>
							</React.Fragment>
						) : (
							<React.Fragment>
								No PPPC Selected. Please Try again
							</React.Fragment>
						)}
					</Material.Dialog>
					{/* PPMP Dialog */}
					<Material.Dialog
						open={this.state.dialogVisible}
						onClose={() => {
							this.onDisplayDialog(false);
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<Material.DialogTitle id="alert-dialog-title">
							Create new PPMP?
						</Material.DialogTitle>
						<Material.DialogContent>
							<Material.DialogContentText id="alert-dialog-description">
								This will generate new a PPMP transaction number. Do you wish to
								continue?
							</Material.DialogContentText>
						</Material.DialogContent>
						<div className={classes.textField}>
							<Material.TextField
								id="title"
								fullWidth
								label="Title"
								type="text"
								error={this.state.errorHandler.ppmp_title_error}
								onChange={(e) => {
									this.setState({ ...this.state, ppmp_title: e.target.value });
								}}
								value={this.state.ppmp_title}
								className={classes.textField_input}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className={classes.textField}>
							<Material.TextField
								id="year"
								label="Year"
								type="number"
								error={this.state.errorHandler.year_error}
								onChange={(e) => {
									this.setState({ ...this.state, year: e.target.value }, () => {
										// console.log("$$year", this.state.year);
										this.requestPOW();
									});
								}}
								value={this.state.year}
								className={classes.textField_input}
								InputLabelProps={{ shrink: true }}
							/>
							<Material.TextField
								id="division"
								label="Division/Section/Unit"
								type="text"
								error={this.state.errorHandler.division_name_error}
								onChange={(e) => {
									this.setState({
										...this.state,
										division_name: e.target.value,
									});
								}}
								value={this.state.division_name}
								className={classes.textField_input}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className={classes.textField}>
							<Material.FormControlLabel
								control={
									<Material.Checkbox
										checked={this.state.is_indicative_checked}
										value={this.state.is_indicative_checked}
										name="is_indicative_checked"
										onChange={this.onCheckIndicativeHandlerCreate}
									/>
								}
								label="Is Indicative ?"
							/>
						</div>
						{this.state.can_create_unforeseen_ppmp === true ? (
							<div className={classes.textField}>
								<Material.FormControlLabel
									control={
										<Material.Checkbox
											checked={this.state.is_unforeseen_checked}
											value={this.state.is_unforeseen_checked}
											name="is_unforeseen_checked"
											onChange={this.onCheckUnforseenHandlerCreate}
										/>
									}
									label="Unforeseen/Emergency PPMP"
								/>
							</div>
						) : (
							""
						)}
						{this.state.is_unforeseen_checked ? (
							<div className={classes.textField}>
								<Material.FormControl fullWidth>
									<Material.InputLabel id="demo-simple-select-label">
										Select Unforeseen Grounds
									</Material.InputLabel>
									<Material.Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={this.state.selected_unforeseen_grounds}
										label="Unforeseen Grounds"
										onChange={this.onHandleUnforseenGroundsChange}
									>
										<Material.MenuItem value="Trust Fund ">
											Trust Fund
										</Material.MenuItem>
										<Material.MenuItem value="Complexity/Urgency of Needs">
											Complexity/Urgency of Needs
										</Material.MenuItem>
										<Material.MenuItem value="Others">Others</Material.MenuItem>
									</Material.Select>
								</Material.FormControl>
							</div>
						) : (
							""
						)}
						<div className={classes.textField}>
							{this.props.data.user.has_pow > 0 ? (
								<Material.FormControlLabel
									control={
										<Material.Checkbox
											checked={this.state.is_pow}
											value={this.state.is_pow}
											name="is_pow"
											onChange={this.onCheckChangeHandler}
										/>
									}
									label="Is Infra ?"
								/>
							) : (
								""
							)}
							{this.state.is_pow ? (
								// console.log("$$ispow", this.state.is_pow),
								<Material.FormControl fullWidth>
									<Material.InputLabel>Program of Works</Material.InputLabel>
									<Material.Select
										value={this.state.pow_id}
										name="pow_id"
										label="Program of Works"
										error={this.state.errorHandler.pow_error}
										onChange={this.onTextChangeHandler}
									>
										{this.state.pows.map((row) => (
											<Material.MenuItem value={row.id}>
												{row.title}
											</Material.MenuItem>
										))}
									</Material.Select>
								</Material.FormControl>
							) : (
								""
							)}
						</div>
						<div>
							<Material.Grid
								container
								// spacing={2}
								className={classes.textField}
							>
								<Material.Grid item xs={3}>
									<Material.Collapse in={Boolean(this.state.pow_id)}>
										<div className="inline">
											<Material.Typography color="primary" variant="subtitle1">
												Year:
											</Material.Typography>
											{this.state.pow_year}
											<Material.Typography
												className="wrap"
												variant="subtitle1"
											></Material.Typography>
										</div>
										<div className="inline">
											<Material.Typography color="primary" variant="subtitle1">
												Dimension:
											</Material.Typography>
											{this.state.pow_dimension}
											<Material.Typography
												className="wrap"
												variant="subtitle1"
											></Material.Typography>
										</div>
										<div className="inline">
											<Material.Typography color="primary" variant="subtitle1">
												Area:
											</Material.Typography>
											{this.state.pow_area}
											<Material.Typography
												className="wrap"
												variant="subtitle1"
											></Material.Typography>
										</div>
										<div className="inline">
											<Material.Typography color="primary" variant="subtitle1">
												Classification:
											</Material.Typography>
											{this.state.pow_classification}
											<Material.Typography
												className="wrap"
												variant="subtitle1"
											></Material.Typography>
										</div>
										<div className="inline">
											<Material.Typography color="primary" variant="subtitle1">
												Location:
											</Material.Typography>
											{this.state.pow_location}
											<Material.Typography
												className="wrap"
												variant="subtitle1"
											></Material.Typography>
										</div>
									</Material.Collapse>
								</Material.Grid>
								<Material.Grid item xs={9}>
									<Material.Collapse in={Boolean(this.state.pow_id)}>
										<div className="inline">
											<Material.Typography color="primary" variant="subtitle1">
												Project Cost:
											</Material.Typography>
											{this.state.pow_project_cost}
											<Material.Typography
												className="wrap"
												variant="subtitle1"
											></Material.Typography>
										</div>
										<div className="inline">
											<Material.Typography color="primary" variant="subtitle1">
												Fund Source:
											</Material.Typography>
											{this.state.pow_fund_source}
											<Material.Typography
												className="wrap"
												variant="subtitle1"
											></Material.Typography>
										</div>
										<div className="inline">
											<Material.Typography color="primary" variant="subtitle1">
												Project Duration:
											</Material.Typography>
											{this.state.pow_project_duration}
											<Material.Typography
												className="wrap"
												variant="subtitle1"
											></Material.Typography>
										</div>
									</Material.Collapse>
								</Material.Grid>
							</Material.Grid>
						</div>
						<Material.DialogActions>
							<div>
								<Material.Button
									onClick={this.onCancelPPMPManual}
									color="secondary"
								>
									Cancel
								</Material.Button>
								<Material.Button
									onClick={this.onClickAddTransactionButton}
									color="primary"
									autoFocus
								>
									Continue
								</Material.Button>
							</div>
						</Material.DialogActions>
					</Material.Dialog>
					{/* Cancel PPMP Dialog */}
					<Material.Dialog
						open={this.state.ppmpCancelDialogVisible}
						onClose={() => {
							this.onDisplayDialog(false);
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<Material.DialogTitle id="alert-dialog-title">
							Cancel PPMP No. {ppmpToCancel.ppmp_no}?
						</Material.DialogTitle>
						<Material.DialogContent>
							<Material.DialogContentText id="alert-dialog-description">
								Are you sure you want to cancel {ppmpToCancel.ppmp_no} (
								{ppmpToCancel.year})?
							</Material.DialogContentText>
						</Material.DialogContent>
						<Material.DialogActions>
							<div>
								<Material.Button
									onClick={() => {
										this.onCancelPPMP({}, false);
									}}
									color="secondary"
								>
									Cancel
								</Material.Button>
								<Material.Button
									onClick={this.onRequestCancelPPMP}
									color="primary"
									autoFocus
								>
									Continue
								</Material.Button>
							</div>
						</Material.DialogActions>
					</Material.Dialog>

					{/* For cancel supplemental PPMP */}
					<Material.Dialog
						open={this.state.cancelSuppDialogVisible}
						onClose={() => {
							this.onDisplayCancelSuppDialog(false);
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<Material.DialogTitle id="alert-dialog-title">
							Cancel PPMP No. {suppPpmpToCancel.ppmp_no}?
						</Material.DialogTitle>
						<Material.DialogContent>
							<Material.DialogContentText id="alert-dialog-description">
								Are you sure you want to cancel {suppPpmpToCancel.ppmp_no} (
								{suppPpmpToCancel.year})?
							</Material.DialogContentText>
						</Material.DialogContent>
						<Material.DialogActions>
							<div>
								<Material.Button
									onClick={() => {
										this.onCancelPPMP({}, false);
									}}
									color="secondary"
								>
									Cancel
								</Material.Button>
								<Material.Button
									onClick={this.onRequestCancelPPMP}
									color="primary"
									autoFocus
								>
									Continue
								</Material.Button>
							</div>
						</Material.DialogActions>
					</Material.Dialog>
					{/* Duplicate PPMP Dialog */}
					<Material.Dialog
						open={this.state.dialogDuplicateVisible}
						onClose={() => {
							this.onDisplayDuplicateDialog(false);
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						{this.state.supplementalPlanTarget ? (
							<React.Fragment>
								<Material.DialogTitle id="alert-dialog-title">
									Duplicate PPMP for {this.state.supplementalPlanTarget.ppmp_no}
									?
								</Material.DialogTitle>
								<Material.DialogContent>
									<Material.DialogContentText id="alert-dialog-description">
										This will duplicate a copy for PPMP{" "}
										{this.state.supplementalPlanTarget.ppmp_no}. Do you wish to
										continue?
									</Material.DialogContentText>
									<Material.FormControlLabel
										control={
											<Material.Checkbox
												checked={this.state.is_indicative_checked}
												value={this.state.is_indicative_checked}
												name="is_indicative_checked"
												onChange={this.onCheckIndicativeHandlerDuplicate}
											/>
										}
										label="Is Indicative ?"
									/>
								</Material.DialogContent>
								{/* <div> */}
								<Material.TextField
									id="duplicate-target-year"
									label="Year"
									type="number"
									// defaultValue={this.state.supplementalPlanTarget.year}
									value={this.state.duplicate_year}
									className={classes.textField}
									onChange={(e) => {
										this.setState({
											...this.state,
											duplicate_year: e.target.value,
										});
									}}
								/>
								{/* </div> */}
								<Material.DialogActions>
									<div>
										<Material.Button
											onClick={() => {
												this.onDisplayDuplicateDialog(false);
											}}
											color="secondary"
										>
											Cancel
										</Material.Button>
										<Material.Button
											onClick={() =>
												this.onClickDuplicatePPMP({
													parent_id: this.state.supplementalPlanTarget.id,
													isIndicative: this.state.is_indicative,
												})
											}
											color="primary"
											autoFocus
										>
											Continue
										</Material.Button>
										{/* <Material.Button
											onClick={() => this.onDisplayVerifyDialog(true)}
											color="primary"
											autoFocus
										>
											Continue
										</Material.Button> */}
									</div>
								</Material.DialogActions>
							</React.Fragment>
						) : (
							<React.Fragment></React.Fragment>
						)}
					</Material.Dialog>

					{/* Verify Dialog */}
					<Material.Dialog
						open={this.state.dialogVerifyVisible}
						onClose={() => {
							this.onDisplayVerifyDialog(false);
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<Material.DialogContent>
							<Material.DialogContentText id="alert-dialog-description">
								Please verify your password first to proceed.
							</Material.DialogContentText>
						</Material.DialogContent>
						<form noValidate autoComplete="off">
							<div>
								<Material.TextField
									id="outlined-username-input"
									name="username"
									error={usernameError}
									label="Employee ID"
									onChange={this.onChange}
									// value={this.state.username}
									value={this.props.data.user.username}
									disabled={true}
									className={classes.textField}
									InputProps={{
										startAdornment: (
											<Material.InputAdornment position="start">
												<PersonIcon />
											</Material.InputAdornment>
										),
									}}
								/>
							</div>
							<div>
								{/* <Material.TextField id={user.user_id} name={user.user_id} className={classes.textField} disabled/> */}
								<Material.TextField
									id="outlined-password-input"
									name="password"
									error={passwordError}
									label="Password"
									onChange={this.onChange}
									value={this.state.password}
									className={classes.textField}
									type={showPassword ? "text" : "password"}
									InputProps={{
										startAdornment: (
											<Material.InputAdornment position="start">
												<LockIcon />
											</Material.InputAdornment>
										),
										endAdornment: (
											<Material.InputAdornment position="end">
												<Material.IconButton
													aria-label="toggle password visibility"
													onClick={() => {
														this.setState({
															...this.state,
															showPassword: !showPassword,
														});
													}}
													onMouseDown={(event) => {
														event.preventDefault();
													}}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</Material.IconButton>
											</Material.InputAdornment>
										),
									}}
								/>
							</div>
							<div className={classes.textField}>
								<Material.FormControlLabel
									label="Document Generator System User"
									control={
										<Material.Checkbox
											checked={this.state.type}
											onChange={this.handleChange}
											name="type"
											color="primary"
										/>
									}
								/>
							</div>
							<Material.DialogActions>
								<div>
									<Material.Button
										onClick={() => {
											this.onDisplayVerifyDialog(false);
										}}
										color="secondary"
									>
										Cancel
									</Material.Button>
									<Material.Button
										type="submit"
										onClick={this.onSubmit}
										color="primary"
										autoFocus
									>
										Continue
									</Material.Button>
								</div>
							</Material.DialogActions>
						</form>
					</Material.Dialog>
					{/* <VerifyPasswordModal dialogVerifyVisible={this.state.dialogVerifyVisible} onCloseModal={this.onDisplayVerifyDialog(false)} expectItemReturn onCloseRender={this.openSuppliesModal}/> */}
					{/* File Not Found Dialog */}
					<Material.Dialog
						open={this.state.dialogOpen}
						onClose={this.handleCloseDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<Material.DialogTitle
							id="alert-dialog-title"
							style={{ color: "#ff9100" }}
						>
							Warning!!!
						</Material.DialogTitle>
						<Material.DialogContent>
							<Material.DialogContentText id="alert-dialog-description">
								There is no uploaded file in this PPMP (Program of Work).
							</Material.DialogContentText>
						</Material.DialogContent>
						<Material.DialogActions>
							<div>
								<Material.Button
									onClick={this.handleCloseDialog}
									color="secondary"
								>
									Close
								</Material.Button>
							</div>
						</Material.DialogActions>
					</Material.Dialog>
				</div>
			</React.Fragment>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Material.withStyles(useStyles)(PPMP));
