import {
  BreakfastDiningOutlined,
  KeyboardReturnTwoTone,
  RestartAlt,
} from "@mui/icons-material";
import { API, dgAPI } from "../../helpers/axios.instance";

export const generateTransaction = (user) => {
  let data = new FormData();
  data.append("username", user.username);
  data.append("password", user.password);
  return API()
    .post("/Login/login", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { error: true, message: `${err}` };
    });
};

export const getPPMPTransaction = (ppmp_input) => {
  let data = new FormData();
  data.append("year", ppmp_input.ppmp_year);
  data.append("office_id", ppmp_input.ppmp_office_id);
  data.append("status", ppmp_input.ppmp_status);
  return API()
    .post("/Ppmp/GetAll/index_post", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getItemList = (dataInput) => {
  let data = new FormData();
  data.append("keyword", dataInput.keyword);
  data.append("page_no", dataInput.page_no);
  data.append("limit", dataInput.limit);
  data.append("sort_column", "name");
  data.append("sort_type", "ASC");
  data.append("filter_category_name", dataInput.filter_category_name);
  data.append("filter_isactive", "Y");
  // console.log('$$dataInput', dataInput);
  return API()
    .post("/items/get", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getActiveItems = (ppmpcart_input) => {
  let data = new FormData();
  data.append("is_active", ppmpcart_input.is_active);
  return API()
    .post("Items/SelectAll/index_post")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getAllItems = () => {
  return API()
    .get("Items/GetAllItems/index_get")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const createPPMPTransaction = (value) => {
  // console.log("$$value", value);
  let data = new FormData();
  data.append("user_id", value.user_id);
  data.append("office_id", value.office_id);
  data.append("division_name", value.division_name);
  data.append("ppmp_title", value.ppmp_title);
  data.append("year", value.year);
  data.append("pow_id", value.pow_id);
  data.append("is_indicative", value.is_indicative);
  data.append("is_unforeseen", value.is_unforeseen);
  data.append("is_unforeseen_grounds", value.is_unforeseen_grounds);
  return API()
    .post("/Ppmp/Insert/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const editPPMPTransaction = (value) => {
  let data = new FormData();
  data.append("ppmp_id", value.ppmp_id);
  data.append("division_name", value.division_name);
  data.append("ppmp_title", value.ppmp_title);
  data.append("is_indicative", value.is_indicative);
  return API()
    .post("/Ppmp/EditPPMP/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const createSupplementalTransaction = (value) => {
  let data = new FormData();
  data.append("user_id", value.user_id);
  data.append("office_id", value.office_id);
  data.append("year", value.year);
  data.append("parent_id", value.parent);
  return API()
    .post("/Ppmp/InsertSupplemental/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getPPMP = (tableProp) => {
  let data = new FormData();
  data.append("keyword", tableProp.keyword); //searches
  data.append("page_no", tableProp.page_no); //required
  data.append("limit", tableProp.limit); //required
  data.append("sort_column", tableProp.sort_column);
  data.append("sort_type", tableProp.sort_type);
  data.append("filter_year", tableProp.filter_year);
  data.append("filter_office_id", tableProp.filter_office_id);
  data.append("filter_status", tableProp.filter_status);
  // console.log('$$tableProp', tableProp);
  return API()
    .post("/Ppmp/Get/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getAccountCodePerRespo = (value) => {
  let data = new FormData();
  data.append("_respo_code", value.respo_code);
  data.append("_year", value.year);
  data.append("_ppmp_id", value.ppmp_id);
  return dgAPI()
    .post(
      "transactionAccounts/GetLegalBasisByYear/view_legalbasis_by_year/",
      data
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getRAyear = (value) => {
  let data = new FormData();
  data.append("_respo_code", value.respo_code);
  data.append("_ppmp_id", value.ppmp_id);
  return dgAPI()
    .post(
      "transactionAccounts/GetRAYearByLegalBasis/view_rayear_by_legalbasis",
      data
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getSourceOfFundData = (value) => {

  let data = new FormData();
  data.append("_sp_used", value.sp_used);
  // data.append("_respo_code", value.respo_code);
  data.append("_respo_code", value.respoCodeValue.respo_code);
  data.append("_ra_desc", value.RA_desc);
  data.append("_ra_year", value.RA_year);
  data.append("_fund_type", value.fundType);
  // data.append("_ppa_code", value.PPA_code);
  data.append("_ppa_code", value.respoCodeValue.ppa_code);
  data.append("_ppmp_id", value.ppmp_id);
  return dgAPI()
    .post("transactionAccounts/GetAccounts/view_accounts/", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

// backuop 0724024
export const getRespoCenterData = () => {
  return dgAPI()
    .get("transactionAccounts/GetRespoFromEngas/view_respo_center/")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getRespoCenterData2 = (ppmp_id) => {
  // console.log('$$ppmp_id', ppmp_id);
  if (ppmp_id) {
    let data = new FormData();
    data.append("_ppmp_id", ppmp_id);
    return dgAPI()
      .post("transactionAccounts/GetRespoFromEngas2/index_post", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error('$$error', err);
      });
  } else {
    return { error: true, data: [] }
  }
};

export const getGeneratedPPMP = (ppmp_items) => {
  let data = new FormData();
  data.append("ppmp_id", ppmp_items.ppmp_id);
  return API()
    .post("/PpmpItems/GetAllPerPPMP", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

let errorFundsMessage = "";
let fundStatus = [];
const fundsPassedSuccessfully = async (fund_rows, itemAmount, ppmp_items) => {
  fundStatus = [];
  // get total amount allocated & compare to item cost
  let totalAmount = 0;
  let someFundsHaveZeroValue = false;
  for (let i = 0; i < fund_rows.length; i++) {
    let allotedAmount = parseFloat(fund_rows[i].fundSourceAllocatedAmount);
    totalAmount += allotedAmount;
    if (allotedAmount === 0) {
      someFundsHaveZeroValue = true;
      break;
    }
  }
  if (!someFundsHaveZeroValue) {
    if (parseFloat(itemAmount) === totalAmount) {
      let fundOKCount = 0;
      let apiPromises = [];
      for (let i = 0; i < fund_rows.length; i++) {
        let allotedAmount = parseFloat(fund_rows[i].fundSourceAllocatedAmount);
        let dataToPass = new FormData();
        dataToPass.append("ppmp_id", ppmp_items.ppmp_id);
        dataToPass.append(
          "allotment_class",
          fund_rows[i].fundSource.allot_type
        );
        dataToPass.append("account_code", fund_rows[i].fundSource.account_code);
        dataToPass.append(
          "account_code_description",
          fund_rows[i].fundSource.account_particulars
        );
        dataToPass.append("respo_code", fund_rows[i].fundSource.respo_code);
        dataToPass.append(
          "respo_code_description",
          fund_rows[i].fundSource.respo_desc
        );
        dataToPass.append("ppa_code", fund_rows[i].fundSource.ppa_code);
        dataToPass.append("ppa_desc", fund_rows[i].fundSource.ppa_desc);
        dataToPass.append("year", fund_rows[i].fundSource.ra_year);
        dataToPass.append("legal_basis", fund_rows[i].fundSource.ra_desc);
        dataToPass.append("fund_type", fund_rows[i].fundSource.fund_type);
        dataToPass.append(
          "respo_description",
          fund_rows[i].fundSource.respo_desc2
        );
        dataToPass.append("respo_type", fund_rows[i].fundSource.respo_type);
        dataToPass.append("item_id", ppmp_items.item_id);
        dataToPass.append("unit", ppmp_items.unit);
        dataToPass.append("qty", ppmp_items.qty);
        dataToPass.append("unit_cost", ppmp_items._latest_price);
        dataToPass.append("total_cost", ppmp_items.total_cost);
        dataToPass.append("remark", "");
        dataToPass.append("qty_jan", ppmp_items.jan_qty);
        dataToPass.append("qty_feb", ppmp_items.feb_qty);
        dataToPass.append("qty_mar", ppmp_items.mar_qty);
        dataToPass.append("qty_apr", ppmp_items.apr_qty);
        dataToPass.append("qty_may", ppmp_items.may_qty);
        dataToPass.append("qty_jun", ppmp_items.jun_qty);
        dataToPass.append("qty_jul", ppmp_items.jul_qty);
        dataToPass.append("qty_aug", ppmp_items.aug_qty);
        dataToPass.append("qty_sep", ppmp_items.sep_qty);
        dataToPass.append("qty_oct", ppmp_items.oct_qty);
        dataToPass.append("qty_nov", ppmp_items.nov_qty);
        dataToPass.append("qty_dec", ppmp_items.dec_qty);
        dataToPass.append("id", ppmp_items.id);
        dataToPass.append("mode_of_procurement", ppmp_items.mode);
        dataToPass.append("procurement_mode_id", ppmp_items.mode_id);
        dataToPass.append("is_non_office", ppmp_items.IsNonOffice ? "Y" : "N");
        dataToPass.append(
          "catering_office_activity_id",
          ppmp_items.selected_activity_id
        );
        dataToPass.append("prefered_desc", ppmp_items.prefered_description);
        dataToPass.append("user_id", ppmp_items.user_id);
        dataToPass.append("user_name", ppmp_items.user_name);
        dataToPass.append("alloted_cost", allotedAmount);
        dataToPass.append("jan_for_edit", ppmp_items.jan_for_edit);
        dataToPass.append("feb_for_edit", ppmp_items.feb_for_edit);
        dataToPass.append("mar_for_edit", ppmp_items.mar_for_edit);
        dataToPass.append("apr_for_edit", ppmp_items.apr_for_edit);
        dataToPass.append("may_for_edit", ppmp_items.may_for_edit);
        dataToPass.append("jun_for_edit", ppmp_items.jun_for_edit);
        dataToPass.append("jul_for_edit", ppmp_items.jul_for_edit);
        dataToPass.append("aug_for_edit", ppmp_items.aug_for_edit);
        dataToPass.append("sep_for_edit", ppmp_items.sep_for_edit);
        dataToPass.append("oct_for_edit", ppmp_items.oct_for_edit);
        dataToPass.append("nov_for_edit", ppmp_items.nov_for_edit);
        dataToPass.append("dec_for_edit", ppmp_items.dec_for_edit);
        dataToPass.append("jan_price", ppmp_items.jan_price);
        dataToPass.append("feb_price", ppmp_items.feb_price);
        dataToPass.append("mar_price", ppmp_items.mar_price);
        dataToPass.append("apr_price", ppmp_items.apr_price);
        dataToPass.append("may_price", ppmp_items.may_price);
        dataToPass.append("jun_price", ppmp_items.jun_price);
        dataToPass.append("jul_price", ppmp_items.jul_price);
        dataToPass.append("aug_price", ppmp_items.aug_price);
        dataToPass.append("sep_price", ppmp_items.sep_price);
        dataToPass.append("oct_price", ppmp_items.oct_price);
        dataToPass.append("nov_price", ppmp_items.nov_price);
        dataToPass.append("dec_price", ppmp_items.dec_price);
        dataToPass.append("for_edit", ppmp_items.for_edit);
        dataToPass.append("for_update_price", ppmp_items.for_update_price);

        let apiPromise = API()
          .post("/PpmpItems/InsertCheckFunds/index_post", dataToPass)
          .then((response) => {
            let resStatus = response?.data?.data[0]._ret;
            let status = "",
              type = "";
            if (resStatus === "ok") {
              status = "OK";
              type = "ok";
              fundOKCount++;
            } else if (resStatus === "50k_above_capital_outlay") {
              status = "Capital Outlay funds must be 50,000";
              type = "error";
            } else if (resStatus === "budget_exceed") {
              status = "Budget exceed!";
              type = "error";
            }
            let fundsToDisp = {
              respo_code: fund_rows[i].fundSource.respo_code,
              respo_code_description: fund_rows[i].fundSource.respo_desc,
              ra_desc: fund_rows[i].fundSource.ra_desc,
              account_code_description:
                fund_rows[i].fundSource.account_particulars,
              alloted_amount: allotedAmount,
              status,
              type,
            };
            fundStatus.push(fundsToDisp);
          })
          .catch((err) => {
            console.error("$$error fund checker", err);
          });
        apiPromises.push(apiPromise);
      }

      await Promise.all(apiPromises);
      //check if has error
      return fundOKCount === fund_rows.length ? true : false;
    } else {
      errorFundsMessage = `Fund allocated [${totalAmount}] not equal to item cost[${itemAmount}]!`;
    }
  } else {
    errorFundsMessage = `Fund allocated must be greater than zero!`;
  }
  return false;
};

const saveFunds = async (ppmp_items_id, fund_rows, ppmp_items) => {
  let apiPromises = [];
  for (let i = 0; i < fund_rows.length; i++) {
    // console.log("$$loop_save_funds", fund_rows[i]);
    let allotedAmount = parseFloat(fund_rows[i].fundSourceAllocatedAmount);
    // console.log('$$Service saveFunds:', allotedAmount);
    let dataToPass = new FormData();
    dataToPass.append("id", ppmp_items_id);
    dataToPass.append("allotment_class", fund_rows[i].fundSource.allot_type);
    dataToPass.append("account_code", fund_rows[i].fundSource.account_code);
    dataToPass.append(
      "account_code_description",
      fund_rows[i].fundSource.account_particulars
    );
    dataToPass.append("respo_code", fund_rows[i].fundSource.respo_code);
    dataToPass.append(
      "respo_code_description",
      fund_rows[i].fundSource.respo_desc
    );
    dataToPass.append("year", fund_rows[i].fundSource.ra_year);
    dataToPass.append("legal_basis", fund_rows[i].fundSource.ra_desc);
    dataToPass.append("ppa_desc", fund_rows[i].fundSource.ppa_desc);
    dataToPass.append("ppa_code", fund_rows[i].fundSource.ppa_code);
    dataToPass.append("fund_type", fund_rows[i].fundSource.fund_type);
    dataToPass.append("respo_type", fund_rows[i].fundSource.respo_type);
    dataToPass.append("respo_description", fund_rows[i].fundSource.respo_desc2);
    dataToPass.append("alloted_cost", allotedAmount);
    dataToPass.append("is_non_office", ppmp_items.IsNonOffice ? "Y" : "N");

    let apiPromise = API()
      .post("/PpmpItems/InsertFunds/index_post", dataToPass)
      .then((response) => {
        // console.log("$$ppmp_item_funds_saved", response);
        return response;
      })
      .catch((err) => {
        console.error("$$error", err);
      });
    apiPromises.push(apiPromise);
  }
  await Promise.all(apiPromises);
  return;
};

export const updateItem = async (ppmp_items) => {
  // console.log("$$ppmp_items", ppmp_items);
  // check funds:
  const fundsPassed = await fundsPassedSuccessfully(
    ppmp_items.rows,
    ppmp_items.total_cost,
    ppmp_items
  );
  if (!fundsPassed) {
    return new Promise((resolve, reject) => {
      // Simulating an asynchronous operation with a timeout
      setTimeout(() => {
        resolve({ error: true, errmsg: errorFundsMessage, fundStatus });
      }, 100); // Simulating a delay of .1 second
    });
  } else {
    let data = new FormData();
    // console.log("$$dataService", data);
    data.append("ppmp_id", ppmp_items.ppmp_id);
    data.append("allotment_class", ppmp_items.allotment_class);
    data.append("account_code", ppmp_items.account_code);
    data.append(
      "account_code_description",
      ppmp_items.account_code_description
    );
    data.append("respo_code", ppmp_items.respo_code);
    data.append("respo_code_description", ppmp_items.respo_code_description);
    data.append("ppa_code", ppmp_items.ppa_code);
    data.append("ppa_desc", ppmp_items.ppa_desc);
    data.append("year", ppmp_items.year);
    data.append("legal_basis", ppmp_items.legal_basis);
    data.append("fund_type", ppmp_items.fundType);
    data.append("respo_description", ppmp_items.respo_desc2);
    data.append("respo_type", ppmp_items.respo_type);
    data.append("item_id", ppmp_items.item_id);
    data.append("unit", ppmp_items.unit);
    data.append("qty", ppmp_items.qty);
    data.append("unit_cost", ppmp_items._latest_price);
    data.append("total_cost", ppmp_items.total_cost);
    data.append("remark", "");
    data.append("qty_jan", ppmp_items.jan_qty);
    data.append("qty_feb", ppmp_items.feb_qty);
    data.append("qty_mar", ppmp_items.mar_qty);
    data.append("qty_apr", ppmp_items.apr_qty);
    data.append("qty_may", ppmp_items.may_qty);
    data.append("qty_jun", ppmp_items.jun_qty);
    data.append("qty_jul", ppmp_items.jul_qty);
    data.append("qty_aug", ppmp_items.aug_qty);
    data.append("qty_sep", ppmp_items.sep_qty);
    data.append("qty_oct", ppmp_items.oct_qty);
    data.append("qty_nov", ppmp_items.nov_qty);
    data.append("qty_dec", ppmp_items.dec_qty);
    data.append("id", ppmp_items.id);
    data.append("mode_of_procurement", ppmp_items.mode);
    data.append("procurement_mode_id", ppmp_items.mode_id);
    data.append("is_non_office", ppmp_items.IsNonOffice ? "Y" : "N");
    data.append("catering_office_activity_id", ppmp_items.selected_activity_id);
    data.append("prefered_desc", ppmp_items.prefered_description);
    data.append("user_id", ppmp_items.user_id);
    data.append("user_name", ppmp_items.user_name);
    data.append("jan_for_edit", ppmp_items.jan_for_edit);
    data.append("feb_for_edit", ppmp_items.feb_for_edit);
    data.append("mar_for_edit", ppmp_items.mar_for_edit);
    data.append("apr_for_edit", ppmp_items.apr_for_edit);
    data.append("may_for_edit", ppmp_items.may_for_edit);
    data.append("jun_for_edit", ppmp_items.jun_for_edit);
    data.append("jul_for_edit", ppmp_items.jul_for_edit);
    data.append("aug_for_edit", ppmp_items.aug_for_edit);
    data.append("sep_for_edit", ppmp_items.sep_for_edit);
    data.append("oct_for_edit", ppmp_items.oct_for_edit);
    data.append("nov_for_edit", ppmp_items.nov_for_edit);
    data.append("dec_for_edit", ppmp_items.dec_for_edit);
    data.append("jan_price", ppmp_items.jan_price);
    data.append("feb_price", ppmp_items.feb_price);
    data.append("mar_price", ppmp_items.mar_price);
    data.append("apr_price", ppmp_items.apr_price);
    data.append("may_price", ppmp_items.may_price);
    data.append("jun_price", ppmp_items.jun_price);
    data.append("jul_price", ppmp_items.jul_price);
    data.append("aug_price", ppmp_items.aug_price);
    data.append("sep_price", ppmp_items.sep_price);
    data.append("oct_price", ppmp_items.oct_price);
    data.append("nov_price", ppmp_items.nov_price);
    data.append("dec_price", ppmp_items.dec_price);
    data.append("for_edit", ppmp_items.for_edit);
    data.append("for_update_price", ppmp_items.for_update_price);
    try {
      const response = await API().post("/PpmpItems/Insert/index_post", data);
      // save funds
      await saveFunds(
        parseInt(response.data.data[0]?.ppmp_items_id),
        ppmp_items.rows,
        ppmp_items
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
};

export const editQtyPerMonth = (ppmp_items) => {
  let data = new FormData();
  data.append("qty", ppmp_items.Qty);
  data.append("total_cost", ppmp_items.totalCost);
  data.append("qty_jan", ppmp_items.qtyJan);
  data.append("qty_feb", ppmp_items.qtyFeb);
  data.append("qty_mar", ppmp_items.qtyMar);
  data.append("qty_apr", ppmp_items.qtyApr);
  data.append("qty_may", ppmp_items.qtyMay);
  data.append("qty_jun", ppmp_items.qtyJun);
  data.append("qty_jul", ppmp_items.qtyJul);
  data.append("qty_aug", ppmp_items.qtyAug);
  data.append("qty_sep", ppmp_items.qtySep);
  data.append("qty_oct", ppmp_items.qtyOct);
  data.append("qty_nov", ppmp_items.qtyNov);
  data.append("qty_dec", ppmp_items.qtyDec);
  data.append("id", ppmp_items.id);
  return API()
    .post("/PpmpItems/EditQtyPerMonth/index_post", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const deletePpmpItem = (ppmp_items) => {
  let data = new FormData();
  data.append("id", ppmp_items.ppmp_item_id);
  return API()
    .post("/PpmpItems/Delete/index_post", data)
    .then((response) => {
      // console.log('$$res101', response);
      let isError = response.data.error;
      // console.log('$$isError', isError);
      let msg = !isError ? "Success!" : "Item in used!";
      // console.log('$$msg', msg);
      return {
        error: isError,
        msg,
      };
    })
    .catch((err) => {
      console.error(err);
    });
};

export const searchRecommendation = (value) => {
  let data = new FormData();
  data.append("keyword", value);
  data.append("limit", 10);
  return API()
    .post("/Items/GetSearch", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getTotalBudget = (ppmp_items) => {
  let data = new FormData();
  data.append("ppmp_id", ppmp_items.ppmp_id);
  return API()
    .post("/PpmpItems/GetTotalBudget/index_post", data)
    .then((response) => {
      return response.data.result;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getOfficeSignatories = (value) => {
  let data = new FormData();
  data.append("office_id", value.office_id);
  return dgAPI()
    .post("/Signatories/GetDocSignatories/index_post", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updateSignatories = (ppmp) => {
  let data = new FormData();
  data.append("id", ppmp.ppmp_id);
  data.append("prepared_signatory_name", ppmp.admin_fullname);
  data.append("prepared_signatory_pos", ppmp.admin_designation);
  data.append("head_signatory_name", ppmp.head_fullname);
  data.append("head_signatory_pos", ppmp.head_designation);
  data.append("reviewed_signatory_name", ppmp.budget_fullname);
  data.append("reviewed_signatory_pos", ppmp.budget_designation);
  data.append("plus_10per_inflation", ppmp.plus_10per_inflation ? 1 : 0);
  data.append("plus_10per_contingency", ppmp.plus_10per_contingency ? 1 : 0);
  return API()
    .post("/Ppmp/UpdatePPMPsignatories/index_post", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getPPMPsignatories = (ppmp) => {
  let data = new FormData();
  data.append("id", ppmp.ppmp_id);
  return API()
    .post("/Ppmp/GetPPMPsignatories/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getRequestedItems = (value) => {
  let data = new FormData();
  data.append("filter_office_id", value.office_id);
  data.append("page_no", value.page_no);
  data.append("limit", value.limit);
  return API()
    .post("/Items/GetForApproval/index_post", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updatePpmpStatus = (ppmp) => {
  let data = new FormData();
  data.append("status", ppmp.status);
  data.append("id", ppmp.ppmp_id);
  return API()
    .post("/Ppmp/ChangeStatus/index_post", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getOfficeName = (value) => {
  let data = new FormData();
  data.append("office_id", value.office_id);
  return API()
    .post("/Ppmp/GetOfficeName/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const duplicatePPMP = (value) => {
  let data = new FormData();
  data.append("ppmp_id", value.ppmp_id);
  data.append("user_id", value.user_id);
  data.append("year", value.year);
  data.append("is_indicative", value.is_indicative);
  return API()
    .post("/Ppmp/DuplicatePPMP/index_post", data)
    .then((response) => {
      // console.log(response, "$$response");
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const login = (value) => {
  // let data = new FormData();
  // console.log("service_here!");
  let data = new URLSearchParams();
  // console.log(value);
  data.append("username", value.username);
  data.append("password", value.password);
  data.append("type", value.proc);
  // console.log(data);

  // console.log(API());
  return API()
    .post("/Login/login", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { error: true, message: `${err}` };
    });
};

export const trackingGet = (value) => {
  let data = new FormData();
  data.append("_app_quarterly_id", value.app_quarterly_id);
  data.append("_ppmp_items_id", value.ppmp_items_id);
  data.append("_quarter", value.quarter);
  return API()
    .post("/PpmpItems/Tracking/index_post", data)
    .then((response) => {
      return response.data.result;
    })
    .catch((err) => {
      console.error(err);
    });
};

// Service for tracking app status
// export const getAPPStatus = () => {
//     return API().get('/PPMP/GetAppStatus/index_post').then((response) => {
//         return response;
//     }).catch((err) => {
//         console.error(err);
//       });
//   };

export const getActivePOW = (tableProp) => {
  // console.log("$$tableProp", tableProp);
  let data = new FormData();
  data.append("office_id", tableProp.office_id); //searches
  data.append("year", parseInt(tableProp.year)); //required
  return API()
    .post("/ProgramOfWorks/GetActive/index_post", data)
    .then((response) => {
      // console.log("$$data_res", response);
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDetails = (tableProp) => {
  // console.log('$$tableProp', tableProp);
  let data = new FormData();
  data.append("id", tableProp.id); //searches
  return API()
    .post("/Ppmp/GetDetails/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const savePPMPWithPOW = async (val) => {
  // validations
  let itemAmount = 0;
  let formattedNumber = val.powProjectCost;
  // Remove the comma and .00
  let cleanedNumber = formattedNumber.replace(/,/g, "").replace(/\.00$/, "");

  const fundsPassed = await fundsPassedSuccessfully(
    val.rows,
    (itemAmount = parseFloat(cleanedNumber)),
    val
  );
  if (!fundsPassed) {
    return new Promise((resolve, reject) => {
      // Simulating an asynchronous operation with a timeout
      setTimeout(() => {
        resolve({ error: true, errmsg: errorFundsMessage, fundStatus });
      }, 100); // Simulating a delay of .1 second
    });
  } else {
    // saving
    let data = new FormData();
    data.append("ppmp_id", val.ppmp_id);
    data.append("allotment_class", val.allot_type);
    data.append("account_code", val.account_code);
    data.append("account_code_description", val.account_particulars);
    data.append("respo_code", val.respo_code);
    data.append("respo_code_description", val.respo_desc);
    data.append("respo_description", val.respo_desc2);
    data.append("respo_type", val.respo_type);
    data.append("year", val.RA_year);
    data.append("legal_basis", val.RA_desc);
    data.append("fund_type", val.fundType);
    data.append("ppa_code", val.ppa_code);
    data.append("ppa_desc", val.ppa_desc);
    // data.append("alloted_cost", val.allocated_amount);
    data.append("is_non_office", val.IsNonOffice ? "Y" : "N");
    data.append("target_date", val.date_selected);
    data.append("base64", val.base64);
    data.append("user_id", val.user_id);

    try {
      const response = await API().post(
        "/Ppmp/CreatePPMPWithPOW/index_post",
        data
      );
      // console.log("$$rep", response);
      await saveFunds(
        parseInt(response.data.data[0]?.ppmp_items_id),
        val.rows,
        val
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
    // return API()
    //   .post("/Ppmp/CreatePPMPWithPOW/index_post", data)
    //   .then((response) => {
    //     console.log("$$response101", response);
    //     return response;
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  }
};

export const getOfficeNotification = (value) => {
  let data = new FormData();
  data.append("office_id", value.office_id);

  return API()
    .post("/OfficeNotification/Get/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const officeNotificationSetRecieved = (value) => {
  let data = new FormData();
  data.append("id", value.user_value.message_id);
  data.append("received_by", value.user_value.user_id);
  data.append("received_on", value.user_value.currentDateTime);

  return API()
    .post("/OfficeNotification/SetStatus/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPendingPPMP = (value) => {
  let data = new FormData();
  data.append("office_id", value.office_id);
  
  return API()
    .post("/Ppmp/GetForRevisionPPMP/index_post", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};
