export default (state = {
    twgSignatoriesDatasource: [],
    twgTotal: 0,
    twgOffices: [],
    twgFilter: [],
}, action) => {
    switch (action.type){
        case "SETTWGDATASOURCE":
            return state = {
                ...state,
                twgSignatoriesDatasource: action.payload.twgSignatoriesDatasource,
				twgTotal: action.payload.twgTotal,
				twgFilter: action.payload.twgFilter,
            }
        case "SETTWGOFFICES":
            return state = {
                ...state,
                twgOffices: action.payload.twgOffices,
            }
        case "DROPTWGSIGNATORIESSTATE":
            return state = {
                twgSignatoriesDatasource: [],
                twgTotal: 0,
                twgOffices: [],
                twgFilter: [],
            }
        default:
            return state;
        
    }
};
