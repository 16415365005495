import { API } from '../../../helpers/axios.instance';

export const getPRModes = (tableProp) => {
    let data = new FormData();
    data.append('keyword', tableProp.keyword); //searches
    data.append('page_no', tableProp.page_no); //required
    data.append('limit', tableProp.limit); //required
    data.append('sort_column', tableProp.sort_column);
	data.append('sort_type', tableProp.sort_type);
    data.append('filter_business_personality', tableProp.filter_business_personality);
    data.append('filter_nationality', tableProp.filter_nationality);
    data.append('filter_nature_of_operation', tableProp.filter_nature_of_operation);
    return API().post('/ProcurementMode/Get/index_post',data).then((response)=>{
        return response.data;
    }).catch((err)=>{
       // console.error(err);
    });
};
