import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";
import Highlight from "react-highlighter";

import * as Material from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Imported Material Icons
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosSharp from "@material-ui/icons/ArrowBackIosSharp";
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { mapStateToProps, mapDispatchToProps } from "../ppmp.reducer";
import { useStyles } from "./ppmp.supplies.styles";
import { COMM } from "../../../helpers/common";
import RequestItemModal from "../request.item/req.item.modal";
import SuppliesModal from "./ppmp.modal";
import * as PPMPService from "../ppmp.service";

class PPMPSupplies extends Component {
  constructor() {
    super();
    this.state = {
      modalVisible: false,
      searchValue: "",
      drawerVisible: true,
      chipData: [],
      totalItems: 0,
      transactionNo: "",
      transactionData: null,
      keyword: "",
      page_no: 0,
      limit: 20,
      sort_column: "",
      sort_type: "",
      filter_unit: "",
      filter_category_name: "",

      reqItemModalVisible: false,
      searchAUVisible: false,
      searchOptions: [],
      searchValueAU: null,

      categories: [],
      search_cat: "",

      // IsReimbursement: 0
      isUnforeseen: false,
    };
    this.requestItemDataSouce = this.requestItemDataSouce.bind(this);
    this.toggleDrawerShow = this.toggleDrawerShow.bind(this);
    this.getGridListCols = this.getGridListCols.bind(this);
    this.onTextChangeHandler = this.onTextChangeHandler.bind(this);
    this.onSearchHandler = this.onSearchHandler.bind(this);
    this.generateChip = this.generateChip.bind(this);
    this.removeChipHandler = this.removeChipHandler.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onLoadMoreItems = this.onLoadMoreItems.bind(this);
    this.tableFilterContainer = this.tableFilterContainer.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.requestGeneratedPPMP = this.requestGeneratedPPMP.bind(this);
    this.onOpenRequestModal = this.onOpenRequestModal.bind(this);
    this.onCloseRequestModal = this.onCloseRequestModal.bind(this);
    this.openSuppliesModal = this.openSuppliesModal.bind(this);
    this.onHandleSearchAutocomplete =
      this.onHandleSearchAutocomplete.bind(this);
    this.searchRenderOptions = this.searchRenderOptions.bind(this);
    this.onHandleSearchAutocompleteSelect =
      this.onHandleSearchAutocompleteSelect.bind(this);
    this.onSearchCategories = this.onSearchCategories.bind(this);
  }
  onSearchCategories(e) {
    this.setState(
      {
        ...this.state,
        search_cat: e.target.value,
      },
      () => {
        let dbCategories = this.props.ppmp.itemFilter;
        // console.log('$$categories', dbCategories);
        let searchText = e.target.value.toLowerCase();
        // console.log('$$searchText', searchText);
        if (searchText.length > 0) {
          let filteredCategories2 = dbCategories.filter((category) => {
            // console.log('$$category', category);
            return (
              category._particular == "category_name" &&
              category._value.toLowerCase().includes(searchText)
            );
          });
          this.setState({
            ...this.state,
            categories: filteredCategories2,
          });
        } else {
          this.setState({
            ...this.state,
            categories: this.props.ppmp.itemFilter,
          });
        }
      }
    );
  }
  componentDidMount() {
    // console.log('$$State', this.state);
    const { ppmp, history, width } = this.props;
    sessionService
      .loadSession()
      .then((currentSession) => {
        // console.log('$$currentSessio', currentSession.selectedTransaction);
        if (
          currentSession.selectedTransaction === null ||
          currentSession.selectedTransaction === undefined
        ) {
          history.push("/");
        } else {
          ppmp.itemDataSource.length === 0 && this.requestItemDataSouce();
          const data = currentSession.selectedTransaction;
          // console.log('$$data', data);
          const drawerVisible = Material.isWidthUp("xl", width);
          this.setState(
            {
              ...this.state,
              transactionID: data.id, // ppmp_id
              transactionNo: data.ppmp_no,
              transactionStatus: data.status,
              transactionDate: data.transdate,
              transactionYear: data.year,
              transactionData: data,
              // IsReimbursement: data.is_reimbursement,
              drawerVisible,
              isUnforeseen: data.is_unforeseen,
            },
            () => this.requestGeneratedPPMP()
          );
        }
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "warning",
        });
      });
  }
  componentWillUnmount() {
    const { history } = this.props;
    var targetPath = history.location.pathname;
    if (
      targetPath.indexOf("ppmplist") === -1 &&
      targetPath.indexOf("ppmpform") === -1
    ) {
      sessionService
        .saveSession({
          ...this.props.auth,
          selectedTransaction: null,
        })
        .catch((err) => {
          this.props.displayAlert({
            visible: true,
            message: err,
            severity: "warning",
          });
        });
    }
  }

  requestGeneratedPPMP() {
    const { displayAlert, setGenPPMPDataSource } = this.props;
    PPMPService.getGeneratedPPMP({ ppmp_id: this.state.transactionID })
      .then((response) => {
        if (response.error) {
          displayAlert({
            visible: true,
            message: response.msg,
            severity: "warning",
          });
        } else {
          setGenPPMPDataSource({ genPPMPDataSource: response.data.result0 });
          let count = 0;
          response.data.result0.forEach((e, r) => {
            e.funds.forEach((t) => {
              t.ppa_list.forEach((r) => {
                r.items.forEach(() => {
                  count++;
                });
              });
            });
          });
          this.setState({ ...this.state, totalItems: count });
        }
      })
      .catch((err) => {
        displayAlert({ visible: true, message: err, severity: "error" });
      });
  }
  onOpenRequestModal() {
    this.setState({ ...this.state, reqItemModalVisible: true });
  }
  onCloseRequestModal() {
    this.setState({ ...this.state, reqItemModalVisible: false });
  }
  requestItemDataSouce() {
    const { setItemDataSource, displayLoading, displayAlert } = this.props;
    displayLoading(true);
    PPMPService.getItemList({ ...this.state })
      .then((response) => {
        if (response.error) {
          displayAlert({
            visible: true,
            message: response.msg,
            severity: "error",
          });
        } else {
          setItemDataSource({
            itemDataSource: response.result.result0.filter(
              (x) => x._latest_price > 0
            ), // only display non-zero items
            itemTotal: parseInt(response.result.result1[0].ROW_COUNT),
            itemFilter: response.result.result3,
          });
          this.setState(
            {
              ...this.state,
              categories: response.result.result3,
            },
            () => {
              this.generateChip();
            }
          );
        }
        displayLoading(false);
      })
      .catch((err) => {
        this.generateChip();
        displayLoading(false);
        displayAlert({ visible: true, message: err, severity: "warning" });
      });
    this.toggleDrawerShow(false);
  }
  onTextChangeHandler(e) {
    const node = e.target;
    this.setState({ searchValue: node.value });
  }
  onSearchHandler(e) {
    e && e.preventDefault();
    const itemFullname = this.state.searchValue.replace(/[^A-Z0-9]+/gi, " ");
    this.setState({ ...this.state, keyword: itemFullname, page_no: 0 }, () => {
      this.requestItemDataSouce();
    });
  }
  getGridListCols() {
    const { width } = this.props;
    if (Material.isWidthUp("xl", width)) {
      return 6;
    }
    if (Material.isWidthUp("lg", width)) {
      return 5;
    }
    if (Material.isWidthUp("md", width)) {
      return 4;
    }
    if (Material.isWidthUp("sl", width)) {
      return 2;
    }
    return 1;
  }
  generateChip() {
    const { keyword, filter_unit, filter_category_name } = this.state;
    let data = [];
    keyword &&
      data.push({
        key: 1,
        label: keyword,
        icon: <SearchIcon />,
        state1: "keyword",
        state2: "searchValue",
        state3: "searchValueAU",
      });
    filter_unit &&
      data.push({
        key: 3,
        label: filter_unit,
        icon: <FilterListIcon />,
        state1: "filter_unit",
      });
    filter_category_name &&
      data.push({
        key: 4,
        label: filter_category_name,
        icon: <FilterListIcon />,
        state1: "filter_category_name",
      });
    this.setState({ ...this.state, chipData: data });
  }
  handleChangeRowsPerPage(e) {
    this.setState(
      { ...this.state, page_no: 0, limit: parseInt(e.target.value, 10) },
      () => {
        this.requestItemDataSouce();
      }
    );
  }
  handleChangePage(event, value) {
    this.setState({ ...this.state, page_no: value }, () => {
      this.requestItemDataSouce();
    });
  }
  onLoadMoreItems() {
    this.setState(
      { ...this.state, page_no: 0, limit: this.state.limit + 20 },
      () => {
        this.requestItemDataSouce();
      }
    );
  }
  removeChipHandler(value) {
    this.setState(
      (state) => {
        if (value.state3) {
          return {
            ...state,
            chipData: state.chipData.filter((chip) => chip.key !== value.key),
            [value.state1]: "",
            [value.state2]: "",
            [value.state3]: null,
          };
        }
        return {
          ...state,
          chipData: state.chipData.filter((chip) => chip.key !== value.key),
          [value.state1]: "",
        };
      },
      () => {
        this.requestItemDataSouce();
      }
    );
  }
  toggleDrawerShow(value) {
    this.setState(
      { ...this.state, drawerVisible: value, search_cat: "" },
      () => {
        if (this.state.categories && this.state.categories.length == 0) {
          this.setState({
            ...this.state,
            categories: this.props.ppmp.itemFilter,
          });
        }
      }
    );
  }
  onFilterChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }
  openSuppliesModal(res) {
    this.props.displaySupplies({
      modalVisible: true,
      suppliesData: res.data,
      transactionData: this.state.transactionData,
    });
  }
  onHandleSearchAutocomplete(event) {
    const { displayAlert } = this.props;
    const node = event.target;
    if (node) {
      const value = node.value.replace(/[^A-Z0-9]+/gi, " ");
      this.setState({ searchValue: value });
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        PPMPService.searchRecommendation(value)
          .then((res) => {
            if (res.error) {
              this.setState({ ...this.state, searchOptions: [] });
            } else {
              this.setState({ ...this.state, searchOptions: res.data.result });
            }
            clearTimeout(this.timeout);
          })
          .catch((err) => {
            displayAlert({ visible: true, message: err, severity: "error" });
          });
      }, 1000);
    }
  }
  onHandleSearchAutocompleteSelect(event, value) {
    if (value) {
      const itemFullname = value.itemFullname.replace(/[^A-Z0-9]+/gi, " ");
      this.setState(
        { ...this.state, searchValue: itemFullname, searchValueAU: value },
        () => {
          this.onSearchHandler();
        }
      );
    }
  }
  searchRenderOptions(option) {
    return (
      <div className={this.props.classes.OptionContainer}>
        <Material.Typography variant="subtitle1">
          {option.name} {option.description}
        </Material.Typography>
      </div>
    );
  }
  tableFilterContainer() {
    const { classes } = this.props;
    const options = [
      {
        key: 1,
        label: "Category",
        value: this.state[`filter_category_name`],
        target: "category_name",
        handleChange: this.onFilterChange,
      },
      // {key: 2, label: "Unit", value: this.state[`filter_unit`], target: "unit", handleChange: this.onFilterChange },
    ];
    return (
      <Material.Box className={classes.drawer}>
        {options.map((option) => {
          return (
            <Material.FormControl
              component="fieldset"
              key={option.key}
              margin="normal"
              size="small"
              variant="outlined"
            >
              <Material.FormLabel filled>{option.label}</Material.FormLabel>
              <Material.Divider></Material.Divider>
              <Material.RadioGroup
                aria-label={option.target}
                name={`filter_${option.target}`}
                value={option.value}
                onChange={option.handleChange}
              >
                {this.state.categories
                  .filter(
                    (obj) =>
                      obj._particular === option.target && obj._value !== ""
                  )
                  .map((filter, index) => {
                    return (
                      <React.Fragment
                        key={`key-label-asdnasd-${filter._value}`}
                      >
                        <Material.FormControlLabel
                          key={`${filter._value}-${index}`}
                          value={filter._value}
                          className="radio-choices"
                          control={<Material.Radio size="small" />}
                          label={filter._value}
                        />
                        {filter._child ? (
                          <React.Fragment>
                            {filter._child
                              .filter(
                                (objCD) =>
                                  objCD._particular === option.target &&
                                  objCD._value !== ""
                              )
                              .map((filterCd, indexCd) => {
                                return (
                                  <Material.FormControlLabel
                                    key={`${filterCd._value}-${indexCd}`}
                                    value={filterCd._value}
                                    className="subradio-choices"
                                    control={<Material.Radio size="small" />}
                                    label={filterCd._value}
                                  />
                                );
                              })}
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    );
                  })}
              </Material.RadioGroup>
            </Material.FormControl>
          );
        })}
      </Material.Box>
    );
  }
  render() {
    const {
      drawerVisible,
      searchValue,
      chipData,
      transactionNo,
      transactionStatus,
      transactionDate,
      transactionYear,
      searchOptions,
      searchValueAU,
    } = this.state;
    const { classes, ppmp, data, displaySupplies } = this.props;
    // console.log('$$this.props.location.state', this.props.location.state?.ppmpId);
    return (
      <div className={classes.root}>
        <Material.Drawer
          variant="persistent"
          anchor="left"
          open={drawerVisible}
        >
          <Material.AppBar position="static" color="default">
            <Material.Toolbar className={classes.drawerToolbar}>
              <Material.Typography
                variant="p"
                component="div"
                style={{ flexGrow: 1 }}
              >
                Categories
              </Material.Typography>
              <Material.IconButton
                fontSize="small"
                aria-label="filter list"
                color="primary"
                onClick={() => {
                  this.toggleDrawerShow(!drawerVisible);
                }}
              >
                <ArrowBackIosSharp />
              </Material.IconButton>
            </Material.Toolbar>
          </Material.AppBar>
          <Material.Divider></Material.Divider>
          <Material.Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <div style={{ paddingLeft: 30 }}>
              <Material.InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Categories"
                onChange={this.onSearchCategories}
                name="search_cat"
                value={this.state.search_cat}
              />
              <Material.IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <SearchIcon />
              </Material.IconButton>
            </div>
          </Material.Paper>
          {this.tableFilterContainer()}
          <Material.Divider></Material.Divider>
          <Material.AppBar position="static" color="default">
            <Material.Toolbar>
              <Material.Typography
                variant="h6"
                component="div"
                style={{ flexGrow: 1 }}
              ></Material.Typography>
              <Material.Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<FilterListIcon />}
                onClick={() => this.requestItemDataSouce()}
              >
                Apply
              </Material.Button>
            </Material.Toolbar>
          </Material.AppBar>
        </Material.Drawer>
        <Material.Paper className={classes.transactionHeader}>
          <Material.Grid container spacing={3}>
            <Material.Grid item xs={9}>
              <div className="inline">
                <div className={classes.statusColor}>
                  <Material.Chip
                    className={transactionStatus}
                    label={COMM.capitalizeFirstLetter(transactionStatus)}
                  />
                </div>
                <Material.Typography
                  className="flex"
                  color="primary"
                  variant="h5"
                >
                  {transactionNo}
                </Material.Typography>
                <Material.Typography variant="h6">
                  ({transactionYear})
                </Material.Typography>
              </div>
            </Material.Grid>
            <Material.Grid item xs={3}>
              <Material.Typography variant="subtitle2" className="pull-right">
                {COMM.DdMmmYyyyHhMm(transactionDate)}
              </Material.Typography>
            </Material.Grid>
          </Material.Grid>
          <Material.Divider className="div-margin" />
          <Material.Typography variant="caption">
            PPMP Transaction No:
          </Material.Typography>
        </Material.Paper>
        <Material.Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Material.Grid item xs={12}>
            <Material.Paper className={classes.paper} elevation={3}>
              <Material.Toolbar>
                <div className={classes.toolRight}>
                  <Material.Badge
                    badgeContent={this.state.totalItems}
                    color="secondary"
                  >
                    <Material.Button
                      variant="contained"
                      color="primary"
                      startIcon={<ShoppingCartIcon />}
                      onClick={() => {
                        // this.props.history.push('/ppmplist')
                        this.props.history.push({
                          pathname: "/ppmplist",
                          state: {
                            ppmpId: this.props.location.state?.ppmpId,
                          },
                        });
                      }}
                    >
                      View PPMP List
                    </Material.Button>
                  </Material.Badge>
                  <Material.Tooltip title="Filter list">
                    <Material.IconButton
                      className="animate"
                      aria-label="filter list"
                      color="primary"
                      onClick={() => {
                        this.toggleDrawerShow(!drawerVisible);
                      }}
                    >
                      <FilterListIcon />
                    </Material.IconButton>
                  </Material.Tooltip>
                </div>
                <Material.Button
                  className={classes.margin}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    this.onOpenRequestModal();
                  }}
                >
                  Request Supplies
                </Material.Button>
              </Material.Toolbar>
              {/* <form>
								<Material.Toolbar>
									<Material.Tooltip title="Search for specific supply">
										<Material.FormControl fullWidth variant="outlined">
											<Material.InputLabel htmlFor="outlined-adornment-password">Search</Material.InputLabel>
											<Material.OutlinedInput id="outlined-adornment-password" type='text'
												value={searchValue} onChange={this.onTextChangeHandler}
												endAdornment={
													<Material.InputAdornment position="end">
														<Material.IconButton aria-label="toggle password visibility" type="submit"
															onClick={this.onSearchHandler} edge="end" >
															<SearchIcon /> 
														</Material.IconButton>
													</Material.InputAdornment>
												} labelWidth={70} />
										</Material.FormControl>
									</Material.Tooltip>
								</Material.Toolbar>
							</form> */}
              <form>
                <Material.Toolbar>
                  <Material.Tooltip title="Search for specific supply">
                    <Autocomplete
                      id="search-asynch-nfs"
                      className={classes.searchAutocomplete}
                      getOptionSelected={(option, value) =>
                        option.itemFullname === value.itemFullname
                      }
                      options={searchOptions}
                      getOptionLabel={(option) => `${option.itemFullname}`}
                      value={searchValueAU}
                      renderOption={this.searchRenderOptions}
                      onChange={this.onHandleSearchAutocompleteSelect}
                      renderInput={(params) => (
                        <Material.TextField
                          {...params}
                          label="Search"
                          variant="outlined"
                          className={classes.searchInput}
                          onChange={this.onHandleSearchAutocomplete}
                          value={searchValue}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Material.InputAdornment position="end">
                                <Material.IconButton
                                  aria-label="toggle password visibility"
                                  type="submit"
                                  onClick={this.onSearchHandler}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </Material.IconButton>
                              </Material.InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Material.Tooltip>
                </Material.Toolbar>
              </form>
              <div>
                <div className={classes.chipContainer}>
                  {chipData.map((data) => (
                    <Material.Chip
                      className={classes.chipData}
                      color="primary"
                      key={data.key}
                      label={data.label}
                      onDelete={() => this.removeChipHandler(data)}
                      icon={data.icon}
                    />
                  ))}
                </div>
                {ppmp.itemDataSource.length > 0 ? (
                  <Material.GridList
                    cellHeight="auto"
                    className={classes.gridList}
                    cols={this.getGridListCols()}
                  >
                    {ppmp.itemDataSource.map(
                      (item) =>
                        parseFloat(item._latest_price) >= 0 && ( //only display non-zero price
                          <Material.GridListTile
                            key={`list-grid-key-aksh-${item.id}`}
                            cols={1}
                            onClick={() => {
                              displaySupplies({
                                modalVisible: true,
                                suppliesData: item,
                                transactionData: this.state.transactionData,
                              });
                            }}
                          >
                            <Material.Paper
                              className={classes.gridTile}
                              elevation={3}
                            >
                              <div className={classes.gridMainContent}>
                                <Material.Tooltip
                                  title={
                                    item.description ? (
                                      <Highlight
                                        search={this.state.keyword}
                                      >{`${item.name} - ${item.description}`}</Highlight>
                                    ) : (
                                      ""
                                    )
                                  }
                                >
                                  <div className="title">
                                    <b>
                                      <Highlight search={this.state.keyword}>
                                        {item.name}
                                      </Highlight>
                                    </b>{" "}
                                    <Highlight search={this.state.keyword}>
                                      {item.description}
                                    </Highlight>
                                  </div>
                                </Material.Tooltip>
                                <div className="description">
                                  <Material.Tooltip
                                    title={
                                      item.category_name
                                        ? item.category_name
                                        : ""
                                    }
                                  >
                                    <div className="fit-details">
                                      {" "}
                                      {item.category_name}{" "}
                                    </div>
                                  </Material.Tooltip>
                                  <Material.Tooltip
                                    title={
                                      item.item_code ? (
                                        <Highlight search={this.state.keyword}>
                                          {item.item_code}
                                        </Highlight>
                                      ) : (
                                        ""
                                      )
                                    }
                                  >
                                    <div className="fit-details">
                                      <Highlight search={this.state.keyword}>
                                        {item.item_code}
                                      </Highlight>
                                    </div>
                                  </Material.Tooltip>
                                </div>
                              </div>
                              <div>
                                Php <strong>{item._latest_price}</strong>
                              </div>
                              <Material.Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                startIcon={
                                  <AddShoppingCartIcon color="primary" />
                                }
                              >
                                Add to list
                              </Material.Button>
                            </Material.Paper>
                          </Material.GridListTile>
                        )
                    )}
                  </Material.GridList>
                ) : (
                  <React.Fragment>
                    <div className="emptyCont">
                      <div className="empty">
                        <div className="emptyBorder">
                          No Items found.
                          <Material.Link
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.onOpenRequestModal();
                            }}
                          >
                            {" "}
                            Request now
                          </Material.Link>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className={classes.pagerFooter}>
                  {data.loadingVisible ? (
                    <Material.LinearProgress />
                  ) : (
                    <Material.Button
                      variant="contained"
                      color="primary"
                      onClick={this.onLoadMoreItems}
                    >
                      Load more items
                    </Material.Button>
                  )}
                </div>
              </div>
            </Material.Paper>
          </Material.Grid>
        </Material.Grid>
        <SuppliesModal
          requestGeneratedPPMP={this.requestGeneratedPPMP}
          isUnforeseen={this.state.isUnforeseen}
          ppmpId={this.props.location.state?.ppmpId}
        />
        <RequestItemModal
          reqItemModalVisible={this.state.reqItemModalVisible}
          onCloseModal={this.onCloseRequestModal}
          // expectItemReturn onCloseRender={this.openSuppliesModal}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Material.withWidth()(Material.withStyles(useStyles)(PPMPSupplies)));
