import React from "react";
import { connect } from "react-redux";

import * as Material from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useStyles } from "../item/item.modal.styles"; // CHANGE
import * as ItemRequestService from "./item.request.service"; // CHANGE

import { mapStateToProps, mapDispatchToProps } from "./item.request.reducer"; // CHANGE

class ItemRequestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // itemSerialNo: "",
      itemDesc: "",
      itemUnit: "",
      itemCategory: 0,
      itemCategoryName: "",
      itemLink: "",
      itemIsActive: true,
      itemUserId: 0,
      itemPrice: 0,
      itemId: 0,
      itemName: "",
      ppmpItemListId: 0,
      // itemCode: "",
      itemUnitCost: "",
      itemUnitApproved: true,
      itemIsApproved: false,
      itemRejectRemarks: "",

      dialogApprovedItemRequestVisible: false,
      dialogRejectedItemRequestVisible: false,
      requestedItemTarget: false,
    };
    this.onHandleAutocomplete = this.onHandleAutocomplete.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleEditMode = this.onHandleEditMode.bind(this);
    this.onHandleCancel = this.onHandleCancel.bind(this);
    this.requestEditApprovedItem = this.requestEditApprovedItem.bind(this);
    this.requestEditRejectedItem = this.requestEditRejectedItem.bind(this);
    this.PPMPListRequestEditRejectedItem =
      this.PPMPListRequestEditRejectedItem.bind(this);
    this.onPriceInput = this.onPriceInput.bind(this);
    this.normalizePriceInput = this.normalizePriceInput.bind(this);
    this.onDisplayRejectedItemRequestDialog =
      this.onDisplayRejectedItemRequestDialog.bind(this);
    this.onDisplayApprovedItemRequestDialog =
      this.onDisplayApprovedItemRequestDialog.bind(this);
    this.handleAnchorClick = this.handleAnchorClick.bind(this);
  }
  componentDidMount() {
    this.setState({ ...this.state, itemUserId: this.props.data.user.id });
  }
  onHandleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onHandleAutocomplete(event, value) {
    if (value) {
      this.setState({
        ...this.state,
        itemCategory: value.id,
        itemCategoryName: value.category_name,
      });
    }
  }
  requestEditApprovedItem() {
    const {
      requestGetRequestedItems,
      onCloseModal,
      displayAlert,
      forPriceRequestList,
      requestPPMPPriceReqList,
    } = this.props;
    // Delete the comma because of decimal structure of itemPrice
    const { itemPrice } = this.state;
    let itemPriceDecimalValue = itemPrice.replace(/[,]+/g, "");
    ItemRequestService.postRequestedItem({
      ...this.state,
      itemPrice: itemPriceDecimalValue,
      itemUnitApproved: true,
    })
      .then((res) => {
        displayAlert({ visible: true, message: res.msg, severity: "success" });
        if (forPriceRequestList) {
          requestPPMPPriceReqList();
        } else {
          requestGetRequestedItems();
        }
      })
      .catch((err) => {
        displayAlert({ visible: true, message: err, severity: "error" });
      });
    this.setState({
      ...this.state,
      requestedItemTarget: false,
      dialogApprovedItemRequestVisible: false,
    });
    onCloseModal();
  }
  requestEditRejectedItem() {
    const { itemRejectRemarks, itemPrice } = this.state;
    const { requestGetRequestedItems, onCloseModal, displayAlert } = this.props;
    let itemPriceDecimalValue = itemPrice.replace(/[,]+/g, "");
    const errorHandlerB = {};

    errorHandlerB.itemRejectRemarksError = !itemRejectRemarks && true;
    this.setState({ ...this.state, ...errorHandlerB });
    const { itemRejectRemarksError } = errorHandlerB;
    if (!itemRejectRemarksError) {
      ItemRequestService.RejectRequestedItem({
        ...this.state,
        itemPrice: itemPriceDecimalValue,
        itemIsActive: false,
        itemUnitApproved: false,
      })
        .then((res) => {
          displayAlert({
            visible: true,
            message: res.msg,
            severity: "success",
          });
          requestGetRequestedItems();
          onCloseModal();
        })
        .catch((err) => {
          displayAlert({ visible: true, message: err, severity: "error" });
        });
      this.setState({
        ...this.state,
        requestedItemTarget: false,
        dialogRejectedItemRequestVisible: false,
        itemRejectRemarksError: false,
      });
    } else {
      displayAlert({
        visible: true,
        message: "Please add reason for rejecting item request.",
        severity: "warning",
      });
    }
  }
  PPMPListRequestEditRejectedItem() {
    const { itemRejectRemarks, itemPrice } = this.state;
    const { requestPPMPPriceReqList, onCloseModal, displayAlert } = this.props;
    let itemPriceDecimalValue = itemPrice.replace(/[,]+/g, "");
    const errorHandlerB = {};

    errorHandlerB.itemRejectRemarksError = !itemRejectRemarks && true;
    this.setState({ ...this.state, ...errorHandlerB });
    const { itemRejectRemarksError } = errorHandlerB;
    if (!itemRejectRemarksError) {
      ItemRequestService.RejectRequestedItem({
        ...this.state,
        itemPrice: itemPriceDecimalValue,
        itemIsActive: false,
        itemUnitApproved: false,
      })
        .then((res) => {
          displayAlert({
            visible: true,
            message: res.msg,
            severity: "success",
          });
          requestPPMPPriceReqList();
          onCloseModal();
        })
        .catch((err) => {
          displayAlert({ visible: true, message: err, severity: "error" });
        });
      this.setState({
        ...this.state,
        requestedItemTarget: false,
        dialogRejectedItemRequestVisible: false,
        itemRejectRemarksError: false,
      });
    } else {
      displayAlert({
        visible: true,
        message: "Please add reason for rejecting item request.",
        severity: "warning",
      });
    }
  }
  onHandleCancel() {
    const { onCloseModal } = this.props;
    onCloseModal();
  }
  onHandleEditMode() {
    const { editData, forPriceRequestList } = this.props;
    if (forPriceRequestList) {
      this.setState(() => {
        return {
          itemDesc: editData.description,
          itemUnit: editData.unit,
          itemCategory: editData.category_id,
          itemCategoryName: editData.category_name,
          itemPrice: editData._latest_price,
          itemId: editData.item_id,
          itemName: editData.name,
          itemUnitCost: editData.unit_cost,
          itemLink: editData.link,
          itemIsApproved: editData.is_approved === "Y",
          ppmpItemListId: editData.ppmp_item_id,
        };
      });
    } else {
      this.setState(() => {
        return {
          itemDesc: editData.description,
          itemUnit: editData.unit,
          itemCategory: editData.category_id,
          itemLink: editData.link,
          itemCategoryName: editData.category_name,
          itemPrice: editData._latest_price,
          itemId: editData.id,
          itemName: editData.name,
        };
      });
    }
  }
  // Function to set the state by calling the format currency function
  onPriceInput({ target: { value } }) {
    this.setState({ itemPrice: this.normalizePriceInput(value) });
  }
  //Function to format the currency
  normalizePriceInput(value) {
    // Return value based on Regular Expression below:
    return value
      .replace(/[^0-9.]/g, "") // Remove all characters that aren't digit or dot
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Put comma every 3rd digit
      .replace(/\.+/g, ".") // Replace multiple dots with a single dot
      .replace(/(.*\.[0-9][0-9]?).*/g, "$1") // Only allow 2 digits after a dot
      .replace(/^0+(.*)$/, "0$1") // Replace multiple zeros with a single one
      .replace(/^0([^.].*)$/, "$1"); // Remove leading zero
  }
  onDisplayApprovedItemRequestDialog(value) {
    const {
      itemDesc,
      itemUnit,
      itemCategoryName,
      itemPrice,
      itemName,
      // itemLink,
    } = this.state;
    const errorHandler = {};
    errorHandler.itemNameError = !itemName && true;
    errorHandler.itemDescError = !itemDesc && true;
    // errorHandler.itemLinkError = !itemLink && true;
    errorHandler.itemUnitError = !itemUnit && true;
    errorHandler.itemPriceError = !itemPrice && true;
    errorHandler.itemCategoryNameError = !itemCategoryName && true;
    this.setState({ ...this.state, ...errorHandler });
    const {
      itemDescError,
      itemUnitError,
      itemCategoryNameError,
      // itemLinkError,
      itemPriceError,
      itemNameError,
      itemCodeError,
    } = errorHandler;
    if (
      !itemDescError &&
      !itemUnitError &&
      // !itemLinkError &&
      !itemCategoryNameError &&
      !itemPriceError &&
      !itemNameError &&
      !itemCodeError
    ) {
      this.setState({
        ...this.state,
        requestedItemTarget: true,
        dialogApprovedItemRequestVisible: value,
      });
    } else {
      this.setState({
        ...this.state,
        requestedItemTarget: false,
        dialogApprovedItemRequestVisible: value,
      });
    }
  }
  handleAnchorClick = (event) => {
    // 👇️ use event.preventDefault() if you want to
    // prevent navigation
    // event.preventDefault();

    console.log("Anchor element clicked");

    // 👇️ refers to the link element
    console.log(event.currentTarget);
  };
  onDisplayRejectedItemRequestDialog(value) {
    this.setState({
      ...this.state,
      requestedItemTarget: true,
      dialogRejectedItemRequestVisible: value,
      itemRejectRemarksError: false,
    });
  }
  render() {
    const { itemRequestModalVisible, classes, items } = this.props;
    const {
      itemPrice,
      itemDesc,
      itemUnit,
      itemCategoryName,
      itemLink,
      itemName,
      itemIsApproved,
      itemDescError,
      itemLinkError,
      itemUnitError,
      itemCategoryNameError,
      itemPriceError,
      itemNameError,
      itemRejectRemarksError,
    } = this.state;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={itemRequestModalVisible}
        onClose={this.onHandleCancel}
        onRendered={this.onHandleEditMode}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.root}>
          <Fade in={itemRequestModalVisible}>
            <div className={classes.paper}>
              <Material.Typography color="primary" variant="h5">
                Requested Item
              </Material.Typography>
              <div className={classes.FormContainer}>
                <form
                  className={classes.foroneline}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    multiline
                    required
                    id="item-name"
                    label="Name"
                    name="itemName"
                    value={itemName}
                    disabled={itemIsApproved}
                    error={itemNameError}
                    onChange={this.onHandleChange}
                  />
                </form>
                <form
                  className={classes.foroneline}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    multiline
                    required
                    id="item-description"
                    label="Description"
                    name="itemDesc"
                    value={itemDesc}
                    disabled={itemIsApproved}
                    error={itemDescError}
                    onChange={this.onHandleChange}
                  />
                </form>
                <form
                  className={classes.fortwoline}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    required
                    id="item-unit"
                    label="Unit"
                    name="itemUnit"
                    error={itemUnitError}
                    disabled={itemIsApproved}
                    value={itemUnit}
                    onChange={this.onHandleChange}
                  />
                  <TextField
                    onChange={this.onPriceInput}
                    required
                    id="item-price"
                    label="Price"
                    name="itemPrice"
                    error={itemPriceError}
                    value={"₱ " + itemPrice || ""}
                  />
                </form>
                <form
                  className={classes.foroneline}
                  noValidate
                  autoComplete="off"
                >
                  <Autocomplete
                    value={itemCategoryName || ""}
                    onChange={this.onHandleAutocomplete}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="item-category"
                    options={items.categoryDatasource}
                    disabled={itemIsApproved}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      return option.category_name;
                    }}
                    renderOption={(option) => option.category_name}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category *"
                        margin="normal"
                        error={itemCategoryNameError}
                      />
                    )}
                  />
                </form>
                <div>
                  <Material.Typography>Reference Link *</Material.Typography>
                  <Material.Link
                    href={itemLink}
                    passHref
                    target="_blank"
                    rel="noreferrer"
                    error={itemLinkError}
                  >
                    {itemLink}
                    <br></br>
                  </Material.Link>
                </div>
                <form className={classes} noValidate autoComplete="off">
                  <Button
                    className={classes.action}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      itemIsApproved
                        ? this.requestEditApprovedItem()
                        : this.onDisplayApprovedItemRequestDialog(true);
                    }}
                  >
                    {" "}
                    {itemIsApproved ? "Update Price" : "Approved"}{" "}
                  </Button>
                  {!itemIsApproved ? (
                    <Button
                      className={classes.action}
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        this.onDisplayRejectedItemRequestDialog(true)
                      }
                    >
                      {" "}
                      Reject{" "}
                    </Button>
                  ) : (
                    ""
                  )}
                </form>
              </div>
              <Material.Dialog
                open={this.state.dialogApprovedItemRequestVisible}
                onClose={() => {
                  this.onDisplayApprovedItemRequestDialog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                {this.state.requestedItemTarget ? (
                  <React.Fragment>
                    <Material.DialogTitle id="alert-dialog-title">
                      Approve this requested item {itemName}?
                    </Material.DialogTitle>
                    <Material.DialogContent>
                      <Material.DialogContentText id="alert-dialog-description">
                        This will create a new item {itemName}. Do you wish to
                        continue?
                      </Material.DialogContentText>
                    </Material.DialogContent>
                    <Material.DialogActions>
                      <Material.Button
                        onClick={() => {
                          this.onDisplayApprovedItemRequestDialog(false);
                        }}
                        color="secondary"
                      >
                        {" "}
                        Cancel{" "}
                      </Material.Button>
                      <Material.Button
                        onClick={() => {
                          this.requestEditApprovedItem();
                        }}
                        color="primary"
                        autoFocus
                      >
                        {" "}
                        Continue{" "}
                      </Material.Button>
                    </Material.DialogActions>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    Please fill up all the fields and try again.{" "}
                  </React.Fragment>
                )}
              </Material.Dialog>
              <Material.Dialog
                open={this.state.dialogRejectedItemRequestVisible}
                onClose={() => {
                  this.onDisplayRejectedItemRequestDialog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                {this.state.requestedItemTarget ? (
                  <React.Fragment>
                    <Material.DialogTitle id="alert-dialog-title">
                      Reject this requested item {itemName}?
                    </Material.DialogTitle>
                    <Material.DialogContent>
                      <Material.DialogContentText id="alert-dialog-description">
                        This will reject a requested item {itemName}. Do you
                        wish to continue?
                      </Material.DialogContentText>
                    </Material.DialogContent>
                    <div className={classes.bodyPaper}>
                      <form
                        className={classes.foroneline}
                        noValidate
                        autoComplete="off"
                      >
                        <Material.TextField
                          id="reject_remarks"
                          label="Reason:"
                          type="text"
                          onChange={(e) => {
                            this.setState({
                              ...this.itemRejectRemarks,
                              itemRejectRemarks: e.target.value,
                            });
                          }}
                          value={this.state.itemRejectRemarks}
                          className={classes.textField}
                          InputLabelProps={{ shrink: true }}
                          error={itemRejectRemarksError}
                        />
                      </form>
                    </div>
                    <Material.DialogActions>
                      <Material.Button
                        onClick={() => {
                          this.onDisplayRejectedItemRequestDialog(false);
                        }}
                        color="secondary"
                      >
                        {" "}
                        Cancel{" "}
                      </Material.Button>
                      <Material.Button
                        onClick={() => {
                          this.props.forPriceRequestList
                            ? this.PPMPListRequestEditRejectedItem()
                            : this.requestEditRejectedItem();
                        }}
                        color="primary"
                        autoFocus
                      >
                        Continue
                      </Material.Button>
                    </Material.DialogActions>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    Please fill up all the fields and try again.{" "}
                  </React.Fragment>
                )}
              </Material.Dialog>
            </div>
          </Fade>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ItemRequestModal));
