import React, { Component } from "react";
import { connect } from "react-redux";
import { useStyles } from "./ppmp.modal.styles";
import { mapStateToProps, mapDispatchToProps } from "../ppmp.reducer";
// import { VariableSizeList } from 'react-window';
import matchSorter from "match-sorter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Material from "@material-ui/core";

import * as PPMPService from "../ppmp.service";
import * as ModesService from "../../admin/modes/modes.service";
import * as ActivitiesService from "../../activities/activities.service";
import { sessionService } from "redux-react-session";
import Logout from "../../auth/logout";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

class SuppliesModal extends Component {
  constructor() {
    super();
    this.state = {
      addItemModalVisible: false,
      dialogVisible: false,
      proceedAnyway: false,
      advanceSearchVisible: false,
      rows: [{ fundSource: null, fundSourceAllocatedAmount: 0 }],
      fundSource: null,
      fundSourceOpen: false,
      respoCodeValue: null,
      sourceFundList: [],
      RA_year_choices: [],
      respoAccount_choices: [],
      fundTypeChoices: [],
      legalBasisChoices: [],
      checkedRCA: false,
      invalidState: false,
      errorDialogVisible: false,
      errorDialogMessage: "",

      errorDialogVisibleAllocated: false,

      //default values
      IsNonOffice: false,
      RA_year: "",
      RA_desc: "",
      fundType: "",
      PPA_code: "",
      respo_code: "",
      activeStep: 0,
      jan_qty: "",
      feb_qty: "",
      mar_qty: "",
      apr_qty: "",
      may_qty: "",
      jun_qty: "",
      jul_qty: "",
      aug_qty: "",
      sep_qty: "",
      oct_qty: "",
      nov_qty: "",
      dec_qty: "",
      remarks: "",
      year: "",
      // year_range:[],
      jan_for_edit:0,
      feb_for_edit:0,
      mar_for_edit:0,
      apr_for_edit:0,
      may_for_edit:0,
      jun_for_edit:0,
      jul_for_edit:0,
      aug_for_edit:0,
      sep_for_edit:0,
      oct_for_edit:0,
      nov_for_edit:0,
      dec_for_edit:0,
      jan_price:0,
      feb_price:0,
      mar_price:0,
      apr_price:0,
      may_price:0,
      jun_price:0,
      jul_price:0,
      aug_price:0,
      sep_price:0,
      oct_price:0,
      nov_price:0,
      dec_price:0,
      for_edit:0,
      for_update_price:0,

      stepper: ["Item Details", "Target Months", "Selecting Funds"],

      // modes
      modes: [],
      // parent
      parent_modes: [],
      mode_parent_id: 0,
      procurement_mode: "",
      // category
      category_modes: [],
      mode_category_id: 0,
      procurement_subMode: "",
      // sub-category
      sub_category_modes: [],
      mode_sub_category_id: 0,
      procurement_subMode_child: "",
      //prefered	item description
      prefered_description: "",

      mode_proc: "",
      ppmp_item_id: 0,
      ppmp_id: 0,
      item_id: 0,
      unit: "",

      // catering data
      activities: [],
      activity_type_id: 0,
      // year: "",
      year_range: [],
      selected_activity_id: 0,
      office_id: 0,
      // activity details
      selected_activity: {},
      //year trigger and data for monthly control
      monthly_closing: [],
      monthly_closing_year: "",
      mode_id: 0,
      mode: "",
      is_supplimental: false,
    };
    this.initRespoCenter = this.initRespoCenter.bind(this);
    this.initDefaultRespoValues = this.initDefaultRespoValues.bind(this);
    this.requestRespoCenterData = this.requestRespoCenterData.bind(this);
    this.requestSourceOfFund = this.requestSourceOfFund.bind(this);
    this.onHandleRespoCodeAutocomplete =
      this.onHandleRespoCodeAutocomplete.bind(this);
    this.onHandleSourceFundAutocomplete =
      this.onHandleSourceFundAutocomplete.bind(this);
    this.onHandleCheckboxChange = this.onHandleCheckboxChange.bind(this);
    this.onHandleSelectChange = this.onHandleSelectChange.bind(this);
    this.onClickAdvanceSearch = this.onClickAdvanceSearch.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
    this.requestFundList = this.requestFundList.bind(this);
    this.requestRAyear = this.requestRAyear.bind(this);
    this.accountCodeRenderOptions = this.accountCodeRenderOptions.bind(this);
    this.respoCodeRenderOptions = this.respoCodeRenderOptions.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.onClickNextStep = this.onClickNextStep.bind(this);
    this.onHandleTargetChange = this.onHandleTargetChange.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.addToCart_final = this.addToCart_final.bind(this);
    this.onHandleTextChange = this.onHandleTextChange.bind(this);
    this.requestGetProcModes = this.requestGetProcModes.bind(this);
    this.requestGetProcModesParent = this.requestGetProcModesParent.bind(this);
    this.requestGetProcModesCategory =
      this.requestGetProcModesCategory.bind(this);
    this.requestGetProcModesSubCategory =
      this.requestGetProcModesSubCategory.bind(this);
    this.resetState = this.resetState.bind(this);
    this.getYear = this.getYear.bind(this);
    this.requestGetOfficeActivities =
      this.requestGetOfficeActivities.bind(this);
    this.onChangeCateringYear = this.onChangeCateringYear.bind(this);
    this.onHandleActivityNameAutocomplete =
      this.onHandleActivityNameAutocomplete.bind(this);
    this.showActivityDetails = this.showActivityDetails.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.handleFundSourceAllocatedAmountChange =
      this.handleFundSourceAllocatedAmountChange.bind(this);
    this.calculateSum = this.calculateSum.bind(this);
    this.isSupplimentalByPass = this.isSupplimentalByPass.bind(this);
    this.updateState = this.updateState.bind(this);
    this.logOut = this.logOut.bind(this);
  }
  componentDidMount() {
    (this.props.ppmp.respoCenterCodeDatasource.length === 0 ||
      this.state.respoCodeValue === null) &&
      this.requestRespoCenterData();
  }

  logOut() {
    sessionService.deleteSession().then(() => {
      sessionService.deleteUser().then(() => {});
    });
  }

  isSupplimentalByPass = (month) => {
    if (this.props.ppmp.transactionData.parent_id > 0) {
      // console.log('$$-----------------------');
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // getMonth() returns month from 0 (January) to 11 (December)
      // Quarter calculation
      const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
      const monthQuarter = Math.floor((month - 1) / 3) + 1;
      // console.log('$$month', month);
      // console.log("$$monthQuarter", monthQuarter);
      // console.log("$$currentMonth", currentMonth);
      // console.log('$$currentQuarter', currentQuarter);

      if (currentQuarter === monthQuarter) {
        // console.log("$$bypass", month, monthQuarter);
        return true;
      }
    } else {
      return false;
    }
  };

  // Function to calculate the sum of a specific property in the array of objects
  calculateSum = (dataArray, propertyName) => {
    // Using the reduce function to accumulate the sum
    const sum = dataArray.reduce((accumulator, currentValue) => {
      // Checking if the property exists in the current object
      if (currentValue.hasOwnProperty(propertyName)) {
        return accumulator + currentValue[propertyName];
      } else {
        // Property doesn't exist, so return the accumulator unchanged
        return accumulator;
      }
    }, 0); // Initial value of the accumulator is set to 0

    return sum;
  };

  resetState() {
    this.setState({
      ...this.state,
      addItemModalVisible: false,
      dialogVisible: false,
      proceedAnyway: false,
      advanceSearchVisible: false,
      // fundSource: "",
      // fundSourceOpen: false,
      // respoCodeValue: null,
      // sourceFundList: [],
      // RA_year_choices: [],
      // respoAccount_choices: [],
      // fundTypeChoices: [],
      // legalBasisChoices: [],
      checkedRCA: false,
      invalidState: false,
      errorDialogVisible: false,
      errorDialogMessage: "",

      //default valuescc
      // IsNonOffice: false,
      // RA_year: "",
      // RA_desc: "",
      // fundType: "",
      // PPA_code: "",
      // respo_code: "",
      activeStep: 0,
      jan_qty: "",
      feb_qty: "",
      mar_qty: "",
      apr_qty: "",
      may_qty: "",
      jun_qty: "",
      jul_qty: "",
      aug_qty: "",
      sep_qty: "",
      oct_qty: "",
      nov_qty: "",
      dec_qty: "",
      remarks: "",

      stepper: ["Item Details", "Target Months", "Selecting Funds"],

      // parent

      // mode_parent_id: 0,
      // procurement_mode: "",

      // category

      // category_modes: [],
      // mode_category_id: 0,
      // procurement_subMode: "",

      // sub-category

      // sub_category_modes: [],
      // mode_sub_category_id: 0,
      // procurement_subMode_child: "",

      // catering data
      // activities: [],
      // activity_type_id: 0,
      selected_activity_id: 0,
    });
  }
  formatNumber(num) {
    if (num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
  addToCart_final() {
    // console.log("$$Save State", this.state.rows);
    if (this.state.rows?.length === 0) {
      this.setState({
        errorDialogVisible: false,
        activeStep: 2,
      });
    } else {
      const {
        procurement_mode,
        mode_parent_id,
        procurement_subMode,
        mode_category_id,
        procurement_subMode_child,
        mode_sub_category_id,

        IsNonOffice,
        fundType,
        RA_year,
        RA_desc,
        fundSource,
        jan_qty,
        feb_qty,
        mar_qty,
        apr_qty,
        may_qty,
        jun_qty,
        jul_qty,
        aug_qty,
        sep_qty,
        oct_qty,
        nov_qty,
        dec_qty,
        selected_activity_id,
        prefered_description,
        rows,

        item_id,
      } = this.state;
      const { ppmp, displayAlert, displayLoading } = this.props;

      // let item_is_catering = ppmp.suppliesData.category_name.indexOf("Catering") == -1 ? true : false;
      // console.log('$$item_is_catering', item_is_catering);

      let latest_price = parseFloat(
        ppmp.suppliesData._latest_price.replace(",", "")
      ).toFixed(2);
      let qty = [
        jan_qty,
        feb_qty,
        mar_qty,
        apr_qty,
        may_qty,
        jun_qty,
        jul_qty,
        aug_qty,
        sep_qty,
        oct_qty,
        nov_qty,
        dec_qty,
      ]
        .map((elt) => (elt ? parseFloat(elt) : 0))
        .reduce((a, b) => parseFloat(a) + parseFloat(b))
        .toFixed(2);
      let total_cost = (qty * latest_price).toFixed(2);
      this.setState({
        ...this.state,
        errorDialogVisible: false,
        proceedAnyway: false,
      });
      displayLoading(true);
      //update for save
      let mode_id =
        mode_sub_category_id > 0
          ? mode_sub_category_id
          : mode_category_id > 0
          ? mode_category_id
          : mode_parent_id > 0
          ? mode_parent_id
          : 0;
      let mode =
        mode_sub_category_id > 0
          ? procurement_subMode_child
          : mode_category_id > 0
          ? procurement_subMode
          : mode_parent_id > 0
          ? procurement_mode
          : "";
      if (mode_id > 0 && mode) {
        let to_save = false;
        // console.log('$$SavingStateData', this.state);
        // check if total amount from ppmp list + total cost < budget (per budget account)
        // filter ppmp list to get the total amount for this account
        // console.log('$$genPPMPDataSource', ppmp.genPPMPDataSource);
        // console.log('$$fundSource', fundSource);
        // filter allotment_class

        const fundSourceData = {
          allot_type: [],
          account_code: [],
          account_particulars: [],
          respo_code: [],
          respo_desc: [],
          ppa_code: [],
          ppa_desc: [],
          respo_desc2: [],
          respo_type: [],
          RA_year: [],
          RA_desc: [],
          fundSourceAllocatedAmount: [],
        };

        // Use reduce to concatenate fundSourceData from rows array
        rows.reduce((acc, row) => {
          const {
            allot_type,
            account_code,
            account_particulars,
            respo_code,
            respo_desc,
            ppa_code,
            ppa_desc,
            respo_desc2,
            respo_type,
            ra_year,
            ra_desc,
          } = Object.values(row)[0];
          let fundSourceAllocatedAmount = row.fundSourceAllocatedAmount
            .toString()
            .replace(/,/g, "");
          fundSourceAllocatedAmount = parseFloat(
            fundSourceAllocatedAmount
          ).toFixed(2);

          acc.allot_type.push(allot_type);
          acc.account_code.push(account_code);
          acc.account_particulars.push(account_particulars);
          acc.respo_code.push(respo_code);
          acc.respo_desc.push(respo_desc);
          acc.ppa_code.push(ppa_code);
          acc.ppa_desc.push(ppa_desc);
          acc.respo_desc2.push(respo_desc2);
          acc.respo_type.push(respo_type);
          acc.RA_year.push(ra_year);
          acc.RA_desc.push(ra_desc);
          acc.fundSourceAllocatedAmount.push(fundSourceAllocatedAmount);

          // console.log('$$fundSourceAllocatedAmount:', fundSourceAllocatedAmount);
          // console.log('$$Accumulator:', acc);
          return acc;
        }, fundSourceData);

        // console.log('$$fundSourceData: ', fundSourceData);
        let allotment_class_filtered = ppmp.genPPMPDataSource.filter(function (
          value
        ) {
          return value.allotment_class === fundSourceData.allot_type;
        });
        // console.log('$$allotment_class_filtered', allotment_class_filtered);
        if (allotment_class_filtered.length > 0) {
          //filter fund_source
          let fund_source_filtered = allotment_class_filtered[0].funds.filter(
            function (value) {
              return (
                value.fund_source ===
                `${fundSourceData.RA_desc.join(
                  ", "
                )} (${fundSourceData.account_particulars.join(", ")})`
              );
            }
          );
          // console.log('$$fund_source_filtered', fund_source_filtered);
          if (fund_source_filtered.length > 0) {
            //filter ppa_list
            let ppa_list_filtered = fund_source_filtered[0].ppa_list.filter(
              function (value) {
                return (
                  value.ppa_respo ===
                  `${fundSourceData.ppa_code.join(
                    ", "
                  )}-${fundSourceData.ppa_desc.join(", ")}`
                );
              }
            );
            // console.log('$$ppa_list_filtered', ppa_list_filtered);
            if (ppa_list_filtered.length > 0) {
              // get ppmp_list total amount:
              let ppmp_account_items = ppa_list_filtered[0].items;
              // console.log('$$ppmp_account_items', ppmp_account_items);
              let ppmp_account_total = 0;
              for (let i = 0; i < ppmp_account_items.length; i++) {
                if (
                  fundSourceData.fundSource.respo_code ==
                  ppmp_account_items[i].respo_code
                ) {
                  // console.log('$$loop_item_id', parseInt(ppmp_account_items[i].item_id));
                  if (
                    parseInt(ppmp_account_items[i].item_id) !==
                    parseInt(item_id)
                  ) {
                    ppmp_account_total += parseFloat(
                      ppmp_account_items[i].total_cost
                    );
                  }
                }
              }
              // console.log(
              //   "$$total_purchase",
              //   parseFloat(total_cost) + parseFloat(ppmp_account_total)
              // );
              // console.log("$$allot_balance", fundSource.allot_balance);
              let activeStep = 0;
              let total_purchase = parseFloat(
                parseFloat(total_cost) + parseFloat(ppmp_account_total)
              );
              // console.log("$$total_purchase", total_purchase);
              if (
                total_purchase <=
                parseFloat(fundSourceData.allot_balance.join(", "))
              ) {
                to_save = true;
              } else {
                to_save = false;
              }
            } else {
              to_save = true;
            }
          } else {
            to_save = true;
          }
        } else {
          to_save = true;
        }
        if (to_save) {
          // console.log("$$RA_year", RA_year);
          let fundSourceAllocated = parseFloat(
            fundSourceData.fundSourceAllocatedAmount
          );

          PPMPService.updateItem({
            ...this.state,
            ppmp_id: ppmp.transactionData.id,
            allotment_class: fundSourceData.allot_type.join(", "),
            account_code: fundSourceData.account_code.join(", "),
            account_code_description:
              fundSourceData.account_particulars.join(", "),
            respo_code: fundSourceData.respo_code.join(", "),
            respo_code_description: fundSourceData.respo_desc.join(", "),
            ppa_code: fundSourceData.ppa_code.join(", "),
            ppa_desc: fundSourceData.ppa_desc.join(", "),
            respo_desc2: fundSourceData.respo_desc2.join(", "),
            respo_type: fundSourceData.respo_type.join(", "),
            allocated_amount:
              fundSourceData.fundSourceAllocatedAmount.join(", "),
            procurement_mode: procurement_mode,
            year: fundSourceData.RA_year.join(", "),
            legal_basis: fundSourceData.RA_desc.join(", "),
            fundType: fundType,
            IsNonOffice: IsNonOffice,
            item_id: ppmp.suppliesData.id,
            unit: ppmp.suppliesData.unit,
            _latest_price: latest_price,
            total_cost,
            qty,
            //added
            jan_qty: jan_qty,
            feb_qty: feb_qty,
            mar_qty: mar_qty,
            apr_qty: apr_qty,
            may_qty: may_qty,
            jun_qty: jun_qty,
            jul_qty: jul_qty,
            aug_qty: aug_qty,
            sep_qty: sep_qty,
            oct_qty: oct_qty,
            nov_qty: nov_qty,
            dec_qty: dec_qty,
            mode,
            mode_id,
            id: 0,
            selected_activity_id,
            prefered_description,
            user_id: this.props.data.user.id,
            user_name: this.props.data.user.username,
            jan_price:latest_price,
            feb_price:latest_price,
            mar_price:latest_price,
            apr_price:latest_price,
            may_price:latest_price,
            jun_price:latest_price,
            jul_price:latest_price,
            aug_price:latest_price,
            sep_price:latest_price,
            oct_price:latest_price,
            nov_price:latest_price,
            dec_price:latest_price,
          })
            .then((response) => {
              // console.log("$$res", response?.data);
              if (response.error) {
                if (response.fundStatus[0]) {
                  this.setState({
                    ...this.state,
                    errorDialogVisible: true,
                    errorDialogMessage: `Cannot proceed! Total amount for this funds exceeded from 
                      Respo Code/s: 
                      ${response.fundStatus
                        .map((status) =>
                          status.type === "error"
                            ? ` ${status.account_code_description} with the alloted budget of ${status.alloted_amount} - ${status.status}`
                            : ""
                        )
                        .join("\n")}`,
                    activeStep: 2,
                    proceedAnyway: ppmp.transactionData.is_indicative > 0,
                  });
                } else {
                  displayAlert({
                    visible: true,
                    message: `Error saving item! ${response?.errmsg}`,
                    severity: "error",
                  });
                }
              } else {
                // console.log('$$_ret', response.data[0]);
                let item_tagged_accounts = response.data[0]?._tagged_accounts;
                if (response.data[0]._ret === "unauthorized_action") {
                  displayAlert({
                    visible: true,
                    message: "Unauthorized Access!",
                    severity: "error",
                  });
                  this.logOut();
                } else if (response.data[0]._ret === "invalid_account_code") {
                  // displayAlert({
                  //   visible: true,
                  //   message: "Invalid account code for the selected item, Please Select another account code!",
                  //   severity: "warning",
                  // });
                  displayLoading(false);
                  this.setState({
                    ...this.state,
                    errorDialogVisible: true,
                    errorDialogMessage: `Invalid account code for the selected item, please select the correct account code for this item! 
                      ${
                        item_tagged_accounts
                          ? `| Account(suggested) code tagged for this item: ${item_tagged_accounts}`
                          : `
                      | Possible cause:
                      1. This item not yet tagged by PBMO (Contact PBMO). 
                      2. Incorrect account code for this specific item (Select the correct account code).`
                      }`,
                    activeStep: 2,
                    proceedAnyway: 0,
                  });
                } else if (response.data[0]._ret === "budget_exceeded") {
                  displayLoading(false);
                  this.setState({
                    ...this.state,
                    errorDialogVisible: true,
                    errorDialogMessage: `Budget exceeded for the selected account code`,
                    activeStep: 2,
                    proceedAnyway: 0,
                  });
                } else {
                  displayAlert({
                    visible: true,
                    message: "Item added successfully!",
                    severity: "success",
                  });
                  this.closeModal();
                  this.resetState();
                }
              }
            })
            .catch((err) => {
              console.log("$$err_add", err);
              displayAlert({
                visible: true,
                message: "Oops! Error occured, please try again",
                severity: "warning",
              });
            });
          // <Alert variant="filled" severity="success">
          // 	Added Successfully!
          // </Alert>;
        } else {
          // console.log("$$not save!");
          this.setState({
            ...this.state,
            errorDialogVisible: true,
            errorDialogMessage:
              "Cannot proceed! Total amount for this funds exceeded!",
            activeStep: 2,
            proceedAnyway: ppmp.transactionData.is_indicative > 0,
          });
        }
      } else {
        displayAlert({
          alertVisible: true,
          alertMessage: "No mode of procurement selected!",
          alertSeverity: "warning",
        });
      }
      displayLoading(false);
    }
  }
  initRespoCenter() {
    const { ppmp, displayLoading } = this.props;
    const data = ppmp.suppliesData;
    // console.log("$$UNKNOWN DATA", data);
    // console.log('$$parent_id', this.props.ppmp.transactionData.parent_id);

    displayLoading(true);
    this.setState({
      ...this.state,
      activeStep: 0,
      is_supplimental: parseInt(this.props.ppmp.transactionData.parent_id) > 0,
    });
    if (this.state.respoCodeValue === null) {
      this.initDefaultRespoValues();
    } else {
      this.requestRAyear();
    }
    if (data.category_name.indexOf("Catering") !== -1) {
      this.setState({
        ...this.state,
        is_supplimental:
          parseInt(this.props.ppmp.transactionData.parent_id) > 0,
        stepper: [...this.state.stepper, "Catering Details"],
      });
    }
    displayLoading(false);
  }
  requestRespoCenterData() {
    const {
      data,
      displayAlert,
      displayLoading,
      setRespoCenterDataSource,
      setRespoDefaultValues,
      ppmpId,
    } = this.props;
    // console.log('$$props', this.props);
    displayLoading(true);

    if (ppmpId) {
      PPMPService.getRespoCenterData2(ppmpId)
        .then((res) => {
          const respoCenterData = res.data;
          // console.log('$$respoCenterData', respoCenterData);
          if (respoCenterData.length > 0) {
            setRespoCenterDataSource({ respoCenterData });
            const defval = data.user;
            const respoCodeValue = respoCenterData.filter((item) => {
              return (
                item.ppa_code === defval.fpp &&
                item.respo_code === defval.respo_center
              );
            });
            // console.log("$$respoCode", respoCodeValue);
            setRespoDefaultValues({ respoCodeValue: respoCodeValue[0] });
            this.setState({ ...this.state, respoCodeValue: respoCodeValue[0] });
            // console.log("$$respoCodeValue", respoCodeValue);
            displayLoading(false);
          }
        })
        .catch((err) => {
          displayAlert({
            alertVisible: true,
            alertMessage: err,
            alertSeverity: "warning",
          });
          displayLoading(false);
        });
    }
    //get modes
    this.requestGetProcModes();
    //get year
    this.getYear();
  }
  requestGetProcModes() {
    const { displayLoading, displayAlert } = this.props;
    displayLoading(true);
    let arr = {
      keyword: "",
      total: 0,
      page_no: 0,
      limit: 100,
      sort_column: "",
      sort_type: "",
      filter_business_personality: "",
      filter_nationality: "",
      filter_nature_of_operation: "",
    };
    // console.log(arr);
    ModesService.getPRModes(arr)
      .then((response) => {
        // console.log('$$response', response);
        // console.log('$$year', this.state.year);
        let data = response.data.result0;
        let parent_modes = data.filter((element) => 0 == element.parent_id);
        this.setState(
          {
            ...this.state,
            modes: data,
            parent_modes,
            monthly_closing: response.data.result4,
            monthly_closing_year: this.state.year,
          },
          () => {
            // console.log("$$monthly_closing", this.state.monthly_closing);
            // console.log('$$monthly_closing_year', this.state.monthly_closing_year);
          }
        );
        // console.log('$$modes', data);
        // console.log('$$parent_modes', parent_modes);
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }
  requestGetProcModesParent(parent_id) {
    // console.log('$$parent_id', parent_id);
    const { modes } = this.state;
    let data = modes;
    let category_modes = data.filter(
      (element) => element.parent_id == parent_id
    );
    let parent_name = data.filter((element) => element.id == parent_id)[0]
      .mode_name;
    // console.log('$$parent_name', parent_name);
    // console.log('$$category_modes', category_modes);
    if (category_modes) {
      this.setState({
        ...this.state,
        category_modes,
        mode_parent_id: parent_id,
        procurement_mode: parent_name,

        mode_category_id: 0,
        procurement_subMode: "",

        mode_sub_category_id: 0,
        procurement_subMode_child: "",
        sub_category_modes: [],
      });
    }
  }
  requestGetProcModesCategory(category_id) {
    // console.log('$$category_id', category_id);
    const { modes } = this.state;
    let data = modes;
    let sub_category_modes = data.filter(
      (element) => element.parent_id == category_id
    );
    let category_name = data.filter((element) => element.id == category_id)[0]
      .mode_name;
    // console.log("$$category_name", category_name);
    // console.log('$$sub_category_modes', sub_category_modes);
    this.setState({
      ...this.state,
      sub_category_modes,
      mode_category_id: category_id,
      procurement_subMode: category_name,

      mode_sub_category_id: 0,
      procurement_subMode_child: "",
    });
  }
  requestGetProcModesSubCategory(sub_category_id) {
    // console.log('$$sub_category_id', sub_category_id);
    const { modes } = this.state;
    let data = modes;
    let sub_category_name = data.filter(
      (element) => element.id == sub_category_id
    )[0].mode_name;
    // console.log('$$sub_category_name', sub_category_name);
    this.setState({
      ...this.state,

      mode_sub_category_id: sub_category_id,
      procurement_subMode_child: sub_category_name,
    });
  }
  requestRAyear() {
    const { displayAlert, displayLoading, setRespoDefaultValues, ppmp } =
      this.props;
    const { respoCodeValue } = this.state;
    if (!respoCodeValue || !respoCodeValue.respo_code) {
      // Handle the case where respoCodeValue is not available or does not have respo_code
      // console.log("$$respo", respoCodeValue);
      return;
    }
    // console.log("$$respo", respoCodeValue);
    // console.log("$$PPMP ID:", ppmp);
    displayLoading(true);
    PPMPService.getRAyear({
      respo_code: respoCodeValue.respo_code,
      ppmp_id: ppmp.transactionData.id,
    })
      .then((res) => {
        var data = res.data;
        if (typeof data === "string") {
          this.setState(
            { ...this.state, RA_year: "", RA_year_choices: [{ ra_year: "" }] },
            () => this.requestFundList()
          );
          displayLoading(false);
        } else {
          if (data.length > 0) {
            const RA_year = data[0].ra_year;
            setRespoDefaultValues({ RA_year, IsNonOffice: false });
            this.setState(
              { ...this.state, RA_year, RA_year_choices: data },
              () => this.requestFundList()
            );
          }
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }
  requestFundList() {
    const { displayAlert, setRespoDefaultValues, displayLoading, ppmp } =
      this.props;
    const { RA_year, respoCodeValue } = this.state;

    displayLoading(true);
    PPMPService.getAccountCodePerRespo({
      respo_code: respoCodeValue.respo_code,
      year: RA_year,
      ppmp_id: ppmp.transactionData.id,
    })
      .then((res) => {
        var data = res.data;
        if (typeof data === "string") {
          this.setState({
            ...this.state,
            RA_desc: "",
            fundType: "",
            PPA_code: "",
          });
          displayLoading(false);
        } else {
          if (data.length > 0) {
            // console.log('$$FirstState', this.state);

            let dataFiltered = data;

            // let dataFiltered = data.filter((item) => {
            //   if (IsNonOffice) {
            //     return (
            //       item.ppa_code === ppmp?.respoDefaultParams?.respoCodeValue?.ppa_code
            //     )
            //   } else {
            //     return (
            //       item.ppa_code !== ppmp?.respoDefaultParams?.respoCodeValue?.ppa_code
            //     )
            //   }
            // });
            // console.log('$$State', this.state);
            // console.log('$$RespoDefault', ppmp.respoDefaultParams.respoCodeValue.ppa_code)
            // console.log('$$dataFiltered', dataFiltered);
            var _entryData = dataFiltered[0];
            // console.log('$$_entryData', _entryData);
            setRespoDefaultValues({
              RA_desc: _entryData.ra_desc,
              fundType: _entryData.fund_type,
              PPA_code: _entryData.ppa_code,
            });
            this.setState(
              {
                ...this.state,
                RA_desc: _entryData.ra_desc,
                fundType: _entryData.fund_type,
                PPA_code: _entryData.ppa_code,
                respoAccount_choices: dataFiltered,
                legalBasisChoices: [
                  ...new Set(dataFiltered.map((e, i) => e.ra_desc)),
                ],
                fundTypeChoices: [
                  ...new Set(dataFiltered.map((e, i) => e.fund_type)),
                ],
              },
              () => {
                this.state.sourceFundList.length === 0
                  ? this.requestSourceOfFund()
                  : displayLoading(false);
              }
            );
          }
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }

  // For Catering Details
  getYear() {
    const { data } = this.props;
    let currDate = new Date();
    let currYear = parseInt(currDate.getFullYear());
    //get year +1 & -2
    let yearRange = [currYear - 2, currYear - 1, currYear, currYear + 1];

    sessionService
      .loadSession()
      .then((currentSession) => {
        const data2 = currentSession.selectedTransaction;
        this.setState(
          {
            ...this.state,
            year: data2.year,
            year_range: yearRange,
            office_id: parseInt(data.user.office_id),
          },
          () => {
            this.onChangeCateringYear(currYear);
          }
        );
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "warning",
        });
      });
  }
  onChangeCateringYear(year) {
    // console.log("$$year", year);
    const { displayLoading, displayAlert } = this.props;
    displayLoading(true);
    ActivitiesService.getOfficeActivities({ ...this.state })
      .then((response) => {
        // console.log("$$response", response);
        if (!response.error) {
          this.setState(
            {
              ...this.state,
              activities: response.data,
              selected_activity_id: 0,
            },
            () => {
              // console.log("$$activities", response.data);
            }
          );
        } else {
          this.setState({
            ...this.state,
            activities: [],
            selected_activity_id: 0,
          });
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }
  requestGetOfficeActivities() {
    const { displayLoading, displayAlert } = this.props;
    displayLoading(true);
    ActivitiesService.getOfficeActivities({ ...this.state })
      .then((response) => {
        // console.log("$$response", response);
        if (!response.error) {
          this.setState(
            {
              ...this.state,
              activities: response.data,
            },
            () => {
              // console.log("$$activities", response.data);
            }
          );
        } else {
          this.setState({
            ...this.state,
            activities: [],
          });
        }
        displayLoading(false);
        this.generateChip();
      })
      .catch((err) => {
        displayLoading(false);
        this.generateChip();
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }
  onSelectHandler(e) {
    this.setState(
      {
        ...this.state,
        year: e.target.value,
      },
      () => {
        this.requestGetOfficeActivities();
      }
    );
  }
  requestSourceOfFund() {
    const { displayAlert, displayLoading, ppmp } = this.props;
    const { respoCodeValue } = this.state;
    // console.log('$$StateAfterSearch', this.state.IsNonOffice);

    // let supplyPrice = parseFloat(ppmp.suppliesData._latest_price);

    let sp_used = this.state.IsNonOffice
      ? "sp_account_get_fundsource_nonoffice3"
      : "sp_account_get_fundsource3";
    displayLoading(true);
    if (respoCodeValue) {
      PPMPService.getSourceOfFundData({
        ...this.state,
        respo_code: respoCodeValue.respo_code,
        sp_used,
        ppmp_id: ppmp.transactionData.id,
      })
        .then((res) => {
          if (typeof res.data === "string") {
            this.setState({
              ...this.state,
              sourceFundList: [],
              fundSource: null,
            });
          } else {
            this.setState(
              {
                ...this.state,
                sourceFundList: res.data,
                fundSource: null,
              },
              () => {
                let sourceFundList = res.data;
                const data = ppmp.suppliesData;
                if (data.ppmp_item_id > 0) {
                  // continue code_here	account_code_description == account_particulars
                  let fundSource = sourceFundList.filter(
                    (element) =>
                      element.account_particulars ==
                      data.account_code_description
                  )[0];
                  // console.log('$$fundSource', fundSource);
                  this.setState({
                    ...this.state,
                    // funds
                    fundSource,
                  });
                  // console.log('$$state', this.state);
                }
              }
            );
            displayLoading(false);
            // // for filtering
            // if (supplyPrice < 50000.00) {
            // 	let filteredFunds = [];
            // 	for (let i = 0; i < res.data.length; i++) {
            // 		if (
            // 			res.data[i].allot_type ===
            // 			"Maintenance and Other Operating Expenses"
            // 		) {
            // 			filteredFunds = [...filteredFunds, res.data[i]];
            // 		}
            // 	}
            // 	// console.log("$$filteredFunds", filteredFunds);
            // 	this.setState(
            // 		{
            // 			...this.state,
            // 			sourceFundList: filteredFunds,
            // 			fundSource: null,
            // 		},
            // 		() => {
            // 			let sourceFundList = filteredFunds;
            // 			const data = ppmp.suppliesData;
            // 			if (data.ppmp_item_id > 0) {
            // 				// continue code_here	account_code_description == account_particulars
            // 				let fundSource = sourceFundList.filter(
            // 					(element) =>
            // 						element.account_particulars ==
            // 						data.account_code_description
            // 				)[0];
            // 				// console.log('$$fundSource', fundSource);
            // 				this.setState({
            // 					...this.state,
            // 					// funds
            // 					fundSource,
            // 				});
            // 				// console.log('$$state', this.state);
            // 			}
            // 		}
            //   );
            // 	displayLoading(false);
            // } else {
            // 	let filteredFunds2 = [];
            // 	for (let i = 0; i < res.data.length; i++) {
            // 		if (res.data[i].allot_type === "Capital Outlay") {
            // 			filteredFunds2 = [...filteredFunds2, res.data[i]];
            // 		}
            // 	}
            // 	this.setState(
            // 		{
            // 			...this.state,
            // 			sourceFundList: filteredFunds2,
            // 			fundSource: null,
            // 		},
            // 		() => {
            // 			let sourceFundList = filteredFunds2;
            // 			const data = ppmp.suppliesData;
            // 			if (data.ppmp_item_id > 0) {
            // 				// continue code_here	account_code_description == account_particulars
            // 				let fundSource = sourceFundList.filter(
            // 					(element) =>
            // 						element.account_particulars ==
            // 						data.account_code_description
            // 				)[0];
            // 				// console.log('$$fundSource', fundSource);
            // 				this.setState({
            // 					...this.state,
            // 					// funds
            // 					fundSource,
            // 				});
            // 				// console.log('$$state', this.state);
            // 			}
            // 		}
            //   );
            // 	displayLoading(false);
            // }
          }
        })
        .catch((err) => {
          displayAlert({
            alertVisible: true,
            alertMessage: err,
            alertSeverity: "warning",
          });
        });
    } else {
      // do nothing here
    }
    displayLoading(false);
  }
  onHandleRespoCodeAutocomplete(event, value) {
    this.setState({ ...this.state, respoCodeValue: value }, () => {
      if (value) {
        this.setState({ ...this.state, IsNonOffice: false });
        this.props.displayLoading(true);
        this.requestRAyear();
      }
    });
  }
  addRow = () => {
    const { rows } = this.state;
    this.setState({
      rows: [...rows, { fundSource: "" }],
    });
  };

  removeRow = (index) => {
    const { rows } = this.state;
    if (rows.length > 1) {
      const updatedRows = [...rows.slice(0, index), ...rows.slice(index + 1)];
      this.setState({ rows: updatedRows });
    }
  };
  handleFundSourceAllocatedAmountChange = (event, index) => {
    const { rows } = this.state;
    let value = event.target.value.replace(/,/g, ""); // Remove existing commas

    // Check if the input is empty and set the value to 0
    if (value === "") {
      value = "0";
    }

    // Allow only numbers and up to 2 decimal places
    if (!isNaN(value) && /^(\d+(\.\d{0,2})?)?$/.test(value)) {
      if (value.includes(".")) {
        // Format only the integer part for commas
        let parts = value.split(".");
        parts[0] = parseFloat(parts[0]).toLocaleString("en-US");
        value = parts.join(".");
      } else {
        value = parseFloat(value).toLocaleString("en-US");
      }

      const updatedRows = [...rows];
      updatedRows[index] = {
        ...updatedRows[index],
        fundSourceAllocatedAmount: value,
      };
      this.setState({ rows: updatedRows });
    }
  };
  // onHandleSourceFundAutocomplete(event, value) {
  //   this.setState({ ...this.state, fundSource: value });
  // }
  onHandleSourceFundAutocomplete = (event, value, index) => {
    const { rows } = this.state;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], fundSource: value };
    this.setState({ rows: updatedRows });
  };
  // For Activity Autocomplete
  onHandleActivityNameAutocomplete(event, value) {
    this.setState({ ...this.state, selected_activity_id: value });
  }
  onHandleTargetChange(event) {
    const regex = /^[0-9\b]+$/;
    var value = event.target.value;
    // if (event.target.value === "" || regex.test(e.target.value))
    if (!isNaN(value)) {
      if (event.target.value === "" || regex.test(event.target.value)) {
        this.setState({ ...this.state, [event.target.name]: value });
      }
    }
  }
  onHandleTextChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }
  onHandleSelectChange(event) {
    // console.log('$$event', event);
    this.props.displayLoading(true);
    this.setState(
      { ...this.state, [event.target.name]: event.target.value },
      () => {
        if (event.target.name === "RA_year") {
          this.requestFundList();
        } else if (event.target.name === "procurement_mode") {
          let parent_id = event.target.value;
          this.requestGetProcModesParent(parent_id);
        } else if (event.target.name === "procurement_category") {
          let category_id = event.target.value;
          this.requestGetProcModesCategory(category_id);
        } else if (event.target.name === "sub_category") {
          let sub_category_id = event.target.value;
          this.requestGetProcModesSubCategory(sub_category_id);
        } else if (event.target.name === "year") {
          let selected_activity_year = event.target.value;
          this.onChangeCateringYear(selected_activity_year);
        } else if (event.target.name === "selected_activity_id") {
          let selected_activity_id = event.target.value;
          this.showActivityDetails(selected_activity_id);
        } else {
          // this.requestSourceOfFund();
        }
      }
    );
    this.props.displayLoading(false);
  }
  showActivityDetails(activity_id) {
    // console.log('$$activity_id', activity_id);
    const { activities } = this.state;
    if (activity_id > 0) {
      let activity_details = activities.filter(
        (element) => element.id == activity_id
      )[0];
      // console.log('$$activity_details', activity_details);
      this.setState({
        ...this.state,
        selected_activity: activity_details,
      });
    }
  }
  onHandleCheckboxChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
    if (event.target.name === "checkedRCA") {
      if (!event.target.checked) {
        this.initDefaultRespoValues();
      }
    }
  }
  initDefaultRespoValues() {
    const { ppmp } = this.props;
    const value = ppmp.respoDefaultParams;
    this.setState(
      {
        ...this.state,
        IsNonOffice: false,
        respoCodeValue: value.respoCodeValue,
      },
      () => {
        // this.initRespoCenter();
      }
    );
  }
  onClickAdvanceSearch(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      advanceSearchVisible: !this.state.advanceSearchVisible,
    });
  }
  closeModal() {
    this.props.displaySupplies({ modalVisible: false, suppliesData: {} });
    let currUrl = window.location;
    if (!currUrl.href.includes("ppmplist")) {
      this.props.requestGeneratedPPMP();
      this.resetState();
    }
  }
  accountCodeRenderOptions(option) {
    return (
      <div className={this.props.classes.OptionContainer}>
        <Material.Typography variant="subtitle1">
          {option.account_particulars}
        </Material.Typography>
        <div>
          <div className="inline padded">
            <Material.Typography color="primary" variant="caption">
              FPP:{" "}
            </Material.Typography>
            <Material.Typography variant="caption">
              {option.ppa_code} {option.ppa_desc}
            </Material.Typography>
          </div>
          <div className="inline padded">
            <Material.Typography color="primary" variant="caption">
              Legal Basis:{" "}
            </Material.Typography>
            <Material.Typography variant="caption">
              {option.ra_desc}
            </Material.Typography>
          </div>
        </div>
      </div>
    );
  }
  respoCodeRenderOptions(option) {
    return (
      <div className={this.props.classes.OptionContainer}>
        <Material.Typography variant="subtitle1">
          {option.respo_code}. {option.office_name}
        </Material.Typography>
        <div>
          <div className="inline padded">
            <Material.Typography color="primary" variant="caption">
              Respo Type:{" "}
            </Material.Typography>
            <Material.Typography variant="caption">
              {option.respo_type}
            </Material.Typography>
          </div>
          <div className="inline padded">
            <Material.Typography color="primary" variant="caption">
              FPP:{" "}
            </Material.Typography>
            <Material.Typography variant="caption">
              {option.ppa_code}: {option.ppa_desc}
            </Material.Typography>
          </div>
        </div>
      </div>
    );
  }

  getStepContent(stepIndex) {
    const { ppmp, classes } = this.props;
    // const { parent_modes, category_modes, sub_category_modes } = this.state;
    const { activities, selected_activity_id } = this.state;
    switch (stepIndex) {
      case 0:
        const data = ppmp.suppliesData;
        const { parent_modes, category_modes, sub_category_modes } = this.state;
        return (
          <div>
            <div className="title">
              <Material.Typography variant="subtitle1">
                Item Details
              </Material.Typography>
            </div>
            <Material.Divider></Material.Divider>
            <Material.Grid container spacing={3}>
              <Material.Grid item xs={7}>
                <div className="inline">
                  <Material.Typography color="primary" variant="subtitle1">
                    Category:
                  </Material.Typography>
                  <Material.Typography variant="subtitle1">
                    {data.category_name}
                  </Material.Typography>
                </div>
                <div className="inline">
                  <Material.Typography color="primary" variant="subtitle1">
                    Unit:
                  </Material.Typography>
                  <Material.Typography variant="subtitle1">
                    {data.unit}
                  </Material.Typography>
                </div>
                <div className="inline">
                  <Material.Typography color="primary" variant="subtitle1">
                    Serial No:
                  </Material.Typography>
                  <Material.Typography variant="subtitle1">
                    {data.serial_no}
                  </Material.Typography>
                </div>
              </Material.Grid>
              <Material.Grid item xs={5}>
                <div classname="inline"> </div>
                <div className="inline">
                  <Material.Typography color="primary" variant="subtitle1">
                    Price:
                  </Material.Typography>
                  <Material.Typography variant="subtitle1">
                    Php {data._latest_price}
                  </Material.Typography>
                </div>
              </Material.Grid>
            </Material.Grid>
            <div className="title">
              <Material.Typography variant="subtitle1">
                Procurement Mode
              </Material.Typography>
            </div>
            <Material.Divider></Material.Divider>
            <div className="full-width-choices">
              {
                <Material.FormControl className={classes.formControlMax}>
                  <Material.InputLabel id="procurement-mode-label">
                    Mode of Procurement *
                  </Material.InputLabel>
                  <Material.Select
                    labelId="procurement-mode-select"
                    id="procurement-mode-select"
                    name="procurement_mode"
                    value={this.state.mode_parent_id}
                    onChange={this.onHandleSelectChange}
                  >
                    {/* <Material.Typography variant="h6">
											Public Bidding
										</Material.Typography> */}
                    {parent_modes.map((item, index) => {
                      return (
                        <Material.MenuItem
                          key={`procurement-mode-item-${index}`}
                          value={item.id}
                        >
                          {item.mode_name}
                        </Material.MenuItem>
                      );
                    })}
                  </Material.Select>
                </Material.FormControl>
              }

              {this.state.category_modes.length > 0 && (
                <Material.FormControl className={classes.formControlMax}>
                  <Material.InputLabel id="procurement-subMode-label">
                    Sub category *
                  </Material.InputLabel>
                  <Material.Select
                    labelId="procurement-subMode-select"
                    id="procurement-subMode-select"
                    name="procurement_category"
                    value={this.state.mode_category_id}
                    onChange={this.onHandleSelectChange}
                  >
                    {category_modes.map((item, index) => {
                      return (
                        <Material.MenuItem
                          key={`procurement-category-item-${index}`}
                          value={item.id}
                        >
                          {item.mode_name}
                        </Material.MenuItem>
                      );
                    })}
                  </Material.Select>
                </Material.FormControl>
              )}

              {this.state.sub_category_modes.length > 0 &&
                this.state.category_modes.length > 0 && (
                  <Material.FormControl className={classes.formControlMax}>
                    <Material.InputLabel id="procurement-subMode-child-label">
                      Sub category *
                    </Material.InputLabel>
                    <Material.Select
                      labelId="procurement-subMode-child-select"
                      id="procurement-subMode-child-select"
                      name="sub_category"
                      value={this.state.mode_sub_category_id}
                      onChange={this.onHandleSelectChange}
                    >
                      {sub_category_modes.map((item, index) => {
                        return (
                          <Material.MenuItem
                            key={`procurement-subMode-item-${index}`}
                            value={item.id}
                          >
                            {item.mode_name}
                          </Material.MenuItem>
                        );
                      })}
                    </Material.Select>
                  </Material.FormControl>
                )}

              {this.state.category_modes.length > 0 && (
                <Material.FormControl className={classes.formControlMax}>
                  <Material.TextField
                    id="prefered_description"
                    name="prefered_description"
                    onChange={this.onHandleTextChange}
                    label="Prefered Item Description/Brand/Specification/Remarks *"
                    multiline
                    value={this.state.prefered_description}
                  />
                </Material.FormControl>
              )}
            </div>
          </div>
        );
      case 1:
        const forToolTip = ppmp.suppliesData;

        return (
          <div>
            <Material.Grid>
              <div className="title">
                <Material.Typography variant="subtitle1">
                  Target of Procurement
                </Material.Typography>
              </div>
              <Material.Divider></Material.Divider>

              <Material.Grid container spacing={3}>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(1)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 1 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    onChange={this.onHandleTargetChange}
                    id="formatted-jan_qty-input"
                    name="jan_qty"
                    label="January"
                    type="number"
                    value={this.state.jan_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(2)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 2 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-feb_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="feb_qty"
                    label="February"
                    type="number"
                    value={this.state.feb_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(3)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 3 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-mar_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="mar_qty"
                    label="March"
                    type="number"
                    value={this.state.mar_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(4)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 4 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-apr_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="apr_qty"
                    label="April"
                    type="number"
                    value={this.state.apr_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(5)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 5 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-may_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="may_qty"
                    label="May"
                    type="number"
                    value={this.state.may_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(6)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 6 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-jun_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="jun_qty"
                    label="June"
                    type="number"
                    value={this.state.jun_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(7)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 7 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-jul_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="jul_qty"
                    label="July"
                    type="number"
                    value={this.state.jul_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(8)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 8 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-aug_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="aug_qty"
                    label="August"
                    type="number"
                    value={this.state.aug_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(9)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 9 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-sep_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="sep_qty"
                    label="September"
                    type="number"
                    value={this.state.sep_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(10)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 10 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-oct_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="oct_qty"
                    label="October"
                    type="number"
                    value={this.state.oct_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(11)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 11 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-nov_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="nov_qty"
                    label="November"
                    type="number"
                    value={this.state.nov_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    disabled={
                      parseInt(ppmp.transactionData?.is_indicative) > 0
                        ? false
                        : this.state.is_supplimental
                        ? !this.isSupplimentalByPass(12)
                        : parseInt(this.props.isUnforeseen) === 1
                        ? false // exclude unforeseen and emergency
                        : !(
                            parseInt(
                              this.state.monthly_closing.filter(
                                (value) =>
                                  parseInt(value.month) === 12 &&
                                  parseInt(value.year) ===
                                    parseInt(this.state.monthly_closing_year)
                              )[0]?.is_open
                            ) === 1
                          )
                    }
                    id="formatted-dec_qty-input"
                    onChange={this.onHandleTargetChange}
                    name="dec_qty"
                    label="December"
                    type="number"
                    value={this.state.dec_qty}
                    helperText={this.props.ppmp.suppliesData.unit}
                  />
                </Material.Grid>
              </Material.Grid>
            </Material.Grid>
          </div>
        );
      case 2:
        const {
          advanceSearchVisible,
          sourceFundList,
          fundSource,
          checkedRCA,
          legalBasisChoices,
          fundTypeChoices,
          jan_qty,
          feb_qty,
          mar_qty,
          apr_qty,
          may_qty,
          jun_qty,
          jul_qty,
          aug_qty,
          sep_qty,
          oct_qty,
          nov_qty,
          dec_qty,
          rows,
        } = this.state;
        let latest_price = parseFloat(
          ppmp.suppliesData?._latest_price?.replace(",", "")
        ).toFixed(2);
        let qty = [
          jan_qty,
          feb_qty,
          mar_qty,
          apr_qty,
          may_qty,
          jun_qty,
          jul_qty,
          aug_qty,
          sep_qty,
          oct_qty,
          nov_qty,
          dec_qty,
        ]
          .map((elt) => (elt ? parseFloat(elt) : 0))
          .reduce((a, b) => parseFloat(a) + parseFloat(b))
          .toFixed(2);
        let total_cost = (qty * latest_price).toFixed(2);
        // console.log("$$uiTotal_cost", total_cost);
        return (
          <div>
            <div className="title">
              <Material.Grid container spacing={3}>
                <Material.Grid item xs={6}>
                  <Material.Typography variant="subtitle1">
                    Funds (WFP)
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Total Amount: ₱{total_cost}
                  </Material.Typography>
                </Material.Grid>
              </Material.Grid>
            </div>
            <Material.Divider></Material.Divider>
            <Material.Grid container spacing={3}>
              <Material.Grid item xs={12}>
                <Material.Link
                  href=""
                  style={{ textAlign: "right" }}
                  onClick={this.onClickAdvanceSearch}
                >
                  {" "}
                  {advanceSearchVisible ? "Hide" : "Show"} Advance Search{" "}
                </Material.Link>
                <Material.Collapse
                  className={classes.collapseExpand}
                  in={advanceSearchVisible}
                >
                  <Material.Paper
                    elevation={1}
                    className={classes.paperAdvance}
                  >
                    <div className="full-width-choices">
                      <Material.Tooltip
                        title="Get Fund source from other Respo Center"
                        arrow
                      >
                        <Material.FormControlLabel
                          control={
                            <Material.Checkbox
                              checked={checkedRCA}
                              onChange={this.onHandleCheckboxChange}
                              name="checkedRCA"
                              color="primary"
                            />
                          }
                          label="Respo Center"
                        />
                      </Material.Tooltip>
                      <Material.Tooltip
                        title="Respo Center needs approval when using other funds"
                        arrow
                      >
                        <Autocomplete
                          onChange={this.onHandleRespoCodeAutocomplete}
                          id="respo-code-select"
                          autoHighlight
                          disabled={!checkedRCA}
                          options={ppmp.respoCenterCodeDatasource}
                          getOptionLabel={(option) =>
                            `${option.respo_code}. ${option.office_name}`
                          }
                          filterOptions={this.filterOptions}
                          getOptionSelected={(option, value) =>
                            option.ppa_code === value.ppa_code
                          }
                          value={this.state.respoCodeValue}
                          renderOption={this.respoCodeRenderOptions}
                          renderInput={(params) => (
                            <Material.TextField
                              {...params}
                              color="primary"
                              label="Fund Source *"
                              margin="normal"
                            />
                          )}
                          disableListWrap
                        />
                      </Material.Tooltip>
                      <div>
                        <Material.FormControl className={classes.formControl}>
                          <Material.InputLabel id="RA-year-select-label">
                            Year *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="RA-year-select"
                            id="RA-year-select"
                            name="RA_year"
                            value={this.state.RA_year}
                            onChange={this.onHandleSelectChange}
                          >
                            {this.state.RA_year_choices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`ra-year-item-${index}`}
                                  value={item.ra_year}
                                >
                                  {item.ra_year}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                        <Material.FormControl
                          display="inline"
                          className={classes.formControl}
                        >
                          <Material.InputLabel id="legal-basis-select-label">
                            Legal Basis *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="legal-basis-select"
                            id="legal-basis-select"
                            name="RA_desc"
                            value={this.state.RA_desc}
                            onChange={this.onHandleSelectChange}
                          >
                            {legalBasisChoices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`legal_basis-item-${index}`}
                                  value={item}
                                >
                                  {item}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                      </div>
                      <div style={{ display: "inline" }}>
                        <Material.FormControl className={classes.formControl}>
                          <Material.InputLabel id="fund-type-select-label">
                            Fund Type *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="fund-type-select"
                            id="fund-type-select"
                            name="fundType"
                            value={this.state.fundType}
                            onChange={this.onHandleSelectChange}
                          >
                            {fundTypeChoices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`fund-type-item-${index}`}
                                  value={item}
                                >
                                  {item}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                        <Material.FormControl className={classes.formControl}>
                          {/* <Material.InputLabel id="fund-type-select-label">Non-Office</Material.InputLabel> */}
                          <Material.FormControlLabel
                            control={
                              <Material.Checkbox
                                checked={this.state.IsNonOffice}
                                onChange={this.onHandleCheckboxChange}
                                name="IsNonOffice"
                                color="primary"
                              />
                            }
                            label="Non-Office"
                          />
                        </Material.FormControl>
                      </div>
                    </div>
                    <Material.Button
                      className={classes.action}
                      variant="outlined"
                      color="primary"
                      onClick={this.requestSourceOfFund}
                    >
                      {" "}
                      Search{" "}
                    </Material.Button>
                  </Material.Paper>
                </Material.Collapse>
                {/* <div className="full-width-choices">
                  <Autocomplete
                    onChange={this.onHandleSourceFundAutocomplete}
                    id="fund-source-select"
                    autoHighlight
                    className={classes.autocompleteRoot}
                    options={sourceFundList}
                    getOptionLabel={(option) => option.account_particulars}
                    renderOption={this.accountCodeRenderOptions}
                    getOptionSelected={(option, value) =>
                      option.unique_code === value.unique_code
                    }
                    value={this.state.fundSource}
                    filterOptions={this.filterOptions}
                    renderInput={(params) => (
                      <Material.TextField
                        {...params}
                        name="fundSource"
                        color="primary"
                        label="Account Code *"
                        margin="normal"
                      />
                    )}
                  />
                  <Material.Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ color: "rgba(0, 0, 0, 0.54)", float: "right" }}
                  >
                    <i>
                      {sourceFundList.length} Account{" "}
                      {sourceFundList.length > 1 ? "codes" : "code"} found
                    </i>
                  </Material.Typography>
                </div> */}
                {rows.map((row, index) => {
                  // console.log("$$Row:", row);
                  return (
                    <div className="full-width-choices" key={index}>
                      <Autocomplete
                        onChange={(e, value) =>
                          this.onHandleSourceFundAutocomplete(e, value, index)
                        }
                        id={`fund-source-select-${index}`}
                        autoHighlight
                        className={classes.autocompleteRoot}
                        options={sourceFundList}
                        getOptionLabel={(option) => option.account_particulars}
                        renderOption={this.accountCodeRenderOptions}
                        value={row.fundSource}
                        filterOptions={this.filterOptions}
                        renderInput={(params) => (
                          <Material.TextField
                            {...params}
                            name="fundSource"
                            color="primary"
                            label="Reselect/ Select New Account Code *"
                            margin="normal"
                          />
                        )}
                      />
                      <Material.Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ color: "rgba(0, 0, 0, 0.54)", float: "right" }}
                      >
                        <i>
                          {sourceFundList.length} Account{" "}
                          {sourceFundList.length > 1 ? "codes" : "code"} found
                        </i>
                      </Material.Typography>
                      <Material.FormControl
                        fullWidth
                        sx={{ m: 1 }}
                        variant="standard"
                      >
                        <Material.InputLabel
                          htmlFor={`standard-adornment-amount-${index}`}
                        >
                          Allocated Amount
                        </Material.InputLabel>
                        <Material.Input
                          id={`standard-adornment-amount-${index}`}
                          value={row.fundSourceAllocatedAmount}
                          onChange={(e) =>
                            this.handleFundSourceAllocatedAmountChange(e, index)
                          }
                          startAdornment={
                            <Material.InputAdornment position="start">
                              ₱
                            </Material.InputAdornment>
                          }
                        />
                      </Material.FormControl>

                      {rows.length > 1 ? (
                        <Material.Button
                          variant="text"
                          color="secondary"
                          onClick={() => this.removeRow(index)}
                          style={{ float: "right" }}
                        >
                          - Remove Account Code
                        </Material.Button>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </Material.Grid>
              <Material.Grid>
                <Material.Button
                  variant="text"
                  color="primary"
                  onClick={this.addRow}
                >
                  + Add Account Code
                </Material.Button>
              </Material.Grid>
              <Material.Grid
                style={{ paddingTop: 15, paddingLeft: 7 }}
                container
                spacing={2}
              >
                {rows.map((row, index) => (
                  <React.Fragment key={index}>
                    <Material.Grid style={{ paddingLeft: 9 }} item xs={6}>
                      <Material.Collapse in={Boolean(row.fundSource)}>
                        <div>
                          <Material.Typography
                            style={{ paddingLeft: 5 }}
                            color="secondary"
                          >
                            Account Code {index + 1} Details
                          </Material.Typography>
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            F.P.P.:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.ppa_code}:{" "}
                              {row.fundSource.ppa_desc}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            RESPO:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.respo_desc}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </Material.Collapse>
                    </Material.Grid>
                    <Material.Grid style={{ paddingTop: 33 }} item xs={6}>
                      <Material.Collapse in={Boolean(row.fundSource)}>
                        <div paddingTop={5} className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Balance:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              Php {row.fundSource.allot_balance}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Legal Basis:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.ra_desc}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Year:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.ra_year}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Allotted Class:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.allot_type}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inline">
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Fund Type:
                          </Material.Typography>
                          {row.fundSource ? (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.fund_type}
                            </Material.Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </Material.Collapse>
                    </Material.Grid>
                  </React.Fragment>
                ))}
              </Material.Grid>
            </Material.Grid>
          </div>
        );
      case 3:
        const { stepper, year_range, selected_activity } = this.state;
        // const Activities = [
        // 	{ activity_type_id: 1, activity: "Year End" },
        // 	{ activity_type_id: 2, activity: "Mid Year" },
        // 	{ activity_type_id: 3, activity: "PGBh Family Day" },
        // ];
        if (stepper[3]) {
          return (
            <div>
              <div className="title">
                <Material.Typography variant="subtitle1">
                  Additional Catering Details
                </Material.Typography>
              </div>
              <Material.Divider></Material.Divider>
              <Material.FormControl
                className={classes.formControlMex}
                variant="standard"
              >
                <Material.InputLabel>Year of Activity</Material.InputLabel>
                <Material.Select
                  id="year-select"
                  value={this.state.year}
                  name="year"
                  onChange={this.onHandleSelectChange}
                  disabled
                >
                  {year_range.map((val, index) => (
                    <Material.MenuItem key={`${index}-option`} value={val}>
                      {val}
                    </Material.MenuItem>
                  ))}
                </Material.Select>
              </Material.FormControl>
              <Material.FormControl
                className={classes.formControlMaxx}
                variant="standard"
              >
                <Material.InputLabel id="activity-input">
                  Name of Activity
                </Material.InputLabel>
                <Material.Select
                  id="activity-type-select"
                  onChange={this.onHandleSelectChange}
                  name="selected_activity_id"
                  value={selected_activity_id}
                >
                  {activities.map((item, index) => (
                    <Material.MenuItem key={`${index}-option`} value={item.id}>
                      {item.title}
                    </Material.MenuItem>
                  ))}
                </Material.Select>
              </Material.FormControl>
              <div>
                {/* <Autocomplete
										onChange=""
										id="activity-type-select"
										autoHighlight
										className={classes.formControlMaxx}
										options=""
										getOptionLabel="Hello" //{(option) => option.account_particulars}
										// getOptionSelected={(option, value) =>
										// 	option.unique_code === value.unique_code
										// }
										value={this.state.activities}
										// filterOptions={this.filterOptions}
										renderInput={(params) => (
											<Material.TextField
												{...params}
												name="selected_activity_id"
												color="primary"
												label="Activity"
											/>
										)}
									/> */}
                <Material.Grid container spacing={3}>
                  <Material.Grid item xs={5}>
                    <Material.Collapse in={Boolean(selected_activity_id)}>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Activity Type:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.activity_type}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Year:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.year}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Batch:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_batches}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Rooms:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_rooms}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Pax:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_pax}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Meals:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_meals}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Snacks:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.no_of_snacks}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Venue:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.venue}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    </Material.Collapse>
                  </Material.Grid>
                  <Material.Grid item xs={7}>
                    <Material.Collapse in={Boolean(selected_activity_id)}>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Date Span:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.date_of_activity_from} -{" "}
                            {selected_activity.date_of_activity_to}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Participants:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.participants}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Equipment:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.facilities_equipment_needed}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Other Requirements:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.other_requirements}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Type of catering service:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.type_of_catering_service}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Accommodation:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.accommodation}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    </Material.Collapse>
                  </Material.Grid>
                  <Material.Grid item xs={7}>
                    <Material.Collapse in={Boolean(selected_activity_id)}>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Remarks:
                        </Material.Typography>
                        {selected_activity_id ? (
                          <Material.Typography
                            className="wrap"
                            variant="subtitle1"
                          >
                            {selected_activity.remarks}
                          </Material.Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    </Material.Collapse>
                  </Material.Grid>
                </Material.Grid>
              </div>
            </div>
          );
        } else {
          return (
            <div className={classes.loading}>
              <Material.FormControlLabel
                control={<Material.CircularProgress />}
                checked={false}
                label="Loading..."
              />
            </div>
          );
        }
      default:
        return (
          <div className={classes.loading}>
            <Material.FormControlLabel
              control={<Material.CircularProgress />}
              checked={false}
              label="Loading..."
            />
          </div>
        );
    }
  }
  updateState(step) {
    const { rows } = this.state;

    // Create a new array of rows with the updated fundSourceAllocatedAmount
    const updatedRows = rows.map((row) => {
      // Remove commas, parse as float, and format to 2 decimal places
      let allocated_amount = parseFloat(
        row.fundSourceAllocatedAmount.toString().replace(/,/g, "")
      ).toFixed(2);
      // console.log('$$allocatedAmount:', allocated_amount);
      return { ...row, fundSourceAllocatedAmount: allocated_amount };
    });

    // Update the state with the new array of rows
    this.setState({ rows: updatedRows }, () => {
      this.onClickNextStep(step);
    });
  }
  onClickNextStep(step) {
    this.setState(
      { ...this.state, activeStep: this.state.activeStep + step },
      () => {
        const {
          activeStep,
          fundSource,
          jan_qty,
          feb_qty,
          mar_qty,
          apr_qty,
          may_qty,
          jun_qty,
          jul_qty,
          aug_qty,
          sep_qty,
          oct_qty,
          nov_qty,
          dec_qty,
          stepper,

          sub_category_modes,
          mode_sub_category_id,
          mode_category_id,
          category_modes,
          mode_parent_id,
          parent_modes,
          procurement_subMode_child,
          procurement_subMode,
          procurement_mode,
          rows,
        } = this.state;

        //reyno 02072024
        let mode_id =
          mode_sub_category_id > 0
            ? mode_sub_category_id
            : mode_category_id > 0
            ? mode_category_id
            : mode_parent_id > 0
            ? mode_parent_id
            : 0;
        let mode =
          mode_sub_category_id > 0
            ? procurement_subMode_child
            : mode_category_id > 0
            ? procurement_subMode
            : mode_parent_id > 0
            ? procurement_mode
            : "";
        this.setState(
          {
            ...this.state,
            mode,
            mode_id,
          },
          () => {
            // console.log("$$mode_id", this.state.mode_id);
          }
        );

        const { ppmp } = this.props;
        if (activeStep === stepper.length) {
          let target = [
            jan_qty,
            feb_qty,
            mar_qty,
            apr_qty,
            may_qty,
            jun_qty,
            jul_qty,
            aug_qty,
            sep_qty,
            oct_qty,
            nov_qty,
            dec_qty,
          ];
          let qty = target
            .map((elt) => (elt ? parseFloat(elt) : 0))
            .reduce((a, b) => parseFloat(a) + parseFloat(b));
          let total_cost = (
            qty * parseFloat(ppmp.suppliesData._latest_price)
          ).toFixed(2);
          let unit_price = parseFloat(ppmp.suppliesData._latest_price);
          let activeStep = 0;
          let errorMessage = "";
          const totalAllocatedAmount = rows
            .reduce(
              (sum, row) =>
                sum + parseFloat(row.fundSourceAllocatedAmount || 0),
              0
            )
            .toFixed(2);
          if (parent_modes.length > 0 && mode_parent_id > 0) {
            if (
              (category_modes.length > 0 && mode_category_id > 0) ||
              category_modes.length == 0
            ) {
              if (
                (sub_category_modes.length > 0 && mode_sub_category_id > 0) ||
                sub_category_modes.length == 0
              ) {
                if (ppmp.transactionData === null) {
                  errorMessage =
                    "Ohh uh! look like there are no PPMP transaction selected";
                  activeStep = 0;
                } else {
                  if (qty > 0) {
                    if (
                      ppmp.suppliesData.category_name.indexOf("Catering") !==
                        -1 &&
                      this.state.selected_activity_id === 0
                    ) {
                      errorMessage = "Please add Catering - Office Activity";
                      activeStep = 3;
                    } else {
                      if (rows[0]?.fundSource != null) {
                        if (parseInt(ppmp.transactionData?.is_indicative) > 0) {
                          // console.log('$$is_indicative', ppmp.transactionData?.is_indicative);
                          // if (
                          //   unit_price > parseFloat(50000.0) &&
                          //   rows[0]?.fundSource.allot_type !== "Capital Outlay"
                          // ) {
                          //   this.setState({
                          //     ...this.state,
                          //     errorDialogVisible: true,
                          //   });
                          //   errorMessage =
                          //     "Suggestion: Please select Capital Outlay as fund source for amounts exceeding 50,000!";
                          //   activeStep = 2;
                          // }
                          if (
                            unit_price >= parseFloat(50000.0) &&
                            rows[0]?.fundSource.allot_type === "Capital Outlay"
                          ) {
                            this.addToCart_final();
                          } else if (
                            total_cost >
                            parseFloat(rows[0]?.fundSource.allot_balance)
                          ) {
                            // var fs = rows[0]?.fundSource.allot_balance;
                            // errorMessage =
                            //   "Current total cost: " +
                            //   this.formatNumber(total_cost) +
                            //   " exceeds the remaining fund allotment balance: " +
                            //   this.formatNumber(fs);
                            // activeStep = 2;
                            // console.log("total", total_cost);
                            this.addToCart_final();
                          } else if (
                            // total_cost !==
                            // parseFloat(rows[0]?.fundSourceAllocatedAmount)
                            total_cost !== totalAllocatedAmount
                          ) {
                            errorMessage = `Total cost (${total_cost}) does not match the allocated amount (${totalAllocatedAmount}). Please verify the allocated amount.`;
                            activeStep = 2;
                            // console.log(
                            //   "$$fundSourceAllocatedAmount",
                            //   rows[0]?.fundSourceAllocatedAmount
                            // );
                          } else if (
                            rows[0]?.fundSource.allot_type ===
                              "Capital Outlay" &&
                            unit_price < parseFloat(50000.0)
                          ) {
                            this.setState({
                              ...this.state,
                              errorDialogVisible: true,
                              proceedAnyway: false,
                            });
                            errorMessage =
                              "Unit price must be 50k above for Capital Outlay";
                            activeStep = 2;
                          } else {
                            this.addToCart_final();
                          }
                        } else {
                          if (
                            rows[0]?.fundSource.allot_type ===
                              "Capital Outlay" &&
                            unit_price < parseFloat(50000.0) &&
                            ppmp.suppliesData.category_id !== "63"
                          ) {
                            this.setState({
                              ...this.state,
                              errorDialogVisible: true,
                              proceedAnyway: false,
                            });
                            errorMessage =
                              "Unit price must be 50k above! For Capital Outlay";
                            activeStep = 2;
                          } else if (
                            total_cost !== totalAllocatedAmount
                            // rows.reduce(
                            //   (acc, row) =>
                            //     acc +
                            //     parseFloat(
                            //       row?.fundSourceAllocatedAmount.replace(
                            //         /,/g,
                            //         ""
                            //       ) || 0
                            //     ),
                            //   0
                            // )
                          ) {
                            errorMessage = `Allocated amount (${totalAllocatedAmount}) does not match the total cost(${total_cost}). Please verify the allocated amount.`;
                            activeStep = 2;
                          } else if (
                            rows.some(
                              (row) =>
                                parseFloat(row.fundSourceAllocatedAmount || 0) >
                                parseFloat(row.fundSource?.allot_balance || 0)
                            )
                          ) {
                            const rowWithExcessAllocation = rows.find(
                              (row) =>
                                parseFloat(row.fundSourceAllocatedAmount || 0) >
                                parseFloat(row.fundSource?.allot_balance || 0)
                            );

                            if (rowWithExcessAllocation) {
                              errorMessage =
                                `The allocated amount for fund source : ${rowWithExcessAllocation.fundSource.account_particulars} , exceeds its allotment balance. ` +
                                `Allotted amount: ${this.formatNumber(
                                  parseFloat(
                                    rowWithExcessAllocation.fundSourceAllocatedAmount ||
                                      0
                                  )
                                )}, ` +
                                `Allotment balance: ${this.formatNumber(
                                  parseFloat(
                                    rowWithExcessAllocation.fundSource
                                      .allot_balance || 0
                                  )
                                )}. Please verify.`;
                              activeStep = 2;
                            }
                          } else if (
                            rows.some(
                              (row) =>
                                row?.fundSource?.allot_type ===
                                  "Capital Outlay" &&
                                unit_price < parseFloat(50000.0)
                            )
                          ) {
                            const rowWithIssue = rows.find(
                              (row) =>
                                row?.fundSource?.allot_type ===
                                  "Capital Outlay" &&
                                unit_price < parseFloat(50000.0)
                            );

                            if (rowWithIssue) {
                              errorMessage = `The fund allotment type is 'Capital Outlay' but the unit price is below 50k. For fund source ${rowWithIssue.fundSource?.account_particulars}. Please verify.`;
                              activeStep = 2;
                            }
                          } else {
                            this.addToCart_final();
                          }
                        }
                      } else {
                        errorMessage = "Please select a source of fund!";
                        activeStep = 2;
                      }
                    }
                  } else {
                    errorMessage = "Please add a target month!";
                    activeStep = 1;
                  }
                }
              } else {
                errorMessage = "Please add a procurement sub-category mode!";
                activeStep = 0;
              }
            } else {
              errorMessage = "Please add a procurement category mode!";
              activeStep = 0;
            }
          } else {
            errorMessage = "Please add a procurement mode!";
            activeStep = 0;
          }
          if (errorMessage) {
            this.setState({
              ...this.state,
              errorDialogVisible: true,
              errorDialogMessage: errorMessage,
              activeStep,
              proceedAnyway: 0,
            });
          }
        }
      }
    );
  }

  filterOptions(options, { inputValue }) {
    return matchSorter(options, inputValue, {
      keys: [
        "office_name",
        "ppa_code",
        "ppa_desc",
        "respo_code",
        "respo_type",
        "ra_desc",
        "account_particulars",
      ],
    });
  }
  render() {
    const { ppmp, classes } = this.props;
    const { activeStep, stepper } = this.state;
    const data = ppmp.suppliesData;
    return (
      <Material.Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        onRendered={this.initRespoCenter}
        className={classes.modal}
        open={ppmp.suppliesModalVisible}
        // onClose={this.closeModal}
        closeAfterTransition
        disableAutoFocus={true}
        BackdropComponent={Material.Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.root}>
          <Material.Fade in={ppmp.suppliesModalVisible}>
            <div className={classes.paper}>
              <Material.Grid container spacing={0}>
                <Material.Grid item xs={10}>
                  <div className="inline">
                    <Material.Typography
                      className="flex"
                      color="primary"
                      variant="h5"
                    >
                      {data.name}
                    </Material.Typography>
                  </div>
                  <div className={classes.description}>
                    <Material.Tooltip title={data.description}>
                      <Material.Typography
                        className="title"
                        variant="subtitle1"
                      >
                        {data.description}
                      </Material.Typography>
                    </Material.Tooltip>
                  </div>
                </Material.Grid>
                <Material.Grid item xs={2}>
                  <Material.Typography variant="h6" className="pull-right">
                    {data._latest_price}
                  </Material.Typography>
                  <span className="pull-right padded">Php</span>
                </Material.Grid>
              </Material.Grid>
              <Material.Divider></Material.Divider>
              <div className={classes.FormContainer}>
                <Material.Paper elevation={4} className={classes.paperFunds}>
                  <Material.Stepper
                    className={classes.stepperCont}
                    activeStep={activeStep}
                    alternativeLabel
                  >
                    {stepper.map((label) => (
                      <Material.Step key={label}>
                        <Material.StepLabel>{label}</Material.StepLabel>
                      </Material.Step>
                    ))}
                  </Material.Stepper>
                </Material.Paper>
                {this.getStepContent(activeStep)}
              </div>
              <form noValidate autoComplete="off">
                <Material.Grid container spacing={0}>
                  <Material.Grid item xs={6}>
                    <Material.Button
                      className={classes.action}
                      variant="outlined"
                      color="secondary"
                      onClick={this.closeModal}
                    >
                      {" "}
                      Cancel{" "}
                    </Material.Button>
                  </Material.Grid>
                  {activeStep !== stepper.length ? (
                    <Material.Grid item xs={6} style={{ textAlign: "right" }}>
                      <Material.Button
                        className={classes.action}
                        disabled={activeStep === 0}
                        onClick={() => this.updateState(-1)}
                      >
                        Back{" "}
                      </Material.Button>
                      <Material.Button
                        className={classes.action}
                        variant="contained"
                        color="primary"
                        onClick={() => this.updateState(1)}
                        disabled={this.state.invalidState}
                      >
                        {activeStep === stepper.length - 1
                          ? "Add to PPMP List"
                          : "Next"}
                      </Material.Button>
                    </Material.Grid>
                  ) : (
                    ""
                  )}
                </Material.Grid>
              </form>
            </div>
          </Material.Fade>
          <Material.Dialog
            open={this.state.errorDialogVisible}
            onClose={() =>
              this.setState({
                ...this.state,
                errorDialogVisible: false,
              })
            }
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Material.DialogTitle className={classes.yellow}>
              Warning!
            </Material.DialogTitle>

            <Material.DialogContent>
              <Material.DialogContentText id="alert-dialog-description">
                {this.state.errorDialogMessage}
              </Material.DialogContentText>
            </Material.DialogContent>
            <Material.DialogActions>
              <Material.Button
                onClick={() =>
                  this.setState({ ...this.state, errorDialogVisible: false })
                }
                color="primary"
              >
                Close
              </Material.Button>
            </Material.DialogActions>
          </Material.Dialog>

          {/* dialog for error allocated_amount */}
          <Material.Dialog
            open={this.state.errorDialogVisibleAllocated}
            onClose={() =>
              this.setState({
                ...this.state,
                errorDialogVisibleAllocated: false,
              })
            }
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Material.DialogTitle>ERROR!</Material.DialogTitle>

            <Material.DialogContent>
              <Material.DialogContentText id="alert-dialog-description">
                {this.state.errorDialogMessage}
              </Material.DialogContentText>
            </Material.DialogContent>
            <Material.DialogActions>
              <Material.Button
                onClick={() =>
                  this.setState({
                    ...this.state,
                    errorDialogVisibleAllocated: false,
                  })
                }
                color="primary"
              >
                Close
              </Material.Button>
            </Material.DialogActions>
          </Material.Dialog>
        </div>
      </Material.Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Material.withStyles(useStyles)(
    React.forwardRef((props, ref) => (
      <SuppliesModal innerRef={ref} {...props} />
    ))
  )
);
