export const useStyles = (theme) => ({
    // '@global': {
	// 	'*::-webkit-scrollbar': {
    //         width: '0.4em'
    //     },
    //     '*::-webkit-scrollbar-track': {
    //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    //     },
    //     '*::-webkit-scrollbar-thumb': {
    //     backgroundColor: 'rgba(0,0,0,.1)',
    //     }      
	// },
    root: {
        flexGrow: 1,
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
		'& .inline': {
			display: 'inline-flex',
			'& > *': {
				paddingRight: theme.spacing(2),
			},
			'& .MuiTypography-subtitle1': {
				whiteSpace: 'nowrap',
				margin: theme.spacing(0,0,0,0.5),
				display: 'inline-block',
            },
        },
        '& .myGri':{
            '& .MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded':{
                boxShadow: false,
            }
        }
	},
	transactionHeader: {
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
        boxShadow: theme.shadows[5],
		'& .div-margin': {
			margin: theme.spacing(1, 0, 0, 0),
		},
		'& .pull-right': {
			float: 'right'
		}
	},
	statusColor: {
		'& .Open': {
			backgroundColor: '#2e4ae6',
            color: 'white',
            width: '120px',
		},
        '& .Pre-Approval': {
			backgroundColor: '#ef8e78',
            color: 'white',
            width: '120px',
        },
        '& .Returned': {
			backgroundColor: '#18b3d6',
            color: 'white',
            width: '120px',
		},
		'& .Pending': {
			backgroundColor: "#e89e16eb",
            color: 'white',
            width: '120px',
		},
        '& .Revision': {
			backgroundColor: "#e89e16eb",
            color: 'white',
            width: '120px',
		},
		'& .Approved': {
			backgroundColor: "green",
            color: 'white',
            width: '120px',
        },
        '& .Generated': {
			backgroundColor: "#cccc10",
            color: 'white',
            width: '120px',
        },
    },
    tableContainer: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        boxShadow: theme.shadows[5],
        '& .title': {
            whiteSpace: 'nowrap',
            textAlign: 'center',
            fontWeight: 'bold',
            boxSizing: 'inherit',
        },
        '& .secondary-title': {
            fontWeight: 'bold',
        },
        '& .yellow':{
            backgroundColor: 'yellow',
        },
        '& .lightgrey':{
            backgroundColor: 'lightgrey',
        },
        '& .button-p':{
            margin: theme.spacing(1),
        },
        '& .col_fit':{
            maxWidth: '100%',
        },
    },
    tableSimple: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        boxShadow: theme.shadows[0],
        '@media print': {
            '.tableSimple':{
                backgroundColor: '#1a4567 !important',
                '-webkit-print-color-adjust': 'exact',
                color: 'white !important',
            }
        },
    },
    tableFooter:{
        '& .signatory_pre': {
            fontSize: '14px',
            display: 'block',
            paddingTop: theme.spacing(1),
        },
        '& .signatory_name': {
            display: 'block',
            paddingTop: theme.spacing(2),
        },
        '& .signatory_pos': {
            fontSize: '14px',
            display: 'block'
        },
    },
    subLine_sec: {
        paddingTop: '6px'
    },
    rootLine_sec: {
        paddingTop: '12px'
    },
    table: {
        '& .MuiTableCell-root': {
            border: '1.5px solid black',
            padding: '2px',
            fontSize: '1rem'
        },
        '& .col_fit':{
            maxWidth: '100%',
        },
    },
    title: {
        textAlign: 'center',
    }
})