export const mapStateToProps = (state) => {
    return {
        data: state.data,
        users: state.users,
        userRole: state.userRole,
		session: state.session
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setUserDataSource: (res) => {
            dispatch({
                type: "SETUSERDATASOURCE",
                payload: {
					userDatasource: res.userDatasource,
					userTotal: res.total,
					userFilter: res.userFilter,
				}
            })
        },
        setUserProfileDataSource: (res) => {
            dispatch({
                type: "SETUSERPROFILEDATASOURCE",
                payload: {
					userProfileDatasource: res.userProfileDatasource,
				}
            })
        },
        setUserroleDataSource: (res) => {
            dispatch({
                type: "SETUSERROLEDATASOURCE",
                payload: {
					userroleDatasource: res.userroleDatasource,
					// userroleTotal: res.total,
					// userroleFilter: res.userroleFilter,
				}
            })
        },
        setUserOffices: (res) => {
            dispatch({
                type: "SETOFFICES",
                payload: {
					userOffices: res.userOffices,
				}
            })
        },
        displayAlert: (res) => {
            dispatch({
                type: "DISPLAYALERT",
                payload: {
					visible: res.visible,
					message: res.message,
					severity: res.severity,
                }
            })
        },
        displayLoading: (res) => {
            dispatch({
                type: "DISPLAYLOADING",
                payload: {
                    value: res,
                }
            })
        },
    };
};