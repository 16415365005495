export const useStyles = (theme) => ({
	// '@global': {
	// 	'*::-webkit-scrollbar': {
	// 		width: '0.4em'
	// 	},
	// 	'*::-webkit-scrollbar-track': {
	// 	'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
	// 	},
	// 	'*::-webkit-scrollbar-thumb': {
	// 	backgroundColor: 'rgba(0,0,0,.1)',
	// 	}   
	// },
    root: {
		minWidth: '676px',
		maxWidth: '745px',
		outline: 'none',
		display: 'inline-block',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
		},
		'& .inline': {
			display: 'inline-flex',
			'& > *': {
				paddingRight: theme.spacing(2),
			},
			'& .MuiTypography-subtitle1': {
				whiteSpace: 'nowrap',
				margin: theme.spacing(0,0,0,0.5),
				display: 'inline-block',
			},
			'& .wrap': {
				whiteSpace:'pre-wrap'
			}
		},
		'& .pull-right': {
			float: 'right'
		},
		'& .pull-right.padded': {
			padding: theme.spacing(0,1),
		},
    },
	modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
	paper: {
		overflowY: 'auto',
		maxHeight: '605px',
		minHeight: '355px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px',
        padding: theme.spacing(4, 4, 3),
	},
	paperFunds: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[4],
        borderRadius: '10px',
        padding: theme.spacing(1),
	},
	paperAdvance: {
        backgroundColor: '#5555551a',
        boxShadow: theme.shadows[0],
        borderRadius: '10px',
        padding: theme.spacing(1),
	},
	paperChoices: {
		width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        borderRadius: '5px',
        padding: theme.spacing(1),
	},
	textField: {
		margin: theme.spacing(0.5,1),
    },
	collapseExpand: {
		padding: theme.spacing(1),
		
		'& .MuiCollapse-wrapper':{
			display: 'block'
		}
	},
	action: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
	FormContainer: {
		padding: theme.spacing(2),
		'& ::-webkit-scrollbar-track': {
			WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
			borderRadius: '10px',
			backgroundColor: '#F5F5F5',
		},
		'& .title': {
			padding: theme.spacing(2,0,0,0),
		},
		'& .MuiGrid-item': {
            display: "inline-grid"
        },
		'& .full-width-choices': {
			paddingRight: theme.spacing(4),
			'& .MuiFormControl-root': {
				width: '100%'
			}
		}
	},
	formControl: {
		margin: theme.spacing(1),
    	maxWidth: '273px',
	},
	formControlMaxx: {
		margin: theme.spacing(3),
		minWidth:'350px',
		color:'secondary',
	},
	formControlMex: {
		margin: theme.spacing(3),
		minWidth:'180px',
		color:'secondary',
	},
	formControlMax: {
		margin: theme.spacing(1),
	},
    listtable:{
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
	},
	ul: {
        backgroundColor: 'inherit',
        padding: 0,
	},
	OptionContainer: {
		'& .inline': {
			display: 'inline-block',
		},
		'& .inline-flex': {
			display: 'inline-flex',
		},
		'& .padded': {
			padding: theme.spacing(0,2),
		},
	},
	listbox: {
		'& .MuiAutocomplete-listbox': {
			backgroundColor: 'black',
			padding: theme.spacing(4),
			borderStyle: 'groove'
		}	
	},
	stepperCont: {
		padding: theme.spacing(1,3),
	},
	loading: {
		display: 'flex',
        alignItems: 'center',
		justifyContent: 'center',
		height: '252px'
    },
    yellow:{
        color: 'orange'
    },
	description: {
		// color: theme.palette.text.secondary,
		'& .title': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': 3,
			height: 'inherit',
		},
	},
})