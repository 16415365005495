export const mapStateToProps = (state) => {
    return {
        token: state.auth,
		data: state.data,
        session: state.session,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setCredential: (res) => {
			dispatch({
				type: "SETCREDENTIAL",
				payload: {
					userToken: res.userToken,
					tokenHash: "ENCRYPTION TYPE",
				}
			})
        },
        removeCredential: (res) => {
			dispatch({
				type: "REMOVECREDENTIALS",
			})
        },
        removeUser: () => {
            dispatch({
                type: "REMOVEUSERS"
            })
		},
		setUser: (res) => {
            dispatch({
                type: "SETUSER",
                payload: {
                    user: res.user,
                }
            })
		},
		displayLoading: (res) => {
            dispatch({
                type: "DISPLAYLOADING",
                payload: {
                    value: res,
                }
            })
		},
		displayAlert: (res) => {
            dispatch({
                type: "DISPLAYALERT",
                payload: {
					visible: res.visible,
					message: res.message,
					severity: res.severity,
                }
            })
		},
        redirectAdmin: (res) => {
            dispatch({
                type: "REDIRECTITEMS",
                payload: {
					value: res,
                }
            })
		},
		removeStorageState: () => {
			dispatch({ type: "DROPPPMPSTATE" })
            dispatch({ type: "DROPPRSTATE" })
            dispatch({ type: "DROPITEMSTATE" })
            dispatch({ type: "DROPPRICESTATE" })
            dispatch({ type: "DROPCATEGORYSTATE" })
            dispatch({ type: "DROPREQUESTSTATE" })
            dispatch({ type: "DROPSUPPLIESSTATE" })
            dispatch({ type: "DROPUSERSTATE" })
            dispatch({ type: "DROPUSERROLESTATE" })
            dispatch({ type: "DROPMODESSTATE" })
            dispatch({ type: "DROPBACSIGNATORIESSTATE" })
            dispatch({ type: "DROPTWGSIGNATORIESSTATE" })
            dispatch({ type: "DROPPRICEREQSTATE" })
            dispatch({ type: "DROPPRICEREQLISTSTATE" })
        },
    };
};