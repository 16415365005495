import React from "react";
import { connect } from "react-redux";

//Imported Material Components
import * as Material from "@material-ui/core";

//Imported Icons

import Loading from "../common/loading";
import { useStyles } from "./payments.style";
import * as PaymentsService from "./payments.service";
import { mapStateToProps, mapDispatchToProps } from "./payments.reducer";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@mui/icons-material/Info";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import PaymentsInfoModal from "./payments.info.modal";

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      searchValue: "",
      year_range: [],
      showModalInfo: false,

      sort_column: "",
      sort_type: "",
      year: "",
      office_id: 0,
      show_all: 0,
      show_all_checked: false,
      payments: [],

      displayPaymentsData: [],
    };

    // this.ontoggleInfoModal = this.ontoggleInfoModal.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    // console.log("$$data", data);
    let currDate = new Date();
    let currYear = parseInt(currDate.getFullYear());

    //get year +1 & -2
    let yearRange = [currYear - 2, currYear - 1, currYear, currYear + 1];
    this.ontoggleInfoListModal = this.ontoggleInfoListModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSearchHandler = this.onSearchHandler.bind(this);
    this.requestGetPayments = this.requestGetPayments.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.onCheckHandlerCreate = this.onCheckHandlerCreate.bind(this);

    this.setState(
      {
        ...this.state,
        year: currYear,
        year_range: yearRange,
        office_id: parseInt(data.user.office_id),
      },
      () => {
        this.requestGetPayments();
      }
    );
  }
  onCloseModal() {
    this.setState({ ...this.state, modalVisible: false, showModalInfo: false });
  }
  onSearchHandler(e) {
    e.preventDefault();
    this.setState(
      { ...this.state, keyword: this.state.searchValue, page_no: 0 },
      () => {
        this.requestGetPayments();
      }
    );
  }

  onSelectHandler(e) {
    this.setState(
      {
        ...this.state,
        year: e.target.value,
      },
      () => {
        this.requestGetPayments();
      }
    );
  }
  ontoggleInfoListModal(value) {
    this.setState({
      ...this.state,
      showModalInfo: value.open,
      displayPaymentsData: value.data,
    });
  }

  handleRequestSort(event, property) {
    const { sort_column, sort_type } = this.state;
    const isAsc = sort_column === property && sort_type === "asc";
    this.setState(
      {
        ...this.state,
        sort_column: property,
        sort_type: isAsc ? "desc" : "asc",
      },
      () => {
        this.requestGetPayments();
      }
    );
  }
  onCheckHandlerCreate(e) {
    const isChecked = e.target.checked;
    this.setState(
      {
        ...this.state,
        show_all_checked: isChecked,
        show_all: isChecked ? 1 : 0,
      },
      () => {
        this.requestGetPayments();
      }
    );
  }
  requestGetPayments() {
    const { displayLoading, displayAlert, data } = this.props;
    displayLoading(true);
    let toPass = {
      office_id: parseInt(data.user.office_id),
      year: this.state.year,
      show_all: this.state.show_all,
    };
    // console.log("$$toPass", toPass);
    PaymentsService.getPayments({ toPass })
      .then((response) => {
        // console.log("$$response", response);
        if (!response.error) {
          this.setState(
            {
              ...this.state,
              payments: response.data,
            },
            () => {
              //   console.log("$$payments", response.data);
            }
          );
        } else {
          this.setState({
            ...this.state,
            payments: [],
          });
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }

  render() {
    const { year, year_range, sort_column, sort_type, payments } = this.state;
    const { classes, data, displayAlert } = this.props; //TableHead
    let office_id = data.user.office_id;
    const TableHeaderOp = [
      {
        id: "PO No.",
        numeric: false,
        disablePadding: false,
        label: "PO No.",
        align: "center",
        field: "po_no",
      },
      {
        id: "Transaction Number",
        numeric: false,
        disablePadding: false,
        label: "Transaction Number",
        align: "center",
        field: "dg_transaction_numer",
      },
      {
        id: "Project Name",
        numeric: false,
        disablePadding: false,
        label: "Project Name",
        align: "center",
        field: "project_name",
      },
      {
        id: "Type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        align: "center",
        field: "type",
      },
    ];
    return (
      <div className={classes.root}>
        <Material.Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Material.Grid item xs={12}>
            <Material.Paper className={classes.paper}>
              <Material.TableContainer //start of the table; (per category)
                component={Material.Paper}
              >
                <Material.Toolbar className={classes.toolbar}>
                  <form>
                    <Material.FormControl
                      variant="standard"
                      className={classes.formControlMax}
                    >
                      <Material.InputLabel>Select Year</Material.InputLabel>
                      <Material.Select
                        inputProps={{
                          name: "year",
                          id: "",
                        }}
                        value={year}
                        onChange={this.onSelectHandler}
                      >
                        <Material.MenuItem value={0}>
                          <em>None</em>
                        </Material.MenuItem>
                        {year_range.map((val) => (
                          <Material.MenuItem value={val}>
                            {" "}
                            {val}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                  </form>
                  <Material.FormControlLabel
                    control={
                      <Material.Checkbox
                        checked={this.state.show_all_checked}
                        value={this.state.show_all_checked}
                        name="show_all_checked"
                        onChange={this.onCheckHandlerCreate}
                      />
                    }
                    label="Show ALL ?"
                  />
                </Material.Toolbar>
                <Loading modalVisible={this.state.loadingVisible} />
                <Material.Table className={classes.table}>
                  <Material.TableHead>
                    <Material.TableRow>
                      {TableHeaderOp.map((option) => (
                        <Material.TableCell
                          key={option.id}
                          align={option.align}
                          padding={option.disablePadding ? "none" : "default"}
                          sortDirection={
                            sort_column === option.id ? sort_type : false
                          }
                        >
                          <Material.TableSortLabel
                            active={sort_column === option.id}
                            direction={
                              sort_column === option.id ? sort_type : "asc"
                            }
                            onClick={(event) => {
                              this.handleRequestSort(event, option.field);
                            }}
                          >
                            {option.label}
                            {sort_column === option.id ? (
                              <span className={classes.visuallyHidden}>
                                {sort_type === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </Material.TableSortLabel>
                        </Material.TableCell>
                      ))}
                      <Material.TableCell align="center">
                        {" "}
                        Actions
                      </Material.TableCell>
                    </Material.TableRow>
                  </Material.TableHead>
                  {payments.length === 0 ? (
                    <Material.TableRow>
                      <Material.TableCell colSpan={20} className="emptyCont">
                        <div className="empty">
                          <div className="emptyBorder">
                            No Payment Transactions.
                          </div>
                        </div>
                      </Material.TableCell>
                    </Material.TableRow>
                  ) : (
                    <Material.TableRow />
                  )}
                  <Material.TableBody>
                    {payments.map((row) => (
                      <Material.TableRow
                        key={row.id}
                        className={classes.tableRow}
                      >
                        <Material.TableCell align="center">
                          {row.po_no}
                        </Material.TableCell>
                        <Material.TableCell align="center">
                          {row.dg_transaction_number}
                        </Material.TableCell>
                        <Material.TableCell align="center">
                          {row.abs_project_name}
                        </Material.TableCell>
                        <Material.TableCell align="center">
                          {row.type}
                        </Material.TableCell>
                        {/* actionButtons */}
                        <Material.TableCell
                          align="center"
                          // className="lightblue"
                        >
                          {row.dg_transaction_number === "0" ? (
                            <Material.IconButton
                              className="animate row"
                              onClick={() =>
                                displayAlert({
                                  alertVisible: true,
                                  alertMessage:
                                    "No Transaction Details As of Now.",
                                  alertSeverity: "warning",
                                })
                              }
                            >
                              <DoubleArrowRoundedIcon />
                            </Material.IconButton>
                          ) : (
                            <>
                              <Material.IconButton
                                className="animate row"
                                onClick={(event) => {
                                  this.setState({
                                    ...this.state,
                                    [`anchorEl-${row.id}`]: event.currentTarget,
                                  });
                                }}
                              >
                                <DoubleArrowRoundedIcon />
                              </Material.IconButton>
                              <Material.Popover
                                id="mouse-over-popover"
                                open={Boolean(this.state[`anchorEl-${row.id}`])}
                                anchorEl={this.state[`anchorEl-${row.id}`]}
                                onClick={() => {
                                  this.setState({
                                    [`anchorEl-${row.id}`]: null,
                                  });
                                }}
                                classes={{ paper: classes.paperless_ref }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                              >
                                <Material.Tooltip title="Transaction Details">
                                  <Material.Fab
                                    color="primary"
                                    className={classes.actionList}
                                    size="small"
                                    onClick={() => {
                                      this.ontoggleInfoListModal({
                                        open: true,
                                        data: { ...row, office_id },
                                      });
                                    }}
                                  >
                                    <InfoIcon />
                                  </Material.Fab>
                                </Material.Tooltip>
                              </Material.Popover>
                            </>
                          )}
                        </Material.TableCell>
                      </Material.TableRow>
                    ))}
                  </Material.TableBody>
                </Material.Table>
              </Material.TableContainer>
              <PaymentsInfoModal
                modalVisible={this.state.showModalInfo}
                data={this.state.displayPaymentsData}
                dataHandler={this.ontoggleInfoListModal}
              />
            </Material.Paper>
          </Material.Grid>
        </Material.Grid>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Material.withStyles(useStyles)(Payments));
