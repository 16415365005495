export const mapStateToProps = (state) => {
    return {
		session: state.session,
        ppmp: state.ppmp,
		data: state.data,
        auth: state.auth,
        ppmpReqPriceList: state.ppmpReqPriceList,
        items: state.items,
        itemCategory: state.itemCategory,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setPPMPPriceReqListDataSource: (res) => {
			dispatch({
				type: "SETPPMPPRICEREQLISTDATASOURCE",
				payload: {
                    PPMPPriceReqListDataSource: res.PPMPPriceReqListDataSource,
                }
			})
        },
        setItemsDataSource: (res, callback) => {
            dispatch({
                type: "SETITEMSDATASOURCE",
                payload: {
                    itemsDatasource: res.itemsDatasource,
                    categoryDatasource: res.categoryDatasource,
                    itemsTotal: res.total,
                    itemsFilter: res.itemsFilter,
                },
                callback: () => { callback(); }
            })
        },
        setItemsCategoriesDataSource: (res) => {
            dispatch({
                type: "SETITEMSCATEGORIESDATASOURCE",
                payload: {
					categoryDatasource: res.categoryDatasource,
					// userroleTotal: res.total,
					// userroleFilter: res.userroleFilter,
				}
            })
        },
		displayLoading: (res) => {
            dispatch({
                type: "DISPLAYLOADING",
                payload: {
                    value: res,
                }
            })
		},
		displayAlert: (res) => {
            dispatch({
                type: "DISPLAYALERT",
                payload: {
					visible: res.visible,
					message: res.message,
					severity: res.severity,
                }
            })
		},
        redirectAdmin: (res) => {
            dispatch({
                type: "REDIRECTITEMS",
                payload: {
					value: res,
                }
            })
		},
    };
};