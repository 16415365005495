export const mapStateToProps = (state) => {
    return {
        data: state.data,
        request_items: state.request_items,
        items: state.items,
        ppmpReqPrice: state.ppmpReqPrice,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setRequestItemsDataSource: (res, callback) => {
            dispatch({
                type: "SETREQUESTITEMSDATASOURCE",
                payload: {
                    itemsRequestDatasource: res.itemsRequestDatasource,
                    categoryDatasource: res.categoryDatasource,
                    itemsRequestTotal: res.total,
                    itemsRequestFilter: res.itemsRequestFilter,
                },
                callback: () => { callback(); }
            })
        },
        setPPMPReqPriceDataSource: (res) => {
			dispatch({
				type: "SETPPMPREQPRICEDATASOURCE",
				payload: res
			})
        },
        setItemsDataSource: (res, callback) => {
            dispatch({
                type: "SETITEMSDATASOURCE",
                payload: {
                    itemsDatasource: res.itemsDatasource,
                    categoryDatasource: res.categoryDatasource,
                    itemsTotal: res.total,
                    itemsFilter: res.itemsFilter,
                },
                callback: () => { callback(); }
            })
        },
        displayLoading: (res) => {
            dispatch({
                type: "DISPLAYLOADING",
                payload: {
                    value: res,
                }
            })
        },
        displayAlert: (res) => {
            dispatch({
                type: "DISPLAYALERT",
                payload: {
                    visible: res.visible,
                    message: res.message,
                    severity: res.severity,
                }
            })
        },

    };
};