import React, { Component } from "react";
import { connect } from "react-redux";
import { useStyles } from "../list/edit.modal.styles";
import { mapStateToProps, mapDispatchToProps } from "../ppmp.reducer";
import * as Material from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as PPMPService from "../ppmp.service";
import matchSorter from "match-sorter";

class EditModal extends Component {
  constructor() {
    super();
    this.state = {
      advanceSearchVisible: false,
      rows: [{ fundSource: null, fundSourceAllocatedAmount: 0 }],
      fundSource: null,
      fundSourceOpen: false,
      respoCodeValue: null,
      sourceFundList: [],
      RA_year_choices: [],
      respoAccount_choices: [],
      fundTypeChoices: [],
      legalBasisChoices: [],
      checkedRCA: false,
      IsNonOffice: false,
      RA_year: "",
      RA_desc: "",
      fundType: "",
      PPA_code: "",
      respo_code: "",

      activeStep: 0,
      stepper: ["Selecting Funds", "Target Month", "Upload File"],

      isLoadItem: true,
      noOfItems: 1,
      itemArray: [],

      base64: "",
      filename: "",
      month_selected: "",
      date_selected: "",
      user_id: 0,
      ppmp_id: 0,

      error_dialog_visible: false,
      error_dialog_msg: "",

      is_maxProjectCost: false,
      sourceFundList2: [],
    };
    this.initRespoCenter = this.initRespoCenter.bind(this);
    this.onClickAdvanceSearch = this.onClickAdvanceSearch.bind(this);
    this.onHandleCheckboxChange = this.onHandleCheckboxChange.bind(this);
    this.initDefaultRespoValues = this.initDefaultRespoValues.bind(this);
    this.onHandleRespoCodeAutocomplete =
      this.onHandleRespoCodeAutocomplete.bind(this);
    this.requestSourceOfFund = this.requestSourceOfFund.bind(this);
    this.onHandleSourceFundAutocomplete =
      this.onHandleSourceFundAutocomplete.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.handleFundSourceAllocatedAmountChange =
      this.handleFundSourceAllocatedAmountChange.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
    this.requestRAyear = this.requestRAyear.bind(this);
    this.requestFundList = this.requestFundList.bind(this);
    this.requestRespoCenterData = this.requestRespoCenterData.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.onClickLoadItem = this.onClickLoadItem.bind(this);
    this.onHandleTextChange = this.onHandleTextChange.bind(this);
    this.onHandleChangeArray = this.onHandleChangeArray.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onHandleFileSelect = this.onHandleFileSelect.bind(this);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.removeCommas = this.removeCommas.bind(this);
    this.getCapitalOutlay = this.getCapitalOutlay.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onHandleSelectChange = this.onHandleSelectChange.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  onClickSave() {
    const { powProjectCost, transactionData } = this.props;
    const { fundSource, rows } = this.state;

    const projectCost = parseFloat(this.removeCommas(powProjectCost));
    let errMessage = "";
    let step = 0;

    if (this.state.rows[0]?.fundSource) {
      if (this.state.month_selected) {
        if (parseInt(transactionData.is_indicative) === 0) {
          // if (
          // 	projectCost > parseFloat(50000.0) &&
          // 	fundSource.allot_type !== "Capital Outlay"
          // ) {
          // 	errMessage =
          // 		"Please select Capital Outlay as fund source for 50k above!";
          // } else {
          if (
            projectCost !==
            rows.reduce(
              (acc, row) =>
                acc + parseFloat(row?.fundSourceAllocatedAmount || 0),
              0
            )
          ) {
            // console.log('$$projectCots: ', parseFloat(projectCost));
            const totalAllocatedAmount = rows.reduce((acc, row) => {
              // console.log('$$FundSourceAllocatedAmount: ', parseFloat(row.fundSourceAllocatedAmount));
              return acc + parseFloat(row?.fundSourceAllocatedAmount || 0);
            }, 0);
            
            // console.log('Total Allocated Amount:', totalAllocatedAmount);
            errMessage =
              "Program total cost does not match the allocated amount. Please verify the allocated amount.";
            step = 0;
          } else if (
            rows.some(
              (row) =>
                parseFloat(row.fundSourceAllocatedAmount || 0) >
                parseFloat(row.fundSource?.allot_balance || 0)
            )
          ) {
            const rowWithExcessAllocation = rows.find(
              (row) =>
                parseFloat(row.fundSourceAllocatedAmount || 0) >
                parseFloat(row.fundSource?.allot_balance || 0)
            );

            if (rowWithExcessAllocation) {
              errMessage =
                `The allocated amount for fund source : ${rowWithExcessAllocation.fundSource.account_particulars} , exceeds its allotment balance. ` +
                `Allotted amount: ${this.formatNumber(
                  parseFloat(
                    rowWithExcessAllocation.fundSourceAllocatedAmount || 0
                  )
                )}, ` +
                `Allotment balance: ${this.formatNumber(
                  parseFloat(
                    rowWithExcessAllocation.fundSource.allot_balance || 0
                  )
                )}. Please verify.`;
              step = 0;
            }
          } else {
            this.onSaveChanges();
          }
          // }
        } else {
          this.onSaveChanges();
          // if (
          // 	projectCost > parseFloat(50000.0) &&
          // 	fundSource.allot_type !== "Capital Outlay"
          // ) {
          // 	errMessage =
          // 		"Please select Capital Outlay as fund source for 50k above!";
          // } else {
          // 	this.onSaveChanges();
          // }
        }
      } else {
        errMessage = "Please select target month!";
        step = 1;
      }
    } else {
      errMessage = "Please select source of fund!";
    }
    if (errMessage) {
      this.setState({
        ...this.state,
        activeStep: step,
        error_dialog_msg: errMessage,
        error_dialog_visible: true,
      });
    }
  }
  onSaveChanges() {
    const {
      ppmp,
      displayAlert,
      displayLoading,
      refreshData,
      onCloseModal,
      powProjectCost,
    } = this.props;
    const { rows } = this.state; // Destructuring rows from state
    let errMessage = "";
    let step = 0;
    const fundSourceData = {
      allot_type: [],
      account_code: [],
      account_particulars: [],
      respo_code: [],
      respo_desc: [],
      ppa_code: [],
      ppa_desc: [],
      respo_desc2: [],
      respo_type: [],
      fundSourceAllocatedAmount: [],
    };
    rows.reduce((acc, row) => {
      const {
        allot_type,
        account_code,
        account_particulars,
        respo_code,
        respo_desc,
        ppa_code,
        ppa_desc,
        respo_desc2,
        respo_type,
      } = Object.values(row)[0];
      const fundSourceAllocatedAmount = row.fundSourceAllocatedAmount;

      acc.allot_type.push(allot_type);
      acc.account_code.push(account_code);
      acc.account_particulars.push(account_particulars);
      acc.respo_code.push(respo_code);
      acc.respo_desc.push(respo_desc);
      acc.ppa_code.push(ppa_code);
      acc.ppa_desc.push(ppa_desc);
      acc.respo_desc2.push(respo_desc2);
      acc.respo_type.push(respo_type);
      acc.fundSourceAllocatedAmount.push(fundSourceAllocatedAmount);
      return acc;
    }, fundSourceData);

    // Log the current state and RA_year
    // console.log("$$Current state:", this.state);
    // let 

    const payload = {
      ...this.state,
      allot_type: fundSourceData.allot_type.join(", "),
      account_code: fundSourceData.account_code.join(", "),
      account_particulars: fundSourceData.account_particulars.join(", "),
      respo_code: fundSourceData.respo_code.join(", "),
      respo_desc: fundSourceData.respo_desc.join(", "),
      ppa_code: fundSourceData.ppa_code.join(", "),
      ppa_desc: fundSourceData.ppa_desc.join(", "),
      respo_desc2: fundSourceData.respo_desc2.join(", "),
      respo_type: fundSourceData.respo_type.join(", "),
      allocated_amount: fundSourceData.fundSourceAllocatedAmount.join(", "),
      powProjectCost: powProjectCost,
      _latest_price: parseFloat(this.removeCommas(powProjectCost))
    };

    displayLoading(true);
    PPMPService.savePPMPWithPOW(payload)

      .then((res) => {
        // onCloseModal();
        // refreshData();

        // displayAlert({
        //   alertVisible: true,
        //   alertMessage: res,
        //   alertSeverity: "success",
        // });
        // displayLoading(false);
        if (!res.error) {
          displayAlert({
            visible: true,
            message: "Item added successfully!",
            severity: "success",
          });
          onCloseModal();
          refreshData();
        } else {
          if (res.fundStatus && res.fundStatus[0]) {
            errMessage = `Cannot proceed! Total amount for this funds exceeded from
              Respo Code/s:
              ${res.fundStatus
                .map((status) =>
                  status.type === "error"
                    ? ` ${status.account_code_description} with the allocated amount of ${status.alloted_amount} - ${status.status}`
                    : ""
                )
                .join("\n")}`;

            step = 0;
          } else {
            errMessage = `Error saving item! ${res?.errmsg}`;
          }

          this.setState({
            activeStep: step,
            error_dialog_msg: errMessage,
            error_dialog_visible: true,
            proceedAnyway: ppmp.transactionData.is_indicative > 0,
          });
          displayLoading(false);
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }

  // onSaveChanges() {
  //   const { displayAlert, displayLoading, refreshData, onCloseModal } =
  //     this.props;
  //   // console.log("$$transactionData", transactionData);
  //   // let projectCost = parseFloat(this.removeCommas(powProjectCost));
  //   // if (this.state.fundSource && this.state.month_selected) {
  //   console.log("$$Current state:", this.state);
  //   console.log("$$Curr", RA_year);
  //   displayLoading(true);
  //   PPMPService.savePPMPWithPOW({
  //     ...this.state,
  //     RA_year: rows[0].fundSource.RA_year.join(","),
  //     RA_desc: rows[0].fundSource.RA_year.join(","),
  //     fundType: rows[0].fundSource.RA_year.join(","),
  //     PPA_code: rows[0].fundSource.RA_year.join(","),
  //     respo_code: rows[0].fundSource.RA_year.join(","),
  //   })
  //     .then((res) => {
  //       onCloseModal();
  //       refreshData();

  //       displayAlert({
  //         alertVisible: true,
  //         alertMessage: res,
  //         alertSeverity: "success",
  //       });
  //       displayLoading(false);
  //     })
  //     .catch((err) => {
  //       displayAlert({
  //         alertVisible: true,
  //         alertMessage: err,
  //         alertSeverity: "warning",
  //       });
  //       displayLoading(false);
  //     });
  // } else {
  // let step = !this.state.fundSource
  // 	? 0
  // 	: !this.state.month_selected
  // 		? 1
  // 		: -1;
  // let errMessage = !this.state.fundSource
  // 	? "Please select source of fund!"
  // 	: !this.state.month_selected
  // 		? "Please select target month!"
  // 		: "";

  // }
  // }
  onHandleFileSelect = (event) => {
    // console.log('$$event', event);
    const { displayAlert } = this.props;
    const file = event.target.files[0];
    if (file) {
      // console.log('$$file', file);
      if (file.type.includes("pdf")) {
        try {
          //convert to base64
          const reader = new FileReader();
          reader.onload = (event) => {
            this.setState(
              {
                ...this.state,
                base64: event.target.result,
                filename: file.name,
              },
              () => {
                //base64 file:
                // console.log("$$base64", this.state.base64);
              }
            );
          };
          reader.readAsDataURL(file);
        } catch (err) {
          console.log("$$error", err);
        }
      } else {
        displayAlert({
          alertVisible: true,
          alertMessage: "Invalid file format!",
          alertSeverity: "warning",
        });
      }
    } else {
      this.setState(
        {
          ...this.state,
          base64: "",
        },
        () => {
          //base64 file:
          // console.log('$$base64', this.state.base64);
        }
      );
    }
  };
  onHandleChangeArray(index, target, value) {
    // console.log('$$index', index);
    // console.log('$$target', target);
    // console.log('$$value', value);
    let itemArray = [...this.state.itemArray];
    // console.log('$$itemArray', itemArray);
    let targetItem = { ...itemArray[index], [target]: value.target.value };
    itemArray[index] = targetItem;
    this.setState({ ...this.state, itemArray });
  }
  onHandleTextChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }
  onClickLoadItem() {
    this.setState(
      {
        ...this.state,
        isLoadItem: !this.state.isLoadItem,
      },
      () => {
        let newArray = [];
        let noOfItems = this.state.noOfItems;
        if (!this.state.isLoadItem) {
          // create an array
          for (let i = 0; i < noOfItems; i++) {
            let emptyItems = {
              id: i,
              item_no: "",
              item_name: "",
              qty: "",
              unit: "",
              unit_cost: "",
              amount: "",
            };
            // console.log('$$emptyItems', emptyItems);
            newArray.push(emptyItems);
          }
        }
        // console.log('$$newArray', newArray);
        this.setState(
          {
            ...this.state,
            itemArray: newArray,
          },
          () => {
            // console.log('$$itemArray', this.state.itemArray);
          }
        );
      }
    );
  }
  initRespoCenter() {
    if (this.state.respoCodeValue === null) {
      this.initDefaultRespoValues();
    } else {
      this.requestRAyear();
    }
  }
  requestRespoCenterData() {
    const {
      data,
      displayAlert,
      displayLoading,
      setRespoCenterDataSource,
      setRespoDefaultValues,
    } = this.props;
    displayLoading(true);
    PPMPService.getRespoCenterData()
      .then((res) => {
        const respoCenterData = res.data;
        if (respoCenterData.length > 0) {
          setRespoCenterDataSource({ respoCenterData });
          const defval = data.user;
          const respoCodeValue = respoCenterData.filter((item) => {
            return (
              item.ppa_code === defval.fpp &&
              item.respo_code === defval.respo_center
            );
          });
          displayLoading(false);
          setRespoDefaultValues({ respoCodeValue: respoCodeValue[0] });
          this.setState({ ...this.state, respoCodeValue: respoCodeValue[0] });
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }
  requestFundList() {
    const { displayAlert, setRespoDefaultValues, displayLoading, ppmp } =
      this.props;
    const { RA_year, respoCodeValue, IsNonOffice } = this.state;
    displayLoading(true);
    PPMPService.getAccountCodePerRespo({
      respo_code: respoCodeValue.respo_code,
      year: RA_year,
      ppmp_id: this.state.ppmp_id
    })
      .then((res) => {
        var data = res.data;
        if (typeof data === "string") {
          this.setState({
            ...this.state,
            RA_desc: "",
            fundType: "",
            PPA_code: "",
          });
          displayLoading(false);
        } else {
          if (data.length > 0) {
            let dataFiltered = data;
            // let dataFiltered = data.filter((item) => {
            //   if (IsNonOffice) {
            //     return (
            //       item.ppa_code !==
            //       ppmp?.respoDefaultParams?.respoCodeValue?.ppa_code
            //     );
            //   } else {
            //     return (
            //       item.ppa_code ===
            //       ppmp?.respoDefaultParams?.respoCodeValue?.ppa_code
            //     );
            //   }
            // });
            var _entryData = dataFiltered[0];
            setRespoDefaultValues({
              RA_desc: _entryData.ra_desc,
              fundType: _entryData.fund_type,
              PPA_code: _entryData.ppa_code,
            });
            this.setState(
              {
                ...this.state,
                RA_desc: _entryData.ra_desc,
                fundType: _entryData.fund_type,
                PPA_code: _entryData.ppa_code,
                respoAccount_choices: dataFiltered,
                legalBasisChoices: [
                  ...new Set(dataFiltered.map((e, i) => e.ra_desc)),
                ],
                fundTypeChoices: [
                  ...new Set(dataFiltered.map((e, i) => e.fund_type)),
                ],
              },
              () => {
                this.state.sourceFundList.length === 0
                  ? this.requestSourceOfFund()
                  : displayLoading(false);
              }
            );
          }
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }
  requestRAyear() {
    const { displayAlert, displayLoading, setRespoDefaultValues, ppmp } = this.props;
    const { respoCodeValue } = this.state;
    displayLoading(true);
    PPMPService.getRAyear({
      respo_code: respoCodeValue.respo_code,
      ppmp_id : this.state.ppmp_id
    })
      .then((res) => {
        var data = res.data;
        if (typeof data === "string") {
          this.setState(
            { ...this.state, RA_year: "", RA_year_choices: [{ ra_year: "" }] },
            () => this.requestFundList()
          );
          displayLoading(false);
        } else {
          if (data.length > 0) {
            const RA_year = data[0].ra_year;
            setRespoDefaultValues({ RA_year, IsNonOffice: false });
            this.setState(
              { ...this.state, RA_year, RA_year_choices: data },
              () => this.requestFundList()
            );
          }
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }
  filterOptions(options, { inputValue }) {
    return matchSorter(options, inputValue, {
      keys: [
        "office_name",
        "ppa_code",
        "ppa_desc",
        "respo_code",
        "respo_type",
        "ra_desc",
        "account_particulars",
      ],
    });
  }
  addRow = () => {
    const { rows } = this.state;
    this.setState({
      rows: [...rows, { fundSource: "" }],
    });
  };

  removeRow = (index) => {
    const { rows } = this.state;
    if (rows.length > 1) {
      const updatedRows = [...rows.slice(0, index), ...rows.slice(index + 1)];
      this.setState({ rows: updatedRows });
    }
  };
  // handleFundSourceAllocatedAmountChange = (event, index) => {
  //   const { rows } = this.state;
  //   const updatedRows = [...rows];
  //   updatedRows[index] = {
  //     ...updatedRows[index],
  //     fundSourceAllocatedAmount: event.target.value,
  //   };
  //   this.setState({ rows: updatedRows });
  //   // console.log("$$Updated fundSourceAllocatedAmount:", event.target.value);
  // };
  handleFundSourceAllocatedAmountChange = (event, index) => {
    const { rows } = this.state;
    let value = event.target.value.replace(/,/g, ''); // Remove existing commas

    // Allow only numbers and up to 2 decimal places
    if (!isNaN(value) && /^(\d+(\.\d{0,2})?)?$/.test(value)) {
      if (value.includes('.')) {
        // Format only the integer part for commas
        let parts = value.split('.');
        parts[0] = parseFloat(parts[0]).toLocaleString('en-US');
        value = parts.join('.');
      } else {
        value = parseFloat(value).toLocaleString('en-US');
      }

      const updatedRows = [...rows];
      updatedRows[index] = {
        ...updatedRows[index],
        fundSourceAllocatedAmount: value,
      };
      this.setState({ rows: updatedRows });
    }
  };
  
  
  onHandleSourceFundAutocomplete = (event, value, index) => {
    const { rows } = this.state;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], fundSource: value };
    this.setState({ rows: updatedRows });
  };
  requestSourceOfFund() {
    const { displayAlert, displayLoading, ppmp } = this.props;
    const { respoCodeValue } = this.state;

    // let maxProjectCost = 50000.00;
    // let projectCost = parseFloat(this.removeCommas(powProjectCost));

    let sp_used = this.state.IsNonOffice
      ? "sp_account_get_fundsource_nonoffice3"
      : "sp_account_get_fundsource3";
    displayLoading(true);
    PPMPService.getSourceOfFundData({
      ...this.state,
      respo_code: respoCodeValue.respo_code,
      sp_used,
    })
      .then((res) => {
        // console.log('$$Res', res.data);

        if (typeof res.data === "string") {
          this.setState({
            ...this.state,
            sourceFundList: [],
            fundSource: null,
          });
          displayLoading(false);
        } else {
          this.setState(
            {
              ...this.state,
              sourceFundList: res.data,
              fundSource: null,
            },
            () => {
              let sourceFundList = res.data;
              const data = ppmp.suppliesData;
              if (data.ppmp_item_id > 0) {
                // continue code_here	account_code_description == account_particulars
                let fundSource = sourceFundList.filter(
                  (element) =>
                    element.account_particulars == data.account_code_description
                )[0];
                // console.log('$$fundSource', fundSource);
                this.setState({
                  ...this.state,
                  // funds
                  fundSource,
                });
                // console.log('$$state', this.state);
              }
            }
          );
          displayLoading(false);
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "warning",
        });
        displayLoading(false);
      });
  }
  onHandleRespoCodeAutocomplete(event, value) {
    this.setState({ ...this.state, respoCodeValue: value }, () => {
      if (value) {
        this.props.displayLoading(true);
        this.requestRAyear();
      }
    });
  }
  initDefaultRespoValues() {
    const { ppmp } = this.props;
    const value = ppmp.respoDefaultParams;
    this.setState(
      {
        IsNonOffice: false,
        respoCodeValue: value.respoCodeValue,
        user_id: this.props.data.user.id,
        ppmp_id: this.props.ppmp_id,
      },
      () => {
        this.initRespoCenter();
      }
    );
  }
  onHandleCheckboxChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
    if (event.target.name === "checkedRCA") {
      if (!event.target.checked) {
        this.initDefaultRespoValues();
      }
    }
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (e.target.name == "month_selected") {
        // console.log("$$month_picker", e.target.value);
        let val = e.target.value;
        let year = val.substring(0, 4);
        let month = val.substring(5, 7);
        let day = "01";

        // console.log('$$year', year);
        // console.log('$$month', month);

        // let setDate = day + "/" + month + "/" + year;
        let setDate = year + "-" + month + "-" + day;
        this.setState({
          ...this.state,
          date_selected: setDate,
        });
      }
    });
  }
  onClickAdvanceSearch(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      advanceSearchVisible: !this.state.advanceSearchVisible,
    });
  }
  // initRespoCenter() {
  //     this.requestRespoCenterData();
  // }

  // function to remove comma's from powProjectCost
  removeCommas() {
    const { powProjectCost } = this.props;

    var projectCost = "";
    for (var i = 0; i < powProjectCost.length; i++) {
      if (powProjectCost[i] !== ",") {
        projectCost += powProjectCost[i];
      }
    }
    return projectCost;
  }

  formatNumber(num) {
    if (num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  // getCapitalOutlay() {
  // 	const { sourceFundList } = this.state;

  // 	sourceFundList.map((source, index) => {
  // 		this.setState({
  // 			...this.state,
  // 			sourceFundList2: [source]
  // 		})
  // 	})
  // }
  getCapitalOutlay() {
    const { sourceFundList } = this.state;
    const updatedSourceFundList2 = [];

    sourceFundList.forEach((source) => {
      updatedSourceFundList2.push(source);
    });

    this.setState({
      ...this.state,
      sourceFundList2: updatedSourceFundList2,
    });
  }

  onHandleSelectChange(event) {
    // console.log('$$event', event);
    this.props.displayLoading(true);
    this.setState(
      { ...this.state, [event.target.name]: event.target.value },
      () => {
        if (event.target.name === "RA_year") {
          this.requestFundList();
        } else if (event.target.name === "procurement_mode") {
          let parent_id = event.target.value;
          this.requestGetProcModesParent(parent_id);
        } else if (event.target.name === "procurement_category") {
          let category_id = event.target.value;
          this.requestGetProcModesCategory(category_id);
        } else if (event.target.name === "sub_category") {
          let sub_category_id = event.target.value;
          this.requestGetProcModesSubCategory(sub_category_id);
        } else if (event.target.name === "year") {
          let selected_activity_year = event.target.value;
          this.onChangeCateringYear(selected_activity_year);
        } else if (event.target.name === "selected_activity_id") {
          let selected_activity_id = event.target.value;
          this.showActivityDetails(selected_activity_id);
        } else {
          // this.requestSourceOfFund();
        }
      }
    );
    this.props.displayLoading(false);
  }

  // for stepper
  getStepContent(stepIndex) {
    const { ppmp, classes, powProjectCost } = this.props;
    switch (stepIndex) {
      case 0:
        const {
          advanceSearchVisible,
          sourceFundList,
          fundSource,
          checkedRCA,
          legalBasisChoices,
          fundTypeChoices,
          rows,
        } = this.state;

        // // max project cost for MOOE(Maintainance and Other Operating Expense)
        // const maxProjectCost = 50000.00;
        // const projectCost = parseFloat(this.removeCommas(powProjectCost));

        return (
          <div>
            <div className="title">
              <Material.Grid container spacing={3}>
                <Material.Grid item xs={6}>
                  <Material.Typography variant="subtitle1">
                    Funds (WFP)
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Total Amount: ₱{powProjectCost}
                  </Material.Typography>
                </Material.Grid>
              </Material.Grid>
            </div>
            <Material.Divider></Material.Divider>
            <Material.Grid container spacing={3}>
              <Material.Grid item xs={12}>
                <Material.Link
                  href=""
                  style={{ textAlign: "right" }}
                  onClick={this.onClickAdvanceSearch}
                >
                  {" "}
                  {advanceSearchVisible ? "Hide" : "Show"} Advance Search{" "}
                </Material.Link>
                <Material.Collapse
                  className={classes.collapseExpand}
                  in={advanceSearchVisible}
                >
                  <Material.Paper
                    elevation={1}
                    className={classes.paperAdvance}
                  >
                    <div className="full-width-choices">
                      <Material.Tooltip
                        title="Get Fund source from other Respo Center"
                        arrow
                      >
                        <Material.FormControlLabel
                          control={
                            <Material.Checkbox
                              checked={checkedRCA}
                              onChange={this.onHandleCheckboxChange}
                              name="checkedRCA"
                              color="primary"
                            />
                          }
                          label="Respo Center"
                        />
                      </Material.Tooltip>
                      <Material.Tooltip
                        title="Respo Center needs approval when using other funds"
                        arrow
                      >
                        <Autocomplete
                          onChange={this.onHandleRespoCodeAutocomplete}
                          id="respo-code-select"
                          autoHighlight
                          disabled={!checkedRCA}
                          options={ppmp.respoCenterCodeDatasource}
                          getOptionLabel={(option) =>
                            `${option.respo_code}. ${option.office_name}`
                          }
                          filterOptions={this.filterOptions}
                          getOptionSelected={(option, value) =>
                            option.ppa_code === value.ppa_code
                          }
                          value={this.state.respoCodeValue}
                          renderOption={this.respoCodeRenderOptions}
                          renderInput={(params) => (
                            <Material.TextField
                              {...params}
                              color="primary"
                              label="Fund Source *"
                              margin="normal"
                            />
                          )}
                          disableListWrap
                        />
                      </Material.Tooltip>

                      <div>
                        <Material.FormControl className={classes.formControl}>
                          <Material.InputLabel id="RA-year-select-label">
                            Year *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="RA-year-select"
                            id="RA-year-select"
                            name="RA_year"
                            value={this.state.RA_year}
                            onChange={this.onHandleSelectChange}
                          >
                            {this.state.RA_year_choices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`ra-year-item-${index}`}
                                  value={item.ra_year}
                                >
                                  {item.ra_year}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                        <Material.FormControl className={classes.formControl}>
                          <Material.InputLabel id="legal-basis-select-label">
                            Legal Basis *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="legal-basis-select"
                            id="legal-basis-select"
                            name="RA_desc"
                            value={this.state.RA_desc}
                            onChange={this.onHandleSelectChange}
                          >
                            {legalBasisChoices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`legal_basis-item-${index}`}
                                  value={item}
                                >
                                  {item}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                      </div>
                      <div style={{ display: "inline" }}>
                        <Material.FormControl className={classes.formControl}>
                          <Material.InputLabel id="fund-type-select-label">
                            Fund Type *
                          </Material.InputLabel>
                          <Material.Select
                            labelId="fund-type-select"
                            id="fund-type-select"
                            name="fundType"
                            value={this.state.fundType}
                            onChange={this.onHandleSelectChange}
                          >
                            {fundTypeChoices.map((item, index) => {
                              return (
                                <Material.MenuItem
                                  key={`fund-type-item-${index}`}
                                  value={item}
                                >
                                  {item}
                                </Material.MenuItem>
                              );
                            })}
                          </Material.Select>
                        </Material.FormControl>
                        <Material.FormControl className={classes.formControl}>
                          {/* <Material.InputLabel id="fund-type-select-label">Non-Office</Material.InputLabel> */}
                          <Material.FormControlLabel
                            control={
                              <Material.Checkbox
                                checked={this.state.IsNonOffice}
                                onChange={this.onHandleCheckboxChange}
                                name="IsNonOffice"
                                color="primary"
                              />
                            }
                            label="Non-Office"
                          />
                        </Material.FormControl>
                      </div>
                    </div>
                    <Material.Button
                      className={classes.action}
                      variant="outlined"
                      color="primary"
                      onClick={this.requestSourceOfFund}
                    >
                      {" "}
                      Search{" "}
                    </Material.Button>
                  </Material.Paper>
                </Material.Collapse>
                {/* <div className="inline">
									<Material.Typography color="primary" variant="subtitle1">
										Saved Account Code:
									</Material.Typography>
									<Material.Typography variant="subtitle1">
										{this.props.ppmp.suppliesData.account_code}
									</Material.Typography>
								</div> */}
                {/* <Material.Divider></Material.Divider> */}
                {rows.map((row, index) => {
                  // console.log("$$Row:", row);
                  return (
                    <div className="full-width-choices" key={index}>
                      <Autocomplete
                        onChange={(e, value) =>
                          this.onHandleSourceFundAutocomplete(e, value, index)
                        }
                        id={`fund-source-select-${index}`}
                        autoHighlight
                        className={classes.autocompleteRoot}
                        options={sourceFundList}
                        getOptionLabel={(option) => option.account_particulars}
                        renderOption={this.accountCodeRenderOptions}
                        value={row.fundSource}
                        filterOptions={this.filterOptions}
                        renderInput={(params) => (
                          <Material.TextField
                            {...params}
                            name="fundSource"
                            color="primary"
                            label="Reselect/ Select New Account Code *"
                            margin="normal"
                          />
                        )}
                      />
                      <Material.Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ color: "rgba(0, 0, 0, 0.54)", float: "right" }}
                      >
                        <i>
                          {sourceFundList.length} Account{" "}
                          {sourceFundList.length > 1 ? "codes" : "code"} found
                        </i>
                      </Material.Typography>
                      <Material.FormControl
                        fullWidth
                        sx={{ m: 1 }}
                        variant="standard"
                      >
                        <Material.InputLabel
                          htmlFor={`standard-adornment-amount-${index}`}
                        >
                          Allocated Amount
                        </Material.InputLabel>
                        <Material.Input
                          id={`standard-adornment-amount-${index}`}
                          value={row.fundSourceAllocatedAmount}
                          onChange={(e) =>
                            this.handleFundSourceAllocatedAmountChange(e, index)
                          }
                          startAdornment={
                            <Material.InputAdornment position="start">
                              ₱
                            </Material.InputAdornment>
                          }
                        />
                      </Material.FormControl>

                      {rows.length > 1 ? (
                        <Material.Button
                          variant="text"
                          color="secondary"
                          onClick={() => this.removeRow(index)}
                          style={{ float: "right" }}
                        >
                          - Remove Account Code
                        </Material.Button>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </Material.Grid>
              <Material.Grid>
                <Material.Button
                  paddingBottom="5"
                  variant="text"
                  color="primary"
                  onClick={this.addRow}
                >
                  + Add Account Code
                </Material.Button>
              </Material.Grid>
              {rows.map((row, index) => (
                <React.Fragment key={index}>
                  <Material.Grid container spacing={1}>
                    <Material.Collapse in={Boolean(row.fundSource)}>
                      <Material.Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Material.Grid style={{ marginTop: 5 }} item xs={12}>
                          <Material.Typography color="secondary">
                            Account Code {index + 1} Details
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid item xs={6}>
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            F.P.P.:
                          </Material.Typography>
                          <Material.Grid style={{ display: "inline-block" }}>
                            {row.fundSource && (
                              <Material.Typography
                                className="wrap"
                                variant="subtitle1"
                              >
                                {row.fundSource.ppa_code}:{" "}
                                {row.fundSource.ppa_desc}
                              </Material.Typography>
                            )}
                          </Material.Grid>
                        </Material.Grid>

                        <Material.Grid item xs={6} style={{ paddingTop: 5 }}>
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Balance:
                          </Material.Typography>
                          {row.fundSource && (
                            <Material.Typography
                              className="inline"
                              variant="subtitle1"
                            >
                              Php {row.fundSource.allot_balance}
                            </Material.Typography>
                          )}
                        </Material.Grid>
                        <Material.Grid item xs={6}>
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            RESPO:
                          </Material.Typography>
                          {row.fundSource && (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.respo_desc}
                            </Material.Typography>
                          )}
                        </Material.Grid>
                        <Material.Grid item xs={6}>
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Legal Basis:
                          </Material.Typography>
                          {row.fundSource && (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.ra_desc}
                            </Material.Typography>
                          )}
                        </Material.Grid>
                        <Material.Grid item xs={6}>
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Year:
                          </Material.Typography>
                          {row.fundSource && (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.ra_year}
                            </Material.Typography>
                          )}
                        </Material.Grid>
                        <Material.Grid item xs={6}>
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Allotted Class:
                          </Material.Typography>
                          {row.fundSource && (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.allot_type}
                            </Material.Typography>
                          )}
                        </Material.Grid>
                        <Material.Grid item xs={6}>
                          <Material.Typography
                            color="primary"
                            variant="subtitle1"
                          >
                            Fund Type:
                          </Material.Typography>
                          {row.fundSource && (
                            <Material.Typography
                              className="wrap"
                              variant="subtitle1"
                            >
                              {row.fundSource.fund_type}
                            </Material.Typography>
                          )}
                        </Material.Grid>
                      </Material.Grid>
                    </Material.Collapse>
                  </Material.Grid>
                </React.Fragment>
              ))}
            </Material.Grid>
          </div>
        );
      case 1:
        return (
          <div>
            <div className="title">
              <Material.Typography variant="subtitle1">
                Select Target Month
              </Material.Typography>
            </div>
            <Material.Divider></Material.Divider>
            <div>
              <Material.Grid container spacing={3}>
                <Material.Grid item xs={6}>
                  <Material.TextField
                    fullWidth
                    type="month"
                    label="  "
                    name="month_selected"
                    value={this.state.month_selected}
                    onChange={this.onChangeHandler}
                  />
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.FormControl className={classes.formControl}>
                    <Material.InputLabel>Quantity</Material.InputLabel>
                    <Material.Input
                      disabled
                      value="1"
                      type="number"
                      label="Quantity"
                      onChange={this.onChangeHandler}
                      endAdornment={
                        <Material.InputAdornment position="end">
                          lot
                        </Material.InputAdornment>
                      }
                    />
                  </Material.FormControl>
                </Material.Grid>
              </Material.Grid>
            </div>
          </div>
        );
      case 2:
        // const { classes} = this.props;
        // const { editDataQty } = this.state;
        return (
          <div>
            {/* <Material.Grid
							// aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
							onRendered={this.onHandleEdit}
							// editdata={editDataQty}
						> */}
            <div className="title">
              <Material.Typography variant="subtitle1">
                File Upload
              </Material.Typography>
            </div>
            <Material.Divider></Material.Divider>
            <div>
              <Material.Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="center"
                display="flex"
                className={classes.formControlMax}
              >
                <Material.Grid item xs={6}>
                  {/* <Material.FormControl className={classes.formControlMex}>
										<Material.Typography
											className={classes.textField}
											color="primary"
										>
											Upload File (accepts PDF file only):
										</Material.Typography>
										<Material.Input
											fullWidth
											type="file"
											inputProps={{ accept: "application/pdf" }}
											onChange={this.onHandleFileSelect}
										/>
									</Material.FormControl> */}
                  {/* <div
										style={{
											display: "inline-block",
											justifyContent: "center",
										}}
									> */}
                  <Material.TextField
                    className={classes.textField}
                    disabled
                    label="PDF File"
                    defaultValue="Select .pdf file..."
                    value={this.state.filename}
                    variant="standard"
                    name="filename"
                  />
                  {/* </div> */}
                </Material.Grid>
                <Material.Grid
                  item
                  xs={3}
                // alignItems="stretch"
                // style={{ display: "flex" }}
                >
                  <Material.Button
                    variant="contained"
                    component="label"
                    size="small"
                  >
                    Browse...
                    <input
                      type="file"
                      hidden
                      accept="application/pdf"
                      onChange={this.onHandleFileSelect}
                    />
                  </Material.Button>
                </Material.Grid>
              </Material.Grid>
            </div>
            {/* </Material.Grid> */}
          </div>
        );
      default:
        return (
          <div className={classes.loading}>
            <Material.FormControlLabel
              control={<Material.CircularProgress />}
              checked={false}
              label="Loading..."
            />
          </div>
        );
    }
  }
  updateState(step) {
    const { rows } = this.state;
  
    // Create a new array of rows with the updated fundSourceAllocatedAmount
  const updatedRows = rows.map(row => {
    // Remove commas, parse as float, and format to 2 decimal places
    let allocated_amount = parseFloat(row.fundSourceAllocatedAmount.toString().replace(/,/g, '')).toFixed(2);
    // console.log('$$allocatedAmount:', allocated_amount);
    return { ...row, fundSourceAllocatedAmount: allocated_amount };
  });

    // Update the state with the new array of rows
    this.setState({ rows: updatedRows }, () => {
      this.onClickNextStep(step);
    });
  }
  onClickNextStep(step) {
    this.setState({
      ...this.state,
      activeStep: this.state.activeStep + step,
    });
  }
  render() {
    const {
      modalVisible,
      onCloseModal,
      classes,
      powTitle,
      powDescription,
      powLocation,
      powProjectCost,
    } = this.props;
    const { activeStep, stepper } = this.state;
    return (
      <Material.Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        onRendered={this.initRespoCenter}
        className={classes.modal}
        open={modalVisible}
        // onClose={onCloseModal}
        closeAfterTransition
        disableAutoFocus={true}
        BackdropComponent={Material.Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.root}>
          <Material.Fade in={modalVisible}>
            <div className={classes.paper}>
              <Material.Grid container spacing={0}>
                <Material.Grid item xs={10}>
                  <div className="inline">
                    <Material.Typography
                      className="flex"
                      color="primary"
                      variant="h5"
                    >
                      {powTitle}
                    </Material.Typography>
                  </div>
                  <div className={classes.description}>
                    <Material.Tooltip title="Description Here">
                      <Material.Typography
                        className="title"
                        variant="subtitle1"
                      >
                        {`${powDescription} - ${powLocation}`}
                      </Material.Typography>
                    </Material.Tooltip>
                  </div>
                </Material.Grid>
                <Material.Grid item xs={2}>
                  <Material.Typography variant="h6" className="pull-right">
                    {powProjectCost}
                  </Material.Typography>
                  <span className="pull-right padded">Php</span>
                </Material.Grid>
              </Material.Grid>
              <Material.Divider></Material.Divider>
              <div className={classes.FormContainer}>
                <Material.Paper elevation={4} className={classes.paperFunds}>
                  <Material.Stepper
                    className={classes.stepperCont}
                    activeStep={activeStep}
                    alternativeLabel
                  >
                    {stepper.map((label) => (
                      <Material.Step key={label}>
                        <Material.StepLabel>{label}</Material.StepLabel>
                      </Material.Step>
                    ))}
                  </Material.Stepper>
                </Material.Paper>
                {this.getStepContent(activeStep)}
              </div>
              <form noValidate autoComplete="off">
                <Material.Grid container spacing={0}>
                  <Material.Grid item xs={6}>
                    <Material.Button
                      className={classes.action}
                      variant="outlined"
                      color="secondary"
                      onClick={onCloseModal}
                    >
                      {" "}
                      Cancel{" "}
                    </Material.Button>
                  </Material.Grid>
                  {activeStep !== stepper.length ? (
                    <Material.Grid item xs={6} style={{ textAlign: "right" }}>
                      <Material.Button
                        className={classes.action}
                        disabled={activeStep === 0}
                        onClick={() => this.updateState(-1)}
                      >
                        Back{" "}
                      </Material.Button>
                      <Material.Button
                        className={classes.action}
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          activeStep === stepper.length - 1
                            ? this.onClickSave()
                            : this.updateState(1)
                        }
                        disabled={this.state.invalidState}
                      >
                        {activeStep === stepper.length - 1 ? "Save" : "Next"}
                      </Material.Button>
                    </Material.Grid>
                  ) : (
                    ""
                  )}
                </Material.Grid>
              </form>
            </div>
          </Material.Fade>
          <Material.Dialog open={this.state.error_dialog_visible}>
            <Material.DialogTitle className={classes.yellow}>
              {"WARNING!!"}
            </Material.DialogTitle>
            <Material.DialogContent>
              <Material.DialogContentText>
                {this.state.error_dialog_msg}
              </Material.DialogContentText>
            </Material.DialogContent>
            <Material.DialogActions>
              <Material.Button
                onClick={() => {
                  this.setState({
                    ...this.state,
                    error_dialog_visible: false,
                  });
                }}
                autoFocus
              >
                Okay
              </Material.Button>
            </Material.DialogActions>
          </Material.Dialog>
        </div>
      </Material.Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Material.withStyles(useStyles)(
    React.forwardRef((props, ref) => <EditModal innerRef={ref} {...props} />)
  )
);
