import React from "react";
import * as Material from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ImportExportIcon from '@material-ui/icons/GetApp';

import { useStyles } from "./annual.ppmp.style";
import { COMM } from "../../helpers/common";
import { mapStateToProps, mapDispatchToProps } from './annual.ppmp.reducer';

const initialState = {
    rows: [
        {   
            "id": 1,
            "item_name": "Multipaper A4 Bondpaper",
            "category": "Office Supplies",
            "description": "Multipaper A4 Bondpaper description goes here Multipaper A4 Bondpaper description goes here Multipaper A4 Bondpaper description goes here",
            "unit": "rim",
            "amount": "284.75",
            "offices_who_purchased": [
                { "office_name": 'BICTO', "quantity_purchased": '50', "amount_purchased": 1121.65 },
                { "office_name": 'PACCO', "quantity_purchased": '150', "amount_purchased": 10232.43 },
            ],
        },
        {
            "id": 2,
            "item_name": "Stapler Heave Duty",
            "category": "Office Supplies",
            "description": "Stapler Heave Duty description goes here Multipaper A4 Bondpaper description goes here Multipaper A4 Bondpaper description goes here",
            "unit": "piece",
            "amount": "100.75",
            "offices_who_purchased": [
                { "office_name": 'PADMO', "quantity_purchased": '3', "amount_purchased": 301.21 },
                { "office_name": 'PTO', "quantity_purchased": '5', "amount_purchased": 500.32 },
            ],
        }
    ],
    targetData: {},
    dialogVisible: false,
    dialogConfirm: false,
    dialogType: "",
    initDataToExport: {}
}
class AnnualPPMPTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...initialState};
        this.triggerCollapse = this.triggerCollapse.bind(this);
        this.onHandleDialog = this.onHandleDialog.bind(this);
        this.onDialogConfirmed = this.onDialogConfirmed.bind(this);
        this.getInitDataToExport = this.getInitDataToExport.bind(this);
        this.fileExport = React.createRef();
    }
    triggerCollapse(index){
        const { rows } = this.state;
        let data = [...rows];
        data[index].isCollapse =  !data[index].isCollapse;
        this.setState({...this.state, rows: [...data]},()=>{});
    }
    onHandleDialog(row, value, type = ""){
        this.setState({...this.state, targetData: row, dialogVisible: value, dialogConfirm: false, dialogType: type  });
    }
    onDialogConfirmed(){
        const { initDataToExport } = this.state;

        // let itemDataToExport = initItemDataToExport.map((element)=>{
        //     return {...element,
        //         [`transdate`] : element.transdate ? COMM.dateConverter(element.transdate) : ""
        //     };
        // })
        this.setState({...this.state, dataToExport: initDataToExport },()=>{this.onExport()});
    }
    getInitDataToExport(){
        // const { displayLoading, displayAlert, items } = this.props;
        // const { itemsTotal } = items;

        // displayLoading(true);
        // ItemService.getItems({ ...this.state, limit: itemsTotal }).then((response)=>{
        //     if(response.error){
        //         displayAlert({ visible: true, message: `${response.message}`, severity: "error" });
        //     } else{
        //         this.setState({ initItemDataToExport: response.result.result0 },()=>{
        //             this.onDialogConfirmed()
        //         });
        //     }
        //     displayLoading(false);
        // }).catch((err)=>{
        //     displayLoading(false);
        //     displayAlert({ visible: true, message: `${err}`, severity: "error" });
        // })
        this.setState({ initDataToExport: this.state.rows },()=>{
            this.onDialogConfirmed()
        });
    }
    confirmExport() {
        if(this.state.dialogConfirm)
            this.fileExport.current.link.click()
    }
    onExport(){
        this.setState({...this.state, targetData: {}, dialogVisible: false, dialogConfirm: true },()=>
            this.confirmExport()
        );
    }
    render(){
        const { classes } = this.props;
        const { rows } = this.state;
        const TableHeaderExp = [
            { id: 'ItemCodeheader', label: 'Item Code',key: "id", field: "id"},
            { id: 'Nameheader', label: 'Name',key: "item_name", field: "item_name"},
            { id: 'Categoryheader', label: 'Category',key: "category", field: "category"},
            { id: 'Descriptionheader', label: 'Description',key: "description", field: "description"},
            { id: 'Unitheader', label: 'Unit',key: "unit", field: "unit"},
            { id: 'Amountheader', label: 'Amount',key: "amount", field: "amount"},
            { id: 'Office', label: 'Office',key: "office_name", field: "office_name"},
            { id: 'QuantityPurchasedheader', label: 'Quantity Purchase',key: "quantity_purchased", field: "quantity_purchased"},
            { id: 'Totalamountpurchasedheader', label: 'Total Amount Purchased',key: "amount_purchased", field: "amount_purchased"},
        ];
        return(
            <Material.TableContainer component={Material.Paper}>
                <Material.Table aria-label="collapsible table">
                    <Material.TableHead>
                        <Material.TableRow>
                            <Material.TableCell></Material.TableCell>
                            <Material.TableCell>Item Name</Material.TableCell>
                            <Material.TableCell align="right">Category</Material.TableCell>
                            <Material.TableCell align="right">Description</Material.TableCell>
                            <Material.TableCell align="right">Unit</Material.TableCell>
                            <Material.TableCell align="right">Price</Material.TableCell>
                        </Material.TableRow>
                    </Material.TableHead>
                    <Material.TableBody>
                    {rows.map((row,index) => (
                        <React.Fragment key={row.id}>
                        <Material.TableRow className={classes.root} key={row.id}>
                            <Material.TableCell>
                            <Material.IconButton aria-label="expand row" size="small" onClick={()=>{this.triggerCollapse(index)}}>
                                {Boolean(row.isCollapse) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Material.IconButton>
                            </Material.TableCell>
                            <Material.TableCell component="th" scope="row">
                                {row.item_name}
                            </Material.TableCell>
                            <Material.TableCell align="right">{row.category}</Material.TableCell>
                            <Material.TableCell align="right">{row.description}</Material.TableCell>
                            <Material.TableCell align="right">{row.unit}</Material.TableCell>
                            <Material.TableCell align="right">{row.amount}</Material.TableCell>
                        </Material.TableRow>
                        <Material.TableRow>
                            <Material.TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Material.Collapse in={Boolean(row.isCollapse)} timeout="auto" unmountOnExit>
                                <Material.Box margin={1}>
                                <Material.Typography variant="h6" gutterBottom component="div">
                                    Offices
                                </Material.Typography>
                                <Material.Table size="small" aria-label="purchases">
                                    <Material.TableHead>
                                    <Material.TableRow>
                                        <Material.TableCell>Name</Material.TableCell>
                                        <Material.TableCell>Quantity</Material.TableCell>
                                        <Material.TableCell align="right">Amount</Material.TableCell>
                                    </Material.TableRow>
                                    </Material.TableHead>
                                    <Material.TableBody>
                                    {row.offices_who_purchased.map((offices) => (
                                        <Material.TableRow key={offices.amount_purchased}>
                                        <Material.TableCell component="th" scope="row">
                                            {offices.office_name}
                                        </Material.TableCell>
                                        <Material.TableCell>{offices.quantity_purchased}</Material.TableCell>
                                        <Material.TableCell align="right">{offices.amount_purchased}</Material.TableCell>
                                        </Material.TableRow>
                                    ))}
                                    </Material.TableBody>
                                </Material.Table>
                                </Material.Box>
                            </Material.Collapse>
                            </Material.TableCell>
                        </Material.TableRow>
                        </React.Fragment>
                    ))}
                        
                    </Material.TableBody>
                    <Material.TableFooter>
                        {/* <Material.TableRow>
                            <Material.TablePagination
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                count={items.itemsTotal} rowsPerPage={limit} page={page_no}
                                SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                onChangePage={this.onHandleChangePage}
                                onChangeRowsPerPage={this.onHandleChangeRowsPerPage}
                                ActionsComponent={this.TablePaginationActions}
                            />
                        </Material.TableRow> */}
                    </Material.TableFooter>
                </Material.Table>{/* export dialog */}
                <div style={{float: "left",padding: "10px"}}>
                    <Material.Button size="small" variant="contained" startIcon={<ImportExportIcon />} color="primary" style={{marginRight: "5px"}} onClick={()=> this.onHandleDialog({title: "Export", content: "Are you sure do you want to export data?"},true,"export")}>Export</Material.Button>
                    <CSVLink data={rows} headers={TableHeaderExp} filename={`Annual Procurement Plan (${COMM.dateConverter(new Date())}).csv`} ref={this.fileExport}></CSVLink>
                </div>
                <Material.Dialog
                    open={this.state.dialogVisible}
                    onClose={()=> this.onHandleDialog({},false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <Material.DialogTitle id="alert-dialog-title">{`${this.state.targetData.title}?`}</Material.DialogTitle>
                    <Material.DialogContent>
                    <Material.DialogContentText id="alert-dialog-description">
                        {`${this.state.targetData.content}`}
                    </Material.DialogContentText>
                    </Material.DialogContent>
                    <Material.DialogActions>
                        <Material.Button  onClick={()=> this.onHandleDialog(this.state.targetData,false)} color="secondary">
                            No
                        </Material.Button>
                        <Material.Button  onClick={this.getInitDataToExport} color="primary" autoFocus>
                            Yes
                        </Material.Button>
                    </Material.DialogActions>
                </Material.Dialog>
            </Material.TableContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AnnualPPMPTable));