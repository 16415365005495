export const useStyles = (theme) => ({
  root: {
    "& .animate:hover": {
      backgroundColor: "rgba(8, 0, 74, 0.15)",
      transform: "scale(1.1)",
    },
    "& .emptyCont": {
      padding: theme.spacing(4),
      "& .emptyBorder": {
        padding: theme.spacing(8, 4),
        borderRadius: "10px",
        borderStyle: "dashed",
        borderColor: "rgba(29, 29, 29, 0.10)",
      },
      "& .empty": {
        padding: theme.spacing(4),
        backgroundColor: "rgba(29, 29, 29, 0.10)",
        borderRadius: "10px",
        textAlign: "center",
        color: "gray",
      },
    },
  },
  drawer: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: 250,
    height: "97%",
    "& .MuiFormControl-root": {
      display: "flex",
    },
    "& .MuiFormControlLabel-root": {
      paddingLeft: theme.spacing(2),
    },
    "& .radio-choices:hover": {
      "& .MuiButtonBase-root": {
        backgroundColor: "rgba(8, 0, 74, 0.15)",
        transform: "scale(1.1)",
      },
      "& .MuiFormControlLabel-label": {
        transform: "scale(1.1)",
      },
    },
  },
  iconButton: {
    padding: "10px",
  },
  toolRight: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  chipContainer: {
    padding: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  formControlMax: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
  table: {
    minWidth: 700,
    "& .yellow": {
      backgroundColor: "#F6EAB8",
    },
    "& .lightgrey": {
      backgroundColor: "lightgray",
    },
    "& .blue": {
      backgroundColor: "#7BA6EF",
    },
    "& .lightblue": {
      backgroundColor: "#BAD7F8",
    },
    "& .lightfink": {
      backgroundColor: "#f7d6f6",
    },
    "& .lightmint": {
      backgroundColor: "#d1ffd1",
    },
  },
  tableFooter: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  actionItem: {
    display: "flex",
    "& > *": {
      align: "right",
    },
    "& .animate": {
      "&:hover": {
        align: "right",
        backgroundColor: "rgba(8, 0, 74, 0.15)",
        transform: "scale(1.1)",
      },
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(29, 29, 29, 0.10)",
      transform: "scale(1)",
    },
  },
  animate: {
    "&:hover": {
      backgroundColor: "rgba(8, 0, 74, 0.15)",
      transform: "scale(1.1)",
    },
  },
  approved: {
    backgroundColor: "#d1ffd1",
    color: "green",
    "& .MuiChip-avatar": {
      color: "green",
    },
    maxWidth: "300px"
  },
  pending: {
    backgroundColor: "#ffebc7",
    color: "orange",
    "& .MuiChip-avatar": {
      color: "orange",
    },
    maxWidth: "300px"
  },
  rejected: {
    backgroundColor: "#ffc9c9",
    color: "red",
    "& .MuiChip-avatar": {
      color: "red",
    },
    maxWidth: "300px"
  },
  paperless_ref: {
    padding: theme.spacing(1),
    backgroundColor: 'rgba(29, 29, 29, 0)',
    backgroundImage: 'linear-gradient(to left, rgba(29, 29, 29, 0.35) , rgba(29, 29, 29, 0))',
    color: 'transparent',
    borderRadius: '55px',
    border: 'none',
    elevation: 0,
    boxShadow: 'none',
    width: '300px',
    display: 'flex',
    justifyContent: 'right'
},
actionList: {
    margin: theme.spacing(0, 1, 0, 0),
}
});
