export default (state = {
    userDatasource: [],
    userroleDatasource: [],
    userProfileDatasource: [],
    userTotal: 0,
    userOffices: [],
    userFilter: [],
}, action) => {
    switch (action.type){
        case "SETUSERDATASOURCE":
            return state = {
                ...state,
                userDatasource: action.payload.userDatasource,
				userTotal: action.payload.userTotal,
				userFilter: action.payload.userFilter,
            }
        case "SETUSERPROFILEDATASOURCE":
            return state = {
                ...state,
                userProfileDatasource: action.payload.userProfileDatasource,
            }
        case "SETUSERROLEDATASOURCE":
            return state = {
                ...state,
                userroleDatasource: action.payload.userroleDatasource,
            }
        case "SETOFFICES":
            return state = {
                ...state,
                userOffices: action.payload.userOffices,
            }
        case "DROPUSERSTATE":
            return state = {
                userDatasource: [],
                userroleDatasource: [],
                userProfileDatasource: [],
                userTotal: 0,
                userOffices: [],
                userFilter: [],
            }
        default:
            return state;
        
    }
};
