import React from "react";
import { connect } from "react-redux";
import * as Material from "@material-ui/core";
import { useStyles } from "./payments.info.modal.style";
import * as PaymentsService from "./payments.service";
import Loading from "../common/loading";

class PaymentsInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dg_transaction_number: "",
      office_id: "",
      show_detail: 0,
      show_detail_checked: false,
      infos: [],
    };
    this.requestGetPaymentsInfo = this.requestGetPaymentsInfo.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.onCheckHandlerCreate = this.onCheckHandlerCreate.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.setState(
        {
          dg_transaction_number: data.dg_transaction_number,
          office_id: data.office_id,
        },
        this.requestGetPaymentsInfo
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      this.setState(
        {
          dg_transaction_number: data.dg_transaction_number,
          office_id: data.office_id,
        },
        this.requestGetPaymentsInfo
      );
    }
  }

  handleRequestSort(event, property) {
    const { sort_column, sort_type } = this.state;
    const isAsc = sort_column === property && sort_type === "asc";

    this.setState(
      {
        sort_column: property,
        sort_type: isAsc ? "desc" : "asc",
      },
      this.requestGetPaymentsInfo
    );
  }

  requestGetPaymentsInfo() {
    const { displayAlert, data } = this.props;
    const { dg_transaction_number, office_id, show_detail } = this.state;

    const toPass = {
      dg_transaction_number,
      office_id,
      show_detail,
    };

    PaymentsService.getPaymentsInfo({ toPass })
      .then((response) => {
        if (!response.error) {
          this.setState({ infos: response.data });
        } else {
          this.setState({ infos: [] });
        }
      })
      .catch((err) => {
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }

  onCheckHandlerCreate(e) {
    const isChecked = e.target.checked;
    this.setState(
      {
        show_detail_checked: isChecked,
        show_detail: isChecked ? 1 : 0,
      },
      this.requestGetPaymentsInfo
    );
  }

  onCancel() {
    this.props.dataHandler({ open: false, data: {} });
  }

  render() {
    const { modalVisible, classes } = this.props;
    const { sort_column, sort_type, infos, loadingVisible, show_detail } =
      this.state;

    const TableHeaderOp = [
      {
        id: "Responsibility Center",
        numeric: false,
        disablePadding: false,
        label: "Responsibility Center",
        align: "center",
        field: "respo_code",
      },
      {
        id: "Particulars",
        numeric: false,
        disablePadding: false,
        label: "Particulars",
        align: "center",
        field: "_code_description",
      },
      {
        id: "F.P.P",
        numeric: false,
        disablePadding: false,
        label: "F.P.P",
        align: "center",
        field: "ppa_code",
      },
      {
        id: "Account Code",
        numeric: false,
        disablePadding: false,
        label: "Account Code",
        align: "center",
        field: "account_code",
      },
      {
        id: "Total Cost",
        numeric: false,
        disablePadding: false,
        label: "Total Cost",
        align: "center",
        field: "_total_cost",
      },
    ];

    const TableHeaderAlt = [
      {
        id: "Item Name",
        numeric: false,
        disablePadding: false,
        label: "Item Name",
        align: "center",
        field: "_item_name",
      },
      {
        id: "Responsibility Center",
        numeric: false,
        disablePadding: false,
        label: "Responsibility Center",
        align: "center",
        field: "respo_code",
      },
      {
        id: "Particulars",
        numeric: false,
        disablePadding: false,
        label: "Particulars",
        align: "center",
        field: "_code_description",
      },
      {
        id: "F.P.P",
        numeric: false,
        disablePadding: false,
        label: "F.P.P",
        align: "center",
        field: "ppa_code",
      },
      {
        id: "Account Code",
        numeric: false,
        disablePadding: false,
        label: "Account Code",
        align: "center",
        field: "account_code",
      },

      {
        id: "Unit Cost",
        numeric: false,
        disablePadding: false,
        label: "Unit Cost",
        align: "center",
        field: "unit_cost",
      },
      {
        id: "Total Cost",
        numeric: false,
        disablePadding: false,
        label: "Total Cost",
        align: "center",
        field: "_total_cost",
      },
    ];

    return (
      <Material.Dialog
        open={modalVisible}
        closeAfterTransition
        disableAutoFocus={true}
        BackdropComponent={Material.Backdrop}
        BackdropProps={{ timeout: 500 }}
        maxWidth="md"
        fullWidth
      >
        <div className={classes.root}>
          <Material.Grid
            container
            style={{ paddingRight: 15, paddingLeft: 15 }}
            spacing={2}
            direction="row"
            justifyContent="center"
          >
            <Material.Grid
              style={{ paddingTop: 20, paddingRight: 15, paddingBottom: 15 }}
              container
              justify="flex-end"
            >
              <Material.FormControlLabel
                control={
                  <Material.Checkbox
                    checked={this.state.show_detail_checked}
                    onChange={this.onCheckHandlerCreate}
                  />
                }
                label="Show Detailed ?"
                labelPlacement="start"
                style={{ paddingRight: "16px", paddingTop: "8px" }}
              />
            </Material.Grid>

            <Material.Grid item xs={12}>
              <Material.Paper className={classes.paper}>
                <Material.TableContainer component={Material.Paper}>
                  <Loading modalVisible={loadingVisible} />
                  <Material.Table className={classes.table}>
                    <Material.TableHead>
                      <Material.TableRow>
                        {(show_detail === 0
                          ? TableHeaderOp
                          : TableHeaderAlt
                        ).map((option) => (
                          <Material.TableCell
                            key={option.id}
                            align={option.align}
                            sortDirection={
                              sort_column === option.id ? sort_type : false
                            }
                          >
                            <Material.TableSortLabel
                              active={sort_column === option.id}
                              direction={
                                sort_column === option.id ? sort_type : "asc"
                              }
                              onClick={(event) =>
                                this.handleRequestSort(event, option.field)
                              }
                            >
                              {option.label}
                            </Material.TableSortLabel>
                          </Material.TableCell>
                        ))}
                      </Material.TableRow>
                    </Material.TableHead>
                    <Material.TableBody>
                      {infos.map((row) => (
                        <Material.TableRow
                          key={row.id}
                          className={classes.tableRow}
                        >
                          {show_detail === 0 ? (
                            <>
                              <Material.TableCell align="center">
                                {row.respo_code}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row.account_code_description}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row.ppa_code}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row.account_code}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row._total_cost}
                              </Material.TableCell>
                            </>
                          ) : (
                            <>
                              <Material.TableCell align="center">
                                {row._item_name}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row.respo_code}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row.account_code_description}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row.ppa_code}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row.account_code}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row.unit_cost}
                              </Material.TableCell>
                              <Material.TableCell align="center">
                                {row._total_cost}
                              </Material.TableCell>
                            </>
                          )}
                        </Material.TableRow>
                      ))}
                    </Material.TableBody>
                  </Material.Table>
                </Material.TableContainer>
              </Material.Paper>
            </Material.Grid>
          </Material.Grid>

          <form
            style={{ paddingTop: 20, paddingBottom: 15, paddingRight: 15 }}
            align="right"
          >
            <Material.Button color="secondary" onClick={this.onCancel}>
              Close
            </Material.Button>
          </form>
        </div>
      </Material.Dialog>
    );
  }
}

export default Material.withStyles(useStyles)(PaymentsInfoModal);
