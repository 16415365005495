import React from "react";
import { connect } from 'react-redux';
import * as Material from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "./annual.ppmp.style";

import AnnualPPMPTable from "./annual.ppmp.table";
import { mapStateToProps, mapDispatchToProps } from './annual.ppmp.reducer';

const initialState = {
    drawerVisible: false,
    filter_category_name: "",
    tabIndex: 0,
    prevIndex: 0,
    tab1Trans: true,
    tab2Trans: false,
    tab3Trans: false,
    tab4Trans: false,
}
class AnnualPPMP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...initialState};
        this.onToggleFilter = this.onToggleFilter.bind(this);
        this.tableFilterContainer = this.tableFilterContainer.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
    }
    onChangeHandler(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
    onToggleFilter() {
        this.setState({ drawerVisible: !this.state.drawerVisible });
    }
    onTabChange(e, v) {
        this.setState({
            ...this.state,
            // drawerVisible: false,
            prevIndex: this.state.tabIndex,
            tabIndex: v,
            tab1Trans: Boolean(v === 0),
            tab2Trans: Boolean(v === 1),
            tab3Trans: Boolean(v === 2),
            tab4Trans: Boolean(v === 3),
        });
    }
    tableFilterContainer() {
        const { classes } = this.props;
        const options = [
            {
                key: 1,
                label: "Category",
                value: this.state[`filter_category_name`],
                target: "category_name",
                handleChange: this.onChangeHandler
            },
            // {
            //     key: 2,
            //     label: "Unit",
            //     value: this.state[`filter_unit`],
            //     target: "unit",
            //     handleChange: this.onHandleFilterChange
            // },
        ];
        return <Material.Paper className={classes.drawer}>
             <Material.FormControl
                    component="fieldset"
                    key={options.key}
                    margin="normal"
                    size="small"
                    variant="outlined">
                <Material.FormLabel filled>Category</Material.FormLabel>
                    <Material.Divider></Material.Divider>
                    <Material.RadioGroup
                        aria-label={options.target}
                        name={`filter_${options.target}`}
                        value={options.value}
                        onChange={options.handleChange}>
                        {/* {items.itemsFilter.filter((obj) => obj._particular === option.target).map((filter, index) => {
                            return <Material.FormControlLabel
                                key={`${filter._value}-${index}`}
                                value={filter._value}
                                control={<Material.Radio size="small" />}
                                label={filter._value} />
                        })} */}
                        <Material.FormControlLabel value="Office Supplies" control={<Material.Radio />} label="Office Supplies" />
                        <Material.FormControlLabel value="IT Equipments" control={<Material.Radio />} label="IT Equipments" />
                        <Material.FormControlLabel value="Goods" control={<Material.Radio />} label="Goods" />
                    </Material.RadioGroup>
            </Material.FormControl>
            <Material.Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<FilterListIcon />}
                onClick={console.log("request here")} >
                Apply
            </Material.Button>
        </Material.Paper>
    }
    render(){
        const { classes } = this.props;
        const { drawerVisible, tabIndex, tab1Trans, tab2Trans, tab3Trans, tab4Trans } = this.state;
        return(
            <div className={classes.root}>
                <Material.Paper className={classes.transactionHeader}>
					<Material.Grid container spacing={3}>
						<Material.Grid item xs={12}>
							<div className="inline">
								<Material.Typography className="flex" color="primary" variant="h5">2022</Material.Typography>
                            </div>
						</Material.Grid>
					</Material.Grid>
				</Material.Paper>
                <Material.Grid container spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="stretch">
                    {drawerVisible ?
                        <Material.Grid item xs={12} sm={3}>
                            <Material.Slide direction="right" in={drawerVisible} mountOnEnter unmountOnExit>
                                {this.tableFilterContainer()}
                            </Material.Slide>
                        </Material.Grid> : ""}
                    <Material.Grid item xs={12} sm={drawerVisible ? 9 : 12}>
                        <Material.Paper className={classes.paper}>
                            <Material.Toolbar className={classes.toolbar}>
                                <div className={classes.toolRight}>
                                    <Material.Tooltip title="Toggle Filter list">
                                        <Material.IconButton aria-label="filter list" color="primary" onClick={() => { this.onToggleFilter() }}>
                                            <FilterListIcon />
                                        </Material.IconButton>
                                    </Material.Tooltip>
                                </div>
                            </Material.Toolbar>
                            <div>
                                <Material.Tabs onChange={this.onTabChange}
                                    value={tabIndex}
                                    className={classes.tabs}
                                    indicatorColor="primary"
                                    aria-label="admin-tab" >
                                    <Material.Tab className={classes.tab} label="1st Quarter" />
                                    <Material.Tab className={classes.tab} label="2nd Quarter" />
                                    <Material.Tab className={classes.tab} label="3rd Quarter" />
                                    <Material.Tab className={classes.tab} label="4th Quarter" />
                                </Material.Tabs>
                                <div className={classes.tabContainer} >
                                    <Material.Slide direction={"up"}
                                        in={tab1Trans} mountOnEnter unmountOnExit >
                                        <div className={classes.paperB}>
                                            <AnnualPPMPTable />
                                        </div>
                                    </Material.Slide>
                                    <Material.Slide direction={"up"}
                                    // tab2Trans ? (Number(prevIndex) < 1 ? "left" : "right") : "right"
                                        in={tab2Trans} mountOnEnter unmountOnExit>
                                        <div className={classes.paperB}>
                                            "2 here"
                                        </div>
                                    </Material.Slide>
                                    <Material.Slide direction={"up"}
                                        in={tab3Trans} mountOnEnter unmountOnExit>
                                        <div className={classes.paperB}>
                                            "3 here"
                                        </div>
                                    </Material.Slide>
                                    <Material.Slide direction={"up"}
                                        in={tab4Trans} mountOnEnter unmountOnExit>
                                        <div className={classes.paperB}>
                                            "4 here"
                                        </div>
                                    </Material.Slide>
                                </div>
                            </div>
                        </Material.Paper>
                    </Material.Grid>
                </Material.Grid>
            </div >
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AnnualPPMP));