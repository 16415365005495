import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";
// import jwt_decode from 'jwt-decode';
import { setAPIauth } from "../../helpers/axios.instance";
import * as Material from "@material-ui/core";
import jwt_decode from "jwt-decode";

// import StickyFooter from 'react-sticky-footer';
import Container from "@material-ui/core/Container";

import Navigation from "../navigation/navigation";
import AnnualPPMP from "../annual.ppmp/annual.ppmp";
import Activities from "../activities/activities";
import Pow from "../pow/pow";
import Payments from "../payments/payments";
import ConsumedPPMP from "../consumption/consumption";
import PPMP from "../ppmp/ppmp";
import PPMPSupplies from "../ppmp/supplies/ppmp.supplies";
import PriceRequestList from "../../components/admin/price.request.list/price.request.list";
import PPMPList from "../ppmp/list/ppmp.list";
import VIEWList from "../ppmp/list/view.list";
import ForApproval from "../ppmp.items.approval/ppmp.per_office";
import ItemListForApproval from "../ppmp.items.approval/ppmp.items.list.per_office";
import UnforeseenItemListForApproval from "../unforeseen.ppmp/unforeseen.ppmp.list.per_office";
import UnforeseenPPMP from "../unforeseen.ppmp/unforeseen.ppmp.per_office";
import Deliverables from "../deliverables/deliverables";
// import PR from '../pr/pr';
// import PO from '../po/po';
// import PRList from '../pr/list/pr.list';
// import Admin from "../admin/admin";
import Login from "../auth/login";
import Logout from "../auth/logout";
import Loading from "../common/loading";
import SessionLoading from "./session.loading";
import Alert from "../common/alert";
import ErrorUA from "../error/error.unauthorized";
import PPMPform from "../ppmp/form/ppmp.form";
// import PRform from '../pr/form/pr.form';
// import ABCform from '../pr/abc/abc.form';
import UserProfile from "../user.account/user.account";
import { useStyles } from "./container.styles";
// import Meme from '../../helpers/meme';
import bg from "../../assets/bg.png";
import bglogo from "../../assets/bglogo.png";

import { mapStateToProps, mapDispatchToProps } from "./container.reducer";
// import classes from '*.module.css';

class Controller extends Component {
  constructor() {
    super();
    this.state = {
      validated: true,
    };
  }
  componentDidMount() {
    sessionService
      .loadSession()
      .then((currentSession) => {
        if (currentSession.userToken) {
          const userToken = currentSession.userToken;
          setAPIauth(currentSession.userToken);
          this.props.setCredential({ userToken });
          const user = jwt_decode(currentSession.userToken);
          this.props.setUser({
            user: {
              ...user,
              can_manage_ppmp: user.can_manage_ppmp === "Y" ? true : false,
              // can_manage_activities: user.can_manage_activities === "Y" ? true : false,
              // can_manage_pr: user.can_manage_pr === "Y" ? true : false,
              // can_manage_po: user.can_manage_po === "Y" ? true : false,
              can_manage_admin: user.can_manage_admin === "Y" ? true : false,
              isactive: user.isactive === "Y" ? true : false,
            },
          });
        }
      })
      .catch((err) => {
        console.log("container Component session error ", err);
      });
    this.props.displayLoading(false);
  }
  render() {
    const { classes, session, data } = this.props;
    return (
      <div
        className={classes.root}
        style={
          session.authenticated
            ? {}
            : {
              backgroundImage: `url(${bg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }
        }
      >
        <div className="mainContent">
          <SessionLoading />
          <Loading />
          <Alert />
          <Router>
            {session.authenticated ? (
              <div className="p-grid p-dir-col">
                <div className="p-col">
                  <div className="box">
                    <Navigation />
                  </div>
                </div>
                <Container maxWidth={false}>
                  <div className="p-col">
                    <div className="box">
                      <Material.Toolbar />
                      <Switch>
                        {data.user.can_manage_ppmp ? (
                          <Route exact path="/" component={PPMP} />
                        ) : (
                          ""
                        )}
                        {data.user.can_manage_ppmp ? (
                          <Route
                            exact
                            path="/ppmpsupplies"
                            component={PPMPSupplies}
                          />
                        ) : (
                          ""
                        )}
                        {data.user.can_manage_ppmp ? (
                          <Route exact path="/ppmplist" component={PPMPList} />
                        ) : (
                          ""
                        )}
                        {data.user.can_manage_ppmp ? (
                          <Route exact path="/viewlist" component={VIEWList} />
                        ) : (
                          ""
                        )}
                        {data.user.can_manage_ppmp ? (
                          <Route exact path="/ppmpform" component={PPMPform} />
                        ) : (
                          ""
                        )}
                        {/* { data.user.can_manage_pr ? <Route exact path="/pr" component={PR} /> : ""}  */}
                        {/* { data.user.can_manage_pr ? <Route exact path="/prlist" component={PRList} /> : ""}  */}
                        {/* { data.user.can_manage_pr ? <Route exact path="/prform" component={PRform}/> : ""}  */}
                        {/* { data.user.can_manage_pr ? <Route exact path="/prabcform" component={ABCform} /> : ""}  */}
                        {/* { data.user.can_manage_po ? <Route exact path="/po" component={PO} /> : ""}  */}
                        {/* {data.user.can_manage_admin ? <Route exact path="/Admin" component={Admin} /> : ""} */}
                        {data.user.can_manage_admin ? (
                          <Route
                            exact
                            path="/adminrequest"
                            component={PriceRequestList}
                          />
                        ) : (
                          ""
                        )}
                        <Route
                          exact
                          path="/Activities"
                          component={Activities}
                        />
                        {data.user.is_approver ? (
                          <Route
                            exact
                            path="/itemsforapproval"
                            component={ForApproval}
                          />
                        ) : (
                          ""
                        )}
                        {data.user.is_approver ? (
                          <Route
                            exact
                            path="/itemlistforapproval"
                            component={ItemListForApproval}
                          />
                        ) : (
                          ""
                        )}
                        {data.user.can_approve_manage_unforseen_emergency ? (
                          <Route
                            exact
                            path="/unforeseenPPMP"
                            component={UnforeseenPPMP}
                          />
                        ) : (
                          ""
                        )}
                        {data.user.can_approve_manage_unforseen_emergency ? (
                          <Route
                            exact
                            path="/unforeseenitemlistforapproval"
                            component={UnforeseenItemListForApproval}
                          />
                        ) : (
                          ""
                        )}
                        <Route exact path="/Pow" component={Pow} />
                        <Route exact path="/Payments" component={Payments} />
                        <Route exact path="/Consumption" component={ConsumedPPMP} />
                        <Route exace path="/deliverables" component={Deliverables} />
                        <Route
                          exact
                          path="/annualPPMP"
                          component={AnnualPPMP}
                        />
                        <Route
                          exact
                          path="/useraccount"
                          component={UserProfile}
                        />
                        <Route exact path="/logout" component={Logout} />
                        <Route exact component={ErrorUA} />
                      </Switch>
                    </div>
                  </div>
                </Container>
              </div>
            ) : (
              <div className="p-grid">
                <div className="p-col p-col-align-center">
                  <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact component={ErrorUA} />
                  </Switch>
                </div>
              </div>
            )}
          </Router>
        </div>
        <div className={classes.footer}>
          <img
            className={classes.logoBtm}
            src={bglogo}
            width="300"
            alt="PGBhUS Procurement"
          ></img>
          <div className={classes.footCont}>
            <strong>PGBhUS- Procurement Information System v1.4.2</strong> |
            Developed & Maintained by BICT0 | © 2022
            {process.env.REACT_APP_BASEURL.includes("192.168") ? (
              <strong>
                {" "}
                [
                {process.env.REACT_APP_BASEURL.replace("http://", "").replace(
                  "/api/PGBhUSProcurement/",
                  ""
                )}
                ]
              </strong>
            ) : (
              " | BD:10092024-2"
            )}
          </div>
        </div>
        {/* <Meme /> */}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Material.withStyles(useStyles)(Controller));
