export const useStyles = (theme) => {
    console.log(theme)
    return {
        root: {
            marginBottom: theme.spacing(2),
            flexGrow: 2,
            "& .animate:hover": {
                backgroundColor: "rgba(8, 0, 74, 0.15)",
                transform: "scale(1.1)",
            },
            "& .container": {
                padding: theme.spacing(2),
            },
            "& .MuiTable-root": {},
            "& .MuiTableContainer-root": {
                "overflow-x": "hidden",
            },
            "& .emptyCont": {
                "& .emptyBorder": {
                    padding: theme.spacing(10, 4),
                    height: "calc(100vh - 578px)",
                    borderRadius: "10px",
                    borderStyle: "dashed",
                    borderColor: "rgba(29, 29, 29, 0.10)",
                },
                "& .empty": {
                    height: "100%",
                    padding: theme.spacing(4),
                    backgroundColor: "rgba(29, 29, 29, 0.10)",
                    borderRadius: "10px",
                    textAlign: "center",
                    color: "gray",
                },
            },
        },
        paper: {
            // padding: theme.spacing(1),
            color: theme.palette.text.secondary,
        },
        paperless: {
            padding: theme.spacing(1),
            color: "transparent",
            border: "none",
            elevation: 0,
        },
        paperless_ref: {
            padding: theme.spacing(1),
            backgroundColor: "rgba(29, 29, 29, 0)",
            backgroundImage:
                "linear-gradient(to right, rgba(29, 29, 29, 0.40) , rgba(29, 29, 29, 0))",
            color: "transparent",
            borderRadius: "55px 0px 0px 55px",
            border: "none",
            elevation: 0,
            boxShadow: "none",
            width: "300px",
        },
        tablePaper: {
            overflow: "auto",
            height: "calc(100vh - 274px)",
        },
        tableFooter: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
        drawer: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
            width: 250,
            "& .MuiFormControl-root": {
                display: "flex",
            },
            "& .MuiFormControlLabel-root": {
                paddingLeft: theme.spacing(1),
            },
            "& .radio-choices:hover": {
                "& .MuiButtonBase-root": {
                    backgroundColor: "rgba(8, 0, 74, 0.15)",
                    transform: "scale(1.1)",
                },
                "& .MuiFormControlLabel-label": {
                    transform: "scale(1.1)",
                },
            },
        },
        tableRow: {
            "&:hover": {
                backgroundColor: "rgba(29, 29, 29, 0.10)",
                transform: "scale(1)",
            },
        },
        icon: {
            margin: theme.spacing(1),
        },
        menuButton: {
            // marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        tabIcon: {
        },
        tabs: {
            flexGrow: 1,
            '& .Mui-selected': {
                transform: 'scale(1.1)',
                backgroundColor: "#b9b9e063"
            },
            '& .MuiTab-root': {
                textTransform: 'none'
            }
        },
        toolbar: {
            minHeight: '0px'
        },
        navAppBar: {
            zIndex: theme.zIndex.drawer + 1
        },
        iconButtonHover: {
            '&:hover': {
                animation: '$swing 0.5s ease',
            },
        },
        '@keyframes swing': {
            '0%': { transform: 'rotate(0deg)' },
            '20%': { transform: 'rotate(15deg)' },
            '40%': { transform: 'rotate(-10deg)' },
            '60%': { transform: 'rotate(5deg)' },
            '80%': { transform: 'rotate(-5deg)' },
            '100%': { transform: 'rotate(0deg)' },
        },
    }
}