import { API } from "../../../helpers/axios.instance";

// export const postItem = (dataInput) => {
//     let data = new FormData();
//     data.append("name", dataInput.itemName);
//     data.append("description", dataInput.itemDesc);
//     data.append("unit", dataInput.itemUnit);
//     data.append("category_id", dataInput.itemCategory);
//     data.append("is_active", dataInput.itemIsActive ? "Y" : "N");
//     data.append("user_id", dataInput.itemUserId);
//     data.append("unit_price", dataInput.itemPrice);
//     return API()
//         .post("/items/add", data)
//         .then((res) => {
//             return res;
//         })
//         .catch((err) => {
//             console.log(err);
//         });
// };

export const getRequestItems = (dataInput) => {
    let data = new FormData();
    data.append("keyword", dataInput.keyword);
    data.append("page_no", dataInput.page_no);
    data.append("limit", dataInput.limit);
    data.append("sort_column", dataInput.sort_column);
    data.append("sort_type", dataInput.sort_type);
    data.append("filter_unit", dataInput.filter_unit);
    data.append("filter_is_active", dataInput.ri_filter_active ? "Y" : "N");
    data.append("filter_is_approved", dataInput.ri_filter_is_approved);
    data.append("filter_office_id", dataInput.ri_filter_office_id);
    return API()
        .post("/items/GetForApproval", data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return { error: true, msg: `${err}` };
        });
};

// POST REQUESTED ITEMS
export const postRequestedItem = (dataInput) => {
    let data = new FormData();
    data.append("item_code",dataInput.itemItemCode);
    data.append("name", dataInput.itemName);
    data.append("description", dataInput.itemDesc);
    data.append("unit", dataInput.itemUnit);
    data.append("category_id", dataInput.itemCategory);
    data.append("is_active", dataInput.itemIsActive ? "Y" : "N");
    data.append("unit_price", dataInput.itemPrice);
    data.append("is_approved", dataInput.itemUnitApproved  ? "Y" : "N");
    data.append("id", dataInput.itemId);
    data.append("user_id", 1);
  //  data.append("user_id", dataInput.itemUserId);
  
    return API()
        .post("/items/Updatemanual", data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => { return { error: true, msg: `${err}` } });
};

export const RejectRequestedItem = (dataInput) => {
    let data = new FormData();
    data.append("item_id", dataInput.itemId);
    data.append("remarks", dataInput.itemRejectRemarks);
    //data.append("ppmp_items_id",dataInput.ppmpItemListId);
  
    return API()
        .post("/PpmpItems/RejectPPMPItems", data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => { return { error: true, msg: `${err}` } });
};
