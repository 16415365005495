import React from 'react';
import { connect } from 'react-redux';

import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './loading.styles';
import { mapStateToProps } from './common.reducer';

class Loading extends React.Component {
    render() {
        const { data, classes } = this.props;
        return (
            <Modal aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal} open={data.loadingVisible}
                closeAfterTransition disableAutoFocus={true}
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} >
                <div className={classes.root}>
                    <Fade in={data.loadingVisible}>
                        <FormControlLabel control={ <CircularProgress /> } 
                            checked={false}
                            label="Loading..." />
                    </Fade>
                </div>
            </Modal>
        );
    }
}

export default connect(mapStateToProps)(withStyles(useStyles)(Loading));