export const useStyles = (theme) => ({
    root: {
        outline: 'none',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    foroneline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '98%',
        },
    },
    fortwoline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '52ch',
        },
    },
    forthreeline: {
        '& .MuiAutocomplete-root': {
            display: 'inline',
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '34ch',
        },
    },
    forfourline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '24ch',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        overflowY: 'auto',
		maxHeight: '605px',
		minHeight: '355px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px',
        padding: theme.spacing(4, 4, 3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    FormContainer: {
        // // display: 'flex',
        // flexWrap: 'wrap',
    },
    action: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },

      formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
      
      formControlLabel: {
        marginTop: theme.spacing(1),
      },

})