export default (state = {
    ppmpReqPriceDataSource: [],
    ppmpReqPriceTotal: 0,
    transactionData: {},
    selectedTransaction: null,
}, action) => {
    switch (action.type){
        case "SETPPMPREQPRICEDATASOURCE": 
            return state = {
                ...state,
                ppmpReqPriceDataSource: action.payload.ppmpReqPriceDataSource,
                //ppmpRawSource: action.payload.ppmpRawSource,
                ppmpReqPriceTotal: action.payload.ppmpReqPriceTotal,
				ppmpReqPriceFilter: action.payload.ppmpReqPriceFilter,
            }
        case "DROPPRICEREQSTATE": 
            return state = {
                ppmpReqPriceDataSource: [],
                ppmpReqPriceTotal: 0,
                transactionData: {},
                selectedTransaction: null,
            }
        default:
            return state;
    }
};
