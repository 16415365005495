export const useStyles = (theme) => ({
	'@global': {
		'*::-webkit-scrollbar': {
		  	width: '0.5em'
		},
		'*::-webkit-scrollbar-track': {
		  	'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.20)'
		},
		'*::-webkit-scrollbar-thumb': {
		  	backgroundColor: 'rgba(0,0,0,.2)',
        }
	},
    root: {
        display: 'flex',
		flexDirection: 'column',
		minHeight: '100%',
		height: '100%',
		"& .mainContent": {
			backgroundColor: "#e5e5e5",
			minHeight: 'calc(100vh - 77px)',
			flex: 1,
			flexDirection: 'column',
            justifyContent: 'center',
		}, 
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
    },
    paper: {
		zIndex: 1000,
		position: 'absolute',
		height: '100%',
		width: '100%',
		display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	padded: {
		paddingTop: theme.spacing(2),
	},
	logoBtm: {
		position: 'absolute',
		// zIndex: '10000',
		left: '0px'
	},
	footer: { 
		backgroundColor: "#e5e5e5",
		// zIndex: theme.zIndex.drawer,
        textAlign: 'center',
        fontSize: '12px',
        color: '#fff',
	},
	footCont: {
		padding: "10px",
		textAlign: "center",
		fontSize: "small",
		backgroundColor: '#152ba0',
		marginTop: '40px'
	}
})