import React, { Component } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import { mapStateToProps, mapDispatchToProps } from '../container/container.reducer';

class Alert extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            autoHideDuration: 6000,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            elevation: 6,
            variant: "filled",
        }
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose () {
		const { data, displayAlert } = this.props;
        displayAlert({ visible: false, message: data.alertMessage, severity: data.alertSeverity });
    }
    render() {
        const { data } = this.props;
        const { autoHideDuration, anchorOrigin, elevation, variant } = this.state;
        return (
            <Snackbar open={data.alertVisible} autoHideDuration={autoHideDuration} onClose={this.handleClose} 
                anchorOrigin={anchorOrigin} TransitionComponent={Slide}>
                <MuiAlert onClose={this.handleClose} severity={data.alertSeverity} 
                    elevation={elevation} variant={variant} >
                    {data.alertMessage}
                </MuiAlert>
            </Snackbar>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Alert);
