export default (state = {
	modesDatasource: [],
	modesTotal: 0,
	modesFilter: [],
	modesCreate: {}
}, action) => {
    switch (action.type){
		case "SETMODESDATASOURCE": 
            return state = {
                ...state,
				modesDatasource: action.payload.modesDatasource,
				modesTotal: action.payload.modesTotal,
				modesFilter: action.payload.modesFilter,
			}
		case "DROPMODESSTATE": 
            return state = {
                modesDatasource: [],
				modesTotal: 0,
				modesFilter: [],
				modesCreate: {}
			}
        default:
            return state;
	}
};
