import { API } from "../../helpers/axios.instance";

export const getOfficesWithPPMPUnforeseen = (value) => {
  let data = new FormData();
  data.append("_status", value.data_to_pass);

  return API()
    .post("/Ppmp/GetOfficesWithPPMPUnforeseen", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getUnforeseenPPMP = (value) => {
  let data = new FormData();
  data.append("_status", value.status);
  data.append("_year", value.year);
  data.append("_office_id", value.office_id);

  return API()
    .post("/Ppmp/GetFilterUnforeseen", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const setItemStatus = (value) => {
  let data = new FormData();
  data.append("_ppmp_items_id", value.ppmp_item_id);
  data.append("_status_code", value.status_code);
  data.append("_remarks", value.remarks);

  return API()
    .post("/PpmpItems/ApprovePpmpItemsUnforeseen", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getUnforeseenItemsForApprovalPerPPMP = (value) => {
  let data = new FormData();
  data.append("_ppmp_no", value.ppmp_no);
  data.append("_status", value.status);

  return API()
    .post("/PpmpItems/GetItemsFilterUnforeseen", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};
