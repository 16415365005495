export default (state = {
    suppliesData: {},
    respoDefaultParams: {},
    signatories: {},
    prDataSource: [],
    genPRDataSource: [],
    formPRDataSource: [],
    itemDataSource: [],
    itemFilter: [],
    respoCenterCodeDatasource: [],
    ppmpDataSource: [],
    genPPMPDataSource: [],
    signatoriesDatasource: [],
    prTotal: 0,
    itemTotal: 0,
    ppmpTotal: 0,
    selectedTransaction: null,
    suppliesModalVisible: false,
}, action) => {
    switch (action.type) {
        case "SETPRDATASOURCE":
            return state = {
                ...state,
                prDataSource: action.payload.prDataSource,
                prTotal: action.payload.prTotal,
                prFilter: action.payload.prFilter,
            }
        case "SETGENPRDATASOURCE":
            return state = {
                ...state,
                genPRDataSource: action.payload.genPRDataSource,
            }
        case "SETFORMPRDATASOURCE": 
            return state = {
                ...state,
                formPRDataSource: action.payload.formPRDataSource,
            }
        case "SETPPMPDATASOURCE":
            return state = {
                ...state,
                ppmpDataSource: action.payload.ppmpDataSource,
                ppmpTotal: action.payload.ppmpTotal,
                ppmpFilter: action.payload.ppmpFilter,
            }
        case "SETGENPPMPDATASOURCE":
            return state = {
                ...state,
                genPPMPDataSource: action.payload.genPPMPDataSource,
            }
        case "SETITEMDATASOURCE":
            return state = {
                ...state,
                itemDataSource: action.payload.itemDataSource,
                itemTotal: action.payload.itemTotal,
                itemFilter: action.payload.itemFilter,
            }
        case "SETSUPPLIESDISPLAY":
            return state = {
                ...state,
                suppliesModalVisible: action.payload.modalVisible,
                suppliesData: action.payload.suppliesData,
            }
        case "SETRESPODEFAULTVALUE":
            return state = {
                ...state,
                respoDefaultParams: {
                    ...state.respoDefaultParams,
                    ...action.payload
                }
            }
        case "SETRESPOCENTERDATASOURCE":
            return state = {
                ...state,
                respoCenterCodeDatasource: action.payload.respoCenterData
            }
        case "SETSIGNATORIESDATASOURCE": 
            return state = {
                ...state,
                signatoriesDatasource: action.payload.signatoriesDatasource,
            }
        case "SETCURRENTSIGNATORIES": 
			return state = {
				...state,
                signatories: {
                    ...state.signatories,
                    ...action.payload
                }
            }
        case "DROPPRSTATE": 
			return state = {
				suppliesData: {},
                respoDefaultParams: {},
                signatories: {},
                prDataSource: [],
                genPRDataSource: [],
                formPRDataSource: [],
                itemDataSource: [],
                itemFilter: [],
                respoCenterCodeDatasource: [],
                ppmpDataSource: [],
                genPPMPDataSource: [],
                signatoriesDatasource: [],
                prTotal: 0,
                itemTotal: 0,
                ppmpTotal: 0,
                selectedTransaction: null,
                suppliesModalVisible: false,
			}
        default:
            return state;
    }
};
