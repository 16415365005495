export const useStyles = (theme) => ({
    root: {
        outline: 'none',
		display: 'inline-block',
		'& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
		},
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 216,
	},
    foroneline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '28ch',
        },
    },
    fortwoline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '16ch',
        },
    },
    fortline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '15ch',
        },
    },
    formControlMax: {
		margin: theme.spacing(1),
        minWidth: 220,
	},
    forthreeline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '34ch',
		},
		'& .chiper-select': {
            margin: theme.spacing(1),
            width: '98%',
		}
    },
    forthreelineofselect: {
        '& .MuiSelect-root': {
            margin: theme.spacing(1),
            width: '28ch',
		}
    },
    forfourlineofselect: {
        '& .MuiSelect-root': {
            margin: theme.spacing(1),
            width: '22ch',
		}
    },
    forlastline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '98%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        overflow:'scroll',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px',
        padding: theme.spacing(4, 4, 3),
        spacing: 2
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    FormContainer: {
        // // display: 'flex',
        // flexWrap: 'wrap',
    },
    action: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    typography: {
        marginTop: theme.spacing(1)
    }
})