import React from "react";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "../ppmp/ppmp.reducer";

//Imported Material Components
import * as Material from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import matchSorter from "match-sorter";

//Imported Icons
import { useStyles } from "./consumption.style";
import * as BudgetConsumptionService from "./consumption.service";
import * as PPMPService from "../ppmp/ppmp.service";

import SearchIcon from "@mui/icons-material/Search";

class Consumption extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalVisible: false,
			searchValue: "",
			year_range: [],
			showModalInfo: false,

			sort_column: "",
			sort_type: "",
			year: "",
			office_id: 0,
			show_all: 0,
			show_all_checked: false,
			payments: [],

			displayPaymentsData: [],
			openSearchDialog: false,

			respo_code_desc: "",
			account_code_desc: "",
			legal_basis: "",

			budget_consumption_ppmp: [],
			fundSourceHasNoPPMP: false,

			// new code
			advanceSearchVisible: false,
			rows: [{ fundSource: null, fundSourceAllocatedAmount: 0 }],
			fundSource: null,
			fundSourceOpen: false,
			respoCodeValue: null,
			sourceFundList: [],
			RA_year_choices: [],
			respoAccount_choices: [],
			fundTypeChoices: [],
			legalBasisChoices: [],
			checkedRCA: false,
			IsNonOffice: false,
			RA_year: "",
			RA_desc: "",
			fundType: "",
			PPA_code: "",
			respo_code: "",

			activeStep: 0,
			stepper: ["Selecting Funds", "Target Month", "Upload File"],

			isLoadItem: true,
			noOfItems: 1,
			itemArray: [],

			base64: "",
			filename: "",
			month_selected: "",
			date_selected: "",
			user_id: 0,
			ppmp_id: 0,

			error_dialog_visible: false,
			error_dialog_msg: "",

			is_maxProjectCost: false,
			sourceFundList2: [],
		};
		this.onClickAdvanceSearch = this.onClickAdvanceSearch.bind(this);
		this.onHandleCheckboxChange = this.onHandleCheckboxChange.bind(this);
		this.initDefaultRespoValues = this.initDefaultRespoValues.bind(this);
		this.onHandleRespoCodeAutocomplete =
			this.onHandleRespoCodeAutocomplete.bind(this);
		this.filterOptions = this.filterOptions.bind(this);
		this.onHandleSelectChange = this.onHandleSelectChange.bind(this);
		this.requestSourceOfFund = this.requestSourceOfFund.bind(this);
		this.respoCodeRenderOptions = this.respoCodeRenderOptions.bind(this);
	}

	componentDidMount() {
		// this.requestGetPayments();
		// this.initRespoCenter();
		this.requestRespoCenterData();
	}
	componentDidUpdate(prevProps, prevState) {
		// Only trigger initRespoCenter if the dialog is opened
		if (!prevState.openSearchDialog && this.state.openSearchDialog) {
			this.initRespoCenter();
		}
	}
	resetState() {
		this.setState({
			...this.state,
			respo_code_desc: "",
			account_code_desc: "",
			legal_basis: "",
		});
	}
	requestGetPayments() {
		const { displayLoading, displayAlert, data } = this.props;
		displayLoading(true);
		let values = {
			respo_code_desc: this.state.respo_code_desc,
			account_code_desc: this.state.account_code_desc,
			legal_basis: this.state.legal_basis,
		};
		// console.log("$$toPass", toPass);
		BudgetConsumptionService.getPPMPByBudgetConsumption({ values })
			.then((response) => {
				console.log("$$response", response);
				if (!response.error) {
					this.setState({
						...this.state,
						budget_consumption_ppmp: response.data,
					});
					this.handleClose();
				} else {
					this.setState(
						{
							...this.state,
							budget_consumption_ppmp: [],
							fundSourceHasNoPPMP: true,
						},
						() => {
							displayAlert({
								alertVisible: true,
								alertMessage: "Error Occured!",
								alertSeverity: "error",
							});
						}
					);
					this.handleClose();
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
			});
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};
	handleClickOpen = () => {
		this.setState({ openSearchDialog: true });
	};

	// Method to handle closing the dialog
	handleClose = () => {
		this.setState({ openSearchDialog: false });
	};
	onCloseModal() {
		this.setState({ ...this.state, modalVisible: false, showModalInfo: false });
	}
	onSearchHandler(e) {
		e.preventDefault();
		this.setState(
			{ ...this.state, keyword: this.state.searchValue, page_no: 0 },
			() => {
				this.requestGetPayments();
			}
		);
	}

	onSelectHandler(e) {
		this.setState(
			{
				...this.state,
				year: e.target.value,
			},
			() => {
				this.requestGetPayments();
			}
		);
	}
	ontoggleInfoListModal(value) {
		// console.log("$$displayPayments", value);
		this.setState({
			...this.state,
			showModalInfo: value.open,
			displayPaymentsData: value.data,
		});
	}

	handleRequestSort(event, property) {
		const { sort_column, sort_type } = this.state;
		const isAsc = sort_column === property && sort_type === "asc";
		this.setState(
			{
				...this.state,
				sort_column: property,
				sort_type: isAsc ? "desc" : "asc",
			},
			() => {
				this.requestGetPayments();
			}
		);
	}
	onCheckHandlerCreate(e) {
		const isChecked = e.target.checked;
		this.setState(
			{
				...this.state,
				show_all_checked: isChecked,
				show_all: isChecked ? 1 : 0,
			},
			() => {
				this.requestGetPayments();
			}
		);
	}
	initRespoCenter() {
		if (this.state.respoCodeValue === null) {
			this.initDefaultRespoValues();
		} else {
			this.requestRAyear();
		}
	}
	requestRespoCenterData() {
		const {
			data,
			displayAlert,
			displayLoading,
			setRespoCenterDataSource,
			setRespoDefaultValues,
		} = this.props;
		displayLoading(true);
		PPMPService.getRespoCenterData()
			.then((res) => {
				const respoCenterData = res.data;
				if (respoCenterData.length > 0) {
					setRespoCenterDataSource({ respoCenterData });
					const defval = data.user;
					const respoCodeValue = respoCenterData.filter((item) => {
						return (
							item.ppa_code === defval.fpp &&
							item.respo_code === defval.respo_center
						);
					});
					displayLoading(false);
					setRespoDefaultValues({ respoCodeValue: respoCodeValue[0] });
					this.setState({ ...this.state, respoCodeValue: respoCodeValue[0] });
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "warning",
				});
				displayLoading(false);
			});
	}
	requestFundList() {
		const { displayAlert, setRespoDefaultValues, displayLoading, ppmp } =
			this.props;
		const { RA_year, respoCodeValue, IsNonOffice } = this.state;
		displayLoading(true);
		PPMPService.getAccountCodePerRespo({
			respo_code: respoCodeValue.respo_code,
			year: RA_year,
			ppmp_id: this.state.ppmp_id,
		})
			.then((res) => {
				var data = res.data;
				if (typeof data === "string") {
					this.setState({
						...this.state,
						RA_desc: "",
						fundType: "",
						PPA_code: "",
					});
					displayLoading(false);
				} else {
					if (data.length > 0) {
						let dataFiltered = data;
						// let dataFiltered = data.filter((item) => {
						//   if (IsNonOffice) {
						//     return (
						//       item.ppa_code !==
						//       ppmp?.respoDefaultParams?.respoCodeValue?.ppa_code
						//     );
						//   } else {
						//     return (
						//       item.ppa_code ===
						//       ppmp?.respoDefaultParams?.respoCodeValue?.ppa_code
						//     );
						//   }
						// });
						var _entryData = dataFiltered[0];
						setRespoDefaultValues({
							RA_desc: _entryData.ra_desc,
							fundType: _entryData.fund_type,
							PPA_code: _entryData.ppa_code,
						});
						this.setState(
							{
								...this.state,
								RA_desc: _entryData.ra_desc,
								fundType: _entryData.fund_type,
								PPA_code: _entryData.ppa_code,
								respoAccount_choices: dataFiltered,
								legalBasisChoices: [
									...new Set(dataFiltered.map((e, i) => e.ra_desc)),
								],
								fundTypeChoices: [
									...new Set(dataFiltered.map((e, i) => e.fund_type)),
								],
							},
							() => {
								this.state.sourceFundList.length === 0
									? this.requestSourceOfFund()
									: displayLoading(false);
							}
						);
					}
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "warning",
				});
				displayLoading(false);
			});
	}
	requestRAyear() {
		const { displayAlert, displayLoading, setRespoDefaultValues, ppmp } =
			this.props;
		const { respoCodeValue } = this.state;
		displayLoading(true);
		PPMPService.getRAyear({
			respo_code: respoCodeValue.respo_code,
			ppmp_id: this.state.ppmp_id,
		})
			.then((res) => {
				var data = res.data;
				if (typeof data === "string") {
					this.setState(
						{ ...this.state, RA_year: "", RA_year_choices: [{ ra_year: "" }] },
						() => this.requestFundList()
					);
					displayLoading(false);
				} else {
					if (data.length > 0) {
						const RA_year = data[0].ra_year;
						setRespoDefaultValues({ RA_year, IsNonOffice: false });
						this.setState(
							{ ...this.state, RA_year, RA_year_choices: data },
							() => this.requestFundList()
						);
					}
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "warning",
				});
				displayLoading(false);
			});
	}
	filterOptions(options, { inputValue }) {
		return matchSorter(options, inputValue, {
			keys: [
				"office_name",
				"ppa_code",
				"ppa_desc",
				"respo_code",
				"respo_type",
				"ra_desc",
				"account_particulars",
			],
		});
	}
	addRow = () => {
		const { rows } = this.state;
		this.setState({
			rows: [...rows, { fundSource: "" }],
		});
	};

	removeRow = (index) => {
		const { rows } = this.state;
		if (rows.length > 1) {
			const updatedRows = [...rows.slice(0, index), ...rows.slice(index + 1)];
			this.setState({ rows: updatedRows });
		}
	};
	handleFundSourceAllocatedAmountChange = (event, index) => {
		const { rows } = this.state;
		let value = event.target.value.replace(/,/g, ""); // Remove existing commas

		// Allow only numbers and up to 2 decimal places
		if (!isNaN(value) && /^(\d+(\.\d{0,2})?)?$/.test(value)) {
			if (value.includes(".")) {
				// Format only the integer part for commas
				let parts = value.split(".");
				parts[0] = parseFloat(parts[0]).toLocaleString("en-US");
				value = parts.join(".");
			} else {
				value = parseFloat(value).toLocaleString("en-US");
			}

			const updatedRows = [...rows];
			updatedRows[index] = {
				...updatedRows[index],
				fundSourceAllocatedAmount: value,
			};
			this.setState({ rows: updatedRows });
		}
	};
	onHandleSourceFundAutocomplete = (event, value, index) => {
		const { rows } = this.state;
		const updatedRows = [...rows];
		updatedRows[index] = { ...updatedRows[index], fundSource: value };
		this.setState({
			rows: updatedRows,
			respo_code_desc: value.respo_code,
			account_code_desc: value.account_code,
			legal_basis: value.ra_desc,
		});
	};
	requestSourceOfFund() {
		const { displayAlert, displayLoading, ppmp } = this.props;
		const { respoCodeValue } = this.state;

		this.setState({
			...this.state,
			fundSource: "",
		});
		// let maxProjectCost = 50000.00;
		// let projectCost = parseFloat(this.removeCommas(powProjectCost));

		let sp_used = this.state.IsNonOffice
			? "sp_account_get_fundsource_nonoffice3"
			: "sp_account_get_fundsource3";
		displayLoading(true);
		PPMPService.getSourceOfFundData({
			...this.state,
			respo_code: respoCodeValue.respo_code,
			sp_used,
		})
			.then((res) => {
				if (typeof res.data === "string") {
					this.setState({
						...this.state,
						sourceFundList: [],
						fundSource: null,
					});
					displayLoading(false);
				} else {
					this.setState(
						{
							...this.state,
							sourceFundList: res.data,
							fundSource: null,
						},
						() => {
							let sourceFundList = res.data;
							const data = ppmp.suppliesData;
							if (data.ppmp_item_id > 0) {
								// continue code_here	account_code_description == account_particulars
								let fundSource = sourceFundList.filter(
									(element) =>
										element.account_particulars == data.account_code_description
								)[0];
								// console.log('$$fundSource', fundSource);
								this.setState({
									...this.state,
									// funds
									fundSource,
								});
								// console.log('$$state', this.state);
							}
						}
					);
					displayLoading(false);
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "warning",
				});
				displayLoading(false);
			});
	}
	onHandleRespoCodeAutocomplete(event, value) {
		this.setState({ ...this.state, respoCodeValue: value }, () => {
			if (value) {
				this.props.displayLoading(true);
				this.requestRAyear();
			}
		});
	}
	initDefaultRespoValues() {
		const { ppmp } = this.props;
		const value = ppmp.respoDefaultParams;
		this.setState(
			{
				IsNonOffice: false,
				respoCodeValue: value.respoCodeValue,
				user_id: this.props.data.user.id,
				ppmp_id: this.props.ppmp_id,
			},
			() => {
				this.initRespoCenter();
			}
		);
	}
	onHandleCheckboxChange(event) {
		this.setState({ ...this.state, [event.target.name]: event.target.checked });

		// Additional log to check the condition
		if (event.target.name === "checkedRCA") {
			if (!event.target.checked) {
				this.initDefaultRespoValues();
			}
		}
	}

	onClickAdvanceSearch(e) {
		e.preventDefault();
		this.setState((prevState) => ({
			advanceSearchVisible: !prevState.advanceSearchVisible, // Toggle the visibility
		}));
	}

	removeCommas() {
		const { powProjectCost } = this.props;

		var projectCost = "";
		for (var i = 0; i < powProjectCost.length; i++) {
			if (powProjectCost[i] !== ",") {
				projectCost += powProjectCost[i];
			}
		}
		return projectCost;
	}

	formatNumber(num) {
		if (num) {
			return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		} else {
			return <React.Fragment></React.Fragment>;
		}
	}
	getCapitalOutlay() {
		const { sourceFundList } = this.state;
		const updatedSourceFundList2 = [];

		sourceFundList.forEach((source) => {
			updatedSourceFundList2.push(source);
		});

		this.setState({
			...this.state,
			sourceFundList2: updatedSourceFundList2,
		});
	}
	onHandleSelectChange(event) {
		// console.log('$$event', event);
		this.props.displayLoading(true);
		this.setState(
			{ ...this.state, [event.target.name]: event.target.value },
			() => {
				if (event.target.name === "RA_year") {
					this.requestFundList();
				} else {
					// this.requestSourceOfFund();
				}
			}
		);
		this.props.displayLoading(false);
	}
	respoCodeRenderOptions(option) {
		return (
			<div className={this.props.classes.OptionContainer}>
				<Material.Typography variant="subtitle1">
					{option.respo_code}. {option.office_name}
				</Material.Typography>
				<div>
					<div className="inline padded">
						<Material.Typography color="primary" variant="caption">
							Respo Type:{" "}
						</Material.Typography>
						<Material.Typography variant="caption">
							{option.respo_type}
						</Material.Typography>
					</div>
					<div className="inline padded">
						<Material.Typography color="primary" variant="caption">
							FPP:{" "}
						</Material.Typography>
						<Material.Typography variant="caption">
							{option.ppa_code}: {option.ppa_desc}
						</Material.Typography>
					</div>
				</div>
			</div>
		);
	}
	getStepContent() {
		const { ppmp, classes } = this.props;
		const {
			advanceSearchVisible,
			sourceFundList,
			fundSource,
			checkedRCA,
			legalBasisChoices,
			fundTypeChoices,
			rows,
		} = this.state;

		return (
			<div>
				<Material.Grid container spacing={3}>
					<Material.Grid item xs={12}>
						<div style={{ textAlign: "right" }}>
							<Material.Link href="" onClick={this.onClickAdvanceSearch}>
								{advanceSearchVisible ? "Hide" : "Show"} Advance Search
							</Material.Link>
						</div>
						<Material.Collapse
							className={classes.collapseExpand}
							in={advanceSearchVisible}
						>
							<Material.Paper elevation={1} className={classes.paperAdvance}>
								<div className="full-width-choices">
									<Material.Tooltip
										title="Get Fund source from other Respo Center"
										arrow
									>
										<Material.FormControlLabel
											control={
												<Material.Checkbox
													checked={checkedRCA}
													onChange={this.onHandleCheckboxChange}
													name="checkedRCA"
													color="primary"
												/>
											}
											label="Respo Center"
										/>
									</Material.Tooltip>
									<Material.Tooltip
										title="Respo Center needs approval when using other funds"
										arrow
									>
										<Autocomplete
											onChange={this.onHandleRespoCodeAutocomplete}
											id="respo-code-select"
											autoHighlight
											disabled={!checkedRCA}
											options={ppmp.respoCenterCodeDatasource}
											getOptionLabel={(option) =>
												`${option.respo_code}. ${option.office_name}`
											}
											filterOptions={this.filterOptions}
											getOptionSelected={(option, value) =>
												option.ppa_code === value.ppa_code
											}
											value={this.state.respoCodeValue}
											renderOption={this.respoCodeRenderOptions}
											renderInput={(params) => (
												<Material.TextField
													{...params}
													color="primary"
													label="Fund Source *"
													margin="normal"
												/>
											)}
											disableListWrap
										/>
									</Material.Tooltip>

									<div>
										<Material.FormControl
											className={classes.formControl}
											fullWidth
										>
											<Material.InputLabel id="RA-year-select-label">
												Year *
											</Material.InputLabel>
											<Material.Select
												labelId="RA-year-select"
												id="RA-year-select"
												name="RA_year"
												value={this.state.RA_year}
												onChange={this.onHandleSelectChange}
											>
												{this.state.RA_year_choices.map((item, index) => {
													return (
														<Material.MenuItem
															key={`ra-year-item-${index}`}
															value={item.ra_year}
														>
															{item.ra_year}
														</Material.MenuItem>
													);
												})}
											</Material.Select>
										</Material.FormControl>
										<Material.FormControl
											className={classes.formControl}
											fullWidth
										>
											<Material.InputLabel id="legal-basis-select-label">
												Legal Basis *
											</Material.InputLabel>
											<Material.Select
												labelId="legal-basis-select"
												id="legal-basis-select"
												name="RA_desc"
												value={this.state.RA_desc}
												onChange={this.onHandleSelectChange}
											>
												{legalBasisChoices.map((item, index) => {
													return (
														<Material.MenuItem
															key={`legal_basis-item-${index}`}
															value={item}
														>
															{item}
														</Material.MenuItem>
													);
												})}
											</Material.Select>
										</Material.FormControl>
									</div>
									<div style={{ display: "inline" }}>
										<Material.FormControl
											className={classes.formControl}
											fullWidth
										>
											<Material.InputLabel id="fund-type-select-label">
												Fund Type *
											</Material.InputLabel>
											<Material.Select
												labelId="fund-type-select"
												id="fund-type-select"
												name="fundType"
												value={this.state.fundType}
												onChange={this.onHandleSelectChange}
											>
												{fundTypeChoices.map((item, index) => {
													return (
														<Material.MenuItem
															key={`fund-type-item-${index}`}
															value={item}
														>
															{item}
														</Material.MenuItem>
													);
												})}
											</Material.Select>
										</Material.FormControl>
										<Material.FormControl
											className={classes.formControl}
											fullWidth
										>
											<Material.FormControlLabel
												control={
													<Material.Checkbox
														checked={this.state.IsNonOffice}
														onChange={this.onHandleCheckboxChange}
														name="IsNonOffice"
														color="primary"
													/>
												}
												label="Non-Office"
											/>
										</Material.FormControl>
									</div>
								</div>
								<Material.Button
									className={classes.action}
									variant="outlined"
									color="primary"
									onClick={this.requestSourceOfFund}
								>
									Search
								</Material.Button>
							</Material.Paper>
						</Material.Collapse>

						{/* {rows.map((row, index) => {
							return ( */}
						<div className="full-width-choices">
							<Autocomplete
								onChange={(e, value) =>
									this.onHandleSourceFundAutocomplete(e, value)
								}
								id={`fund-source-select`}
								autoHighlight
								className={classes.autocompleteRoot}
								options={sourceFundList}
								getOptionLabel={(option) => option.account_particulars}
								renderOption={this.accountCodeRenderOptions}
								value={fundSource}
								filterOptions={this.filterOptions}
								renderInput={(params) => (
									<Material.TextField
										{...params}
										name="fundSource"
										color="primary"
										label="Reselect/ Select New Account Code *"
										margin="normal"
									/>
								)}
							/>
							<Material.Typography
								variant="caption"
								display="block"
								gutterBottom
								style={{ color: "rgba(0, 0, 0, 0.54)", float: "right" }}
							>
								<i>
									{sourceFundList.length} Account{" "}
									{sourceFundList.length > 1 ? "codes" : "code"} found
								</i>
							</Material.Typography>
						</div>
						{/* );
						})} */}
					</Material.Grid>
					{rows.map((row, index) => (
						<React.Fragment key={index}>
							<Material.Grid container spacing={1}>
								<Material.Collapse in={Boolean(row.fundSource)}>
									<Material.Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									>
										<Material.Grid style={{ marginTop: 5 }} item xs={12}>
											<Material.Typography color="secondary">
												Account Code Details
											</Material.Typography>
										</Material.Grid>
										<Material.Grid item xs={6}>
											<Material.Typography color="primary" variant="subtitle1">
												F.P.P.:
											</Material.Typography>
											<Material.Grid style={{ display: "inline-block" }}>
												{row.fundSource && (
													<Material.Typography
														className="wrap"
														variant="subtitle1"
													>
														{row.fundSource.ppa_code}: {row.fundSource.ppa_desc}
													</Material.Typography>
												)}
											</Material.Grid>
										</Material.Grid>

										<Material.Grid item xs={6} style={{ paddingTop: 5 }}>
											<Material.Typography color="primary" variant="subtitle1">
												Balance:
											</Material.Typography>
											{row.fundSource && (
												<Material.Typography
													className="inline"
													variant="subtitle1"
												>
													Php {row.fundSource.allot_balance}
												</Material.Typography>
											)}
										</Material.Grid>
										<Material.Grid item xs={6}>
											<Material.Typography color="primary" variant="subtitle1">
												RESPO:
											</Material.Typography>
											{row.fundSource && (
												<Material.Typography
													className="wrap"
													variant="subtitle1"
												>
													{row.fundSource.respo_desc}
												</Material.Typography>
											)}
										</Material.Grid>
										<Material.Grid item xs={6}>
											<Material.Typography color="primary" variant="subtitle1">
												Legal Basis:
											</Material.Typography>
											{row.fundSource && (
												<Material.Typography
													className="wrap"
													variant="subtitle1"
												>
													{row.fundSource.ra_desc}
												</Material.Typography>
											)}
										</Material.Grid>
										<Material.Grid item xs={6}>
											<Material.Typography color="primary" variant="subtitle1">
												Year:
											</Material.Typography>
											{row.fundSource && (
												<Material.Typography
													className="wrap"
													variant="subtitle1"
												>
													{row.fundSource.ra_year}
												</Material.Typography>
											)}
										</Material.Grid>
										<Material.Grid item xs={6}>
											<Material.Typography color="primary" variant="subtitle1">
												Allotted Class:
											</Material.Typography>
											{row.fundSource && (
												<Material.Typography
													className="wrap"
													variant="subtitle1"
												>
													{row.fundSource.allot_type}
												</Material.Typography>
											)}
										</Material.Grid>
										<Material.Grid item xs={6}>
											<Material.Typography color="primary" variant="subtitle1">
												Fund Type:
											</Material.Typography>
											{row.fundSource && (
												<Material.Typography
													className="wrap"
													variant="subtitle1"
												>
													{row.fundSource.fund_type}
												</Material.Typography>
											)}
										</Material.Grid>
									</Material.Grid>
								</Material.Collapse>
							</Material.Grid>
						</React.Fragment>
					))}
				</Material.Grid>
			</div>
		);
	}

	render() {
		const {
			year,
			year_range,
			sort_column,
			sort_type,
			budget_consumption_ppmp,
			respo_code_desc,
			account_code_desc,
			legal_basis,
		} = this.state;
		const { classes, data } = this.props; //TableHead
		const { activeStep, stepper } = this.state;
		let office_id = data.user.office_id;
		const TableHeaderOp = [
			{
				id: "Status",
				numeric: false,
				disablePadding: false,
				label: "Status",
				align: "center",
				field: "status",
			},
			{
				id: "PPMP No.",
				numeric: false,
				disablePadding: false,
				label: "PPMP No.",
				align: "center",
				field: "ppmp_no",
			},
			{
				id: "Title",
				numeric: false,
				disablePadding: false,
				label: "Title",
				align: "left",
				field: "title",
			},
			{
				id: "Office",
				numeric: false,
				disablePadding: false,
				label: "Office",
				align: "left",
				field: "office",
			},
			{
				id: "Total Cost(PPMP)",
				numeric: false,
				disablePadding: false,
				label: "Total Cost(PPMP)",
				align: "right",
				field: "total_cost",
			},
			// {
			// 	id: "Running Balance",
			// 	numeric: false,
			// 	disablePadding: false,
			// 	label: "Running Balance",
			// 	align: "right",
			// 	field: "balance",
			// },
			// {
			// 	id: "Usable Balance",
			// 	numeric: false,
			// 	disablePadding: false,
			// 	label: "Usable Balance",
			// 	align: "right",
			// 	field: "usable_balance",
			// },
		];
		let grandTotal = this.state.budget_consumption_ppmp.reduce((total, row) => {
			return total + (parseFloat(row.cost) || 0); // Ensure cost is added only if it exists
		}, 0);
		return (
			<div className={classes.root}>
				<Material.Paper className={classes.paper}>
					<Material.TableContainer //start of the table; (per category)
						component={Material.Paper}
					>
						<Material.Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								marginBottom: 2,
							}}
						>
							<Material.Toolbar className={classes.toolbar}>
								<Material.Button
									color="primary"
									variant="contained"
									startIcon={<SearchIcon />}
									onClick={this.handleClickOpen}
								>
									Search by respo code
								</Material.Button>
							</Material.Toolbar>
						</Material.Box>

						<Material.Table className={classes.table}>
							<>
								<Material.Paper
									className={classes.paperAdvance}
									style={{ margin: 4 }}
								>
									<Material.Grid container spacing={2} justifyContent="center">
										<Material.Grid item xs={4} style={{ textAlign: "center" }}>
											<b>Account Code: </b>
											<Material.Chip
												label={this.state.account_code_desc}
												// className={classes.approved}
											/>
										</Material.Grid>
										<Material.Grid item xs={4} style={{ textAlign: "center" }}>
											<b>
												Budget Allotment Balance:{" "}
												<Material.Chip
													// className={classes.approved}
													label={
														budget_consumption_ppmp[0] &&
														budget_consumption_ppmp[0].approp_balance !==
															undefined &&
														budget_consumption_ppmp[0].approp_balance !== null
															? `₱ ${new Intl.NumberFormat("en-PH", {
																	style: "decimal",
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
															  }).format(
																	budget_consumption_ppmp[0].approp_balance
															  )}`
															: ""
													}
												/>
											</b>
										</Material.Grid>
										<Material.Grid item xs={4} style={{ textAlign: "center" }}>
											<b>
												Total Obligation:{" "}
												<Material.Chip
													// className={classes.approved}
													label={
														budget_consumption_ppmp[0] &&
														budget_consumption_ppmp[0].total_oblig !==
															undefined &&
														budget_consumption_ppmp[0].total_oblig !== null
															? `₱ ${new Intl.NumberFormat("en-PH", {
																	style: "decimal",
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
															  }).format(
																	budget_consumption_ppmp[0].total_oblig
															  )}`
															: ""
													}
												/>
											</b>
										</Material.Grid>
									</Material.Grid>
								</Material.Paper>
							</>
						</Material.Table>
						<Material.Table className={classes.table}>
							<Material.TableHead>
								<Material.TableRow>
									{TableHeaderOp.map((option) => (
										<Material.TableCell
											key={option.id}
											align={option.align}
											padding={option.disablePadding ? "none" : "default"}
											sortDirection={
												sort_column === option.id ? sort_type : false
											}
										>
											<Material.TableSortLabel
												active={sort_column === option.id}
												direction={
													sort_column === option.id ? sort_type : "asc"
												}
												onClick={(event) => {
													this.handleRequestSort(event, option.field);
												}}
											>
												{option.label}
												{sort_column === option.id ? (
													<span className={classes.visuallyHidden}>
														{sort_type === "desc"
															? "sorted descending"
															: "sorted ascending"}
													</span>
												) : null}
											</Material.TableSortLabel>
										</Material.TableCell>
									))}
								</Material.TableRow>
							</Material.TableHead>
							{budget_consumption_ppmp.length === 0 ? (
								<Material.TableRow>
									<Material.TableCell colSpan={20} className="emptyCont">
										<div className="empty">
											<div className="emptyBorder">
												{this.state.fundSourceHasNoPPMP === true
													? "The account code you searched found no PPMP results!"
													: "Search respo code to display."}
											</div>
										</div>
									</Material.TableCell>
								</Material.TableRow>
							) : (
								<Material.TableRow />
							)}

							<Material.TableBody>
								{budget_consumption_ppmp.map((row) => (
									<Material.TableRow key={row.id} className={classes.tableRow}>
										<Material.TableCell
											align="center"
											className={classes.statusColor}
										>
											<Material.Chip
												label={row.status}
												className={row.status}
											/>
										</Material.TableCell>
										<Material.TableCell align="center">
											{row.ppmp_no}
										</Material.TableCell>
										<Material.TableCell align="left">
											{row.ppmp_title}
										</Material.TableCell>
										<Material.TableCell align="left">
											<Material.Chip label={<b>{row.office_abb}</b>} />
										</Material.TableCell>
										<Material.TableCell align="right">
											<Material.Chip
												className={classes.approved}
												label={`₱ ${new Intl.NumberFormat("en-PH", {
													style: "decimal",
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}).format(row.cost)}`}
											/>
										</Material.TableCell>

										{/* <Material.TableCell align="right">
											<Material.Chip
												label={`₱ ${new Intl.NumberFormat("en-PH", {
													style: "decimal",
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}).format(row.balance)}`}
											/>
										</Material.TableCell> */}

										{/* actionButtons */}
										{/* <Material.TableCell align="right">
											<Material.Chip
												className={
													row.usable_balance < 0
														? classes.rejected
														: classes.approved
												}
												label={`₱ ${new Intl.NumberFormat("en-PH", {
													style: "decimal",
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}).format(row.usable_balance)}`}
											/>
										</Material.TableCell> */}
									</Material.TableRow>
								))}
								<Material.TableRow>
									<Material.TableCell align="right" colSpan={5}>
										Grand Total:{" "}
										<Material.Chip
											className={classes.approved}
											label={`₱ ${new Intl.NumberFormat("en-PH", {
												style: "decimal",
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}).format(grandTotal)}`}
										/>
									</Material.TableCell>
								</Material.TableRow>
							</Material.TableBody>
						</Material.Table>
					</Material.TableContainer>

					<Material.Dialog
						open={this.state.openSearchDialog}
						onClose={this.handleClose}
					>
						<Material.DialogTitle>
							Search PPMP by respo code
						</Material.DialogTitle>
						<Material.DialogContent>
							<Material.DialogContentText>
								{this.getStepContent()}
							</Material.DialogContentText>
							<Material.DialogActions>
								<Material.Button variant="text" onClick={this.handleClose}>
									Close
								</Material.Button>
								<Material.Button
									color="primary"
									variant="text"
									onClick={() => this.requestGetPayments()}
								>
									Search
								</Material.Button>
							</Material.DialogActions>
						</Material.DialogContent>
					</Material.Dialog>
				</Material.Paper>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Material.withStyles(useStyles)(Consumption));
