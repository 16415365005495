import { API } from '../../../helpers/axios.instance';

export const getUsers = (tableProp) => {
    let data = new FormData();
    data.append('keyword', tableProp.keyword);
    data.append('page_no', tableProp.page_no);
    data.append('limit', tableProp.limit);
    data.append('sort_column', tableProp.sort_column);
    data.append('sort_type', tableProp.sort_type);
    data.append('filter_is_active', tableProp.filter_is_active ? 'Y' : 'N');
    data.append('filter_role_name', tableProp.filter_role_name);
    return API().post('/Users/Get/index_post',data).then((response)=>{
        return response.data;
    }).catch((err)=>{
        console.error(err);
    });
};

export const createUser = (user) => {
    let data = new FormData();
    data.append('username', user.username);
    data.append('password', user.password);
    data.append('firstname', user.firstname);
    data.append('middlename', user.middlename);
    data.append('lastname', user.lastname);
    data.append('mobile_no', user.mobile_no);
    data.append('office_id', user.office.office_id);
    data.append('role_id', user.role_id);
    data.append('added_by', user.added_by);
    data.append('isactive', user.isactive? 'Y' : 'N');
    return API().post('/Users/Add/index_post', data).then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
}

export const editUser = (user) => {
    let data = new FormData();
    data.append('id', user.id);
    data.append('firstname', user.firstname);
    data.append('middlename', user.middlename);
    data.append('lastname', user.lastname);
    data.append('mobile_no', user.mobile_no);
    data.append('office_id', user.office.office_id);
    data.append('role_id', user.role_id);
    data.append('isactive', user.isactive ? 'Y' : 'N');
    return API().post('/Users/Edit/index_post', data).then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
}

export const resetUser = (user) => {
    let data = new FormData();
    data.append('id', user.id);
    return API().post('/Users/ResetPassword/index_post', data).then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
}

export const goDeactivateUser = (dataInput) => {
    let data = new FormData();
    data.append("id", dataInput.id);
    return API().post("/Users/Deactivate/index_post", data).then((res) => {
        return res;
    }).catch((err) => err);
};

export const getOffices = () => {
    return API().get("/Users/GetOffices").then((res) => {
        return res.data;
    }).catch((err) => err);
};

export const getUserAccount = (dataInput) => {
    let data = new FormData();
    data.append("id", dataInput.id);
    data.append("dg_user", dataInput.dg_user);
    return API()
        .post("/Users/GetUserAccount", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
};

export const editUserAccountPass = (dataInput) => {
    let data = new FormData();
    data.append('id', dataInput.id);
    data.append('current_password', dataInput.pwd_current);
    data.append('new_password', dataInput.pwd_new);
    data.append('dg_user', dataInput.dg_user);
    return API().post('/Users/Profile_UpdatePassword/index_post', data).then((response)=>{
        return response.data;
    }).catch((err)=>{
        console.error(err);
    });
}