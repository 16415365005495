export default (state = {
    categoryDatasource: [],
    categoryTotal: 0,
    categoryFilter: [],
    supplierCreate: {}
}, action) => {
    switch (action.type) {
        case "SETCATEGORYDATASOURCE":
            return state = {
                ...state,
                categoryDatasource: action.payload.categoryDatasource,
                categoryTotal: action.payload.categoryTotal,
                categoryFilter: action.payload.categoryFilter,
            }
        case "DROPCATEGORYSTATE":
            return state = {
                categoryDatasource: [],
                categoryTotal: 0,
                categoryFilter: [],
                supplierCreate: {}
            }
        default:
            return state;
    }
};
