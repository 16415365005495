export const mapStateToProps = (state) => {
	return {
		session: state.session,
		ppmp: state.ppmp,
		data: state.data,
		auth: state.auth,
		itemCategory: state.itemCategory,
	};
};

export const mapDispatchToProps = (dispatch) => {
	return {
		setPPMPDataSource: (res) => {
			dispatch({
				type: "SETPPMPDATASOURCE",
				payload: res
			})
		},
		setGenPPMPDataSource: (res) => {
			dispatch({
				type: "SETGENPPMPDATASOURCE",
				payload: {
					genPPMPDataSource: res.genPPMPDataSource,
				}
			})
		},
		setSignatoriesDataSource: (res) => {
			dispatch({
				type: "SETSIGNATORIESDATASOURCE",
				payload: {
					signatoriesDatasource: res.signatoriesDatasource,
				}
			})
		},
		setItemDataSource: (res) => {
			dispatch({
				type: "SETITEMDATASOURCE",
				payload: res
			})
		},
		displaySupplies: (res) => {
			dispatch({
				type: "SETSUPPLIESDISPLAY",
				payload: res
			})
		},
		displaysSupplies: (res) => {
			dispatch({
				type: "SETEDITdISPLAY",
				payload: res
			})
		},
		displayLoading: (res) => {
			dispatch({
				type: "DISPLAYLOADING",
				payload: {
					value: res,
				}
			})
		},
		displayAlert: (res) => {
			dispatch({
				type: "DISPLAYALERT",
				payload: {
					visible: res.visible,
					message: res.message,
					severity: res.severity,
				}
			})
		},
		setRespoDefaultValues: (res) => {
			dispatch({
				type: "SETRESPODEFAULTVALUE",
				payload: { ...res }
			})
		},
		setRespoCenterDataSource: (res) => {
			dispatch({
				type: "SETRESPOCENTERDATASOURCE",
				payload: res
			})
		},
		setCurrentSignatories: (res) => {
			dispatch({
				type: "SETCURRENTSIGNATORIES",
				payload: res

			})
		},
		setPPMPManualRequestItemDataSource: (res, callback) => {
			dispatch({
				type: "SETPPMPMANUALREQUESTITEMDATASOURCE",
				payload: {
					PPMPRequestItemDatasource: res.PPMPRequestItemDatasource,
					categoryDatasource: res.categoryDatasource,
					itemsRequestTotal: res.total,
					itemsRequestFilter: res.itemsRequestFilter,
				},
				callback: () => { callback(); }
			})
		},
		setItemsCategoriesDataSource: (res) => {
			dispatch({
				type: "SETITEMSCATEGORIESDATASOURCE",
				payload: {
					itemsCategoriesDatasource: res.itemsCategoriesDatasource,
					// userroleTotal: res.total,
					// userroleFilter: res.userroleFilter,
				}
			})
		},
		setRequestedItemsDataSource: (res) => {
			dispatch({
				type: "SETREQUESTEDITEMSDATASOURCE",
				payload: {
					requestedItemsDatasource: res.requestedItemsDatasource,
					reqTotal: res.total,
					// userroleFilter: res.userroleFilter,
				}
			})
		},
	};
};