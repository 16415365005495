import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';

import * as Material from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import EditIcon from '@material-ui/icons/Edit';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import ItemRequestModal from "../item.request/item.request.modal"; 

import { useStyles } from '../../ppmp/list/ppmp.list.styles';
import { mapStateToProps, mapDispatchToProps } from './price.request.list.reducer';
import { COMM } from '../../../helpers/common';
import * as PPMPprlService from './price.request.list.service';

class PriceRequestList extends Component {
	constructor(props) {
        super(props)
		this.state = {

            ppmp_id: 0,
            user_id: 0,

            page_no: 0,
            limit: 5,
            total: 0,

            i_keyword: "",
            i_page_no: 0,
            i_sort_column: "",
            i_sort_type: "",
            i_filter_unit: "",
            i_filter_category_name: "",
            i_filter_is_approved: true,
            i_limit: 10,
            
            needs_validation: false,
            itemRequestModalVisible: false,
            forPriceRequestList: true,

            itemData: [],
            categoryData: [],
            editData: {},
            loadingVisible: true,
            editMode: true,
            office_name: "",
        }

        this.requestPPMPPriceReqList = this.requestPPMPPriceReqList.bind(this);
        this.formatNumber = this.formatNumber.bind(this);
        this.requestChangeStatusService = this.requestChangeStatusService.bind(this);
        this.onSendForReturnClick = this.onSendForReturnClick.bind(this);
        this.handleUpdateUnitPrice = this.handleUpdateUnitPrice.bind(this);
        this.requestGetItemsCategories = this.requestGetItemsCategories.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }
	componentDidMount() {
        const { history } = this.props;

		sessionService.loadSession().then((currentSession)=>{
			if (currentSession.selectedTransaction === null || currentSession.selectedTransaction === undefined) {
				history.push('/');
			} else {
                const data = currentSession.selectedTransaction;
                this.setState({...this.state,
                    office_name: data.office,
                    office_id: this.props.data.user.office_id, 
                    transactionID: data.id,
					transactionNo: data.ppmp_no,
					transactionStatus: data.status,
					transactionDate: data.transdate,
                    transactionYear: data.year,
                    ppmp_id : data.id,
                    user_id: this.props.data.user.id,
                }, ()=> {
                    this.requestPPMPPriceReqList();
                    this.requestGetItemsCategories();
                });
			}
		}).catch((err)=>{
			this.props.displayAlert({ visible: true, message: err, severity: "warning"});
		});
	}
	componentWillUnmount() {
		const { history, setPPMPPriceReqListDataSource } = this.props;
		var targetPath = history.location.pathname;
		if (targetPath.indexOf("supplies") === -1 && targetPath.indexOf("ppmplist") === -1 && targetPath.indexOf("ppmpform") === -1 ) {
            setPPMPPriceReqListDataSource({ PPMPPriceReqListDataSource: [] });
			sessionService.saveSession({...this.props.auth, 
				selectedTransaction: null
			}).catch((err)=>{
				this.props.displayAlert({ visible: true, message: err, severity: "warning"});
			});
		}
    }
    requestPPMPPriceReqList(){
        const { displayLoading, displayAlert, setPPMPPriceReqListDataSource } = this.props;
        displayLoading(true);
        PPMPprlService.getGeneratedPPMPPriceReqList({ppmp_id: this.state.ppmp_id}).then((response) => {
            if (response.error) {
                displayAlert({ visible: true, message: response.msg, severity: "warning" })
            } else {
                this.setState({...this.state, needs_validation: parseInt(response.data.result1.needs_validation) > 0})
                setPPMPPriceReqListDataSource({ PPMPPriceReqListDataSource: response.data.result0 });
            }
            //this.getTotalBudget();
            displayLoading(false);
        }).catch((err) => {
            displayLoading(false);
            displayAlert({ visible: true, message: err, severity: "error" })
        });
    }
    formatNumber(num){
        return (num)? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "";
    }
    requestChangeStatusService (callback) {
        const {displayAlert} = this.props;
        PPMPprlService.updatePpmpStatus({...this.state}).then((res)=>{
            displayAlert({ visible: true, message: res.data.msg, severity: "success" })
            // this.props.redirectAdmin(1);
            callback();
        }).catch((err)=>{
            this.props.displayAlert({ visible: true, message: err, severity: "error" })
        });
    }
    onSendForReturnClick(){
        this.setState({...this.state, status: 'Returned'}, ()=>this.requestChangeStatusService(()=>{this.props.history.push('/admin')}))
    }
    handleUpdateUnitPrice(data){
        //this.setState({...this.state, item_id: value}, ()=> console.log("item id - unit price", this.state.item_id," - ",this.state._latest_price, "*",this.state.user_id));    
        this.setState({
            editData: data,
            itemRequestModalVisible: true,
        });
    }
    requestGetItemsCategories() {
        // setItemsDataSource
        const { displayLoading, displayAlert, setItemsCategoriesDataSource } = this.props;
        displayLoading(true);
        PPMPprlService.getItemsCategories({ ...this.state }).then((response) => {
             if (response.error) {
                displayAlert({
                    visible: true,
                    message: response.data.msg,
                    severity: "warning"
                });
            } else {
                setItemsCategoriesDataSource({
                    categoryDatasource: response.data,
                });
            }
            displayLoading(false);
        }).catch(err => {
            displayLoading(false);
            displayAlert({
                visible: true,
                message: err.msg,
                severity: "warning"
            });
        });
    }
    onCloseModal() {
        this.setState({
            ...this.state,
            itemRequestModalVisible: false,
        });
    }
	render() {
		const { transactionNo, transactionStatus, transactionDate, transactionYear, itemRequestModalVisible, editData } = this.state; //totalBudget, deteleItem, deleteDialogVisible
        const { classes, ppmpReqPriceList, showSnackBar,items } = this.props;
		return (
			<div className={classes.root}>
				<Material.Paper className={classes.transactionHeader}>
					<Material.Grid container spacing={3}>
						<Material.Grid item xs={9}>
							<div className="inline">
								<div className={classes.statusColor}>
									<Material.Chip className={transactionStatus} label={COMM.capitalizeFirstLetter(transactionStatus)} />
								</div>
								<Material.Typography className="flex" color="primary" variant="h5">{transactionNo}</Material.Typography>
								<Material.Typography variant="h6">({transactionYear})</Material.Typography>
							</div>
						</Material.Grid>
						<Material.Grid item xs={3}>
							<Material.Typography variant="subtitle2" className="pull-right">{COMM.DdMmmYyyyHhMm(transactionDate)}</Material.Typography>
						</Material.Grid>
					</Material.Grid>
					<Material.Divider className="div-margin"/>
					<Material.Typography variant="subtitle2">{this.state.office_name}</Material.Typography>
				</Material.Paper>
				<Material.Paper className="container" elevation={3}>
                    <Material.Divider></Material.Divider>
                    <Material.TableContainer component={Paper}>
                        <Material.Table className={classes.table} aria-label="generated ppmp table">
                            <Material.TableBody>
                                <Material.TableRow>
                                    <Material.TableCell align="left" className={!this.state.needs_validation ? "lightmint" : "lightfink"}><b>Item Name</b></Material.TableCell>
                                    <Material.TableCell align="left" className={!this.state.needs_validation ? "lightmint" : "lightfink"}><b>Unit Cost</b></Material.TableCell>
                                    <Material.TableCell align="left" className={!this.state.needs_validation ? "lightmint" : "lightfink"}><b>Unit</b></Material.TableCell>
                                    <Material.TableCell align="left" className={!this.state.needs_validation ? "lightmint" : "lightfink"}><b>Category</b></Material.TableCell>
                                    <Material.TableCell align="left" className={!this.state.needs_validation ? "lightmint" : "lightfink"}></Material.TableCell>
                                </Material.TableRow>
                                {   ppmpReqPriceList.PPMPPriceReqListDataSource.map((element, index) => {
                                    return <React.Fragment key={`class-${index}`}>
                                            {   element.funds.map((fund_item,fund_index) => {
                                                return <React.Fragment key={`fund-${fund_index}`}>
                                                    { fund_item.ppa_list.map((ppa_list_item,ppa_list_index)=>{
                                                        return <React.Fragment key={`fund_ppa-${ppa_list_index}`}>
                                                            {   ppa_list_item.items.map((item_data, item_index) => {
                                                                    return <Material.TableRow key={`items-${item_index}`}  className={classes.tableRow} onClick={()=>{((item_data.is_approved === 'W') || (parseFloat(item_data.unit_cost) === 0 && item_data.is_approved === 'Y')) && this.handleUpdateUnitPrice(item_data)}}>
                                                                        <Material.TableCell align="left">
                                                                        <Material.Tooltip title={item_data.is_approved === 'N' ? item_data.reject_remarks : ""} arrow placement="top">
                                                                            <b><Material.Chip className={(item_data.is_approved==="Y") ? classes.approved: (item_data.is_approved==="W") ? classes.pending : classes.rejected } size="small" avatar={(item_data.is_approved==="Y") ? <CheckCircleIcon />: (item_data.is_approved==="W") ? <HelpIcon />: <ThumbDownIcon />} label={item_data.name}/></b></Material.Tooltip> - {item_data.description} {item_data.remark? `(${item_data.remark})`:""}
                                                                        </Material.TableCell>
                                                                        <Material.TableCell align="left">
                                                                            <Material.Tooltip title={(parseFloat(item_data._latest_price) > 0) && (parseFloat(item_data.unit_cost) === 0) ? `Item has latest price found: ${item_data._latest_price}` : "Item price is already latest"} arrow placement="top">
                                                                                <Material.Chip align="center" className={(parseFloat(item_data.unit_cost)>0) ? classes.approved: classes.pending } size="small" avatar={(parseFloat(item_data.unit_cost)===0) ? <HelpIcon/>:<CheckCircleIcon/> } label={this.formatNumber(item_data.unit_cost)} />
                                                                            </Material.Tooltip>
                                                                        </Material.TableCell>
                                                                        <Material.TableCell>{item_data.unit}</Material.TableCell>
                                                                        <Material.TableCell>{item_data.category_name}</Material.TableCell>
                                                                        <Material.TableCell>
                                                                        {
                                                                            ((item_data.is_approved === 'W') || (parseFloat(item_data.unit_cost) === 0 && item_data.is_approved === 'Y')) ? 
                                                                                <Material.IconButton className="animate" color="primary" onClick={()=>{this.handleUpdateUnitPrice(item_data)}}>
                                                                                    <EditIcon/>
                                                                                </Material.IconButton> : 
                                                                            <React.Fragment></React.Fragment>
                                                                        }
                                                                        </Material.TableCell>
                                                                        </Material.TableRow>
                                                                    })
                                                            }</React.Fragment>
                                                        })
                                                    }</React.Fragment>
                                                })
                                            }</React.Fragment>
                                    })
                                }
                                { ppmpReqPriceList.PPMPPriceReqListDataSource.length === 0? 
                                   <Material.TableRow>
                                        <Material.TableCell colSpan={20} className="emptyCont"> 
                                            <div className="empty">
                                                <div className="emptyBorder">
                                                    There is no Pre-Approval PPMP at this time.  
                                                </div>
                                            </div>
                                        </Material.TableCell>
                                   </Material.TableRow>: 
                                   <Material.TableRow />
                                }
                            </Material.TableBody>
                        </Material.Table>                    
                    </Material.TableContainer>
                    {   ppmpReqPriceList.PPMPPriceReqListDataSource.length > 0 ?
                        <React.Fragment>
                            <div align="right">
                            <Material.Button disabled={this.state.needs_validation ? true : false} className={classes.button} variant="contained" color="primary" startIcon={<ThumbUpIcon />}
                                onClick={()=>{this.onSendForReturnClick()}} > Save &amp; Approve                                         						
                            </Material.Button>
                            </div>
                        </React.Fragment>  
                        : <React.Fragment />
                    }
                    <ItemRequestModal
                        itemRequestModalVisible={itemRequestModalVisible}
                        showSnackBar={showSnackBar}
                        onCloseModal={this.onCloseModal}
                        editData={editData}
                        categoryData={items.categoryDatasource}
                        forPriceRequestList={this.state.forPriceRequestList}
                        requestPPMPPriceReqList={this.requestPPMPPriceReqList}
                    />
				</Material.Paper>
			</div>
		);
	}
}

export default connect( mapStateToProps, mapDispatchToProps)(Material.withStyles(useStyles)(PriceRequestList));
