import * as React from 'react';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';

import { withStyles } from '@material-ui/core/styles';
import { mapStateToProps, mapDispatchToProps } from '../container/container.reducer';
import { useStyles } from './login.styles';

class Logout extends React.Component {
    constructor() {
        super();
        this.state = {
            seconds: 5
        };
        this.logOut = this.logOut.bind(this);
    }
    componentDidMount() {
        this.myInterval = setInterval(() => {
            this.setState(({ seconds }) => ({
                seconds: seconds - 1
            }))
            if (this.state.seconds === 0) { clearInterval(this.myInterval); this.logOut(); }
        }, 1000)
    }
    componentWillUnmount() {
        clearInterval(this.myInterval)
    }
    logOut(){
        const { removeUser, removeCredential, removeStorageState, history } = this.props;
        sessionService.deleteSession().then(()=>{
            sessionService.deleteUser().then(()=>{
                removeUser();
                removeCredential();
                removeStorageState()
                history.push('/');
            });
        });
    }
    render() {
        const { seconds } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.logoutContainer}>
                <div>You will be logged out in <b>{seconds}</b> seconds</div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Logout));