import axios from "axios";
import * as Handler from "./validateSession";

export let connection = {
  baseURL: "",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  proxy: {
    host: "192.168.13.108",
    port: 443,
    auth: {
      username: "admin",
      password: "2ok@sS35$HlG&Lstx",
    },
  },
};
let dgConnection = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export const checkEnvironment = () => {
  const location = window.location;   
  if (location.hostname !== "localhost") {
    connection.baseURL = location.href;
  }
};

export const initAuthOp = (callback) => {
  Handler.getAuth((auth) => {
    connection.auth = auth;
    dgConnection.auth = auth;
    callback();
  }); 
};

export const initToken = (callback) => {
  Handler.getToken((auth) => {
    const key = `${auth}`;
    connection.headers["x-api-key"] = key;
    dgConnection.headers["x-api-key"] = key;
    callback();
  });
};
export const initURL = (callback) => {
  Handler.setBaseURL((url) => {
    connection.baseURL = url;
    callback();
  });
  Handler.setdgURL((url) => {
    dgConnection.baseURL = url;
  });
};

export const setAPIauth = (session) => {
  connection.headers["Auth"] = `${session}`;
  dgConnection.headers["Auth"] = `${session}`;
};

export const API = () => {
  return axios.create(connection);
};

export const dgAPI = () => {
  return axios.create(dgConnection);
};


