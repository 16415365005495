export default (state = {
	suppliersDatasource: [],
	suppliersTotal: 0,
	suppliersFilter: [],
	supplierCreate: {}
}, action) => {
    switch (action.type){
		case "SETSUPPLIERDATASOURCE": 
            return state = {
                ...state,
				suppliersDatasource: action.payload.suppliersDatasource,
				suppliersTotal: action.payload.suppliersTotal,
				suppliersFilter: action.payload.suppliersFilter,
			}
		case "DROPSUPPLIESSTATE": 
            return state = {
                suppliersDatasource: [],
				suppliersTotal: 0,
				suppliersFilter: [],
				supplierCreate: {}
			}
        default:
            return state;
	}
};
