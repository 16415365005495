export default (state = {
    poDataSource: [],
    poTotal: 0,
    poFilter: [],
}, action) => {
    switch (action.type) {
        case "SETPODATASOURCE":
            return state = {
                ...state,
                poDataSource: action.payload.poDataSource,
                poTotal: action.payload.poTotal,
                poFilter: action.payload.poFilter,
            }
        case "DROPPOSTATE": 
			return state = {
				poDataSource: [],
                poTotal: 0,
                poFilter: [],
			}
        default:
            return state;
    }
};
