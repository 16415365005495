import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";

import * as Material from "@material-ui/core";
import { orange } from "@mui/material/colors";

import SaveAltIcon from "@material-ui/icons/SaveAlt";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import TextField from "@material-ui/core/TextField";
import BallotIcon from "@material-ui/icons/Ballot";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Paper from "@material-ui/core/Paper";

import { useStyles } from "./ppmp.list.styles";
import { mapStateToProps, mapDispatchToProps } from "../ppmp.reducer";
import { COMM } from "../../../helpers/common";
import * as PPMPService from "../ppmp.service";
import * as LoginService from "../../auth/login.service";

import ViewRequestedItemModal from "../request.item/view.req.item.modal";
// import SuppliesModal from '../supplies/ppmp.modal';
import EditModal from "../list/edit.modal";
import SignatoriesModal from "../form/signatories.modal";
import AddPOWModal from "./addpow.modal";

class PPMPList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      allotment_class: {},
      editMode: false,
      editDataQty: {},
      totalBudget: 0,

      ppmp_id: 0,
      itemCost: 0,

      Qty: 0,

      ItemName: "",
      ItemDescription: "",
      UnitCost: 0,
      totalCost: 0,
      qtyJan: 0,
      qtyFeb: 0,
      qtyMar: 0,
      qtyApr: 0,
      qtyMay: 0,
      qtyJun: 0,
      qtyJul: 0,
      qtyAug: 0,
      qtySep: 0,
      qtyOct: 0,
      qtyNov: 0,
      qtyDec: 0,
      id: 0,
      ppmpItemId: 0,
      office_id: 0,
      ppmp_title: "",
      status: "Generated",
      division_name: "",
      ViewReqItemsModalVisible: false,
      deleteDialogVisible: false,
      exceedDialogVisible: false,
      deteleItem: {},
      exceedItem: {},
      page_no: 0,
      limit: 5,
      is_indicative: 0,
      is_unforeseen: 0,

      total: 0,

      signatoriesData: {},
      modalVisible: false,
      signatoriesFound: false,
      isPreApproval: false,
      isForEdit: false,
      preRemoval: false,
      ppmpEditModalVisible: false,

      pow_id: 0,
      pow_title: "",
      pow_description: "",
      pow_location: "",
      pow_project_cost: 0,
      pow_fund_source: "",
      add_pow_visible: false,
      pow_file_link: "",
      uploadFileLink: "",
      is_indicative_checked: false,
      alertDialogVisible: false,
      // is_reimbursement: 0,

      ppmpVerifyPasswordModalVisible: false,
      password: "",
      username: "",
      proc: "",
    };
    this.onClickAddTransactionButton =
      this.onClickAddTransactionButton.bind(this);
    this.onDisplayDialog = this.onDisplayDialog.bind(this);
    this.requestPPMP = this.requestPPMP.bind(this);
    this.onSaveItemQuantity = this.onSaveItemQuantity.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.onHandleDelete = this.onHandleDelete.bind(this);
    this.onHandleExceed = this.onHandleExceed.bind(this);
    this.onHandleEditData = this.onHandleEditData.bind(this);
    this.onHandleEdit = this.onHandleEdit.bind(this);
    this.getTotalBudget = this.getTotalBudget.bind(this);
    this.requestGetRequestedItems = this.requestGetRequestedItems.bind(this);
    this.closeViewReqItemsModal = this.closeViewReqItemsModal.bind(this);
    this.onDisplayRequestedItems = this.onDisplayRequestedItems.bind(this);
    this.requestChangeStatusService =
      this.requestChangeStatusService.bind(this);
    this.onSaveAndGenerateClick = this.onSaveAndGenerateClick.bind(this);
    this.checkSignatories = this.checkSignatories.bind(this);
    this.requestOfficeSignatories = this.requestOfficeSignatories.bind(this);
    this.onSetSignatories = this.onSetSignatories.bind(this);
    this.onCloseSigModal = this.onCloseSigModal.bind(this);
    this.setSignatoriesData = this.setSignatoriesData.bind(this);
    this.onDisplayPPMPEditDialog = this.onDisplayPPMPEditDialog.bind(this);
    this.getPOWId = this.getPOWId.bind(this);
    this.onCloseModalAddPOW = this.onCloseModalAddPOW.bind(this);
    this.initFunctions = this.initFunctions.bind(this);
    this.onDownloadFile = this.onDownloadFile.bind(this);
    this.onCheckIndicativeHandlerCreate =
      this.onCheckIndicativeHandlerCreate.bind(this);
    this.onDisplayPPMPVerifyPasswordDialog =
      this.onDisplayPPMPVerifyPasswordDialog.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.requestPassword = this.requestPassword.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEditPPMPDetails = this.onEditPPMPDetails.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onEditPPMPDetails = () => {
    const { classes } = this.props;
    const { ppmpEditModalVisible } = this.state;

    return (
      <Material.Dialog
        open={ppmpEditModalVisible}
        onClose={() => {
          this.onDisplayPPMPEditDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Material.DialogTitle id="alert-dialog-title">
          Edit PPMP?
        </Material.DialogTitle>
        <Material.DialogContent>
          <div>
            <Material.TextField
              id="ppmp_title"
              label="Title"
              type="text"
              onChange={(e) => {
                this.setState({ ...this.state, ppmp_title: e.target.value });
              }}
              value={this.state.ppmp_title}
              className={classes.textField_input}
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
            <Material.TextField
              id="division"
              label="Division/Section/Unit"
              type="text"
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  division_name: e.target.value,
                });
              }}
              value={this.state.division_name}
              className={classes.textField_input}
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
            <Material.FormControlLabel
              control={
                <Material.Checkbox
                  checked={this.state.is_indicative > 0}
                  value={this.state.is_indicative_checked}
                  name="is_indicative_checked"
                  onChange={this.onCheckIndicativeHandlerCreate}
                  disabled={parseInt(this.state.is_indicative) === 0}
                />
              }
              label="Is Indicative ?"
            />
          </div>
        </Material.DialogContent>
        <Material.DialogActions>
          <div>
            <Material.Button
              onClick={() => {
                this.onDisplayPPMPEditDialog(false);
              }}
              color="secondary"
            >
              {" "}
              Cancel
            </Material.Button>
            <Material.Button
              onClick={this.onClickAddTransactionButton}
              color="primary"
              autoFocus
            >
              Edit
            </Material.Button>
          </div>
        </Material.DialogActions>
      </Material.Dialog>
    );
  };
  requestPassword() {
    const { displayLoading, displayAlert } = this.props;
    const { usernameError, passwordError } = this.state;

    if (!usernameError && !passwordError) {
      displayLoading(true);
      LoginService.login({ ...this.state })
        .then((res) => {
          if (res.error) {
            displayAlert({
              visible: true,
              message: res.message,
              severity: "error",
            });
            displayLoading(false);
          } else {
            // CALL EDIT DIALOG AFTER PASSWORD VERIFICATION
            displayLoading(false);
            this.onDisplayPPMPVerifyPasswordDialog(false);
            this.setState({ ppmpEditModalVisible: true, password: "" });
          }
        })
        .catch((err) => {
          displayAlert({ visible: true, message: err, severity: "warning" });
          displayLoading(false);
        });
    }
  }
  onVerify(e) {
    e.preventDefault();
    const user = {
      username: this.state.username,
      password: this.state.password,
    };
    // let validate = { usernameError: false, passwordError: false };
    // user.username === "" && (validate.usernameError = true);
    // user.password === "" && (validate.passwordError = true);
    this.setState({ ...this.state }, () => this.requestPassword());
  }
  onDownloadFile() {
    let link = process.env.REACT_APP_ROOTURL + this.state.pow_file_link;
    // console.log("$$file_link", link);
    window.open(link, "_blank", "noreferrer");
  }
  componentDidMount() {
    const { history } = this.props;

    sessionService
      .loadSession()
      .then((currentSession) => {
        if (
          currentSession.selectedTransaction === null ||
          currentSession.selectedTransaction === undefined
        ) {
          history.push("/");
        } else {
          const data = currentSession.selectedTransaction;
          console.log("$$daatta",data);
          this.setState(
            {
              ...this.state,
              office_id: this.props.data.user.office_id,
              transactionID: data.id,
              transactionNo: data.ppmp_no,
              transactionStatus: data.status,
              transactionDate: data.transdate,
              transactionYear: data.year,
              transactionDivision: data.division_name,
              transactionTitle: data.ppmp_title,
              ppmp_title: data.ppmp_title,
              division_name: data.division_name,
              ppmp_id: data.id,
              isPreApproval: data.status === "Pre-Approval",
              isForEdit: data.status === "For Edit",
              is_indicative: data.is_indicative,
              transactionData: data,
              username: this.props.data.user.username,
              proc: this.props.data.user.is_dg,
              is_unforeseen: data.is_unforeseen,
              parent_id: data.parent_id,
            },

            () => {
              this.initFunctions();
            }
          );
        }
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "warning",
        });
      });
  }
  initFunctions() {
    this.requestPPMP();
    this.requestGetRequestedItems();
    this.requestOfficeSignatories();
    this.setSignatoriesData();
    this.getPOWId(this.state.ppmp_id);
    // console.log('$$State', this.state);
  }
  getPOWId(ppmp_id) {
    PPMPService.getDetails({ id: ppmp_id })
      .then((response) => {
        // console.log("$$ppmp_details", response);
        this.setState(
          {
            ...this.state,
            pow_id: !response.error ? response.data[0].pow_id : 0,
            pow_title: !response.error ? response.data[0].title : "",
            pow_description: !response.error
              ? response.data[0].description
              : "",
            pow_location: !response.error ? response.data[0].location : "",
            pow_project_cost: !response.error
              ? response.data[0]._project_cost
              : 0,
            pow_fund_source: !response.error
              ? response.data[0].fund_source
              : "",
            pow_file_link: !response.error ? response.data[0]._file_link : "",
            uploadFileLink: response.data[0]._file_link,

            // is_reimbursement: !response.error ? response.data[0].is_reimbursement : "",
          },
          () => {
            // console.log("$$upload", this.state.uploadFileLink);
            // console.log('$$pow_title', this.state.pow_title);
            // console.log('$$pow_description', this.state.pow_description);
            // console.log('$$pow_location', this.state.pow_location);
            // console.log('$$pow_project_cost', this.state.pow_project_cost);
          }
        );
      })
      .catch((err) => {
        // console.log("$$err", err);
      });
  }
  componentWillUnmount() {
    const { history, setGenPPMPDataSource } = this.props;
    var targetPath = history.location.pathname;
    if (
      targetPath.indexOf("supplies") === -1 &&
      targetPath.indexOf("ppmplist") === -1 &&
      targetPath.indexOf("ppmpform") === -1
    ) {
      setGenPPMPDataSource({ genPPMPDataSource: [] });
      sessionService
        .saveSession({
          ...this.props.auth,
          selectedTransaction: null,
        })
        .catch((err) => {
          this.props.displayAlert({
            visible: true,
            message: err,
            severity: "warning",
          });
        });
    }
  }
  requestPPMP() {
    const { displayLoading, displayAlert, setGenPPMPDataSource } = this.props;
    displayLoading(true);
    PPMPService.getGeneratedPPMP({ ppmp_id: this.state.ppmp_id })
      .then((response) => {
        // console.log("$$response", response);
        if (response.error) {
          displayAlert({
            visible: true,
            message: response.msg,
            severity: "warning",
          });
        } else {
          this.setState({
            ...this.state,
            preApproval: parseInt(response.data.result1.needs_validation) > 0,
            preRemoval: parseInt(response.data.result2.needs_removal) > 0,
          });
          setGenPPMPDataSource({ genPPMPDataSource: response.data.result0 });

          // // get items
          // let data1 = response.data.result0;
          // // console.log("$$data1", data1);
          // data1.map((fundData) => {
          //   // console.log("$$Funds", fundData.funds);
          //   fundData.funds.map((ppaList) => {
          //     // console.log("$$PPA_LIST", ppaList.ppa_list);
          //     ppaList.ppa_list.map((Items) => {
          //       // console.log("$$items", Items);
          //       Items.items.map((itemData) => {
          //         // console.log("$$itemData", itemData);
          //       });
          //     });
          //   });
          // });
        }
        this.getTotalBudget();
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({ visible: true, message: err, severity: "error" });
      });
  }
  getTotalBudget() {
    const { displayAlert } = this.props;
    PPMPService.getTotalBudget({ ppmp_id: this.state.ppmp_id })
      .then((response) => {
        if (response.error) {
          displayAlert({
            visible: true,
            message: response.msg,
            severity: "warning",
          });
        } else {
          this.setState({
            ...this.state,
            totalBudget: response.result0[0].tot,
          });
        }
      })
      .catch((err) => {
        displayAlert({ visible: true, message: err, severity: "error" });
      });
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: Number(e.target.value) });
  }
  onDisplayDialog(value) {
    this.setState({ ...this.state, dialogVisible: value });
  }
  onCheckIndicativeHandlerCreate(e) {
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      ...prevState,
      is_indicative_checked: isChecked,
      is_indicative: isChecked ? 1 : 0,
    }));
  }

  onSaveItemQuantity(value) {
    const {
      qtyJan,
      qtyFeb,
      qtyMar,
      qtyApr,
      qtyMay,
      qtyJun,
      qtyJul,
      qtyAug,
      qtySep,
      qtyOct,
      qtyNov,
      qtyDec,
      itemCost,
    } = this.state;
    const { displayAlert } = this.props;
    const Qty =
      Number(qtyJan) +
      Number(qtyFeb) +
      Number(qtyMar) +
      Number(qtyApr) +
      Number(qtyMay) +
      Number(qtyJun) +
      Number(qtyJul) +
      Number(qtyAug) +
      Number(qtySep) +
      Number(qtyOct) +
      Number(qtyNov) +
      Number(qtyDec);
    const totalCost = Number(Qty) * Number(itemCost);
    PPMPService.editQtyPerMonth({ ...this.state, Qty, totalCost })
      .then((res) => {
        displayAlert({
          visible: true,
          message: res.data.msg,
          severity: "success",
        });
        this.onDisplayDialog(false);
        this.requestPPMP();
      })
      .catch((err) => {
        displayAlert({ visible: true, message: err, severity: "error" });
      });
  }
  onRemoveItem(row) {
    const { displayAlert, displayLoading } = this.props;
    displayLoading(true);
    PPMPService.deletePpmpItem(row)
      .then((res) => {
        displayAlert({
          visible: true,
          message: res?.error ? "Error deleting item!" : "Success!",
          severity: res?.error ? "error" : "success",
        });
        this.requestPPMP();
        displayLoading(false);
        this.onDisplayDialog(false);
        this.onHandleDelete(false, {});
        this.getPOWId(this.state.ppmp_id);
      })
      .catch((err) => {
        displayAlert({ visible: true, message: err, severity: "error" });
      });
  }
  formatNumber(num) {
    return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";
  }
  onHandleDelete(visible, row) {
    this.setState({
      ...this.state,
      deleteDialogVisible: visible,
      deteleItem: row,
    });
  }
  onHandleExceed(visible, row) {
    this.setState({
      ...this.state,
      exceedDialogVisible: visible,
      exceedItem: row,
    });
  }
  onHandleEditData(row) {
    this.setState({ dialogVisible: true, editDataQty: row });
    this.onHandleEdit(row);
  }
  onHandleEdit(row) {
    // console.log("$$row", row);
    this.setState({
      ItemName: this.state.editDataQty.name,
      ItemDescription: this.state.editDataQty.description,
      UnitCost: Number(this.state.editDataQty.unit_cost),
      qtyJan: this.state.editDataQty.qty_jan,
      qtyFeb: this.state.editDataQty.qty_feb,
      qtyMar: this.state.editDataQty.qty_mar,
      qtyApr: this.state.editDataQty.qty_apr,
      qtyMay: this.state.editDataQty.qty_may,
      qtyJun: this.state.editDataQty.qty_jun,
      qtyJul: this.state.editDataQty.qty_jul,
      qtyAug: this.state.editDataQty.qty_aug,
      qtySep: this.state.editDataQty.qty_sep,
      qtyOct: this.state.editDataQty.qty_oct,
      qtyNov: this.state.editDataQty.qty_nov,
      qtyDec: this.state.editDataQty.qty_dec,
      itemCost: this.state.editDataQty.unit_cost,
      id: this.state.editDataQty.ppmp_item_id,
    });
    this.props.displaySupplies({ modalVisible: false, suppliesData: {} });
  }

  requestGetRequestedItems() {
    PPMPService.getRequestedItems({ ...this.state })
      .then((response) => {
        this.props.setRequestedItemsDataSource({
          requestedItemsDatasource: response.data.result.result0,
          total: parseInt(response.data.result.result1[0].ROW_COUNT),
        });
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "error",
        });
      });
  }
  closeViewReqItemsModal() {
    this.setState({ ViewReqItemsModalVisible: false }, () => {
      this.initFunctions();
    });
  }
  onDisplayRequestedItems() {
    this.setState({ ViewReqItemsModalVisible: true });
  }
  requestChangeStatusService(callback) {
    const { displayAlert } = this.props;
    PPMPService.updatePpmpStatus({ ...this.state })

      .then((res) => {
        if (res.data[0]._ret === "$$ok") {
          displayAlert({
            visible: true,
            message: "PPMP status set successfuly.",
            severity: "success",
          });
          callback();
        } else {
          this.onHandleExceed(true, res.data[0]);
        }
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "error",
        });
      });
  }
  requestOfficeSignatories() {
    const { displayLoading, displayAlert, setSignatoriesDataSource } =
      this.props;
    displayLoading(true);
    PPMPService.getOfficeSignatories({ office_id: this.state.office_id })
      .then((response) => {
        if (response.error) {
          displayAlert({
            visible: true,
            message: response.msg,
            severity: "warning",
          });
        } else {
          setSignatoriesDataSource({
            signatoriesDatasource: response.data.result,
          });
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({ visible: true, message: err, severity: "error" });
      });
  }
  onSetSignatories() {
    this.setState({
      modalVisible: true,
      signatoriesData: this.props.ppmp.signatoriesDatasource,
    });
  }
  setSignatoriesData() {
    const { setCurrentSignatories, displayLoading, displayAlert } = this.props;

    displayLoading(true);
    PPMPService.getPPMPsignatories({ ppmp_id: this.state.ppmp_id })
      .then((response) => {
        if (response.error) {
          //display error message from server.
          displayAlert({
            visible: true,
            message: response.msg,
            severity: "warning",
          });
        } else {
          const data = response.data[0];
          setCurrentSignatories({
            adminFullname: data.prepared_signatory_name
              ? data.prepared_signatory_name
              : "",
            adminDesignation: data.prepared_signatory_pos
              ? data.prepared_signatory_pos
              : "",
            headFullname: data.head_signatory_name
              ? data.head_signatory_name
              : "",
            headDesignation: data.head_signatory_pos
              ? data.head_signatory_pos
              : "",
            budgetFullname: data.reviewed_signatory_name
              ? data.reviewed_signatory_name
              : "",
            budgetDesignation: data.reviewed_signatory_pos
              ? data.reviewed_signatory_pos
              : "",
          });
          if (this.props.ppmp.signatories.length > 0) {
            this.setState({ signatoriesFound: true });
          }
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          visible: true,
          message: err,
          severity: "error",
        });
      });
  }
  onClickAddTransactionButton() {
    const { displayLoading, displayAlert, history } = this.props;
    displayLoading(true);
    PPMPService.editPPMPTransaction({
      ppmp_id: this.state.transactionID,
      division_name: this.state.division_name,
      ppmp_title: this.state.ppmp_title,
      is_indicative: this.state.is_indicative,
    })
      .then((res) => {
        if (res.error) {
          displayAlert({
            visible: true,
            message: `${res.msg}`,
            severity: "error",
          });
        } else {
          history.push("/");
          displayAlert({
            visible: true,
            message: `${res.msg}`,
            severity: "success",
          });
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        this.onDisplayDialog(false);
        displayAlert({ visible: true, message: `${err}`, severity: "warning" });
      });
  }
  onCloseSigModal() {
    this.setState({ ...this.state, modalVisible: false }, () => {
      this.initFunctions();
    });
  }
  onCloseModalAddPOW() {
    this.setState({
      ...this.state,
      add_pow_visible: false,
    });
  }
  onDisplayPPMPEditDialog(value) {
    this.setState({ ...this.state, ppmpEditModalVisible: value });
  }
  onDisplayPPMPVerifyPasswordDialog(value) {
    this.setState({ ...this.state, ppmpVerifyPasswordModalVisible: value });
  }
  // onSaveAndGenerateClick() {
  //   // check if there is a un checked items
  //   const { ppmp, displayAlert } = this.props;
  //   // loop structure
  //   // data-source
  //   //      funds
  //   //          ppa_list
  //   //              items
  //   let hasItemNotChecked = false;
  //   // data-source
  //   for (var ds = 0; ds < ppmp.genPPMPDataSource.length; ds++) {
  //     for (var f = 0; f < ppmp.genPPMPDataSource[ds].funds.length; f++) {
  //       for (
  //         var p = 0;
  //         p < ppmp.genPPMPDataSource[ds].funds[f].ppa_list.length;
  //         p++
  //       ) {
  //         for (
  //           var i = 0;
  //           i < ppmp.genPPMPDataSource[ds].funds[f].ppa_list[p].items.length;
  //           i++
  //         ) {
  //           if (
  //             parseInt(
  //               ppmp.genPPMPDataSource[ds].funds[f].ppa_list[p].items[i]
  //                 .user_checked
  //             ) === 0
  //           ) {
  //             hasItemNotChecked = true;

  //             break;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   if (!hasItemNotChecked) {
  //     this.setState({ ...this.state, status: "Generated" }, () =>
  //       this.requestChangeStatusService(() => {
  //         this.props.history.push("/ppmpform");
  //       })
  //     );
  //   } else {
  //     displayAlert({
  //       visible: true,
  //       message:
  //         "Cannot proceed! Some items are not yet checked! To check each item you need to edit and save it to proceed.",
  //       severity: "error",
  //     });
  //   }
  // }

  onSaveAndGenerateClick() {
    const { ppmp } = this.props;

    // console.log("item", ppmp);

    const hasUncheckedItems = ppmp.genPPMPDataSource.some((dataSource) =>
      dataSource.funds.some((fund) =>
        fund.ppa_list.some((ppa) =>
          ppa.items.some((item) => parseInt(item.user_checked) === 0)
        )
      )
    );

    const hasWaitingItemsRequested = ppmp.genPPMPDataSource.some((dataSource) =>
      dataSource.funds.some((fund) =>
        fund.ppa_list.some((ppa) =>
          ppa.items.some(
            (item) => item.is_approved === "W" // Checking for "W" as a string
          )
        )
      )
    );

    const hasUnapprovedItems = ppmp.genPPMPDataSource.some((dataSource) =>
      dataSource.funds.some((fund) =>
        fund.ppa_list.some((ppa) =>
          ppa.items.some(
            (item) =>
              parseInt(item.bong_approved) === 3 ||
              parseInt(item.bong_approved) === 0
          )
        )
      )
    );

    const hasUnapprovedUnforeseenItems =
      this.state.is_unforeseen > 0 &&
      ppmp.genPPMPDataSource.some((dataSource) =>
        dataSource.funds.some((fund) =>
          fund.ppa_list.some((ppa) =>
            ppa.items.some(
              (item) => parseInt(item.is_unforeseen_is_approved) !== 1
            )
          )
        )
      );

    if (
      hasUncheckedItems ||
      hasUnapprovedItems ||
      hasUnapprovedUnforeseenItems ||
      hasWaitingItemsRequested
    ) {
      this.setState({ alertDialogVisible: true });
    } else {
      this.setState({ ...this.state, status: "Generated" }, () => {
        // Set status to "Generated"
        this.requestChangeStatusService(() => {
          this.props.history.push("/ppmpform");
        });
      });
    }
  }

  onSendForApprovalClick() {
    this.setState({ ...this.state, status: "Pre-Approval" }, () =>
      this.requestChangeStatusService(() => {
        this.props.history.push("/");
      })
    );
  }
  checkSignatories() {
    const { ppmp } = this.props;
    return ppmp.signatories.adminDesignation !== "";
  }
  render() {
    const {
      transactionNo,
      transactionStatus,
      transactionTitle,
      transactionDate,
      transactionYear,
      transactionDivision,
      totalBudget,
      alertDialogVisible,
      deteleItem,
      exceedItem,
      deleteDialogVisible,
      exceedDialogVisible,
      isPreApproval,
      isForEdit,
      preRemoval,
      isIndicative,
      isUnforeseen,
      ppmpParentId,
      ppmpVerifyPasswordModalVisible,
    } = this.state;
    const { classes, ppmp, displaySupplies } = this.props;
    const { editDataQty } = this.state;

    return (
      <div className={classes.root}>
        <Material.Paper className={classes.transactionHeader}>
          <Material.Grid container spacing={3}>
            <Material.Grid item xs={9}>
              <div className="inline">
                <div className={classes.statusColor}>
                  <Material.Chip
                    className={transactionStatus === 'For Edit' ? 'For-Edit' : transactionStatus === 'Received(PBMO)' ? 'Recieved-PBMO' : transactionStatus}
                    label={COMM.capitalizeFirstLetter(transactionStatus)}
                  />
                </div>
                <Material.Typography
                  className="flex"
                  color="primary"
                  variant="h5"
                >
                  {transactionNo}
                </Material.Typography>
                <div>
                  {this.state.pow_id > 0 ? (
                    <Material.Chip
                      label="INFRA"
                      color="secondary"
                      // variant="outlined"
                    />
                  ) : (
                    ""
                  )}{" "}
                  {this.state.is_indicative > 0 ? (
                    <Material.Chip
                      label="INDICATIVE"
                      variant="outlined"
                      size="medium"
                      style={{ borderColor: "#ff9800", color: "#ff9800" }}
                    />
                  ) : (
                    ""
                  )}{" "}
                  {this.state.is_unforeseen > 0 ? (
                    <Material.Chip
                      label="UNFORESEEN"
                      variant="outlined"
                      size="medium"
                      style={{ borderColor: "#ef5350", color: "#ef5350" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <Material.Typography variant="h6">
                  ({transactionYear}) {transactionTitle}
                </Material.Typography>
                {/* <Material.Typography variant="h6" color="secondary" sx={{fontWeight: "bold"}}>{this.state.is_reimbursement > 0 ? "Reimbursement" : ""}</Material.Typography> */}
                <Material.Typography
                  variant="h6"
                  color="secondary"
                  sx={{ fontWeight: "bold" }}
                ></Material.Typography>
              </div>
            </Material.Grid>
            <Material.Grid item xs={3}>
              <Material.Typography variant="subtitle2" className="pull-right">
                {COMM.DdMmmYyyyHhMm(transactionDate)}
              </Material.Typography>
            </Material.Grid>
          </Material.Grid>
          <Material.Divider className="div-margin" />
          <Material.Grid container spacing={3}>
            <Material.Grid item xs={9}>
              <Material.Typography variant="caption">
                PPMP Transaction No
              </Material.Typography>
              <Material.Typography variant="subtitle2">
                {" "}
                Division: {transactionDivision}
              </Material.Typography>
            </Material.Grid>
            <Material.Grid item xs={3}>
              <Material.Button
                variant="contained"
                className="pull-right edit-button"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.onDisplayPPMPVerifyPasswordDialog(true)}
              >
                Edit
              </Material.Button>
            </Material.Grid>
          </Material.Grid>
        </Material.Paper>
        <Material.Paper className="container" elevation={3}>
          <Material.Grid container spacing={2}>
            <Material.Grid item xs={6}>
              <div className={classes.toolRight}>
                {this.state.pow_file_link ? (
                  this.state.pow_file_link === "uploads/infra/" ? (
                    ""
                  ) : (
                    <Material.Button
                      disabled={isPreApproval}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      startIcon={<FileDownloadIcon />}
                      onClick={this.onDownloadFile}
                    >
                      Download File
                    </Material.Button>
                  )
                ) : (
                  <Material.Button
                    disabled={isPreApproval}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    startIcon={<ShoppingCartIcon />}
                    onClick={() => {
                      if (this.state.pow_id > 0) {
                        this.setState({
                          ...this.state,
                          add_pow_visible: true,
                        });
                      } else {
                        // this.props.history.push("/ppmpsupplies");
                        this.props.history.push({
                          pathname: "/ppmpsupplies",
                          state: {
                            ppmpId: this.state.ppmp_id,
                          },
                        });
                      }
                    }}
                  >
                    {this.state.pow_id > 0 ? "Add POW" : "Add Supplies"}
                  </Material.Button>
                )}
              </div>
            </Material.Grid>
            {this.state.pow_id > 0 ? (
              ""
            ) : (
              <Material.Grid item xs={6}>
                <div align="right">
                  <Material.Button
                    disabled={isPreApproval}
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<BallotIcon />}
                    onClick={() => {
                      this.onDisplayRequestedItems();
                    }}
                  >
                    View Requested Supplies
                  </Material.Button>
                </div>
              </Material.Grid>
            )}
          </Material.Grid>
          <Material.Divider></Material.Divider>

          <Material.TableContainer component={Paper}>
            <Material.Table
              className={classes.table}
              aria-label="generated ppmp table"
            >
              <Material.TableBody>
                {ppmp.genPPMPDataSource.map((element, index) => {
                  {
                    /* {
                     console.log("$$genPPMPDataSource", ppmp.genPPMPDataSource);
                  } */
                  }
                  return (
                    <React.Fragment key={`class-${index}`}>
                      <Material.TableRow key={`class-${index}`}>
                        <Material.TableCell colSpan={20} className="blue">
                          <Material.Typography variant="h6">
                            {" "}
                            <b> {element.allotment_class}</b>
                          </Material.Typography>
                        </Material.TableCell>
                      </Material.TableRow>
                      {element.funds.map((fund_item, fund_index) => {
                        return (
                          <React.Fragment key={`fund-${fund_index}`}>
                            <Material.TableRow>
                              <Material.TableCell className="lightblue">
                                {" "}
                              </Material.TableCell>
                              <Material.TableCell
                                colSpan={20}
                                className="lightblue"
                              >
                                <b>{fund_item["fund_source"]}</b>
                              </Material.TableCell>
                            </Material.TableRow>
                            {fund_item.ppa_list.map(
                              (respo_item, respo_index) => {
                                {
                                  /* console.log("$$respo_item", respo_item); */
                                }
                                return (
                                  <React.Fragment
                                    key={`respo-item-${respo_index}`}
                                  >
                                    <Material.TableRow>
                                      <Material.TableCell className="lightgrey">
                                        {" "}
                                      </Material.TableCell>
                                      <Material.TableCell
                                        colSpan={20}
                                        className="lightgrey"
                                      >
                                        <b>{respo_item.ppa_respo}</b>
                                        {respo_item.items
                                          .reduce((filteredItems, item) => {
                                            const existingItem =
                                              filteredItems.find(
                                                (filteredItem) =>
                                                  filteredItem.respo_code ===
                                                  item.respo_code
                                              );

                                            if (!existingItem) {
                                              filteredItems.push(item);
                                            }

                                            return filteredItems;
                                          }, [])
                                          .map((item, item_index) => (
                                            <b>
                                              {" "}
                                              ({item.respo_code}){" "}
                                              {item.fund_type} ({item.year})
                                            </b>
                                          ))}
                                      </Material.TableCell>
                                    </Material.TableRow>
                                    <Material.TableRow>
                                      <Material.TableCell></Material.TableCell>
                                      <Material.TableCell align="center">
                                        <b>Item Name</b>
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        <b>Quantity</b>
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        <b>Unit</b>
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        <b>Unit Cost</b>
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        <b>Total Cost</b>
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        <b>Mode of Procurement</b>
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        <b>Is Checked ?</b>
                                      </Material.TableCell>
                                      <Material.TableCell
                                        align="center"
                                        className="lightgrey"
                                      >
                                        <b>Action</b>
                                      </Material.TableCell>
                                    </Material.TableRow>
                                    {respo_item.items
                                      .slice()
                                      .sort((a, b) =>
                                        a.name
                                          .toLowerCase()
                                          .localeCompare(b.name.toLowerCase())
                                      )
                                      .map((items, items_index) => {
                                        console.log("$$items", items);
                                        return (
                                          <Material.TableRow
                                            key={`items-data-sfsd-${items_index}`}
                                            className={classes.tableRow}
                                          >
                                            <Material.TableCell></Material.TableCell>
                                            <Material.TableCell
                                              align="left"
                                              sx={{ width: "5px" }}
                                            >
                                              <Material.Tooltip
                                                title={
                                                  items.is_approved === "N"
                                                    ? items.reject_remarks
                                                    : ""
                                                }
                                                arrow
                                                placement="top"
                                              >
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  {/* Badge for bong_approved */}
                                                  <b>
                                                    {items.bong_approved ===
                                                    "0" ? (
                                                      <Material.Tooltip title="This item needs approval from BICTO.">
                                                        <Material.Badge
                                                          classes={{
                                                            badge:
                                                              classes.customBadge,
                                                          }}
                                                          variant="dot"
                                                          badgeContent=" "
                                                          anchorOrigin={{
                                                            vertical: "top",
                                                            horizontal: "left",
                                                          }}
                                                        >
                                                          <Material.Chip
                                                            className={
                                                              items.is_approved ===
                                                              "Y"
                                                                ? // &&
                                                                  // items.pbmo_approved ===
                                                                  // "Y"
                                                                  classes.approved
                                                                : items.is_approved ===
                                                                  "W"
                                                                ? // ||
                                                                  // items.pbmo_approved ===
                                                                  // "W"
                                                                  classes.pending
                                                                : classes.rejected
                                                            }
                                                            size="small"
                                                            avatar={
                                                              items.is_approved ===
                                                              // &&
                                                              //   items.pbmo_approved
                                                              "Y" ? (
                                                                <CheckCircleIcon />
                                                              ) : items.is_approved ===
                                                                // &&
                                                                //   items.pbmo_approved
                                                                "W" ? (
                                                                <HelpIcon />
                                                              ) : items.is_approved ===
                                                                "N" ? (
                                                                <ThumbDownIcon />
                                                              ) : null
                                                            }
                                                            label={items.name}
                                                            // label="dawn1"
                                                          />
                                                          {/* <Material.Chip
                                                            className={
                                                              items.is_approved ===
                                                              "N"
                                                                ? classes.rejected
                                                                : items.is_approved ===
                                                                    "Y" &&
                                                                  items.pbmo_approved ===
                                                                    "Y"
                                                                ? classes.approved
                                                                : items.is_approved ===
                                                                    "W" ||
                                                                  items.pbmo_approved ===
                                                                    "W"
                                                                ? classes.pending
                                                                : classes.rejected
                                                            }
                                                            size="small"
                                                            avatar={
                                                              items.is_approved ===
                                                                "Y" &&
                                                              items.pbmo_approved ===
                                                                "Y" ? (
                                                                <CheckCircleIcon />
                                                              ) : (items.is_approved ===
                                                                  "Y" &&
                                                                  items.pbmo_approved ===
                                                                    "W") ||
                                                                (items.is_approved ===
                                                                  "W" &&
                                                                  items.pbmo_approved ===
                                                                    "Y") ||
                                                                (items.is_approved ===
                                                                  "W" &&
                                                                  items.pbmo_approved ===
                                                                    "W") ? (
                                                                <HelpIcon />
                                                              ) : items.is_approved ===
                                                                "N" ? (
                                                                <ThumbDownIcon />
                                                              ) : null
                                                            }
                                                            label={items.name}
                                                            // label="dawn1"
                                                          /> */}
                                                        </Material.Badge>
                                                      </Material.Tooltip>
                                                    ) : items.bong_approved ===
                                                      "3" ? (
                                                      <Material.Tooltip
                                                        title={
                                                          "Item is rejected!" +
                                                          (items.remarks
                                                            ? "With remarks: " +
                                                              items.remarks
                                                            : "")
                                                        }
                                                      >
                                                        <Material.Badge
                                                          color="secondary"
                                                          variant="dot"
                                                          badgeContent=" "
                                                          anchorOrigin={{
                                                            vertical: "top",
                                                            horizontal: "left",
                                                          }}
                                                        >
                                                          {/* <Material.Chip
                                                            className={
                                                              items.is_approved ===
                                                              "N"
                                                                ? classes.rejected
                                                                : items.is_approved ===
                                                                    "Y" &&
                                                                  items.pbmo_approved ===
                                                                    "Y"
                                                                ? classes.approved
                                                                : items.is_approved ===
                                                                    "W" ||
                                                                  items.pbmo_approved ===
                                                                    "W"
                                                                ? classes.pending
                                                                : classes.rejected
                                                            }
                                                            size="small"
                                                            avatar={
                                                              items.is_approved ===
                                                                "Y" &&
                                                              items.pbmo_approved ===
                                                                "Y" ? (
                                                                <CheckCircleIcon />
                                                              ) : (items.is_approved ===
                                                                  "Y" &&
                                                                  items.pbmo_approved ===
                                                                    "W") ||
                                                                (items.is_approved ===
                                                                  "W" &&
                                                                  items.pbmo_approved ===
                                                                    "Y") ||
                                                                (items.is_approved ===
                                                                  "W" &&
                                                                  items.pbmo_approved ===
                                                                    "W") ? (
                                                                <HelpIcon />
                                                              ) : items.is_approved ===
                                                                "N" ? (
                                                                <ThumbDownIcon />
                                                              ) : null
                                                            }
                                                            label={items.name}
                                                          /> */}
                                                          <Material.Chip
                                                            className={
                                                              items.is_approved ===
                                                              "Y"
                                                                ? // &&
                                                                  // items.pbmo_approved ===
                                                                  // "Y"
                                                                  classes.approved
                                                                : items.is_approved ===
                                                                  "W"
                                                                ? // ||
                                                                  // items.pbmo_approved ===
                                                                  // "W"
                                                                  classes.pending
                                                                : classes.rejected
                                                            }
                                                            size="small"
                                                            avatar={
                                                              items.is_approved ===
                                                              //  &&
                                                              //   items.pbmo_approved
                                                              "Y" ? (
                                                                <CheckCircleIcon />
                                                              ) : items.is_approved ===
                                                                // &&
                                                                //   items.pbmo_approved
                                                                "W" ? (
                                                                <HelpIcon />
                                                              ) : items.is_approved ===
                                                                "N" ? (
                                                                <ThumbDownIcon />
                                                              ) : null
                                                            }
                                                            label={items.name}
                                                          />
                                                        </Material.Badge>
                                                      </Material.Tooltip>
                                                    ) : items.bong_approved ===
                                                        "2" ||
                                                      items.bong_approved ===
                                                        "1" ? (
                                                      //   <Material.Tooltip
                                                      //   title={
                                                      //     items.is_approved ===
                                                      //       "Y" &&
                                                      //     items.pbmo_approved ===
                                                      //       "W"
                                                      //       ? "This item needs approval from PBMO"
                                                      //       : items.is_approved ===
                                                      //           "W" &&
                                                      //         items.pbmo_approved ===
                                                      //           "Y"
                                                      //       ? "This item needs approval from PPMU"
                                                      //       : items.is_approved ===
                                                      //           "W" &&
                                                      //         items.pbmo_approved ===
                                                      //           "W"
                                                      //       ? "This item needs approval from PPMU and PBMO"
                                                      //       : items.is_approved ===
                                                      //           "N" &&
                                                      //         (items.pbmo_approved ===
                                                      //           "W" ||
                                                      //           items.pbmo_approved ===
                                                      //             "Y")
                                                      //       ? "This item was rejected by PPMU"
                                                      //       : "Item approved by PPMU and PBMO"
                                                      //   }
                                                      // >
                                                      //   <Material.Chip
                                                      //   className={
                                                      //     items.is_approved ===
                                                      //     "N"
                                                      //       ? classes.rejected
                                                      //       : items.is_approved ===
                                                      //           "Y" &&
                                                      //         items.pbmo_approved ===
                                                      //           "Y"
                                                      //       ? classes.approved
                                                      //       : items.is_approved ===
                                                      //           "W" ||
                                                      //         items.pbmo_approved ===
                                                      //           "W"
                                                      //       ? classes.pending
                                                      //       : classes.rejected
                                                      //   }
                                                      //   size="small"
                                                      //   avatar={
                                                      //     items.is_approved ===
                                                      //       "Y" &&
                                                      //     items.pbmo_approved ===
                                                      //       "Y" ? (
                                                      //       <CheckCircleIcon />
                                                      //     ) : (items.is_approved ===
                                                      //         "Y" &&
                                                      //         items.pbmo_approved ===
                                                      //           "W") ||
                                                      //       (items.is_approved ===
                                                      //         "W" &&
                                                      //         items.pbmo_approved ===
                                                      //           "Y") ||
                                                      //       (items.is_approved ===
                                                      //         "W" &&
                                                      //         items.pbmo_approved ===
                                                      //           "W") ? (
                                                      //       <HelpIcon />
                                                      //     ) : items.is_approved ===
                                                      //       "N" ? (
                                                      //       <ThumbDownIcon />
                                                      //     ) : null
                                                      //   }
                                                      //   label={items.name}
                                                      // />
                                                      <Material.Chip
                                                        className={
                                                          items.is_approved ===
                                                          "Y"
                                                            ? // &&
                                                              // items.pbmo_approved ===
                                                              // "Y"
                                                              classes.approved
                                                            : items.is_approved ===
                                                              "W"
                                                            ? // ||
                                                              // items.pbmo_approved ===
                                                              // "W"
                                                              classes.pending
                                                            : classes.rejected
                                                        }
                                                        size="small"
                                                        avatar={
                                                          items.is_approved ===
                                                          // &&
                                                          //   items.pbmo_approved
                                                          "Y" ? (
                                                            <CheckCircleIcon />
                                                          ) : items.is_approved ===
                                                            // &&
                                                            //   items.pbmo_approved
                                                            "W" ? (
                                                            <HelpIcon />
                                                          ) : (
                                                            <ThumbDownIcon />
                                                          )
                                                        }
                                                        label={items.name}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </b>

                                                  {/* Badge for unforeseen */}
                                                  {this.state.is_unforeseen >
                                                    0 && (
                                                    <>
                                                      {items.is_unforeseen_is_approved ===
                                                        "0" && (
                                                        <Material.Tooltip title="This item needs approval.">
                                                          <Material.Badge
                                                            classes={{
                                                              badge:
                                                                classes.unforeseenBadge,
                                                            }}
                                                            variant="dot"
                                                            anchorOrigin={{
                                                              vertical: "top",
                                                              horizontal:
                                                                "left",
                                                            }}
                                                          />
                                                        </Material.Tooltip>
                                                      )}
                                                      {items.is_unforeseen_is_approved ===
                                                        "2" && (
                                                        <Material.Tooltip
                                                          title={
                                                            "Item is rejected!" +
                                                            (items.is_unforeseen_reject_remarks
                                                              ? " With remarks: " +
                                                                items.is_unforeseen_reject_remarks
                                                              : "")
                                                          }
                                                        >
                                                          <Material.Badge
                                                            classes={{
                                                              badge:
                                                                classes.unforeseenBadgeReject,
                                                            }}
                                                            variant="dot"
                                                            anchorOrigin={{
                                                              vertical: "top",
                                                              horizontal:
                                                                "left",
                                                            }}
                                                          />
                                                        </Material.Tooltip>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              </Material.Tooltip>{" "}
                                              -{" "}
                                              {items.remark ? (
                                                <b>{items.remark}</b>
                                              ) : (
                                                ""
                                              )}{" "}
                                              {items.description}
                                              {/* activity_name */}
                                              {items.prefered_desc ? (
                                                <b>- {items.prefered_desc}</b>
                                              ) : (
                                                ""
                                              )}
                                              <br />
                                              {items.catering_office_activity_id >
                                              0 ? (
                                                <Material.Chip
                                                  label={`Name of Activity: ${items.activity_name}`}
                                                  color="primary"
                                                  variant="outlined"
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </Material.TableCell>

                                            <Material.TableCell align="center">
                                              {items.qty}
                                            </Material.TableCell>
                                            <Material.TableCell align="center">
                                              {items.unit}
                                            </Material.TableCell>
                                            <Material.TableCell align="center">
                                              <Material.Chip
                                                className={
                                                  parseFloat(items.unit_cost) >
                                                  0
                                                    ? classes.approved
                                                    : classes.pending
                                                }
                                                size="small"
                                                avatar={
                                                  parseFloat(
                                                    items.unit_cost
                                                  ) === 0 ? (
                                                    <HelpIcon />
                                                  ) : (
                                                    <CheckCircleIcon />
                                                  )
                                                }
                                                label={this.formatNumber(
                                                  items.unit_cost
                                                )}
                                              />
                                            </Material.TableCell>
                                            <Material.TableCell align="center">
                                              {this.formatNumber(
                                                items.total_cost
                                              )}
                                            </Material.TableCell>
                                            <Material.TableCell align="center">
                                              {items.mode_of_procurement}
                                            </Material.TableCell>
                                            <Material.TableCell align="center">
                                              <Material.Chip
                                                className={
                                                  parseInt(items.user_checked) >
                                                  0
                                                    ? classes.approved
                                                    : classes.pending
                                                }
                                                size="small"
                                                avatar={
                                                  parseInt(
                                                    items.user_checked
                                                  ) === 0 ? (
                                                    <HelpIcon />
                                                  ) : (
                                                    <CheckCircleIcon />
                                                  )
                                                }
                                                label={
                                                  parseInt(items.user_checked) >
                                                  0
                                                    ? "Yes"
                                                    : "No"
                                                }
                                              />
                                            </Material.TableCell>
                                            <Material.TableCell
                                              align="center"
                                              className="lightgrey"
                                            >
                                              <div className="inline">
                                                {this.state.pow_id > 0 ? (
                                                  <Material.IconButton
                                                    disabled={isPreApproval  || (isForEdit && items.for_edit !== "1")}
                                                    className="animate"
                                                    color="primary"
                                                    onClick={() => {
                                                      this.setState({
                                                        ...this.state,
                                                        add_pow_visible: true,
                                                      });
                                                    }}
                                                  >
                                                    <EditIcon />
                                                  </Material.IconButton>
                                                ) : (
                                                  <Material.IconButton
                                                    disabled={isPreApproval || (isForEdit && items.for_edit !== "1")}
                                                    className="animate"
                                                    color="primary"
                                                    onClick={() => {
                                                      displaySupplies({
                                                        modalVisible: true,
                                                        suppliesData: items,
                                                        transactionData:
                                                          this.state
                                                            .transactionData,
                                                      });
                                                    }}
                                                  >
                                                    <EditIcon />
                                                  </Material.IconButton>
                                                )}

                                                <Material.IconButton
                                                  disabled={isPreApproval}
                                                  className="animate"
                                                  color="primary"
                                                  onClick={() => {
                                                    this.onHandleDelete(
                                                      true,
                                                      items
                                                    );
                                                  }}
                                                >
                                                  <DeleteIcon />
                                                </Material.IconButton>
                                              </div>
                                            </Material.TableCell>
                                          </Material.TableRow>
                                        );
                                      })}
                                    <Material.TableRow>
                                      <Material.TableCell
                                        colSpan={3}
                                      ></Material.TableCell>
                                      <Material.TableCell
                                        colSpan={3}
                                        align="right"
                                      >
                                        <b>SUBTOTAL</b>
                                      </Material.TableCell>
                                      <Material.TableCell
                                        colSpan={3}
                                        align="right"
                                      >
                                        <b>
                                          {this.formatNumber(
                                            respo_item.items
                                              .map((elt) =>
                                                !isNaN(elt.total_cost) &&
                                                elt.total_cost
                                                  .toString()
                                                  .indexOf(".") !== -1
                                                  ? parseFloat(elt.total_cost)
                                                  : 0
                                              )
                                              .reduce((a, b) => a + b)
                                              .toFixed(2)
                                          )}
                                        </b>
                                      </Material.TableCell>
                                    </Material.TableRow>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
                {ppmp.genPPMPDataSource.length === 0 ? (
                  <Material.TableRow>
                    <Material.TableCell colSpan={20} className="emptyCont">
                      <div className="empty">
                        <div className="emptyBorder">
                          No Supplies.
                          <Material.Link
                            href="#"
                            onClick={(e) => {
                              if (this.state.pow_id > 0) {
                                this.setState({
                                  ...this.state,
                                  add_pow_visible: true,
                                });
                              } else {
                                e.preventDefault();
                                // this.props.history.push("/ppmpsupplies");
                                this.props.history.push({
                                  pathname: "/ppmpsupplies",
                                  state: {
                                    ppmpId: this.state.ppmp_id,
                                  },
                                });
                              }
                            }}
                          >
                            {" "}
                            Add now
                          </Material.Link>
                        </div>
                      </div>
                    </Material.TableCell>
                  </Material.TableRow>
                ) : (
                  <Material.TableRow />
                )}
                <Material.TableRow className="yellow">
                  <Material.TableCell colSpan={3}></Material.TableCell>
                  <Material.TableCell colSpan={3} align="right">
                    <b>GRAND TOTAL</b>
                  </Material.TableCell>
                  <Material.TableCell colSpan={3} align="right">
                    <b>{totalBudget ? this.formatNumber(totalBudget) : 0}</b>
                  </Material.TableCell>
                </Material.TableRow>
              </Material.TableBody>
            </Material.Table>
          </Material.TableContainer>
          {ppmp.genPPMPDataSource.length > 0 ? (
            <React.Fragment>
              <Material.Button
                variant="contained"
                color={this.checkSignatories() ? "default" : "primary"}
                className="button-p"
                onClick={() => {
                  this.onSetSignatories();
                }}
                startIcon={<SupervisedUserCircleIcon />}
              >
                Settings & Signatories
              </Material.Button>
              &nbsp;&nbsp;
              {/* {this.state.preApproval ? (
                <Material.Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  startIcon={<SaveAltIcon />}
                  disabled={!this.checkSignatories() || isPreApproval}
                  onClick={() => {
                    this.onSendForApprovalClick();
                  }}
                >
                  {" "}
                  Send for Pre-Approval
                </Material.Button>
              ) : (
                <Material.Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  startIcon={<SaveAltIcon />}
                  disabled={
                    !this.checkSignatories() || isPreApproval || preRemoval
                  }
                  onClick={() => {
                    this.onSaveAndGenerateClick();
                  }}
                >
                  {" "}
                  Save and Generate PPMP
                </Material.Button>
              )} */}
              <Material.Button
                className={classes.button}
                variant="contained"
                color="primary"
                startIcon={<SaveAltIcon />}
                disabled={
                  !this.checkSignatories() || isPreApproval || preRemoval
                }
                onClick={() => {
                  this.onSaveAndGenerateClick();
                }}
              >
                {" "}
                Save and Generate PPMP
              </Material.Button>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          <Material.Dialog
            open={this.state.dialogVisible}
            onClose={() => {
              this.onDisplayDialog(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onRendered={this.onHandleEdit}
            editdata={editDataQty}
          >
            <Material.DialogTitle id="alert-dialog-title">
              <Material.Paper className={classes.dialogHeader}>
                <Material.Grid container spacing={3}>
                  <Material.Grid item xs={8}>
                    <div className="inline">
                      <Material.Typography
                        className="flex"
                        color="primary"
                        variant="h5"
                      >
                        {this.state.ItemName}
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {this.state.ItemDescription}
                      </Material.Typography>
                    </div>
                  </Material.Grid>
                  <Material.Grid item xs={4} align="right">
                    ₱ {this.formatNumber(this.state.UnitCost)}
                  </Material.Grid>
                </Material.Grid>
              </Material.Paper>
            </Material.DialogTitle>
            <Material.DialogContent>
              <Material.Grid container spacing={1}>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-jan"
                    onChange={this.onChangeHandler}
                    name="qtyJan"
                    label="January"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyJan || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-feb"
                    onChange={this.onChangeHandler}
                    name="qtyFeb"
                    label="February"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyFeb || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-mar"
                    onChange={this.onChangeHandler}
                    name="qtyMar"
                    label="March"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyMar || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-apr"
                    onChange={this.onChangeHandler}
                    name="qtyApr"
                    label="April"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyApr || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-may"
                    onChange={this.onChangeHandler}
                    name="qtyMay"
                    label="May"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyMay || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-jun"
                    onChange={this.onChangeHandler}
                    name="qtyJun"
                    label="June"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyJun || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-jul"
                    onChange={this.onChangeHandler}
                    name="qtyJul"
                    label="July"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyJul || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <TextField
                    className={classes.textField}
                    id="qty-aug"
                    onChange={this.onChangeHandler}
                    name="qtyAug"
                    label="August"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyAug || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-sep"
                    onChange={this.onChangeHandler}
                    name="qtySep"
                    label="September"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtySep || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty-oct"
                    onChange={this.onChangeHandler}
                    name="qtyOct"
                    label="October"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyOct || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty_nov"
                    onChange={this.onChangeHandler}
                    name="qtyNov"
                    label="November"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyNov || ""}
                  />
                </Material.Grid>
                <Material.Grid item xs={4}>
                  <Material.TextField
                    className={classes.textField}
                    id="qty_dec"
                    onChange={this.onChangeHandler}
                    name="qtyDec"
                    label="December"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.qtyDec || ""}
                  />
                </Material.Grid>
              </Material.Grid>
            </Material.DialogContent>
            <Material.DialogActions>
              <Material.Button
                onClick={() => {
                  this.onDisplayDialog(false);
                }}
                variant="contained"
                color="secondary"
              >
                {" "}
                Cancel{" "}
              </Material.Button>
              <Material.Button
                onClick={this.onSaveItemQuantity}
                variant="contained"
                color="primary"
                autoFocus
              >
                {" "}
                Save{" "}
              </Material.Button>{" "}
              <span />
            </Material.DialogActions>
          </Material.Dialog>
          <Material.Dialog
            open={deleteDialogVisible}
            onClose={() => this.onHandleDelete(false, {})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Material.DialogTitle id="alert-dialog-title" color="secondary">
              Delete {deteleItem.name}?
            </Material.DialogTitle>
            <Material.DialogContent>
              <Material.DialogContentText id="alert-dialog-description">
                Are you sure you want to delete {deteleItem.pr_qty}{" "}
                {deteleItem.unit} of {deteleItem.description}?
              </Material.DialogContentText>
            </Material.DialogContent>
            <Material.DialogActions>
              <Material.Button
                onClick={() => this.onHandleDelete(false, {})}
                color="secondary"
              >
                Cancel
              </Material.Button>
              <Material.Button
                onClick={() => this.onRemoveItem(deteleItem)}
                color="primary"
                autoFocus
              >
                Continue
              </Material.Button>
            </Material.DialogActions>
          </Material.Dialog>

          <Material.Dialog
            open={exceedDialogVisible}
            onClose={() => this.onHandleExceed(false, {})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Material.DialogTitle id="alert-dialog-title" color="secondary">
              Budget Exceeded
            </Material.DialogTitle>
            <Material.DialogContent>
              <Material.DialogContentText id="alert-dialog-description">
                Account Code: {exceedItem.account_code_description}
              </Material.DialogContentText>
              <Material.DialogContentText id="alert-dialog-description">
                Respo Code: {exceedItem.respo_code}
              </Material.DialogContentText>
              <Material.DialogContentText id="alert-dialog-description">
                Legal Basis: {exceedItem.legal_basis}
              </Material.DialogContentText>
              <Material.DialogContentText id="alert-dialog-description">
                PPA: {exceedItem.ppa_desc}
              </Material.DialogContentText>
              <Material.DialogContentText id="alert-dialog-description">
                Year: {exceedItem.ra_year}
              </Material.DialogContentText>
              <Material.DialogContentText id="alert-dialog-description">
                Alloted Budget: {exceedItem.budget_alloted_balance}
              </Material.DialogContentText>
              <Material.DialogContentText id="alert-dialog-description">
                Total Amount: {exceedItem.total_cart}
              </Material.DialogContentText>
            </Material.DialogContent>
            <Material.DialogActions>
              <Material.Button
                onClick={() => this.onHandleExceed(false, {})}
                color="secondary"
              >
                Cancel
              </Material.Button>
            </Material.DialogActions>
          </Material.Dialog>

          <ViewRequestedItemModal
            ViewReqItemsModalVisible={this.state.ViewReqItemsModalVisible}
            closeViewReqItemsModal={this.closeViewReqItemsModal}
            office_id={this.state.office_id}
            ppmpId={this.props.location?.state?.ppmpId}
          />
          <EditModal
            requestGeneratedPPMP={this.requestPPMP}
            isUnforeseen={this.state.is_unforeseen === "1"}
            ppmpParentId={this.state.parent_id}
            ppmpId={this.props.location?.state?.ppmpId}
          />
          <AddPOWModal
            modalVisible={this.state.add_pow_visible}
            onCloseModal={this.onCloseModalAddPOW}
            powId={this.state.pow_id}
            powTitle={this.state.pow_title}
            powDescription={this.state.pow_description}
            powLocation={this.state.pow_location}
            powProjectCost={this.state.pow_project_cost}
            powFundSource={this.state.pow_fund_source}
            refreshData={this.initFunctions}
            ppmp_id={this.state.ppmp_id}
            transactionData={this.state.transactionData}
            ppmpId={this.props.location?.state?.ppmpId}
          />
          <SignatoriesModal
            modalVisible={this.state.modalVisible}
            onCloseModal={this.onCloseSigModal}
            signatoriesData={this.props.ppmp.signatoriesDatasource}
            signatoriesFound={this.state.signatoriesFound}
          />
        </Material.Paper>
        {/* PPMP Dialog */}
        {/* <Material.Dialog
          open={ppmpEditModalVisible}
          onClose={() => {
            this.onDisplayPPMPEditDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogTitle id="alert-dialog-title">
            Edit PPMP?
          </Material.DialogTitle>
          <Material.DialogContent>
            <div>
              <Material.TextField
                id="ppmp_title"
                label="Title"
                type="text"
                onChange={(e) => {
                  this.setState({ ...this.state, ppmp_title: e.target.value });
                }}
                value={this.state.ppmp_title}
                className={classes.textField_input}
                margin="dense"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <Material.TextField
                id="division"
                label="Division/Section/Unit"
                type="text"
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    division_name: e.target.value,
                  });
                }}
                value={this.state.division_name}
                className={classes.textField_input}
                margin="dense"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <Material.FormControlLabel
                control={
                  <Material.Checkbox
                    checked={this.state.is_indicative > 0}
                    value={this.state.is_indicative_checked}
                    name="is_indicative_checked"
                    onChange={this.onCheckIndicativeHandlerCreate}
                  />
                }
                label="Is Indicative ?"
              />
            </div>
          </Material.DialogContent>
          <Material.DialogActions>
            <div>
              <Material.Button
                onClick={() => {
                  this.onDisplayPPMPEditDialog(false);
                }}
                color="secondary"
              >
                {" "}
                Cancel
              </Material.Button>
              <Material.Button
                // onClick={this.onClickAddTransactionButton}
                onClick={this.onDisplayPPMPVerifyPasswordDialog(true)}
                color="primary"
                autoFocus
              >
                Edit
              </Material.Button>
            </div>
          </Material.DialogActions>
        </Material.Dialog> */}

        {this.state.ppmpEditModalVisible && this.onEditPPMPDetails()}

        {/* Verify Password Dialog */}
        <Material.Dialog
          open={ppmpVerifyPasswordModalVisible}
          onClose={() => {
            this.onDisplayPPMPVerifyPasswordDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogTitle id="alert-dialog-title">
            Verify Password To Proceed
          </Material.DialogTitle>
          <Material.DialogContent>
            <Material.DialogContentText>
              To ensure the security of your account, please verify your
              password before proceeding. Please enter your password in the
              field below to continue:
            </Material.DialogContentText>
            <Material.TextField
              autoFocus
              margin="dense"
              id="password"
              name="password"
              label="Enter Password"
              type="password"
              fullWidth
              variant="standard"
              value={this.state.password}
              onChange={this.onChange}
            />
          </Material.DialogContent>
          <Material.DialogActions>
            <div>
              <Material.Button
                onClick={() => {
                  this.onDisplayPPMPVerifyPasswordDialog(false);
                }}
                color="secondary"
              >
                {" "}
                Cancel
              </Material.Button>
              <Material.Button
                onClick={this.onVerify}
                color="primary"
                autoFocus
              >
                Proceed
              </Material.Button>
            </div>
          </Material.DialogActions>
        </Material.Dialog>
        {/* Alert Dialog */}
        <Material.Dialog
          open={alertDialogVisible}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogTitle
            id="alert-dialog-title"
            style={{ color: "warning" }}
          >
            Warning!
          </Material.DialogTitle>
          <Material.DialogContent>
            <Material.DialogContentText id="alert-dialog-description">
              We're unable to proceed! Some items have not been checked or
              approved. Update and save each item to continue. In order to
              proceed, rejected items can be deleted but you must first review
              their remarks.
              <div style={{ paddingTop: 10 }}></div>
              <Material.Divider />
              <div style={{ paddingTop: 10 }}>
                These are the indicators that you need to check.
                <div style={{ paddingLeft: 10, paddingTop: 5 }}>
                  <Material.Badge
                    classes={{
                      badge: classes.customBadge,
                    }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    variant="dot"
                  >
                    <Material.Chip
                      className={classes.approved}
                      avatar={<CheckCircleIcon />}
                      label={"item name"}
                    />
                  </Material.Badge>
                  {"   "} - This indicates that your item is for approval.
                </div>
                <div style={{ paddingLeft: 10, paddingTop: 5 }}>
                  <Material.Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    color="secondary"
                    variant="dot"
                  >
                    <Material.Chip
                      className={classes.approved}
                      avatar={<CheckCircleIcon />}
                      label={"item name"}
                    />
                  </Material.Badge>
                  {"   "} - This indicates that your item is rejected.
                </div>
                <div style={{ paddingLeft: 10, paddingTop: 5 }}>
                  <Material.Badge color="primary" variant="dot">
                    <Material.Chip
                      className={classes.approved}
                      avatar={<CheckCircleIcon />}
                      label={"item name"}
                    />
                  </Material.Badge>
                  {"   "} - This indicates that your unforeseen item is for
                  approval.
                </div>
                <div style={{ paddingLeft: 10, paddingTop: 5 }}>
                  <Material.Badge
                    classes={{
                      badge: classes.unforeseenBadgeReject,
                    }}
                    variant="dot"
                  >
                    <Material.Chip
                      className={classes.approved}
                      avatar={<CheckCircleIcon />}
                      label={"item name"}
                    />
                  </Material.Badge>
                  {"   "} - This indicates that your unforeseen item is
                  rejected.
                </div>
                <div style={{ paddingLeft: 10, paddingTop: 5 }}>
                  <Material.Chip
                    className={classes.pending}
                    size="small"
                    avatar={<HelpIcon />}
                    label={"No"}
                  />
                  {"   "} - This indicates that you need to review/check your
                  item.
                </div>
                <div style={{ paddingLeft: 10, paddingTop: 5 }}>
                  <Material.Chip
                    className={classes.pending}
                    size="small"
                    avatar={<HelpIcon />}
                    label={"item name"}
                  />
                  {"   "} - This indicates that your requested item is waiting
                  for approval from PPMU.
                </div>
              </div>
            </Material.DialogContentText>
          </Material.DialogContent>

          <Material.DialogActions>
            <Material.Button
              onClick={() => {
                this.setState({
                  ...this.state,
                  approve: false,
                  ppmp_item_to_approve_id: 0,
                  remarks: "",
                });
                this.setState({ alertDialogVisible: false });
              }}
              color="secondary"
            >
              Cancel
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Material.withStyles(useStyles)(PPMPList));
