import React from "react";
import { connect } from "react-redux";

//Imported Material Components
import * as Material from "@material-ui/core";

//Imported Icons
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Loading from "../common/loading";
import { useStyles } from "./activities.style";
import * as ActivitiesService from "./activities.service";
import ActivityModal from "./activity.modal";
import { mapStateToProps, mapDispatchToProps } from "./activities.reducer";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@mui/icons-material/Info";
import ACTInfoModal from "./act.info.modal";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";

class Activities extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: "",
			keyword: "",
			total: 0,
			page_no: 0,
			limit: 10,
			sort_column: "",
			sort_type: "",
			filter_business_personality: "",
			filter_nationality: "",
			filter_nature_of_operation: "",
			modalVisible: false,
			drawerVisible: false,
			showModalInfo: false,
			chipData: [],

			// new state
			title: "",
			year: "",
			office_id: 0,
			office_name: "",
			activity_type_id: 0,
			activity_type_name: "",
			date_of_activity: "",
			participants: "",
			no_of_batches: 0,
			venue: "",
			type_of_catering_service: "",
			facilities_equipment_needed: "",
			other_requirements: "",
			accommodation: "",
			activities: [],
			year_range: [],
			deleteDialogVisible: false,
			activityToDel: {},
		};
		this.ontoggleInfoModal = this.ontoggleInfoModal.bind(this);
		this.onClickAddButton = this.onClickAddButton.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onEditActivity = this.onEditActivity.bind(this);
		this.ontoggleInfoListModal = this.ontoggleInfoListModal.bind(this);
		this.onSearchHandler = this.onSearchHandler.bind(this);
		this.requestGetOfficeActivities =
			this.requestGetOfficeActivities.bind(this);
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.onTextChangeHandler = this.onTextChangeHandler.bind(this);
		this.removeChipHandler = this.removeChipHandler.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.TablePaginationActions = this.TablePaginationActions.bind(this);
		this.generateChip = this.generateChip.bind(this);
		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.tableFilterContainer = this.tableFilterContainer.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
		this.onDeleteActivity = this.onDeleteActivity.bind(this);
		this.onReqDeleteActivity = this.onReqDeleteActivity.bind(this);
	}
	componentDidMount() {
		const { data } = this.props;
		let currDate = new Date();
		let currYear = parseInt(currDate.getFullYear());

		//get year +1 & -2
		let yearRange = [currYear - 2, currYear - 1, currYear, currYear + 1];

		this.setState(
			{
				...this.state,
				year: currYear,
				year_range: yearRange,
				office_id: parseInt(data.user.office_id),
			},
			() => {
				this.requestGetOfficeActivities();
			}
		);
	}
	ontoggleInfoListModal(value) {
		this.setState({
			...this.state,
			showModalInfo: value.open,
			displayACTData: value.data,
		});
	}
	requestGetOfficeActivities() {
		const { displayLoading, displayAlert } = this.props;
		displayLoading(true);
		ActivitiesService.getOfficeActivities({ ...this.state })
			.then((response) => {
				// console.log("$$response", response);
				if (!response.error) {
					this.setState(
						{
							...this.state,
							activities: response.data,
						},
						() => {
							// console.log("$$activities", response.data);
						}
					);
				} else {
					this.setState({
						...this.state,
						activities: [],
					});
				}
				displayLoading(false);
				this.generateChip();
			})
			.catch((err) => {
				displayLoading(false);
				this.generateChip();
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
			});
	}
	ontoggleInfoModal(value) {
		this.setState({
			...this.state,
			showModalInfo: value.open,
			displayActivityData: value.data,
		});
	}
	onEditActivity(value) {
		this.setState({
			...this.state,
			editActivityData: value,
			editMode: true,
			modalVisible: true,
		});
	}
	onClickAddButton() {
		this.setState({ ...this.state, modalVisible: true, editMode: false });
	}
	onCloseModal() {
		this.setState({ ...this.state, modalVisible: false, showModalInfo: false });
	}
	onDeleteActivity(row, visible) {
		this.setState({
			...this.state,
			activityToDel: row,
			deleteDialogVisible: visible,
		});
	}
	onReqDeleteActivity() {
		const { displayLoading, displayAlert } = this.props;
		displayLoading(true);
		// console.log('$$this.state.activityToDel', this.state.activityToDel);
		ActivitiesService.deleteActivity({
			activity_id: this.state.activityToDel.id,
		})
			.then((response) => {
				var res = response.data;
				this.onDeleteActivity({}, false);
				if (res.error) {
					displayAlert({ visible: true, message: res.msg, severity: "error" });
				} else {
					this.requestGetOfficeActivities();
					displayAlert({
						alertVisible: true,
						alertMessage: "Deleted Successfully",
						alertSeverity: "success",
					});
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				this.onDeleteActivity({}, false);
				displayAlert({ visible: true, message: err, severity: "error" });
			});
	}
	toggleDrawer(value) {
		this.setState({ ...this.state, drawerVisible: value });
	}
	onTextChangeHandler(e) {
		const node = e.target;
		this.setState({ ...this.state, [node.name]: node.value });
	}
	onSearchHandler(e) {
		e.preventDefault();
		this.setState(
			{ ...this.state, keyword: this.state.searchValue, page_no: 0 },
			() => {
				this.requestGetOfficeActivities();
			}
		);
	}
	onSelectHandler(e) {
		this.setState(
			{
				...this.state,
				year: e.target.value,
			},
			() => {
				this.requestGetOfficeActivities();
			}
		);
	}
	generateChip() {
		const {
			keyword,
			sort_column,
			sort_type,
			filter_business_personality,
			filter_nationality,
			filter_nature_of_operation,
		} = this.state;
		let data = [];
		keyword &&
			data.push({
				key: 1,
				label: keyword,
				icon: <SearchIcon />,
				stateVar: "keyword",
				stateVara: "searchValue",
			});
		if (sort_column) {
			var icon =
				sort_type === "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
			data.push({
				key: 2,
				label: sort_column,
				icon,
				stateVar: "sort_column",
				stateVara: "sort_type",
			});
		}
		filter_business_personality &&
			data.push({
				key: 3,
				label: filter_business_personality,
				icon: <FilterListIcon />,
				stateVar: "filter_business_personality",
				stateVara: "filter_business_personality",
			});
		filter_nationality &&
			data.push({
				key: 3,
				label: filter_nationality,
				icon: <FilterListIcon />,
				stateVar: "filter_nationality",
				stateVara: "filter_nationality",
			});
		filter_nature_of_operation &&
			data.push({
				key: 3,
				label: filter_nature_of_operation,
				icon: <FilterListIcon />,
				stateVar: "filter_nature_of_operation",
				stateVara: "filter_nature_of_operation",
			});
		this.setState({ ...this.state, chipData: data });
	}
	removeChipHandler(value) {
		this.setState(
			(state) => {
				return {
					...state,
					chipData: state.chipData.filter((chip) => chip.key !== value.key),
					[value.stateVar]: "",
					[value.stateVara]: "",
				};
			},
			() => {
				this.requestGetOfficeActivities();
			}
		);
	}
	handleRequestSort(event, property) {
		const { sort_column, sort_type } = this.state;
		const isAsc = sort_column === property && sort_type === "asc";
		this.setState(
			{
				...this.state,
				sort_column: property,
				sort_type: isAsc ? "desc" : "asc",
			},
			() => {
				this.requestGetOfficeActivities();
			}
		);
	}
	handleChangePage(event, value) {
		this.setState({ ...this.state, page_no: value }, () => {
			this.requestGetOfficeActivities();
		});
	}
	handleChangeRowsPerPage(e) {
		this.setState(
			{ ...this.state, page_no: 0, limit: parseInt(e.target.value, 10) },
			() => {
				this.requestGetOfficeActivities();
			}
		);
	}
	onFilterChange(e) {
		this.setState({ ...this.state, [e.target.name]: e.target.value });
	}
	tableFilterContainer() {
		const { classes, suppliers } = this.props; //to edit for future filter used
		const options = [
			{
				key: 1,
				label: "",
				value: this.state[``],
				target: "",
				handleChange: this.onFilterChange,
			},
			{
				key: 2,
				label: "",
				value: this.state[``],
				target: "",
				handleChange: this.onFilterChange,
			},
			{
				key: 3,
				label: "",
				value: this.state[``],
				target: "",
				handleChange: this.onFilterChange,
			},
		];
		return (
			<Material.Paper className={classes.drawer}>
				{options.map((option) => {
					return (
						<Material.FormControl
							component="fieldset"
							key={option.key}
							margin="normal"
							size="small"
							variant="outlined"
						>
							<Material.FormLabel filled>{option.label}</Material.FormLabel>
							<Material.Divider></Material.Divider>
							<Material.RadioGroup
								aria-label={option.target}
								name={`filter_${option.target}`}
								value={option.value}
								onChange={option.handleChange}
							>
								{suppliers.suppliersFilter
									.filter((obj) => obj._particular === option.target)
									.map((filter, index) => {
										return (
											<Material.FormControlLabel
												key={`${filter._value}-${index}`}
												value={filter._value}
												className="radio-choices"
												control={<Material.Radio size="small" />}
												label={filter._value}
											/>
										);
									})}
							</Material.RadioGroup>
						</Material.FormControl>
					);
				})}
				<Material.Button
					variant="contained"
					color="primary"
					size="small"
					className={classes.button}
					startIcon={<FilterListIcon />}
					onClick={() => this.requestGetOfficeActivities()}
				>
					Apply
				</Material.Button>
			</Material.Paper>
		);
	}
	TablePaginationActions(props) {
		const { classes } = this.props;
		const { count, page, rowsPerPage, onChangePage } = props;
		return (
			<div className={classes.tableFooter}>
				<Material.IconButton
					className="animate"
					onClick={(event) => {
						onChangePage(event, 0);
					}}
					disabled={page === 0}
					aria-label="first page"
				>
					<FirstPageIcon />
				</Material.IconButton>
				<Material.IconButton
					className="animate"
					onClick={(event) => {
						onChangePage(event, page - 1);
					}}
					disabled={page === 0}
					aria-label="previous page"
				>
					<KeyboardArrowLeft />
				</Material.IconButton>
				<Material.IconButton
					className="animate"
					onClick={(event) => {
						onChangePage(event, page + 1);
					}}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
				>
					<KeyboardArrowRight />
				</Material.IconButton>
				<Material.IconButton
					className="animate"
					onClick={(event) => {
						onChangePage(
							event,
							Math.max(0, Math.ceil(count / rowsPerPage) - 1)
						);
					}}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
				>
					<LastPageIcon />
				</Material.IconButton>
			</div>
		);
	}
	render() {
		const {
			drawerVisible,
			chipData,
			sort_column,
			sort_type,
			activityToDel,
			activities,
			year,
			year_range,
		} = this.state;
		const { classes, showSnackBar } = this.props; //TableHead
		const TableHeaderOp = [
			{
				id: "Title",
				numeric: false,
				disablePadding: false,
				label: "Title",
				field: "title",
				align: "left",
			},
+   			{
				id: "Participants",
				numeric: false,
				disablePadding: false,
				label: "Participants",
				align: "center",
				field: "participants",
			},
			{
				id: "Batch",
				numeric: false,
				disablePadding: false,
				label: "Batch(es)",
				align: "center",
				field: "no_of_batch",
			},
			{
				id: "Accomodation",
				numeric: false,
				disablePadding: false,
				label: "Accomodation",
				align: "center",
				field: "accomodation",
			},
			{
				id: "Type",
				numeric: false,
				disablePadding: true,
				label: "Type",
				align: "center",
				field: "type_of_catering_service",
			},
			{
				id: "Date",
				numeric: false,
				disablePadding: false,
				label: "Date",
				align: "center",
				field: "date_of_activity",
			},
		];
		return (
			<div className={classes.root}>
				<Material.Grid
					container
					spacing={2}
					direction="row"
					justify="center"
					alignItems="stretch"
				>
					{drawerVisible ? (
						<Material.Grid item xs={12} sm={3}>
							<Material.Slide
								direction="right"
								in={drawerVisible}
								mountOnEnter
								unmountOnExit
							>
								{this.tableFilterContainer()}
							</Material.Slide>
						</Material.Grid>
					) : (
						""
					)}
					<Material.Grid item xs={12} sm={drawerVisible ? 9 : 12}>
						<Material.Paper className={classes.paper}>
							<Material.Toolbar className={classes.toolbar}>
								<div className={classes.toolRight}>
									<Material.Button
										variant="contained"
										color="primary"
										size="small"
										onClick={this.onClickAddButton}
										className={classes.button}
										startIcon={<AddIcon />}
									>
										ADD NEW ACTIVITY
									</Material.Button>
									{/* <Material.Tooltip 
                                        title="Toggle Filter list">
                                        <Material.IconButton 
                                            className={classes.animate} 
                                            aria-label="filter list" 
                                            color="primary" 
                                            onClick={() => { this.toggleDrawer(!drawerVisible) }}>
                                            <FilterListIcon />
                                        </Material.IconButton>
                                    </Material.Tooltip> */}
								</div>
								{/* selectyear */}
								<form>
									<Material.FormControl
										variant="standard"
										className={classes.formControlMax}
									>
										<Material.InputLabel>Select Year</Material.InputLabel>
										<Material.Select
											inputProps={{
												name: "year",
												id: "",
											}}
											value={year}
											onChange={this.onSelectHandler}
										>
											<Material.MenuItem value={0}>
												<em>None</em>
											</Material.MenuItem>
											{year_range.map((val) => (
												<Material.MenuItem value={val}>
													{" "}
													{val}
												</Material.MenuItem>
											))}
										</Material.Select>
									</Material.FormControl>
								</form>
							</Material.Toolbar>
							<div className={classes.chipContainer}>
								{chipData.map((data, index) => (
									<Material.Chip
										color="primary"
										key={index}
										label={data.label}
										onDelete={() => this.removeChipHandler(data)}
										icon={data.icon}
									/>
								))}
							</div>

							<Material.TableContainer //start of the table; (per category)
								component={Material.Paper}
							>
								<Loading modalVisible={this.state.loadingVisible} />
								<Material.Table className={classes.table}>
									<Material.TableHead>
										{/* <Material.TableRow >
                                            <Material.TableCell colSpan={20} className="blue">
                                                <Material.Typography variant="h6"> <b>Activity Title</b></Material.Typography>
                                            </Material.TableCell>
                                        </Material.TableRow>
                                        <Material.TableRow>
                                            <Material.TableCell className="lightblue"> </Material.TableCell>
                                            <Material.TableCell colSpan={20} className="lightblue"><b>Source of Fund</b></Material.TableCell>
                                        </Material.TableRow> */}

										<Material.TableRow>
											{TableHeaderOp.map((option) => (
												<Material.TableCell
													key={option.id}
													align={option.align}
													padding={option.disablePadding ? "none" : "default"}
													sortDirection={
														sort_column === option.id ? sort_type : false
													}
												>
													<Material.TableSortLabel
														active={sort_column === option.id}
														direction={
															sort_column === option.id ? sort_type : "asc"
														}
														onClick={(event) => {
															this.handleRequestSort(event, option.field);
														}}
													>
														{option.label}
														{sort_column === option.id ? (
															<span className={classes.visuallyHidden}>
																{sort_type === "desc"
																	? "sorted descending"
																	: "sorted ascending"}
															</span>
														) : null}
													</Material.TableSortLabel>
												</Material.TableCell>
											))}
											<Material.TableCell align="center">
												{" "}
												Actions
											</Material.TableCell>
										</Material.TableRow>
									</Material.TableHead>
									{activities.length === 0 ? (
										<Material.TableRow>
											<Material.TableCell colSpan={20} className="emptyCont">
												<div className="empty">
													<div className="emptyBorder">
														No Activity.
														<Material.Link
															href="#"
															onClick={this.onClickAddButton}
														>
															{" "}
															Add now
														</Material.Link>
													</div>
												</div>
											</Material.TableCell>
										</Material.TableRow>
									) : (
										<Material.TableRow />
									)}
									<Material.TableBody>
										{activities.map((row) => (
											<Material.TableRow
												key={row.id}
												className={classes.tableRow}
											>
												<Material.TableCell align="left">
													{/* {row.title} */}
													<Material.Chip
														className={
															parseInt(row.is_submitted) > 0
																? classes.approved
																: classes.pending
														}
														size="small"
														avatar={
															parseInt(row.is_submitted) === 0 ? (
																<ErrorIcon />
															) : (
																<CheckCircleIcon />
															)
														}
														label={row.title}
													/>
												</Material.TableCell>
												<Material.TableCell align="center">
													{row.participants}
												</Material.TableCell>
												<Material.TableCell align="center">
													{row.no_of_batches}
												</Material.TableCell>
												<Material.TableCell align="center">
													{row.accommodation}
												</Material.TableCell>
												<Material.TableCell align="center">
													{row.type_of_catering_service}
												</Material.TableCell>
												<Material.TableCell align="center">
													{new Date(
														row.date_of_activity_from
													).toLocaleDateString()}{" "}
													-{" "}
													{new Date(
														row.date_of_activity_to
													).toLocaleDateString()}
												</Material.TableCell>
												{/* actionButtons */}
												<Material.TableCell
													align="center"
													className="lightblue"
												>
													{/* <div>
                            <Material.Tooltip title="Edit">
                              <Material.IconButton
                                color="primary"
                                onClick={() => {
                                  this.onEditActivity(row);
                                }}
                                disabled={parseInt(row.is_submitted) > 0}
                              >
                                <EditIcon />
                              </Material.IconButton>
                            </Material.Tooltip>
                            <Material.Tooltip title="Delete">
                              <Material.IconButton
                                className={classes.actionList}
                                color="primary"
                                onClick={() => {
                                  this.onDeleteActivity(row, true);
                                }}
                                disabled={parseInt(row.is_submitted) > 0}
                              >
                                <DeleteIcon />
                              </Material.IconButton>
                            </Material.Tooltip>
                            <Material.Tooltip title="Info & Item List">
                              <Material.IconButton
                                className="animate"
                                aria-label="info"
                                color="primary"
                                onClick={() => {
                                  this.ontoggleInfoListModal({
                                    open: true,
                                    data: row,
                                  });
                                }}
                              >
                                <InfoIcon />
                              </Material.IconButton>
                            </Material.Tooltip>
                          </div> */}
													<Material.IconButton
														className="animate row"
														onClick={(event) => {
															this.setState({
																...this.state,
																[`anchorEl-${row.id}`]: event.currentTarget,
															});
														}}
													>
														<DoubleArrowRoundedIcon />
													</Material.IconButton>
													<Material.Popover
														id="mouse-over-popover"
														open={Boolean(this.state[`anchorEl-${row.id}`])}
														anchorEl={this.state[`anchorEl-${row.id}`]}
														onClick={() => {
															this.setState({ [`anchorEl-${row.id}`]: null });
														}}
														classes={{ paper: classes.paperless_ref }}
														anchorOrigin={{
															vertical: "bottom",
															horizontal: "right",
														}}
														transformOrigin={{
															vertical: "bottom",
															horizontal: "right",
														}}
													>
														<Material.Tooltip title="Info & Item List">
															<Material.Fab
																color="primary"
																className={classes.actionList}
																size="small"
																onClick={() => {
																	this.ontoggleInfoListModal({
																		open: true,
																		data: row,
																	});
																}}
															>
																<InfoIcon />
															</Material.Fab>
														</Material.Tooltip>
														<Material.Tooltip title="Edit">
															<Material.Fab
																color="primary"
																className={classes.actionList}
																size="small"
																onClick={() => {
																	this.onEditActivity(row);
																}}
																disabled={parseInt(row.is_submitted) > 0}
															>
																<EditIcon />
															</Material.Fab>
														</Material.Tooltip>
														<Material.Tooltip title="Delete">
															<Material.Fab
																color="primary"
																className={classes.actionList}
																size="small"
																onClick={() => {
																	this.onDeleteActivity(row, true);
																}}
																disabled={parseInt(row.is_submitted) > 0}
															>
																<DeleteIcon />
															</Material.Fab>
														</Material.Tooltip>
													</Material.Popover>
												</Material.TableCell>
											</Material.TableRow>
										))}
									</Material.TableBody>
									<Material.TableFooter>
										{/* <Material.TableRow>
                                            <Material.TablePagination
                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                count={suppliers.suppliersTotal} rowsPerPage={limit} page={page_no}
                                                SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                ActionsComponent={this.TablePaginationActions}
                                            />
                                        </Material.TableRow> */}
									</Material.TableFooter>
								</Material.Table>
							</Material.TableContainer>

							{/* deleteDialog */}
							<Material.Dialog
								open={this.state.deleteDialogVisible}
								onClose={() => {
									this.onDeleteActivity({}, false);
								}}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
							>
								<Material.DialogTitle id="alert-dialog-title">
									Delete {activityToDel.title} Catering Office Activity?
								</Material.DialogTitle>
								<Material.DialogContent>
									<Material.DialogContentText id="alert-dialog-description">
										Are you sure you want to delete {activityToDel.title} (
										{activityToDel.year})?
									</Material.DialogContentText>
								</Material.DialogContent>
								<Material.DialogActions>
									<div>
										<Material.Button
											onClick={() => {
												this.onDeleteActivity({}, false);
											}}
											color="secondary"
										>
											Cancel
										</Material.Button>
										<Material.Button
											onClick={this.onReqDeleteActivity}
											color="primary"
											autoFocus
										>
											Continue
										</Material.Button>
									</div>
								</Material.DialogActions>
							</Material.Dialog>
							<ACTInfoModal
								modalVisible={this.state.showModalInfo}
								data={this.state.displayACTData}
								dataHandler={this.ontoggleInfoListModal}
							/>
							<ActivityModal //for calling add and edit reusable modal
								modalVisible={this.state.modalVisible}
								showSnackBar={showSnackBar}
								displayLoading={this.displayLoading}
								onCloseModal={this.onCloseModal}
								editMode={this.state.editMode}
								requestGetOfficeActivities={this.requestGetOfficeActivities}
								editActivityData={this.state.editActivityData}
							/>
						</Material.Paper>
					</Material.Grid>
				</Material.Grid>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Material.withStyles(useStyles)(Activities));
