import React from 'react';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';

import * as Material from '@material-ui/core';
import Button from '@material-ui/core/Button';

import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import Logout from '../auth/logout';

import { useStyles } from '../user.account/user.account.style';
import * as UsersService from '../admin/users/users.service';
import { mapStateToProps, mapDispatchToProps } from '../admin/users/users.reducer';

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            pwd_current:"",
            pwd_new:"",
            pwd_verify:"",
            dg_user: "",
            logout: false,
            visible: false,
            showPasswordA: false,
            showPasswordB: false,
            showPasswordC: false,
        };
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.requestEditUserService = this.requestEditUserService.bind(this);
        this.requestGetUserProfile = this.requestGetUserProfile.bind(this);
        this.logOut = this.logOut.bind(this);
    }
    componentDidMount(){
        this.setState({ id: parseInt(this.props.data.user.id), dg_user: this.props.data.user.is_dg},()=>{
            this.requestGetUserProfile();
        });
    }
    requestGetUserProfile(){
        UsersService.getUserAccount({ ...this.state }).then((response)=>{
            this.props.setUserProfileDataSource({userProfileDatasource: response.data.result[0]})
        }).catch((err)=>{
            console.error(err)
        });
    }
    onChangeHandler (e) {
        this.setState({ [e.target.name]: e.target.value});
    }
    logOut(){
        sessionService.deleteSession().then(()=>{
           sessionService.deleteUser().then(()=>{
              this.props.removeCredential();
              this.props.history.push('/');
           });
        });
    }
    requestEditUserService () {

        const{ pwd_current, pwd_new, pwd_verify } = this.state;
        const {displayLoading, displayAlert} = this.props;

        const errorHander = {};
        errorHander.pwd_currentError = !pwd_current && true;
        errorHander.pwd_newError = !pwd_new && true;
        errorHander.pwd_verifyError = !pwd_verify && true;

        
      
        this.setState({...this.state,...errorHander });
        const{pwd_currentError, pwd_newError, pwd_verifyError} = errorHander;
        if ( !pwd_currentError && !pwd_newError &&  !pwd_verifyError) {
            if(pwd_new.localeCompare(pwd_verify) !== 0){
                displayAlert({ visible: true, message: "Please verify your new password correctly.", severity: "warning" });
            }
            else{
                this.props.displayLoading(true);
                UsersService.editUserAccountPass({...this.state}).then((res)=>{
                    if(res.error){
                        displayAlert({ visible: true, message: res.message, severity: "error" });
                    } else {
                        displayAlert({ visible: true, message: `${res.message} You will be logged out.`, severity: "success" });
                        this.setState({...this.state, logout: true})
                    }
                    displayLoading(false);
                }).catch((err)=>{
                    displayAlert({ visible: true, message: err, severity: "warning" });
                    displayLoading(false);
                });
            }
        }
    }
    render() {
        const { classes } = this.props;
        const { showPasswordA, showPasswordB, showPasswordC } = this.state;
        return (
            <div className={classes.paper}>
                <div className={classes.FormContainer}>
                    <Material.Grid container spacing={0}>
                        <Material.Grid item xs={7}>
                            <div className="padded">
                                Add Profile Here
                            </div>
                        </Material.Grid>
                        <Material.Divider orientation="vertical" flexItem />
                        <Material.Grid item xs={4}>
                            <div className="padded">
                                <Material.Typography color="primary" variant="h5"><LockOpenTwoToneIcon/>&nbsp;&nbsp;Update Password</Material.Typography>
                                <Material.Divider></Material.Divider>
                                <br></br>
                                    <form className={classes.forthreeline} noValidate autoComplete="off">
                                        <Material.TextField id="pwd_current" name="pwd_current" error={this.state.pwd_currentError}
                                        label="Old Password" onChange={this.onChangeHandler} value={this.state.pwd_current || ""}
                                        type={showPasswordA ? 'text' : 'password'} variant="outlined" InputProps={{
                                            endAdornment:
                                                <Material.InputAdornment position="end">
                                                    <Material.IconButton aria-label="toggle password visibility"
                                                        onClick={()=>{this.setState({...this.state, showPasswordA: !showPasswordA })}}
                                                        onMouseDown={(event)=>{event.preventDefault()}} >
                                                        {showPasswordA ? <Visibility /> : <VisibilityOff />}
                                                    </Material.IconButton>
                                                </Material.InputAdornment>
                                        }}/>
                                    </form>
                                    <form className={classes.forthreeline} noValidate autoComplete="off">
                                        <Material.TextField id="pwd_new" name="pwd_new" error={this.state.pwd_newError}
                                        label="New Password" onChange={this.onChangeHandler} value={this.state.pwd_new || ""}
                                        type={showPasswordB ? 'text' : 'password'} variant="outlined" InputProps={{
                                            endAdornment:
                                                <Material.InputAdornment position="end">
                                                    <Material.IconButton aria-label="toggle password visibility"
                                                        onClick={()=>{this.setState({...this.state, showPasswordB: !showPasswordB })}}
                                                        onMouseDown={(event)=>{event.preventDefault()}} >
                                                        {showPasswordB ? <Visibility /> : <VisibilityOff />}
                                                    </Material.IconButton>
                                                </Material.InputAdornment>
                                        }}/>
                                    </form>
                                    <form className={classes.forthreeline} noValidate autoComplete="off">
                                        <Material.TextField id="pwd_verify" name="pwd_verify" error={this.state.pwd_verifyError}
                                        label="Verify new password" onChange={this.onChangeHandler} value={this.state.pwd_verify || ""}
                                        type={showPasswordC ? 'text' : 'password'} variant="outlined" InputProps={{
                                            endAdornment:
                                                <Material.InputAdornment position="end">
                                                    <Material.IconButton aria-label="toggle password visibility"
                                                        onClick={()=>{this.setState({...this.state, showPasswordC: !showPasswordC })}}
                                                        onMouseDown={(event)=>{event.preventDefault()}} >
                                                        {showPasswordC ? <Visibility /> : <VisibilityOff />}
                                                    </Material.IconButton>
                                                </Material.InputAdornment>
                                        }}/>
                                    </form>
                                    <form className={classes} noValidate autoComplete="off">
                                    <Button className={classes.action} variant="contained" color="primary" onClick={()=>{this.requestEditUserService()}}>Update Password</Button>
                                    </form>
                                    <br></br>
                                    {this.state.logout ? <Logout history={this.props.history}/> : <React.Fragment />}
                                </div>
                        </Material.Grid>
                    </Material.Grid>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(UserProfile));