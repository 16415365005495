import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";

import * as Material from "@material-ui/core";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import Paper from "@material-ui/core/Paper";

import { useStyles } from "./ppmp.items.list.per_office.styles";
import { mapStateToProps, mapDispatchToProps } from "../ppmp/ppmp.reducer";
import { COMM } from "../../helpers/common";
import * as PPMPService from "../ppmp/ppmp.service";
import * as PPMPItemsApproval from "./ppmp.items.approval.service";

class ItemListForApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      allotment_class: {},

      ppmp_id: 0,
      itemCost: 0,

      Qty: 0,

      ItemName: "",
      ItemDescription: "",
      UnitCost: 0,
      id: 0,
      ppmpItemId: 0,
      office_id: 0,
      ppmp_title: "",
      status: "Generated",
      division_name: "",
      is_indicative: 0,

      pow_id: 0,
      pow_title: "",
      pow_description: "",
      pow_location: "",
      pow_project_cost: 0,
      pow_fund_source: "",
      add_pow_visible: false,
      pow_file_link: "",
      uploadFileLink: "",
      is_indicative_checked: false,

      confirmationDialogVisible: false,
      modifyDialogVisible: false,
      proc: "",
      approve: false,
      item_to_approve_id: 0,
      name: "",
      description: "",
      remarks: "",
    };
    this.requestPPMP = this.requestPPMP.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.getPOWId = this.getPOWId.bind(this);
    this.initFunctions = this.initFunctions.bind(this);
    this.onHandleConfirm = this.onHandleConfirm.bind(this);
    this.setPPMPItemStatus = this.setPPMPItemStatus.bind(this);
    this.handleRemarksChange = this.handleRemarksChange.bind(this);
    this.handleModifyItemDescription =
      this.handleModifyItemDescription.bind(this);
    this.handleModifyItemName = this.handleModifyItemName.bind(this);
  }
  componentDidMount() {
    const { history } = this.props;
    sessionService
      .loadSession()
      .then((currentSession) => {
        if (
          currentSession.selectedTransaction === null ||
          currentSession.selectedTransaction === undefined
        ) {
          history.push("/");
        } else {
          const data = currentSession.selectedTransaction;
          this.setState(
            {
              ...this.state,
              office_id: this.props.data.user.office_id,
              transactionID: data.id,
              transactionNo: data.ppmp_no,
              transactionStatus: data.status,
              transactionDate: data.transdate,
              transactionYear: data.year,
              transactionDivision: data.division_name,
              transactionTitle: data.ppmp_title,
              ppmp_title: data.ppmp_title,
              division_name: data.division_name,
              ppmp_id: data.id,
              is_indicative: data.is_indicative,
              transactionData: data,
              username: this.props.data.user.username,
              proc: this.props.data.user.is_dg,
            },
            () => {
              this.initFunctions();
            }
          );
        }
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "warning",
        });
      });
  }
  onHandleConfirm(visible, row) {
    this.setState({
      ...this.state,
      confirmationDialogVisible: visible,
      //   deteleItem: row,
    });
  }

  onHandleModify(visible, row) {
    this.setState({
      modifyDialogVisible: visible,
      item_to_approve_id: row.ppmp_item_id,
      name: row.name,
      description: row.description,
      status_code: 0, // Set status_code to 0 for approval when modifying
    });
  }

  handleModifyItemName = (e) => {
    this.setState({
      description: this.state.description,
      name: e.target.value,
      status_code: 0, // Set status_code to 0 for approval when modifying
    });
  };

  handleModifyItemDescription = (e) => {
    this.setState({
      name: this.state.name,
      description: e.target.value,
      status_code: 0, // Set status_code to 0 for approval when modifying
    });
  };

  setPPMPItemStatus() {
    const { displayLoading, displayAlert } = this.props;
    const { item_to_approve_id, approve, remarks, name, description } =
      this.state;

    displayLoading(true);

    let final_status_code = 0; // Default: modification pending approval

    // If approve is explicitly true, it's an approval action
    if (approve === true) {
      final_status_code = 2; // Approved
    }
    // If approve is explicitly false, it's a rejection action
    else if (approve === false) {
      if (remarks.trim() === "") {
        displayAlert({
          visible: true,
          message: "Remarks cannot be empty for rejection.",
          severity: "warning",
        });
        displayLoading(false);
        return;
      }
      final_status_code = 3; // Rejected
    }

    // API call payload
    const payload = {
      ppmp_item_id: item_to_approve_id,
      status_code: final_status_code,
      remarks: final_status_code === 3 ? remarks : "", // Only send remarks if rejecting
      name,
      description,
    };

    PPMPItemsApproval.setItemStatus(payload)
      .then((res) => {
        if (res.error) {
          displayAlert({
            visible: true,
            message: res.error.message,
            severity: "warning",
          });
        } else {
          const successMessage =
            final_status_code === 2
              ? "Item approved!"
              : final_status_code === 3
              ? "Item rejected!"
              : "Item modified successfully and pending approval!";

          displayAlert({
            visible: true,
            message: successMessage,
            severity: "success",
          });

          displayLoading(false);
          this.setState({
            confirmationDialogVisible: false,
            modifyDialogVisible: false,
            remarks: "", // Reset remarks after action
            name: "",
            description: "",
          });

          // Refresh data to ensure UI is updated with the latest data
          this.initFunctions();
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          visible: true,
          message: err,
          severity: "error",
        });
      });
  }

  handleRemarksChange = (e) => {
    this.setState({
      ...this.state,
      remarks: e.target.value,
    });
  };

  initFunctions() {
    this.requestPPMP();
    this.getPOWId(this.state.ppmp_id);
  }
  getPOWId(ppmp_id) {
    PPMPService.getDetails({ id: ppmp_id })
      .then((response) => {
        this.setState(
          {
            ...this.state,
            pow_id: !response.error ? response.data[0].pow_id : 0,
            pow_title: !response.error ? response.data[0].title : "",
            pow_description: !response.error
              ? response.data[0].description
              : "",
            pow_location: !response.error ? response.data[0].location : "",
            pow_project_cost: !response.error
              ? response.data[0]._project_cost
              : 0,
            pow_fund_source: !response.error
              ? response.data[0].fund_source
              : "",
            pow_file_link: !response.error ? response.data[0]._file_link : "",
            uploadFileLink: response.data[0]._file_link,
          },
          () => {}
        );
      })
      .catch((err) => {
        // console.log("$$err", err);
      });
  }
  requestPPMP() {
    const { displayLoading, displayAlert, setGenPPMPDataSource } = this.props;
    displayLoading(true);
    PPMPService.getGeneratedPPMP({ ppmp_id: this.state.ppmp_id })
      .then((response) => {
        if (response.error) {
          displayAlert({
            visible: true,
            message: response.msg,
            severity: "warning",
          });
        } else {
          this.setState({
            ...this.state,
            preApproval: parseInt(response.data.result1.needs_validation) > 0,
            preRemoval: parseInt(response.data.result2.needs_removal) > 0,
          });
          setGenPPMPDataSource({ genPPMPDataSource: response.data.result0 });
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({ visible: true, message: err, severity: "error" });
      });
  }
  formatNumber(num) {
    return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";
  }
  render() {
    const { filtered_status, for_approval } = this.props.location.state;
    const {
      transactionNo,
      confirmationDialogVisible,
      modifyDialogVisible,
      transactionStatus,
      transactionTitle,
      transactionDate,
      transactionYear,
      transactionDivision,
      isPreApproval,
      isIndicative,
    } = this.state;
    const { classes, ppmp } = this.props;
    return (
      <div className={classes.root}>
        <div className="inline">
          <Material.Breadcrumbs aria-label="breadcrumb">
            <Material.Link
              underline="hover"
              color="inherit"
              href="/itemsforapproval"
            >
              PPMP PER OFFICE
            </Material.Link>
            <Material.Typography color="text.primary">
              ITEM LIST
            </Material.Typography>
          </Material.Breadcrumbs>
        </div>
        <Material.Paper className={classes.transactionHeader}>
          <Material.Grid container spacing={3}>
            <Material.Grid item xs={9}>
              <div className="inline">
                <div className={classes.statusColor}>
                  <Material.Chip
                    className={transactionStatus}
                    label={COMM.capitalizeFirstLetter(transactionStatus)}
                  />
                </div>
                <Material.Typography
                  className="flex"
                  color="primary"
                  variant="h5"
                >
                  {transactionNo}
                </Material.Typography>
                <div>
                  {this.state.pow_id > 0 ? (
                    <Material.Chip
                      label="INFRA"
                      color="secondary"
                      // variant="outlined"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <Material.Typography variant="h6">
                  ({transactionYear}) {transactionTitle}
                </Material.Typography>
                {/* <Material.Typography variant="h6" color="secondary" sx={{fontWeight: "bold"}}>{this.state.is_reimbursement > 0 ? "Reimbursement" : ""}</Material.Typography> */}
                <Material.Typography
                  variant="h6"
                  color="secondary"
                  sx={{ fontWeight: "bold" }}
                ></Material.Typography>
              </div>
            </Material.Grid>
            <Material.Grid item xs={3}>
              <Material.Typography variant="subtitle2" className="pull-right">
                {isIndicative > 0 ? (
                  <Material.Chip
                    label="INDICATIVE"
                    variant="outlined"
                    size="small"
                    style={{ borderColor: "#ff9800", color: "#ff9800" }}
                  />
                ) : (
                  ""
                )}{" "}
                {COMM.DdMmmYyyyHhMm(transactionDate)}
              </Material.Typography>
            </Material.Grid>
          </Material.Grid>
          <Material.Divider className="div-margin" />
          <Material.Grid container spacing={3}>
            <Material.Grid item xs={9}>
              <Material.Typography variant="caption">
                PPMP Transaction No
              </Material.Typography>
              <Material.Typography variant="subtitle2">
                {" "}
                Division: {transactionDivision}
              </Material.Typography>
            </Material.Grid>
          </Material.Grid>
        </Material.Paper>
        <Material.Paper className="container" elevation={3}>
          <Material.Divider></Material.Divider>

          <Material.TableContainer component={Paper}>
            <Material.Table
              className={classes.table}
              aria-label="generated ppmp table"
            >
              <Material.TableBody>
                <Material.TableRow>
                  <Material.TableCell></Material.TableCell>
                  <Material.TableCell align="center">
                    <b>Item Name</b>
                  </Material.TableCell>
                  <Material.TableCell align="center">
                    <b>Quantity</b>
                  </Material.TableCell>
                  <Material.TableCell align="center">
                    <b>Unit</b>
                  </Material.TableCell>
                  <Material.TableCell align="center">
                    <b>Unit Cost</b>
                  </Material.TableCell>
                  <Material.TableCell align="center">
                    <b>Total Cost</b>
                  </Material.TableCell>
                  <Material.TableCell align="center">
                    <b>Mode of Procurement</b>
                  </Material.TableCell>
                  <Material.TableCell align="center">
                    <b>Action</b>
                  </Material.TableCell>
                  {filtered_status !== 3 ? (
                    ""
                  ) : (
                    <Material.TableCell align="center">
                      <b>Remarks</b>
                    </Material.TableCell>
                  )}
                </Material.TableRow>
                {ppmp.genPPMPDataSource.map((element, index) => (
                  <React.Fragment key={`class-${index}`}>
                    {element.funds.map((fund_item, fund_index) => (
                      <React.Fragment key={`fund-${fund_index}`}>
                        {fund_item.ppa_list.map((respo_item, respo_index) => (
                          <React.Fragment key={`respo-item-${respo_index}`}>
                            {respo_item.items
                              .slice()
                              .sort((a, b) => {
                                const nameA = a.name
                                  ? a.name.toLowerCase()
                                  : "";
                                const nameB = b.name
                                  ? b.name.toLowerCase()
                                  : "";
                                return nameA.localeCompare(nameB);
                              })
                              .map((item, items_index) => {
                                const isApproved = item.is_approved === "Y";
                                const isPending = item.is_approved === "W";
                                const isRejected = !isApproved && !isPending;
                                const hasBongApproved =
                                  item.bong_approved === "2";
                                const isApprovedStatusToShow =
                                  parseInt(item.bong_approved) ===
                                  filtered_status;

                                if (isApprovedStatusToShow) {
                                  return (
                                    <Material.TableRow
                                      key={`items-data-sfsd-${items_index}`}
                                      className={classes.tableRow}
                                    >
                                      <Material.TableCell></Material.TableCell>
                                      <Material.TableCell
                                        align="left"
                                        sx={{ width: "5px" }}
                                      >
                                        <Material.Tooltip
                                          title={
                                            isRejected
                                              ? item.reject_remarks
                                              : ""
                                          }
                                          arrow
                                          placement="top"
                                        >
                                          <b>
                                            <Material.Chip
                                              className={
                                                isApproved
                                                  ? classes.approved
                                                  : isPending
                                                  ? classes.pending
                                                  : classes.rejected
                                              }
                                              size="small"
                                              avatar={
                                                isApproved ? (
                                                  <CheckCircleIcon />
                                                ) : isPending ? (
                                                  <HelpIcon />
                                                ) : (
                                                  <ThumbDownIcon />
                                                )
                                              }
                                              label={item.name}
                                            />
                                          </b>
                                        </Material.Tooltip>{" "}
                                        -{" "}
                                        {item.remark ? (
                                          <b>{item.remark}</b>
                                        ) : (
                                          ""
                                        )}{" "}
                                        {item.description}
                                        {item.prefered_desc && (
                                          <b>- {item.prefered_desc}</b>
                                        )}
                                        <br />
                                        {item.catering_office_activity_id >
                                          0 && (
                                          <Material.Chip
                                            label={`Name of Activity: ${item.activity_name}`}
                                            color="primary"
                                            variant="outlined"
                                          />
                                        )}
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        {item.qty}
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        {item.unit}
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        <Material.Chip
                                          className={
                                            parseFloat(item.unit_cost) > 0
                                              ? classes.approved
                                              : classes.pending
                                          }
                                          size="small"
                                          avatar={
                                            parseFloat(item.unit_cost) === 0 ? (
                                              <HelpIcon />
                                            ) : (
                                              <CheckCircleIcon />
                                            )
                                          }
                                          label={this.formatNumber(
                                            item.unit_cost
                                          )}
                                        />
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        {this.formatNumber(item.total_cost)}
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        {item.mode_of_procurement}
                                      </Material.TableCell>
                                      <Material.TableCell align="center">
                                        {for_approval ? (
                                          <div className="inline">
                                            <Material.Tooltip title="Approve Item">
                                              <Material.IconButton
                                                className="animate"
                                                color="primary"
                                                onClick={() => {
                                                  this.setState(
                                                    (prevState) => ({
                                                      ...prevState,
                                                      approve: true,
                                                      item_to_approve_id:
                                                        item.ppmp_item_id,

                                                      name: item.name,
                                                      description:
                                                        item.description,
                                                    }),
                                                    () => {
                                                      this.onHandleConfirm(
                                                        true,
                                                        item.id
                                                      );
                                                    }
                                                  );
                                                }}
                                              >
                                                <ThumbUpIcon />
                                              </Material.IconButton>
                                            </Material.Tooltip>
                                            <Material.Tooltip title="Reject Item">
                                              <Material.IconButton
                                                className="animate"
                                                color="primary"
                                                onClick={() => {
                                                  if (
                                                    item.name &&
                                                    item.description
                                                  ) {
                                                    this.setState(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        approve: false,
                                                        item_to_approve_id:
                                                          item.ppmp_item_id,
                                                        name: item.name,
                                                        description:
                                                          item.description,
                                                      }),
                                                      () => {
                                                        this.onHandleConfirm(
                                                          true
                                                        );
                                                      }
                                                    );
                                                  } else {
                                                    alert(
                                                      "Name and description cannot be empty."
                                                    );
                                                  }
                                                }}
                                              >
                                                <ThumbDownIcon />
                                              </Material.IconButton>
                                            </Material.Tooltip>
                                            <Material.Tooltip title="Modify Item Description">
                                              <Material.IconButton
                                                className="animate"
                                                color="primary"
                                                onClick={() => {
                                                  this.onHandleModify(
                                                    true,
                                                    item
                                                  );
                                                }}
                                              >
                                                <DriveFileRenameOutlineIcon />
                                              </Material.IconButton>
                                            </Material.Tooltip>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="inline">
                                              <Material.IconButton
                                                className="animate"
                                                color="gray"
                                              >
                                                <ThumbUpIcon />
                                              </Material.IconButton>
                                              <Material.IconButton
                                                className="animate"
                                                color="gray"
                                              >
                                                <ThumbDownIcon />
                                              </Material.IconButton>
                                            </div>
                                          </>
                                        )}
                                      </Material.TableCell>
                                      {filtered_status !== 3 ? (
                                        ""
                                      ) : (
                                        <Material.TableCell>
                                          {item.remarks}
                                        </Material.TableCell>
                                      )}
                                    </Material.TableRow>
                                  );
                                }
                                return null;
                              })}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Material.TableBody>
            </Material.Table>
          </Material.TableContainer>
        </Material.Paper>
        {/* <Material.Dialog
          open={confirmationDialogVisible}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogTitle id="alert-dialog-title" color="secondary">
            Confirmation
          </Material.DialogTitle>
          <Material.DialogContent>
            <Material.DialogContentText id="alert-dialog-description">
              Do you want to confirm this action ?
            </Material.DialogContentText>
            {this.state.approve === false ? (
              <Material.TextField
                id="remarks"
                label="Remarks"
                multiline
                type="text"
                onChange={this.handleRemarksChange}
                value={this.state.remarks}
                className={classes.textField_input}
                margin="dense"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            ) : (
              ""
            )}
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Button
              onClick={() => {
                this.setState({
                  ...this.state,
                  approve: false,
                  ppmp_item_to_approve_id: 0,
                  remarks: "",
                  name: this.state.name,
                  description: this.state.description,
                });
                this.onHandleConfirm(false, {});
              }}
              color="secondary"
            >
              Cancel
            </Material.Button>
            <Material.Button
              onClick={() =>
                this.setPPMPItemStatus(this.state.item_to_approve_id)
              }
              color="primary"
              autoFocus
            >
              Continue
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog> */}
        <Material.Dialog
          open={confirmationDialogVisible}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogTitle id="alert-dialog-title" color="secondary">
            Confirmation
          </Material.DialogTitle>
          <Material.DialogContent>
            <Material.DialogContentText id="alert-dialog-description">
              Do you want to confirm this action?
            </Material.DialogContentText>
            {!this.state.approve && (
              <Material.TextField
                id="remarks"
                label="Remarks"
                multiline
                type="text"
                onChange={this.handleRemarksChange}
                value={this.state.remarks}
                className={classes.textField_input}
                margin="dense"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Button
              onClick={() => {
                this.setState({
                  confirmationDialogVisible: false,
                  remarks: "",
                  name: "",
                  description: "",
                });
                this.onHandleConfirm(false, {});
              }}
              color="secondary"
            >
              Cancel
            </Material.Button>
            <Material.Button
              onClick={() => {
                this.setPPMPItemStatus(this.state.item_to_approve_id);
              }}
              color="primary"
              autoFocus
            >
              Continue
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog>

        <Material.Dialog
          open={this.state.modifyDialogVisible}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogTitle id="alert-dialog-title" color="secondary">
            Modification of Item Description
          </Material.DialogTitle>
          <Material.DialogContent>
            {/* Editable item name */}
            <Material.TextField
              label="Item Name"
              type="text"
              value={this.state.name}
              onChange={this.handleModifyItemName}
              className={classes.textField_input}
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            {/* Editable item description */}
            <Material.TextField
              label="Item Description"
              multiline
              type="text"
              value={this.state.description}
              onChange={this.handleModifyItemDescription}
              className={classes.textField_input}
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Button
              onClick={() => {
                this.setState({
                  approve: false,
                  ppmp_item_to_approve_id: 0,
                  remarks: "",
                  name: "",
                  description: "",
                });
                this.onHandleModify(false, {}); // Reset dialog
              }}
              color="secondary"
            >
              Cancel
            </Material.Button>
            <Material.Button
              onClick={() => {
                this.setState({ approve: null }, () =>
                  this.setPPMPItemStatus()
                );
              }}
              color="primary"
              autoFocus
            >
              Continue
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Material.withStyles(useStyles)(ItemListForApproval));
