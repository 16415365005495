export const useStyles = (theme) => ({
    root: {
		outline: 'none',
        margin: theme.spacing(0.5),
        display: 'inline-block',
		alignItems: 'center',
		'& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
		},
		'& .inline': {
			display: 'inline-flex',
			'& > *': {
				paddingRight: theme.spacing(2),
			},
			'& .MuiTypography-subtitle1': {
				whiteSpace: 'nowrap',
				margin: theme.spacing(0,0,0,0.5),
				display: 'inline-block',
			},
		}
	},
	FormContainer: {
		padding: theme.spacing(2),
		'& .MuiGrid-item': {
			display: "inline-grid"
		},
		'& .title': {
			padding: theme.spacing(2,0,0,0),
		}
	},
	gridContianer: {
		
	},
    foroneline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '98%',
        },
    },
    fortwoline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '52ch',
        },
    },
    forthreeline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '34ch',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overFlow: 'scroll',
        '& .curved': {
            border: 'none',
            borderRadius: '10px',
            outline: 'none',
        },
    },
    table: {
        minWidth: 650
	},
    paper: {
        backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
        borderRadius: '10px',
        padding: theme.spacing(4, 4, 3),
    },
    action: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
})