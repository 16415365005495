import React from "react";
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';

import { mapStateToProps, mapDispatchToProps } from '../ppmp.reducer';

import * as Material from '@material-ui/core';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
// import CheckIcon from '@material-ui/icons/Check';
// import ClearIcon from '@material-ui/icons/Clear';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import SuppliesModal from '../supplies/ppmp.modal';

import * as PPMPService from '../ppmp.service';
import { useStyles } from "./view.req.item.modal.style";

class ViewReqItemsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 5,
            page_no: 0,
            office_id: 0,
            total: 0,

            totalItems: 0,
            transactionNo: "",
            transactionData: null,
            modalVisible: false,

            keyword: "",                    //--
            sort_column: "",                //---
            sort_type: "",                  //----- initialiation for get items
            filter_unit: "",                //---
            filter_category_name: "",       //--
        };
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.requestGetRequestedItems = this.requestGetRequestedItems.bind(this);
        this.TablePaginationActions = this.TablePaginationActions.bind(this);
        this.closeViewReqItemsModal = this.closeViewReqItemsModal.bind(this);
        this.setTransactionData = this.setTransactionData.bind(this);
        this.requestItemDataSouce = this.requestItemDataSouce.bind(this);
        this.requestGeneratedPPMP = this.requestGeneratedPPMP.bind(this);
        this.generateChip = this.generateChip.bind(this);
        this.openSuppliesModal = this.openSuppliesModal.bind(this);
    }
    handleChangePage(event, value) {
        this.setState({ ...this.state, page_no: value }, () => this.requestGetRequestedItems())
    }
    handleChangeRowsPerPage(e) {
        this.setState({ ...this.state, page_no: 0, limit: parseInt(e.target.value) }, () => { this.requestGetRequestedItems() });
    }
    requestGetRequestedItems() {
        const { displayLoading, displayAlert } = this.props;
        displayLoading(true);
        PPMPService.getRequestedItems({ ...this.state }).then((response) => {
            this.props.setRequestedItemsDataSource({
                requestedItemsDatasource: response.data.result.result0,
                total: parseInt(response.data.result.result1[0].ROW_COUNT),
            })
            displayLoading(false);
        }).catch((err) => {
            displayLoading(false);
            displayAlert({ visible: true, message: err, severity: "error" });
        });
    }

    TablePaginationActions(props) {
        const { classes } = this.props;
        const { count, page, rowsPerPage, onChangePage } = props;
        return (
            <div className={classes.tableFooter}>
                <Material.IconButton onClick={(event) => { onChangePage(event, 0) }}
                    disabled={page === 0}
                    aria-label="first page" >
                    <FirstPageIcon />
                </Material.IconButton>
                <Material.IconButton onClick={(event) => { onChangePage(event, page - 1) }}
                    disabled={page === 0} aria-label="previous page">
                    <KeyboardArrowLeft />
                </Material.IconButton>
                <Material.IconButton onClick={(event) => { onChangePage(event, page + 1) }}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page" >
                    <KeyboardArrowRight />
                </Material.IconButton>
                <Material.IconButton onClick={(event) => { onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1)) }}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page" >
                    <LastPageIcon />
                </Material.IconButton>
            </div>
        );
    }
    closeViewReqItemsModal() {
        this.props.closeViewReqItemsModal();
    }
    requestItemDataSouce() {
        const { setItemDataSource, displayLoading, displayAlert } = this.props;
        displayLoading(true);
        PPMPService.getItemList({ ...this.state }).then((response) => {
            if (response.error) {
                displayAlert({ visible: true, message: response.message, severity: "error" }) ///diri mu error igka open sa view requested ppmp items
            } else {
                setItemDataSource({
                    itemDataSource: response.result.result0,
                    itemTotal: parseInt(response.result.result1[0].ROW_COUNT),
                    itemFilter: response.result.result3,
                });
                this.generateChip();
            }
            displayLoading(false);
        }).catch((err) => {
            this.generateChip();
            displayLoading(false);
            displayAlert({ visible: true, message: err, severity: "warning" });
        });
    }
    openSuppliesModal(res) {
        this.props.displaySupplies({ modalVisible: true, suppliesData: res.data, transactionData: this.state.transactionData })
    }
    requestGeneratedPPMP() {
        const { displayLoading, displayAlert, setGenPPMPDataSource } = this.props;
        displayLoading(true);
        PPMPService.getGeneratedPPMP({ ppmp_id: this.state.transactionID }).then((response) => {
            if (response.error) {
                displayAlert({ visible: true, message: response.msg, severity: "warning" })
            } else {
                setGenPPMPDataSource({ genPPMPDataSource: response.data })
                let count = 0;
                response.data.forEach((e, r) => {
                    e.funds.forEach((t, y) => {
                        t.items.forEach((i, o) => {
                            count++;
                        })
                    })
                })
                this.setState({ ...this.state, totalItems: count });
            }
            displayLoading(false);
        }).catch((err) => {
            displayLoading(false);
            displayAlert({ visible: true, message: err, severity: "error" })
        });
    }
    setTransactionData() {
        // console.log('$$props_requested_items', this.props);
        sessionService.loadSession().then((currentSession) => {
            this.setState({
                ...this.state,
                transactionData: currentSession.selectedTransaction,
                office_id: this.props.office_id
            }, () => this.requestGetRequestedItems());
        }).catch((err) => {
            this.props.displayAlert({ visible: true, message: err, severity: "warning" });
        });
    }
    generateChip() {
        const { keyword, filter_unit, filter_category_name } = this.state;
        let data = [];
        (keyword) && data.push({ key: 1, label: keyword, icon: <SearchIcon />, stateVar: "keyword", stateVara: "searchValue" });
        // if (sort_column) {
        //     var icon = sort_type==='asc'?<ArrowUpwardIcon />:<ArrowDownwardIcon />;
        //     data.push({key: 2, label: sort_column, icon , stateVar: "sort_column", stateVara: "sort_type"});
        // }
        (filter_unit) && data.push({ key: 3, label: filter_unit, icon: <FilterListIcon />, stateVar: "filter_unit", stateVara: "filter_unit" });
        (filter_category_name) && data.push({ key: 4, label: filter_category_name, icon: <FilterListIcon />, stateVar: "filter_category_name", stateVara: "filter_category_name" });
        this.setState({ ...this.state, chipData: data });
    }

    render() {
        const { ViewReqItemsModalVisible, classes, closeViewReqItemsModal, ppmp, displaySupplies } = this.props;
        return (
            <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" className={classes.modal}
                open={ViewReqItemsModalVisible}
                // onClose={closeViewReqItemsModal}
                onRendered={this.setTransactionData}
                closeAfterTransition
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, }}>
                <div className={classes.root}>
                    <Material.Fade in={ViewReqItemsModalVisible}>
                        <div className={classes.paper}>
                            <Material.Grid container spacing={0}>
                                <Material.Grid item xs={10}>
                                    <div className="inline">
                                        <Material.Typography className="flex" color="primary" variant="h5">Requested Supplies</Material.Typography>
                                    </div>
                                </Material.Grid>
                            </Material.Grid>
                            <Material.Divider></Material.Divider>
                            <br></br>
                            <div className={classes.tableHeight}>
                                <Material.Table className={classes.table} aria-label="simple table">
                                    <Material.TableHead>
                                        <Material.TableRow>
                                            <Material.TableCell>Action</Material.TableCell>
                                            <Material.TableCell>Item Name</Material.TableCell>
                                            <Material.TableCell>Description</Material.TableCell>
                                            <Material.TableCell>Unit</Material.TableCell>
                                            <Material.TableCell>Approval Status</Material.TableCell>
                                            <Material.TableCell>Reject Remarks</Material.TableCell>
                                        </Material.TableRow>
                                    </Material.TableHead>
                                    <Material.TableBody >
                                        {ppmp.reqTotal === 0 ?
                                            <Material.TableRow><Material.TableCell colSpan={5} align="center"><b>No Requested Items</b></Material.TableCell></Material.TableRow>
                                            : <React.Fragment></React.Fragment>
                                        }
                                        {ppmp.requestedItemsDatasource.map((row, index) => (
                                            <Material.TableRow key={`class-${index}`}>
                                                <Material.TableCell>
                                                    <Material.Button disabled={row.is_approved === 'N' ? true : false} variant="outlined" color="primary" className={classes.button} startIcon={<AddShoppingCartIcon color="primary" />}
                                                        onClick={() => {
                                                            displaySupplies({ modalVisible: true, suppliesData: row, transactionData: this.state.transactionData });
                                                        }}> Add to list
                                                    </Material.Button>
                                                </Material.TableCell>
                                                <Material.TableCell>{row.name}</Material.TableCell>
                                                <Material.TableCell>{row.description}</Material.TableCell>
                                                <Material.TableCell>{row.unit}</Material.TableCell>
                                                <Material.TableCell align="center">
                                                    {(row.is_approved === 'Y') && <Material.Chip className={classes.approved} size="small" avatar={<CheckCircleIcon />} label="Approved" />}
                                                    {(row.is_approved === 'N') && <Material.Chip className={classes.rejected} size="small" avatar={<ThumbDownIcon />} label="Rejected" />}
                                                    {(row.is_approved === 'W') && <Material.Chip className={classes.pending} size="small" avatar={<HourglassFullIcon />} label="Pending" />}
                                                </Material.TableCell>
                                                <Material.TableCell>{row.reject_remarks}</Material.TableCell>
                                            </Material.TableRow>
                                        ))
                                        }
                                    </Material.TableBody>
                                    <Material.TableFooter>
                                        <Material.TableRow>
                                            <Material.TablePagination rowsPerPageOptions={[5, 10, 15, { label: 'All', value: 1000 }]}
                                                count={ppmp.reqTotal} rowsPerPage={this.state.limit} page={this.state.page_no}
                                                SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                ActionsComponent={this.TablePaginationActions} />
                                        </Material.TableRow>
                                    </Material.TableFooter>
                                </Material.Table>
                            </div>
                            <Material.Button className={classes.action} color="secondary" onClick={() => { this.closeViewReqItemsModal() }}>Close</Material.Button>
                        </div>
                    </Material.Fade>
                    <SuppliesModal
                        onCloseModal={this.closeViewReqItemsModal}
                        requestGeneratedPPMP={this.requestGeneratedPPMP}
                        ppmpId={this.props.ppmpId}
                    />
                </div>
            </Modal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Material.withStyles(useStyles)(ViewReqItemsModal));
