export const useStyles = (theme) => ({
    root: {
        outline: 'none',
        '& .MuiTypography-body1': {
            color: 'white',
            fontSize: '20px',
            paddingLeft: '10px',
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px',
        padding: theme.spacing(2, 4, 3),
    },
})