import React from "react";
import { connect } from "react-redux";

import * as Material from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useStyles } from "../request.item/req.item.modal.style";
import * as RequestItemService from "../request.item/req.item.service";

import { mapStateToProps, mapDispatchToProps } from "../../ppmp/ppmp.reducer";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

class RequestItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDesc: "",
      itemUnit: "",
      itemCategory: 0,
      itemCategoryName: "",
      itemIsActive: true,
      itemUserId: 0,
      itemPrice: "0",
      itemId: 0,
      itemLink: "",
      itemName: "",
      itemIsApproved: false,
      itemCode: 0,

      user_id: 0,
      office_id: 0,
      limit: 5,
      page_no: 0,
      total: 0,

      itemsCategories: [],
      ViewReqItemsModalVisible: false,
    };
    this.onHandleAutocomplete = this.onHandleAutocomplete.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleCancel = this.onHandleCancel.bind(this);
    this.onPriceInput = this.onPriceInput.bind(this);
    this.normalizePriceInput = this.normalizePriceInput.bind(this);
    this.requestItemNow = this.requestItemNow.bind(this);
    this.requestItemNowService = this.requestItemNowService.bind(this);
    this.modalMount = this.modalMount.bind(this);
    this.resetDefault = this.resetDefault.bind(this);
    this.requestGetItemsCategories = this.requestGetItemsCategories.bind(this);
  }
  modalMount() {
    this.requestGetItemsCategories();
    this.setState({
      office_id: this.props.data.user.office_id,
      user_id: this.props.data.user.id,
    });
  }
  requestGetItemsCategories() {
    RequestItemService.getItemsCategories()
      .then((response) => {
        this.props.setItemsCategoriesDataSource({
          itemsCategoriesDatasource: response.data.data,
        });
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "error",
        });
      });
  }
  onHandleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onHandleAutocomplete(event, value) {
    if (value) {
      this.setState({
        ...this.state,
        itemCategory: value.id,
        itemCategoryName: value.category_name,
      });
    }
  }
  onHandleCancel() {
    this.props.onCloseModal();
    this.resetDefault();
  }
  resetDefault() {
    this.setState({
      ...this.state,
      itemNameError: false,
      itemDescError: false,
      itemUnitError: false,
      itemDesc: "",
      itemUnit: "",
      itemCategory: 0,
      itemCategoryName: "",
      itemIsActive: true,
      itemUserId: 0,
      itemPrice: "0",
      itemId: 0,
      itemLink: "",
      itemName: "",
      itemIsApproved: false,
      itemCode: 0,

      user_id: 0,
      office_id: 0,
      limit: 5,
      page_no: 0,
      total: 0,
    });
  }
  onPriceInput({ target: { value } }) {
    this.setState({ itemPrice: this.normalizePriceInput(value) });
  }
  normalizePriceInput(value) {
    return value
      .replace(/[^0-9.]/g, "") // Remove all characters that aren't digit or dot
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Put comma every 3rd digit
      .replace(/\.+/g, ".") // Replace multiple dots with a single dot
      .replace(/(.*\.[0-9][0-9]?).*/g, "$1") // Only allow 2 digits after a dot
      .replace(/^0+(.*)$/, "0$1") // Replace multiple zeros with a single one
      .replace(/^0([^.].*)$/, "$1"); // Remove leading zero
  }
  requestItemNow() {
    const { itemName, itemDesc, itemUnit, itemCategoryName } = this.state;
    const errorHander = {};
    errorHander.itemNameError = !itemName && true;
    errorHander.itemDescError = !itemDesc && true;
    errorHander.itemUnitError = !itemUnit && true;
    errorHander.itemCategoryNameError = !itemCategoryName && true;
    this.setState({ ...this.state, ...errorHander });
    const {
      itemNameError,
      itemDescError,
      itemUnitError,
      itemCategoryNameError,
    } = errorHander;
    if (
      !itemNameError &&
      !itemDescError &&
      !itemUnitError &&
      !itemCategoryNameError
    ) {
      this.requestItemNowService();
    }
  }
  requestItemNowService() {
    const {
      displayLoading,
      displayAlert,
      // expectItemReturn,
      // onCloseRender,
    } = this.props;
    const { itemPrice } = this.state;
    displayLoading(true);
    let itemPriceCC = itemPrice.replace(/[,]+/g, "");
    RequestItemService.requestItemNow({ ...this.state, itemPrice: itemPriceCC })
      .then((res) => {
        if (res.data.error) {
          displayAlert({
            visible: true,
            message: res.data.msg,
            severity: "error",
          });
        } else {
          displayAlert({
            visible: true,
            message: res.data.msg,
            severity: "success",
          });
          displayLoading(false);
          this.onHandleCancel();
          // if (expectItemReturn) {
          //   onCloseRender(res.data);
          // }
        }
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({ visible: true, message: err, severity: "error" });
      });
  }
  render() {
    const { reqItemModalVisible, classes, ppmp } = this.props;
    const {
      itemPrice,
      itemDesc,
      itemUnit,
      itemCategoryName,
      itemName,
      itemLink,
      itemDescError,
      itemCategoryNameError,
      itemUnitError,
      itemNameError,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={reqItemModalVisible}
          onClose={this.onHandleCancel}
          onRendered={this.modalMount}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <div className={classes.root}>
            <Fade in={reqItemModalVisible}>
              <div className={classes.paper}>
                <Material.Typography color="primary" variant="h5">
                  Request Supply
                </Material.Typography>
                <div className={classes.FormContainer}>
                  <form
                    className={classes.foroneline}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      multiline
                      required
                      id="item-name"
                      label="Item Name"
                      name="itemName"
                      value={itemName || ""}
                      error={itemNameError}
                      onChange={this.onHandleChange}
                    />
                  </form>
                  <form
                    className={classes.foroneline}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      multiline
                      required
                      id="item-description"
                      label="Item Description/Specification"
                      name="itemDesc"
                      value={itemDesc || ""}
                      error={itemDescError}
                      onChange={this.onHandleChange}
                    />
                  </form>
                  <form
                    className={classes.forthreeline}
                    noValidate
                    autoComplete="off"
                  >
                    <Autocomplete
                      id="item-category"
                      value={itemCategoryName || ""}
                      onChange={this.onHandleAutocomplete}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={ppmp.itemsCategoriesDatasource}
                      getOptionLabel={(option) =>
                        typeof option === "string"
                          ? option
                          : option.category_name
                      }
                      renderOption={(option) => option.category_name}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Category"
                          required
                          error={itemCategoryNameError}
                          margin="normal"
                        />
                      )}
                    />
                    <TextField
                      onChange={this.onPriceInput}
                      id="item-price"
                      label="Price"
                      name="itemPrice"
                      value={"₱ " + itemPrice || ""}
                    />
                    {/* select revamp */}
                    {/* <TextField required id="item-unit" label="Unit" name="itemUnit" error={itemUnitError} value={itemUnit || ""} onChange={this.onHandleChange} /> */}
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel>Unit</InputLabel>
                      <Select
                        required
                        id="item-unit"
                        value={itemUnit || ""}
                        label="Unit"
                        onChange={this.onHandleChange}
                        error={itemUnitError}
                        name="itemUnit"
                      >
                        <MenuItem value={"Ampule"}>Ampule</MenuItem>
                        <MenuItem value={"Bag"}>Bag</MenuItem>
                        <MenuItem value={"Bar"}>Bar</MenuItem>
                        <MenuItem value={"Board Feet"}>Board Feet</MenuItem>
                        <MenuItem value={"Book"}>Book</MenuItem>
                        <MenuItem value={"Bottle"}>Bottle</MenuItem>
                        <MenuItem value={"Box"}>Box</MenuItem>
                        <MenuItem value={"Bundle"}>Bundle</MenuItem>
                        <MenuItem value={"Can"}>Can</MenuItem>
                        <MenuItem value={"Capsule"}>Capsule</MenuItem>
                        <MenuItem value={"Carton"}>Carton</MenuItem>
                        <MenuItem value={"Cartridge"}>Cartridge</MenuItem>
                        <MenuItem value={"Copy"}>Copy</MenuItem>
                        <MenuItem value={"Crate"}>Crate</MenuItem>
                        <MenuItem value={"Cubic Meter"}>Cubic Meter</MenuItem>
                        <MenuItem value={"Cycle"}>Cycle</MenuItem>
                        <MenuItem value={"Cylinder"}>Cylinder</MenuItem>
                        <MenuItem value={"Dose"}>Dose</MenuItem>
                        <MenuItem value={"Dozen"}>Dozen</MenuItem>
                        <MenuItem value={"Each"}>Each</MenuItem>
                        <MenuItem value={"Feet"}>Feet</MenuItem>
                        <MenuItem value={"Gallon"}>Gallon</MenuItem>
                        <MenuItem value={"Gram"}>Gram</MenuItem>
                        <MenuItem value={"Head"}>Head</MenuItem>
                        <MenuItem value={"Hectare"}>Hectare</MenuItem>
                        <MenuItem value={"Inch"}>Inch</MenuItem>
                        <MenuItem value={"Jar"}>Jar</MenuItem>
                        <MenuItem value={"Kilo"}>Kilo</MenuItem>
                        <MenuItem value={"Kilometer"}>Kilometer</MenuItem>
                        <MenuItem value={"License"}>License</MenuItem>
                        <MenuItem value={"Liter"}>Liter</MenuItem>
                        <MenuItem value={"Lot"}>Lot</MenuItem>
                        <MenuItem value={"Lump Sum"}>Lump Sum</MenuItem>
                        <MenuItem value={"Meter"}>Meter</MenuItem>
                        <MenuItem value={"Mile"}>Mile</MenuItem>
                        <MenuItem value={"Nebule"}>Nebule</MenuItem>
                        <MenuItem value={"Pack"}>Pack</MenuItem>
                        <MenuItem value={"Package"}>Package</MenuItem>
                        <MenuItem value={"Packet"}>Packet</MenuItem>
                        <MenuItem value={"Pad"}>Pad</MenuItem>
                        <MenuItem value={"Pail"}>Pail</MenuItem>
                        <MenuItem value={"Pair"}>Pair</MenuItem>
                        <MenuItem value={"Piece"}>Piece</MenuItem>
                        <MenuItem value={"Quart"}>Quart</MenuItem>
                        <MenuItem value={"Quire"}>Quire</MenuItem>
                        <MenuItem value={"Ream"}>Ream</MenuItem>
                        <MenuItem value={"Roll"}>Roll</MenuItem>
                        <MenuItem value={"Sack"}>Sack</MenuItem>
                        <MenuItem value={"Set"}>Set</MenuItem>
                        <MenuItem value={"Sheet"}>Sheet</MenuItem>
                        <MenuItem value={"Spool"}>Spool</MenuItem>
                        <MenuItem value={"Square Meter"}>Square Meter</MenuItem>
                        <MenuItem value={"Tablet"}>Tablet</MenuItem>
                        <MenuItem value={"Tube"}>Tube</MenuItem>
                        <MenuItem value={"Unit"}>Unit</MenuItem>
                        <MenuItem value={"Vial"}>Vial</MenuItem>
                        <MenuItem value={"Pax"}>Pax</MenuItem>
                        <MenuItem value={"Kit"}>Kit</MenuItem>
                        <MenuItem value={"Drum"}>Drum</MenuItem>
                        <MenuItem value={"Seedling"}>Seedling</MenuItem>
                        <MenuItem value={"Rack"}>Rack</MenuItem>
                      </Select>
                      <FormHelperText>Please select a unit.</FormHelperText>
                    </FormControl>
                  </form>
                  <form
                    className={classes.foroneline}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      multiline
                      id="item-link"
                      label="Reference Link"
                      name="itemLink"
                      value={itemLink || ""}
                      onChange={this.onHandleChange}
                    />
                  </form>
                  <form className={classes} noValidate autoComplete="off">
                    <Button
                      className={classes.action}
                      variant="contained"
                      color="primary"
                      onClick={() => this.requestItemNow()}
                    >
                      Request
                    </Button>
                    <Button
                      className={classes.action}
                      color="secondary"
                      onClick={() => this.onHandleCancel()}
                    >
                      Cancel
                    </Button>
                  </form>
                </div>
              </div>
            </Fade>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(RequestItemModal));
