// PGBHUS-PR BAC Signatories Store
// Copyright 2020 ᜋᜃᜐ Makasa <perinmaxangelo@hotmail.com>

export default (state = {
	bacsignatoriesDatasource: [],
	bacsignatoriesTotal: 0,
	bacsignatoriesOffices: [],
	bacsignatoriesFilter: [],
	bacsignatoriesCreate: {}
}, action) => {
    switch (action.type){
		case "SETBACSIGNATORIESDATASOURCE": 
            return state = {
                ...state,
				bacsignatoriesDatasource: action.payload.bacsignatoriesDatasource,
				bacsignatoriesTotal: action.payload.bacsignatoriesTotal,
				bacsignatoriesOffices: action.payload.bacsignatoriesOffices,
				bacsignatoriesFilter: action.payload.bacsignatoriesFilter,
			}
		case "DROPBACSIGNATORIESSTATE": 
            return state = {
                bacsignatoriesDatasource: [],
				bacsignatoriesTotal: 0,
				bacsignatoriesOffices: [],
				bacsignatoriesFilter: [],
				bacsignatoriesCreate: {}
			}
        default:
            return state;
	}
};
