import { API } from "../../helpers/axios.instance";

export const login = (value) => {
	// let data = new FormData();
	console.log('service_here!');
	let data = new URLSearchParams();
	console.log(value);
	data.append("username", value.username);
	data.append("password", value.password);
	data.append("type", value.proc);
	// console.log(data);

	console.log(API());
	return API().post("/Login/login", data).then((res) => {
		return res.data;
	}).catch((err) => {
		return { error: true, message: `${err}` };
	});
};
