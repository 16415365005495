import React from 'react';
import { connect } from 'react-redux';

import * as Material from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { mapStateToProps } from '../container/container.reducer';
import { mapDispatchToProps } from './login.reducer';
import { setAPIauth } from '../../helpers/axios.instance';
import * as LoginService from './login.service';
import { sessionService } from 'redux-react-session';
import { useStyles } from './login.styles';
import jwt_decode from 'jwt-decode';

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            username: "",
            password: "",
            visible: false,
			showPassword: false,
			usernameError: false,
            passwordError: false,
            type: true,
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.showError = this.showError.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.redirectLogin = this.redirectLogin.bind(this)
        window.redirectLogin = this.redirectLogin;
    }
    onChange (e) {
        this.setState({[e.target.name]: e.target.value});
    }
    componentDidMount(){
        this.setState({...this.state, visible: true, });
    }
    redirectLogin (token) {
        const { displayLoading, displayAlert, setCredential} = this.props;
        displayLoading(true)
        const data = token;
        setAPIauth(data);
        sessionService.saveSession({
            userToken: data,
            selectedTransaction: null,
            selectedPRTransaction: null
        }).then(()=>{
            const user = jwt_decode(data);
            const access = {...user, 
                can_manage_ppmp: user.can_manage_ppmp === "Y" ? true : false,
                // can_manage_pr: user.can_manage_pr === "Y" ? true : false,
                // can_manage_po: user.can_manage_po === "Y" ? true : false,
                can_manage_admin: user.can_manage_admin === "Y" ? true : false,
                isactive: user.isactive === "Y" ? true : false
            }
            setCredential({ userToken: data });
            this.props.setUser({user: access })
            displayAlert({ visible: true, message: `Welcome ${access.firstname}`, severity: "success"  });
            if (!access.can_manage_ppmp) {
                this.props.history.push("/admin");
            }
            displayLoading(false);
        }).catch((err)=> {
            displayAlert({ visible: true, message: err.message, severity: "warning" });
            displayLoading(false);
        });
    }
	requestLogin () {
		const { displayLoading, displayAlert} = this.props;
		const { usernameError, passwordError, type } = this.state;
		if (!usernameError && !passwordError) {
            displayLoading(true);
			LoginService.login({...this.state, proc: type ? "Y" : "N" }).then(res=> {
				if (res.error){
					displayAlert({ visible: true, message: res.message, severity: "error" });
					displayLoading(false);
				} else {
					this.redirectLogin(res);
				}
			}).catch((err)=> {
				displayAlert({ visible: true, message: err, severity: "warning" });
				displayLoading(false);
			});;
		}
	}
    onSubmit (e) {
        e.preventDefault();
        const user = { username: this.state.username, password: this.state.password };
		let validate = { usernameError: false, passwordError: false };
		(user.username === '') && (validate.usernameError=true);
		(user.password === '') && (validate.passwordError=true);
		this.setState({...this.state, ...validate}, ()=>this.requestLogin())
    }
    showError(severity, msg) {
        this.messages.show({ severity: severity, summary: 'Error', detail: msg });
    }
    handleChange (event) {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    }
    render() {
        const { visible, showPassword, usernameError, passwordError } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Material.Slide in={visible} timeout={3000}>
                    <Material.Paper elevation={3} className={classes.container}>
                        <form noValidate autoComplete="off">
                            <div>
                                <Material.TextField id="outlined-username-input" name="username" error={usernameError}
                                    label="Employee ID" onChange={this.onChange} value={this.state.username}
                                    variant="outlined" InputProps={{
                                        startAdornment: <Material.InputAdornment position="start"><PersonIcon /></Material.InputAdornment>,
                                    }}/>
                            </div>
                            <div>
                                <Material.TextField id="outlined-password-input" name="password" error={passwordError}
                                    label="Password" onChange={this.onChange} value={this.state.password}
                                    type={showPassword ? 'text' : 'password'} variant="outlined" InputProps={{
                                        startAdornment: <Material.InputAdornment position="start"><LockIcon /></Material.InputAdornment>,
                                        endAdornment:
                                            <Material.InputAdornment position="end">
                                                <Material.IconButton aria-label="toggle password visibility"
                                                    onClick={()=>{this.setState({...this.state, showPassword: !showPassword })}}
                                                    onMouseDown={(event)=>{event.preventDefault()}} >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </Material.IconButton>
                                            </Material.InputAdornment>
                                    }}/>
                            </div>
                            <div className="checkBoxContainer">
                                <Material.FormControlLabel label="Document Generator System User" 
                                    control={
                                        <Material.Checkbox checked={this.state.type}
                                            onChange={this.handleChange}
                                            name="type" color="primary" />
                                    }/>
                            </div>
                            <div className="buttonContainer">
                                <Material.Button type="submit" className="loginbtn" onClick={this.onSubmit}>Login</Material.Button>
                            </div>
                        </form>
                    </Material.Paper>
                </Material.Slide>
            </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(useStyles)(Login));