import React from "react";
import * as Material from "@material-ui/core";

//imported icons from material

import { useStyles } from "./act.info.modal.style";

// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import ListSubheader from "@mui/material/ListSubheader";
// import DoneIcon from "@material-ui/icons/Done";

class ACTInfoModal extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.onCancelACT = this.onCancelACT.bind(this);
  }
  onCancelACT() {
    this.props.dataHandler({ open: false, data: {} });
  }
  render() {
    const { modalVisible, classes, data } = this.props;
    return (
      <Material.Modal
        aria-labelledby="transition-modal-title"
        onRendered={this.loadEditData}
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalVisible}
        onClose={this.onCancelACT}
        closeAfterTransition
        disableAutoFocus={true}
        BackdropComponent={Material.Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Material.FormControl className={classes.root}>
          <Material.Fade in={modalVisible}>
            {data ? (
              <div className={classes.paper}>
                <div className="inline">
                  <Material.Typography color="primary" variant="h5">
                    {data.title}
                  </Material.Typography>
                </div>
                <div>
                  <Material.Typography variant="subtitle1">
                    {data.activity_type}
                  </Material.Typography>
                </div>
                <div className={classes.FormContainer}>
                  <Material.Grid item xs={9}>
                    <div className="title">
                      <Material.Typography variant="subtitle1" color="secondary">
                        Office Activities Details
                      </Material.Typography>
                    </div>
                    {/* <Material.Divider></Material.Divider> */}
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Activity Type:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.activity_type}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Date:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.date_of_activity_from}
                        {data.date_of_activity_to}
                      </Material.Typography>
                    </div>
                    <div className="title">
                      <Material.Typography variant="subtitle1" color="secondary">
                        Venue Details
                      </Material.Typography>
                    </div>
                    {/* <Material.Divider></Material.Divider> */}
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Venue:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.venue}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Participants:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.participants}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Batch:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.no_of_batches}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Equipment Needed:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.facilities_equipment_needed}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Other Requirements:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.other_requirements}
                      </Material.Typography>
                    </div>
                  </Material.Grid>
                  <Material.Grid item xs={5}>
                    <div className="title">
                      <Material.Typography variant="subtitle1" color="secondary">
                        Catering Details
                      </Material.Typography>
                    </div>
                    {/* <Material.Divider></Material.Divider> */}
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Type of Catering Service:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.type_of_catering_service}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Accomodation:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.accommodation}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Rooms:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.no_of_rooms}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Meals:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.no_of_meals}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Snacks:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.no_of_snacks}
                      </Material.Typography>
                    </div>
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Pax:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.no_of_pax}
                      </Material.Typography>
                    </div>
                    <div className="title">
                      <Material.Typography variant="subtitle1" color="secondary">
                        Other Details
                      </Material.Typography>
                    </div>
                    {/* <Material.Divider></Material.Divider> */}
                    <div className="inline">
                      <Material.Typography color="primary" variant="subtitle1">
                        Remarks:
                      </Material.Typography>
                      <Material.Typography variant="subtitle1">
                        {data.remarks}
                      </Material.Typography>
                    </div>
                  </Material.Grid>
                  <form
                    align="right"
                    className={classes}
                    noValidate
                    autoComplete="off"
                  >
                    <Material.Button
                      className={classes.action}
                      // variant="outlined"
                      color="secondary"
                      onClick={this.onCancelACT}
                    >
                      Close
                    </Material.Button>
                  </form>
                </div>
                {/* <div>				
								<Material.Divider></Material.Divider>
								<div className="title"><Material.Typography color="primary" variant="subtitle1">List</Material.Typography></div>
								<List 
									sx={{
										width: '100%',
										bgcolor: 'background.paper',
										position: 'relative',
										overflow: 'auto',
										maxHeight: 300,
										'& ul': { padding: 0 },
									}}
									// subheader={<li />}
									>
									{[1].map((sectionId) => (
										<li key={`section-${sectionId}`}>
										<ul>
											{/* <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader> */}
                {/* {[0, 1, 2,4,5,6,7,8,9].map((item) => (
											<ListItem key={`item-${sectionId}-${item}`}>
												<ListItemText primary={`Item ${item}`} />
											</ListItem>
											))}
										</ul>
										</li>
									))}
									</List>
							</div> */}
                <div></div>
              </div>
            ) : (
              <div></div>
            )}
          </Material.Fade>
        </Material.FormControl>
      </Material.Modal>
    );
  }
}

export default Material.withStyles(useStyles)(ACTInfoModal);
