export const useStyles = (theme) => ({
	root: {
		flexGrow: 2,
		"& .animate:hover": {
			backgroundColor: "rgba(8, 0, 74, 0.15)",
			transform: "scale(1.1)",
		},
		"& .container": {
			padding: theme.spacing(2),
		},
		"& .MuiTable-root": {},
		"& .MuiTableContainer-root": {
			"overflow-x": "hidden",
		},
		"& .emptyCont": {
			"& .emptyBorder": {
				padding: theme.spacing(10, 4),
				height: "calc(100vh - 578px)",
				borderRadius: "10px",
				borderStyle: "dashed",
				borderColor: "rgba(29, 29, 29, 0.10)",
			},
			"& .empty": {
				height: "100%",
				padding: theme.spacing(4),
				backgroundColor: "rgba(29, 29, 29, 0.10)",
				borderRadius: "10px",
				textAlign: "center",
				color: "gray",
			},
        },
	},
	paper: {
		// padding: theme.spacing(1),
		color: theme.palette.text.secondary,
	},
	paperless: {
		padding: theme.spacing(1),
		color: "transparent",
		border: "none",
		elevation: 0,
	},
	paperless_ref: {
		padding: theme.spacing(1),
		backgroundColor: "rgba(29, 29, 29, 0)",
		backgroundImage:
			"linear-gradient(to right, rgba(29, 29, 29, 0.40) , rgba(29, 29, 29, 0))",
		color: "transparent",
		borderRadius: "55px 0px 0px 55px",
		border: "none",
		elevation: 0,
		boxShadow: "none",
		width: "300px",
	},
	tablePaper: {
		overflow: "auto",
		height: "calc(100vh - 274px)",
	},
	tableFooter: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
	drawer: {
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		width: 250,
		"& .MuiFormControl-root": {
			display: "flex",
		},
		"& .MuiFormControlLabel-root": {
			paddingLeft: theme.spacing(1),
		},
		"& .radio-choices:hover": {
			"& .MuiButtonBase-root": {
				backgroundColor: "rgba(8, 0, 74, 0.15)",
				transform: "scale(1.1)",
			},
			"& .MuiFormControlLabel-label": {
				transform: "scale(1.1)",
			},
		},
	},
	tableRow: {
		"&:hover": {
			backgroundColor: "rgba(29, 29, 29, 0.10)",
			transform: "scale(1)",
		},
	},
	expandableContent: {
		backgroundColor: "rgba(247, 247, 247)",
	},
	expandableRow: {
		"& > *": {
			borderBottom: "unset",
		},
		"&:hover": {
			backgroundColor: "rgba(29, 29, 29, 0.10)",
			transform: "scale(1)",
		},
	},
	statusColor: {
		"& .Open": {
			backgroundColor: "#2e4ae6",
			color: "white",
			width: "120px",
		},
		"& .Pre-Approval": {
			backgroundColor: "#ef8e78",
			color: "white",
			width: "120px",
		},
		"& .Cancelled": {
			width: "120px",
		},
		"& .Returned": {
			backgroundColor: "#18b3d6",
			color: "white",
			width: "120px",
		},
		"& .Pending": {
			backgroundColor: "#e89e16eb",
			color: "white",
			width: "120px",
		},
		"& .Revision": {
			backgroundColor: "#e89e16eb",
			color: "white",
			width: "120px",
		},
		"& .Approved": {
			backgroundColor: "green",
			color: "white",
			width: "120px",
		},
		"& .Generated": {
			backgroundColor: "#cccc10",
			color: "white",
			width: "120px",
		},
		"& .Approved-Revision": {
			backgroundColor: "green",
			color: "white",
			width: "120px",
			fontSize: "10px", 
		},
		"& .RecievedPBMO": {
			backgroundColor: "#78909c",
			color: "white",
			width: "120px",
			fontSize: "12px",
		},
		"& .ForEdit": {
			backgroundColor: "#e89e16eb",
			color: "white",
			width: "120px",
		},
	},
	list: {
		width: 250,
	},
	buttons: {
		//padding: theme.spacing(2),
		margin: theme.spacing(1),
		"&:hover": {
			transform: "scale(1.1)",
		},
	},
	toolbar: {
		// paddingLeft: theme.spacing(2),
		// paddingRight: theme.spacing(1),
	},
	toolRight: {
		flexGrow: 1,
	},
	form: {
		display: "flex",
		flexDirection: "column",
		margin: "auto",
		width: "fit-content",
	},
	formControl: {
		marginTop: theme.spacing(2),
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: theme.spacing(1),
	},
	container: {
		display: "flex",
		flexWrap: "flex",
	},
	textField: {
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(5),
		marginRight: theme.spacing(6),
	},
	textField_input: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	animate: {
		"&:hover": {
			backgroundColor: "rgba(8, 0, 74, 0.15)",
			transform: "scale(1.1)",
		},
	},

	chipContainer: {
		padding: theme.spacing(1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},

	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	speedDial: {
		position: "absolute",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	popover: {
		pointerEvents: "none",
	},
	actionList: {
		margin: theme.spacing(0, 1, 0, 0),
	},

	noRevisionYet: {
        backgroundColor: '#66bb6a',
        color: 'white',
        width: '50%',
		'& .MuiChip-avatar': {
			color:"white",
		}
	},
	revision_two: {
        backgroundColor: '#ffa726',
        color: "white",
        width: '50%',
		'& .MuiChip-avatar': {
			color: "orange",
		}
    },
    revision_one: {
        backgroundColor: '#ffee58',
        color: "white",
        width: '50%',
		'& .MuiChip-avatar': {
			color: "orange",
		}
    },
    noRevisionLeft: {
        backgroundColor: '#ef5350',
        color: "white",
        width: '50%',
		'& .MuiChip-avatar': {
			color:"red",
		}
    },
});
