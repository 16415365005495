export default (state = {
	userroleDatasource: [],
}, action) => {
    switch (action.type){
        case "SETUSERROLEDATASOURCE":
            return state = {
                ...state,
				userroleDatasource: action.payload.userroleDatasource,
            }
        case "DROPUSERROLESTATE":
            return state = {
                userroleDatasource: [],
			}
        default:
            return state;
    }
};
