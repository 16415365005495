import { DataArrayRounded } from "@mui/icons-material";
import { API } from "../../helpers/axios.instance";

export const getPPMPByBudgetConsumption = (values) => {
	let data = new FormData();
	data.append("respo_code_desc", values.values.respo_code_desc);
	data.append("account_code_desc", values.values.account_code_desc);
	data.append("legal_basis", values.values.legal_basis);


	return API()
		.post("/BudgetConsumption/GetPPMP/index_post", data)
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			console.log("$$err", err);
		});
};

