import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { sessionReducer, sessionService } from "redux-react-session";
import logger from "redux-logger";

import "./index.css";
import Controller from "./components/container/container";
import * as serviceWorker from "./serviceWorker";
import oAuth from "./helpers/oAuth.store";
import data from './components/container/container.store';
import userRole from './components/admin/users.role/users.role.store';
import suppliers from './components/admin/suppliers/suppliers.store';
import bacsignatories from './components/admin/bac_signatories/bac_signatories.store';
import twgSignatories from './components/admin/twg.signatories/twg.store';
import ppmp from './components/ppmp/ppmp.store';
import items from './components/admin/item/item.store';
import request_items from './components/admin/item.request/item.request.store';
import users from './components/admin/users/users.store';
import category from './components/admin/item/item.category/category.store';
import price from './components/admin/item/item.price/price.store';
import pr from './components/pr/pr.store';
import po from './components/po/po.store';
import modes from './components/admin/modes/modes.store'
import ppmpReqPrice from './components/admin/price.request/price.request.store';
import ppmpReqPriceList from './components/admin/price.request.list/price.request.list.store';
import { initAuthOp, initToken, initURL } from "./helpers/axios.instance";
import { validateSession } from "./helpers/validateSession";

const log = (msg)=>(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && console.log(msg);
let store = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
  createStore(combineReducers({
    userRole, auth: oAuth, data, suppliers, bacsignatories, twgSignatories, ppmp, items, request_items, category, price, users, pr, po, modes, ppmpReqPrice,ppmpReqPriceList,
    session: sessionReducer,
  }), {}, applyMiddleware(logger)) :
  createStore(combineReducers({
    userRole, auth: oAuth, data, suppliers, bacsignatories, twgSignatories, ppmp, items, request_items, category, price, users, pr, po, modes, ppmpReqPrice,ppmpReqPriceList,
    session: sessionReducer,
  }), {});
initURL(() => log("Base URL loaded"));
sessionService.initSessionService(store, {
  refreshOnCheckAuth: true,
  redirectPath: "/logout",
  driver: "LOCALSTORAGE",
  validateSession,
}).then(() => log("Redux React Session is ready and a session was refreshed from your storage"))
.catch(() => log("Redux React Session is ready and there is no session in your storage"));
initAuthOp(() => log("Basic Auth loaded"))
initToken(() => log("Static Token loaded"))

ReactDOM.render(
  <Provider store={store}>
    <Controller />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
