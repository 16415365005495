import { API } from "../../helpers/axios.instance";

export const getItemsByPO = (values) => {
    let data = new FormData();

    data.append("po_no", values.po_no);
	data.append("office_id", values.office_id);


	return API()
		.post("/PoDeliverables/GetPoItems/index_post", data)
		.then((response) => {
            return response.data;
		})
		.catch((err) => {
			console.log("$$err", err);
		});
};

export const getItemDeliveryDetails = (values) => {
	let data = new FormData();

    data.append("ppmp_items_id", values.ppmp_items_id);
	data.append("po_items_id", values.po_items_id);


	return API()
		.post("/PoDeliverables/GetPOItemsTracking/index_post", data)
		.then((response) => {
            return response.data;
		})
		.catch((err) => {
			console.log("$$err", err);
		});
}

export const insertDeliveredItems = (values) => {
	let data = new FormData();

	data.append("id", values.id);
    data.append("ppmp_items_id", values.ppmp_items_id);
	data.append("po_items_id", values.po_items_id);
	data.append("qty_delivered", values.qty_delivered);
	data.append("dg_user_id", values.dg_user_id);
	data.append("is_final", values.is_final);
	data.append("delivered_on", values.delivered_on);

	return API()
		.post("/PoDeliverables/Insert/index_post", data)
		.then((response) => {
            return response.data;
		})
		.catch((err) => {
			console.log("$$err", err);
		});
}

