// CO-AUTHOR - MP-DEV, Class Based Components
import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import * as Material from "@material-ui/core";
import { useStyles } from "./activity.modal.style";
import * as ActivitiesService from "./activities.service";

import {
	mapStateToProps,
	mapDispatchToProps,
} from "../activities/activities.reducer";

class ActivityModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_active: true,

			// new state
			id: 0,
			title: "",
			year: "",
			office_id: 0,
			activity_type_id: 0,
			activity_type_name: "",
			date_of_activity_from: "",
			date_of_activity_to: "",
			participants: "",
			no_of_batches: 0,
			venue: "",
			type_of_catering_service: "",
			facilities_equipment_needed: "",
			other_requirements: "",
			remarks: "",
			accommodation: "",
			no_of_rooms: 0,
			no_of_meals: 0,
			no_of_snacks: 0,
			no_of_pax: 0,
			is_active: false,

			year_range: [],
			activity_types: [],
			added_by: 0,

			municipalities: [],

			errorHandler: {},
		};
		this.onToggleSwitch = this.onToggleSwitch.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onChangeHandlerr = this.onChangeHandlerr.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.initStates = this.initStates.bind(this);
		this.requestSaveActivity = this.requestSaveActivity.bind(this);
		this.selectOnChangeHandler = this.selectOnChangeHandler.bind(this);
	}
	componentDidMount() {}
	onChangeHandlerr(e) {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === "" || regex.test(e.target.value)) {
			this.setState({ [e.target.name]: e.target.value });
		}
	}
	onChangeHandler(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onToggleSwitch() {
		this.setState({
			...this.state,
			is_active: !this.state.is_active,
		});
	}
	// This two function serves displays the SnackBar using the redux suppliers.reducer.js
	requestSaveActivity() {
		const { requestGetOfficeActivities, displayAlert, displayLoading } =
			this.props;
		const {
			title,
			year,
			activity_type_id,
			date_of_activity_from,
			date_of_activity_to,
			participants,
			no_of_batches,
			venue,
			type_of_catering_service,
			facilities_equipment_needed,
			other_requirements,
			remarks,
			accommodation,
			no_of_rooms,
			no_of_meals,
			no_of_snacks,
			no_of_pax,
		} = this.state;
		const errorHandler = {
			title_error: !title && true,
			year_error: !year && true,
			activity_type_id_error: activity_type_id == 0 && true,
			date_of_activity_from_error:
				Date.parse(date_of_activity_from) <= Date.parse(date_of_activity_to) &&
				date_of_activity_from
					? false
					: true,
			date_of_activity_to_error:
				Date.parse(date_of_activity_to) >= Date.parse(date_of_activity_from) &&
				date_of_activity_to
					? false
					: true,
			participants_error: !participants && true,
			no_of_batches_error: no_of_batches == 0 && true,
			venue_error: !venue && true,
			type_of_catering_service_error: !type_of_catering_service && true,
			facilities_equipment_needed_error: !facilities_equipment_needed && true,
			other_requirements_error: !other_requirements && true,
			remarks_error: !remarks && true,
			accommodation_error: !accommodation && true,
			no_of_rooms_error: no_of_rooms && no_of_rooms >= 0 ? false : true,
			no_of_meals_error: no_of_meals && no_of_meals >= 0 ? false : true,
			no_of_snacks_error: no_of_snacks && no_of_snacks >= 0 ? false : true,
			no_of_pax_error: no_of_pax && no_of_pax >= 0 ? false : true,
		};

		this.setState(
			{
				...this.state,
				errorHandler,
			},
			() => {
				const {
					title_error,
					year_error,
					activity_type_id_error,
					date_of_activity_from_error,
					date_of_activity_to_error,
					participants_error,
					no_of_batches_error,
					venue_error,
					type_of_catering_service_error,
					facilities_equipment_needed_error,
					other_requirements_error,
					remarks_error,
					accommodation_error,
					no_of_rooms_error,
					no_of_meals_error,
					no_of_snacks_error,
					no_of_pax_error,
				} = errorHandler;
				if (
					!title_error &&
					!year_error &&
					!activity_type_id_error &&
					!date_of_activity_from_error &&
					!date_of_activity_to_error &&
					!participants_error &&
					!no_of_batches_error &&
					!venue_error &&
					!type_of_catering_service_error &&
					!facilities_equipment_needed_error &&
					!other_requirements_error &&
					!remarks_error &&
					!accommodation_error &&
					!no_of_rooms_error &&
					!no_of_meals_error &&
					!no_of_snacks_error &&
					!no_of_pax_error
				) {
					displayLoading(true);
					ActivitiesService.saveActivity({ ...this.state })
						.then((res) => {
							requestGetOfficeActivities();
							displayLoading(false);
							this.closeModal();
							displayAlert({
								alertVisible: true,
								alertMessage: res.data.msg, // Data from The DAL Response
								alertSeverity: "success",
							});
						})
						.catch((err) => {
							console.error(err);
						});
				}
			}
		);
	}
	closeModal() {
		this.props.onCloseModal();
		this.setState({
			...this.state,
			title: "",
			year: "",
			office_id: 0,
			office_name: "",
			activity_type_id: 0,
			activity_type_name: "",
			date_of_activity_from: "",
			date_of_activity_to: "",
			participants: "",
			no_of_batches: 0,
			venue: "",
			type_of_catering_service: "",
			facilities_equipment_needed: "",
			other_requirements: "",
			remarks: "",
			accommodation: "",
			no_of_rooms: 0,
			no_of_meals: 0,
			no_of_snacks: 0,
			no_of_pax: 0,

			titleError: "",
			yearError: "",
			office_idError: 0,
			office_nameError: "",
			activity_type_idError: 0,
			activity_type_nameError: "",
			date_of_activity_fromError: "",
			date_of_activity_toError: "",
			participantsError: "",
			no_of_batchesError: 0,
			venueError: "",
			type_of_catering_serviceError: "",
			facilities_equipment_neededError: "",
			other_requirementsError: "",
			remarksError: "",
			accommodationError: "",
			no_of_roomsError: 0,
			no_of_mealsError: 0,
			no_of_snacksError: 0,
			no_of_paxErrro: 0,
		});
	}
	//for data display for edit (state)
	initStates() {
		const { editMode, editActivityData, data } = this.props;
		let id = editMode ? editActivityData.id : 0;

		// load year
		let currDate = new Date();
		let currYear = parseInt(currDate.getFullYear());
		//get year +1 & -2
		let yearRange = [currYear - 2, currYear - 1, currYear, currYear + 1];

		this.setState(
			{
				...this.state,
				id,
				year_range: yearRange,
				is_active: editMode
					? editActivityData.is_active == 1
						? true
						: false
					: true,
				title: editMode ? editActivityData.title : "",
				year: editMode ? editActivityData.year : currYear,
				office_id: editMode
					? editActivityData.office_id
					: parseInt(data.user.office_id),
				activity_type_id: editMode ? editActivityData.activity_type_id : 0,
				activity_type_name: editMode ? editActivityData.activity_type_name : "",
				date_of_activity_from: editMode
					? editActivityData.date_of_activity_from
					: "",
				date_of_activity_to: editMode
					? editActivityData.date_of_activity_to
					: "",
				participants: editMode ? editActivityData.participants : "",
				no_of_batches: editMode ? editActivityData.no_of_batches : 0,
				venue: editMode ? editActivityData.venue : "",
				type_of_catering_service: editMode
					? editActivityData.type_of_catering_service
					: "",
				facilities_equipment_needed: editMode
					? editActivityData.facilities_equipment_needed
					: "",
				other_requirements: editMode ? editActivityData.other_requirements : "",
				remarks: editMode ? editActivityData.remarks : "",
				accommodation: editMode ? editActivityData.accommodation : "",
				no_of_rooms: editMode ? editActivityData.no_of_rooms : 0,
				no_of_meals: editMode ? editActivityData.no_of_meals : 0,
				no_of_snacks: editMode ? editActivityData.no_of_snacks : 0,
				no_of_pax: editMode ? editActivityData.no_of_pax : 0,
				added_by: editMode ? editActivityData.added_by : data.user.id,
			},
			() => {
				// console.log('$$state', this.state);
				// load activity types
				ActivitiesService.getActivityTypes()
					.then((response) => {
						// console.log("$$response", response);
						if (!response.error) {
							this.setState(
								{
									...this.state,
									activity_types: response.data,
								},
								() => {
									// console.log("$$activity_types", response.data);
								}
							);
						} else {
							this.setState({
								...this.state,
								activity_types: [],
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		);

		ActivitiesService.getMunicipalities()
			.then((response) => {
				if (!response.error) {
					const municipalities = response.data.map(
						(item) => item.municipal_name
					);
					this.setState(
						{
							...this.state,
							municipalities: municipalities,
						},
						() => {
							// console.log("$$municipalities", municipalities);
						}
					);
				} else {
					this.setState({
						...this.state,
						municipalities: [],
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}
	// Function to set state event name target from value target
	selectOnChangeHandler(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	// Render function:
	render() {
		// Declaration of props and state
		const { modalVisible, classes } = this.props;
		const { id, year_range, activity_types, errorHandler, municipalities } =
			this.state;
		const catering_types = ["Buffet", "Platter", "Packed Meals"];
		const venues = [...(municipalities || "")];

		return (
			<Material.Modal
				aria-labelledby="transition-modal-title"
				onRendered={this.initStates}
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={modalVisible}
				onClose={this.closeModal}
				closeAfterTransition
				disableAutoFocus={true}
				BackdropComponent={Material.Backdrop}
				BackdropProps={{ timeout: 500 }}
			>
				<div>
					<Material.Fade in={modalVisible}>
						<div className={classes.paper} spacing={2}>
							<Material.Typography color="primary" variant="h5">
								{id ? "Edit" : "Add"} Office Activity
							</Material.Typography>
							<Material.Divider></Material.Divider>
							<div>
								<Material.Typography
									color="secondary"
									className={classes.typography}
								>
									Activity Details
								</Material.Typography>
								<Material.Grid container spacing={2}>
									<Material.Grid item xs={8}>
										<Material.TextField
											fullWidth
											id="title-input"
											onChange={this.onChangeHandler}
											name="title"
											label="Title *"
											error={errorHandler.title_error}
											value={this.state.title || ""}
										/>
									</Material.Grid>
									<Material.Grid item xs={4}>
										<Material.FormControl sx={{ minwidth: 220 }} fullWidth>
											<Material.InputLabel
												id="activity-input"
												margin="dense"
												error={this.state.activity_type_nameError}
											>
												Activity Type
											</Material.InputLabel>
											<Material.Select
												id="activity-type-select"
												onChange={this.selectOnChangeHandler}
												error={errorHandler.activity_type_id_error}
												name="activity_type_id"
												value={this.state.activity_type_id || ""}
											>
												{activity_types.map((item, index) => (
													<Material.MenuItem
														key={`${index}-option`}
														value={item.id}
													>
														{item.name}
													</Material.MenuItem>
												))}
											</Material.Select>
										</Material.FormControl>
									</Material.Grid>
									<Material.Grid item xs={4}>
										<Material.TextField
											fullWidth
											id="date-from-input"
											type="date"
											onChange={this.onChangeHandler}
											name="date_of_activity_from"
											label="     "
											error={errorHandler.date_of_activity_from_error}
											value={this.state.date_of_activity_from || ""}
											helperText="Date From"
										/>
									</Material.Grid>
									<Material.Grid item xs={4}>
										<Material.TextField
											className={classes.fortline}
											fullWidth
											id="date-to-input"
											type="date"
											onChange={this.onChangeHandler}
											name="date_of_activity_to"
											label="     "
											error={errorHandler.date_of_activity_to_error}
											value={this.state.date_of_activity_to || ""}
											helperText="Date To"
										/>
									</Material.Grid>
									<Material.Grid item xs={4}>
										<Material.FormControl fullWidth>
											<Material.InputLabel
												id="year-input"
												error={this.state.yearError}
											>
												Year
											</Material.InputLabel>
											<Material.Select
												id="year-select"
												onChange={this.selectOnChangeHandler}
												name="year"
												error={errorHandler.year_error}
												value={this.state.year || ""}
											>
												{year_range.map((year) => (
													<Material.MenuItem value={year}>
														{year}
													</Material.MenuItem>
												))}
											</Material.Select>
										</Material.FormControl>
									</Material.Grid>
								</Material.Grid>
								<Material.Typography color="secondary" margin="dense">
									Venue Details
								</Material.Typography>
								<Material.Grid container spacing={2}>
									<Material.Grid item xs={4}>
										{/* <Material.TextField
											fullWidth
											id="venue-input"
											onChange={this.onChangeHandler}
											name="venue"
											label="Venue"
											error={errorHandler.venue_error}
											value={this.state.venue || ""}
										/> */}
										<Material.FormControl fullWidth>
											<Material.InputLabel
												margin="dense"
												error={errorHandler.venue_error}
											>
												Venue
											</Material.InputLabel>
											<Material.Select
												native
												id="venue-select"
												onChange={this.selectOnChangeHandler}
												error={errorHandler.venue_error}
												name="venue"
												value={this.state.venue || ""}
											>
												<option aria-label="None" value="" />
												{/* <optgroup label="Select">
													<option value="Outside Tagbilaran City">
														Outside Tagbilaran City
													</option>
													<option value="Within Tagbilaran City">
														Within Tagbilaran City
													</option>
												</optgroup> */}
												<optgroup label="Select Municipality">
													{venues.map((venue) => (
														<option key={venue} value={venue}>
															{venue}
														</option>
													))}
												</optgroup>
											</Material.Select>
										</Material.FormControl>
									</Material.Grid>
									<Material.Grid item xs={4}>
										<Material.TextField
											fullWidth
											id="participants-input"
											onChange={this.onChangeHandler}
											name="participants"
											label="Participants*"
											error={errorHandler.participants_error}
											value={this.state.participants || ""}
										/>
									</Material.Grid>
									<Material.Grid item xs={4}>
										<Material.TextField
											fullWidth
											id="no_of_batches"
											type="number"
											onChange={this.onChangeHandlerr}
											name="no_of_batches"
											label="Batch"
											error={errorHandler.no_of_batches_error}
											value={this.state.no_of_batches || ""}
										/>
									</Material.Grid>
									<Material.Grid item xs={6}>
										<Material.TextField
											fullWidth
											id="facilities-equipment-needed-input"
											onChange={this.onChangeHandler}
											name="facilities_equipment_needed"
											label="Equipment Needed"
											error={errorHandler.facilities_equipment_needed_error}
											value={this.state.facilities_equipment_needed || ""}
										/>
									</Material.Grid>
									<Material.Grid item xs={6}>
										<Material.TextField
											fullWidth
											id="other-requirements-input"
											onChange={this.onChangeHandler}
											name="other_requirements"
											label="Other Requirements"
											error={errorHandler.other_requirements_error}
											value={this.state.other_requirements || ""}
										/>
									</Material.Grid>
								</Material.Grid>
								<Material.Typography color="secondary" margin="normal">
									Catering Details
								</Material.Typography>
								<Material.Grid container spacing={2}>
									{/* <Material.Grid item xs={6}>
										<Material.TextField
											fullWidth
											id="type-of-catering-field"
											onChange={this.onChangeHandler}
											name="type_of_catering_service"
											label="Type of catering service"
											error={errorHandler.type_of_catering_service_error}
											value={this.state.type_of_catering_service || ""}
										/>
									</Material.Grid> */}
									<Material.Grid item xs={6}>
										<Material.FormControl fullWidth>
											<Material.InputLabel
												margin="dense"
												error={errorHandler.type_of_catering_service_error}
											>
												Type of catering service
											</Material.InputLabel>
											<Material.Select
												id="type-of-catering-service-select"
												onChange={this.selectOnChangeHandler}
												error={errorHandler.type_of_catering_service_error}
												name="type_of_catering_service"
												value={this.state.type_of_catering_service || ""}
											>
												{catering_types.map((type_of_catering_service) => (
													<Material.MenuItem
														key={type_of_catering_service}
														value={type_of_catering_service}
													>
														{type_of_catering_service}
													</Material.MenuItem>
												))}
											</Material.Select>
										</Material.FormControl>
									</Material.Grid>
									<Material.Grid item xs={6}>
										<Material.TextField
											fullWidth
											id="accommodation-input"
											onChange={this.onChangeHandler}
											name="accommodation"
											label="Accommodation"
											error={errorHandler.accommodation_error}
											value={this.state.accommodation || ""}
										/>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.TextField
											fullWidth
											id="no_of_rooms"
											type="number"
											onChange={this.onChangeHandlerr}
											name="no_of_rooms"
											label="Rooms"
											error={errorHandler.no_of_rooms_error}
											value={this.state.no_of_rooms || ""}
										/>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.TextField
											fullWidth
											id="no_of_meals"
											type="number"
											onChange={this.onChangeHandlerr}
											name="no_of_meals"
											label="Meals"
											error={errorHandler.no_of_meals_error}
											value={this.state.no_of_meals || ""}
										/>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.TextField
											fullWidth
											id="no_of_snacks"
											type="number"
											onChange={this.onChangeHandlerr}
											name="no_of_snacks"
											label="Snacks"
											error={errorHandler.no_of_snacks_error}
											value={this.state.no_of_snacks || ""}
										/>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.TextField
											fullWidth
											id="no_of_pax"
											type="number"
											onChange={this.onChangeHandlerr}
											name="no_of_pax"
											label="Pax"
											error={errorHandler.no_of_pax_error}
											value={this.state.no_of_pax || ""}
										/>
									</Material.Grid>
								</Material.Grid>
								<Material.Typography color="secondary" margin="normal">
									Others
								</Material.Typography>
								<Material.Grid container spacing={2}>
									<Material.Grid item xs={12}>
										<Material.TextField
											fullWidth
											id="remarks"
											onChange={this.onChangeHandler}
											name="remarks"
											helperText=""
											label="Remarks"
											error={errorHandler.remarks_error}
											value={this.state.remarks || ""}
											multiline
										/>
									</Material.Grid>
								</Material.Grid>
								<form>
									<Material.FormControlLabel
										control={
											<Material.Switch
												checked={this.state.is_active}
												onChange={this.onToggleSwitch}
												name="is_active"
												color="primary"
											/>
										}
										label="Active"
									/>
								</form>
								<form
									align="right"
									className={classes}
									noValidate
									autoComplete="off"
								>
									<Material.Button
										className={classes.action}
										variant="outlined"
										color="primary"
										onClick={this.requestSaveActivity}
										// onClick={this.onCreateActivity}
									>
										{id > 0 ? "Edit" : "Add"}
									</Material.Button>
									<Material.Button
										className={classes.action}
										variant="outlined"
										color="secondary"
										onClick={this.closeModal}
									>
										Cancel
									</Material.Button>
								</form>
							</div>
						</div>
					</Material.Fade>
				</div>
			</Material.Modal>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(useStyles)(ActivityModal));
