export default (state = {
    userToken: null,
    tokenHash: null,
}, action) => {
    switch (action.type){
        case "SETCREDENTIAL": 
            return state = {
                ...state,
                userToken: action.payload.userToken,
                tokenHash: action.payload.tokenHash,
            }
        case "GETCREDENTIAL":
            return state;
        case "REMOVECREDENTIALS":
            return state = {
                userToken: null,
                tokenHash: null,
            }
        default:
            return state;
    }
};
