import { API } from '../../helpers/axios.instance';

export const getPOW = (tableProp) => {
    // console.log('$$tableProp', tableProp);
    let data = new FormData();
    data.append('office_id', tableProp.office_id); //searches
    data.append('year', parseInt(tableProp.year)); //required
    return API().post('/ProgramOfWorks/Get/index_post', data).then((response) => {
        return response.data;
    }).catch((err) => {
        // console.log('$$err', err);
    });
};

export const getPOWEditModal = (tableProp) => {
    // console.log('$$tableProp', tableProp);
    let data = new FormData();
    data.append('office_id', tableProp.office_id); //searches
    data.append('year', parseInt(tableProp.activity_year)); //required
    return API().post('/OfficeActivities/Get/index_post', data).then((response) => {
        return response.data;
    }).catch((err) => {
        // console.log('$$err', err);
    });
};

export const savePOW = (value) => {
    let data = new FormData();
    data.append("id", value.id);
    data.append("title", value.title);
    data.append("description", value.description);
    data.append("location", value.location);
    data.append("dimension", value.dimension);
    data.append("area", value.area);
    data.append("classification", value.classification);
    data.append("project_cost", value.project_cost);
    data.append("fund_source", value.fund_source);
    data.append("project_duration", value.project_duration);
    data.append("implemented_by", value.implemented_by);
    data.append("date_prepared", value.date_prepared);
    data.append("year", value.year);
    data.append("office_id", value.office_id);
    data.append("is_active", value.is_active ? 1 : 0);
    data.append("added_by", value.added_by);

    return API().post('/ProgramOfWorks/Save/index_post', data).then((response) => {
        // console.log(response);
        return response;
    }).catch((err) => {
        // console.error(err);
    });
}

export const deletePOW = (pow) => {
    let data = new FormData();
    data.append("id", pow.pow_id);
    return API().post('/ProgramOfWorks/Delete/index_post', data).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err);
    });
}

// export const getActivityTypes = () => {
//     let data = new FormData();
//     return API().post('', data).then((response) => {
//         return response.data;
//     }).catch((err) => {
//         console.log('$$err', err);
//     });
// };