export const useStyles = (theme) => ({
  root: {
    "& .inline": {
      display: "inline-flex",
      "& > *": {
        paddingRight: theme.spacing(2),
      },
      "& .MuiTypography-subtitle1": {
        whiteSpace: "nowrap",
        margin: theme.spacing(0, 0, 0, 0.5),
        display: "inline-block",
      },
    },
    "& .container": {
      padding: theme.spacing(2),
    },
    "& .emptyCont": {
      padding: theme.spacing(4),
      "& .emptyBorder": {
        padding: theme.spacing(8, 4),
        borderRadius: "10px",
        borderStyle: "dashed",
        borderColor: "rgba(29, 29, 29, 0.10)",
      },
      "& .empty": {
        padding: theme.spacing(4),
        backgroundColor: "rgba(29, 29, 29, 0.10)",
        borderRadius: "10px",
        textAlign: "center",
        color: "gray",
      },
    },
    "& .animate:hover": {
      backgroundColor: "rgba(8, 0, 74, 0.15)",
      transform: "scale(1.1)",
    },
  },
  transactionHeader: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: theme.shadows[5],
    "& .div-margin": {
      margin: theme.spacing(1, 0, 0, 0),
    },
    "& .pull-right": {
      float: "right",
    },
    "& .edit-button": {
      backgroundColor: "#16ab39",
      color: "white",
      margin: theme.spacing(1, 0),
    },
  },
  statusColor: {
    "& .Open": {
      backgroundColor: "#2e4ae6",
      color: "white",
      width: "120px",
    },
    "& .Pending": {
      backgroundColor: "#e89e16eb",
      color: "white",
      width: "120px",
    },
    "& .Returned": {
      backgroundColor: "#18b3d6",
      color: "white",
      width: "120px",
    },
    "& .Approved": {
      backgroundColor: "green",
      color: "white",
      width: "120px",
    },
    "& .Revision": {
      backgroundColor: "#e89e16eb",
      color: "white",
      width: "120px",
    },
    "& .Generated": {
      backgroundColor: "#cccc10",
      color: "white",
      width: "120px",
    },
    "& .Pre-Approval": {
      backgroundColor: "#ef8e78",
      color: "white",
      width: "120px",
    },
    "& .Approved-Revision": {
      backgroundColor: "green",
      color: "white",
      width: "120px",
      fontSize: "10px",
    },
    "& .Recieved-PBMO": {
			backgroundColor: "#78909c",
			color: "white",
			width: "120px",
			fontSize: "12px",
		},
    "& .For-Edit": {
      backgroundColor: "#e89e16eb",
      color: "white",
      width: "120px",
    },
  },
  toolRight: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(2, 2, 2, 0),
  },
  table: {
    minWidth: 700,
    "& .yellow": {
      backgroundColor: "#F6EAB8",
    },
    "& .lightgrey": {
      backgroundColor: "lightgray",
    },
    "& .blue": {
      backgroundColor: "#7BA6EF",
    },
    "& .lightblue": {
      backgroundColor: "#BAD7F8",
    },
    "& .lightfink": {
      backgroundColor: "#f7d6f6",
    },
    "& .lightmint": {
      backgroundColor: "#d1ffd1",
    },
  },
  textField: {
    margin: theme.spacing(0.5, 1),
  },
  animate: {
    "&:hover": {
      backgroundColor: "rgba(8, 0, 74, 0.15)",
      transform: "scale(1.1)",
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(29, 29, 29, 0.10)",
    },
  },
  dialogHeader: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: theme.shadows[5],
    "& .div-margin": {
      margin: theme.spacing(1, 1, 1, 1),
    },
    "& .pull-right": {
      float: "right",
    },
  },
  dialogContent: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
    "& .div-margin": {
      margin: theme.spacing(1, 1, 1, 1),
    },
    "& .pull-right": {
      float: "right",
    },
  },
  approved: {
    backgroundColor: "#d1ffd1",
    color: "green",
    "& .MuiChip-avatar": {
      color: "green",
    },
  },
  pending: {
    backgroundColor: "#ffebc7",
    color: "orange",
    "& .MuiChip-avatar": {
      color: "orange",
    },
  },
  rejected: {
    backgroundColor: "#ffc9c9",
    color: "red",
    "& .MuiChip-avatar": {
      color: "red",
    },
  },
  approvedRevision: {
    backgroundColor: "#d1ffd1",
    color: "green",
    "& .MuiChip-avatar": {
      color: "green",
    },
  },
  noTooltip: {
    display: "none",
  },
  formcontrol: {
    padding: theme.spacing(2),
  },

  customBadge: {
    backgroundColor: "#ff9100",
    color: "white",
  },
  unforeseenBadge: {
    backgroundColor: "#01579b",
    color: "white",
  },
  unforeseenBadgeReject: {
    backgroundColor: "#c62828",
    color: "white",
  },
  paperAdvance: {
		backgroundColor: "#5555551a",
		boxShadow: theme.shadows[0],
		borderRadius: "10px",
		padding: theme.spacing(1),
	},
});
