import React from "react";
import * as Material from "@material-ui/core";

//imported icons from material

import { useStyles } from "./pow.info.modal.style";

// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import ListSubheader from "@mui/material/ListSubheader";
// import DoneIcon from "@material-ui/icons/Done";

class POWInfoModal extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.onCancelPOW = this.onCancelPOW.bind(this);
  }
  onCancelPOW() {
    this.props.dataHandler({ open: false, data: {} });
  }
  render() {
    const { modalVisible, classes, data } = this.props;
    return (
      <Material.Modal
        aria-labelledby="transition-modal-title"
        onRendered={this.loadEditData}
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalVisible}
        onClose={this.onCancelPOW}
        closeAfterTransition
        disableAutoFocus={true}
        BackdropComponent={Material.Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Material.FormControl className={classes.root}>
          <Material.Fade in={modalVisible}>
            {data ? (
              <div className={classes.paper}>
                <div className="inline">
                  <Material.Typography color="primary" variant="h5">
                    {data.title}
                  </Material.Typography>
                </div>
                <div>
                  <Material.Typography variant="subtitle1">
                    {data.description}
                  </Material.Typography>
                </div>
                <div className={classes.FormContainer}>
                  <div className="title">
                    <Material.Typography variant="subtitle1">
                      Program of Work Information
                    </Material.Typography>
                  </div>
                  <Material.Divider></Material.Divider>
                  <Material.Grid container spacing={3}>
                    <Material.Grid item xs={6}>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Location:
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          {data.location}
                        </Material.Typography>
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Dimension:
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          {data.dimension}
                        </Material.Typography>
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Area:
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          {data.area}
                        </Material.Typography>
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Classification:
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          {data.classification}
                        </Material.Typography>
                      </div>
                    </Material.Grid>
                    <Material.Grid item xs={6}>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Project Duration:
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          {data.project_duration}
                        </Material.Typography>
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Project Cost:{" "}
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          ₱ {data.project_cost}
                        </Material.Typography>
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Fund Source:
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          {data.fund_source}
                        </Material.Typography>
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          To Be Implemented By:
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          {data.implemented_by}
                        </Material.Typography>
                      </div>
                      <div className="inline">
                        <Material.Typography
                          color="primary"
                          variant="subtitle1"
                        >
                          Date Prepared:
                        </Material.Typography>
                        <Material.Typography variant="subtitle1">
                          {data.date_prepared}
                        </Material.Typography>
                      </div>
                    </Material.Grid>
                  </Material.Grid>
                  <form
                    align="right"
                    className={classes}
                    noValidate
                    autoComplete="off"
                  >
                    <Material.Button
                      className={classes.action}
                      // variant="outlined"
                      color="secondary"
                      onClick={this.onCancelPOW}
                    >
                      Close
                    </Material.Button>
                  </form>
                </div>
                {/* <div>				
								<Material.Divider></Material.Divider>
								<div className="title"><Material.Typography color="primary" variant="subtitle1">List</Material.Typography></div>
								<List 
									sx={{
										width: '100%',
										bgcolor: 'background.paper',
										position: 'relative',
										overflow: 'auto',
										maxHeight: 300,
										'& ul': { padding: 0 },
									}}
									// subheader={<li />}
									>
									{[1].map((sectionId) => (
										<li key={`section-${sectionId}`}>
										<ul>
											{/* <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader> */}
                {/* {[0, 1, 2,4,5,6,7,8,9].map((item) => (
											<ListItem key={`item-${sectionId}-${item}`}>
												<ListItemText primary={`Item ${item}`} />
											</ListItem>
											))}
										</ul>
										</li>
									))}
									</List>
							</div> */}
                <div></div>
              </div>
            ) : (
              <div></div>
            )}
          </Material.Fade>
        </Material.FormControl>
      </Material.Modal>
    );
  }
}

export default Material.withStyles(useStyles)(POWInfoModal);
