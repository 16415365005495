import { API } from '../../../helpers/axios.instance';

export const getGeneratedPPMPPriceReqList = (ppmp) => {
    let data = new FormData();
    data.append('ppmp_id',ppmp.ppmp_id);
    return API().post('/PpmpItems/GetAllPerPPMP/index_post',data).then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
}

export const updatePpmpStatus = (ppmp) => {
    let data = new FormData();
    data.append('status', ppmp.status);
    data.append('id', ppmp.ppmp_id);
    return API().post('/Ppmp/ChangeStatus/index_post', data).then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
}

export const getItems = (dataInput) => {
    let data = new FormData();
    data.append("keyword", dataInput.i_keyword);
    data.append("page_no", dataInput.i_page_no);
    data.append("limit", dataInput.i_limit);
    data.append("sort_column", dataInput.i_sort_column);
    data.append("sort_type", dataInput.i_sort_type);
    data.append("filter_unit", dataInput.i_filter_unit);
    data.append("filter_category_name", dataInput.i_filter_category_name);
    data.append("filter_is_approved", dataInput.i_filter_is_approved ? "Y" : "N");
    return API()
        .post("/items/get", data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return { error: true, msg: `${err}` };
        });
};

export const getItemsCategories = () => {
    return API().get('/Items/GetReqItemCategories/').then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
};