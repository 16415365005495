import { API } from '../../helpers/axios.instance';

export const getOfficeActivities = (tableProp) => {
    // console.log('$$tableProp', tableProp);
    let data = new FormData();
    data.append('office_id', tableProp.office_id); //searches
    data.append('year', parseInt(tableProp.year)); //required
    return API().post('/OfficeActivities/Get/index_post', data).then((response) => {
        return response.data;
    }).catch((err) => {
        // console.log('$$err', err);
    });
};

export const getOfficeActivitiesEditModal = (tableProp) => {
    // console.log('$$tableProp', tableProp);
    let data = new FormData();
    data.append('office_id', tableProp.office_id); //searches
    data.append('year', parseInt(tableProp.activity_year)); //required
    return API().post('/OfficeActivities/Get/index_post', data).then((response) => {
        return response.data;
    }).catch((err) => {
        // console.log('$$err', err);
    });
};


export const saveActivity = (value) => {
    let data = new FormData();
    data.append("id", value.id);
    data.append("title", value.title);
    data.append("year", value.year);
    data.append("office_id", value.office_id);
    data.append("office_name", value.office_name);
    data.append("activity_type_id", value.activity_type_id);
    data.append("activity_type_name", value.activity_type_name);
    data.append("date_of_activity_from", value.date_of_activity_from);
    data.append("date_of_activity_to", value.date_of_activity_to);
    data.append("participants", value.participants);
    data.append("no_of_batches", value.no_of_batches);
    data.append("venue", value.venue);
    data.append("type_of_catering_service", value.type_of_catering_service);
    data.append("facilities_equipment_needed", value.facilities_equipment_needed);
    data.append("other_requirements", value.other_requirements);
    data.append("remarks", value.remarks);
    data.append("accommodation", value.accommodation);
    data.append("no_of_rooms", value.no_of_rooms);
    data.append("no_of_meals", value.no_of_meals);
    data.append("no_of_snacks", value.no_of_snacks);
    data.append("no_of_pax", value.no_of_pax);
    data.append("is_active", value.is_active ? 1 : 0);
    data.append("added_by", value.added_by);

    return API().post('OfficeActivities/Save/index_post', data).then((response) => {
        // console.log(response);
        return response;
    }).catch((err) => {
        console.error(err);
    });
}

export const deleteActivity = (activity) => {
    let data = new FormData();
    data.append("id", activity.activity_id);
    return API().post('OfficeActivities/Delete/index_post', data).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err);
    });
}

export const getActivityTypes = () => {
    let data = new FormData();
    return API().post('/OfficeActivities/GetTypes/index_post', data).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log(err);
    });
};

export const getMunicipalities = () => {
    let data = new FormData();
    return API().post('/OfficeActivities/GetMunicipalities/index_post', data).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log(err);
    });
}