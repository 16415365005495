export default (state = {
    signatories:{},
    suppliesData: {},
    suppData:{},
    transactionData: {},
    respoDefaultParams: {},
    ppmpRawSource: [],
    genPPMPDataSource: [],
    signatoriesDatasource: [],
    itemDataSource: [],
    itemFilter: [],
    ppmpDataSource: [],
    respoCenterCodeDatasource: [],
    PPMPRequestItemDatasource: [],
    itemsRequestFilter: [],
    categoryDatasource: [],
    itemsCategoriesDatasource: [],
    requestedItemsDatasource: [],
    ppmpTotal: 0,
    itemTotal: 0,
    itemsRequestTotal: 0,
    reqTotal: 0,
    selectedTransaction: null,
    suppliesModalVisible: false,
    editModalVisible: false,
}, action) => {
    switch (action.type){
        case "SETPPMPDATASOURCE": 
            return state = {
                ...state,
                ppmpDataSource: action.payload.ppmpDataSource,
                ppmpRawSource: action.payload.ppmpRawSource,
                ppmpTotal: action.payload.ppmpTotal,
				ppmpFilter: action.payload.ppmpFilter,
            }
        case "SETGENPPMPDATASOURCE": 
            return state = {
                ...state,
                genPPMPDataSource: action.payload.genPPMPDataSource,
            }
        case "SETSIGNATORIESDATASOURCE": 
            return state = {
                ...state,
                signatoriesDatasource: action.payload.signatoriesDatasource,
			}
		case "SETITEMDATASOURCE": 
            return state = {
                ...state,
				itemDataSource: action.payload.itemDataSource,
				itemTotal: action.payload.itemTotal,
				itemFilter: action.payload.itemFilter,
			}
		case "SETSUPPLIESDISPLAY": 
            return state = {
                ...state,
				suppliesModalVisible: action.payload.modalVisible,
                suppliesData: action.payload.suppliesData,
                transactionData: action.payload.transactionData,
			}
        case "SETEDITDISPLAY": 
            return state = {
                ...state,
				editModalVisible: action.payload.modalVisible,
                suppData: action.payload.suppData,
                transactionData: action.payload.transactionData,
			}
		case "SETRESPODEFAULTVALUE": 
			return state = {
				...state,
				respoDefaultParams: { ...state.respoDefaultParams,
					...action.payload
				}
			}
		case "SETRESPOCENTERDATASOURCE": 
			return state = {
				...state,
				respoCenterCodeDatasource: action.payload.respoCenterData
            }
        case "SETCURRENTSIGNATORIES": 
			return state = {
				...state,
                signatories: {
                    ...state.signatories,
                    ...action.payload
                }
            }
        case "SETPPMPMANUALREQUESTITEMDATASOURCE":
            return state = {
                ...state,
                PPMPRequestItemDatasource: action.payload.PPMPRequestItemDatasource,
                itemsRequestTotal: action.payload.itemsRequestTotal,
                itemsRequestFilter: action.payload.itemsRequestFilter,
                categoryDatasource: action.payload.categoryDatasource
            }
        case "SETITEMSCATEGORIESDATASOURCE":
                return state = {
                ...state,
                itemsCategoriesDatasource: action.payload.itemsCategoriesDatasource,
            }
        case "SETREQUESTEDITEMSDATASOURCE":
                return state = {
                ...state,
                requestedItemsDatasource: action.payload.requestedItemsDatasource,
                reqTotal: action.payload.reqTotal,
            }
        case "DROPPPMPSTATE": 
            return state = {
                signatories:{},
                suppliesData: {},
                suppData:{},
                transactionData: {},
                respoDefaultParams: {},
                ppmpRawSource: [],
                genPPMPDataSource: [],
                signatoriesDatasource: [],
                itemDataSource: [],
                itemFilter: [],
                ppmpDataSource: [],
                respoCenterCodeDatasource: [],
                PPMPRequestItemDatasource: [],
                itemsRequestFilter: [],
                categoryDatasource: [],
                itemsCategoriesDatasource: [],
                requestedItemsDatasource: [],
                ppmpTotal: 0,
                itemTotal: 0,
                itemsRequestTotal: 0,
                reqTotal: 0,
                selectedTransaction: null,
                suppliesModalVisible: false,
                editModalVisible: false,
            }
        default:
            return state;
    }
};
