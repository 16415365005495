import { API } from "../../helpers/axios.instance";

export const getOffices = (value) => {
  let data = new FormData();
  data.append("_user_id", parseInt(value.user_id));
  data.append("_status", value.status);
  // console.log('$$value', value);
  return API()
    .post("/Ppmp/GetOfficesWithPPMP", data)
    .then((res) => {
      // console.log('$$res', res);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getForApprovalItems = (value) => {
  let data = new FormData();
  data.append("_status", value.status);
  data.append("_year", value.year);
  data.append("_office_id", value.office_id);
  data.append("_user_id", value.user_id);
  // console.log('$$value', value);
  return API()
    .post("/Ppmp/GetFilter", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const setItemStatus = (value) => {
  let data = new FormData();
  data.append("_ppmp_items_id", value.ppmp_item_id);
  data.append("_status_code", value.status_code);
  data.append("_remarks", value.remarks);
  data.append("_name", value.name);
  data.append("_description", value.description);

  console.log("$$value", value);

  return API()
    .post("/PpmpItems/ApprovePpmpItems", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getItemsForApprovalPerPPMP = (value) => {
  let data = new FormData();
  data.append("_ppmp_no", value.ppmp_no);
  data.append("_user_id", value.user_id);
  data.append("_status", value.status);

  return API()
    .post("/PpmpItems/GetItemsFilter", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};
