import { API } from "../../../helpers/axios.instance";

export const getRequestItems = (dataInput) => {
    let data = new FormData();
    data.append("keyword", dataInput.keyword);
    data.append("page_no", dataInput.page_no);
    data.append("limit", dataInput.limit);
    data.append("sort_column", dataInput.sort_column);
    data.append("sort_type", dataInput.sort_type);
    data.append("filter_unit", dataInput.filter_unit);
    data.append("filter_is_active", dataInput.ri_filter_active ? "Y" : "N");
    data.append("filter_is_approved", dataInput.ri_filter_is_approved ? "Y" : "N");
    return API()
        .post("/items/GetForApproval", data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return { error: true, msg: `${err}` };
        });
};

export const postRequestedItem = (dataInput) => {
    let data = new FormData();
    data.append("name", dataInput.itemName);
    data.append("description", dataInput.itemDesc);
    data.append("unit", dataInput.itemUnit);
    data.append("category_id", dataInput.itemCategory);
    data.append("is_active", dataInput.itemIsActive ? "Y" : "N");
    data.append("unit_price", dataInput.itemPrice);
    data.append("is_approved", dataInput.itemUnitApproved  ? "Y" : "N");
    data.append("id", dataInput.itemId);
    data.append("user_id", 1);
  //  data.append("user_id", dataInput.itemUserId);
  
    return API()
        .post("/items/Updatemanual", data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => { return { error: true, msg: `${err}` } });
};

export const requestItemNow = (items) => {
    let data = new FormData();
    data.append('name', items.itemName);
    data.append('description', items.itemDesc);
    data.append('unit', items.itemUnit);
    data.append('category_id', items.itemCategory);
    data.append('user_id', items.user_id);
    data.append('price', items.itemPrice);
    data.append('link', items.itemLink);
    data.append('is_approved','W');
    data.append('is_active','Y');
    data.append('office_id', items.office_id);
    return API().post('/Items/InsertManual/index_post', data).then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
};

export const getRequestedItems = (value) => {
    let data = new FormData();
    data.append('filter_office_id',value.office_id);
    data.append('page_no',value.page_no);
    data.append('limit',value.limit);
    return API().post('/Items/GetForApproval/index_post',data).then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
};


export const getItemsCategories = () => {
    return API().get('/Items/GetReqItemCategories/').then((response)=>{
        return response;
    }).catch((err)=>{
        console.error(err);
    });
};