export const mapStateToProps = (state) => {
    return {
		data: state.data,
		suppliers: state.suppliers
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
		setSuppliersDataSource: (res) => {
            dispatch({
                type: "SETSUPPLIERDATASOURCE",
                payload: {
					suppliersDatasource: res.suppliersDatasource,
					suppliersTotal: res.suppliersTotal,
					suppliersFilter: res.supplierFilter,
				}
            })
		},
		displayLoading: (res) => {
            dispatch({
                type: "DISPLAYLOADING",
                payload: {
                    value: res,
                }
            })
		},
		displayAlert: (res) => {
            dispatch({
                type: "DISPLAYALERT",
                payload: {
					visible: res.alertVisible,
					message: res.alertMessage,
					severity: res.alertSeverity,
                }
            })
		},
		
    };
};