export default (state = {
    itemsRequestDatasource: [],
    itemsRequestTotal: 0,
    itemsRequestFilter: [],
    categoryDatasource: [],
    supplierCreate: {},
}, action) => {
    switch (action.type) {
        case "SETREQUESTITEMSDATASOURCE":
            return state = {
                ...state,
                itemsRequestDatasource: action.payload.itemsRequestDatasource,
                itemsRequestTotal: action.payload.itemsRequestTotal,
                itemsRequestFilter: action.payload.itemsRequestFilter,
                categoryDatasource: action.payload.categoryDatasource
            }
        case "DROPREQUESTSTATE":
            return state = {
                itemsRequestDatasource: [],
                itemsRequestTotal: 0,
                itemsRequestFilter: [],
                categoryDatasource: [],
                supplierCreate: {}
            }
        default:
            return state;
    }
};
