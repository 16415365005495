export const useStyles = (theme) => ({
    root: {
		flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(4),
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '35ch',
        },
        '& .buttonContainer': {
            padding: theme.spacing(1),
        },
        '& .checkBoxContainer': {
            padding: theme.spacing(0,1),
        },
        '& .loginbtn': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(45deg, #3c39ee 30%, #0d0647 90%)',
            border: 0,
            borderRadius: 3,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            color: 'white',
            height: 48,
            '&:hover': {
				transform: 'scale(1.02)'
            }
        }
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px',
		padding: theme.spacing(4, 4, 3),
		borderBottom: '2px solid blue',
    },
    logoutContainer: {
        display: 'flex',
    }
})