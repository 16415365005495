import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";

import * as Material from "@material-ui/core";

import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

import Paper from "@material-ui/core/Paper";

import { useStyles } from "./ppmp.list.styles";
import { mapStateToProps, mapDispatchToProps } from "../ppmp.reducer";
import { COMM } from "../../../helpers/common";

import * as PPMPService from "../ppmp.service";

import ViewRequestedItemModal from "../request.item/view.req.item.modal";
import SuppliesModal from "../supplies/ppmp.modal";

class VIEWList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogVisible: false,
			openDialogVisible:false,
			allotment_class: {},
			editMode: false,
			editDataQty: {},
			dataPollout:{},
			totalBudget: 0,

			ppmp_id: 0,
			itemCost: 0,

			Qty: 0,

			ItemName: "",
			ItemDescription: "",
			Mode_Of_Procurement: "",
			UnitCost: 0,
			totalCost: 0,
			qtyJan: 0,
			qtyFeb: 0,
			qtyMar: 0,
			qtyApr: 0,
			qtyMay: 0,
			qtyJun: 0,
			qtyJul: 0,
			qtyAug: 0,
			qtySep: 0,
			qtyOct: 0,
			qtyNov: 0,
			qtyDec: 0,
			id: 0,
			ppmpItemId: 0,
			office_id: 0,
			ppmp_title: "",
			status: "Generated",
			division_name: "",
			ViewReqItemsModalVisible: false,
			deleteDialogVisible: false,
			deteleItem: {},
			page_no: 0,
			limit: 5,
			is_indicative: 0,
			app_status: "Received for Public Bidding",

			total: 0,

			modalVisible: false,
			isPreApproval: false,
			preRemoval: false,
			ppmpEditModalVisible: false,

			//tracking updates
			quarter1_is_checked: false,
			quarter2_is_checked: false,
			quarter3_is_checked: false,
			quarter4_is_checked: false,
			qty_quarter1: 0,
			qty_quarter2: 0,
			qty_quarter3: 0,
			qty_quarter4: 0,
			app_quarter1_id: 0,
			app_quarter2_id: 0,
			app_quarter3_id: 0,
			app_quarter4_id: 0,
			selected_app_quarterly_id: 0,
			selected_quarter: 1,
			tracking: [],

			monthly_closing: [],
     		is_supplimental: false,

			 itemUnit: 0,
			 itemCategory: "",

		};
		this.onClickAddTransactionButton =
			this.onClickAddTransactionButton.bind(this);
		this.onDisplayDialog = this.onDisplayDialog.bind(this);
		this.onCloseDialog = this.onCloseDialog.bind(this);
		this.requestPPMP = this.requestPPMP.bind(this);
		this.onSaveItemQuantity = this.onSaveItemQuantity.bind(this);
		this.onRemoveItem = this.onRemoveItem.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onChangeHandlerPullout = this.onChangeHandlerPullout.bind(this);
		this.formatNumber = this.formatNumber.bind(this);
		this.onHandleDelete = this.onHandleDelete.bind(this);
		this.onHandleEditData = this.onHandleEditData.bind(this);
		this.onHandleOpen = this.onHandleOpen.bind(this);
		this.onHandleEdit = this.onHandleEdit.bind(this);
		this.getTotalBudget = this.getTotalBudget.bind(this);
		this.requestGetRequestedItems = this.requestGetRequestedItems.bind(this);
		this.closeViewReqItemsModal = this.closeViewReqItemsModal.bind(this);
		this.onDisplayRequestedItems = this.onDisplayRequestedItems.bind(this);
		this.requestChangeStatusService =
			this.requestChangeStatusService.bind(this);
		this.onSaveAndGenerateClick = this.onSaveAndGenerateClick.bind(this);
		this.onDisplayPPMPEditDialog = this.onDisplayPPMPEditDialog.bind(this);
		this.onChangeRadioQuarterly = this.onChangeRadioQuarterly.bind(this);
		this.loadTracking = this.loadTracking.bind(this);
		this.onClickChip = this.onClickChip.bind(this);
	}
	componentDidMount() {
		const { history } = this.props;

		sessionService
			.loadSession()
			.then((currentSession) => {
				if (
					currentSession.selectedTransaction === null ||
					currentSession.selectedTransaction === undefined
				) {
					history.push("/");
				} else {
					const data = currentSession.selectedTransaction;
					console.log("$$current", data);
					this.setState(
						{
							...this.state,
							office_id: this.props.data.user.office_id,
							transactionID: data.id,
							transactionNo: data.ppmp_no,
							transactionStatus: data.status,
							transactionDate: data.transdate,
							transactionYear: data.year,
							transactionDivision: data.division_name,
							transactionTitle: data.ppmp_title,
							ppmp_title: data.ppmp_title,
							division_name: data.division_name,
							ppmp_id: data.id,
							isPreApproval: data.status === "Pre-Approval",
							is_indicative: data.is_indicative,
						},
						() => {
							// console.log("$$Viewlist", data.is_indicative);
							this.requestPPMP();
							this.requestGetRequestedItems();
						}
					);
				}
			})
			.catch((err) => {
				this.props.displayAlert({
					visible: true,
					message: err,
					severity: "warning",
				});
			});
	}
	onChangeRadioQuarterly(e) {
		// console.log('$$e', e);
		let radioSelected = e.target.value;
		let quarter1_is_checked = radioSelected == 1;
		let quarter2_is_checked = radioSelected == 2;
		let quarter3_is_checked = radioSelected == 3;
		let quarter4_is_checked = radioSelected == 4;
		let selected_quarter = radioSelected;

		// let
		this.setState(
			{
				...this.state,
				quarter1_is_checked,
				quarter2_is_checked,
				quarter3_is_checked,
				quarter4_is_checked,
				selected_quarter,
			},
			() => {
				this.loadTracking();
			}
		);
	}
	onClickChip(selected_quarter) {
		let radioSelected = selected_quarter;
		let quarter1_is_checked = radioSelected == 1;
		let quarter2_is_checked = radioSelected == 2;
		let quarter3_is_checked = radioSelected == 3;
		let quarter4_is_checked = radioSelected == 4;

		// let
		this.setState(
			{
				...this.state,
				quarter1_is_checked,
				quarter2_is_checked,
				quarter3_is_checked,
				quarter4_is_checked,
				selected_quarter,
			},
			() => {
				this.loadTracking();
			}
		);
	}
	loadTracking() {
		const { displayLoading, displayAlert } = this.props;
		const {
			selected_quarter,
			app_quarter1_id,
			app_quarter2_id,
			app_quarter3_id,
			app_quarter4_id,
			ppmpItemId,
		} = this.state;

		let selected_app_quarterly_id =
			selected_quarter == 1
				? app_quarter1_id
				: selected_quarter == 2
				? app_quarter2_id
				: selected_quarter == 3
				? app_quarter3_id
				: app_quarter4_id;
		this.setState(
			{
				...this.state,
				selected_app_quarterly_id,
			},
			() => {
				//pull some data from db
				displayLoading(true);
				PPMPService.trackingGet({
					app_quarterly_id: selected_app_quarterly_id || 0,
					ppmp_items_id: ppmpItemId,
					quarter: selected_quarter,
				})
					.then((response) => {
						// console.log('$$response', response);
						if (response.error) {
							displayAlert({
								visible: true,
								message: response.msg,
								severity: "warning",
							});
						} else {
							this.setState({
								...this.state,
								tracking: response,
							});
						}
						displayLoading(false);
					})
					.catch((err) => {
						displayLoading(false);
						displayAlert({ visible: true, message: err, severity: "error" });
					});
			}
		);
	}
	componentWillUnmount() {
		const { history, setGenPPMPDataSource } = this.props;
		var targetPath = history.location.pathname;
		if (
			targetPath.indexOf("supplies") === -1 &&
			targetPath.indexOf("viewlist") === -1 &&
			targetPath.indexOf("ppmpform") === -1
		) {
			setGenPPMPDataSource({ genPPMPDataSource: [] });
			sessionService
				.saveSession({ ...this.props.auth, selectedTransaction: null })
				.catch((err) => {
					this.props.displayAlert({
						visible: true,
						message: err,
						severity: "warning",
					});
				});
		}
	}
	requestPPMP() {
		const { displayLoading, displayAlert, setGenPPMPDataSource } = this.props;
		displayLoading(true);
		PPMPService.getGeneratedPPMP({ ppmp_id: this.state.ppmp_id })
			.then((response) => {
				// console.log('$$response2', response);
				if (response.error) {
					displayAlert({
						visible: true,
						message: response.msg,
						severity: "warning",
					});
				} else {
					this.setState({
						...this.state,
						preApproval: parseInt(response.data.result1.needs_validation) > 0,
						preRemoval: parseInt(response.data.result2.needs_removal) > 0,
					});
					setGenPPMPDataSource({ genPPMPDataSource: response.data.result0 });
				}
				this.getTotalBudget();
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				displayAlert({ visible: true, message: err, severity: "error" });
			});

		// for requesting ppmp tracking data on first request
		// let selected_app_quarterly_id = null;
		// this.setState(
		//   {
		//     ...this.state,
		//     selected_app_quarterly_id,
		//   },
		//   () => {
		//     //pull some data from db
		//     displayLoading(true);
		//     PPMPService.trackingGet(selected_app_quarterly_id)
		//       .then((response) => {
		//         // console.log('$$response', response);
		//         if (response.error) {
		//           displayAlert({
		//             visible: true,
		//             message: response.msg,
		//             severity: "warning",
		//           });
		//         } else {
		//           this.setState({
		//             ...this.state,
		//             tracking: response,
		//           });
		//         }
		//         displayLoading(false);
		//       })
		//       .catch((err) => {
		//         displayLoading(false);
		//         displayAlert({ visible: true, message: err, severity: "error" });
		//       });
		//   }
		// );
	}
	getTotalBudget() {
		const { displayAlert } = this.props;
		PPMPService.getTotalBudget({ ppmp_id: this.state.ppmp_id })
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: true,
						message: response.msg,
						severity: "warning",
					});
				} else {
					this.setState({
						...this.state,
						totalBudget: response.result0[0].tot,
					});
				}
			})
			.catch((err) => {
				displayAlert({ visible: true, message: err, severity: "error" });
			});
	}
	onChangeHandler(e) {
		this.setState({ [e.target.name]: Number(e.target.value) });
	}
	onDisplayDialog(value) {
		this.setState({ ...this.state, dialogVisible: value });
	}
	onCloseDialog (value) {
		this.setState({ ...this.state, openDialogVisible:value});
	}
	onSaveItemQuantity(value) {
		const {
			qtyJan,
			qtyFeb,
			qtyMar,
			qtyApr,
			qtyMay,
			qtyJun,
			qtyJul,
			qtyAug,
			qtySep,
			qtyOct,
			qtyNov,
			qtyDec,
			itemCost,
		} = this.state;
		const { displayAlert } = this.props;
		const Qty =
			Number(qtyJan) +
			Number(qtyFeb) +
			Number(qtyMar) +
			Number(qtyApr) +
			Number(qtyMay) +
			Number(qtyJun) +
			Number(qtyJul) +
			Number(qtyAug) +
			Number(qtySep) +
			Number(qtyOct) +
			Number(qtyNov) +
			Number(qtyDec);
		const totalCost = Number(Qty) * Number(itemCost);
		PPMPService.editQtyPerMonth({ ...this.state, Qty, totalCost })
			.then((res) => {
				displayAlert({
					visible: true,
					message: res.data.msg,
					severity: "success",
				});
				this.onDisplayDialog(false);
				this.requestPPMP();
			})
			.catch((err) => {
				displayAlert({ visible: true, message: err, severity: "error" });
			});
	}
	onRemoveItem(row) {
		const { displayAlert } = this.props;
		PPMPService.deletePpmpItem(row)
			.then((res) => {
				displayAlert({
					visible: true,
					message: res?.error ? "Error deleting item!" : "Success!",
					severity: res?.error ? "error" : "success",
				});
				this.requestPPMP();
				this.onDisplayDialog(false);
				this.onHandleDelete(false, {});
			})
			.catch((err) => {
				displayAlert({ visible: true, message: err, severity: "error" });
			});
	}
	formatNumber(num) {
		return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";
	}
	onHandleDelete(visible, row) {
		this.setState({
			...this.state,
			deleteDialogVisible: visible,
			deteleItem: row,
		});
	}
	onHandleEditData(row) {
		this.setState({ dialogVisible: true, editDataQty: row });
		this.onHandleEdit(row);
	}
	onHandleOpen(row) {
		this.setState({ openDialogVisible:true, dataPollout:row});
		this.onHandleEdit(row);
	}
	onHandleEdit(row) {
		// console.log('$$row1', row);
		this.setState({
			ppmpItemId: row.ppmp_item_id,
			ItemName: row.name,
			ItemDescription: row.description,
			UnitCost: parseFloat(Number(row.unit_cost)).toFixed(2),
			qty_quarter1: row.qty_1st_quarter,
			qty_quarter2: row.qty_2nd_quarter,
			qty_quarter3: row.qty_3rd_quarter,
			qty_quarter4: row.qty_4th_quarter,
			app_quarter1_id: row.app_id_1st_quarter,
			app_quarter2_id: row.app_id_2nd_quarter,
			app_quarter3_id: row.app_id_3rd_quarter,
			app_quarter4_id: row.app_id_4th_quarter,
			itemUnit: row.unit,
			itemCategory:row.category_name,
			qtyJan: row.qty_jan,
			qtyFeb: row.qty_feb,
			qtyMar: row.qty_mar,
			qtyApr: row.qty_apr,
			qtyMay: row.qty_may,
			qtyJun: row.qty_jun,
			qtyJul: row.qty_jul,
			qtyAug: row.qty_aug,
			qtySep: row.qty_sep,
			qtyOct: row.qty_oct,
			qtyNov: row.qty_nov,
			qtyDec: row.qty_dec,
		});
	}

	requestGetRequestedItems() {
		PPMPService.getRequestedItems({ ...this.state })
			.then((response) => {
				// console.log('$$response', response);
				this.props.setRequestedItemsDataSource({
					requestedItemsDatasource: response.data.result.result0,
					total: parseInt(response.data.result.result1[0].ROW_COUNT),
				});
			})
			.catch((err) => {
				this.props.displayAlert({
					visible: true,
					message: err,
					severity: "error",
				});
			});
	}
	closeViewReqItemsModal() {
		this.setState({ ViewReqItemsModalVisible: false });
	}
	onDisplayRequestedItems() {
		this.setState({ ViewReqItemsModalVisible: true });
	}
	requestChangeStatusService(callback) {
		const { displayAlert } = this.props;
		PPMPService.updatePpmpStatus({ ...this.state })
			.then((res) => {
				displayAlert({
					visible: true,
					message: res.data.msg,
					severity: "success",
				});
				callback();
			})
			.catch((err) => {
				this.props.displayAlert({
					visible: true,
					message: err,
					severity: "error",
				});
			});
	}
	onClickAddTransactionButton() {
		const { displayLoading, displayAlert, history } = this.props;
		displayLoading(true);
		PPMPService.editPPMPTransaction({
			ppmp_id: this.state.transactionID,
			division_name: this.state.division_name,
			ppmp_title: this.state.ppmp_title,
		})
			.then((res) => {
				if (res.error) {
					displayAlert({
						visible: true,
						message: `${res.msg}`,
						severity: "error",
					});
				} else {
					history.push("/");
					displayAlert({
						visible: true,
						message: `${res.msg}`,
						severity: "success",
					});
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				this.onDisplayDialog(false);
				displayAlert({ visible: true, message: `${err}`, severity: "warning" });
			});
	}
	onCloseSigModal() {
		this.setState({ ...this.state, modalVisible: false });
	}
	onDisplayPPMPEditDialog(value) {
		this.setState({ ...this.state, ppmpEditModalVisible: value });
	}
	onSaveAndGenerateClick() {
		this.setState({ ...this.state, status: "Generated" }, () =>
			this.requestChangeStatusService(() => {
				this.props.history.push("/ppmpform");
			})
		);
	}
	onSendForApprovalClick() {
		this.setState({ ...this.state, status: "Pre-Approval" }, () =>
			this.requestChangeStatusService(() => {
				this.props.history.push("/");
			})
		);
	}
	onChangeHandlerPullout(e) {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === "" || regex.test(e.target.value)) {
			this.setState({
				[e.target.name]: e.target.value === "" ? 0 : e.target.value,
			});
		}
	}
	render() {
		const {
			transactionNo,
			transactionStatus,
			transactionTitle,
			transactionDate,
			transactionYear,
			transactionDivision,
			totalBudget,
			deteleItem,
			deleteDialogVisible,
			isPreApproval,
			ppmpEditModalVisible,
			tracking,
			isIndicative,
		} = this.state;
		const { classes, ppmp } = this.props;
		console.log("$$ppmp",ppmp);
		const { editDataQty, dataPollout } = this.state;
		return (
			<div className={classes.root}>
				<Material.Paper className={classes.transactionHeader}>
					<Material.Grid container spacing={3}>
						<Material.Grid item xs={9}>
							<div className="inline">
								<div className={classes.statusColor}>
									<Material.Chip
										className={transactionStatus}
										label={COMM.capitalizeFirstLetter(transactionStatus)}
									/>
								</div>
								<Material.Typography
									className="flex"
									color="primary"
									variant="h5"
								>
									{transactionNo}
								</Material.Typography>
								<Material.Typography variant="h6">
									({transactionYear}) {transactionTitle}
								</Material.Typography>
							</div>
						</Material.Grid>
						<Material.Grid item xs={3}>
							<Material.Typography variant="subtitle2" className="pull-right">
								{isIndicative > 0 ? (
									<Material.Chip
										label="INDICATIVE"
										color="primary"
										variant="outlined"
										size="small"
									></Material.Chip>
								) : (
									""
								)}{" "}
								{COMM.DdMmmYyyyHhMm(transactionDate)}
							</Material.Typography>
						</Material.Grid>
					</Material.Grid>
					<Material.Divider className="div-margin" />
					<Material.Grid container spacing={3}>
						<Material.Grid item xs={9}>
							<Material.Typography variant="caption">
								PPMP Transaction No
							</Material.Typography>
							<Material.Typography variant="subtitle2">
								{" "}
								Division: {transactionDivision}
							</Material.Typography>
						</Material.Grid>
						<Material.Grid item xs={3}></Material.Grid>
					</Material.Grid>
				</Material.Paper>
				<Material.Paper className="container" elevation={3}>
					<Material.Grid container spacing={2}>
						<Material.Grid item xs={6}></Material.Grid>
					</Material.Grid>
					<Material.Divider></Material.Divider>
					<Material.TableContainer component={Paper}>
						<Material.Table
							className={classes.table}
							aria-label="generated ppmp table"
						>
							<Material.TableBody>
								{ppmp.genPPMPDataSource.map((element, index) => {
									return (
										<React.Fragment key={`class-${index}`}>
											<Material.TableRow key={`class-${index}`}>
												<Material.TableCell colSpan={20} className="blue">
													<Material.Typography variant="h6">
														{" "}
														<b> {element.allotment_class}</b>
													</Material.Typography>
												</Material.TableCell>
											</Material.TableRow>
											{element.funds.map((fund_item, fund_index) => {
												return (
													<React.Fragment key={`fund-${fund_index}`}>
														<Material.TableRow>
															<Material.TableCell className="lightblue">
																{" "}
															</Material.TableCell>
															<Material.TableCell
																colSpan={20}
																className="lightblue"
															>
																<b>{fund_item["fund_source"]}</b>
															</Material.TableCell>
														</Material.TableRow>
														{fund_item.ppa_list.map(
															(respo_item, respo_index) => {
																return (
																	<React.Fragment
																		key={`respo-item-${respo_index}`}
																	>
																		<Material.TableRow>
																			<Material.TableCell className="lightgrey">
																				{" "}
																			</Material.TableCell>
																			<Material.TableCell
																				colSpan={20}
																				className="lightgrey"
																			>
																				<b>{respo_item.ppa_respo}</b>
																			</Material.TableCell>
																		</Material.TableRow>
																		<Material.TableRow>
																			<Material.TableCell></Material.TableCell>
																			<Material.TableCell align="center">
																				<b>Item Name</b>
																			</Material.TableCell>
																			<Material.TableCell align="center">
																				<b>Quantity</b>
																			</Material.TableCell>
																			<Material.TableCell align="center">
																				<b>Unit Cost</b>
																			</Material.TableCell>
																			<Material.TableCell align="center">
																				<b>Total Cost</b>
																			</Material.TableCell>
																			<Material.TableCell align="center">
																				<b>Mode of Procurement</b>
																			</Material.TableCell>
																			<Material.TableCell
																				align="center"
																				className="lightgrey"
																			>
																				<b>Actions</b>
																			</Material.TableCell>
																		</Material.TableRow>
																		{respo_item.items
																			.slice()
																			.sort((a, b) =>
																				a.name
																					.toLowerCase()
																					.localeCompare(b.name.toLowerCase())
																			)
																			.map((items, items_index) => {
																				console.log("$$item", items);
																				return (
																					<Material.TableRow
																						key={`items-data-sfsd-${items_index}`}
																						className={classes.tableRow}
																					>
																						<Material.TableCell></Material.TableCell>
																						<Material.TableCell align="left">
																							<Material.Tooltip
																								title={
																									items.is_approved === "N"
																										? items.reject_remarks
																										: ""
																								}
																								arrow
																								placement="top"
																							>
																								<b>
																									<Material.Chip
																										className={
																											items.is_approved === "Y"
																												? classes.approved
																												: items.is_approved ===
																												  "W"
																												? classes.pending
																												: classes.rejected
																										}
																										size="small"
																										avatar={
																											items.is_approved ===
																											"Y" ? (
																												<CheckCircleIcon />
																											) : items.is_approved ===
																											  "W" ? (
																												<HelpIcon />
																											) : (
																												<ThumbDownIcon />
																											)
																										}
																										label={items.name}
																									/>
																								</b>
																							</Material.Tooltip>{" "}
																							-{" "}
																							{items.remark ? (
																								<b>{items.remark}</b>
																							) : (
																								""
																							)}{" "}
																							{items.description}
																							{/* activity_name */}
																							{items.prefered_desc ? (
																								<b>- {items.prefered_desc}</b>
																							) : (
																								""
																							)}
																							<br />
																							{items.catering_office_activity_id >
																							0 ? (
																								<Material.Chip
																									label={`Name of Actvity: ${items.activity_name}`}
																									color="primary"
																									variant="outlined"
																								/>
																							) : (
																								""
																							)}
																						</Material.TableCell>
																						<Material.TableCell align="center">
																							{items.qty}
																						</Material.TableCell>
																						<Material.TableCell align="center">
																							<Material.Chip
																								className={
																									parseFloat(items.unit_cost) >
																									0
																										? classes.approved
																										: classes.pending
																								}
																								size="small"
																								avatar={
																									parseFloat(
																										items.unit_cost
																									) === 0 ? (
																										<HelpIcon />
																									) : (
																										<CheckCircleIcon />
																									)
																								}
																								label={this.formatNumber(
																									items.unit_cost
																								)}
																							/>
																						</Material.TableCell>
																						<Material.TableCell align="center">
																							{this.formatNumber(
																								items.total_cost
																							)}
																						</Material.TableCell>
																						<Material.TableCell align="center">
																							{items.mode_of_procurement}
																						</Material.TableCell>
																						<Material.TableCell
																							align="center"
																							className="lightgrey"
																						>
																							<div className="inline">
																								<Material.Tooltip title="Item Tracking." >
																								<Material.IconButton
																									disabled={isPreApproval}
																									className="animate"
																									color="primary"
																									onClick={() => {
																										this.onHandleEditData(
																											items
																										);
																									}}
																								>
																									<ShareLocationIcon />
																								</Material.IconButton>
																								</Material.Tooltip>
																								
																								<Material.Tooltip title= "Request item to pullout.">
																								<Material.IconButton
																									disabled={isPreApproval}
																									className="animate"
																									color="primary"
																									onClick={() => {
																										this.onHandleOpen(
																											items
																										);
																									}}
																								>
																									<RemoveShoppingCartIcon />
																								</Material.IconButton>
																								</Material.Tooltip>
																							</div>
																						</Material.TableCell>
																					</Material.TableRow>
																				);
																			})}
																		<Material.TableRow>
																			<Material.TableCell
																				colSpan={3}
																			></Material.TableCell>
																			<Material.TableCell
																				colSpan={3}
																				align="right"
																			>
																				<b>SUBTOTAL</b>
																			</Material.TableCell>
																			<Material.TableCell
																				colSpan={3}
																				align="right"
																			>
																				<b>
																					{this.formatNumber(
																						respo_item.items
																							.map((elt) =>
																								!isNaN(elt.total_cost) &&
																								elt.total_cost
																									.toString()
																									.indexOf(".") !== -1
																									? parseFloat(elt.total_cost)
																									: 0
																							)
																							.reduce((a, b) => a + b)
																							.toFixed(2)
																					)}
																				</b>
																			</Material.TableCell>
																		</Material.TableRow>
																	</React.Fragment>
																);
															}
														)}
													</React.Fragment>
												);
											})}
										</React.Fragment>
									);
								})}
								{ppmp.genPPMPDataSource.length === 0 ? (
									<Material.TableRow>
										<Material.TableCell colSpan={20} className="emptyCont">
											<div className="empty">
												<div className="emptyBorder">
													No Supplies.
													<Material.Link
														href="#"
														onClick={(e) => {
															e.preventDefault();
															this.props.history.push("/ppmpsupplies");
														}}
													>
														{" "}
														Add now
													</Material.Link>
												</div>
											</div>
										</Material.TableCell>
									</Material.TableRow>
								) : (
									<Material.TableRow />
								)}
								<Material.TableRow className="yellow">
									<Material.TableCell colSpan={3}></Material.TableCell>
									<Material.TableCell colSpan={3} align="right">
										<b>GRAND TOTAL</b>
									</Material.TableCell>
									<Material.TableCell colSpan={3} align="right">
										<b>{totalBudget ? this.formatNumber(totalBudget) : 0}</b>
									</Material.TableCell>
								</Material.TableRow>
							</Material.TableBody>
						</Material.Table>
					</Material.TableContainer>

					{/* Dialog for Tracking PPMP Status | Edited 03/20/23*/}
					<Material.Dialog
						maxWidth={"lg"}
						open={this.state.dialogVisible}
						onClose={() => {
							this.onDisplayDialog(false);
							this.setState({
								selected_app_quarterly_id: null, // Reset selected_app_quarterly_id
								tracking: [], // Reset tracking
								quarter1_is_checked: false, // Reset quarter1_is_checked
								quarter2_is_checked: false, // Reset quarter2_is_checked
								quarter3_is_checked: false, // Reset quarter3_is_checked
								quarter4_is_checked: false, // Reset quarter4_is_checked
								// ppmpItemId:
							});
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						// onRendered={this.onHandleEdit}
						editdata={editDataQty}
					>
						<Material.DialogTitle id="alert-dialog-title" variant="h3">
							{/* <ContentPasteSearchRoundedIcon /> PPMP Tracking */}
							PPMP Item Tracking
						</Material.DialogTitle>
						<Material.DialogContent>
							<Material.FormControl>
								{/* <Material.FormLabel id="demo-row-radio-buttons-group-label">
									Title
								</Material.FormLabel> */}
								<Material.Typography
									className="flex"
									color="primary"
									variant="h6"
								>
									{this.state.ItemName}
								</Material.Typography>
								<Material.Typography
									variant="body2"
									className={classes.formcontrol}
								>
									{this.state.ItemDescription}
								</Material.Typography>
							</Material.FormControl>
							<Material.Paper className={classes.paperAdvance}>
								<Material.Grid
									container
									spacing={2}
									rowSpacing={4}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									direction="row"
									justifyContent="space-evenly"
									alignItems="flex-start"
								>
									
                  			<Material.Grid item xs={12}>
										<Material.Typography variant="button">
											App Quarter
										</Material.Typography>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.Chip
											label={`1st Quarter Quantity: ${this.state.qty_quarter1}`}
											variant={
												this.state.app_quarter1_id > 0 ? "default" : "outlined"
											}
											color={
												this.state.app_quarter1_id > 0 ? "primary" : "secondary"
											}
											onClick={() => {
												if (this.state.app_quarter1_id > 0) this.onClickChip(1);
											}}
											clickable={this.state.app_quarter1_id > 0}
										/>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.Chip
											label={`2nd Quarter Quantity: ${this.state.qty_quarter2}`}
											variant={
												this.state.app_quarter2_id > 0 ? "default" : "outlined"
											}
											color={
												this.state.app_quarter2_id > 0 ? "primary" : "secondary"
											}
											onClick={() => {
												if (this.state.app_quarter2_id > 0) this.onClickChip(2);
											}}
											clickable={this.state.app_quarter2_id > 0}
										/>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.Chip
											label={`3rd Quarter Quantity: ${this.state.qty_quarter3}`}
											variant={
												this.state.app_quarter3_id > 0 ? "default" : "outlined"
											}
											color={
												this.state.app_quarter3_id > 0 ? "primary" : "secondary"
											}
											onClick={() => {
												if (this.state.app_quarter3_id > 0) this.onClickChip(3);
											}}
											clickable={this.state.app_quarter3_id > 0}
										/>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.Chip
											label={`4th Quarter Quantity: ${this.state.qty_quarter4}`}
											variant={
												this.state.app_quarter4_id > 0 ? "default" : "outlined"
											}
											color={
												this.state.app_quarter4_id > 0 ? "primary" : "secondary"
											}
											onClick={() => {
												if (this.state.app_quarter4_id > 0) this.onClickChip(4);
											}}
											clickable={this.state.app_quarter4_id > 0}
										/>
									</Material.Grid>
									<Material.Grid item xs={12}>
										<Material.Divider />
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.Typography variant="button">
											PR Number: <Material.Chip label={tracking[0]?.pr} />
										</Material.Typography>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.Typography variant="button">
											P0 Number: <Material.Chip label={tracking[0]?.po} />
										</Material.Typography>
									</Material.Grid>
									<Material.Grid item xs={3}>
										<Material.Typography variant="button">
											Item Remarks: {" "}
									</Material.Typography>
									<Material.Typography variant="caption">{tracking[0]?.item_remarks}</Material.Typography>
													</Material.Grid>
													<Material.Grid item xs={3}>
														<Material.Typography variant="button">
															Winning Bidder: {" "}
									</Material.Typography>
									<Material.Typography variant="caption">{tracking[0]?.winning_bidder}</Material.Typography>
													</Material.Grid>
								</Material.Grid>
							</Material.Paper>

							<div className={classes.formcontrol}>
								<Material.Typography
									variant="overline"
									sx={{ fontStyle: "italic" }}
									color="secondary"
								>
									Note: The first data below is the most recent tracking data.
								</Material.Typography>
							</div>
							{/* <Material.FormControl className={classes.formcontrol}>
								<Material.FormLabel id="demo-row-radio-buttons-group-label">
									APP Quarter
								</Material.FormLabel>
								<Material.RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									onChange={this.onChangeRadioQuarterly}
								>
									<Material.FormControlLabel
										value={1}
										control={<Material.Radio />}
										label="1st Quarter"
										checked={this.state.quarter1_is_checked}
									/>
									<Material.FormControlLabel
										value={2}
										control={<Material.Radio />}
										label="2nd Quarter"
										checked={this.state.quarter2_is_checked}
									/>
									<Material.FormControlLabel
										value={3}
										control={<Material.Radio />}
										label="3rd Quarter"
										checked={this.state.quarter3_is_checked}
									/>
									<Material.FormControlLabel
										value={4}
										control={<Material.Radio />}
										label="4th Quarter"
										checked={this.state.quarter4_is_checked}
									/>
								</Material.RadioGroup>
							</Material.FormControl> */}

							<Material.Paper className={classes.dialogHeader}>
								<div className={classes.root}>
									{this.state.selected_app_quarterly_id > 0 ? (
										<Material.TableContainer>
											<Material.Table
												sx={{ minWidth: 650 }}
												aria-label="simple table"
											>
												<Material.TableHead>
													<Material.TableRow>
														<Material.TableCell>Status</Material.TableCell>
														<Material.TableCell align="center">
															Division
														</Material.TableCell>
														<Material.TableCell align="center">
															PR Number
														</Material.TableCell>
														<Material.TableCell align="center">
															PR Remarks
														</Material.TableCell>
														<Material.TableCell align="center">
															Transdate
														</Material.TableCell>
													</Material.TableRow>
												</Material.TableHead>
												<Material.TableBody>
                         								{tracking.map((row) => {
														// Convert the string date to a JavaScript Date object
														let date = new Date(row.transdate);

														// Format the date into a more readable string format (e.g., MMMM DD, YYYY HH:MM:SS)
														let options = {
															year: "numeric",
															month: "long",
															day: "numeric",
															hour: "2-digit",
															minute: "2-digit",
															second: "2-digit",
														};
														let formattedDate = date.toLocaleDateString(
															"en-US",
															options
														);
														return (
															<Material.TableRow
																key={row.name}
																sx={{
																	"&:last-child td, &:last-child th": {
																		border: 0,
																	},
																}}
															>
																<Material.TableCell component="th" scope="row">
																	{row.name}
																</Material.TableCell>
																<Material.TableCell align="center">
																	{row.division}
																</Material.TableCell>
																<Material.TableCell align="center">
																	<Material.Chip label={row.pr} />
																</Material.TableCell>
																<Material.TableCell align="center">
																	{row.pr_remarks}
																</Material.TableCell>
																<Material.TableCell align="center">
																	{formattedDate}
																</Material.TableCell>
															</Material.TableRow>
														);
													})}
												</Material.TableBody>
											</Material.Table>
										</Material.TableContainer>
									) : (
										<Material.Typography
											variant="overline"
											display="block"
											gutterBottom
										>
											NO TRACKING DATA
										</Material.Typography>
									)}
								</div>
							</Material.Paper>
						</Material.DialogContent>
					</Material.Dialog>
					{/* End of Tracking Dialog */}
					{/* start of dialog for pollout */}
					<Material.Dialog
						open={this.state.openDialogVisible}
						onClose={() => {
							this.onCloseDialog(false);
						}}
						PaperProps={{
							style: {
								maxWidth: '700px', // Set your max width
								maxHeight: '700px', // Set your max height
							},
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					> 
						<div className="title">
						<Material.DialogTitle color="primary" variant="h3">
							Request for Item Pullout
						</Material.DialogTitle>
						</div>
						<Material.DialogContent>
						<div className="title">
						<Material.Typography  color="primary" variant="h6">
						{this.state.ItemName}
						</Material.Typography >
						</div>
						<Material.Grid>
						<div className="inline">
							<Material.Typography  variant="subtitle1">
									{this.state.ItemDescription}
							</Material.Typography>
						</div>
						</Material.Grid>
						<Material.Grid container spacing={3}>
						<Material.Grid item xs={12}></Material.Grid>
						<Material.Grid item xs={6}>
							<div className="inline">
							<Material.Typography variant="subtitle1">
								Unit: {"" } {this.state.itemUnit}
							</Material.Typography>
							</div>
							<div className="inline">
							<Material.Typography variant="subtitle1">
								Price: {""}Php {this.state.UnitCost}
							</Material.Typography>
							</div>
						</Material.Grid>
						<Material.Grid item xs={6}>
							<div className="inline">
							<Material.Typography variant="subtitle1">
								Category: {""}{this.state.itemCategory}
							</Material.Typography>
							</div>
						</Material.Grid>
						
						<Material.Grid item xs={12}></Material.Grid>
						</Material.Grid>
						<Material.Divider></Material.Divider>
						<div className="title">
						<Material.Typography  variant="h6">
						  Target Month of Pullout
						</Material.Typography >
						</div>
						<Material.Grid container spacing={3} alignItems="center">
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							disabled={dataPollout._app_quarterly_jan > 0}
							id="qtyJan"
							onChange={this.onChangeHandlerPullout}
							name="qtyJan"
							label="January"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyJan}
							helperText={dataPollout.unit}
							
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							disabled={dataPollout._app_quarterly_feb > 0}
							id="qtyFeb"
							onChange={this.onChangeHandlerPullout}
							name="qtyFeb"
							label="February"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyFeb}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtyMar"
							onChange={this.onChangeHandlerPullout}
							name="qtyMar"
							label="March"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyMar}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtyApr"
							onChange={this.onChangeHandlerPullout}
							name="qtyApr"
							label="April"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyApr}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtyMay"
							onChange={this.onChangeHandlerPullout}
							name="qtyMay"
							label="May"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyMay}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtyJun"
							onChange={this.onChangeHandlerPullout}
							name="qtyJun"
							label="June"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyJun}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtyJul"
							onChange={this.onChangeHandlerPullout}
							name="qtyJul"
							label="July"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyJul}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtyAug"
							onChange={this.onChangeHandlerPullout}
							name="qtyAug"
							label="August"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyAug}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtySep"
							onChange={this.onChangeHandlerPullout}
							name="qtySep"
							label="September"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtySep}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtyOct"
							onChange={this.onChangeHandlerPullout}
							name="qtyOct"
							label="October"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyOct}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
						id="qtyNov"
							onChange={this.onChangeHandlerPullout}
							name="qtyNov"
							label="November"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyNov}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						<Material.Grid item xs={4}>
							<Material.TextField
							className={classes.textField}
							id="qtyDec"
							onChange={this.onChangeHandlerPullout}
							name="qtyDec"
							label="December"
							type="number"
							InputLabelProps={{ shrink: true }}
							value={this.state.qtyDec}
							helperText={dataPollout.unit}
							/>
						</Material.Grid>
						</Material.Grid>
						</Material.DialogContent>
					<Material.DialogActions>
					<Material.Button
						onClick={() => this.onCloseDialog(false)}
						color="secondary"
					>
						Cancel
					</Material.Button>
					<Material.Button
						// onClick={() => {
						// this.state.onConfirmSave(); 
						// this.setState({ confirmationDialogVisible: false });
						// }}
						color="primary"
					>
						Save
					</Material.Button>
					</Material.DialogActions>
					</Material.Dialog>
					{/* end of target month of pullout */}
					<Material.Dialog
						open={deleteDialogVisible}
						onClose={() => this.onHandleDelete(false, {})}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<Material.DialogTitle id="alert-dialog-title">
							Delete {deteleItem.description}?
						</Material.DialogTitle>
						<Material.DialogContent>
							<Material.DialogContentText id="alert-dialog-description">
								Are you sure you want to delete {deteleItem.pr_qty}{" "}
								{deteleItem.unit} of {deteleItem.description}?
							</Material.DialogContentText>
						</Material.DialogContent>
						<Material.DialogActions>
							<Material.Button
								onClick={() => this.onHandleDelete(false, {})}
								color="secondary"
							>
								Cancel
							</Material.Button>
							<Material.Button
								onClick={() => this.onRemoveItem(deteleItem)}
								color="primary"
								autoFocus
							>
								Continue
							</Material.Button>
						</Material.DialogActions>
					</Material.Dialog>
					<ViewRequestedItemModal
						ViewReqItemsModalVisible={this.state.ViewReqItemsModalVisible}
						closeViewReqItemsModal={this.closeViewReqItemsModal}
						office_id={this.state.office_id}
					/>
					<SuppliesModal requestGeneratedPPMP={this.requestPPMP} />
				</Material.Paper>
				{/* PPMP Dialog */}
				<Material.Dialog
					open={ppmpEditModalVisible}
					onClose={() => {
						this.onDisplayPPMPEditDialog(false);
					}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<Material.DialogTitle id="alert-dialog-title">
						Edit PPMP?
					</Material.DialogTitle>
					<Material.DialogContent>
						<div>
							<Material.TextField
								id="ppmp_title"
								label="Title"
								type="text"
								onChange={(e) => {
									this.setState({ ...this.state, ppmp_title: e.target.value });
								}}
								value={this.state.ppmp_title}
								className={classes.textField_input}
								margin="dense"
								fullWidth
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div>
							<Material.TextField
								id="division"
								label="Division/Section/Unit"
								type="text"
								onChange={(e) => {
									this.setState({
										...this.state,
										division_name: e.target.value,
									});
								}}
								value={this.state.division_name}
								className={classes.textField_input}
								margin="dense"
								fullWidth
								InputLabelProps={{ shrink: true }}
							/>
						</div>
					</Material.DialogContent>
					<Material.DialogActions>
						<div>
							<Material.Button
								onClick={() => {
									this.onDisplayDialog(false);
								}}
								color="secondary"
							>
								Cancel
							</Material.Button>
							<Material.Button
								onClick={this.onClickAddTransactionButton}
								color="primary"
								autoFocus
							>
								Edit
							</Material.Button>
						</div>
					</Material.DialogActions>
				</Material.Dialog>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Material.withStyles(useStyles)(VIEWList));
