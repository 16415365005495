import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";
import * as Material from "@material-ui/core";
import { mapStateToProps, mapDispatchToProps } from "../ppmp/ppmp.reducer";
import { useStyles } from "./unforeseen.ppmp.styles";
import * as UnforeseenPPMPItemsApproval from "./unforeseen.ppmp.service";

// Imported icons
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import ListIcon from "@material-ui/icons/List";

export class UnforeseenPPMP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: 0,
      year_range: [],
      office_id: 0,
      offices: [],
      for_approval_ppmp: [],
      remarks: "",
      filtered_status: 0,

      res_no_data: 0,
    };

    this.getPPMPToApprovePerOffice = this.getPPMPToApprovePerOffice.bind(this);
    this.getOffices = this.getOffices.bind(this);
    this.onSelectYearHandler = this.onSelectYearHandler.bind(this);
    this.onSelectOfficeHandler = this.onSelectOfficeHandler.bind(this);
    this.onPPMPListDisplay = this.onPPMPListDisplay.bind(this);
  }
  componentDidMount() {
    const { data } = this.props;
    let currDate = new Date();
    let currYear = parseInt(currDate.getFullYear());

    //get year +1 & -2
    let yearRange = [currYear - 2, currYear - 1, currYear, currYear + 1];
    let statuses = ["Select All", "For Approval", "Approved", "Rejected"];

    this.setState(
      {
        ...this.state,
        year: currYear,
        year_range: yearRange,
        // office_id: parseInt(data.user.office_id),
      },
      () => {
        this.getOffices();
        this.getPPMPToApprovePerOffice();
      }
    );
  }

  onPPMPListDisplay(data) {
    sessionService
      .saveSession({ ...this.props.auth, selectedTransaction: data })
      .then(() => {
        // console.log("$$im data-passing2!", this.state.filtered_status);
        this.props.history.push({
          pathname: "/unforeseenitemlistforapproval",
          state: {
            filtered_status: this.state.filtered_status,
            for_approval: this.state.filtered_status === 0,
          },
        });
      })
      .catch((err) => {
        this.props.displayAlert({
          visible: true,
          message: err,
          severity: "warning",
        });
      });
  }

  getPPMPToApprovePerOffice() {
    const { displayLoading, displayAlert, data } = this.props;
    displayLoading(true);
    this.setState({
      ...this.state,
      for_approval_ppmp: [],
    });
    UnforeseenPPMPItemsApproval.getUnforeseenPPMP({
      ...this.state,
      status: this.state.filtered_status,
      year: this.state.year,
      office_id: this.state.office_id,
      user_id: data.user.id,
    })
      .then((res) => {
        if (res.error) {
          displayAlert({
            alertVisible: true,
            alertMessage: "No PPMP found!",
            alertSeverity: "warning",
          });
        } else {
          if (res === "No data found") {
            displayAlert({
              alertVisible: true,
              alertMessage: "No PPMP found!",
              alertSeverity: "warning",
            });
            this.setState({
              ...this.state,
              res_no_data: 1,
              for_approval_ppmp: [],
            });
          } else {
            this.setState({
              ...this.state,
              res_no_data: 0,
              for_approval_ppmp: res,
            });
            this.getOffices();
          }
        }
        displayLoading(false);
      })
      .catch((err) => {
        displayLoading(false);
        displayAlert({
          alertVisible: true,
          alertMessage: err,
          alertSeverity: "error",
        });
      });
  }

  getOffices() {
    const { displayLoading, displayAlert, data } = this.props;
    displayLoading(true);
    UnforeseenPPMPItemsApproval.getOfficesWithPPMPUnforeseen({
      user_id: data.user.id,
      data_to_pass: this.state.filtered_status,
    })
      .then((res) => {
        if (res.error) {
          displayAlert({
            visible: true,
            message: res.message,
            severity: "error",
          });
        } else {
          if (res === "No data found") {
            this.setState({
              ...this.state,
              offices: [],
            });
          } else {
            this.setState({
              ...this.state,
              offices: res,
            });
          }
        }
        displayLoading(false);
      })
      .catch((err) => {
        // displayAlert({ visible: true, message: err, severity: "warning" });
        console.error("$$error", err);
        displayLoading(false);
      });
  }

  onSelectYearHandler(e) {
    this.setState(
      {
        ...this.state,
        year: e.target.value,
      },
      () => {
        this.getPPMPToApprovePerOffice();
      }
    );
  }

  onSelectOfficeHandler(e) {
    let new_office_id = parseInt(e.target.value) > 0 ? e.target.value : 0;
    this.setState(
      {
        ...this.state,
        office_id: new_office_id,
      },
      () => {
        this.getPPMPToApprovePerOffice();
      }
    );
  }

  onSelectStatusHandler(e) {
    let status = parseInt(e.target.value);
    this.setState(
      {
        ...this.state,
        filtered_status: status,
        office_id: 0,
      },
      () => {
        // console.log("$$status", status);
        this.getPPMPToApprovePerOffice();
      }
    );
  }

  render() {
    const { classes } = this.props;
    const { offices } = this.state;
    return (
      <Material.Paper className={classes.drawer}>
        <Material.Toolbar className={classes.toolbar}>
          <Material.Grid container spacing={1}>
            <Material.Grid item xs={3}>
              <form>
                <Material.FormControl
                  variant="standard"
                  className={classes.formControl}
                >
                  <Material.InputLabel>Filter Status</Material.InputLabel>
                  <Material.Select
                    inputProps={{
                      name: "status",
                      id: "",
                    }}
                    value={this.state.filtered_status}
                    onChange={(e) => {
                      this.onSelectStatusHandler(e);
                    }}
                  >
                    <Material.MenuItem value="0">
                      For Approval
                    </Material.MenuItem>
                    <Material.MenuItem value="1">Approved</Material.MenuItem>
                    <Material.MenuItem value="2">Rejected</Material.MenuItem>
                  </Material.Select>
                </Material.FormControl>
              </form>
            </Material.Grid>
            <Material.Grid item xs={6}>
              <div className={classes.toolRight}>
                <Material.FormControl
                  variant="standard"
                  className={classes.formControl}
                >
                  <Material.InputLabel id="demo-simple-select-standard-label">
                    Filter by Office
                  </Material.InputLabel>
                  <Material.Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.office_id}
                    onChange={this.onSelectOfficeHandler}
                    label="Age"
                  >
                    <Material.MenuItem onClick={this.getOffices}>
                      Select Default
                    </Material.MenuItem>
                    {offices.map((office) => (
                      <Material.MenuItem
                        key={office.office_id}
                        value={office.office_id}
                      >
                        <Material.Typography>
                          ({office.office_abb}) - {office.office}
                        </Material.Typography>
                      </Material.MenuItem>
                    ))}
                  </Material.Select>
                </Material.FormControl>
              </div>
            </Material.Grid>
            {/* selectyear */}
            <Material.Grid item xs={3}>
              <form>
                <Material.FormControl
                  variant="standard"
                  className={classes.formControl}
                >
                  <Material.InputLabel>Select Year</Material.InputLabel>
                  <Material.Select
                    inputProps={{
                      name: "year",
                      id: "",
                    }}
                    value={this.state.year}
                    onChange={this.onSelectYearHandler}
                  >
                    {/* <Material.MenuItem value={0}>
                  <em>None</em>
                </Material.MenuItem> */}
                    {this.state.year_range.map((val) => (
                      <Material.MenuItem key={val} value={val}>
                        {" "}
                        {val}
                      </Material.MenuItem>
                    ))}
                  </Material.Select>
                </Material.FormControl>
              </form>
            </Material.Grid>
          </Material.Grid>
        </Material.Toolbar>

        <Material.TableContainer>
          <Material.Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className={classes.table}
          >
            <Material.TableHead>
              <Material.TableRow>
                <Material.TableCell>Action</Material.TableCell>
                <Material.TableCell>PPMP No.</Material.TableCell>
                <Material.TableCell>Title</Material.TableCell>
                <Material.TableCell>Office</Material.TableCell>
                <Material.TableCell>Year</Material.TableCell>
                <Material.TableCell>Date</Material.TableCell>
              </Material.TableRow>
            </Material.TableHead>
            <Material.TableBody>
              {this.state.res_no_data === 1 ? (
                <Material.TableRow>
                  <Material.TableCell colSpan={20} className="emptyCont">
                    <div className="empty">
                      <div className="emptyBorder">No PPMP data found.</div>
                    </div>
                  </Material.TableCell>
                </Material.TableRow>
              ) : (
                this.state.for_approval_ppmp.map((row) => (
                  <React.Fragment key={row.id}>
                    <Material.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Material.TableCell>
                        <Material.IconButton
                          onClick={(event) => {
                            this.setState({
                              ...this.state,
                              [`anchorEl-${row.id}${row.ppmp_no}`]:
                                event.currentTarget,
                            });
                          }}
                        >
                          <DoubleArrowRoundedIcon />
                        </Material.IconButton>
                        <Material.Popover
                          id="mouse-over-popover"
                          open={Boolean(
                            this.state[`anchorEl-${row.id}${row.ppmp_no}`]
                          )}
                          anchorEl={
                            this.state[`anchorEl-${row.id}${row.ppmp_no}`]
                          }
                          onClick={() => {
                            this.setState({
                              [`anchorEl-${row.id}${row.ppmp_no}`]: null,
                            });
                          }}
                          classes={{ paper: classes.paperless_ref }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <React.Fragment>
                            <Material.Tooltip
                              title="For Approval List"
                              aria-label="For Approval List"
                            >
                              <Material.Fab
                                className={classes.actionList}
                                size="small"
                                onClick={() => this.onPPMPListDisplay(row)}
                              >
                                <ListIcon />
                              </Material.Fab>
                            </Material.Tooltip>
                          </React.Fragment>
                        </Material.Popover>
                      </Material.TableCell>
                      <Material.TableCell component="th" scope="row">
                        {row.ppmp_no}
                      </Material.TableCell>
                      <Material.TableCell>{row.ppmp_title}</Material.TableCell>
                      <Material.TableCell>{row.office_abb}</Material.TableCell>
                      <Material.TableCell>{row.year}</Material.TableCell>
                      <Material.TableCell>{row.transdate}</Material.TableCell>
                    </Material.TableRow>
                  </React.Fragment>
                ))
              )}
            </Material.TableBody>
          </Material.Table>
        </Material.TableContainer>
      </Material.Paper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Material.withStyles(useStyles)(UnforeseenPPMP));
