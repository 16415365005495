export const useStyles = (theme) => ({
    root: {
        outline: 'none',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    foroneline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '98%',
        },
    },
    fortwoline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '52ch',
        },
    },
    forthreeline: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '34ch',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px',
        padding: theme.spacing(4, 4, 3),
        maxWidth: '1286px',
        maxHeight: '572px',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    FormContainer: {
        // // display: 'flex',
        // flexWrap: 'wrap',
    },
    action: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    tableFooter: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    iconButton: {
        padding: '10px',
    },
    toolRight: {
        flexGrow: 1,
    },

    approved: {
        backgroundColor: '#d1ffd1',
        color: 'green',
		'& .MuiChip-avatar': {
			color:"green",
		}
	},
	pending: {
        backgroundColor: '#ffebc7',
        color: "orange",
		'& .MuiChip-avatar': {
			color: "orange",
		}
    },
    rejected: {
        backgroundColor: '#ffc9c9',
        color:"red",
		'& .MuiChip-avatar': {
			color:"red",
		}
	},
    tableHeight: {
        overflowY: 'auto',
        maxHeight: '448px',
    }
})