export const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		'& .animate:hover': {
			backgroundColor: 'rgba(8, 0, 74, 0.15)',
			transform: 'scale(1.1)'
		},
		'& .inline': {
			display: 'inline-flex',
			'& > *': {
				paddingRight: theme.spacing(2),
			},
			'& .MuiTypography-subtitle1': {
				whiteSpace: 'nowrap',
				margin: theme.spacing(0, 0, 0, 0.5),
				display: 'inline-block',
			},
		},
		'& .emptyCont': {
			padding: theme.spacing(4),
			'& .emptyBorder': {
				padding: theme.spacing(10, 4),
				borderRadius: '10px',
				borderStyle: 'dashed',
				borderColor: 'rgba(29, 29, 29, 0.10)',
			},
			'& .empty': {
				padding: theme.spacing(4),
				backgroundColor: 'rgba(29, 29, 29, 0.10)',
				borderRadius: '10px',
				textAlign: 'center',
				color: 'gray'
			},
		},
	},
	statusColor: {
		'& .Open': {
			backgroundColor: '#2e4ae6',
            color: 'white',
            width: '120px',
		},
		'& .Pre-Approval': {
			backgroundColor: '#ef8e78',
            color: 'white',
            width: '120px',
        },
        '& .Returned': {
			backgroundColor: '#18b3d6',
            color: 'white',
            width: '120px',
		},
		'& .Pending': {
			backgroundColor: "#e89e16eb",
            color: 'white',
            width: '120px',
		},
		'& .Revision': {
			backgroundColor: "#e89e16eb",
            color: 'white',
            width: '120px',
		},
		'& .Approved': {
			backgroundColor: "green",
            color: 'white',
            width: '120px',
        },
        '& .Generated': {
			backgroundColor: "#cccc10",
            color: 'white',
            width: '120px',
        }
	},
	listSection: {
		backgroundColor: 'inherit',
	},
	ul: {
		backgroundColor: 'inherit',
		padding: 0,
	},
	drawerToolbar: {
		marginTop: '40px'
	},
	drawer: {
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		width: 250,
		overflowY: 'auto',
		'& .MuiFormControl-root': {
			display: 'flex'
		},
		'& .MuiFormControlLabel-root': {
			paddingLeft: theme.spacing(2),
		},
		'& .radio-choices:hover': {
			'& .MuiButtonBase-root': {
				backgroundColor: 'rgba(8, 0, 74, 0.15)',
				transform: 'scale(1.1)'
			},
			'& .MuiFormControlLabel-label': {
				transform: 'scale(1.1)'
			}
		},
		'& .subradio-choices': {
			marginLeft: '10px',
			'& .MuiButtonBase-root': {
				backgroundColor: 'rgba(8, 0, 74, 0.15)',
				transform: 'scale(1.1)'
			},
			'& .MuiFormControlLabel-label': {
				transform: 'scale(1.1)'
			}
		}
	},
	transactionHeader: {
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
		boxShadow: theme.shadows[5],
		'& .div-margin': {
			margin: theme.spacing(1, 0, 0, 0),
		},
		'& .pull-right': {
			float: 'right'
		}
	},
	iconButton: {
		padding: '10px',
	},
	margin: {
		margin: theme.spacing(1),
	},
	pagerFooter: {
		padding: theme.spacing(4),
		flexDirection: 'column',
		textAlign: 'center'
	},
	toolRight: {
		flexGrow: 1,
	},
	button: {
		margin: theme.spacing(1),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'&:hover': {
			backgroundColor: 'rgba(8, 0, 74, 0.15)',
			transform: 'scale(1.02)'
		}
	},
	chipContainer: {
		padding: theme.spacing(1),
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	tableFooter: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
	gridList: {
		padding: theme.spacing(2),
		// width: 500,
		// height: 450,
	},
	gridTile: {
		borderBottom: '2px solid blue',
		borderRadius: '5px',
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		height: 220,
		display: 'flex',
		flexDirection: 'column',
		'&:hover': {
			backgroundColor: 'rgba(29, 29, 29, 0.10)',
			transform: 'scale(1.02)'
		}
	},
	gridMainContent: {
		flex: 1,
		lineHeight: '1.125rem',
		height: '3.25rem',
		'& .title': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': 1,
			height: 'inherit',
		},
		'& .description': {
			color: theme.palette.text.secondary,
			'& .fit-details': {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}
		},
	},
	searchAutocomplete: {
		width: '100%'
	},
	searchInput: {
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			paddingRight: '16px'
		}
	},
	chipData: {
		'& .MuiChip-label': {
			maxWidth: '251px'
		}
	}
})