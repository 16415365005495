import React, { Component } from "react";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "../ppmp/ppmp.reducer";

import Loading from "../common/loading";
import Stack from "@mui/material/Stack";
import * as Material from "@material-ui/core";
import { useStyles } from "./deliverables.styles";
import * as DeliverablesService from "./deliverables.service";
import { COMM } from "../../helpers/common";

import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";

class Deliverables extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sort_column: "",
			sort_type: "",
			po_items: [],
			po_number: "",
			office_id: 0,
			openRows: {},
			ppmp_item_id: "",
			po_item_id: "",
			item_delivered_details: [],
			newDelivery: {
				qty_delivered: "",
				delivered_on: new Date().toISOString().slice(0, 10),
			},
			editingRowIndex: null, // Track which row is being edited
			editedRowData: null, // Hold temporary data for the row being edited
			backupDetails: {},
			originalData: {},
			isDialogOpen: false,
			selectedRow: {},
			error: false,
			ppmp_item_total_qty: 0,
			dateError: "",
		};
	}
	componentDidMount() {
		const { data } = this.props;
		this.setState({
			...this.state,
			office_id: data.user.office_id,
		});
	}

	fetchItemsByPO = () => {
		const { displayLoading, displayAlert } = this.props;
		const values = {
			po_no: this.state.po_number,
			office_id: this.state.office_id,
		};

		displayLoading(true);
		DeliverablesService.getItemsByPO(values)
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: response.error,
						message: response.msg,
						severity: "warning",
					});
				} else {
					this.setState({ po_items: response.data });
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
			})
			.finally(() => {
				displayLoading(false);
			});
	};

	fetchItemDeliveryDetails = () => {
		const { displayLoading, displayAlert } = this.props;
		const values = {
			ppmp_items_id: this.state.ppmp_item_id,
			po_items_id: this.state.po_item_id,
		};

		displayLoading(true);
		DeliverablesService.getItemDeliveryDetails(values)
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: response.error,
						message: response.msg,
						severity: "warning",
					});
				} else {
					this.setState({
						item_delivered_details:
							response.data[0].ret === "No data found"
								? response.data[0].ret
								: response.data,
					});
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
			})
			.finally(() => {
				displayLoading(false);
			});
	};

	toggleRow = (rowId, row) => {
		this.setState(
			(prevState) => ({
				openRows: {
					// ...prevState.openRows, //open multiple rows
					[rowId]: !prevState.openRows[rowId],
				},
				ppmp_item_id: row.ppmp_items_id,
				po_item_id: row.po_items_id,
				ppmp_item_total_qty: parseFloat(row.qty),
			}),
			() => {
				this.fetchItemDeliveryDetails();
			}
		);
	};

	handleChange = (event) => {
		this.setState({ po_number: event.target.value });
	};

	toggleEditMode = (index) => {
		this.setState((prevState) => {
			const updatedDetails = [...prevState.item_delivered_details];

			updatedDetails[index].isEditable = !updatedDetails[index].isEditable;

			if (updatedDetails[index].isEditable) {
				this.startEdit(index);
			}

			return { item_delivered_details: updatedDetails };
		});
	};

	saveRow = (index, row) => {
		const { displayLoading, displayAlert, data } = this.props;
		const { item_delivered_details } = this.state;
		const selectedRow = item_delivered_details[index];

		if (selectedRow.delivered_on === "") {
			// Set error state for the specific row
			this.setState({
				dateError: `Please select a valid date.`,
			});
			return; // Stop the save process
		}

		this.setState(
			(prevState) => {
				const updatedDetails = [...prevState.item_delivered_details];
				updatedDetails[index].isEditable = false;

				return { item_delivered_details: updatedDetails, originalData: null };
			},
			() => {
				const values = {
					id: selectedRow.id || 0,
					ppmp_items_id: row.ppmp_items_id, //get data from parent
					po_items_id: row.po_items_id, //get data from parent
					qty_delivered: selectedRow.qty_delivered,
					dg_user_id: data.user.id, //get id from props
					is_final: selectedRow.is_final || 0,
					delivered_on: selectedRow.delivered_on,
				};

				displayLoading(true);
				DeliverablesService.insertDeliveredItems(values)
					.then((response) => {
						if (response.error) {
							displayAlert({
								alertVisible: true,
								alertMessage: response.msg,
								alertSeverity: "warning",
							});
						} else {
							displayAlert({
								alertVisible: true,
								alertMessage: "Delivery saved successfully",
								alertSeverity: "success",
							});
							this.fetchItemDeliveryDetails();
						}
					})
					.catch((err) => {
						displayAlert({
							alertVisible: true,
							alertMessage: err,
							alertSeverity: "error",
						});
					})
					.finally(() => {
						displayLoading(false);
					});
			}
		);
	};

	finalizeDelivery = (is_final) => {
		const { displayLoading, displayAlert, data } = this.props;
		const { selectedRow } = this.state;

		const values = {
			id: selectedRow.id,
			ppmp_items_id: selectedRow.ppmp_items_id,
			po_items_id: selectedRow.po_items_id,
			qty_delivered: selectedRow.qty_delivered,
			dg_user_id: data.user.id, //get id from props
			is_final: is_final,
			delivered_on: selectedRow.delivered_on,
		};

		displayLoading(true);
		DeliverablesService.insertDeliveredItems(values)
			.then((response) => {
				if (response.error) {
					displayAlert({
						alertVisible: true,
						alertMessage: response.msg,
						alertSeverity: "warning",
					});
				} else {
					displayAlert({
						alertVisible: true,
						alertMessage: "Delivery saved successfully",
						alertSeverity: "success",
					});
					this.fetchItemsByPO();
					this.fetchItemDeliveryDetails();
					this.handleCloseDialog();
				}
			})
			.catch((err) => {
				displayAlert({
					alertVisible: true,
					alertMessage: err,
					alertSeverity: "error",
				});
			})
			.finally(() => {
				displayLoading(false);
			});
	};

	startEdit = (index) => {
		this.setState((prevState) => {
			const originalData = [...prevState.item_delivered_details];

			return {
				originalData,
				item_delivered_details: prevState.item_delivered_details.map(
					(item, idx) => (idx === index ? { ...item, isEditable: true } : item)
				),
			};
		});
	};

	cancelEdit = (index) => {
		this.setState((prevState) => {
			const { item_delivered_details, originalData } = prevState;

			// Check if the item being edited is a new unsaved row
			if (item_delivered_details[index].isNew) {
				// Remove the new row
				const updatedData = item_delivered_details.filter(
					(_, i) => i !== index
				);
				return {
					item_delivered_details: updatedData,
				};
			}

			// Otherwise, revert to original data if available
			if (!originalData || originalData.length === 0) {
				return {};
			}

			const revertedData = [...item_delivered_details];
			revertedData[index] = {
				...originalData[index],
				isEditable: false,
			};

			return {
				item_delivered_details: revertedData,
				originalData: null,
			};
		});
	};

	addNewDeliveryRow = () => {
		const { item_delivered_details } = this.state;

		if (item_delivered_details === "No data found") {
			this.setState({
				item_delivered_details: [
					{
						qty_delivered: "",
						delivered_on: "",
						isEditable: true,
						isNew: true,
					},
				],
			});
		} else {
			this.setState((prevState) => ({
				item_delivered_details: [
					...prevState.item_delivered_details,
					{
						qty_delivered: "",
						delivered_on: "",
						isEditable: true,
						isNew: true,
					},
				],
			}));
		}
	};

	handleInputChange = (index, field, value) => {
		this.setState((prevState) => {
			const updatedDetails = [...prevState.item_delivered_details];
			updatedDetails[index] = {
				...updatedDetails[index],
				[field]: value,
			};

			// Only calculate sum and set error if the field is 'qty_delivered'
			if (field === "qty_delivered") {
				const totalQtyDelivered = Number(
					updatedDetails.reduce((sum, detail) => {
						const qty = parseFloat(detail.qty_delivered) || 0;
						return sum + qty;
					}, 0)
				);

				// Assuming `item.qty` holds the maximum allowable quantity
				const isError =
					totalQtyDelivered > this.state.ppmp_item_total_qty ||
					totalQtyDelivered < 0;
				return {
					item_delivered_details: updatedDetails,
					error: isError,
				};
			}

			// If the field is not 'qty_delivered', only update the details without calculating the sum
			return { item_delivered_details: updatedDetails };
		});
	};

	formatNumber(num) {
		if (num) {
			return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		} else {
			return <React.Fragment></React.Fragment>;
		}
	}

	handleClickOpenDialog = (delivered) => {
		this.setState((prevState) => ({
			...prevState,
			isDialogOpen: true,
			selectedRow: delivered,
		}));
	};

	handleCloseDialog = () => {
		this.setState({ isDialogOpen: false });
	};

	render() {
		const TableHeaderOp = [
			{ id: "Item Code", label: "Item Code", align: "center" },
			{ id: "Name", label: "Name", align: "center" },
			{ id: "Description", label: "Description", align: "left" },
			{ id: "Unit", label: "Unit", align: "center" },
			{ id: "Unit Cost", label: "Unit Cost", align: "center" },
			{ id: "Quantity", label: "Quantity", align: "center" },
			{ id: "Total Cost", label: "Total Cost", align: "center" },
		];
		const { classes } = this.props;
		const {
			sort_column,
			sort_type,
			po_number,
			po_items,
			openRows,
			item_delivered_details,
			editingRowIndex,
			editedRowData,
			error,
		} = this.state;

		return (
			<div className={classes.root}>
				<Material.Paper className={classes.paper}>
					<Material.TableContainer component={Material.Paper}>
						<Material.Paper className={classes.paper}>
							<Material.FormControl
								className={classes.formControlMax}
								variant="filled"
							>
								<Material.InputLabel htmlFor="filled-adornment-password">
									Enter PO Number
								</Material.InputLabel>
								<Material.FilledInput
									id="filled-adornment-password"
									value={po_number}
									onChange={this.handleChange}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											this.fetchItemsByPO();
										}
									}}
									endAdornment={
										<Material.InputAdornment position="end">
											<Material.IconButton onClick={this.fetchItemsByPO}>
												<SearchIcon />
											</Material.IconButton>
										</Material.InputAdornment>
									}
								/>
							</Material.FormControl>
						</Material.Paper>

						<Material.Table
							className={classes.table}
							aria-label="collapsible table"
						>
							<Material.TableHead>
								<Material.TableRow>
									<Material.TableCell />
									{TableHeaderOp.map((option) => (
										<Material.TableCell
											key={option.id}
											align={option.align}
											sortDirection={
												sort_column === option.id ? sort_type : false
											}
										>
											<Material.TableSortLabel
												active={sort_column === option.id}
												direction={
													sort_column === option.id ? sort_type : "asc"
												}
											>
												{option.label}
											</Material.TableSortLabel>
										</Material.TableCell>
									))}
								</Material.TableRow>
							</Material.TableHead>
							<Material.TableBody>
								{po_items && po_items.length > 0 ? (
									po_items.map((item) => (
										<React.Fragment key={item.ppmp_items_id}>
											<Material.TableRow>
												<Material.TableCell>
													<Material.IconButton
														aria-label="expand row"
														size="small"
														onClick={() =>
															this.toggleRow(item.ppmp_items_id, item)
														}
													>
														{openRows[item.ppmp_items_id] ? (
															<KeyboardArrowUpIcon />
														) : (
															<KeyboardArrowDownIcon />
														)}
													</Material.IconButton>
												</Material.TableCell>
												<Material.TableCell align="center">
													{item.item_code}
												</Material.TableCell>
												<Material.TableCell align="center">
													{item.name}
												</Material.TableCell>
												<Material.TableCell align="left">
													{item.description}
												</Material.TableCell>
												<Material.TableCell align="center">
													{item.unit}
												</Material.TableCell>
												<Material.TableCell align="center">
													{item.unit_cost}
												</Material.TableCell>
												<Material.TableCell align="center">
													{item.qty}
												</Material.TableCell>
												<Material.TableCell>
													{`₱ ${new Intl.NumberFormat("en-PH", {
														style: "decimal",
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}).format(item.total_cost)}`}
												</Material.TableCell>
											</Material.TableRow>
											<Material.TableRow>
												<Material.TableCell
													style={{ paddingBottom: 0, paddingTop: 0 }}
													colSpan={9}
												>
													<Material.Collapse
														in={openRows[item.ppmp_items_id]}
														timeout="auto"
														unmountOnExit
													>
														<Material.Box margin={1}>
															<div className={classes.paperAdvance}>
																<Material.Typography
																	variant="h6"
																	gutterBottom
																	component="div"
																>
																	History
																</Material.Typography>
																<p>
																	<strong>Supplier Name:</strong>{" "}
																	{item.supplier_name}
																</p>
																<p>
																	<strong>PPMP Title/Activity Name:</strong>{" "}
																	{item.activity_name}
																</p>
																<p>
																	<strong>Brand:</strong> {item.brand}
																</p>
															</div>
															<Material.TableContainer>
																<Material.Table
																	size="small"
																	aria-label="a dense table"
																>
																	<Material.TableHead>
																		<Material.TableRow>
																			<Material.TableCell
																				width={16}
																			></Material.TableCell>
																			<Material.TableCell>
																				Quantity Delivered
																			</Material.TableCell>
																			<Material.TableCell>
																				Date Delivered
																			</Material.TableCell>
																		</Material.TableRow>
																	</Material.TableHead>
																	<Material.TableBody>
																		{item_delivered_details.length === 0 ||
																		item_delivered_details ===
																			"No data found" ? (
																			<Material.TableRow>
																				<Material.TableCell
																					colSpan={3}
																					align="center"
																				>
																					No delivery details.
																				</Material.TableCell>
																			</Material.TableRow>
																		) : (
																			item_delivered_details.map(
																				(delivered, index) => (
																					<Material.TableRow key={index}>
																						{/* Edit Mode for the row */}
																						{delivered.isEditable ? (
																							<>
																								<Material.TableCell width={10}>
																									<Stack
																										direction="row"
																										spacing={1}
																									>
																										<Material.Tooltip title="Save">
																											<Material.IconButton
																												onClick={() =>
																													this.saveRow(
																														index,
																														item
																													)
																												}
																												size="small"
																												disabled={
																													this.state.error
																												}
																											>
																												<DoneIcon fontSize="small" />
																											</Material.IconButton>
																										</Material.Tooltip>
																										<Material.Tooltip title="Cancel">
																											<Material.IconButton
																												onClick={() =>
																													this.cancelEdit(index)
																												}
																												size="small"
																											>
																												<RevertIcon fontSize="small" />
																											</Material.IconButton>
																										</Material.Tooltip>
																									</Stack>
																								</Material.TableCell>
																								<Material.TableCell>
																									<Material.TextField
																										variant="filled"
																										size="small"
																										value={
																											delivered.qty_delivered
																										}
																										onChange={(e) =>
																											this.handleInputChange(
																												index,
																												"qty_delivered",
																												e.target.value
																											)
																										}
																										InputProps={{
																											inputProps: {
																												min: 0,
																											},
																										}}
																										onKeyPress={(event) => {
																											if (
																												event?.key === "-" ||
																												event?.key === "+"
																											) {
																												event.preventDefault();
																											}
																										}}
																										type="number"
																										placeholder="Enter quantity"
																										fullWidth
																										error={error} // Shows error state on the TextField
																										helperText={
																											error
																												? "Total delivered quantity exceeds remaining quantity."
																												: ""
																										}
																									/>
																								</Material.TableCell>
																								<Material.TableCell>
																									<Material.TextField
																										variant="filled"
																										size="small"
																										type="date"
																										value={
																											delivered.delivered_on
																												? delivered.delivered_on.split(
																														" "
																												  )[0]
																												: ""
																										} // Get only the date part or empty string if null
																										onChange={(e) => {
																											const selectedDate =
																												e.target.value; // Get the selected date

																											// Ensure selectedDate is a valid date string
																											if (
																												Date.parse(selectedDate)
																											) {
																												const currentTime =
																													new Date(); // Get the current time
																												const currentHours =
																													String(
																														currentTime.getHours()
																													).padStart(2, "0"); // Format hours
																												const currentMinutes =
																													String(
																														currentTime.getMinutes()
																													).padStart(2, "0"); // Format minutes
																												const currentSeconds =
																													String(
																														currentTime.getSeconds()
																													).padStart(2, "0"); // Format seconds
																												const updatedDateTime = `${selectedDate} ${currentHours}:${currentMinutes}:${currentSeconds}`; // Combine date and time

																												this.handleInputChange(
																													index,
																													"delivered_on",
																													updatedDateTime
																												); // Update state with new value
																											} else {
																												console.error(
																													"Invalid date selected"
																												);
																											}
																										}}
																										fullWidth
																										error={
																											!!this.state.dateError
																										} // Display error state if dateError is set
																										helperText={
																											this.state.dateError
																										} // Display the error message
																									/>
																								</Material.TableCell>
																							</>
																						) : (
																							/* Display Mode for the row */
																							<>
																								<Material.TableCell width={16}>
																									<Stack
																										direction="row"
																										spacing={1}
																									>
																										<Material.Tooltip title="Finalize Delivery">
																											<Material.IconButton
																												color="primary"
																												disabled={
																													parseInt(
																														delivered.is_final
																													) > 0
																												}
																												onClick={() =>
																													this.handleClickOpenDialog(
																														delivered
																													)
																												}
																												size="small"
																											>
																												<AssignmentTurnedInRoundedIcon fontSize="small" />
																											</Material.IconButton>
																										</Material.Tooltip>
																										<Material.Tooltip title="Edit">
																											<Material.IconButton
																												onClick={() =>
																													this.toggleEditMode(
																														index
																													)
																												}
																												color="primary"
																												disabled={
																													parseInt(
																														delivered.is_final
																													) > 0
																												}
																												size="small"
																											>
																												<EditIcon fontSize="small" />
																											</Material.IconButton>
																										</Material.Tooltip>
																									</Stack>
																								</Material.TableCell>
																								<Material.TableCell>
																									{delivered.qty_delivered}
																								</Material.TableCell>
																								<Material.TableCell>
																									{COMM.DdMmmYyyy(
																										delivered.delivered_on
																									)}
																								</Material.TableCell>
																							</>
																						)}
																					</Material.TableRow>
																				)
																			)
																		)}

																		{/* New Delivery Button */}
																		<Material.TableRow
																			className={classes.paperAdvance}
																		>
																			<Material.TableCell
																				align="left"
																				colSpan={2}
																			>
																				<Material.Button
																					variant="text"
																					startIcon={<LocalShippingIcon />}
																					onClick={this.addNewDeliveryRow}
																					disabled={
																						parseFloat(item.qty_delivered) ===
																						parseFloat(item.qty)
																					}
																				>
																					New Delivery
																				</Material.Button>
																			</Material.TableCell>
																			<Material.TableCell align="right">
																				{/* <Material.Button
																					variant="text"
																					startIcon={<TaskAltIcon />}
																					disabled={
																						item_delivered_details ===
																						"No data found"
																					}
																				>
																					Mark as Complete
																				</Material.Button> */}
																				<Material.Typography variant="button">
																					Finalized Delivery:{" "}
																					<Material.Chip
																						label={parseFloat(
																							item.qty_delivered
																						)}
																						className={
																							parseFloat(item.qty_delivered) ===
																							parseFloat(item.qty)
																								? classes.approved
																								: " "
																						}
																					/>{" "}
																					Remaining:{" "}
																					<Material.Chip
																						label={
																							parseFloat(item.qty) -
																							parseFloat(item.qty_delivered)
																						}
																					/>
																				</Material.Typography>
																			</Material.TableCell>
																		</Material.TableRow>
																	</Material.TableBody>
																</Material.Table>
															</Material.TableContainer>
														</Material.Box>
													</Material.Collapse>
												</Material.TableCell>
											</Material.TableRow>
										</React.Fragment>
									))
								) : (
									<Material.TableRow>
										<Material.TableCell colSpan={20} className="emptyCont">
											<div className="empty">
												<div className="emptyBorder">
													Enter PO to display items.
												</div>
											</div>
										</Material.TableCell>
									</Material.TableRow>
								)}
							</Material.TableBody>
						</Material.Table>
					</Material.TableContainer>
				</Material.Paper>

				<Material.Dialog
					open={this.state.isDialogOpen}
					onClose={this.handleCloseDialog}
				>
					<Material.DialogTitle>
						Finalize PPMP Item Delivery?
					</Material.DialogTitle>
					<Material.DialogContent>
						<Material.DialogContentText id="alert-dialog-description">
							Are you sure you want to finalize this delivery? This means you
							cannot edit this delivery anymore after finalizing.
						</Material.DialogContentText>
					</Material.DialogContent>
					<Material.DialogActions>
						<Material.Button onClick={this.handleCloseDialog}>
							Disagree
						</Material.Button>
						<Material.Button onClick={() => this.finalizeDelivery(1)} autoFocus>
							Agree
						</Material.Button>
					</Material.DialogActions>
				</Material.Dialog>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Material.withStyles(useStyles)(Deliverables));
