import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './error.unauthorized.styles';

import { mapStateToProps, mapDispatchToProps } from '../container/container.reducer';

class ErrorUA extends React.Component {
    constructor() {
        super();
        this.state = {
            timer: 0,
            seconds: 10
        };
        this.redirectAccess = this.redirectAccess.bind(this)
    }
    componentDidMount() {
        // this.redirectAccess();
        this.myInterval = setInterval(() => {
            this.setState(({ seconds }) => ({
                seconds: seconds - 1
            }))
            if (this.state.seconds === 0) {
                this.props.history.push("/");
            }
        }, 1000)
    }
    componentWillUnmount() {
        clearInterval(this.myInterval)
    }
    redirectAccess () {
        const { data } = this.props;
        const { can_manage_admin, can_manage_ppmp, can_manage_pr } = data.user;
        (can_manage_ppmp) && this.props.history.push("/");
        (can_manage_pr) && this.props.history.push("/pr");
        (can_manage_admin) && this.props.history.push("/admin");
    }
    render() {
        const { seconds } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.code}>404</div>
                <div className={classes.text}><b>Oops! Page not found</b></div>
                <div>Sorry, but the page you are looking for is not found. You will be redirected in <b>{seconds}</b> seconds</div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(ErrorUA)));