export const useStyles = (theme) => ({
    root: {
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    code: {
        color: "#4acfea",
        fontSize: "300px",
        margin: theme.spacing(1),
    },
    text: {
        color: "#686364",
        fontSize: "50px"
    }
})