export default (state = {
    transactionData: {},
    PPMPPriceReqListDataSource: [],
    categoryDatasource: [],
    ppmpTotal: 0,
    selectedTransaction: null,
}, action) => {
    switch (action.type){
        case "SETPPMPPRICEREQLISTDATASOURCE": 
            return state = {
                ...state,
                PPMPPriceReqListDataSource: action.payload.PPMPPriceReqListDataSource,
            }
        case "SETITEMSDATASOURCE":
                return state = {
                ...state,
                itemsCategoriesDatasource: action.payload.itemsCategoriesDatasource,
            }
        case "SETREQUESTEDITEMSDATASOURCE":
                return state = {
                ...state,
                requestedItemsDatasource: action.payload.requestedItemsDatasource,
                reqTotal: action.payload.reqTotal,
            }
        case "SETITEMSCATEGORIESDATASOURCE":
                return state = {
                ...state,
                categoryDatasource: action.payload.categoryDatasource,
            }
        case "DROPPRICEREQLISTSTATE": 
            return state = {
                transactionData: {},
                PPMPPriceReqListDataSource: [],
                categoryDatasource: [],
                ppmpTotal: 0,
                selectedTransaction: null,
            }
        default:
            return state;
    }
};
