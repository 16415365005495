export const useStyles = (theme) => ({
	root: {
		"& .animate:hover": {
			backgroundColor: "rgba(8, 0, 74, 0.15)",
			transform: "scale(1.1)",
		},
		"& .emptyCont": {
			padding: theme.spacing(4),
			"& .emptyBorder": {
				padding: theme.spacing(8, 4),
				borderRadius: "10px",
				borderStyle: "dashed",
				borderColor: "rgba(29, 29, 29, 0.10)",
			},
			"& .empty": {
				padding: theme.spacing(4),
				backgroundColor: "rgba(29, 29, 29, 0.10)",
				borderRadius: "10px",
				textAlign: "center",
				color: "gray",
			},
		},
	},
	drawer: {
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		width: 250,
		height: "97%",
		"& .MuiFormControl-root": {
			display: "flex",
		},
		"& .MuiFormControlLabel-root": {
			paddingLeft: theme.spacing(2),
		},
		"& .radio-choices:hover": {
			"& .MuiButtonBase-root": {
				backgroundColor: "rgba(8, 0, 74, 0.15)",
				transform: "scale(1.1)",
			},
			"& .MuiFormControlLabel-label": {
				transform: "scale(1.1)",
			},
		},
	},
	iconButton: {
		padding: "10px",
	},
	toolRight: {
		flexGrow: 1,
	},
	button: {
		margin: theme.spacing(1),
		"&:hover": {
			transform: "scale(1.1)",
		},
	},
	chipContainer: {
		padding: theme.spacing(1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	formControlMax: {
		margin: theme.spacing(2),
		minWidth: 150,
		display: "flex-end",
	},
	table: {
		minWidth: 700,
		"& .yellow": {
			backgroundColor: "#F6EAB8",
		},
		"& .lightgrey": {
			backgroundColor: "lightgray",
		},
		"& .blue": {
			backgroundColor: "#7BA6EF",
		},
		"& .lightblue": {
			backgroundColor: "#BAD7F8",
		},
		"& .lightfink": {
			backgroundColor: "#f7d6f6",
		},
		"& .lightmint": {
			backgroundColor: "#d1ffd1",
		},
	},
	tableFooter: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	actionItem: {
		display: "flex",
		"& > *": {
			align: "right",
		},
		"& .animate": {
			"&:hover": {
				align: "right",
				backgroundColor: "rgba(8, 0, 74, 0.15)",
				transform: "scale(1.1)",
			},
		},
	},
	tableRow: {
		"&:hover": {
			backgroundColor: "rgba(29, 29, 29, 0.10)",
			transform: "scale(1)",
		},
	},
	animate: {
		"&:hover": {
			backgroundColor: "rgba(8, 0, 74, 0.15)",
			transform: "scale(1.1)",
		},
	},
	approved: {
		backgroundColor: "#d1ffd1",
		color: "green",
		"& .MuiChip-avatar": {
			color: "green",
		},
	},
	pending: {
		backgroundColor: "#ffebc7",
		color: "orange",
		"& .MuiChip-avatar": {
			color: "orange",
		},
	},
	rejected: {
		backgroundColor: "#ffc9c9",
		color: "red",
		"& .MuiChip-avatar": {
			color: "red",
		},
	},
	paperless_ref: {
		padding: theme.spacing(1),
		backgroundColor: "rgba(29, 29, 29, 0)",
		backgroundImage:
			"linear-gradient(to left, rgba(29, 29, 29, 0.35) , rgba(29, 29, 29, 0))",
		color: "transparent",
		borderRadius: "55px",
		border: "none",
		elevation: 0,
		boxShadow: "none",
		width: "300px",
		display: "flex",
		justifyContent: "right",
	},
	actionList: {
		margin: theme.spacing(0, 1, 0, 0),
	},
	collapseExpand: {
		padding: theme.spacing(1),

		"& .MuiCollapse-wrapper": {
			display: "block",
		},
	},
	paperAdvance: {
		backgroundColor: "#5555551a",
		boxShadow: theme.shadows[0],
		borderRadius: "10px",
		padding: theme.spacing(1),
	},
	FormContainer: {
		padding: theme.spacing(2),
		"& ::-webkit-scrollbar-track": {
			WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
			borderRadius: "10px",
			backgroundColor: "#F5F5F5",
		},
		"& .title": {
			padding: theme.spacing(2, 0, 0, 0),
		},
		"& .MuiGrid-item": {
			display: "inline-grid",
		},
		"& .full-width-choices": {
			paddingRight: theme.spacing(4),
			"& .MuiFormControl-root": {
				width: "100%",
			},
		},
	},
	formControl: {
		margin: theme.spacing(1),
		maxWidth: "273px",
	},
	action: {
		marginTop: theme.spacing(4),
		marginRight: theme.spacing(2),
	},
	statusColor: {
		"& .Open": {
			backgroundColor: "#2e4ae6",
			color: "white",
			width: "120px",
		},
		"& .Pre-Approval": {
			backgroundColor: "#ef8e78",
			color: "white",
			width: "120px",
		},
		"& .Cancelled": {
			width: "120px",
		},
		"& .Returned": {
			backgroundColor: "#18b3d6",
			color: "white",
			width: "120px",
		},
		"& .Pending": {
			backgroundColor: "#e89e16eb",
			color: "white",
			width: "120px",
		},
		"& .Revision": {
			backgroundColor: "#e89e16eb",
			color: "white",
			width: "120px",
		},
		"& .Approved": {
			backgroundColor: "green",
			color: "white",
			width: "120px",
		},
		"& .Generated": {
			backgroundColor: "#cccc10",
			color: "white",
			width: "120px",
		},
		"& .Approved-Revision": {
			backgroundColor: "green",
			color: "white",
			width: "120px",
			fontSize: "10px",
		},
	},
	totalBalanceColor: {
		backgroundColor: "green",
		color: "white",
		width: "120px",
	},
});
