import { API } from "../../helpers/axios.instance";

export const getPayments = (tableProp) => {
  let data = new FormData();
  data.append("office_id", tableProp.toPass.office_id);
  data.append("year", parseInt(tableProp.toPass.year));
  data.append("show_all", tableProp.toPass.show_all);
  return API()
    .post("/Payments/Get/index_post", data)
    .then((response) => {
      //   console.log("$$responseService", response);
      return response.data;
    })
    .catch((err) => {
      console.log("$$err", err);
    });
};

export const getPaymentsInfo = (tableProp) => {
  let data = new FormData();
  data.append("dg_transaction_number", tableProp.toPass.dg_transaction_number);
  data.append("office_id", parseInt(tableProp.toPass.office_id));
  data.append("show_detail", tableProp.toPass.show_detail);
  return API()
    .post("/Payments/GetInfo/index_post", data)
    .then((response) => {
      //   console.log("$$responseServiceINFO", response);
      return response.data;
    })
    .catch((err) => {
      console.log("$$err", err);
    });
};
