import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";

import * as Material from "@material-ui/core";

import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PrintIcon from "@material-ui/icons/Print";
import UndoIcon from "@mui/icons-material/Undo";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";
import { useStyles } from "./ppmp.form.style";
import { mapStateToProps, mapDispatchToProps } from "../ppmp.reducer";
import { COMM } from "../../../helpers/common";
import SignatoriesModal from "./signatories.modal";

import * as PPMPservice from "../ppmp.service";
import ppmpModal from "../supplies/ppmp.modal";

class PPMPForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalVisible: false,
			signatoriesData: {},

			transactionNo: "ppmp not found",
			office_id: 0,
			office_name: "",
			transactionDivision: "",
			transactionTitle: "",
			ppmp_id: 0,
			allotment_class: {},
			totalBudget: 0, //total budget of 1 PPMP
			provision: 0, //totalBudget *.1
			contingency: 0, //totalBudget *.1
			totalEstimatedBudget: 0, //overall total of totalBudget + provision + contingency
			supplemental: false,
			signatoriesFound: false,

			plus_10per_inflation: false,
			plus_10per_contingency: false,

			is_revised: false,
			is_indicative: false,
			is_received_by_pbmo: false,
			is_unforeseen: false,
			revision_count: 0,
			status: "",
			confirmDialogVisible: false,
		};
		this.requestGeneratedPPMP = this.requestGeneratedPPMP.bind(this);
		this.formatNumber = this.formatNumber.bind(this);
		this.getTotalBudget = this.getTotalBudget.bind(this);
		this.requestOfficeSignatories = this.requestOfficeSignatories.bind(this);
		this.onSetSignatories = this.onSetSignatories.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.displaySignatories = this.displaySignatories.bind(this);
		this.setSignatoriesData = this.setSignatoriesData.bind(this);
		this.getOfficeName = this.getOfficeName.bind(this);
		this.initFunctions = this.initFunctions.bind(this);
		this.updateStatus = this.updateStatus.bind(this);
		this.openConfirmDialog = this.openConfirmDialog.bind(this);
		this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
	}
	componentDidMount() {
		const { history, displayAlert } = this.props; //ppmp
		sessionService
			.loadSession()
			.then((currentSession) => {
				if (
					currentSession.selectedTransaction === null ||
					currentSession.selectedTransaction === undefined
				) {
					history.push("/");
				} else {
					const data = currentSession.selectedTransaction;
					console.log('$$STATE:', data);
					
					this.setState(
						{
							...this.state,
							office_id: data.office_id,
							transactionID: data.id,
							transactionNo: data.ppmp_no,
							transactionStatus: data.status,
							transactionDate: data.transdate,
							transactionYear: data.year,
							transactionDivision: data.division_name,
							transactionTitle: data.ppmp_title,
							supplemental: Boolean(parseInt(data.parent_id)),
							ppmp_id: data.id,
							is_indicative: data.is_indicative === "1" ? true : false,
							is_received_by_pbmo: data.is_received === "1" ? true : false,
							is_unforeseen: data.is_unforeseen === "1" ? true : false,
							revision_count: parseInt(data.revision_count),
						},
						() => {
							this.initFunctions();
						}
					);
				}
			})
			.catch((err) => {
				displayAlert({
					visible: true,
					message: err,
					severity: "warning",
				});
			});
	}
	initFunctions() {
		this.getOfficeName();
		this.requestGeneratedPPMP();
		this.getTotalBudget();
		this.requestOfficeSignatories();
		this.setSignatoriesData();
	}
	componentWillUnmount() {
		const { history, setGenPPMPDataSource } = this.props;
		var targetPath = history.location.pathname;
		if (
			targetPath.indexOf("supplies") === -1 &&
			targetPath.indexOf("ppmplist") === -1 &&
			targetPath.indexOf("ppmpform") === -1
		) {
			setGenPPMPDataSource({ genPPMPDataSource: [] });
			sessionService
				.saveSession({
					...this.props.auth,
					selectedTransaction: null,
				})
				.catch((err) => {
					this.props.displayAlert({
						visible: true,
						message: err,
						severity: "warning",
					});
				});
		}
	}
	setSignatoriesData() {
		const { setCurrentSignatories, displayLoading, displayAlert } = this.props;

		displayLoading(true);
		PPMPservice.getPPMPsignatories({ ppmp_id: this.state.ppmp_id })
			.then((response) => {
				if (response.error) {
					//display error message from server.
					displayAlert({
						visible: true,
						message: response.msg,
						severity: "warning",
					});
				} else {
					const data = response.data[0];
					setCurrentSignatories({
						adminFullname: data.prepared_signatory_name
							? data.prepared_signatory_name
							: "",
						adminDesignation: data.prepared_signatory_pos
							? data.prepared_signatory_pos
							: "",
						headFullname: data.head_signatory_name
							? data.head_signatory_name
							: "",
						headDesignation: data.head_signatory_pos
							? data.head_signatory_pos
							: "",
						budgetFullname: data.reviewed_signatory_name
							? data.reviewed_signatory_name
							: "",
						budgetDesignation: data.reviewed_signatory_pos
							? data.reviewed_signatory_pos
							: "",
						authFullname: data.auth_signatory_name
							? data.auth_signatory_name
							: "",
						authDesignation: data.auth_signatory_pos
							? data.auth_signatory_pos
							: "",
					});
					if (this.props.ppmp.signatories.length !== 0) {
						this.setState({ signatoriesFound: true });
					}
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				displayAlert({
					visible: true,
					message: err,
					severity: "error",
				});
			});
	}
	requestGeneratedPPMP() {
		const { displayLoading, displayAlert, setGenPPMPDataSource } = this.props;
		displayLoading(true);
		PPMPservice.getGeneratedPPMP({ ppmp_id: this.state.ppmp_id })
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: true,
						message: response.msg,
						severity: "warning",
					});
				} else {
					setGenPPMPDataSource({ genPPMPDataSource: response.data.result0 });
					this.setState({
						...this.state,
						// plus_10per_inflation:
						//   response.data.result0[0].funds[0].ppa_list[0].items[0]
						//     .plus_10per_inflation > 0,
						// plus_10per_contingency:
						//   response.data.result0[0].funds[0].ppa_list[0].items[0]
						//     .plus_10per_contingency > 0,
					});
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				displayAlert({ visible: true, message: err, severity: "error" });
			});
	}
	requestOfficeSignatories() {
		const { displayLoading, displayAlert, setSignatoriesDataSource } =
			this.props;
		displayLoading(true);
		PPMPservice.getOfficeSignatories({ office_id: this.state.office_id })
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: true,
						message: response.msg,
						severity: "warning",
					});
				} else {
					setSignatoriesDataSource({
						signatoriesDatasource: response.data.result,
					});
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				displayAlert({ visible: true, message: err, severity: "error" });
			});
	}
	onSetSignatories() {
		this.setState({
			modalVisible: true,
			signatoriesData: this.props.ppmp.signatoriesDatasource,
		});
	}
	onCloseModal() {
		this.setState({ ...this.state, modalVisible: false }, () => {
			this.initFunctions();
		});
	}
	getTotalBudget() {
		const { displayAlert } = this.props;
		PPMPservice.getTotalBudget({ ppmp_id: this.state.ppmp_id })
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: true,
						message: response.msg,
						severity: "warning",
					});
				} else {
					this.setState({
						...this.state,
						totalBudget: response.result0[0].tot,
						is_revised: parseInt(response.result0[0]?.is_revised) > 0,
					});
				}
			})
			.catch((err) => {
				displayAlert({
					visible: true,
					message: err,
					severity: "error",
				});
			});
	}
	formatNumber(num) {
		return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";
	}
	getOfficeName() {
		const { displayLoading, displayAlert } = this.props;
		displayLoading(true);
		PPMPservice.getOfficeName({ office_id: this.state.office_id })
			.then((response) => {
				if (response.error) {
					displayAlert({
						visible: true,
						message: response.msg,
						severity: "warning",
					});
				} else {
					this.setState({ office_name: response.data[0].office });
				}
				displayLoading(false);
			})
			.catch((err) => {
				displayLoading(false);
				displayAlert({ visible: true, message: err, severity: "error" });
			});
	}
	updateStatus() {
		const { displayLoading, displayAlert } = this.props;
		displayLoading(true);

		// Ensure ppmp_id exists in state before calling the service
		if (
			this.state.transactionStatus !== "Approved" ||
			this.state.transactionStatus !== "Approved-Revision"
		) {
			if (this.state.is_received_by_pbmo === false) {
				if (this.state.ppmp_id) {
					PPMPservice.updatePpmpStatus({ ...this.state })
						.then((res) => {
							if (res.data[0]._ret === "$$ok") {
								displayAlert({
									visible: true,
									message: "PPMP status set successfully.",
									severity: "success",
								});
								// callback(); // Uncomment if you have a callback to handle
								this.props.history.push("/");
							} else {
								this.onHandleExceed(true, res.data[0]);
							}
							displayLoading(false);
						})
						.catch((err) => {
							displayLoading(false);
							displayAlert({
								visible: true,
								message: err.toString(),
								severity: "error",
							});
						});
				} else {
					displayLoading(false);
					displayAlert({
						visible: true,
						message: "PPMP ID is missing",
						severity: "warning",
					});
				}
			} else {
				displayAlert({
					visible: true,
					message: "PPMP has already received by PBMO..",
					severity: "warning",
				});
			}
		}
	}
	openConfirmDialog() {
		this.setState({ confirmDialog: true });
	}
	closeConfirmDialog() {
		this.setState({ confirmDialog: false });
	}

	displaySignatories() {
		const { classes, ppmp } = this.props;
		return (
			<React.Fragment>
				<Material.Grid className={classes.tableFooter} container spacing={3}>
					<Material.Grid item xs={4}>
						<div>
							<Material.Typography
								variant="caption"
								align="left"
								className="signatory_pre"
							>
								Prepared By:
							</Material.Typography>
							<Material.Typography
								variant="subtitle1"
								align="center"
								className="signatory_name"
							>
								<u>
									<b>
										{ppmp.signatories.adminFullname
											? ppmp.signatories.adminFullname
											: ""}
									</b>
								</u>
							</Material.Typography>
							<Material.Typography
								variant="caption"
								align="center"
								className="signatory_pos"
							>
								{ppmp.signatories.adminDesignation
									? ppmp.signatories.adminDesignation
									: ""}
							</Material.Typography>
						</div>
					</Material.Grid>
					<Material.Grid item xs={4}>
						<div>
							<Material.Typography
								variant="caption"
								align="left"
								className="signatory_pre"
							>
								Submitted By:
							</Material.Typography>
							<Material.Typography
								variant="subtitle1"
								align="center"
								className="signatory_name"
							>
								<u>
									<b>
										{ppmp.signatories.headFullname
											? ppmp.signatories.headFullname
											: ""}
									</b>
								</u>
							</Material.Typography>
							<Material.Typography
								variant="caption"
								align="center"
								className="signatory_pos"
							>
								{ppmp.signatories.headDesignation
									? ppmp.signatories.headDesignation
									: ""}
							</Material.Typography>
						</div>
					</Material.Grid>
					<Material.Grid item xs={4}>
						<div>
							<Material.Typography
								variant="caption"
								align="left"
								className="signatory_pre"
							>
								Reviewed as to Availability of Appropriation:
							</Material.Typography>
							<Material.Typography
								variant="subtitle1"
								align="center"
								className="signatory_name"
							>
								<u>
									<b>
										{ppmp.signatories.budgetFullname
											? ppmp.signatories.budgetFullname
											: ""}
									</b>
								</u>
							</Material.Typography>
							<Material.Typography
								variant="caption"
								align="center"
								className="signatory_pos"
							>
								{ppmp.signatories.budgetDesignation
									? ppmp.signatories.budgetDesignation
									: ""}
							</Material.Typography>
						</div>
					</Material.Grid>
				</Material.Grid>
			</React.Fragment>
		);
	}
	render() {
		const {
			transactionNo,
			transactionStatus,
			transactionDate,
			transactionYear,
			totalBudget,
			supplemental,
			transactionDivision,
			transactionTitle,
			is_revised,
			is_received_by_pbmo,
			is_indicative,
			is_unforeseen,
		} = this.state;
		const { classes, ppmp } = this.props;
		return (
			<React.Fragment>
				<div className={classes.root}>
					<Material.Paper className={classes.transactionHeader}>
						<Material.Grid container spacing={3}>
							<Material.Grid item xs={9}>
								<div className="inline">
									<div className={classes.statusColor}>
										<Material.Chip
											className={transactionStatus}
											label={COMM.capitalizeFirstLetter(transactionStatus)}
										/>
									</div>
									<Material.Typography
										className="flex"
										color="primary"
										variant="h5"
									>
										{transactionNo}
									</Material.Typography>
									<Material.Typography variant="h6">
										({transactionYear})
									</Material.Typography>
								</div>
							</Material.Grid>
							<Material.Grid item xs={3}>
								<Material.Typography variant="subtitle2" className="pull-right">
									{COMM.DdMmmYyyyHhMm(transactionDate)}
								</Material.Typography>
							</Material.Grid>
						</Material.Grid>
						<Material.Divider className="div-margin" />
						<Material.Grid container spacing={3}>
							<Material.Grid item xs={12}>
								<Material.Typography variant="caption">
									PPMP Transaction No
								</Material.Typography>
								<Material.Typography variant="subtitle2">
									{" "}
									Division: {transactionDivision}
								</Material.Typography>
							</Material.Grid>
						</Material.Grid>
					</Material.Paper>
					<Material.Paper className={classes.tableContainer} elevation={1}>
						<div align="left">
							<Material.Tooltip
								title="Tip : If PBMO has already received the PPMP, you cannot go back to Open or For Revision."
								placement="bottom-end"
							>
								<span>
									<Material.Button
										color="inherit"
										variant="contained"
										className="button-p"
										startIcon={<UndoIcon />}
										disabled={
											is_received_by_pbmo === true ||
											this.state.transactionStatus === "Approved" ||
											this.state.transactionStatus === "Approved-Revision"
										}
										onClick={() => {
											this.openConfirmDialog();
										}}
									>
										REOPEN PPMP
									</Material.Button>
								</span>
							</Material.Tooltip>
						</div>
						<div align="right">
							<Material.Button
								variant="text"
								color="inherit"
								className="button-p"
								onClick={() => {
									this.onSetSignatories();
								}}
								startIcon={<SupervisedUserCircleIcon />}
							>
								Settings & Signatories
							</Material.Button>
							<ReactToPrint
								trigger={() => (
									<Material.Button
										variant="contained"
										color="primary"
										className="button-p"
										startIcon={<PrintIcon />}
									>
										Print
									</Material.Button>
								)}
								content={() => this.componentRef}
								pageStyle="@page { size: auto; margin: 30px 33px 30px 50px; } }"
							/>
						</div>
						<Material.Divider className="div-margin" />
						<Material.Paper
							className={classes.tableSimple}
							elevation={3}
							ref={(el) => (this.componentRef = el)}
						>
							<Material.Grid container spacing={2}>
								<Material.Grid item xs={12}>
									<Material.Typography
										variant="h6"
										gutterBottom
										className={classes.title}
									>
										{supplemental && "SUPPLEMENTAL"} PROJECT PROCUREMENT
										MANAGEMENT PLAN ({supplemental && "S"}PPMP){" "}
										{transactionYear}
									</Material.Typography>
									{transactionTitle ? (
										<Material.Typography
											variant="h7"
											gutterBottom
											className={classes.title}
										>
											<center>
												<u>{transactionTitle}</u>
											</center>
										</Material.Typography>
									) : null}
								</Material.Grid>
								<Material.Grid item xs={8}>
									<div align="left" className={classes.rootLine_sec}>
										END USER/UNIT:{" "}
										<u>
											<b>
												{`${COMM.capitalizeEachWord(
													this.state.office_name.toLocaleLowerCase()
												)}`}
												{transactionDivision
													? `, ${transactionDivision}`
													: null}
											</b>
										</u>
									</div>
									<div align="left" className={classes.subLine_sec}>
										PPMP Transaction No: <b>{transactionNo} </b>{" "}
										{is_indicative ? (
											<i>(INDICATIVE PPMP)</i>
										) : is_unforeseen ? (
											
											<i>(UNFORESEEN PPMP)</i>
										) : (
											""
										)}
										{is_revised ? <i>(REVISED PPMP)</i> : ""}
									</div>
								</Material.Grid>
								<Material.Grid item xs={4}>
									<div align="right">
										<Barcode
											value={transactionNo}
											height={60}
											fontSize={14}
											marginTop={20}
										/>
									</div>
								</Material.Grid>
							</Material.Grid>
							<Material.TableContainer>
								<Material.Table>
									<Material.TableBody className={classes.table}>
										<Material.TableRow>
											<Material.TableCell>
												<b>ITEM No.</b>
											</Material.TableCell>
											<Material.TableCell>
												<b>ITEM CODE.</b>
											</Material.TableCell>
											<Material.TableCell colSpan={2}>
												<b>GENERAL DESCRIPTION</b>
											</Material.TableCell>
											<Material.TableCell>
												<b>QTY/SIZE</b>
											</Material.TableCell>
											<Material.TableCell className="yellow">
												<b>Unit of Measure</b>
											</Material.TableCell>
											<Material.TableCell className="yellow">
												<b>Unit Cost</b>
											</Material.TableCell>
											<Material.TableCell>
												<b>ESTIMATED BUDGET</b>
											</Material.TableCell>
											<Material.TableCell>
												<b>Mode of Procurement</b>
											</Material.TableCell>
											<Material.TableCell>
												<b>Suggested Item Description/Specs</b>
											</Material.TableCell>
											<Material.TableCell>Jan</Material.TableCell>
											<Material.TableCell>Feb</Material.TableCell>
											<Material.TableCell>Mar</Material.TableCell>
											<Material.TableCell>Apr</Material.TableCell>
											<Material.TableCell>May</Material.TableCell>
											<Material.TableCell>Jun</Material.TableCell>
											<Material.TableCell>Jul</Material.TableCell>
											<Material.TableCell>Aug</Material.TableCell>
											<Material.TableCell>Sept</Material.TableCell>
											<Material.TableCell>Oct</Material.TableCell>
											<Material.TableCell>Nov</Material.TableCell>
											<Material.TableCell>Dec</Material.TableCell>
										</Material.TableRow>
										{ppmp.genPPMPDataSource.map((element, index) => {
											return (
												<React.Fragment key={`class-${index}`}>
													<Material.TableRow>
														<Material.TableCell
															colSpan={22}
															className="lightgrey"
														>
															<b>{element.allotment_class}</b>
														</Material.TableCell>
													</Material.TableRow>
													{/* {element.funds.map((fund_item, fund_index) => {
                            return (
                              <React.Fragment key={`fund-${fund_index}`}>
                                <Material.TableRow>
                                  <Material.TableCell
                                    colSpan={22}
                                    className="lightgrey"
                                  >
                                    <b>{fund_item.fund_source}</b>
                                  </Material.TableCell>
                                </Material.TableRow> */}
													{element.funds.map((fund_item, fund_index) => {
														return (
															<React.Fragment key={`fund-${fund_index}`}>
																{fund_item.ppa_list.map(
																	(ppa_list_item, ppa_list_index) => {
																		return (
																			<React.Fragment
																				key={`fundppalist-${ppa_list_index}`}
																			>
																				<Material.TableRow>
																					<Material.TableCell
																						colSpan={22}
																						className="lightgrey"
																					>
																						<b>
																							{ppa_list_item.ppa_respo} (
																							{
																								ppa_list_item.items[0]
																									.respo_code_description
																							}
																							){" "}
																							{ppa_list_item.items[0].fund_type}{" "}
																							({ppa_list_item.items[0].year})
																						</b>
																					</Material.TableCell>
																				</Material.TableRow>
																				{/* {fund_item.ppa_list.map(
                                  (ppa_list_item, ppa_list_index) => {
                                    return (
                                      <React.Fragment
                                        key={`fundppalist-${ppa_list_index}`}
                                      >
                                        <Material.TableRow>
                                          <Material.TableCell
                                            colSpan={22}
                                            className="lightgrey"
                                          > */}
																				{/* <b>{ppa_list_item.ppa_respo} {ppa_list_item.items.map(itemData => <b>{`(${itemData.respo_code}) ${itemData.fund_type} (${itemData.year})`}</b>)}</b> */}
																				{/* <b>
                                              {ppa_list_item.ppa_respo} (
                                              {
                                                ppa_list_item.items[0]
                                                  .respo_code_description
                                              }
                                              ){" "}
                                              {ppa_list_item.items[0].fund_type}{" "}
                                              ({ppa_list_item.items[0].year})
                                            </b> */}
																				{/* </Material.TableCell>
                                        </Material.TableRow> */}
																				<Material.TableRow>
																					<Material.TableCell
																						colSpan={22}
																						className="lightgrey"
																					>
																						<b>{fund_item.fund_source}</b>
																					</Material.TableCell>
																				</Material.TableRow>
																				{ppa_list_item.items
																					.slice()
																					.sort((a, b) =>
																						a.name
																							.toLowerCase()
																							.localeCompare(
																								b.name.toLowerCase()
																							)
																					)
																					.map((item_data, item_index) => {
																						// console.log(
																						//   "$$item_data",
																						//   item_data
																						// );
																						return (
																							<Material.TableRow
																								key={`item-${item_index}`}
																							>
																								<Material.TableCell align="center">
																									{item_index + 1}
																								</Material.TableCell>
																								<Material.TableCell>
																									<Material.Typography>
																										{item_data.item_code}
																									</Material.Typography>
																								</Material.TableCell>
																								<Material.TableCell
																									colSpan={2}
																									className="col_fit"
																								>
																									{item_data.name} -{" "}
																									{item_data.description} <br />
																									{item_data.catering_office_activity_id >
																									0 ? (
																										<Material.Typography>
																											{" "}
																											<b>
																												Name of Activity :{" "}
																												{
																													item_data.activity_name
																												}
																											</b>
																										</Material.Typography>
																									) : (
																										""
																									)}
																									{item_data.remark
																										? `(${item_data.remark})`
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{item_data.qty}
																								</Material.TableCell>
																								<Material.TableCell
																									className="yellow"
																									align="center"
																								>
																									{item_data.unit}
																								</Material.TableCell>
																								<Material.TableCell
																									className="yellow"
																									align="right"
																								>
																									{this.formatNumber(
																										parseFloat(
																											item_data.unit_cost
																										).toFixed(2)
																									)}
																								</Material.TableCell>
																								<Material.TableCell align="right">
																									{this.formatNumber(
																										parseFloat(
																											item_data.total_cost
																										).toFixed(2)
																									)}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{
																										item_data.mode_of_procurement
																									}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{item_data.prefered_desc}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_jan) >
																									0
																										? item_data.qty_jan
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_feb) >
																									0
																										? item_data.qty_feb
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_mar) >
																									0
																										? item_data.qty_mar
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_apr) >
																									0
																										? item_data.qty_apr
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_may) >
																									0
																										? item_data.qty_may
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_jun) >
																									0
																										? item_data.qty_jun
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_jul) >
																									0
																										? item_data.qty_jul
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_aug) >
																									0
																										? item_data.qty_aug
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_sep) >
																									0
																										? item_data.qty_sep
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_oct) >
																									0
																										? item_data.qty_oct
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_nov) >
																									0
																										? item_data.qty_nov
																										: ""}
																								</Material.TableCell>
																								<Material.TableCell align="center">
																									{parseInt(item_data.qty_dec) >
																									0
																										? item_data.qty_dec
																										: ""}
																								</Material.TableCell>
																							</Material.TableRow>
																						);
																					})}
																				<Material.TableRow>
																					<Material.TableCell
																						colSpan={5}
																						align="right"
																					>
																						{ppmp.genPPMPDataSource.length >
																						1 ? (
																							<b>SUBTOTAL</b>
																						) : (
																							<b>TOTAL</b>
																						)}
																					</Material.TableCell>
																					<Material.TableCell
																						colSpan={2}
																						className="yellow"
																						align="right"
																					>
																						<b>
																							{this.formatNumber(
																								ppa_list_item.items
																									.map((elt) =>
																										!isNaN(elt.total_cost) &&
																										elt.total_cost
																											.toString()
																											.indexOf(".") !== -1
																											? parseFloat(
																													elt.total_cost
																											  )
																											: 0
																									)
																									.reduce((a, b) => a + b)
																									.toFixed(2)
																							)}
																						</b>
																					</Material.TableCell>
																					<Material.TableCell
																						colSpan={15}
																					></Material.TableCell>
																				</Material.TableRow>
																			</React.Fragment>
																		);
																	}
																)}
															</React.Fragment>
														);
													})}
												</React.Fragment>
											);
										})}
										{
											<React.Fragment>
												<Material.TableRow>
													<Material.TableCell colSpan={6} align="left">
														<u>
															<b>TOTAL BUDGET:</b>
														</u>
													</Material.TableCell>
													<Material.TableCell colSpan={2} align="right">
														<b>{this.formatNumber(totalBudget)}</b>
													</Material.TableCell>
													<Material.TableCell colSpan={14}></Material.TableCell>
												</Material.TableRow>
												{/* <Material.TableRow>
                          <Material.TableCell colSpan={6} align="left">
                            <b>+ 10% Provision for Inflation</b>
                          </Material.TableCell>
                          <Material.TableCell colSpan={2} align="right">
                            <b>
                              {this.state.plus_10per_inflation
                                ? this.formatNumber(
                                    (totalBudget * 0.1).toFixed(2)
                                  )
                                : "0.00"}
                            </b>
                          </Material.TableCell>
                          <Material.TableCell colSpan={13}></Material.TableCell>
                        </Material.TableRow>
                        <Material.TableRow>
                          <Material.TableCell colSpan={6} align="left">
                            <b>+ 10% Contingency</b>
                          </Material.TableCell>
                          <Material.TableCell colSpan={2} align="right">
                            <b>
                              {this.state.plus_10per_contingency
                                ? this.formatNumber(
                                    (totalBudget * 0.1).toFixed(2)
                                  )
                                : "0.00"}
                            </b>
                          </Material.TableCell>
                          <Material.TableCell colSpan={13}></Material.TableCell>
                        </Material.TableRow> */}
												<Material.TableRow>
													<Material.TableCell colSpan={6} align="left">
														<b>TOTAL ESTIMATED BUDGET:</b>
													</Material.TableCell>
													<Material.TableCell colSpan={2} align="right">
														<b>
															{/* {this.formatNumber(
                                (
                                  parseFloat(
                                    this.state.plus_10per_inflation
                                      ? totalBudget * 0.1
                                      : 0
                                  ) +
                                  parseFloat(
                                    this.state.plus_10per_contingency
                                      ? totalBudget * 0.1
                                      : 0
                                  ) +
                                  parseFloat(totalBudget)
                                ).toFixed(2)
                              )} */}
															{this.formatNumber(
																parseFloat(totalBudget).toFixed(2)
															)}
														</b>
													</Material.TableCell>
													<Material.TableCell colSpan={14}></Material.TableCell>
												</Material.TableRow>
											</React.Fragment>
										}
									</Material.TableBody>
								</Material.Table>
							</Material.TableContainer>
							{this.displaySignatories()}
						</Material.Paper>
					</Material.Paper>
				</div>
				<Material.Dialog
					open={this.state.confirmDialog}
					onClose={this.closeConfirmDialog}
					width="sm"
				>
					<Material.DialogTitle>
						Confirm reopen ppmp status
					</Material.DialogTitle>
					<Material.DialogContent>
						<Material.DialogContentText>
							Are you sure you want to re-open PPMP ?
						</Material.DialogContentText>
					</Material.DialogContent>
					<Material.DialogActions>
						<Material.Button onClick={this.closeConfirmDialog}>
							Cancel
						</Material.Button>
						<Material.Button
							onClick={() => {
								// Update the state and then call updateStatus
								this.setState(
									{
										status:
											this.state.revision_count > 0 ? "For Revision" : "Open",
									},
									() => {
										this.updateStatus(); // Call updateStatus after state update
									}
								);
							}}
						>
							Proceed
						</Material.Button>
					</Material.DialogActions>
				</Material.Dialog>
				<SignatoriesModal
					modalVisible={this.state.modalVisible}
					onCloseModal={this.onCloseModal}
					signatoriesData={this.props.ppmp.signatoriesDatasource}
					signatoriesFound={this.state.signatoriesFound}
				/>
			</React.Fragment>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Material.withStyles(useStyles)(PPMPForm));
